import React, {useEffect, useMemo} from "react";
import styled from "styled-components";
import {ROOT_PATH_PREFIX, Z_INDEX_TOP_UI} from "./const";
import TopNavi from "./navigation/TopNavi";
import useHistoryStore from "../../stores/historyStore";
import SelectPanel from "../lghome/select_panel/SelectPanel";
import useRightPanelStore from "../../stores/rightPanelStore";
import {Image} from "./comps_styled/Image.styled";
import useResizeStore from "../../stores/resizeStore";
import {getLangString} from "../../api/lang/langData";
import useLangStore from "../../stores/langStore";
import {Text} from "./comps_styled/Text.styled";
import {useModalTutorial} from "../../stores/modalStore";
import {useSpring, animated} from "@react-spring/web";
import {easeCubicInOut, easeCubicOut} from "d3-ease";


const ObjetTitle = React.memo(() => {
    const resize = useResizeStore((state) => state.resize);
    const pathname = useHistoryStore((state) => state.pathname);
    const prevPathname = useHistoryStore(state => state.prevPathname);
    const lang = useLangStore((state) => state.lang);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [spring, animate] = useSpring(() => ({
        from: {opacity: 0},
        config: {duration: 3000, easing: easeCubicInOut}
    }));

    useEffect(() => {
        // console.log("@@>>>", isOpenTutorial, pathname, "--", prevPathname);

        //이전엔 네추럴이 아닌 경우
        if ( (pathname.indexOf('/objetcollection/natural') > -1 && prevPathname.indexOf('/objetcollection/natural') === -1) ||
            (pathname.indexOf('/objetcollection/modern') > -1 && prevPathname.indexOf('/objetcollection/modern') === -1)) {
            if (!isOpenTutorial) {
                animate({cancel: true});
                if(spring.opacity.get() === 1){
                    animate({opacity: 0, delay: 4000, config: {duration: 1000, easing: easeCubicInOut}});
                }else{
                    animate({
                        opacity: 1,
                        config: {duration: 500, easing: easeCubicOut},
                        onRest: result => {
                            // console.log(">>>>>>>타이틀 애니 숨김")
                            animate({opacity: 0, delay: 4000, config: {duration: 1000, easing: easeCubicInOut}});
                        }
                    });
                }
            }
        }else {
            animate({cancel: true});
            animate({opacity: 0, delay: 0, config: {duration: 1000, easing: easeCubicInOut}});
        }
    }, [isOpenTutorial, pathname, prevPathname]);

    if (!pathname.includes("/objetcollection")) return <></>;
    return (
        <animated.div
            style={{
                position: "relative",
                top: `${resize.ratio * 2}em`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                opacity: spring.opacity
            }}
        >
            <Image
                alt="title"
                role="img"
                width={`${resize.ratio * 570}px`}
                height={`${resize.ratio * 121}px`}
                src={ROOT_PATH_PREFIX + "/images/objet/LG object Collection_logo.png"}
            />
            <Text
                style={{
                    filter: `drop-shadow(0 0  ${
                        resize.ratio * 15
                    }px rgba(0, 0, 0, 0.7))`,
                }}
                align="center"
                color="white"
                size={`${resize.ratio * 2.75}em`}
            >
                {getLangString(lang, "objet_main_click")?.text}
            </Text>
        </animated.div>
    );
});

const TopUI = React.memo(() => {
    const pathname = useHistoryStore((state) => state.pathname);
    const isShowSelectPanel = useRightPanelStore((state) => state.isShow);
    const setIsShowRightPanel = useRightPanelStore((state) => state.setIsShow);
    const setIsOpenRightPanel = useRightPanelStore((state) => state.setIsOpen);

    useEffect(() => {
        const isObjetMode = pathname.startsWith("/objetcollection");

        if (pathname.startsWith("/home") && pathname.length > 5) {
            setIsShowRightPanel(true, false);
        } else if (pathname.startsWith("/objetcollection") && pathname.length > 5) {
            setIsShowRightPanel(true, true);
        } else {
            setIsShowRightPanel(isObjetMode, isObjetMode); //오브제컬랙션에서는 오른쪽 패널 계속 노출
            setIsOpenRightPanel(false, isObjetMode);
        }
    }, [pathname]);

    return (
        <Wrapper
            id="top-ui"
            style={{
                zIndex: Z_INDEX_TOP_UI,
            }}
        >
            <TopNavi isShowSelectPanel={isShowSelectPanel}/>
            <ObjetTitle/>
            <SelectPanel/>
        </Wrapper>
    );
});

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  color: white;
`;

export default TopUI;
