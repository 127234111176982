import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";
import SelectPanelToggleButton from "./SelectPanelToggleButton";
import { easeCubicOut } from "d3-ease";
import SelectInterior from "./SelectInterior";
import SelectProduct from "./SelectProduct";
import SelectProductColor from "./SelectProductColor";
import {
  IS_IPHONE,
  IS_MOBILE,
  ROOT_PATH_PREFIX,
  Z_INDEX_TOP_UI,
} from "../../common/const";
import useRightPanelStore, {
  RightPanelState,
} from "../../../stores/rightPanelStore";
import SelectObjetColor from "../../objetcollection/select_panel/SelectObjetColor";
import useObjetProductStore, {
  ObjetColorState,
  ObjetProductState,
  useObjetColorStore,
} from "../../../stores/objetProductStore";
import { Image } from "../../common/comps_styled/Image.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import useLangStore, { LangState, LangType } from "../../../stores/langStore";
import { getLangString, LangLabelType } from "../../../api/lang/langData";
import { DividerVetical } from "../../common/comps_styled/Divider.styled";
import theme from "../../../styles/theme.styled";
import { Center, Container } from "../../common/comps_styled/Container.styled";
import useResizeStore from "../../../stores/resizeStore";
import useHistoryStore from "../../../stores/historyStore";
import useProductStore from "../../../stores/productStore";
import { GA_Event } from "../../../api/ga";
// import useLangStore, { LangState } from "../../../stores/langStore";
// import { getLangString, LangLabelType } from "../../../api/lang/langData";

/**
 * 옵션 선택 패널
 * @constructor
 */

// percent
export const PANEL_WIDTH = IS_MOBILE ? 56 : 51;
export const TOGGLE_BUTTON_WIDTH = {
  open: IS_MOBILE ? 4.5 : 3.2,
  close: IS_MOBILE ? 16.5 : 13,
};

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const isShowSelector = (state: RightPanelState) => state.isShow;
const isOpenSelector = (state: RightPanelState) => state.isOpen;
const isObjetModeSelector = (state: RightPanelState) => state.isObjetMode;
const setIsOpenSelector = (state: RightPanelState) => state.setIsOpen;
const selectedCategorySelector = (state: ObjetProductState) =>
  state.selectedCategory;
const langSelector = (state: LangState) => state.lang;

const SelectPanel = React.memo(() => {
  const lang = useLangStore(langSelector);
  const isShow = useRightPanelStore(isShowSelector);
  const isObjetMode = useRightPanelStore(isObjetModeSelector);
  const isOpenPanel = useRightPanelStore(isOpenSelector);
  const setIsOpenPanel = useRightPanelStore(setIsOpenSelector);
  const panelWidth = useRightPanelStore((state) => state.panelWidth);
  const setPanelWidth = useRightPanelStore((state) => state.setPanelWidth);
  const objetData = useObjetProductStore(selectedCategorySelector);
  const productData = useProductStore((state) => state.selectedProduct);
  const ref = useRef<HTMLDivElement>(null!);
  const resize = useResizeStore((state) => state.resize);
  const pathname = useHistoryStore((state) => state.pathname);
  const selectedRoom = useProductStore((state) => state.selectedRoom);

  useEffect(() => {
    console.log("productData", productData);
  }, [productData]);

  //for GAEvent
  const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);
  const ga_objet_product = useObjetProductStore(
    (state) => state.selectedCategory?.ga_id
  );
  const isThinq = useMemo(() => pathname.includes("/thinq"), [pathname]);
  const isHome = useMemo(() => pathname.includes("/home"), [pathname]);
  const isObjet = useMemo(
    () => pathname.includes("/objetcollection"),
    [pathname]
  );
  const isLongTitle = useMemo(
    () => objetData?.label === "objet_category_tiiun",
    [objetData]
  );
  // useEffect(() => {
  //     console.log("lang test: ---> ", getLangString(lang, "select_interior"));
  // }, [lang]);

  useEffect(() => {
    if (isObjetMode) {
      if (objetData?.label !== "objet_category_refrigerator") {
        setPanelWidth(resize.ratio * (PANEL_WIDTH - 14.5), true);
      } else {
        setPanelWidth(resize.ratio * PANEL_WIDTH, true);
      }
    } else {
      setPanelWidth(resize.ratio * PANEL_WIDTH, false);
    }
  }, [objetData, isObjetMode, resize]);

  const spring = useSpring({
    right: !isShow
      ? -panelWidth - TOGGLE_BUTTON_WIDTH.close * resize.ratio
      : isOpenPanel
      ? 0
      : -panelWidth,
    border: isOpenPanel ? 2 : 0,
    config: {
      easing: easeCubicOut,
    },
    onStart: (result) => {
      if (isShow) ref.current.style.display = "inline-flex";
    },
    onRest: (result) => {
      ref.current.style.display = isShow ? "inline-flex" : "none";
    },
  });

  const springForToggle = useSpring({
    width:
      (isOpenPanel ? TOGGLE_BUTTON_WIDTH.open : TOGGLE_BUTTON_WIDTH.close) *
      resize.ratio,
    left: !isShow
      ? -TOGGLE_BUTTON_WIDTH.open * resize.ratio
      : (isOpenPanel ? -TOGGLE_BUTTON_WIDTH.open : -TOGGLE_BUTTON_WIDTH.close) *
        resize.ratio,
    config: {
      easing: easeCubicOut,
    },
  });

  return (
    <>
      <Wrapper
        ref={ref}
        w={panelWidth}
        style={{
          right: spring.right.to((value) => value + "em"),
          zIndex: Z_INDEX_TOP_UI + 100,
          borderTopLeftRadius: `${1.25 * resize.ratio}em`,
          borderBottomLeftRadius: `${1.25 * resize.ratio}em`,
        }}
      >
        <SelectPanelToggleButton
          isOpen={isOpenPanel}
          onClick={() => {
            // setIsOpen(prevState => !prevState);
            setIsOpenPanel(!isOpenPanel, isObjetMode);
            //
            isHome && GA_Event("lg_home", `${ga_product}_select`, "select_tab");
            isObjet &&
              ga_objet_product &&
              GA_Event("lg_objet", `${ga_objet_product}_select`, "select_tab");
          }}
          style={{
            position: "absolute",
            width: springForToggle.width.to((value) => value + "em") as any,
            left: springForToggle.left.to((value) => value + "em") as any,
            // right: springForToggle.right.to((value) => value + "em") as any,
          }}
        />
        {isObjetMode ? (
          <>
            {!objetData ? (
              <div
                className="option-container"
                style={{
                  padding: "5%",
                }}
              >
                <NoneSelected>
                  <Container
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      alt="choose product"
                      role="img"
                      src={
                        ROOT_PATH_PREFIX +
                        "/images/icons/svg/icon_choose_product.svg"
                      }
                      size="29%"
                      style={{ marginBottom: "11%" }}
                    />
                    <Text
                      align="center"
                      lineHeight={1.6}
                      weight={theme.font.weight.semibold}
                      size={`${(IS_MOBILE ? 2.75 : 2) * resize.ratio}em`}
                    >
                      {getLangString(lang, "objet_main_choose")?.text}
                    </Text>
                  </Container>
                </NoneSelected>
              </div>
            ) : (
              <div
                className="option-container"
                style={{ flex: "auto", flexShrink: 0 }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    width: "100%",
                    height: "auto",
                    marginTop: `${resize.ratio * 10}%`,
                    marginBottom: `${resize.ratio * (IS_MOBILE ? 15 : 0)}%`,
                    paddingLeft: "6%",
                    alignItems: "center",
                  }}
                >
                  <DividerVetical
                    borderwidth={resize.ratio * 3.4}
                    height={`${resize.ratio * 3.5}em`}
                    style={{ marginRight: "3%" }}
                  />

                  <Text
                    size={`${(IS_MOBILE ? 4 : 3) * resize.ratio}em`}
                    weight={theme.font.weight.semibold}
                  >
                    {
                      getLangString("en", objetData.label as LangLabelType)
                        ?.text
                    }
                  </Text>
                </div>
                {objetData?.colorData.map((datas, index) => {
                  return (
                    <SelectObjetColor
                      label={datas.label}
                      productsCategory={objetData.category}
                      colorCategory={datas.colorCategory}
                      colors={datas.colors}
                      order={index}
                      key={index}
                      ga_id={datas.ga_id}
                    />
                  );
                })}
              </div>
            )}
          </>
        ) : (
          <>
            {!productData ? (
              <div
                className="option-container"
                style={{
                  padding: "5%",
                }}
              >
                <NoneSelected>
                  <Container
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      alt="choose product"
                      role="img"
                      src={
                        ROOT_PATH_PREFIX +
                        "/images/icons/svg/icon_choose_product.svg"
                      }
                      size="29%"
                      style={{ marginBottom: "11%" }}
                    />
                    <Text
                      align="center"
                      lineHeight={1.6}
                      weight={theme.font.weight.semibold}
                      size={`${(IS_MOBILE ? 2.75 : 2) * resize.ratio}em`}
                    >
                      {getLangString(lang, "home_main_choose")?.text}
                    </Text>
                  </Container>
                </NoneSelected>
              </div>
            ) : (
              <div className="option-container">
                <Container
                  style={{
                    padding: "0 4%",
                    height: "100%",
                  }}
                >
                  <SelectInterior
                    style={{
                      flex: 0.25,
                      paddingTop: "7%",
                    }}
                  />
                  <SelectProduct
                    style={{
                      flex: 0.5,
                      paddingTop: IS_MOBILE ? "3%" : "7%",
                    }}
                  />
                  <SelectProductColor
                    style={{
                      flex: 0.25,
                      paddingTop: IS_MOBILE ? "3%" : "7%",
                    }}
                  />
                </Container>
              </div>
            )}
          </>
        )}
      </Wrapper>
    </>
  );
});

const Wrapper = styled(animated.div)<
  { w?: number; ismobile: boolean | undefined; selected: boolean } & Record<
    string,
    any
  >
>`
  width: ${({ w }) => w}em;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  color: #000;
  display: none;

  // border: 1px solid tomato;

  .option-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: #fff;
    width: 100%;
    height: 100%;
  }
`;

const NoneSelected = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.objet.lightgray};
  border-radius: 0.8em;
`;

export default SelectPanel;
