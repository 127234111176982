import { animated } from "@react-spring/web";
import styled from "styled-components";

export const Image = styled(animated.img)<{
  resizemode?: string;
  size?: string;
  width?: any;
  height?: any;
}>`
  object-fit: ${({ resizemode }) => resizemode || "contain"};
  width: ${({ size, width }) => size || width || "100%"};
  height: ${({ size, height }) => size || height || "100%"};
`;
