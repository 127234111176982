/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_NaturalRoom} from "./NaturalRoomFloor";


type GLTFResult = GLTF & {
    nodes: {
        Plane021: THREE.Mesh
        c_sofa_module_01: THREE.Mesh
        Plane022: THREE.Mesh
        Plane020: THREE.Mesh
        c_sofa_module_02: THREE.Mesh
    }
    materials: {
        sofa_module_02_B: THREE.MeshStandardMaterial
        c_mat_sofa_module_01: THREE.MeshStandardMaterial
        sofa_module_02_C: THREE.MeshStandardMaterial
        sofa_module_02_A: THREE.MeshStandardMaterial
    }
}

type ModelProps = {
    interiorIdx?: number;
} & JSX.IntrinsicElements['group'];


// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_sofa.glb';
const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_sofa_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_sofa_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Sofa_Bake1_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Sofa_Bake1_cyclesbake_DIFFUSE.jpg');

export default function Model({interiorIdx = 0, ...props}: ModelProps) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    useLayoutEffect(()=>{
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

        // materials.c_mat_sofa_module_01.lightMapIntensity = 5;
        // materials.c_mat_sofa_module_02.lightMapIntensity = 5;
        materials.c_mat_sofa_module_01.lightMapIntensity = 8;
        materials.sofa_module_02_A.lightMapIntensity = 5;
        materials.sofa_module_02_B.lightMapIntensity = 5;
        materials.sofa_module_02_C.lightMapIntensity = 5;

    }, [lightmapTex]);

    useEffect(()=>{
        return ()=>{
            disposeScene(scene);
            lightmapTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
        }
    }, []);


    const sofaMat = useMemo(()=>{
        if(interiorIdx === 0) return materials.sofa_module_02_A;
        else if(interiorIdx === 1) return materials.sofa_module_02_B;
        else return materials.sofa_module_02_C;
    }, [interiorIdx]);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.Plane021.geometry}
                material={materials.sofa_module_02_B}
                position={[-3.2006, -8.7426, 0.3951]}
            />
            <mesh
                geometry={nodes.c_sofa_module_01.geometry}
                material={materials.c_mat_sofa_module_01}
                position={[-1.243, 0.3115, -0.8415]}
            />
            <mesh
                geometry={nodes.Plane022.geometry}
                material={materials.sofa_module_02_C}
                position={[-3.2006, -8.7426, 1.6449]}
            />
            <mesh
                geometry={nodes.Plane020.geometry}
                material={nodes.Plane020.material}
                position={[-3.2006, -8.7426, -0.8209]}
            />
            <mesh
                geometry={nodes.c_sofa_module_02.geometry}
                material={sofaMat}
                position={[-1.1225, 0.305, -1.2297]}
            />
        </group>
    )
}
