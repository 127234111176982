//-------------------------------------------- Calming
import {ProductColorType} from "../productsData";


//Air Conditioner
export const AirConditiner_Calming_Beige: ProductColorType = {
    name: <>Calming<br/>Beige</>,
    label: 'objet_color_beige',
    color: '/images/objet/calming/air_conditioner/beige.png',
    ga_id: "beige"
};
export const AirConditioner_Calming_Green: ProductColorType = {
    name: <>Calming<br/>Green</>,
    label: 'objet_color_green',
    color: '/images/objet/calming/air_conditioner/green.png',
    ga_id: "green"
};

//Vacuum Cleaner
export const VacuumCleaner_Calming_Beige: ProductColorType = {
    name: <>Calming<br/>Beige</>,
    label: 'objet_color_beige',
    color: '/images/objet/calming/vacuum_cleaner/beige.png',
    ga_id: "beige"
};
export const VacuumCleaner_Calming_Green: ProductColorType = {
    name: <>Calming<br/>Green</>,
    label: 'objet_color_green',
    color: '/images/objet/calming/vacuum_cleaner/green.png',
    ga_id: "green"
};

//Water Purifier
export const WaterPurifier_Calming_Beige: ProductColorType = {
    name: <>Calming<br/>Beige</>,
    label: 'objet_color_beige',
    color: '/images/objet/calming/water_purifier/beige.png',
    ga_id: "beige"
};
export const WaterPurifier_Calming_Cotton_Sky: ProductColorType = {
    name: <>Calming<br/>Cotton Sky</>,
    label: 'objet_color_cotton_sky',
    color: '/images/objet/calming/water_purifier/cotton_sky.png',
    ga_id: "cotton_sky"
};
export const WaterPurifier_Calming_Green: ProductColorType = {
    name: <>Calming<br/>Green</>,
    label: 'objet_color_green',
    color: '/images/objet/calming/water_purifier/green.png',
    ga_id: "green"
};
export const WaterPurifier_Calming_Pale_Rose: ProductColorType = {
    name: <>Calming<br/>Pale Rose</>,
    label: 'objet_color_pale_rose',
    color: '/images/objet/calming/water_purifier/pale_rose.png',
    ga_id: "pale_rose"
};
