import React from "react";
import styled from "styled-components";
import useResizeStore from "../../../stores/resizeStore";
import { Image } from "../comps_styled/Image.styled";
import { Text } from "../comps_styled/Text.styled";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../const";
import useLangStore from "../../../stores/langStore";
import { getLangString, LangLabelType } from "../../../api/lang/langData";

type Props = {
  showLabel?: boolean;
  rounded?: boolean;
} & JSX.IntrinsicElements["button"];

const CloseButton = ({ showLabel = true, rounded = false, ...rest }: Props) => {
  const resize = useResizeStore((state) => state.resize);
  const lang = useLangStore((state) => state.lang);

  return (
    <Button {...rest}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {showLabel && (
          <>
            <Text
              color="white"
              size={`${resize.ratio * (IS_MOBILE ? 3.3 : 1.65)}em`}
              style={{
                height: "100%",
              }}
            >
              {getLangString(lang, "close")?.text}
            </Text>
            <div style={{ width: `${resize.ratio * 1}em` }} />
          </>
        )}
        <Image
          alt="close"
          role="img"
          width={`${resize.ratio * (IS_MOBILE ? 6.2 : 3.1)}em`}
          height={`${resize.ratio * (IS_MOBILE ? 6.2 : 3.1)}em`}
          src={
            ROOT_PATH_PREFIX +
            (rounded
              ? "/images/icons/close_icon_rounded.png"
              : "/images/icons/close_icon.png")
          }
        />
      </div>
    </Button>
  );
};

const Button = styled.button<Record<string, any>>`
  position: relative;
  right: 0;
  padding: 0;
  background-color: transparent;
  pointer-events: visible;
  cursor: pointer;
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 1));
`;

export default CloseButton;
