import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ROOT_PATH_PREFIX } from "../../common/const";
import { Image } from "../../common/comps_styled/Image.styled";
import theme from "../../../styles/theme.styled";
import useResizeStore from "../../../stores/resizeStore";
import Vimeo from "@u-wave/react-vimeo";
import useHistoryStore from "../../../stores/historyStore";
import { GA_Event } from "../../../api/ga";

let needGa = 5;
type Props = {
  isClosed: boolean;
};
const ZepetoContents = ({ isClosed }: Props) => {
  const goBack = useHistoryStore((state) => state.goBack);
  const resize = useResizeStore((state) => state.resize);

  useEffect(() => {
    if (isClosed) {
      GA_Event("ha_virtual_studio", "lghome_in_metaverse_video", needGa + "%");
      goBack();
    }
  }, [isClosed]);

  const startTime = () => {
    needGa = 5;
  };

  const updateTime = (e) => {
    if (needGa < e.percent * 100)
      needGa = Math.floor((e.percent * 100) / 5) * 5;
  };

  const endTime = () => {
    GA_Event("ha_virtual_studio", "lghome_in_metaverse_video", "99%");
  };

  return (
    <Wrapper>
      <div
        style={{
          position: "relative",
          top: `${resize.ratio * 17}em`,
          left: `${resize.ratio * 41.45}em`,
          width: `${653 * resize.ratio}px`,
          height: `${366 * resize.ratio}px`,
          backgroundColor: "black",
        }}
      >
        <Vimeo
          video={"https://vimeo.com/660651114/9117fd2b61"}
          width={`${653 * resize.ratio}px`}
          height={`${366 * resize.ratio}px`}
          autoplay={true}
          loop={false}
          onPlay={startTime}
          onTimeUpdate={updateTime}
          onEnd={endTime}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* flex-direction: column; */

  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  /* border: 1px solid red; */
`;

export default ZepetoContents;
