/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {useGLTF} from "@react-three/drei";

type GLTFResult = GLTF & {
    nodes: {
        Plane: THREE.Mesh
        Plane001: THREE.Mesh
        utopia3: THREE.Mesh
        ['21_Metal']: THREE.Mesh
        Logo: THREE.Mesh
        ['21_Door009']: THREE.Mesh
    }
    materials: {
        MetalSilver: THREE.MeshStandardMaterial
        MetalBlack: THREE.MeshStandardMaterial
        ['18_Body']: THREE.MeshStandardMaterial
        ['21_Metal.001']: THREE.MeshStandardMaterial
        ['21_Door.009']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/microwave/m/utopia_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/microwave/utopia_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/microwave/utopia.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;
    // const {nodes, materials, scene} = useGLTF<GLTFResult>(GLTF_URL) as GLTFResult;

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct && selectedProduct?.modelNo === 'utopia'){
            if(selectedColorIndex === 1){
                mesh1_Ref.current.material = materials.MetalSilver;
            }
            else{
                mesh1_Ref.current.material = materials.MetalBlack;
            }
            mesh1_Ref.current.material.needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        mesh1_Ref.current.material = materials.MetalBlack;

        materials['21_Metal.001'].metalnessMap = materials['21_Metal.001'].map;
        materials['21_Metal.001'].emissiveIntensity = 3;
        materials['21_Metal.001'].roughness = 0.5;
        materials['21_Metal.001'].metalness = 0;
        materials['21_Metal.001'].needsUpdate = true;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0.008, -0.012, 0.27]} scale={[1.01,1,1]}>
            <mesh
                geometry={nodes.Plane001.geometry}
                material={materials.MetalBlack}
                userData={{ name: 'Plane.001' }}
            />
            <mesh
                geometry={nodes.utopia3.geometry}
                material={materials['18_Body']}
                userData={{ name: 'utopia3' }}>
                <mesh
                    ref={mesh1_Ref}
                    geometry={nodes['21_Metal'].geometry}
                    material={nodes['21_Metal'].material}
                    userData={{ name: '21_Metal' }}
                />
                <mesh
                    geometry={nodes.Logo.geometry}
                    material={materials['21_Metal.001']}
                    position={[0, 0, 0.0018]}
                    userData={{ name: 'Logo' }}
                />
                <mesh
                    geometry={nodes['21_Door009'].geometry}
                    material={materials['21_Door.009']}
                    userData={{ name: '21_Door.009' }}
                />
            </mesh>
            <mesh
                geometry={nodes.Plane.geometry}
                material={nodes.Plane.material}
                userData={{ name: 'Plane' }}
            />

            <mesh position={[-0.005, 0.47, -0.215]}>
                <boxBufferGeometry args={[0.76, 0.1, 0.3]} />
                <meshStandardMaterial color={"#111"} />
            </mesh>
        </group>
    )
}

