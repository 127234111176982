/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Glass: THREE.Mesh
        Inner: THREE.Mesh
        WT_Part2_White: THREE.Mesh
        WT_Part2_Black: THREE.Mesh
        Inner001: THREE.Mesh
        WT_Part1_Black: THREE.Mesh
        Mesh003: THREE.Mesh
        Mesh003_1: THREE.Mesh
        WT_Part1_White: THREE.Mesh
        Mesh001: THREE.Mesh
        Mesh001_1: THREE.Mesh
    }
    materials: {
        ['Glass.002']: THREE.MeshStandardMaterial
        ['WT_Part1.001']: THREE.MeshStandardMaterial
        WT_Part2_White: THREE.MeshStandardMaterial
        WT_Part2_Black: THREE.MeshStandardMaterial
        ['WT_Part2.001']: THREE.MeshStandardMaterial
        WT_Part1_Black: THREE.MeshStandardMaterial
        WT_Part2: THREE.MeshStandardMaterial
        WT_Part1: THREE.MeshStandardMaterial
        WT_Part1_White: THREE.MeshStandardMaterial
        WT_Part1_Decal: THREE.MeshStandardMaterial
        WT_Part2_Decal: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/m/washtower_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/washtower_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/washing_machine/washtower_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const part1Ref = useRef<THREE.Mesh>(null!);
    const part2Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'washtower') {
            materials['Glass.002'].color.setHex(0x666666);
            materials['Glass.002'].opacity = 0.1;
            materials['Glass.002'].roughness = 0;

            materials['WT_Part1.001'].metalness = 1;
            materials['WT_Part1.001'].roughness = 0;
            materials['WT_Part2.001'].metalness = 1;
            materials['WT_Part2.001'].roughness = 0;
            materials['WT_Part1.001'].emissiveIntensity = 0.15;
            materials['WT_Part2.001'].emissiveIntensity = 0.15;

            if (selectedColorIndex === 0) {
                materials.WT_Part1_Black.metalness = 0;
                materials.WT_Part1_Black.roughness = 0.5;
                materials.WT_Part1_Black.envMapIntensity = 2;
                materials.WT_Part1_Black.color.setRGB(1,1,1);
                materials.WT_Part1_Black.needsUpdate = true;

                materials.WT_Part2_Black.metalness = 0;
                materials.WT_Part2_Black.roughness = 0.5;
                materials.WT_Part2_Black.envMapIntensity = 2;
                materials.WT_Part2_Black.color.setRGB(1,1,1);
                materials.WT_Part2_Black.needsUpdate = true;

                part1Ref.current.material = materials.WT_Part1_Black;
                part2Ref.current.material = materials.WT_Part2_Black;
            } else if (selectedColorIndex === 1) {
                materials.WT_Part1_White.metalness = 0;
                materials.WT_Part1_White.roughness = 0.5;
                materials.WT_Part1_White.envMapIntensity = 3;
                materials.WT_Part1_White.color.setRGB(1,1,1);
                materials.WT_Part1_White.needsUpdate = true;

                materials.WT_Part2_White.metalness = 0;
                materials.WT_Part2_White.roughness = 0.5;
                materials.WT_Part2_White.envMapIntensity = 3;
                materials.WT_Part2_White.color.setRGB(1,1,1);
                materials.WT_Part2_White.needsUpdate = true;

                part1Ref.current.material = materials.WT_Part1_White;
                part2Ref.current.material = materials.WT_Part2_White;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        materials['Glass.002'].color.setHex(0x666666);
        materials['Glass.002'].opacity = 0.1;
        materials['Glass.002'].roughness = 0;

        materials['WT_Part1.001'].metalness = 1;
        materials['WT_Part1.001'].roughness = 0;
        materials['WT_Part2.001'].metalness = 1;
        materials['WT_Part2.001'].roughness = 0;
        materials['WT_Part1.001'].emissiveIntensity = 0.15;
        materials['WT_Part2.001'].emissiveIntensity = 0.15;

        materials.WT_Part1_Black.metalness = 0;
        materials.WT_Part1_Black.roughness = 0.5;
        materials.WT_Part1_Black.envMapIntensity = 2;
        materials.WT_Part1_Black.color.setRGB(1,1,1);
        materials.WT_Part1_Black.needsUpdate = true;

        materials.WT_Part2_Black.metalness = 0;
        materials.WT_Part2_Black.roughness = 0.5;
        materials.WT_Part2_Black.envMapIntensity = 2;
        materials.WT_Part2_Black.color.setRGB(1,1,1);
        materials.WT_Part2_Black.needsUpdate = true;

        part1Ref.current.material = materials.WT_Part1_Black;
        part2Ref.current.material = materials.WT_Part2_Black;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} dispose={null} position={[0, 0, 0.34]} scale={[1, 0.97, 0.97]}>
            <mesh
                geometry={nodes.Glass.geometry}
                material={materials['Glass.002']}
                userData={{ name: 'Glass' }}
            />
            <mesh
                geometry={nodes.Inner.geometry}
                material={materials['WT_Part1.001']}
                userData={{ name: 'Inner' }}
            />
            <mesh
                geometry={nodes.WT_Part2_White.geometry}
                material={materials.WT_Part2_White}
                userData={{ name: 'WT_Part2_White' }}
            />
            <mesh
                geometry={nodes.WT_Part2_Black.geometry}
                material={materials.WT_Part2_Black}
                userData={{ name: 'WT_Part2_Black' }}
            />
            <mesh
                geometry={nodes.Inner001.geometry}
                material={materials['WT_Part2.001']}
                userData={{ name: 'Inner.001' }}
            />
            <mesh
                geometry={nodes.WT_Part1_Black.geometry}
                material={materials.WT_Part1_Black}
                userData={{ name: 'WT_Part1_Black' }}
            />
            <group userData={{ name: 'Outer' }}>
                <mesh geometry={nodes.Mesh003.geometry} material={materials.WT_Part2} ref={part2Ref} />
                <mesh geometry={nodes.Mesh003_1.geometry} material={materials.WT_Part1} ref={part1Ref} />
            </group>
            <mesh
                geometry={nodes.WT_Part1_White.geometry}
                material={materials.WT_Part1_White}
                userData={{ name: 'WT_Part1_White' }}
            />
            <group position={[0.0008, 0, 0.0346]} userData={{ name: 'Decal' }}>
                <mesh geometry={nodes.Mesh001.geometry} material={materials.WT_Part1_Decal} />
                <mesh geometry={nodes.Mesh001_1.geometry} material={materials.WT_Part2_Decal} />
            </group>
        </group>
    )
}
