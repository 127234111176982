/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene, setupScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {Shadow} from "@react-three/drei";

type GLTFResult = GLTF & {
    nodes: {
        Mesh001: THREE.Mesh
        Mesh001_1: THREE.Mesh
        Mesh001_2: THREE.Mesh
        Mesh001_3: THREE.Mesh
    }
    materials: {
        ['ac_04.001']: THREE.MeshStandardMaterial
        ['Mask_case_body.001']: THREE.MeshStandardMaterial
        ['Cover_button.001']: THREE.MeshStandardMaterial
        ['UI.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/wearable_air_purifier/m/wap_1nd_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/wearable_air_purifier/wap_1nd_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);

    useEffect(()=>{
        scene.traverse((child)=>{
            if(child.type === 'Mesh'){
                const mesh = child as THREE.Mesh;
                const mat = mesh.material as THREE.MeshStandardMaterial;
                if(!mat.transparent) {
                    mat.envMapIntensity = 1;
                }
            }
        })
        materials['ac_04.001'].envMapIntensity = 4;
        materials['Mask_case_body.001'].map = null;
        materials['Mask_case_body.001'].color.setRGB(1,1,1);
        materials['Mask_case_body.001'].envMapIntensity = 8;
        materials['Cover_button.001'].envMapIntensity = 2;
    }, []);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <group
                position={[-0.18, 0.0472, 0.0587]}
                rotation={[0, 1.5705, 0]}
                scale={[0.0111, 0.0111, 0.0111]}
                userData={{ name: 'Mask_Old' }}>
                <mesh geometry={nodes.Mesh001.geometry} material={materials['ac_04.001']} />
                <mesh geometry={nodes.Mesh001_1.geometry} material={materials['Mask_case_body.001']} />
                <mesh geometry={nodes.Mesh001_2.geometry} material={materials['Cover_button.001']} />
                <mesh geometry={nodes.Mesh001_3.geometry} material={materials['UI.001']} />
            </group>
            <Shadow rotation-x={-Math.PI / 2} scale={0.25} position={[-0.17, 0, -0.08]} opacity={0.2}/>
            <Shadow rotation-x={-Math.PI / 2} scale={0.25} position={[0.1, 0, -0.08]} opacity={0.2}/>
        </group>
    )
}
