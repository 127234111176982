import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useHistoryStore from "../../../stores/historyStore";
import AwardsContents from "./AwardsContents";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../../common/const";
import useResizeStore from "../../../stores/resizeStore";
import CloseButton from "../../common/navigation/CloseButton";
import { GA_Event } from "../../../api/ga";

const POPUP_SIZE_W = 1481;
const POPUP_SIZE_H = 843;

const AwardsPanel = () => {
  const goBack = useHistoryStore((state) => state.goBack);
  const [isReady, setIsReady] = useState(false);
  const resize = useResizeStore((state) => state.resize);

  useEffect(() => {
    window.dispatchEvent(new Event("resize"));
    setIsReady(true);
  }, []);

  return (
    <Wrapper
      ratio={resize.ratio}
      style={{
        display: isReady ? "flex" : "none",
        position: "relative",
      }}
    >
      <CloseButton
        style={{
          position: "absolute",
          zIndex: Z_INDEX_POPUP,
          right: "4%",
          top: "5.5%",
        }}
        onClick={() => {
          GA_Event("ha_virtual_studio", "best_of_innovation_menu", "close");
          goBack();
        }}
      />
      <AwardsContents />
    </Wrapper>
  );
};

const Wrapper = styled.div<{ ratio: number }>`
  width: ${({ ratio }) => POPUP_SIZE_W * ratio * (IS_MOBILE ? 1.05 : 1)}px;
  height: ${({ ratio }) => POPUP_SIZE_H * ratio * (IS_MOBILE ? 1.05 : 1)}px;
  /* left: ${({ ratio }) => (IS_MOBILE ? 9 : 6) * ratio}em; */
  left: ${({ ratio }) =>
    (window.innerWidth - POPUP_SIZE_W * ratio * (IS_MOBILE ? 1.05 : 1)) / 2}px;
  top: ${({ ratio }) => (IS_MOBILE ? 6 : 20.5) * ratio}em;
  background: #000;
  background-image: url(${ROOT_PATH_PREFIX +
  "/images/innovation_awards/award_background.png"});
  background-size: 100%;
  background-repeat: no-repeat;

  filter: drop-shadow(0 0 1em rgba(0, 0, 0, 0.5));

  display: none;
  flex-direction: column;
  align-items: flex-end;
`;

export default AwardsPanel;
