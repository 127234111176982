/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {dishwasher_data, Objet_ColorCategoryType} from "../../../../api/products/objetProductsData";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {LangLabelType} from "../../../../api/lang/langData";
import * as THREE from "three";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {a} from "@react-spring/three";
import {animated, useSpring} from "@react-spring/web";
import {easeCubicInOut} from "d3-ease";
import {useModalTutorial} from "../../../../stores/modalStore";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "./Product_Harvester";
import useHistoryStore from "../../../../stores/historyStore";
import ObjetPlusButton from "../../ObjetPlusButton";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
// const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/r9/Lightmap_R9.jpg';

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/dishwasher/beige_mat_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/dishwasher/beige_mat_body_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/dishwasher/green_mat_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/dishwasher/green_mat_body_BaseColor.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/dishwasher/silver_mat_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/dishwasher/silver_mat_body_BaseColor.jpg');


export default function Model(props) {
    const group = useRef()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.dishwasher_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.dishwasher_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const changeTexture = (materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsSet(true);

        if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_beige') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials["mat_body.003"].map = colorTextures[0];
            }
        } else if (colorCategory === 'Solid') {
            if (colorLabel === 'objet_color_green') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials["mat_body.003"].map = colorTextures[1];
            } else if (colorLabel === 'objet_color_silver') {
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials[`${materialName}`].color.setRGB(0.8, 0.8, 1);
                materials["mat_body.003"].map = colorTextures[2];
            }
        }
    };

    const pathname = useHistoryStore(state => state.pathname);
    const isActiveLnF = useMemo(() => pathname.indexOf('lansen_freezer') > -1, [pathname]);

    useLayoutEffect(() => {
        changeTexture('mat_body.003', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useLayoutEffect(() => {
        materials["mat_body.003"].envMapIntensity = 3;
        materials["mat_body.003"].roughness = 0.6;
        materials["mat_body.003"].metalness = 0.8;

        changeTexture('mat_body.003', "Nature", "objet_color_beige", true);
    }, []);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL
            ]);
        }
    }, []);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);

    return (
        <group ref={group} {...props} visible={!isActiveLnF} dispose={null}>
            <mesh
                geometry={nodes.dishwasher.geometry}
                material={materials['mat_body.003']}
                position={[2.6818, 0.4358, -1.5621]}
            >
                <mesh geometry={nodes.Decal004.geometry} material={materials['mat_decal.001']}/>
            </mesh>

            <ObjetProductContainer3D
                args={[0.6, 0.82, 0.01]}
                position={[2.6818, 0.86, -1.2]}
                data={dishwasher_data}
                showDebug={false}
                cameraTargetPos={[0, 0.3, 2]}
            />

            <mesh
                geometry={nodes.dishwasher.geometry}
                material={materials['mat_body.003']}
                position={[2.6818, 0.4358, -1.5621]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.45, 0.35]} visible={!(isSet || isActiveLnF)} opacity={spring.opacity}
                                 color={pathname.indexOf('/dishwasher') > -1 ? '#be1e42' : '#000'}/>
            </mesh>
        </group>
    )
}
