import React, {useRef} from 'react';
import {useTexture} from "@react-three/drei";
import {ROOT_PATH_PREFIX} from "../common/const";
import {MathUtils, SpriteMaterial} from "three";
import {SpringValue} from "@react-spring/web";
import {a} from "@react-spring/three";


type ObjetPlusButtonProps = {
    position?: [x: number, y: number, z: number];
    opacity: SpringValue<number>;
    color?: string;
} & JSX.IntrinsicElements['group'];

const ObjetPlusButton = ({
                             position = [0, 0, 0],
                             opacity,
                             color = '#000',
                             ...props
                         }: ObjetPlusButtonProps) => {

    const tex = useTexture(ROOT_PATH_PREFIX + '/RP/rooms/natural/map/btn_close-up_default.png');
    const matRef = useRef<SpriteMaterial>(null!);

    return (
        <group
            position={[0 + position[0], 0 + position[1], 0 + position[2]]}
            {...props}
        >
            <sprite scale={0.04} renderOrder={1}>
                {/*@ts-ignore*/}
                <a.spriteMaterial map={tex} depthWrite={true} depthTest={false} sizeAttenuation={false}
                                  transparent={true} opacity={opacity} color={color} ref={matRef}/>
            </sprite>
        </group>
    );
};

export default ObjetPlusButton;
