/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "../../components/objetcollection/room/natural/Product_Harvester";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {TextureLoader} from "three";

type GLTFResult = GLTF & {
    nodes: {
        xd3_steam_back: THREE.Mesh
        xd3_steam_body: THREE.Mesh
        xd3_steam_decal: THREE.Mesh
    }
    materials: {
        ['mat_black.001']: THREE.MeshStandardMaterial
        ['DW_04_Albedo.001']: THREE.MeshStandardMaterial
        DW_04_Albedo_Decal: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/m/xd3_steam_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/xd3_steam_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd3_steam_ktx2.glb';
const AO_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd3_steam_AO.jpg';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const aoTex = useTexture(AO_URL);

    useLayoutEffect(() => {
        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        materials['DW_04_Albedo.001'].aoMap = aoTex;
        materials['DW_04_Albedo.001'].aoMapIntensity = 2;

    }, [aoTex]);

    useEffect(() => {

        materials.DW_04_Albedo_Decal.emissive.setHex(0xffffff);
        materials.DW_04_Albedo_Decal.emissiveMap = materials.DW_04_Albedo_Decal.map;
        materials.DW_04_Albedo_Decal.emissiveIntensity = 1;
        materials.DW_04_Albedo_Decal.roughness = 1;

        return () => {
            disposeScene(scene);
            aoTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, AO_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[1.805, 0.005, 3.1]}
               scale={[1.01, 1, 1]}
        >
            <mesh
                geometry={nodes.xd3_steam_back.geometry}
                material={materials['mat_black.001']}
                position={[-1.79, -0.03, -2.79]}
            />
            <mesh
                geometry={nodes.xd3_steam_body.geometry}
                material={materials['DW_04_Albedo.001']}
                position={[-1.79, -0.03, -2.8]}
            />
            <mesh
                geometry={nodes.xd3_steam_decal.geometry}
                material={materials.DW_04_Albedo_Decal}
                position={[-1.79, 0.4, -2.82]}
            />
            <mesh position={[-1.79, 0.3, -3.6]}>
                <boxBufferGeometry args={[1, 1, 1]}/>
                <meshBasicMaterial color={'#000'}/>
            </mesh>
        </group>
    )
}
