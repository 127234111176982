import {SpecboardsDataItemType} from "./specboardData";
import {Sup} from "../../components/common/comps_styled/Text.styled";
import React from "react";

/**
 * 식물재배기 스펙보드 데이터
 */
const specboardsData_Tiiun: SpecboardsDataItemType = {
  category: "tiiun",
  specboardList: [
    {
      name: "LG tiiun",
      modelNo: "tiiun",
      specboard_img_url: "/images/products/tiiun/tiiun/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "LG tiiun mini",
      modelNo: "tiiun_mini",
      specboard_img_url: "/images/products/tiiun/tiiun_mini/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
  ],
};
export default specboardsData_Tiiun;
