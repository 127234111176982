/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {DRACOLoader, GLTFLoader} from "three-stdlib";
import {useEquirectEXR} from "../../../../utils/useEquirect";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import useProductStore from "../../../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        a_props_basket001: THREE.Mesh
        d_ceiling_light001: THREE.Mesh
        c_door001: THREE.Mesh
        d_ceiling_light002: THREE.Mesh
        d_ceiling_light_glass001: THREE.Mesh
        glass001: THREE.Mesh
        c_wall_03001: THREE.Mesh
        b_props_module_02001: THREE.Mesh
        d_ceiling_light_deco001: THREE.Mesh
        b_furniture_02001: THREE.Mesh
        b_furniture_02003: THREE.Mesh
        a_furniture_01_marble001: THREE.Mesh
        b_furniture_02_shelf001: THREE.Mesh
        d_floor001: THREE.Mesh
        d_props_towel001: THREE.Mesh
        a_props_module_01001: THREE.Mesh
    }
    materials: {
        mat_props_basket: THREE.MeshStandardMaterial
        ['mat_metal.002']: THREE.MeshStandardMaterial
        mat_door: THREE.MeshStandardMaterial
        mat_led: THREE.MeshStandardMaterial
        mat_glass: THREE.MeshStandardMaterial
        mat_wall_01: THREE.MeshStandardMaterial
        mat_props_module_02: THREE.MeshStandardMaterial
        mat_ceiling_light_deco: THREE.MeshStandardMaterial
        mat_cerused_maple_wood: THREE.MeshStandardMaterial
        mat_yule_marble: THREE.MeshStandardMaterial
        mat_cerused_maple_wood_2: THREE.MeshStandardMaterial
        mat_floor: THREE.MeshStandardMaterial
        mat_towel: THREE.MeshStandardMaterial
        mat_props_module_01: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/m/laundry_vivid_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/laundry_vivid_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/laundry/laundry_vivid_ktx2.glb';
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_cyclesbake_DIFFUSE_m.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_cyclesbake_DIFFUSE.exr');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_cyclesbake_Environment_01.exr';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    // @ts-ignore
    const lightmapTex = useLoader(EXRLoader, LIGHT_MAP_URL, (loader: EXRLoader) => {
        if (IS_MOBILE) {
            loader.setDataType(THREE.HalfFloatType);
        } else {
            loader.setDataType(THREE.FloatType);
        }
    });
    const envTex = useEquirectEXR(ENV_URL);
    const gl = useThree(state => state.gl);
    const scene2 = useThree(state => state.scene);

    useLayoutEffect(()=>{
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;

        scene2.environment = envTex;
        gl.toneMappingExposure = 1;

        setupScene(scene, lightmapTex);
        materials.mat_cerused_maple_wood.lightMapIntensity = 1;
        materials.mat_yule_marble.lightMapIntensity = 2;
        materials.mat_led.emissiveIntensity = 10;
        materials["mat_metal.002"].envMapIntensity = 3;

    }, [lightmapTex, envTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            envTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    const selectedCategory = useProductStore(state => state.selectedCategory);
    const selectedProduct = useProductStore(state => state.selectedProduct);
    const dummyPlaneRef = useRef<THREE.Mesh>(null!);
    const dummyPlaneRef2 = useRef<THREE.Mesh>(null!);   //바닥용
    useEffect(()=> {
        if(selectedProduct && selectedCategory)
        {
            if(selectedCategory.category === 'styler'){
                dummyPlaneRef.current.visible = selectedProduct.modelNo !== 'baron';
            }
            else if(selectedCategory.category === 'washer_n_dryer')
            {
                dummyPlaneRef2.current.visible = selectedProduct.modelNo === 'washtower';
            }
        }
    }, [selectedProduct]);


    return (
        <group ref={group} {...props} dispose={null}>
            <group
                position={[10, 0, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Laundry_Room' }}>
                <group userData={{ name: 'HA_LifeStyle_LaundryRoom' }}>
                    <group userData={{ name: 'HA_LifeStyle_Object' }}>
                        <mesh
                            geometry={nodes.a_props_basket001.geometry}
                            material={materials.mat_props_basket}
                            userData={{ name: 'a_props_basket.001' }}
                        />
                    </group>
                </group>
            </group>
            <mesh
                geometry={nodes.d_ceiling_light001.geometry}
                material={materials['mat_metal.002']}
                userData={{ name: 'd_ceiling_light.001' }}
            />
            <mesh
                geometry={nodes.c_door001.geometry}
                material={materials.mat_door}
                userData={{ name: 'c_door.001' }}
            />
            <mesh
                geometry={nodes.d_ceiling_light002.geometry}
                material={materials.mat_led}
                userData={{ name: 'd_ceiling_light.002' }}
            />
            <mesh
                geometry={nodes.d_ceiling_light_glass001.geometry}
                material={nodes.d_ceiling_light_glass001.material}
                userData={{ name: 'd_ceiling_light_glass.001' }}
            />
            <mesh
                geometry={nodes.glass001.geometry}
                material={nodes.glass001.material}
                userData={{ name: 'glass.001' }}
            />
            <mesh
                geometry={nodes.c_wall_03001.geometry}
                material={materials.mat_wall_01}
                userData={{ name: 'c_wall_03.001' }}
            />
            <mesh
                geometry={nodes.b_props_module_02001.geometry}
                material={materials.mat_props_module_02}
                userData={{ name: 'b_props_module_02.001' }}
            />
            <mesh
                geometry={nodes.d_ceiling_light_deco001.geometry}
                material={materials.mat_ceiling_light_deco}
                userData={{ name: 'd_ceiling_light_deco.001' }}
            />
            <mesh
                geometry={nodes.b_furniture_02001.geometry}
                material={nodes.b_furniture_02001.material}
                userData={{ name: 'b_furniture_02.001' }}
            />
            <mesh
                geometry={nodes.b_furniture_02003.geometry}
                material={nodes.b_furniture_02003.material}
                userData={{ name: 'b_furniture_02.003' }}
                position={[-2.56, 0, -0.05]}
                scale={[1.63, 1, 1]}
                ref={dummyPlaneRef2}
            />
            <mesh
                ref={dummyPlaneRef}
                position={[3.125, 1, -4.65]}
                material={nodes.b_furniture_02003.material}>
                <boxBufferGeometry args={[0.11,2.1,1]} />
            </mesh>
            <mesh
                geometry={nodes.a_furniture_01_marble001.geometry}
                material={materials.mat_yule_marble}
                userData={{ name: 'a_furniture_01_marble.001' }}
            />
            <mesh
                geometry={nodes.b_furniture_02_shelf001.geometry}
                material={materials.mat_cerused_maple_wood_2}
                userData={{ name: 'b_furniture_02_shelf.001' }}
            />
            <mesh
                geometry={nodes.d_floor001.geometry}
                material={materials.mat_floor}
                userData={{ name: 'd_floor.001' }}
            />
            <mesh
                geometry={nodes.d_props_towel001.geometry}
                material={materials.mat_towel}
                userData={{ name: 'd_props_towel.001' }}
            />
            <mesh
                geometry={nodes.a_props_module_01001.geometry}
                material={materials.mat_props_module_01}
                userData={{ name: 'a_props_module_01.001' }}
            />
        </group>
    )
}
