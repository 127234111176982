import React, {
    useCallback,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import * as THREE from "three";
import {Box, Preload, Text, useCursor} from "@react-three/drei";
import {Color, ThreeEvent} from "@react-three/fiber";
import Axis from "../../utils/Axis";
import {
    ProductsCategoryType,
    ProductsDataItemType,
} from "../../api/products/productsData";
import useCameraStore, {CameraState} from "../../stores/cameraStore";
import useProductStore, {productDic, ProductState} from "../../stores/productStore";
import useHistoryStore, {HistoryState} from "../../stores/historyStore";
import ProductInfoButtonGroup_PC from "../lghome/info/ProductInfoButtonGroup";
import ProductInfoButtonGroup_Mobile from "../lghome/info/mobile/ProductInfoButtonGroup_M";
import {easeCubicOut} from "d3-ease";
import useCameraControls, {CameraControlsSingleton} from "../../utils/useCameraControls";
import shallow from "zustand/shallow";
import useRightPanelStore from "../../stores/rightPanelStore";
import {IS_MOBILE} from "./const";

/**
 * 제품 모델링의 컨테이너
 */

type ProductContainer3DProps = {
    args?: [x: number, y: number, z: number];
    category?: ProductsCategoryType;
    data?: ProductsDataItemType | null;
    color?: Color;
    showDebug?: boolean;
    uiOffset?: [x: number, y: number, z: number];
    uiInfoOffset?: [x: number, y: number, z: number];   //스펙보드 활성화 시 카메라 이동을 위한 옵셋값
    specboardOffset?: [x: number, y: number, z: number];
    uiScale?: number;
    uiRotation?: [x: number, y: number, z: number];
    cameraTargetPos?: [x: number, y: number, z: number];
    cameraLookOffset?: [x: number, y: number, z: number];
    matchingModelNo?: string[]; //모델넘버로 데이터 일치 여부 추가 체크
    interactable?: boolean;
    children?: React.ReactNode;
} & Omit<JSX.IntrinsicElements["group"], "args">;

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const pushHistorySelector = (state: HistoryState) => state.pushHistory;
const pathnameSelector = (state: HistoryState) => state.pathname;
const selectedCategorySelector = (state: ProductState) => state.selectedCategory;
const selectedProductSelector = (state: ProductState) => state.selectedProduct;

const ProductInfoButtonGroup = IS_MOBILE ? ProductInfoButtonGroup_Mobile : ProductInfoButtonGroup_PC;


const ProductContainer3D = React.memo(
    ({
         args = [1, 1, 1],
         category,
         data,
         color,
         showDebug = true,
         uiOffset = [0, 0, 0],
         uiInfoOffset = [0, 0, 0],
         uiRotation = [0, 0, 0],
         uiScale = 1,
         specboardOffset = [-0.15, 0, 0],
         cameraTargetPos = [0, 0, 4],
         cameraLookOffset = [0, 0, 0],
         matchingModelNo,
         interactable = true,
         children,
         ...rest
     }: ProductContainer3DProps) => {

        const [active, setActive] = useState(false);
        const [hovered, setHovered] = useState(false);
        useCursor(hovered);

        const ref = useRef<THREE.Group>(null!);
        const ref2 = useRef<THREE.Group>(null!);
        const cameraTargetRef = useRef<THREE.Mesh>(null!);

        const [cameraTargetWorldPos] = useState(() => new THREE.Vector3());
        const [worldPos] = useState(() => new THREE.Vector3());
        const [lookOffset] = useState(() => new THREE.Vector3());
        const {setTargetPos, setLookPos} = useCameraStore((state) => state.api);

        const pushHistory = useHistoryStore(pushHistorySelector);
        const pathname = useHistoryStore(pathnameSelector);
        const selectedCategory = useProductStore(selectedCategorySelector); //선택된 카테고리
        const selectedProduct = useProductStore(selectedProductSelector); //선택된 제품 데이터
        const setIsOpen = useRightPanelStore(state => state.setIsOpen);

        const {targetPos, lookPos, isOpenInfo} = useCameraStore(state => ({
            targetPos: state.targetPos,
            lookPos: state.lookPos,
            isOpenInfo: state.isOpenInfo
        }), shallow);

        //선택된 카테고리와 데이터의 카테고리가 같으면 isMe === TRUE
        //선택된 카테고리가 없으면 isMe === FALSE
        const isMe = useMemo(() => selectedCategory?.category === data?.category, [selectedCategory, data]);

        //매칭 여부 판별 (같은 카테고리의 제품이 흩어져 있을 때, 선택을 제어하기 위함)
        const isMatching = useMemo(() => {
            if (matchingModelNo) {
                const result = matchingModelNo?.filter(i => i === selectedProduct?.modelNo);
                return result ? result.length > 0 : false;
            } else {
                return isMe;
            }
        }, [matchingModelNo, selectedProduct, isMe]);

        const isHome = useMemo(() => pathname.indexOf("/home") > -1, [pathname]);
        const [myModel, setMyModel] = useState<React.ReactNode | null>(null);

        //선택 시 카메라 제어값 설정
        const selectMe = useCallback(() => {
            if (cameraTargetRef.current) {
                cameraTargetRef.current.getWorldPosition(cameraTargetWorldPos);
                setTargetPos(cameraTargetWorldPos);
            }
            if (ref.current) {
                ref.current.getWorldPosition(worldPos);
                lookOffset.set(cameraLookOffset[0], cameraLookOffset[1], cameraLookOffset[2]);
                worldPos.add(lookOffset);
                setLookPos(worldPos);
            }
        }, []);

        //첫 로딩 시, 여러 그룹 중에서 활성 그룹 계산!!!!
        useLayoutEffect(() => {
            if (data && data.productList && data.productList.length > 0) {
                const firstItem = data.productList[0];
                const result = matchingModelNo?.filter(i => i === firstItem.modelNo);

                //별도의 매칭그룹을 사용할 경우
                if (matchingModelNo) {
                    //활성그룹이면 활성화
                    if (result && result.length > 0) {
                        setMyModel(firstItem.model);
                        setActive(true);
                        if (ref.current) ref.current.visible = true;
                    }
                    //활성그룹이 아니면 비활성화
                    else {
                        setMyModel(null);
                        setActive(false);
                        if (ref.current) ref.current.visible = false;
                    }
                }
                //매칭그룹을 사용안 할 경우 (무조건 활성)
                else {
                    setMyModel(data.productList[0].model);
                    setActive(true);
                    if (ref.current) ref.current.visible = true;
                }
            }
        }, []);

        useLayoutEffect(() => {
            if (selectedProduct) {
                if (data && data.productList && data.productList.length > 0) {
                    const result = matchingModelNo?.filter(i => i === selectedProduct?.modelNo);
                    // const check = data.productList.filter(d => d.modelNo === selectedProduct?.modelNo);

                    if (isMe) {
                        //별도의 매칭그룹을 사용할 경우
                        if (matchingModelNo) {
                            //활성그룹이면 활성화
                            if (result && result.length > 0) {
                                setMyModel(selectedProduct.model);
                                setActive(true);
                                if (ref.current) ref.current.visible = true;
                            }
                            //활성그룹이 아니면 비활성화
                            else {
                                setMyModel(null);
                                setActive(false);
                                if (ref.current) ref.current.visible = false;
                            }
                        }
                        //매칭그룹을 사용안 할 경우 (무조건 활성)
                        else {
                            setMyModel(selectedProduct.model);
                            setActive(true);
                            if (ref.current) ref.current.visible = true;
                        }
                    }
                }
            }
        }, [selectedProduct, isMe, matchingModelNo]);

        useLayoutEffect(() => {
            if (isMatching) selectMe();
        }, [isMatching]);

        useLayoutEffect(() => {
            if (selectedProduct && isMe) {
                focusToProduct([0, 0, 0]);
            }
        }, [selectedProduct, isMe]);


        const [cameraControls, rotateToTarget, cancelAnimation, isTweening] = useCameraControls();

        //제품 선택 시 포커스 맞추기
        const focusToProduct = useCallback((offset: [number, number, number] = [0, 0, 0]) => {
            if (targetPos && lookPos) {

                rotateToTarget([targetPos.x + offset[0], targetPos.y + offset[1], targetPos.z + offset[2]],
                    [lookPos.x + offset[0], lookPos.y + offset[1], lookPos.z + offset[2]],
                    () => {
                        cameraControls.enabled = true;
                    }, 1, 1, easeCubicOut);
            }
        }, [cameraControls, lookPos, rotateToTarget, targetPos]);

        const onPointerOverHandler = useCallback((e: ThreeEvent<PointerEvent>) => {
            if (!ref.current.visible) return;
            e.stopPropagation();
            setHovered(true);
        }, []);
        const onPointerOutHandler = useCallback((e: ThreeEvent<PointerEvent>) => {
            if (!ref.current.visible) return;
            e.stopPropagation();
            setHovered(false);
        }, []);

        return (
            <group
                ref={ref}
                {...rest}
                onPointerOver={onPointerOverHandler}
                onPointerOut={onPointerOutHandler}
                onClick={(e) => {
                    e.stopPropagation();

                    if (myModel) {
                        console.log("모델이 있다.", data, category, matchingModelNo);
                    } else {
                        if (data && data.productList && data.productList.length > 0) {
                            console.log("모델이 없다.", data.productList);
                        }

                        if (data && data.productList && data.productList.length > 0 && !data.productList[0].model) {
                            console.log("원래 데이터에 모델이 없다.");
                        } else {
                            return;
                        }
                    }

                    if (interactable && data && e.delta < 10) {
                        setIsOpen(true);
                        // selectMe();
                        pushHistory(`/home/${data.roomType}/${data.category}`);
                    }
                }}
            >
                {/*<Box args={[0.1,0.1,0.1]}>*/}
                {/*    <meshBasicMaterial color={"red"} depthTest={false} depthWrite={false} />*/}
                {/*</Box>*/}

                {/* 디버그용 */}
                <group visible={showDebug}>
                    <Box args={args}>
                        <meshBasicMaterial wireframe color={color}/>
                    </Box>
                    <Axis
                        xAxisVisible={false}
                        yAxisVisible={false}
                        position={[0, 0, args[2] * 0.5]}
                    />
                    {/*<Text fontSize={0.1} position={[0, args[1] * 0.6, 0]}>{category}</Text>*/}
                    <Box
                        args={[0.1, 0.1, 0.1]}
                        visible={false}
                        position={cameraTargetPos}
                        ref={cameraTargetRef}
                    >
                        <meshBasicMaterial wireframe color={"#f00"}/>
                    </Box>
                </group>

                {
                    /* data가 있고, productList에 데이터가 있으면 실행 */
                    data && data.productList && data.productList.length > 0 && (
                        <group ref={ref2}>
                            {/* Info 아이콘 */}
                            {isMe && isMatching &&
                            <ProductInfoButtonGroup
                                position={[-args[0] * 0.5 + uiOffset[0], args[1] * 0.5 + uiOffset[1], args[2] * 0.5 + uiOffset[2]]}
                                rotation={uiRotation}
                                data={data.productList[0]}
                                specboardOffset={specboardOffset}
                                scale={uiScale}
                                isShow={isMe}
                                specboardCallback={(show: boolean) => {
                                    if (!show) console.log(">스펙보드 닫힘!");
                                    focusToProduct(show ? uiInfoOffset : [0, 0, 0]);
                                }}
                            />
                            }

                            {/* 제품 3D모델 */}
                            (active && <group position={[0, -args[1] * 0.5, 0]}>
                            <React.Suspense fallback={null}>
                                {myModel}
                                {/*<Preload all/>*/}
                            </React.Suspense>
                        </group>)
                        </group>
                    )
                }
                {children}
            </group>
        );
    }
);

export default ProductContainer3D;
