/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {GLTFResult_ObjetModernProducts} from "./Product_Harvester";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {TextureLoader} from "three";
import {
    Objet_ColorCategoryType,
    vacuum_cleaner_robot_data,
    water_purifier_undersink_data
} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import {easeCubicInOut} from "d3-ease";
import {a} from "@react-spring/three";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom_u_sts/black_mat_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom_u_sts/black_mat_body_merged_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom_u_sts/brown_mat_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom_u_sts/brown_mat_body_merged_BaseColor.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult_ObjetModernProducts
    const colorTextures = useTexture([COLOR_0_URL, COLOR_1_URL]);

    const colorCategory = useObjetColorStore(state => state.water_purifier_undersink_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.water_purifier_undersink_ColorLabel);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);
    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory === 'Solid') {
            if (colorLabel === 'objet_color_black') {
                if (!isFirst) setIsSet(true);
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_clay_brown') {
                if (!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            }
        }
    }, []);


    useLayoutEffect(() => {
        console.log(colorCategory, colorLabel,);
        changeTexture("mat_atom_u_sts_body", colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);


    useEffect(() => {
        return () => {
            disposeScene(scene);
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }

            useLoader.clear(TextureLoader, [COLOR_0_URL, COLOR_1_URL]);
        }
    }, []);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>

            <mesh
                geometry={nodes.atom_u_sts.geometry}
                material={nodes.atom_u_sts.material}
                position={[0.5233, 1.0267, 0.2106]}
                rotation={[Math.PI, -0.331, Math.PI]}
            >
                <mesh geometry={nodes.Iron001.geometry} material={nodes.Iron001.material}>
                </mesh>
                <mesh geometry={nodes.Decal005.geometry} material={materials.mat_atom_u_sts_decal}>
                </mesh>
            </mesh>

            <ObjetProductContainer3D
                args={[0.18, 0.3, 0.18]}
                position={[0.5233, 1.2, 0.2106]}
                rotation={[Math.PI, Math.PI * 0.5, Math.PI]}
                data={water_purifier_undersink_data}
                showDebug={false}
                cameraTargetPos={[-0.8, 0.3, 1]}
            />

            <mesh
                geometry={nodes.atom_u_sts.geometry}
                material={nodes.atom_u_sts.material}
                position={[0.5233, 1.0267, 0.2106]}
                rotation={[Math.PI, -0.331, Math.PI]}
                visible={!isSet}
            >
                <mesh geometry={nodes.Iron001.geometry} material={nodes.Iron001.material}>
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                </mesh>

                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.2, 0]} scale={0.8} visible={!(isSet)} opacity={spring.opacity}
                                 color={pathname.indexOf('/water_purifier_undersink') > -1 ? '#be1e42' : '#000'}/>
            </mesh>
        </group>
    )
}
