/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {Plane, useGLTF, useTexture} from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene, setupScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        harvester_glass: THREE.Mesh
        harvester_inner: THREE.Mesh
        harvester: THREE.Mesh
    }
    materials: {
        mat_glass_dark: THREE.MeshStandardMaterial
        mat_harvester_inner: THREE.MeshStandardMaterial
        mat_harvester: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/tiiun/m/living_tiiun_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/tiiun/living_tiiun_ktx2.glb');

const LIGHT_MAP_URL  = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/tiiun/m/tiiun_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/models/products/tiiun/tiiun/tex/tiiun_cyclesbake_DIFFUSE.jpg');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useTexture(LIGHT_MAP_URL);

    useLayoutEffect(()=>{
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

        materials.mat_harvester.lightMapIntensity = 1;
        materials.mat_harvester.color.setHex(0x666666);
        // materials.mat_glass_dark.opacity = 0.6;
        // materials.mat_glass_dark.color.setRGB(0.1, 0.1, 0.1);
        materials.mat_glass_dark.emissiveIntensity = 1;


    }, [lightmapTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
        }
    }, []);

    return (
        <group ref={group} {...props}>
            <mesh
                geometry={nodes.harvester_glass.geometry}
                material={materials.mat_glass_dark}
                position={[-5.77, 0.34, -3.02]}
                scale={0.97}
                rotation={[0, 0.93, 0]}
                // visible={false}
            />
            {/*<Plane args={[1,1]}>*/}
            {/*    <meshStandardMaterial map={tiiunTex} />*/}
            {/*</Plane>*/}
            <mesh
                geometry={nodes.harvester_inner.geometry}
                material={materials.mat_harvester_inner}
                position={[-5.79, 0.32, -3.02]}
                rotation={[0, 0.93, 0]}
            />
            <mesh
                geometry={nodes.harvester.geometry}
                material={materials.mat_harvester}
                position={[-5.79, 0.32, -3.02]}
                rotation={[0, 0.93, 0]}
            />
        </group>
    )
}
