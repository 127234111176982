/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {useGLTF} from "@react-three/drei";

type GLTFResult = GLTF & {
    nodes: {
        convection: THREE.Mesh
        ['17_GlassUI']: THREE.Mesh
        ['17_Metal']: THREE.Mesh
        LG_Logo: THREE.Mesh
        Plane001: THREE.Mesh
        Plane: THREE.Mesh
    }
    materials: {
        ['18_Body']: THREE.MeshStandardMaterial
        ui: THREE.MeshStandardMaterial
        MetalBlack: THREE.MeshStandardMaterial
        LG_Logo_Decal: THREE.MeshStandardMaterial
        MetalSilver: THREE.MeshStandardMaterial
    }
}


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/microwave/m/1_7_convection_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/microwave/1_7_convection_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/microwave/1_7_convection.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;
    // const {nodes, materials, scene} = useGLTF<GLTFResult>(GLTF_URL) as GLTFResult;

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'convection'){
            if(selectedColorIndex === 1){
                mesh1_Ref.current.material = materials.MetalSilver;
            }
            else{
                mesh1_Ref.current.material = materials.MetalBlack;
            }
            mesh1_Ref.current.material.needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {
        mesh1_Ref.current.material = materials.MetalBlack;
        materials.LG_Logo_Decal.metalnessMap = materials.LG_Logo_Decal.map;
        materials.LG_Logo_Decal.emissiveIntensity = 3;
        materials.LG_Logo_Decal.roughness = 0.5;
        materials.LG_Logo_Decal.metalness = 0;
        materials.LG_Logo_Decal.needsUpdate = true;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);



    return (
        <group ref={group} {...props} dispose={null} position={[0.01, -0.012, 0.27]} scale={[1.01,1,1]}>
            <mesh geometry={nodes.convection.geometry} material={materials['18_Body']}>
                <mesh geometry={nodes['17_GlassUI'].geometry} material={materials.ui} />
                <mesh geometry={nodes.LG_Logo.geometry} material={materials.LG_Logo_Decal} />
                <mesh geometry={nodes['17_Metal'].geometry} material={nodes['17_Metal'].material} ref={mesh1_Ref} />
            </mesh>
            <mesh geometry={nodes.Plane.geometry} material={materials.MetalSilver} />
            <mesh geometry={nodes.Plane001.geometry} material={nodes.Plane001.material} />

            <mesh position={[-0.005, 0.47, -0.215]}>
                <boxBufferGeometry args={[0.76, 0.1, 0.3]} />
                <meshStandardMaterial color={"#111"} />
            </mesh>
        </group>
    )
}
