import React, { ReactElement, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GA_Event } from "../../../api/ga";
import { useModalVimeo } from "../../../stores/modalStore";
import useProductStore from "../../../stores/productStore";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";
import theme from "../../../styles/theme.styled";
import { Image } from "../../common/comps_styled/Image.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { ROOT_PATH_PREFIX } from "../../common/const";

type Props = {
  mainText?: React.ReactNode | string;
  subText?: React.ReactNode | string;
  gaid?: string;
  vimeoId?: string;
  // hidden?: boolean;
  position?: string;
  x?: number | string;
  y?: number | string;
  sizeRatio?: number;
} & JSX.IntrinsicElements["button"];

const BalloonButton = ({
  mainText,
  subText,
  gaid = "temp",
  vimeoId,
  // hidden = false,
  position = "left",
  x,
  y,
  sizeRatio = 1,
}: Props): ReactElement => {
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
  const setVimeoId = useModalVimeo((state) => state.setId);
  const setVimeoGaId = useModalVimeo((state) => state.setGaId);

  const setVimeo = (id: string, ga_id?: string) => {
    setVimeoId(id);
    setVimeoGaId(ga_id ?? "");
    setModalVimeo(true);
  };

  // for GAEvent
  const selectedProduct = useProductStore((state) => state.selectedProduct);
  const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);
  const ga_model = useProductStore((state) => state.selectedProduct?.ga_id);
  const ga_interior = useSpaceThemeStore((state) => state.lgHomeTheme);
  const ga_isNew = useProductStore((state) =>
    state.selectedProduct?.isCES ? "y" : "n"
  );
  const colorIndex = useProductStore((state) => state.selectedColorIndex);
  const ga_color = useMemo(
    () => selectedProduct?.colors[colorIndex].ga_id,
    [colorIndex, , selectedProduct]
  );
  //

  // console.log("position: " + position)

  return (
    <Wrapper
      position={position}
      style={{
        top: y,
        left: x,
        width: `${505 * sizeRatio}px`,
      }}
      onClick={() => {
        vimeoId && setVimeo(vimeoId, gaid);

        GA_Event("lg_home", `${ga_product}_tech`, gaid, {
          dimension5: ga_product,
          dimension6: ga_interior,
          dimension7: ga_model,
          dimension8: ga_isNew,
          dimension9: ga_color,
        });
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => {
        setHover(false);
        setActive(false);
      }}
      onMouseDown={() => setActive(true)}
      onMouseUp={() => setActive(false)}
    >
      <TextBox
        style={{
          width: `${505 * sizeRatio}px`,
          height: "auto",
        }}
      >
        <Image
          alt="top on balloon"
          role="img"
          height={`${17.5 * sizeRatio}px`}
          src={
            ROOT_PATH_PREFIX + `/images/usp/balloon/balloon_${position}_top.png`
          }
        />
        <div
          style={{
            backgroundImage: `url(${
              ROOT_PATH_PREFIX +
              `/images/usp/balloon/balloon_${position}_middle.png`
            })`,
            backgroundSize: "100%",
            backgroundRepeat: "repeat-y",
            display: "flex",
            justifyContent: position === "left" ? "flex-start" : "flex-end",
          }}
        >
          <Text
            style={{
              width: `${480 * sizeRatio}px`,
              padding: "0 5%",
            }}
            size={`${28 * sizeRatio}px`}
            weight="bold"
            align="center"
            lineHeight={1.1}
            color={
              active ? "white" : hover ? theme.colors.home.uspPink : "black"
            }
          >
            {mainText}
          </Text>
        </div>
        <div
          style={{
            height: `${50 * sizeRatio}px`,
            backgroundImage: `url(${
              ROOT_PATH_PREFIX +
              `/images/usp/balloon/balloon_${position}_bottom.png`
            })`,
            backgroundSize: "100%",
            backgroundRepeat: "no-repeat",
            display: "flex",
            justifyContent: position === "left" ? "flex-start" : "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: `${480 * sizeRatio}px`,
              height: "100%",
            }}
          >
            <Text
              size={`${20 * sizeRatio}px`}
              weight={theme.font.weight.semibold}
              align="center"
              color={
                active ? "white" : hover ? theme.colors.home.uspPink : "#696969"
              }
            >
              {subText}
            </Text>
          </div>
        </div>
      </TextBox>
    </Wrapper>
  );
};

const Wrapper = styled.button<{ position: string }>`
  background: transparent;
  cursor: pointer;
  position: absolute;
  display: flex;
`;

const TextBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* border: 1px solid tomato; */
`;

export default BalloonButton;
