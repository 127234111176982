/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {ContactShadows, useGLTF, useTexture} from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import useProductStore, {ProductState} from "../../stores/productStore";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene, setupScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";
import {TextureLoader} from "three";

type GLTFResult = GLTF & {
    nodes: {
        FrontDoor: THREE.Mesh
        FrontDoor_black_stainless: THREE.Mesh
        FrontDoor_sts: THREE.Mesh
        Body_sts: THREE.Mesh
        Body: THREE.Mesh
        Body_black_stainless: THREE.Mesh
        UI: THREE.Mesh
    }
    materials: {
        FrontDoor_black_stainless: THREE.MeshStandardMaterial
        FrontDoor_sts: THREE.MeshStandardMaterial
        Body_sts: THREE.MeshStandardMaterial
        Body_black_stainless: THREE.MeshStandardMaterial
        Bitmap: THREE.MeshStandardMaterial
    }
}


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/m/a_next4_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/a_next4_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/m/map/a_next4_Lightmap.jpg') :
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/map/a_next4_Lightmap.jpg');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/m/map/a_next4_AO.jpg') :
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/map/a_next4_AO.jpg');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult;
    const lightTex = useTexture(LIGHT_MAP_URL);
    const aoTex = useTexture(AO_URL);

    useLayoutEffect(()=>{
        lightTex.flipY = false;
        lightTex.encoding = THREE.sRGBEncoding;
        lightTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        setupScene(scene, undefined, aoTex);
        materials.Body_sts.aoMapIntensity = 1;
        materials.Body_black_stainless.map = null;
        materials.Body_black_stainless.color.setRGB(0.15, 0.15, 0.15);
        materials.FrontDoor_black_stainless.map = null;
        materials.FrontDoor_black_stainless.color.setRGB(0.15, 0.15, 0.15);

        materials.Body_black_stainless.aoMapIntensity = 1;
        materials.FrontDoor_sts.aoMapIntensity = 2;
        materials.FrontDoor_black_stainless.aoMapIntensity = 1;

    }, [lightTex, aoTex]);

    const caseRef = useRef<THREE.Mesh>(null!);
    const doorRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'ref_06'){
            if(selectedColorIndex === 0){
                caseRef.current.material = materials.Body_sts;
                doorRef.current.material = materials.FrontDoor_sts;
            }
            else{
                caseRef.current.material = materials.Body_black_stainless;
                doorRef.current.material = materials.FrontDoor_black_stainless;
            }
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {
        caseRef.current.material = materials.Body_sts;
        doorRef.current.material = materials.FrontDoor_sts;

        return () => {
            disposeScene(scene);
            aoTex.dispose();
            lightTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, AO_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} position={[-0.04,0,0]} dispose={null} scale={1.01}>
            <mesh
                geometry={nodes.FrontDoor.geometry}
                material={nodes.FrontDoor.material}
                userData={{ name: 'FrontDoor' }}
                ref={doorRef}
            />
            <mesh
                geometry={nodes.FrontDoor_black_stainless.geometry}
                material={nodes.FrontDoor_black_stainless.material}
                position={[1.6623, 10.531, 0]}
                scale={[0.0457, 0.0457, 0.0457]}
                userData={{ name: 'FrontDoor_black_stainless' }}
            />
            <mesh
                geometry={nodes.FrontDoor_sts.geometry}
                material={materials.FrontDoor_sts}
                position={[1.165, 10.531, 0]}
                scale={[0.0457, 0.0457, 0.0457]}
                userData={{ name: 'FrontDoor_sts' }}
            />
            <mesh
                geometry={nodes.Body_sts.geometry}
                material={materials.Body_sts}
                position={[0, 10.531, 0]}
                scale={[0.0457, 0.0457, 0.0457]}
                userData={{ name: 'Body_sts' }}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={nodes.Body.material}
                userData={{ name: 'Body' }}
                ref={caseRef}
            />
            <mesh
                geometry={nodes.Body_black_stainless.geometry}
                material={nodes.Body_black_stainless.material}
                position={[0.4973, 10.531, 0]}
                scale={[0.0457, 0.0457, 0.0457]}
                userData={{ name: 'Body_black_stainless' }}
            />
            <mesh geometry={nodes.UI.geometry} material={materials.Bitmap} userData={{ name: 'UI' }} />
        </group>
    )
}

// useGLTF.preload('/models/products/refrigerator/ref_06/ref_06_compressed.glb')
