import create from "zustand";

/**
 * 언어 설정 상태관리
 */
export type LangType = "" | "en" | "es" | "kr";

export type LangState = {
    lang: LangType;
    setLang: (lang: LangType) => void;
};

const useLangStore = create<LangState>(set => ({
    lang: "",
    setLang: lang => set({lang: lang})
}));

export default useLangStore;
