import styled from "styled-components";
import { Z_INDEX_POPUP } from "../../common/const";
import AwardsPanel from "./AwardsPanel";

const InnovationAwardsPanel = () => {
  return (
    <Wrapper
      style={{
        zIndex: Z_INDEX_POPUP,
      }}
    >
      <AwardsPanel />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: visible;
`;

export default InnovationAwardsPanel;
