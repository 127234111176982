import React, {useEffect, useMemo, useRef} from "react";
import styled from "styled-components";
import {animated, useSpring} from "@react-spring/web";
import {IS_MOBILE, KEY_COLOR, ROOT_PATH_PREFIX} from "../const";
import useHistoryStore from "../../../stores/historyStore";
import {easeCubicOut} from "d3-ease";
import useRightPanelStore from "../../../stores/rightPanelStore";
import {TOGGLE_BUTTON_WIDTH} from "../../lghome/select_panel/SelectPanel";
import {GA_Event, sendGaEvent} from "../../../api/ga";
import useResizeStore from "../../../stores/resizeStore";
import useObjetProductStore from "../../../stores/objetProductStore";

/**
 * 메인 버튼
 * @constructor
 */

type MainButtonProps = {
    isShowSelectPanel?: boolean;
} & JSX.IntrinsicElements["div"];
const MainButton = ({isShowSelectPanel, ...rest}: MainButtonProps) => {
    const pushHistory = useHistoryStore((state) => state.pushHistory);
    const isOpenRightPanel = useRightPanelStore((state) => state.isOpen);
    const isShowRightPanel = useRightPanelStore((state) => state.isShow);
    const panelWidth = useRightPanelStore((state) => state.panelWidth);
    const pathname = useHistoryStore((state) => state.pathname);
    const resize = useResizeStore((state) => state.resize);
    const objetData = useObjetProductStore((state) => state.selectedCategory);

    const isThinq = useMemo(() => pathname.includes("/thinq"), [pathname]);
    const isHome = useMemo(() => pathname.includes("/home"), [pathname]);
    const isObjet = useMemo(
        () => pathname.includes("/objetcollection"),
        [pathname]
    );

    const ref = useRef<HTMLButtonElement>(null!);
    const spring = useSpring({
        opacity: !!objetData ? 0 : 1,
        right: !isShowRightPanel ? (IS_MOBILE ? 0.1 * resize.ratio : 2) :
            (isOpenRightPanel ?
                panelWidth - (TOGGLE_BUTTON_WIDTH.close - TOGGLE_BUTTON_WIDTH.open) * (IS_MOBILE ? 0 : resize.ratio)
                : TOGGLE_BUTTON_WIDTH.close * resize.ratio),
        config: {
            easing: easeCubicOut,
        },
    });

    function resizeHandler() {
        if (ref.current) {
            if(!isShowRightPanel){
                ref.current.style.right = (IS_MOBILE ? 0 : 2) + 'rem';
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', resizeHandler);
        window.addEventListener('orientationchange', resizeHandler);
        window.dispatchEvent(new Event('resize'));
        return () => {
            window.removeEventListener('resize', resizeHandler);
            window.removeEventListener('orientationchange', resizeHandler);
        }
    }, []);

    return (
        <Wrapper
            ref={ref}
            ratio={resize.ratio}
            onClick={() => {
                pushHistory("");
                //
                isHome && GA_Event("lg_home", "menu", "main");
                isObjet && GA_Event("lg_objet", "menu", "main");
                if (isThinq) {
                    if (pathname.indexOf("/thinq/easy_convenient") > -1) {
                        sendGaEvent("thinq_menu_easy&convenient_main");
                    } else if (pathname.indexOf("/thinq/unique_delightful") > -1) {
                        sendGaEvent("thinq_menu_unique&delightful_main");
                    } else if (pathname === "/thinq") {
                        sendGaEvent("thinq_menu_main");
                    }
                }
            }}
            style={{
                pointerEvents: isObjet ? (!!objetData ? "none" : "visible") : "visible",
                opacity: isObjet ? spring.opacity.to((value) => value) : 1,
                right: spring.right.to((value) => value + "em"),
            }}
            {...rest}
        >
            <img
                className="mainImg"
                alt="to main"
                role="img"
                src={ROOT_PATH_PREFIX + "/images/topui/gnb_home.png"}
            />
        </Wrapper>
    );
};

const Wrapper = styled(animated.button)<Record<string, any> & { ratio: number; ismobile: boolean; keycolor: string }>`
  background: rgba(0, 0, 0, 0);
  //background: red;
  cursor: pointer;
  pointer-events: visible;
  position: absolute;
  right: 0;
    //width: ${({ratio}) => ratio * (IS_MOBILE ? 33.5 : 17.5)}em;
  height: ${({ratio}) => ratio * (IS_MOBILE ? 16 : 9.5)}em;
  top: 0;
    //top: ${({ratio}) => ratio * (IS_MOBILE ? 1 : 0.8)}em;
    // padding-right: ${({ratio}) => ratio * (IS_MOBILE ? 4 : 4)}em;
  padding-right: 3rem;

  .mainImg {
    height: 100%;
  }
`;

export default MainButton;
