const theme = {
  colors: {
    common: {
      darkpink: "#962045",
      gold: "#F1DCC0",
      gray: "#555559",
      graybrown: "#575757",
    },
    thinq: {},
    home: {
      darkgray: "#2d2d2d",
      darkblue: "#24252a",
      pink: "#b12750",
      uspPink: "#a50034",
    },
    objet: {
      lightgray: "#EEEEEF",
      darkgray: "#2d2d2d",
      darkblue: "#24252a",
      pink: "#b12750",
      warmgray: "#8f8f8f",
    },
  },
  font: {
    name: {
      ls: "LGSmart_H",
      su: "Segoe UI",
      mss: "Microsoft Sans Serif",
      ss: "sans-serif",
    },
    size: {
      "3xs": "0.2rem",
      "2xs": "0.4rem",
      xs: "0.8rem",
      sm: "1.4rem",
      md: "2rem",
      lg: "2.6rem",
      xl: "3rem",
      "2xl": "4rem",
      "3xl": "6rem",
    },
    weight: {
      bold: "bold",
      semibold: "600",
      normal: "normal",
      light: "100",
    },
  },
};

export default theme;
