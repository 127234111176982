import React, {useEffect, useState, useLayoutEffect, useMemo} from "react";
import styled from "styled-components";
import {Html, OrthographicCamera} from "@react-three/drei";
import {
    IS_MOBILE,
    Z_INDEX_POPUP,
    Z_INDEX_THINQ,
    Z_INDEX_TOP_UI,
} from "../common/const";
import useLangStore from "../../stores/langStore";
import useHistoryStore, {HistoryState} from "../../stores/historyStore";
import {useModalVimeo} from "../../stores/modalStore";
import {ROOT_PATH_PREFIX} from "../common/const";
import {animated, useSpring} from "@react-spring/web";
import {sendGaEvent} from "../../api/ga";
import AudioButton from "../common/AudioButton";
import useResizeStore from "../../stores/resizeStore";

/**
 * 씽큐 서비스
 * @constructor
 */

const bgRatio = 1920 / 1080;
let hasShowIntro = false;
let isShowUpgradableAppliance = false;


const LG_ThinQ2 = () => {

    const [sizeRatio, setSizeRatio] = useState(0);

    const pushHistory = useHistoryStore(state => state.pushHistory);
    const pathname = useHistoryStore(state => state.pathname);
    const ratio = useResizeStore((state) => state.resize.ratio);
    const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
    const setVimeoId = useModalVimeo((state) => state.setId);
    const setVimeoGaId = useModalVimeo((state) => state.setGaId);

    const setVimeo = (id: string) => {
        setVimeoId(id);
        setModalVimeo(true);
    };

    function resizeHandler() {
        const ratio = window.innerWidth / window.innerHeight;
        //가로로 더 길어진 경우, 세로에 맞추고 가로에 여백이 생김
        if (ratio > bgRatio) {
            const sizeH = window.innerHeight;
            const sizeRatio = sizeH / 1080;
            setSizeRatio(sizeRatio);
        }
        //세로로 더 길어진 경우, 가로에 맞추고 위아래에 여백이 생김
        else {
            const sizeW = window.innerWidth;
            const sizeRatio = sizeW / 1920;
            setSizeRatio(sizeRatio);
        }
    }

    const isMain = useMemo(() => (pathname === '/thinq'), [pathname]);
    const isEC = useMemo(() => (pathname.indexOf('/thinq/easy_convenient') > -1), [pathname]);
    const isUD = useMemo(() => (pathname.indexOf('/thinq/unique_delightful') > -1), [pathname]);
    const isUA = useMemo(() => (pathname.indexOf('/thinq/unique_delightful/upgradable_appliance') > -1), [pathname]);

    const [buttonECUrl, buttonUDUrl, isShowBottomButtons] = useMemo(() => {
        if (isEC) {
            return [ROOT_PATH_PREFIX + "/images/etc/btn_easy_convenient_press.png",
                ROOT_PATH_PREFIX + "/images/etc/btn_unique_delightful_default.png", true];
        } else if (isUD) {
            return [ROOT_PATH_PREFIX + "/images/etc/btn_easy_convenient_default.png",
                ROOT_PATH_PREFIX + "/images/etc/btn_unique_delightful_press.png", !isUA];
        }
        return [ROOT_PATH_PREFIX + "/images/etc/btn_easy_convenient_default.png",
            ROOT_PATH_PREFIX + "/images/etc/btn_unique_delightful_default.png", true];
    }, [isEC, isUA, isUD]);

    useLayoutEffect(() => {
        if (!hasShowIntro) {
            setVimeoGaId("exhibition_product");
            setVimeo("https://vimeo.com/659012760/2325d4583b");
            hasShowIntro = true;
        }

        window.addEventListener("resize", resizeHandler);
        window.addEventListener('orientationchange', resizeHandler);
        window.dispatchEvent(new Event("resize"));
        return () => {
            window.removeEventListener("resize", resizeHandler);
            window.removeEventListener('orientationchange', resizeHandler);
        };
    }, []);


    const buttonLighting = ROOT_PATH_PREFIX + "/images/etc/ThinQ_Lighting.png";
    const lighting = useSpring({
        from: {opacity: 0},
        to: {opacity: 1},
        loop: {reverse: true},
        reset: true,
    });
    const main = useSpring({
        from: {opacity: 0},
        to: {opacity: isMain ? 1 : 0},
        config: {duration: 500},
    });
    const ec = useSpring({
        from: {opacity: 0},
        to: {opacity: isEC ? 1 : 0},
        config: {duration: 500},
    });
    const ud = useSpring({
        from: {opacity: 0},
        to: {opacity: (isUD && !isUA) ? 1 : 0},
        config: {duration: 500},
    });
    const ua = useSpring({
        from: {opacity: 0},
        to: {opacity: isUA ? 1 : 0},
        config: {duration: 500},
    });


    return (
        <>
            <Html fullscreen center={false} transform={false}>
                <Wrapper id={"thinq"} style={{zIndex: Z_INDEX_THINQ}}>
                    <div
                        style={{
                            position: "absolute",
                            left: 0,
                            bottom: 0,
                            zIndex: Z_INDEX_TOP_UI,
                            padding: `${ratio * (IS_MOBILE ? 2.7 : 3)}em ${
                                ratio * (IS_MOBILE ? 4 : 2.5)
                            }em`,
                        }}
                    >
                        <AudioButton/>
                    </div>
                    <Contents sizeRatio={sizeRatio} style={{zIndex: 9999, opacity: 1}}>

                        <Contents sizeRatio={sizeRatio}>
                            <animated.div style={main}>
                                <img
                                    alt="background of main"
                                    src={ROOT_PATH_PREFIX + "/images/etc/thinkQ_00.png"}
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                    }}
                                />
                            </animated.div>
                            <animated.div style={ec}>
                                <EC sizeRatio={sizeRatio} setVimeo={setVimeo}/>
                            </animated.div>
                            <animated.div style={ud}>
                                <UD sizeRatio={sizeRatio} setVimeo={setVimeo}/>
                            </animated.div>
                            <animated.div style={ua}>
                                <UA sizeRatio={sizeRatio} setVimeo={setVimeo}/>
                            </animated.div>
                        </Contents>

                        {/* 하단 2개의 버튼 */}
                        {isShowBottomButtons && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    position: "absolute",
                                    justifyContent: "space-between",
                                    width: 770 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                    left: 575 * sizeRatio * (IS_MOBILE ? 0.81 : 1),
                                    top: 940 * sizeRatio * (IS_MOBILE ? 0.97 : 1),
                                    zIndex: 999,
                                }}
                            >
                                {/* Easy & Convenient 버튼 */}
                                <div
                                    style={{
                                        position: "relative",
                                        width: 378 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                        height: 105 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                    }}
                                >
                                    <img
                                        alt="easy convenient"
                                        role="img"
                                        src={buttonECUrl}
                                        style={{
                                            cursor: "pointer",
                                            width: 378 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                            height: 105 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                        }}
                                        onClick={() => {
                                            pushHistory("/thinq/easy_convenient");
                                            sendGaEvent("thinq_feature_select_easy&convenient");
                                        }}
                                        onMouseOver={(e) => {
                                            if (!(pathname.indexOf("/thinq/easy_convenient") > -1))
                                                e.currentTarget.src = ROOT_PATH_PREFIX + "/images/etc/btn_easy_convenient_press.png";
                                        }}
                                        onMouseOut={(e) => {
                                            if (!(pathname.indexOf("/thinq/easy_convenient") > -1))
                                                e.currentTarget.src = ROOT_PATH_PREFIX + "/images/etc/btn_easy_convenient_default.png";
                                        }}
                                    />
                                    {/* 깜박이는 OutGlow 모션 */}
                                    {isMain && (
                                        <animated.img
                                            alt="blinking easy convenient"
                                            role="img"
                                            style={{
                                                position: "absolute",
                                                left: -11 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                top: -13.5 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                width: 400 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                height: 127 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                ...lighting,
                                                pointerEvents: "none",
                                            }}
                                            src={buttonLighting}
                                        />
                                    )}
                                </div>
                                {/* Unique & Delightful 버튼 */}
                                <div
                                    style={{
                                        position: "relative",
                                        width: 378 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                        height: 105 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                    }}
                                >
                                    <img
                                        alt="unique delightful"
                                        role="img"
                                        src={buttonUDUrl}
                                        style={{
                                            cursor: "pointer",
                                            width: 378 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                            height: 105 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                        }}
                                        onClick={() => {
                                            pushHistory("/thinq/unique_delightful");
                                            sendGaEvent("thinq_feature_select_unique&delightful");
                                        }}
                                        onMouseOver={(e) => {
                                            if (!(pathname.indexOf("/thinq/unique_delightful") > -1))
                                                e.currentTarget.src = ROOT_PATH_PREFIX + "/images/etc/btn_unique_delightful_press.png";
                                        }}
                                        onMouseOut={(e) => {
                                            if (!(pathname.indexOf("/thinq/unique_delightful") > -1))
                                                e.currentTarget.src = ROOT_PATH_PREFIX + "/images/etc/btn_unique_delightful_default.png";
                                        }}
                                    />
                                    {/* 깜박이는 OutGlow 모션 */}
                                    {isMain && (
                                        <animated.img
                                            alt="blinking unique delightful"
                                            role="img"
                                            style={{
                                                position: "absolute",
                                                left: -11 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                top: -13.5 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                width: 400 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                height: 127 * sizeRatio * (IS_MOBILE ? 1.3 : 1),
                                                ...lighting,
                                                pointerEvents: "none",
                                            }}
                                            src={buttonLighting}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Contents>
                </Wrapper>
            </Html>
        </>
    );
};

interface MainProps {
    sizeRatio: number;
    setVimeo: Function;
}

/**
 * Easy & Convenient 페이지
 * */
const EC = ({sizeRatio, setVimeo}: MainProps) => {
    const setVimeoGaId = useModalVimeo((state) => state.setGaId);
    return (
        <>
            <img
                alt="background of easy and convenient"
                src={ROOT_PATH_PREFIX + "/images/etc/thinkQ_01.png"}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            />
            <LinkButton
                style={{
                    position: "absolute",
                    left: 272 * sizeRatio,
                    top: 189 * sizeRatio,
                    width: 250 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("remote_control");
                    setVimeo("https://vimeo.com/657895912/30f7bd9208");
                    sendGaEvent("thinq_easy&convenient_remote_control");
                }}
            >
                Remote Control
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 825 * sizeRatio,
                    top: 80 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("voice_control");
                    setVimeo("https://vimeo.com/657896026/e0170f4fc7");
                    sendGaEvent("thinq_easy&convenient_voice_control");
                }}
            >
                Voice Control
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 1390 * sizeRatio,
                    top: 189 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("mode&automation");
                    setVimeo("https://vimeo.com/657895839/fc62011551");
                    sendGaEvent("thinq_easy&convenient_mode&automation");
                }}
            >
                Mode & Automation
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 272 * sizeRatio,
                    top: 561 * sizeRatio,
                    width: 250 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("thinq_recipe");
                    setVimeo("https://vimeo.com/660877056/f2314ad754");
                    sendGaEvent("thinq_easy&convenient_thinq_recipe");
                }}
            >
                ThinQ Recipe
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 825 * sizeRatio,
                    top: 621 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("thinq_store");
                    setVimeo("https://vimeo.com/657895964/50e0c6cddd");
                    sendGaEvent("thinq_easy&convenient_thinq_store");
                }}
            >
                ThinQ Store
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 1390 * sizeRatio,
                    top: 561 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("energy_dr");
                    setVimeo("https://vimeo.com/657895792/47057ca307");
                    sendGaEvent("thinq_easy&convenient_energy_dr");
                }}
            >
                Energy DR
            </LinkButton>
        </>
    );
};

/**
 * Unique & Delightful 페이지
 * */
const UD = ({sizeRatio, setVimeo}: MainProps) => {
    const setVimeoGaId = useModalVimeo((state) => state.setGaId);
    const pushHistory = useHistoryStore((state) => state.pushHistory);

    return (
        <>
            <img
                alt="background of unique and delightful"
                src={ROOT_PATH_PREFIX + "/images/etc/thinkQ_02.png"}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            />
            <LinkButton
                style={{
                    position: "absolute",
                    left: 272 * sizeRatio,
                    top: 189 * sizeRatio,
                    width: 300 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    //처음 한번만 인트로 영상 재생
                    if (!isShowUpgradableAppliance) {
                        setVimeoGaId("upgradable_appliance_video");
                        setVimeo("https://vimeo.com/670106194/bdb1aeea44");
                        isShowUpgradableAppliance = true;
                    }
                    sendGaEvent("thinq_unique&delightful_upgradable_appliance");
                    pushHistory("/thinq/unique_delightful/upgradable_appliance");
                }}
            >
                Upgradable Appliance
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 825 * sizeRatio,
                    top: 80 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("proactive_care");
                    setVimeo("https://vimeo.com/657896133/2bfcff0954");
                    sendGaEvent("thinq_unique&delightful_proactive_care");
                }}
            >
                Proactive Care
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 1390 * sizeRatio,
                    top: 189 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("smart_scan");
                    setVimeo("https://vimeo.com/660903225/08368701f0");
                    sendGaEvent("thinq_unique&delightful_smart_scan");
                }}
            >
                Smart Scan
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 272 * sizeRatio,
                    top: 561 * sizeRatio,
                    width: 250 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("insight_report");
                    setVimeo("https://vimeo.com/657896064/3eb07aa694");
                    sendGaEvent("thinq_unique&delightful_insights");
                }}
            >
                Insights Report
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 1390 * sizeRatio,
                    top: 561 * sizeRatio,
                    width: 270 * sizeRatio,
                    height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("smart_life");
                    setVimeo("https://vimeo.com/657896226/de2a7bcbba");
                    sendGaEvent("thinq_unique&delightful_smart_life");
                }}
            >
                Smart Life
            </LinkButton>
        </>
    );
};

/**
 * Upgradable Appliance 페이지
 * */
const UA = ({sizeRatio, setVimeo}: MainProps) => {
    const setVimeoGaId = useModalVimeo((state) => state.setGaId);
    return (
        <>
            <img
                alt="upgradable appliance"
                src={ROOT_PATH_PREFIX + "/images/etc/thinkQ_03.png"}
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            />
            <LinkButton
                style={{
                    position: "absolute",
                    left: 272 * sizeRatio,
                    top: 189 * sizeRatio,
                    // width: 250 * sizeRatio,
                    // height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)"
                }}
                onClick={() => {
                    setVimeoGaId("adjust_brightness");
                    setVimeo("https://vimeo.com/657896352/e299efe156");
                    sendGaEvent("thinq_upgradable_appliance_adjust_brightness");
                }}
            >
                Adjust Brightness
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 825 * sizeRatio,
                    top: 80 * sizeRatio,
                    // width: 270 * sizeRatio,
                    // height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("pet_care_course");
                    setVimeo("https://vimeo.com/657896405/223e1af456");
                    sendGaEvent("thinq_upgradable_appliance_pet_care_course");
                }}
            >
                Pet Care Course
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 1390 * sizeRatio,
                    top: 189 * sizeRatio,
                    // width: 350 * sizeRatio,
                    // height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("splash_screen/washer_ending_melody");
                    setVimeo("https://vimeo.com/666435904/4bf0641d11");
                    sendGaEvent("thinq_upgradable_appliance_splash_screen/washer_ending_melody");
                }}
            >
                Splash Screen /<br/>
                Washer Ending Melody
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 272 * sizeRatio,
                    top: 561 * sizeRatio,
                    // width: 250 * sizeRatio,
                    // height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("voice_control");
                    setVimeo("https://vimeo.com/666435919/9e1d4dbe62");
                    sendGaEvent("thinq_upgradable_appliance_voice_control");
                }}
            >
                Voice Control
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 825 * sizeRatio,
                    top: 731 * sizeRatio,
                    // width: 270 * sizeRatio,
                    // height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("dosage_adjustment");
                    setVimeo("https://vimeo.com/666435875/776d557225");
                    sendGaEvent("thinq_upgradable_appliance_dosage_adjustment");
                }}
            >
                Dosage Adjustment
            </LinkButton>
            <LinkButton
                style={{
                    position: "absolute",
                    left: 1390 * sizeRatio,
                    top: 561 * sizeRatio,
                    // width: 270 * sizeRatio,
                    // height: 72 * sizeRatio,
                    filter: "drop-shadow(0 0 0.5rem #999)",
                }}
                onClick={() => {
                    setVimeoGaId("operating_mode");
                    setVimeo("https://vimeo.com/657896341/f604721430");
                    sendGaEvent("thinq_upgradable_appliance_operating_mode");
                }}
            >
                Operating Mode
            </LinkButton>
        </>
    );
};


const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 2rem;
  background: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Contents = styled.div<{
    sizeRatio?: number;
    url?: string;
}>`
  width: ${({sizeRatio}) => (sizeRatio ? 1920 * sizeRatio : 1920)}px;
  height: ${({sizeRatio}) => (sizeRatio ? 1080 * sizeRatio : 1080)}px;
  position: relative;
  z-index: 2;
    // background-image: url("${(props) => (props.url ? props.url : "")}");
  background-size: contain;
  background-repeat: no-repeat;
`;

const LinkButton = styled.button<{}>`
  font-size: ${IS_MOBILE ? 1.4 : 2}rem;
  line-height: ${IS_MOBILE ? 1.4 : 2}rem;
  font-weight: bold;
  min-height: ${IS_MOBILE ? 4 : 6}rem;
  padding: 1rem 2.5rem;
  border-radius: 5rem;
  color: #fff;
  background-color: #8f8f8f;
  pointer-events: visible;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: ${Z_INDEX_POPUP};

  :hover {
    background-color: #b12750;
  }
`;

export default LG_ThinQ2;
