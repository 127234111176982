/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        ER_Door_Decal: THREE.Mesh
        ER_Body: THREE.Mesh
        ER_Door_Mirror: THREE.Mesh
        ER_Door_Brown: THREE.Mesh
        ER_Door: THREE.Mesh
        ER_Door_White: THREE.Mesh
        ER_UI: THREE.Mesh
    }
    materials: {
        ER_Door_Decal: THREE.MeshStandardMaterial
        ['grey_Material1.001']: THREE.MeshStandardMaterial
        ER_Door_Mirror: THREE.MeshStandardMaterial
        ER_Door_Brown: THREE.MeshStandardMaterial
        ER_Door: THREE.MeshStandardMaterial
        ER_Door_White: THREE.MeshStandardMaterial
        ['texture.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/styler/m/essence_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/styler/essence_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);


    const part1Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'essence') {
            if (selectedColorIndex === 0) {
                materials.ER_Door_Mirror.envMapIntensity = 1;
                materials.ER_Door_Mirror.color.setHex(0x888888);
                materials.ER_Door_Mirror.metalness = 1;
                materials.ER_Door_Mirror.roughness = 0.1;
                materials.ER_Door_Mirror.needsUpdate = true;
                part1Ref.current.material = materials.ER_Door_Mirror;
            } else if (selectedColorIndex === 1) {
                materials.ER_Door_Brown.envMapIntensity = 1;
                materials.ER_Door_Brown.metalness = 0;
                materials.ER_Door_Brown.roughness = 0.3;
                materials.ER_Door_Brown.needsUpdate = true;
                part1Ref.current.material = materials.ER_Door_Brown;
            }else{
                materials.ER_Door_White.envMapIntensity = 2;
                materials.ER_Door_White.metalness = 0;
                materials.ER_Door_White.roughness = 0.3;
                materials.ER_Door_White.needsUpdate = true;

                part1Ref.current.material = materials.ER_Door_White;
            }
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {
        materials.ER_Door_Mirror.envMapIntensity = 1;
        materials.ER_Door_Mirror.color.setHex(0x888888);
        materials.ER_Door_Mirror.metalness = 1;
        materials.ER_Door_Mirror.roughness = 0.1;
        materials.ER_Door_Mirror.needsUpdate = true;
        part1Ref.current.material = materials.ER_Door_Mirror;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[-0.056, 0.01, 0.22]}>
            <mesh
                geometry={nodes.ER_Door_Decal.geometry}
                material={materials.ER_Door_Decal}
                position={[0.0005, -0.0224, 0.0023]}
                scale={[0.0075, 0.0095, 0.01]}
                userData={{ name: 'ER_Door_Decal' }}
                visible={false}
            />
            <mesh
                geometry={nodes.ER_Body.geometry}
                material={materials['grey_Material1.001']}
                position={[0.0005, -0.0224, 0.0023]}
                scale={[0.0075, 0.0095, 0.01]}
                userData={{ name: 'ER_Body' }}
            />
            <mesh
                geometry={nodes.ER_Door_Mirror.geometry}
                material={materials.ER_Door_Mirror}
                position={[0.0005, -0.0224, 0.0023]}
                scale={[0.0075, 0.0095, 0.01]}
                userData={{ name: 'ER_Door_Mirror' }}
                visible={false}
            />
            <mesh
                geometry={nodes.ER_Door_Brown.geometry}
                material={materials.ER_Door_Brown}
                position={[0.0005, -0.0224, 0.0023]}
                scale={[0.0075, 0.0095, 0.01]}
                userData={{ name: 'ER_Door_Brown' }}
                visible={false}
            />
            <mesh
                geometry={nodes.ER_Door.geometry}
                material={materials.ER_Door}
                position={[0.0005, -0.0224, 0.0023]}
                scale={[0.0075, 0.0095, 0.01]}
                userData={{ name: 'ER_Door' }}
                ref={part1Ref}
            />
            <mesh
                geometry={nodes.ER_Door_White.geometry}
                material={materials.ER_Door_White}
                position={[0.0005, -0.0224, 0.0023]}
                scale={[0.0075, 0.0095, 0.01]}
                userData={{ name: 'ER_Door_White' }}
                visible={false}
            />
            <mesh
                geometry={nodes.ER_UI.geometry}
                material={materials['texture.001']}
                position={[-0.0011, -0.0225 + 0.28, 0.0043]}
                scale={[0.0095 * 0.85, 0.0095 * 0.85, 0.01 * 0.85]}
                userData={{ name: 'ER_UI' }}
            />
        </group>
    )
}




// /*
// Auto-generated by: https://github.com/pmndrs/gltfjsx
// */
//
// import * as THREE from 'three'
// import React, {useEffect, useRef} from 'react'
// import {useGLTF, useAnimations} from '@react-three/drei'
// import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
// import {ROOT_PATH_PREFIX} from "../../components/common/const";
// import {disposeScene} from "../../utils/disposeScene";
// import {useLoader} from "@react-three/fiber";
// import {GLTFLoader} from "three-stdlib";
//
// type GLTFResult = GLTF & {
//     nodes: {
//         darkgrey_Materia_body001: THREE.Mesh
//         Ui_display001: THREE.Mesh
//         Object001001: THREE.Mesh
//     }
//     materials: {
//         style_02_body: THREE.MeshStandardMaterial
//         ['texture.001']: THREE.MeshStandardMaterial
//         ['Door.001']: THREE.MeshStandardMaterial
//     }
// }
//
// type ActionName =
//     | 'darkgrey_Materia_body.001|Take 001|BaseLayer'
//     | 'Ui_display.001|Take 001|BaseLayer'
// type GLTFActions = Record<ActionName, THREE.AnimationAction>
//
// export default function Model(props: JSX.IntrinsicElements['group']) {
//     const group = useRef<THREE.Group>()
//     const {nodes, materials, scene} = useGLTF(ROOT_PATH_PREFIX + '/models/sty/sty_02/sty_02.gltf') as GLTFResult
//
//     useEffect(() => {
//         return () => {
//             disposeScene(scene);
//             useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/models/sty/sty_02/sty_02.gltf');
//         }
//     }, []);
//
//     return (
//         <group ref={group} {...props} scale={1} position={[0.056, -0.025, 0.22]} dispose={null}>
//             <mesh
//                 geometry={nodes.darkgrey_Materia_body001.geometry}
//                 material={materials.style_02_body}
//                 position={[-0.1109, 0.0115, 0.0023]}
//                 scale={[0.0075, 0.0095, 0.01]}
//                 userData={{name: 'darkgrey_Materia_body.001'}}
//             />
//             <mesh
//                 geometry={nodes.Ui_display001.geometry}
//                 material={materials['texture.001']}
//                 position={[-0.1124, 0.0114, 0.0043]}
//                 scale={[0.0075, 0.0095, 0.01]}
//                 userData={{name: 'Ui_display.001'}}
//             />
//             <mesh
//                 geometry={nodes.Object001001.geometry}
//                 material={materials['Door.001']}
//                 position={[-0.1109, 0.0115, 0.0023]}
//                 scale={[0.0075, 0.0095, 0.01]}
//                 userData={{name: 'Object001.001'}}
//             />
//             {/*<mesh position={[0.15, 1, -0.57]}>*/}
//             {/*    <boxBufferGeometry args={[0.15, 2, 1]}/>*/}
//             {/*    <meshStandardMaterial color={'#293b46'}/>*/}
//             {/*</mesh>*/}
//         </group>
//     )
// }
//
