import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { getLangString, LangLabelType } from "../../../api/lang/langData";
import { Objet_ColorCategoryType } from "../../../api/products/objetProductsData";
import useLangStore from "../../../stores/langStore";
import useResizeStore from "../../../stores/resizeStore";
import theme from "../../../styles/theme.styled";
import { Image } from "../../common/comps_styled/Image.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { IS_MOBILE } from "../../common/const";

type Props = {
  isOpenBalloon: boolean;
  colorCategory: Objet_ColorCategoryType;
  isBottom?: boolean;
};

const Balloon = ({ isOpenBalloon, colorCategory, isBottom = false }: Props) => {
  const resize = useResizeStore((state) => state.resize);
  const lang = useLangStore((state) => state.lang);

  return (
    <Wrapper
      style={{
        display: isOpenBalloon ? "flex" : "none",
        top: isBottom ? "-200%" : 0,
      }}
    >
      <BalloonRect>
        <Text
          size={`${3 * resize.ratio}em`}
          weight="bold"
          style={{ width: "100%", paddingLeft: "0.4em", marginTop: "0.8em" }}
        >
          {colorCategory}
        </Text>
        <Text
          lineHeight={1.5}
          color={theme.colors.objet.darkblue}
          size={`${2 * resize.ratio}em`}
          style={{ width: "100%", padding: "1.3em 0.6em" }}
        >
          {
            getLangString(
              lang,
              `objet_material_${colorCategory.toLowerCase()}_info` as LangLabelType
            )?.text
          }
        </Text>
      </BalloonRect>
      <Triangle
        style={{
          position: "relative",
          top: isBottom
            ? IS_MOBILE
              ? "4em"
              : "9em"
            : IS_MOBILE
            ? "1.2em"
            : "2.4em",
          filter: "drop-shadow(6px 6px 3px rgba(0, 0, 0, 0.2))",
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  left: -90%;
  width: 100%;
  background-color: transparent;

  /* border: 1px solid red; */
`;

const BalloonRect = styled.div`
  padding: 0.5em;
  width: 90%;
  background-color: white;
  border-radius: 0.8em;
  border-top-right-radius: 0.4em;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: 6px 6px 6px 0 rgba(0, 0, 0, 0.2);
`;

const Triangle = styled.div`
  width: 0px;
  height: 0px;
  border-bottom: ${IS_MOBILE ? 2 : 3}em solid transparent;
  border-left: ${IS_MOBILE ? 4 : 5}em solid white;
  background-color: transparent;
`;

export default Balloon;
