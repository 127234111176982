import {ProductsDataItemType} from "./productsData";
import Wearable_1nd from "../../products/wearable_air_purifier/Wearable_1nd";
import Wearable_2nd from "../../products/wearable_air_purifier/Wearable_2nd";


/**
 * 마스크 데이터
 */
const productsData_WearableAirPurifier: ProductsDataItemType = {
    category: "wearable_air_purifier",
    ga_id: "wearable_air_purifier",
    label: "category_wearable_air_purifier",
    category_img_url: "/images/products/wearable_air_purifier/category_img.png",
    category_thumb_url:
        "/images/products/wearable_air_purifier/category_thumb.png",
    category_glow_url: "/images/products/wearable_air_purifier/category_glow.png",
    roomType: "living",
    usp_transition: "/movies/usp/transition/main_AC_000_00000.webm",
    productList: [
        {
            name: "PuriCare Wearable Air Purifier",
            modelNo: "wearable",
            ga_id: "puricare_wearable",
            isCES: false,
            thumb_url:
                "/images/products/wearable_air_purifier/wearable_2nd/product_thumb.png",
            ar_text: "home_exp_ar_wearable",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color:
                        "/images/products/wearable_air_purifier/wearable/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Wearable_1nd/>,
        },
        {
            name: "PuriCare Wearable Air Purifier 2nd",
            modelNo: "wearable_2nd",
            ga_id: "puricare_wearable_2nd",
            isCES: true,
            thumb_url:
                "/images/products/wearable_air_purifier/wearable/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color:
                        "/images/products/wearable_air_purifier/wearable_2nd/color_white.png",
                    ga_id: "white",
                },
                {
                    name: <></>,
                    label: "home_exp_color_black",
                    color:
                        "/images/products/wearable_air_purifier/wearable_2nd/color_black.png",
                    ga_id: "black",
                },
            ],
            model: <Wearable_2nd/>,
        },
    ],
};

export default productsData_WearableAirPurifier;
