/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {GLTFResult_ObjetModernProducts} from "./Product_Harvester";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {dishwasher_data, Objet_ColorCategoryType} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import {TextureLoader} from "three";
import {easeCubicInOut} from "d3-ease";
import {a} from "@react-spring/three";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/dishwasher/beige_mat_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/dishwasher/beige_mat_body_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/dishwasher/green_mat_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/dishwasher/green_mat_body_BaseColor.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/dishwasher/silver_mat_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/dishwasher/silver_mat_body_BaseColor.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult_ObjetModernProducts

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.dishwasher_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.dishwasher_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsSet(true);

        if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_beige') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            }
        } else if (colorCategory === 'Solid') {
            if (colorLabel === 'objet_color_green') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            } else if (colorLabel === 'objet_color_silver') {
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[2];
            }
        }
    }, []);

    useLayoutEffect(() => {
        changeTexture('mat_body.003', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL
            ]);
        }
    }, []);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut},
                // onRest: result => {
                //     console.log(">>>>>", result);
                // }
            });
        }
    }, [isOpenTutorial]);

    useEffect(() => {
        materials['mat_body.003'].envMapIntensity = 2;
    }, []);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.dish_washer.geometry}
                material={materials['mat_body.003']}
                position={[0.9478, 0.5567, 1.1274]}
                rotation={[0, Math.PI / 2, 0]}>
                <mesh
                    geometry={nodes.Decal004.geometry}
                    material={materials['mat_decal.001']}
                    position={[0, -0.4142, -0.3257]}
                />

                <ObjetProductContainer3D
                    args={[0.6, 0.82, 0.01]}
                    data={dishwasher_data}
                    showDebug={false}
                    cameraTargetPos={[0, 0.5, 2]}
                />
            </mesh>

            <mesh
                geometry={nodes.dish_washer.geometry}
                // material={materials['mat_body.003']}
                position={[0.9478, 0.5567, 1.1274]}
                rotation={[0, Math.PI / 2, 0]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0, 0]} scale={0.8} visible={!(isSet)} opacity={spring.opacity}
                                 color={pathname.indexOf('/dishwasher') > -1 ? '#be1e42' : '#000'}/>
            </mesh>

        </group>
    )
}
