/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {TextureLoader} from "three";
import {useEquirectEXR} from "../../../../utils/useEquirect";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        b_props_5001: THREE.Mesh
        d_ceiling_light001: THREE.Mesh
        d_ceiling001: THREE.Mesh
        c_chair001: THREE.Mesh
        a_wall001: THREE.Mesh
        a_glass001: THREE.Mesh
        a_props_pot3001: THREE.Mesh
        d_floor001: THREE.Mesh
        cabinet_door_03: THREE.Mesh
        c_cabinet_body001: THREE.Mesh
        cabinet_door_04: THREE.Mesh
        c_props_merged001: THREE.Mesh
        b_props_2001: THREE.Mesh
        b_sink_top_2001: THREE.Mesh
        c_props_3001: THREE.Mesh
        a_glass007: THREE.Mesh
        a_props001: THREE.Mesh
    }
    materials: {
        b_mat_metal: THREE.MeshStandardMaterial
        mat_ceiling_light: THREE.MeshStandardMaterial
        mat_wall_white: THREE.MeshStandardMaterial
        c_props_chair: THREE.MeshStandardMaterial
        mat_wall_pink: THREE.MeshStandardMaterial
        glass2: THREE.MeshStandardMaterial
        a_lambert2SG: THREE.MeshStandardMaterial
        mat_floor: THREE.MeshStandardMaterial
        mat_wood_door: THREE.MeshStandardMaterial
        mat_wood_body: THREE.MeshStandardMaterial
        mat_wood_door_2: THREE.MeshStandardMaterial
        ['c_mat_props_merged.001']: THREE.MeshStandardMaterial
        b_mat_ceramic: THREE.MeshStandardMaterial
        mat_white_marble: THREE.MeshStandardMaterial
        ['c_wood_ipe_2.002']: THREE.MeshStandardMaterial
        glass: THREE.MeshStandardMaterial
        a_mat_props: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/kitchen_warm_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/kitchen_warm_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/map/kitchen_cyclesbake_DIFFUSE.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_DIFFUSE.exr');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/map/kitchen_cyclesbake_AO.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_Environment_01.exr';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    // @ts-ignore
    const lightmapTex = useLoader(EXRLoader, LIGHT_MAP_URL,(loader:EXRLoader) => {
        if(IS_MOBILE){
            loader.setDataType(THREE.HalfFloatType);
        }else{
            loader.setDataType(THREE.FloatType);
        }
    });
    const aoTex = useLoader(TextureLoader, AO_URL);
    const envTex = useEquirectEXR(ENV_URL);

    const gl = useThree(state => state.gl);
    const scene2 = useThree(state => state.scene);

    useLayoutEffect(()=>{
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.needsUpdate = true;

        scene2.environment = envTex;
        gl.toneMappingExposure = 1;

        setupScene(scene, lightmapTex, aoTex);

        materials.mat_wall_pink.lightMapIntensity = 0.6;
        materials.mat_floor.aoMap = aoTex;
        materials.mat_floor.aoMapIntensity = 0.3;
        materials.mat_wall_white.aoMap = aoTex;
        materials.mat_wall_white.aoMapIntensity = 0.3;

        materials.glass.opacity = 0.8;
        materials.glass2.opacity = 0.5;

    }, [lightmapTex, aoTex, envTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);

            lightmapTex.dispose();
            aoTex.dispose();
            envTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            // //@ts-ignore
            // useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            // useLoader.clear(TextureLoader, AO_URL)
            // //@ts-ignore
            // useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.b_props_5001.geometry}
                material={materials.b_mat_metal}
                userData={{ name: 'b_props_5.001' }}
            />
            <mesh
                geometry={nodes.d_ceiling_light001.geometry}
                material={materials.mat_ceiling_light}
                userData={{ name: 'd_ceiling_light.001' }}
            />
            <mesh
                geometry={nodes.d_ceiling001.geometry}
                material={materials.mat_wall_white}
                userData={{ name: 'd_ceiling.001' }}
            />
            <mesh
                geometry={nodes.c_chair001.geometry}
                material={materials.c_props_chair}
                userData={{ name: 'c_chair.001' }}
            />
            <mesh
                geometry={nodes.a_wall001.geometry}
                material={materials.mat_wall_pink}
                userData={{ name: 'a_wall.001' }}
            />
            <mesh
                geometry={nodes.a_glass001.geometry}
                material={materials.glass2}
                userData={{ name: 'a_glass.001' }}
            />
            <mesh
                geometry={nodes.a_props_pot3001.geometry}
                material={materials.a_lambert2SG}
                userData={{ name: 'a_props_pot3.001' }}
            />
            <mesh
                geometry={nodes.d_floor001.geometry}
                material={materials.mat_floor}
                userData={{ name: 'd_floor.001' }}
            />
            <mesh
                geometry={nodes.cabinet_door_03.geometry}
                material={materials.mat_wood_door}
                userData={{ name: 'cabinet_door_03' }}
            />
            <mesh
                geometry={nodes.c_cabinet_body001.geometry}
                material={materials.mat_wood_body}
                userData={{ name: 'c_cabinet_body.001' }}
            />
            <mesh
                geometry={nodes.cabinet_door_04.geometry}
                material={materials.mat_wood_door_2}
                userData={{ name: 'cabinet_door_04' }}
            />
            <mesh
                geometry={nodes.c_props_merged001.geometry}
                material={materials['c_mat_props_merged.001']}
                userData={{ name: 'c_props_merged.001' }}
            />
            <mesh
                geometry={nodes.b_props_2001.geometry}
                material={materials.b_mat_ceramic}
                userData={{ name: 'b_props_2.001' }}
            />
            <mesh
                geometry={nodes.b_sink_top_2001.geometry}
                material={materials.mat_white_marble}
                userData={{ name: 'b_sink_top_2.001' }}
            />
            <mesh
                geometry={nodes.c_props_3001.geometry}
                material={materials['c_wood_ipe_2.002']}
                userData={{ name: 'c_props_3.001' }}
            />
            <mesh
                geometry={nodes.a_glass007.geometry}
                material={materials.glass}
                userData={{ name: 'a_glass.007' }}
            />
            <mesh
                geometry={nodes.a_props001.geometry}
                material={materials.a_mat_props}
                userData={{ name: 'a_props.001' }}
            />
        </group>
    )
}
