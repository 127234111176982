import create from "zustand";
import { IS_MOBILE } from "../components/common/const";
import { ModalState } from "./modalStore";

export const useModalSelectRouteMobile = create<ModalState>((set) => ({
  isOpen: true,
  setIsOpen: (val) => set({ isOpen: val }),
}));

type MobileQuailtyType = {
  isNormalQuality: boolean;
  setisNormalQuality: (value) => void;
};

export const useSelectMobileQuality = create<MobileQuailtyType>((set) => ({
  isNormalQuality: false,
  setisNormalQuality: (val) => set({ isNormalQuality: IS_MOBILE && val }),
}));
