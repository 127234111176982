/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {Objet_ColorCategoryType, tiiun_data} from "../../../../api/products/objetProductsData";
import {ObjetColorState, useObjetColorStore} from "../../../../stores/objetProductStore";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {LangLabelType} from "../../../../api/lang/langData";
import {TextureLoader} from "three";
import {animated, useSpring} from "@react-spring/web";
import {a} from "@react-spring/three";
import {easeCubicInOut} from "d3-ease";
import {useModalTutorial} from "../../../../stores/modalStore";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import useHistoryStore from "../../../../stores/historyStore";
import {GLTFLoader} from "three-stdlib";
import ObjetPlusButton from "../../ObjetPlusButton";

export type GLTFResult_ObjetNaturalProducts = GLTF & {
    nodes: {
        artcool: THREE.Mesh
        UI: THREE.Mesh
        art: THREE.Mesh
        Decal001: THREE.Mesh
        Glass001: THREE.Mesh
        Body_merged001: THREE.Mesh
        airconditioner: THREE.Mesh
        UI001: THREE.Mesh
        styler: THREE.Mesh
        Door: THREE.Mesh
        Ui_display: THREE.Mesh
        atom_u_sts: THREE.Mesh
        Decal005: THREE.Mesh
        Iron001: THREE.Mesh
        dishwasher: THREE.Mesh
        Decal004: THREE.Mesh
        aero_360: THREE.Mesh
        Mesh1756: THREE.Mesh
        Mesh1756_1: THREE.Mesh
        Door_Top005: THREE.Mesh
        UI005: THREE.Mesh
        Door_Bottom005: THREE.Mesh
        Glass005: THREE.Mesh
        m_next3: THREE.Mesh
        Door_BL015: THREE.Mesh
        Door_TL015: THREE.Mesh
        Door_BR015: THREE.Mesh
        Glass016: THREE.Mesh
        UI016: THREE.Mesh
        atom4d_plus: THREE.Mesh
        BodySide001: THREE.Mesh
        Decal003: THREE.Mesh
        oven: THREE.Mesh
        Decal002: THREE.Mesh
        r9: THREE.Mesh
        Glass: THREE.Mesh
        Decal_merged: THREE.Mesh
        aero_tower: THREE.Mesh
        harvester: THREE.Mesh
        harvester_glass: THREE.Mesh
        harvester_inner: THREE.Mesh
        l_n_f: THREE.Mesh
        l_n_f_door_l: THREE.Mesh
        l_n_f_door_r: THREE.Mesh
        l_n_f_handle_metal: THREE.Mesh
        l_n_f_handle_plastic: THREE.Mesh
    }
    materials: {
        ['mat_artcool_body.001']: THREE.MeshStandardMaterial
        mat_artcool_ui: THREE.MeshStandardMaterial
        mat_body2_merged: THREE.MeshStandardMaterial
        mat_ui_merged: THREE.MeshStandardMaterial
        ['Glass.002']: THREE.MeshStandardMaterial
        ['mat_body_merged.001']: THREE.MeshStandardMaterial
        mat_airconditioner_body: THREE.MeshStandardMaterial
        mat_airconditioner_ui: THREE.MeshStandardMaterial
        mat_styler_body: THREE.MeshStandardMaterial
        mat_styler_door: THREE.MeshStandardMaterial
        mat_styler_ui: THREE.MeshStandardMaterial
        mat_atom_u_sts_body: THREE.MeshStandardMaterial
        mat_atom_u_sts_decal: THREE.MeshStandardMaterial
        ['mat_body.003']: THREE.MeshStandardMaterial
        ['mat_decal.001']: THREE.MeshStandardMaterial
        ['mat_body.006']: THREE.MeshStandardMaterial
        mat_washtower_body_top: THREE.MeshStandardMaterial
        mat_washtower_body_bottom: THREE.MeshStandardMaterial
        mat_washtower_door_top: THREE.MeshStandardMaterial
        mat_washtower_ui: THREE.MeshStandardMaterial
        mat_washtower_door_bottom: THREE.MeshStandardMaterial
        mat_washtower_glass: THREE.MeshStandardMaterial
        mat_mnext3_body: THREE.MeshStandardMaterial
        mat_mnext3_door_bl: THREE.MeshStandardMaterial
        mat_mnext3_door_tl: THREE.MeshStandardMaterial
        mat_mnext3_door_br: THREE.MeshStandardMaterial
        mat_mnext3_glass: THREE.MeshStandardMaterial
        mat_mnext3_ui: THREE.MeshStandardMaterial
        mat_artcool_body: THREE.MeshStandardMaterial
        mat_artcool_side: THREE.MeshStandardMaterial
        mat_artcool_decal: THREE.MeshStandardMaterial
        mat_oven_body: THREE.MeshStandardMaterial
        mat_oven_decal: THREE.MeshStandardMaterial
        ['mat_body.002']: THREE.MeshStandardMaterial
        ['mat_glass.004']: THREE.MeshStandardMaterial
        mat_decal_merged: THREE.MeshStandardMaterial
        ['mat_body.005']: THREE.MeshStandardMaterial
        mat_harvester: THREE.MeshStandardMaterial
        mat_glass_dark: THREE.MeshStandardMaterial
        mat_harvester_inner: THREE.MeshStandardMaterial
        mat_lnf_body: THREE.MeshStandardMaterial
        mat_lnf_door_l: THREE.MeshStandardMaterial
        mat_lnf_door_r: THREE.MeshStandardMaterial
        mat_white_metal: THREE.MeshStandardMaterial
    }
}

type GLTFResult = GLTF & {
    nodes: {
        harvester: THREE.Mesh
        harvester_glass: THREE.Mesh
        harvester_inner: THREE.Mesh
    }
    materials: {
        mat_harvester: THREE.MeshStandardMaterial
        mat_glass_dark: THREE.MeshStandardMaterial
        mat_harvester_inner: THREE.MeshStandardMaterial
    }
}

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const tiiun_ColorCategorySelector = (state: ObjetColorState) => state.tiiun_ColorCategory;
const tiiun_ColorLabelSelector = (state: ObjetColorState) => state.tiiun_ColorLabel;



const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_tiiun_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_tiiun_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Harvester.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Harvester.jpg');
const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/harvester/harvester_beige.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/harvester/harvester_beige.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/harvester/harvester_green.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/harvester/harvester_green.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL
    ]);

    const tiiun_ColorCategory = useObjetColorStore(tiiun_ColorCategorySelector);
    const tiiun_ColorLabel = useObjetColorStore(tiiun_ColorLabelSelector);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({from: {opacity: 0}}));

    const changeTexture = (materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {
        if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_beige') {
                if (!isFirst) setIsSet(true);
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials.mat_harvester.map = colorTextures[0];

            } else {
                if (!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials.mat_harvester.map = colorTextures[1];
            }
        }
    };

    const pathname = useHistoryStore(state => state.pathname);
    const isActiveLnF = useMemo(()=>pathname.indexOf('lansen_freezer') > -1, [pathname]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials.mat_harvester.lightMap = lightmapTex;
        materials.mat_harvester.lightMapIntensity = 1;
        materials.mat_harvester_inner.lightMap = lightmapTex;
        materials.mat_harvester_inner.lightMapIntensity = 1;
        materials.mat_harvester_inner.emissiveIntensity = 1;
        materials.mat_glass_dark.color.setRGB(1, 1, 1);

    }, [lightmapTex]);

    useLayoutEffect(() => {
        changeTexture('mat_harvester', tiiun_ColorCategory, tiiun_ColorLabel);
    }, [tiiun_ColorCategory, tiiun_ColorLabel]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL
            ]);
        }
    }, []);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);


    return (
        <group ref={group} {...props} visible={!isActiveLnF} dispose={null}>
            <mesh
                geometry={nodes.harvester.geometry}
                material={materials.mat_harvester}
                position={[1.9478, 0.0931, -0.5577]}>
                <mesh
                    geometry={nodes.harvester_glass.geometry}
                    material={materials.mat_glass_dark}
                    position={[0.0036, 0.751, 0.1943]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.2245, 0.2245, 0.2245]}
                    visible={isSet}
                />
                <mesh
                    geometry={nodes.harvester_inner.geometry}
                    material={materials.mat_harvester_inner}
                    position={[0.0036, 0.751, 0.1916]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.2366, 0.2366, 0.2366]}
                />
            </mesh>

            {/* 흰색 영역 */}
            <mesh
                geometry={nodes.harvester.geometry}
                material={materials.mat_harvester}
                position={[1.9478, 0.0931, -0.5577]}
                visible={!isSet}
            >
                <mesh
                    geometry={nodes.harvester_glass.geometry}
                    material={materials.mat_glass_dark}
                    position={[0.0036, 0.751, 0.1943]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.2245, 0.2245, 0.2245]}
                    visible={!isSet}
                >
                    <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                </mesh>
                <mesh
                    geometry={nodes.harvester_inner.geometry}
                    material={materials.mat_harvester_inner}
                    position={[0.0036, 0.751, 0.1916]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.2366, 0.2366, 0.2366]}
                >
                    <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                </mesh>
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.7, 0]} visible={!(isSet || isActiveLnF)} opacity={spring.opacity} color={pathname.indexOf('/tiiun') > -1 ? '#be1e42' : '#000'}/>
            </mesh>

            <ObjetProductContainer3D
                args={[0.6, 0.8, 0.6]}
                position={[1.9478, 0.8, -0.58]}
                data={tiiun_data}
                showDebug={false}
                cameraTargetPos={[0, 0.4, 3]}
            />
        </group>
    )
}
