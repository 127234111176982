/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from '../../../common/const'
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_NaturalRoom} from "./NaturalRoomFloor";

type GLTFResult = GLTF & {
    nodes: {
        e_deco_ivyPot_leaf: THREE.Mesh
        e_props_module_02: THREE.Mesh
        e_deco_ivyPot_0: THREE.Mesh
        e_props_module_01: THREE.Mesh
    }
    materials: {
        e_ivyLeaf: THREE.MeshStandardMaterial
        e_mat_props_module_02: THREE.MeshStandardMaterial
        e_ivyPot: THREE.MeshStandardMaterial
        e_mat_props_module_01: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_props_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_props_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Props_Bake1_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Props_Bake1_cyclesbake_DIFFUSE.jpg');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    useLayoutEffect(()=>{
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

    }, [lightmapTex]);

    useEffect(()=>{
        return ()=>{
            disposeScene(scene);
            lightmapTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.e_deco_ivyPot_leaf.geometry}
                material={materials.e_ivyLeaf}
                position={[-2.32, 1.58, -5.63]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'e_deco_ivyPot_leaf' }}
            />
            <mesh
                geometry={nodes.e_props_module_02.geometry}
                material={materials.e_mat_props_module_02}
                userData={{ name: 'e_props_module_02' }}
            />
            <mesh
                geometry={nodes.e_deco_ivyPot_0.geometry}
                material={materials.e_ivyPot}
                position={[-2.32, 1.58, -5.63]}
                rotation={[-Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'e_deco_ivyPot_0' }}
            />
            <mesh
                geometry={nodes.e_props_module_01.geometry}
                material={materials.e_mat_props_module_01}
                position={[3.96, 1.63, 1.97]}
                userData={{ name: 'e_props_module_01' }}
            />
        </group>
    )
}
