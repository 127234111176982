//-------------------------------------------- Nature
import {ProductColorType} from "../productsData";

//Air Care
export const AirCare_Nature_Clay_Brown: ProductColorType = {
    name: <>Gray</>,
    label: 'objet_color_clay_brown',
    color: '/images/objet/nature/air_care/clay_brown.png',
    ga_id: "clay_brown"
};
export const AirCare_Nature_Cream_White: ProductColorType = {
    name: <>Black</>,
    label: 'objet_color_cream_white',
    color: '/images/objet/nature/air_care/cream_white.png',
    ga_id: "cream_white"
};
export const AirCare_Nature_Dark_Green: ProductColorType = {
    name: <>White</>,
    label: 'objet_color_dark_green',
    color: '/images/objet/nature/air_care/dark_green.png',
    ga_id: "green"
};
export const AirCare_Nature_RedWood: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_redwood',
    color: '/images/objet/nature/air_care/redwood.png',
    ga_id: "redwood"
};
export const AirCare_Nature_Green: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_green',
    color: '/images/objet/nature/air_care/dark_green.png',
    ga_id: "green"
};

//Diashwasher
export const Diashwasher_Nature_Beige: ProductColorType = {
    name: <>Red</>,
    label: 'objet_color_beige',
    color: '/images/objet/nature/dishwasher/beige.png',
    ga_id: "beige"
};

//Refrigerator
export const Refrigerator_Nature_Black: ProductColorType = {
    name: <>Beige</>,
    label: 'objet_color_black',
    color: '/images/objet/nature/refrigerator/black.png',
    ga_id: "black"
};
export const Refrigerator_Nature_Gray: ProductColorType = {
    name: <>Pink</>,
    label: 'objet_color_gray',
    color: '/images/objet/nature/refrigerator/gray.png',
    ga_id: "gray"
};
export const Refrigerator_Nature_White: ProductColorType = {
    name: <>Clay Brown</>,
    label: 'objet_color_white',
    color: '/images/objet/nature/refrigerator/white.png',
    ga_id: "white"
};

//Tiiun
export const Tiiun_Nature_Beige: ProductColorType = {
    name: <>RedWood</>,
    label: 'objet_color_beige',
    color: '/images/objet/nature/tiiun/beige.png',
    ga_id: "beige"
};
export const Tiiun_Nature_Green: ProductColorType = {
    name: <>Dark Green</>,
    label: 'objet_color_green',
    color: '/images/objet/nature/tiiun/green.png',
    ga_id: "green"
};

//Washer & Dryer
export const WashernDryer_Nature_Beige: ProductColorType = {
    name: <>Cream White</>,
    label: 'objet_color_beige',
    color: '/images/objet/nature/washer_n_dryer/beige.png',
    ga_id: "beige"
};
export const WashernDryer_Nature_Clay_Brown: ProductColorType = {
    name: <>Cream White</>,
    label: 'objet_color_clay_brown',
    color: '/images/objet/nature/clay_brown.png',
    ga_id: "clay_brown"
};
export const WashernDryer_Nature_Green: ProductColorType = {
    name: <>Cream White</>,
    label: 'objet_color_green',
    color: '/images/objet/nature/green.png',
    ga_id: "green"
};
export const WashernDryer_Nature_Pink: ProductColorType = {
    name: <>Cream White</>,
    label: 'objet_color_pink',
    color: '/images/objet/nature/pink.png',
    ga_id: "pink"
};
export const WashernDryer_Nature_RedWood: ProductColorType = {
    name: <>Cream White</>,
    label: 'objet_color_redwood',
    color: '/images/objet/nature/redwood.png',
    ga_id: "redwood"
};

