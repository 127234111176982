/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../../../utils/useKTXLoader";


type GLTFResult = GLTF & {
    nodes: {
        e_furniture_00_props005: THREE.Mesh
        e_furniture_00_marble005: THREE.Mesh
        e_furniture_00005: THREE.Mesh
    }
    materials: {
        mat_metal_white: THREE.MeshStandardMaterial
        mat_yule_marble: THREE.MeshStandardMaterial
        mat_cerused_maple_wood_3: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/m/laundry_wood_module_01_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/laundry_wood_module_01_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/laundry/laundry_wood_module_01_ktx2.glb';
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_module_01_cyclesbake_DIFFUSE_m.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_module_01_cyclesbake_DIFFUSE.exr');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    // @ts-ignore
    const lightmapTex = useLoader(EXRLoader, LIGHT_MAP_URL, (loader: EXRLoader) => {
        if (IS_MOBILE) {
            loader.setDataType(THREE.HalfFloatType);
        } else {
            loader.setDataType(THREE.FloatType);
        }
    });

    useLayoutEffect(()=>{
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

    }, [lightmapTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.e_furniture_00_props005.geometry}
                material={materials.mat_metal_white}
                userData={{ name: 'e_furniture_00_props.005' }}
            />
            <mesh
                geometry={nodes.e_furniture_00_marble005.geometry}
                material={materials.mat_yule_marble}
                userData={{ name: 'e_furniture_00_marble.005' }}
            />
            <mesh
                geometry={nodes.e_furniture_00005.geometry}
                material={materials.mat_cerused_maple_wood_3}
                userData={{ name: 'e_furniture_00.005' }}
            />
        </group>
    )
}
