/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_IPHONE, IS_LOW_TIER, IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {disposeScene} from "../../utils/disposeScene";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";
import {TextureLoader} from "three";

type GLTFResult = GLTF & {
    nodes: {
        silver: THREE.Mesh
        beige: THREE.Mesh
        Body: THREE.Mesh
    }
    materials: {
        silver: THREE.MeshStandardMaterial
        beige: THREE.MeshStandardMaterial
        ['silver.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = false ?
    (ROOT_PATH_PREFIX + '/RP/products/air_care/m/aero_tower_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_care/aero_tower_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_care/aero_tower_ktx2.glb';


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const bodyRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    //색상 변경 시
    useEffect(() => {
        if (selectedProduct?.modelNo === 'aerotower') {
            if (selectedColorIndex === 0) {
                bodyRef.current.material = materials.beige;
            } else if (selectedColorIndex === 1) {
                bodyRef.current.material = materials.silver;
            }
            materials.silver.envMapIntensity = 1;
            materials.beige.envMapIntensity = 3;
            materials.silver.metalness = 0.8;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        bodyRef.current.material = materials.beige;
        materials.silver.envMapIntensity = 1;
        materials.beige.envMapIntensity = 3;
        materials.silver.metalness = 0.8;

        console.log(">PC용 AreoTower");

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.silver.geometry}
                material={materials.silver}
                position={[0.1938, 5.5995, 0.0931]}
                scale={[0.0419, 0.0419, 0.0419]}
                userData={{name: 'silver'}}
            />
            <mesh
                geometry={nodes.beige.geometry}
                material={materials.beige}
                position={[0.0784, 5.5995, 0.0931]}
                scale={[0.0419, 0.0419, 0.0419]}
                userData={{name: 'beige'}}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={materials['silver.001']}
                userData={{name: 'Body'}}
                ref={bodyRef}
            />
        </group>
    )
}

//
// //---------------------------------------------------------------------------------------- [Mobile]
// type GLTFResult_M = GLTF & {
//     nodes: {
//         silver: THREE.Mesh
//         beige: THREE.Mesh
//         Body: THREE.Mesh
//     }
//     materials: {
//         silver: THREE.MeshStandardMaterial
//         beige: THREE.MeshStandardMaterial
//         ['silver.001']: THREE.MeshStandardMaterial
//     }
// }
//
// const GLTF_URL_M = ROOT_PATH_PREFIX + '/RP/products/air_care/aero_tower_ktx2.glb';
//
// const Model_M = (props: JSX.IntrinsicElements['group']) => {
//     const group = useRef<THREE.Group>()
//     const {nodes, materials, scene} = useKTXLoader<GLTFResult_M>(GLTF_URL_M);
//
//     const bodyRef = useRef<THREE.Mesh>(null!);
//     const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
//     const selectedProduct = useProductStore(state => state.selectedProduct);
//
//     //색상 변경 시
//     useEffect(() => {
//         if (selectedProduct?.modelNo === 'aerotower') {
//             if (selectedColorIndex === 0) {
//                 bodyRef.current.material = materials.beige;
//             } else if (selectedColorIndex === 1) {
//                 bodyRef.current.material = materials.silver;
//             }
//             materials.silver.envMapIntensity = 1;
//             materials.beige.envMapIntensity = 3;
//             materials.silver.metalness = 0.8;
//         }
//     }, [selectedProduct, selectedColorIndex]);
//
//     useEffect(() => {
//         bodyRef.current.material = materials.beige;
//         materials.silver.envMapIntensity = 1;
//         materials.beige.envMapIntensity = 3;
//         materials.silver.metalness = 0.8;
//
//         console.log(">모바일용 AreoTower");
//
//         return () => {
//             disposeScene(scene);
//             useLoader.clear(GLTFLoader, GLTF_URL);
//         }
//     }, []);
//
//     return (
//         <group ref={group} {...props} dispose={null}>
//             <mesh
//                 geometry={nodes.silver.geometry}
//                 material={materials.silver}
//                 position={[0.1938, 5.5995, 0.0931]}
//                 scale={[0.0419, 0.0419, 0.0419]}
//                 userData={{name: 'silver'}}
//             />
//             <mesh
//                 geometry={nodes.beige.geometry}
//                 material={materials.beige}
//                 position={[0.0784, 5.5995, 0.0931]}
//                 scale={[0.0419, 0.0419, 0.0419]}
//                 userData={{name: 'beige'}}
//             />
//             <mesh
//                 geometry={nodes.Body.geometry}
//                 material={materials['silver.001']}
//                 userData={{name: 'Body'}}
//                 ref={bodyRef}
//             />
//         </group>
//     )
// }

// const Aerotower = () => {
//     return (
//         <>
//             {IS_LOW_TIER ? (<Model_M/>) : (<Model />)}
//         </>
//     );
// };
// export default Aerotower;
