import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "../../../styles/swiper.tutorial.css";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../../common/const";
import { useModalTutorial } from "../../../stores/modalStore";
import { Text } from "../../common/comps_styled/Text.styled";
import { Image } from "../../common/comps_styled/Image.styled";
import {
  getLangTutorialData,
  LangTutorialDataType,
  LangTutorialLabelType,
} from "../../../api/lang/langTutorialData";
import useLangStore from "../../../stores/langStore";
import useProductStore from "../../../stores/productStore";
import useRightPanelStore from "../../../stores/rightPanelStore";
import theme from "../../../styles/theme.styled";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";
import useHistoryStore from "../../../stores/historyStore";
import { animated, useSpring } from "@react-spring/web";
import { getLangString } from "../../../api/lang/langData";

SwiperCore.use([Pagination]);

type Props = {} & JSX.IntrinsicElements["div"];

export default function Tutorial({}: Props): ReactElement {
  const setModalTutorial = useModalTutorial((state) => state.setIsOpen);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [swiper, setSwiper] = useState<any>(null);
  const isObjetMode = useRightPanelStore((state) => state.isObjetMode);
  const category = useProductStore((state) => state.selectedCategory?.category);
  const lang = useLangStore((state) => state.lang);
  const resize = useResizeStore((state) => state.resize);

  const langTutorialData = useMemo<LangTutorialDataType | null>(() => {
    return getLangTutorialData(
      lang,
      (isObjetMode ? "objet" : category) as LangTutorialLabelType
    );
  }, []);

  const pathname = useHistoryStore((state) => state.pathname);
  const isThinq = useMemo(() => pathname.includes("/thinq"), [pathname]);
  const isHome = useMemo(() => pathname.includes("/home"), [pathname]);
  const isObjet = useMemo(
    () => pathname.includes("/objetcollection"),
    [pathname]
  );

  const tutorial = IS_MOBILE
    ? langTutorialData?.mobile
    : langTutorialData?.desktop;

  const styles = useSpring({
    loop: { reverse: true },
    reset: true,
    from: { opacity: 1 },
    to: { opacity: 0 },
  });

  return (
    <Wrapper>
      {/* <Title style={{ position: "absolute" }}>LG Home User Guide</Title> */}
      <NavigationContainer
        selectedIndex={selectedIndex}
        pageTotal={swiper?.slides.length}
      >
        {selectedIndex !== 0 && (
          <PrevButton
            ratio={resize.ratio}
            onClick={() => {
              swiper?.slidePrev();
              isHome && GA_Event("lg_home", "menu", "previous");
              isObjet && GA_Event("lg_objet", "menu", "previous");
            }}
          >
            <Image
              alt="previous arrow"
              role="img"
              className="arrow"
              src={ROOT_PATH_PREFIX + "/images/icons/svg/btn_scroll_left.svg"}
              size={`${resize.ratio * (IS_MOBILE ? 6 : 4)}em`}
            />
            <Text
              className="text"
              size={`${resize.ratio * (IS_MOBILE ? 2 : 1.75)}em`}
              weight={theme.font.weight.semibold}
              color={theme.colors.common.graybrown}
            >
              {getLangString(lang, "guide_pre")?.text}
            </Text>
          </PrevButton>
        )}
        {selectedIndex !== swiper?.slides.length - 1 && (
          <NextButton
            ratio={resize.ratio}
            onClick={() => {
              swiper?.slideNext();
              isHome && GA_Event("lg_home", "menu", "next");
              isObjet && GA_Event("lg_objet", "menu", "next");
            }}
          >
            <BlinkButton style={{ ...styles }} ratio={resize.ratio}>
              <Image
                alt="blinking next arrow"
                role="img"
                style={{ color: "red" }}
                className="arrow"
                src={
                  ROOT_PATH_PREFIX +
                  "/images/icons/svg/btn_scroll_right_white.svg"
                }
                size={`${resize.ratio * (IS_MOBILE ? 6 : 4)}em`}
              />
              <Text
                className="text"
                size={`${resize.ratio * (IS_MOBILE ? 2 : 1.75)}em`}
                weight={theme.font.weight.semibold}
                color={"white"}
              >
                {getLangString(lang, "guide_next")?.text}
              </Text>
            </BlinkButton>
            <Image
              alt="next arrow"
              role="img"
              style={{ color: "red" }}
              className="arrow"
              src={ROOT_PATH_PREFIX + "/images/icons/svg/btn_scroll_right.svg"}
              size={`${resize.ratio * (IS_MOBILE ? 6 : 4)}em`}
            />

            <Text
              className="text"
              size={`${resize.ratio * (IS_MOBILE ? 2 : 1.75)}em`}
              weight={theme.font.weight.semibold}
              color={theme.colors.common.graybrown}
            >
              {getLangString(lang, "guide_next")?.text}
            </Text>
          </NextButton>
        )}
      </NavigationContainer>
      <Pagenation ratio={resize.ratio}>
        {tutorial?.map((data, index) => (
          <Dot ratio={resize.ratio} selected={selectedIndex === index} />
        ))}
      </Pagenation>
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        // pagination
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={(e) => {
          setSelectedIndex(e.activeIndex);
        }}
      >
        {tutorial?.map((src, index) => {
          return (
            <SwiperSlide key={index}>
              <Image alt="guide" role="img" src={ROOT_PATH_PREFIX + src} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div
        style={{
          position: "absolute",
          zIndex: Z_INDEX_POPUP,
          display:
            tutorial && selectedIndex === tutorial?.length - 1
              ? "flex"
              : "none",
          width: "100%",
          justifyContent: "center",
          bottom: `${resize.ratio * 2}em`,
        }}
      >
        <CloseButton
          onClick={() => {
            setModalTutorial(false);
            isHome && GA_Event("lg_home", "menu", "close");
            isObjet && GA_Event("lg_objet", "menu", "close");
          }}
        >
          <>
            <BlinkCloseButton style={styles} ratio={resize.ratio}>
              <Image
                alt="blinking close"
                role="img"
                src={
                  ROOT_PATH_PREFIX + "/images/icons/tutorial_close_blink.png"
                }
                size={`${resize.ratio * (IS_MOBILE ? 11 : 6.5)}em`}
              />
            </BlinkCloseButton>
            <Image
              alt="close"
              role="img"
              src={ROOT_PATH_PREFIX + "/images/icons/tutorial_close.png"}
              size={`${resize.ratio * (IS_MOBILE ? 11 : 6.5)}em`}
            />
          </>
          <Text
            color="white"
            weight={theme.font.weight.semibold}
            size={`${resize.ratio * 1.6}em`}
            style={{ paddingTop: `${resize.ratio * 0.3}em` }}
          >
            {getLangString(lang, "guide_close")?.text}
          </Text>
        </CloseButton>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  pointer-events: visible;
  display: flex;
`;

const Pagenation = styled.div<{ ratio: number }>`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: ${({ ratio }) => ratio * (IS_MOBILE ? 23 : 15.5)}em;

  span {
    margin: 0 ${({ ratio }) => ratio * 1}em;
  }
`;

const Dot = styled.span<{ ratio: number; selected: boolean }>`
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 3 : 1.8)}em;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 3 : 1.8)}em;
  border-radius: ${({ ratio }) => (ratio * (IS_MOBILE ? 3 : 1.8)) / 2}em;
  background-color: ${({ selected }) =>
    selected ? "white" : "rgba(255, 255, 255, 0.4)"};
`;

const NavigationContainer = styled.div<{
  selectedIndex?: number;
  pageTotal: number;
}>`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  display: flex;
  justify-content: ${({ selectedIndex, pageTotal }) =>
    selectedIndex !== 0 && selectedIndex !== pageTotal - 1
      ? "space-between"
      : selectedIndex === 0
      ? "flex-end"
      : "flex-start"};
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PrevButton = styled(animated.button)<{ ratio: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 11 : 10)}em;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 19 : 17)}em;
  background-color: #f1f1f1;
  border-top-right-radius: ${({ ratio }) => ratio * 1}em;
  border-bottom-right-radius: ${({ ratio }) => ratio * 1}em;
  cursor: pointer;
  pointer-events: visible;

  .arrow {
    margin-bottom: ${({ ratio }) => ratio * 2}em;
  }
`;

const NextButton = styled(PrevButton)`
  border-radius: 0;
  border-top-left-radius: ${({ ratio }) => ratio * 1}em;
  border-bottom-left-radius: ${({ ratio }) => ratio * 1}em;
`;

const BlinkButton = styled(NextButton)`
  /* border: ${({ ratio }) => ratio * (IS_MOBILE ? 6 : 3)}px solid
    ${({ theme }) => theme.colors.home.pink} !important; */
  position: absolute;
  background-color: ${({ theme }) => theme.colors.home.pink};
  pointer-events: none;
`;

const BlinkCloseButton = styled(animated.div)<{ ratio: number }>`
  position: absolute;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 12 : 7.5)}em;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 12 : 7.5)}em;
  /* border: 1px solid tomato; */
  display: flex;
  justify-content: center;

  > img {
    image-rendering: auto;
  }
  /* 
  > div {
    width: ${({ ratio }) => ratio * (IS_MOBILE ? 8 : 5.1)}em;
    height: ${({ ratio }) => ratio * (IS_MOBILE ? 8 : 5.1)}em;
    border-radius: ${({ ratio }) => (ratio * (IS_MOBILE ? 8 : 5.1)) / 2}em;
    border: ${({ ratio }) => ratio * (IS_MOBILE ? 6 : 3)}px solid
      ${({ theme }) => theme.colors.home.pink} !important;
  } */
`;

const CloseButton = styled(animated.button)`
  pointer-events: visible;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
