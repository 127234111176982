import { LangType } from "../../stores/langStore";

export type LangTutorialLabelType =
  // Home
  | ""
  | "air_care"
  | "air_conditioner"
  | "cooking"
  | "dishwasher"
  | "homebrew"
  | "microwave_oven"
  | "refrigerator"
  | "styler"
  | "tiiun"
  | "tiiun_mini"
  | "vacuum_cleaner"
  | "washer_n_dryer"
  | "water_purifier"
  | "wearable_air_purifier"

  //Objet
  | "objet";

export type LangTutorialDataType = {
  langType: LangType;
  label: LangTutorialLabelType; //검색용 키워드
  desktop: string[]; // PC용 가이드 이미지
  mobile: string[]; // 모바일용 가이드 이미지
};

const langTutorialData: LangTutorialDataType[] = [
  // HOME
  // EN
  {
    langType: "en",
    label: "air_care",
    desktop: [
      "/images/tutorial/home/desktop/AirCare/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/AirCare/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/AirCare/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/AirCare/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "air_conditioner",
    desktop: [
      "/images/tutorial/home/desktop/RAC/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/RAC/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/RAC/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/RAC/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "cooking",
    desktop: [
      "/images/tutorial/home/desktop/Cooking/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Cooking/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Cooking/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Cooking/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "dishwasher",
    desktop: [
      "/images/tutorial/home/desktop/DishWasher/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/DishWasher/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/DishWasher/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/DishWasher/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "homebrew",
    desktop: [
      "/images/tutorial/home/desktop/HomeBrew/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/HomeBrew/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/HomeBrew/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/HomeBrew/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "microwave_oven",
    desktop: [
      "/images/tutorial/home/desktop/OTR/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/OTR/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/OTR/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/OTR/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "refrigerator",
    desktop: [
      "/images/tutorial/home/desktop/Refrigerator/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Refrigerator/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Refrigerator/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Refrigerator/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "styler",
    desktop: [
      "/images/tutorial/home/desktop/Styler/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Styler/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Styler/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Styler/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "tiiun",
    desktop: [
      "/images/tutorial/home/desktop/Harvester/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/Harvester/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "tiiun_mini",
    desktop: [
      "/images/tutorial/home/desktop/Tiiun/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/Tiiun/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "vacuum_cleaner",
    desktop: [
      "/images/tutorial/home/desktop/Cleaner/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Cleaner/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Cleaner/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Cleaner/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "washer_n_dryer",
    desktop: [
      "/images/tutorial/home/desktop/WasherDryer/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/WasherDryer/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WasherDryer/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WasherDryer/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "water_purifier",
    desktop: [
      "/images/tutorial/home/desktop/WaterPurifier/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WaterPurifier/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WaterPurifier/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WaterPurifier/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "en",
    label: "wearable_air_purifier",
    desktop: [
      "/images/tutorial/home/desktop/WAP/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WAP/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WAP/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WAP/en/bg-guide-2.jpg",
    ],
  },

  // ES
  {
    langType: "es",
    label: "air_care",
    desktop: [
      "/images/tutorial/home/desktop/AirCare/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/AirCare/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/AirCare/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/AirCare/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "air_conditioner",
    desktop: [
      "/images/tutorial/home/desktop/RAC/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/RAC/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/RAC/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/RAC/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "cooking",
    desktop: [
      "/images/tutorial/home/desktop/Cooking/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Cooking/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Cooking/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Cooking/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "dishwasher",
    desktop: [
      "/images/tutorial/home/desktop/DishWasher/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/DishWasher/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/DishWasher/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/DishWasher/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "homebrew",
    desktop: [
      "/images/tutorial/home/desktop/HomeBrew/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/HomeBrew/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/HomeBrew/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/HomeBrew/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "microwave_oven",
    desktop: [
      "/images/tutorial/home/desktop/OTR/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/OTR/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/OTR/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/OTR/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "refrigerator",
    desktop: [
      "/images/tutorial/home/desktop/Refrigerator/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Refrigerator/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Refrigerator/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Refrigerator/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "styler",
    desktop: [
      "/images/tutorial/home/desktop/Styler/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Styler/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Styler/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Styler/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "tiiun",
    desktop: [
      "/images/tutorial/home/desktop/Harvester/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/Harvester/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "tiiun_mini",
    desktop: [
      "/images/tutorial/home/desktop/Tiiun/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/Tiiun/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "vacuum_cleaner",
    desktop: [
      "/images/tutorial/home/desktop/Cleaner/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Cleaner/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Cleaner/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Cleaner/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "washer_n_dryer",
    desktop: [
      "/images/tutorial/home/desktop/WasherDryer/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WasherDryer/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WasherDryer/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WasherDryer/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "water_purifier",
    desktop: [
      "/images/tutorial/home/desktop/WaterPurifier/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WaterPurifier/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WaterPurifier/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WaterPurifier/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "es",
    label: "wearable_air_purifier",
    desktop: [
      "/images/tutorial/home/desktop/WAP/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WAP/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WAP/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WAP/en/bg-guide-2.jpg",
    ],
  },

  // KR
  {
    langType: "kr",
    label: "air_care",
    desktop: [
      "/images/tutorial/home/desktop/AirCare/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/AirCare/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/AirCare/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/AirCare/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "air_conditioner",
    desktop: [
      "/images/tutorial/home/desktop/RAC/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/RAC/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/RAC/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/RAC/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "cooking",
    desktop: [
      "/images/tutorial/home/desktop/Cooking/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Cooking/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Cooking/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Cooking/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "dishwasher",
    desktop: [
      "/images/tutorial/home/desktop/DishWasher/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/DishWasher/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/DishWasher/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/DishWasher/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "homebrew",
    desktop: [
      "/images/tutorial/home/desktop/HomeBrew/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/HomeBrew/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/HomeBrew/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/HomeBrew/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "microwave_oven",
    desktop: [
      "/images/tutorial/home/desktop/OTR/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/OTR/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/OTR/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/OTR/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "refrigerator",
    desktop: [
      "/images/tutorial/home/desktop/Refrigerator/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Refrigerator/en/bg-guide-2.jpg",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Refrigerator/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Refrigerator/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "styler",
    desktop: [
      "/images/tutorial/home/desktop/Styler/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Styler/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Styler/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Styler/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "tiiun",
    desktop: [
      "/images/tutorial/home/desktop/Harvester/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/Harvester/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "tiiun_mini",
    desktop: [
      "/images/tutorial/home/desktop/Tiiun/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/Tiiun/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Harvester/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "vacuum_cleaner",
    desktop: [
      "/images/tutorial/home/desktop/Cleaner/en/bg-guide-1.jpg",
      "/images/tutorial/home/desktop/Cleaner/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/Cleaner/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/Cleaner/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "washer_n_dryer",
    desktop: [
      "/images/tutorial/home/desktop/WasherDryer/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WasherDryer/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WasherDryer/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WasherDryer/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "water_purifier",
    desktop: [
      "/images/tutorial/home/desktop/WaterPurifier/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WaterPurifier/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WaterPurifier/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WaterPurifier/en/bg-guide-2.jpg",
    ],
  },
  {
    langType: "kr",
    label: "wearable_air_purifier",
    desktop: [
      "/images/tutorial/home/desktop/WAP/en/bg-guide-1.png",
      "/images/tutorial/home/desktop/WAP/en/bg-guide-2.png",
    ],
    mobile: [
      "/images/tutorial/home/mobile/WAP/en/bg-guide-1.jpg",
      "/images/tutorial/home/mobile/WAP/en/bg-guide-2.jpg",
    ],
  },

  // OBJET
  // EN
  {
    langType: "en",
    label: "objet",
    desktop: [
      // 네추럴 가이드
      "/images/tutorial/objet/desktop/en/img-guide-01.jpg",
      "/images/tutorial/objet/desktop/en/img-guide-02.png",
      "/images/tutorial/objet/desktop/en/img-guide-03.png",
      // 모던 가이드
      // "/images/tutorial/objet/desktop/en/LG Object_Guide_01.jpg",
      // "/images/tutorial/objet/desktop/en/LG Object_Guide_02.jpg",
      // "/images/tutorial/objet/desktop/en/LG Object_Guide_03.jpg",
    ],
    mobile: [
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_ENG 01.png",
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_ENG 02.png",
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_ENG 03.png",
    ],
  },
  // ES
  {
    langType: "es",
    label: "objet",
    desktop: [
      "/images/tutorial/objet/desktop/en/LG Objet User Guide_ENG 01.png",
      "/images/tutorial/objet/desktop/en/LG Objet User Guide_ENG 02.png",
      "/images/tutorial/objet/desktop/en/LG Objet User Guide_ENG 03.png",
    ],
    mobile: [
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_ENG 01.png",
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_ENG 02.png",
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_ENG 03.png",
    ],
  },
  // KR
  {
    langType: "kr",
    label: "objet",
    desktop: [
      "/images/tutorial/objet/desktop/en/LG Objet User Guide_KOR 01.png",
      "/images/tutorial/objet/desktop/en/LG Objet User Guide_KOR 02.png",
      "/images/tutorial/objet/desktop/en/LG Objet User Guide_KOR 03.png",
    ],
    mobile: [
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_KOR 01.png",
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_KOR 02.png",
      "/images/tutorial/objet/mobile/en/LG Objet User Guide_KOR 03.png",
    ],
  },
];

export function getLangTutorialData(
  langType: LangType,
  label: LangTutorialLabelType
) {
  const result = langTutorialData
    .filter((val) => val.langType === langType)
    .filter((val2) => val2.label === label);
  return result && result.length > 0 ? result[0] : null;
}

export default langTutorialData;
