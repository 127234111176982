import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { animated } from "@react-spring/web";
import theme from "../../../styles/theme.styled";
import { Center, Container } from "../../common/comps_styled/Container.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { Divider } from "../../common/comps_styled/Divider.styled";
import QRCode from "react-qr-code";
import useProductStore from "../../../stores/productStore";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";
import { IS_MOBILE } from "../../common/const";
import useLangStore from "../../../stores/langStore";
import { getLangString, LangLabelType } from "../../../api/lang/langData";

type CloseBtnProps = {} & JSX.IntrinsicElements["button"];
const CloseBtn = ({ ...rest }: CloseBtnProps) => {
  return (
    <CloseBtnStyle id="closeBtn" title="Close ARBoard" {...rest}>
      <div className="icon">
        <div className="line line1" />
        <div className="line line2" />
      </div>
    </CloseBtnStyle>
  );
};

type ARBoardProps = {
  closeHandler: () => void;
} & JSX.IntrinsicElements["div"];
const ARBoard = ({ closeHandler, ...rest }: ARBoardProps) => {
  const lang = useLangStore((state) => state.lang);
  const resize = useResizeStore((state) => state.resize);
  const qr =
    useProductStore((state) => state.selectedProduct?.ar_url) ??
    "https://www.lg.com/global/exhibition";
  const label = useProductStore((state) => state.selectedProduct?.ar_text) ?? (
    <></>
  );

  return (
    <ThemeProvider theme={theme}>
      <Wrapper ratio={resize.ratio} {...rest}>
        <CloseBtn
          style={{ top: "-0.5em", right: "-0.5em" }}
          onClick={(e) => {
            e.stopPropagation();
            closeHandler();
          }}
        />
        <Center style={{ padding: `${IS_MOBILE ? "0.3em" : 5} 0` }}>
          <Text
            color="white"
            size={`${IS_MOBILE ? 4.5 : 3.25}em`}
            style={{ padding: "2.35% 0" }}
          >
            AR Experience
          </Text>
        </Center>
        <Divider
          borderwidth={resize.ratio * (IS_MOBILE ? 12 : 3.5)}
          color="#C00C3F"
        />
        <Container
          style={{
            height: "100%",
            backgroundColor: "#282828",
            padding: `${resize.ratio * (IS_MOBILE ? 5 : 3.5)}em 2em`,
          }}
        >
          <Text color="white" size={`${IS_MOBILE ? 2.6 : 2}em`} align="center">
            {getLangString(lang, label as LangLabelType)?.text}
          </Text>
          <Center className="Container" style={{ flex: 1, marginTop: "2em" }}>
            <div
              style={{
                border: "1px solid white",
                padding: "1em",
                backgroundColor: "white",
              }}
            >
              <QRCode value={qr} size={170} />
            </div>
          </Center>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

const Wrapper = styled(animated.div)<{ ratio: number } & Record<string, any>>`
  width: ${IS_MOBILE ? 55 : 45}em;
  height: 33em;
  background: #1d1d1d;
  position: absolute;
  right: ${IS_MOBILE ? 12 : 10}em;
`;

const CloseBtnStyle = styled.button<Record<string, any>>`
  position: absolute;
  top: 0;
  right: 0;
  width: 3em;
  height: 3em;
  margin: 0.7em;
  background-color: rgba(0, 0, 0, 0);
  //background: red;
  outline: none;
  border: none;
  border-radius: 3rem;
  pointer-events: visible;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    position: absolute;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
      background: #fff;
      height: 0.1em;
      min-height: 1.5px;
      /* width: 1.2em; */
      width: 2em;
      position: absolute;
    }

    .line1 {
      transform: rotate(45deg);
    }

    .line2 {
      transform: rotate(-45deg);
    }
  }

  :focus-visible {
    background-color: rgba(0, 0, 0, 1);
    filter: drop-shadow(0 1px 15px rgba(255, 255, 255, 1));
  }

  :hover {
    .line {
      background: #ac2551;
    }
  }
`;

export default ARBoard;
