/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/**
 * CW Lupin2 Elec. S/in Double Best
 * */
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {useKTXLoader} from "../../utils/useKTXLoader";
import { disposeScene } from '../../utils/disposeScene';
import { useLoader } from '@react-three/fiber';


type GLTFResult = GLTF & {
  nodes: {
    Plane003: THREE.Mesh
    Plane002: THREE.Mesh
    Mesh048: THREE.Mesh
    Mesh048_1: THREE.Mesh
    Mesh048_2: THREE.Mesh
    Glass_1001: THREE.Mesh
    LG_LOGO_DECAL: THREE.Mesh
    Body005: THREE.Mesh
    Induction: THREE.Mesh
    Decal002: THREE.Mesh
  }
  materials: {
    MetalBlack: THREE.MeshStandardMaterial
    MetalSilver: THREE.MeshStandardMaterial
    ['Oven_05_Albedo.001']: THREE.MeshStandardMaterial
    ['Oven_05_Albedo.006']: THREE.MeshStandardMaterial
    ['body.004']: THREE.MeshStandardMaterial
    ['Glass.005']: THREE.MeshStandardMaterial
    ['LG_Logo_Decal.004']: THREE.MeshStandardMaterial
    ['01 - Default.001']: THREE.MeshStandardMaterial
    ['Decal.001']: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/oven/m/oven_elec_sin_double_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/oven/oven_elec_sin_double_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  //@ts-ignore
  const { nodes, materials, scene } = useKTXLoader(ROOT_PATH_PREFIX + '/RP/products/oven/oven_elec_sin_double_ktx2.glb') as GLTFResult

  const mesh1_Ref = useRef<THREE.Mesh>(null!);
  const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
  const selectedProduct = useProductStore(state => state.selectedProduct);

  useEffect(()=>{
    if(selectedProduct?.modelNo === 'lupin_elec_double'){
      materials.MetalBlack.color.setRGB(0.15, 0.15, 0.15);
      if(selectedColorIndex === 1){
        mesh1_Ref.current.material = materials.MetalSilver;
      }
      else{
        mesh1_Ref.current.material = materials.MetalBlack;
      }
      // mesh1_Ref.current.material.needsUpdate = true;
    }
  }, [selectedProduct, selectedColorIndex]);

  useEffect(() => {
    return () => {
      disposeScene(scene);
      useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/RP/products/oven/oven_elec_sin_double_ktx2.glb');
    }
  }, []);

  return (
      <group ref={group} {...props} position={[0, -0.035, 0.32]}>
        <mesh geometry={nodes.Plane003.geometry} material={materials.MetalBlack} />
        <mesh geometry={nodes.Plane002.geometry} material={nodes.Plane002.material} />
        <group position={[0, 0.4292, -0.3132]} scale={[0.01, 0.01, 0.01]}>
          <mesh geometry={nodes.Mesh048.geometry} material={materials['Oven_05_Albedo.001']} />
          <mesh geometry={nodes.Mesh048_1.geometry} material={materials['Oven_05_Albedo.006']} />
          <mesh geometry={nodes.Mesh048_2.geometry} material={materials['body.004']} />
          <mesh
              geometry={nodes.Glass_1001.geometry}
              material={materials['Glass.005']}
              position={[-0.0475, -42.9209, 17.8154]}
              scale={[0.1, 0.1, 0.1]}
          />
          <mesh
              geometry={nodes.LG_LOGO_DECAL.geometry}
              material={materials['LG_Logo_Decal.004']}
              position={[0, -36.7335, 23.5517]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={[7.5, 100, 7.5]}
          />
          <mesh
              geometry={nodes.Body005.geometry}
              material={nodes.Body005.material}
              position={[13.6229, -45.9979, -2.0582]}
              ref={mesh1_Ref}
          />
          <mesh
              geometry={nodes.Induction.geometry}
              material={materials['01 - Default.001']}
              position={[13.3229, -45.9236, -2.419]}
          />
          <mesh
              geometry={nodes.Decal002.geometry}
              material={materials['Decal.001']}
              position={[13.6229, -45.9979, -2.0582]}
          />
        </group>
      </group>
  )
}
