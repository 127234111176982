import React, {useEffect} from 'react';
import Product_Harvester from "./Product_Harvester";
import Product_LnF from "./Product_LnF";
import Product_Aero360 from "./Product_Aero360";
import Product_AeroTower from "./Product_AeroTower";
import Product_R9 from "./Product_R9";
import Product_Art from "./Product_Art";
import Product_AirConditioner from "./Product_AirConditioner";
import Product_MNext3 from "./Product_MNext3";
import Product_WashTower from "./Product_WashTower";
import Product_DishWasher from "./Product_DishWasher";
import Product_Styler from "./Product_Styler";
import Product_Oven from "./Product_Oven";
import Product_AtomUSTS from "./Product_AtomUSTS";
import Product_Atom4dPlus from "./Product_Atom4dPlus";
import Product_Artcool from "./Product_Artcool";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');


const ModernRoomProducts = () => {

    useEffect(() => {
        return () => {
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <>
            <Product_Harvester />
            <Product_LnF />
            <Product_Aero360 />
            <Product_AeroTower />
            <Product_R9 />
            <Product_Art />
            <Product_AirConditioner />
            <Product_MNext3 />
            <Product_WashTower />
            <Product_DishWasher />
            <Product_Styler />
            <Product_Oven />
            <Product_AtomUSTS />
            <Product_Atom4dPlus />
            <Product_Artcool />
        </>
    );
};

export default ModernRoomProducts;
