import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import useRightPanelStore from "../../../../stores/rightPanelStore";
import { IS_MOBILE } from "../../../common/const";




type ProductInfoButtonProps = {
  images: [normal: string, hovered: string, selected: string];
  selected?: boolean;
} & JSX.IntrinsicElements["button"];

const ProductInfoButton = ({
  images,
  selected,
  ...rest
}: ProductInfoButtonProps) => {
  const setIsOpen = useRightPanelStore((state) => state.setIsOpen);
  const ref = useRef<HTMLButtonElement>(null!);
  const imgRef = useRef<HTMLImageElement>(null!);

  function onPointerOutHandler(e: PointerEvent) {
    e.stopPropagation();
    imgRef.current.src = images[selected ? 2 : 0];
  }

  function onPointerOverHandler(e: PointerEvent) {
    e.stopPropagation();
    imgRef.current.src = images[selected ? 2 : 1];
  }

  function onPointerDownHandler(e: PointerEvent) {
    e.stopPropagation();
    imgRef.current.src = images[2];
    //
    if (rest.title === "Info" || rest.title === "AR Experience") {
      setTimeout(() => setIsOpen(false), 100);
    }
  }

  useLayoutEffect(() => {
    imgRef.current.src = images[selected ? 2 : 0];
  }, [selected]);

  return (
    // <Html style={{pointerEvents:'none'}} sprite={false}>
    <Wrapper
      ref={ref}
      onPointerOver={onPointerOverHandler}
      onPointerOut={onPointerOutHandler}
      onPointerDown={onPointerDownHandler}
      {...rest}
    >
      <img ref={imgRef} src={images[0]} alt={rest.title} role="img" />

      {/* 캐시 용도 */}
      <div className="hidden">
        <img src={images[1]} alt={rest.title} />
        <img src={images[2]} alt={rest.title} />
      </div>
    </Wrapper>
    // </Html>
  );
};

const Wrapper = styled.button<Record<string, any>>`
  pointer-events: visible;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  width: ${IS_MOBILE ? 3.5 : 7}em;
  height: ${IS_MOBILE ? 3.5 : 7}em;
  margin: ${IS_MOBILE ? 0.3 : 0.5}em;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

  .hidden {
    display: none;
  }

  :focus-visible {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
  }
`;

export default ProductInfoButton;
