import create from "zustand";
import * as THREE from "three";


/**
 * 카메라 이동 관련 상태관리
 */
export type CameraState = {
    targetPos: THREE.Vector3 | null;
    lookPos: THREE.Vector3 | null;
    touchStart: boolean;
    isOpenInfo: boolean;
    api: {
        setTargetPos: (pos: THREE.Vector3 | null) => void;
        setLookPos: (pos: THREE.Vector3 | null) => void;
        setTouchStart: (value: boolean) => void;
        setIsOpenInfo: (value: boolean) => void;
    }
};

const useCameraStore = create<CameraState>(set => ({
    targetPos: null,
    lookPos: null,
    touchStart: false,
    isOpenInfo: false,
    api: {
        setTargetPos: pos => set(state => ({targetPos: pos, touchStart: pos !== null})),
        setLookPos: pos => set(state => ({lookPos: pos})),
        setTouchStart: value => set({touchStart: value}),
        setIsOpenInfo: value => set({isOpenInfo: value})
    }
}));

export default useCameraStore;
