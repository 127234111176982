import React, {useMemo} from "react";
import {useSpring, animated} from "@react-spring/web";
import styled from "styled-components";
import {IS_MOBILE, KEY_COLOR, ROOT_PATH_PREFIX} from "../const";
import useHistoryStore from "../../../stores/historyStore";
import {GA_Event, sendGaEvent} from "../../../api/ga";
import {Image} from "../comps_styled/Image.styled";
import useResizeStore from "../../../stores/resizeStore";

/**
 * BACK TO PREVIOUS버튼
 * @constructor
 */
type Props = {
    onClickHandler?: () => void;
};

const BackButton = ({onClickHandler}: Props) => {
    const goBack = useHistoryStore((state) => state.goBack);
    const pathname = useHistoryStore((state) => state.pathname);
    const resize = useResizeStore((state) => state.resize);

    const isThinq = useMemo(() => pathname.includes("/thinq"), [pathname]);
    const isHome = useMemo(() => pathname.includes("/home"), [pathname]);
    const isObjet = useMemo(
        () => pathname.includes("/objetcollection"),
        [pathname]
    );

    return (
        <Wrapper
            ratio={resize.ratio}
            onClick={() => {
                onClickHandler ? onClickHandler() : goBack();
                //
                isHome && GA_Event("lg_home", "menu", "back_to_previous_page");
                isObjet && GA_Event("lg_objet", "menu", "back_to_previous_page");
                if (isThinq) {
                    if (pathname.indexOf("/thinq/easy_convenient") > -1) {
                        sendGaEvent("thinq_menu_easy&convenient_back_to_previous");
                    } else if (pathname.indexOf("/thinq/unique_delightful") > -1) {
                        sendGaEvent("thinq_menu_unique&delightful_back_to_previous");
                    } else if (pathname === "/thinq") {
                        sendGaEvent("thinq_menu_back_to_previous");
                    }
                }
            }}
            style={{
                width: IS_MOBILE ? '15em' : 'auto'
            }}
        >
            <img
                className="backImg"
                alt="back to previous"
                role="img"
                src={ROOT_PATH_PREFIX + "/images/topui/btn_back-to-previous.png"}
            />
            <LG_logo
                alt="lg logo"
                role="img"
                ratio={resize.ratio}
                src={ROOT_PATH_PREFIX + "/images/topui/lg_logo.png"}
            />
        </Wrapper>
    );
};

const Wrapper = styled(animated.button)<{ ratio: number } & Record<string, any>>`
  display: flex;
  flex: auto;
  background-color: rgba(0, 0, 0, 0);
  //background: red;
  pointer-events: visible;
  cursor: pointer;
  position: absolute;
  //width: ${({ratio}) => ratio * (IS_MOBILE ? 50 : 29)}em;
  height: ${({ratio}) => ratio * (IS_MOBILE ? 16 : 9.5)}em;
  // top: ${({ratio}) => ratio * (IS_MOBILE ? 1 : 0.8)}em;
  top: 0;
  left: ${({ratio}) => ratio * (IS_MOBILE ? 0 : 2)}em;
  padding-left: 3rem;
  
  .backImg{
    height: 100%;
  }
`;

const LG_logo = styled.img<{ ratio: number } & Record<string, any>>`
  position: absolute;
  width: ${({ratio}) => ratio * (IS_MOBILE ? 26 : 20)}em;
  top: ${({ratio}) => ratio * (IS_MOBILE ? 11 : 7.5)}em;
  left: ${({ratio}) => ratio * (IS_MOBILE ? 7 : 4)}em;
`;

export default BackButton;
