import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {useProgress} from "@react-three/drei";
import styled from "styled-components";
import {useSpring, animated} from "@react-spring/web";
import useHistoryStore from "../../stores/historyStore";
import {ROOT_PATH_PREFIX} from "./const";


const LoaderView = () => {

    const [isLoadingRoom, setIsLoadingRoom] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const {active, progress, errors, item, loaded, total} = useProgress();
    const timeout = useRef<NodeJS.Timeout>();
    const [prevProgress, setPrevProgress] = useState(0);
    const pathname = useHistoryStore(state => state.pathname);


    const spring = useSpring({
        opacity: isActive ? 1 : 0
    });

    useLayoutEffect(() => {
        if (prevProgress > progress) {
            // console.log("이전보다 낮은 값이 들어왔다.", prevProgress, progress);
        } else {
            setPrevProgress(progress);
        }
    }, [progress, prevProgress]);


    useLayoutEffect(() => {
        // console.log("--->", active, progress, " ---->", item);

        if (!isActive && active) {
            // console.log("처음!!!");
            setPrevProgress(0);
        }
        // console.log(progress, active);
        if (active) {
            if (progress === 0) {

                setIsActive(true);

                //공간 데이터 로드
                if (item.indexOf('rooms') > -1 || item.indexOf('pmndrs') > -1) {
                    setIsLoadingRoom(true);
                    console.log("공간 데이터 로드 시작~~!!!", item);
                }
                //제품 데이터 로드
                else {
                    setIsLoadingRoom(false);
                    console.log("제품 데이터 로드 시작~~!!!", item);
                }
            }
        }
        if (progress >= 99) {
            //공간 데이터 로드
            if (item.indexOf('rooms') > -1) {
                console.log("공간 데이터 로드 완료!!", item);
            }
            //제품 데이터 로드
            else {
                console.log("제품 데이터 로드 완료!!", item);
            }

            if (timeout.current) clearTimeout(timeout.current);
            timeout.current = setTimeout(() => {
                setIsActive(false);
            }, 2000);
        }
    }, [active, progress, errors, item, loaded, total]);

    const classNames = useMemo(() => {
        let result = "";
        if (pathname.indexOf('/home/kitchen') > -1) {
            result = "bg kitchen_bg"
        } else if (pathname.indexOf('/home/living') > -1) {
            result = "bg living_bg"
        } else if (pathname.indexOf('/home/utility') > -1) {
            result = "bg laundry_bg"
        } else if (pathname.indexOf('/objetcollection') > -1) {
            result = "bg objet_bg"
        } else result = "bg kitchen_bg";

        return result;
    }, [pathname]);


    return (
        <Wrapper
            style={{
                opacity: isActive ? 1 : spring.opacity,
                background: isLoadingRoom ? '#000' : 'transparent',
                pointerEvents: isActive && isLoadingRoom ? 'visible' : 'none'
            }}
            backgroundSize={"contain"}
        >
            {isLoadingRoom && <div className={`${classNames}`}/>}

            {isLoadingRoom ? (
                <div className='loading-group'>
                    <div className='loading-bar'>
                        <span>{prevProgress.toFixed(0)} %</span>
                        <div className='loading-bar-bg'>
                            <div className='loading-bar-progress' style={{
                                width: prevProgress + '%'
                            }}/>
                        </div>
                    </div>
                    <div className='loading-desc'>
                        Please wait a moment.<br/>
                        We are preparing Virtual Exhibition for you.
                    </div>
                </div>
            ) : (
                <span>Installing... {prevProgress.toFixed(0)}%</span>
            )}
        </Wrapper>
    );
};

const Wrapper = styled(animated.div)<{ backgroundSize: string; }>`
  pointer-events: none;
  position: absolute;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: #fff;
  opacity: 1;

  .loading-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    //background: red;
    width: 100%;
    //height: 50rem;
    z-index: 999999999;
    position: absolute;
    bottom: 0;
    padding: 5rem;

    .loading-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      span {
        width: 6rem;
        text-align: right;
        position: relative;
        //margin-right: 1rem;
        //background: red;
      }

      .loading-bar-bg {
        width: 70rem;
        height: 1.2rem;
        margin: 0 1rem;
        background: #51504d;
        border-radius: 1rem;
        display: inline-flex;
        flex-direction: row;

        .loading-bar-progress {
          width: 50%;
          height: 100%;
          background: #fff;
          border-radius: 1rem;
        }
      }
    }

    .loading-desc {
      margin-top: 1.5rem;
      text-align: center;
      color: #777672;
      font-weight: bold;
    }
  }


  .bg {
    width: 100%;
    height: 100%;
    bottom: 3rem;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${(props) => props.backgroundSize};
    opacity: 1;
  }

  .kitchen_bg {
    background-image: url("${ROOT_PATH_PREFIX}/images/loading/bg_loading_page_lghome_01.jpg");
  }

  .living_bg {
    background-image: url("${ROOT_PATH_PREFIX}/images/loading/bg_loading_page_lghome_03.jpg");
  }

  .laundry_bg {
    background-image: url("${ROOT_PATH_PREFIX}/images/loading/bg_loading_page_lghome_02.jpg");
  }

  .objet_bg {
    background-image: url("${ROOT_PATH_PREFIX}/images/loading/bg_loading_page_lgobjet_01.jpg");
  }

  .loading-txt {
    position: absolute;
    z-index: 9999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    //background: red;
    bottom: 10rem;
  }

  .progress-bg {
    width: 200px;
    height: 2rem;
    background: #ccc;
  }
`;

export default LoaderView;
