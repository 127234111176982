import React, {useEffect} from 'react';
import Product_Aero360 from './Product_Aero360';
import Product_AeroTower from './Product_AeroTower';
import Product_AirConditioner from './Product_AirConditioner';
import Product_Art from './Product_Art';
import Product_Artcool from './Product_Artcool';
import Product_Atom4dPlus from './Product_Atom4dPlus';
import Product_AtomUSTS from './Product_AtomUSTS';
import Product_DishWasher from './Product_DishWasher';
import Product_Harvester from './Product_Harvester';
import Product_LnF from './Product_LnF';
import Product_MNext3 from './Product_MNext3';
import Product_Oven from './Product_Oven';
import Product_R9 from './Product_R9';
import Product_Styler from './Product_Styler';
import Product_WashTower from './Product_WashTower';
import {useLoader} from "@react-three/fiber";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {GLTFLoader} from "three-stdlib";


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');

const NaturalRoomProducts = () => {

    useEffect(() => {
        return () => {
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <>
            <Product_Harvester/>
            <Product_AeroTower/>
            <Product_Aero360/>
            <Product_R9/>
            <Product_DishWasher/>
            <Product_Styler/>
            <Product_WashTower/>
            <Product_AirConditioner/>
            <Product_Art/>
            <Product_MNext3/>
            <Product_Artcool/>
            <Product_Atom4dPlus/>
            <Product_Oven/>
            <Product_AtomUSTS/>
            <Product_LnF/>
        </>
    );
};

export default NaturalRoomProducts;
