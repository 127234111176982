/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        VC_04_Decal: THREE.Mesh
        Plane: THREE.Mesh
        Plane001: THREE.Mesh
        VC_04: THREE.Mesh
        VC_04_Glass: THREE.Mesh
        Logo: THREE.Mesh
        Plane002: THREE.Mesh
        Part1: THREE.Mesh
    }
    materials: {
        VC_04_Decal: THREE.MeshStandardMaterial
        R9_Part1_Gray: THREE.MeshStandardMaterial
        R9_Part1_Red: THREE.MeshStandardMaterial
        Glass: THREE.MeshStandardMaterial
        UI: THREE.MeshStandardMaterial
        R9_Part1_Silver: THREE.MeshStandardMaterial
        ['R9_Part1_Gray.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/m/r9_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/r9_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);

    const targetRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'r9'){
            if(selectedColorIndex === 0){
                targetRef.current.material = materials.R9_Part1_Gray;
            }
            else if(selectedColorIndex === 1){
                materials.R9_Part1_Red.metalness = 0.8;
                materials.R9_Part1_Red.needsUpdate = true;
                materials.R9_Part1_Red.color.setRGB(0.5, 0.5, 0.5);
                targetRef.current.material = materials.R9_Part1_Red;
            }
            else if(selectedColorIndex === 2){
                targetRef.current.material = materials.R9_Part1_Silver;
            }
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

  return (
    <group ref={group} {...props} dispose={null}>
        <mesh geometry={nodes.VC_04_Decal.geometry} material={materials.VC_04_Decal} />
        <mesh
            geometry={nodes.Plane.geometry}
            material={materials.R9_Part1_Gray}
            position={[0, 0, -0.5706]}
            visible={false}
        />
        <mesh
            geometry={nodes.Plane001.geometry}
            material={materials.R9_Part1_Red}
            position={[0, 0, -0.7162]}
            visible={false}
        />
        <mesh geometry={nodes.VC_04.geometry} material={nodes.VC_04.material}>
            <mesh
                geometry={nodes.VC_04_Glass.geometry}
                material={materials.Glass}
                position={[0.0008, 0.0516, -0.0437]}
                scale={[0.01, 0.01, 0.01]}>
                <mesh
                    geometry={nodes.Logo.geometry}
                    material={materials.UI}
                    position={[-0.023, -0.0518, -0.0199]}
                />
            </mesh>
        </mesh>
        <mesh
            geometry={nodes.Plane002.geometry}
            material={materials.R9_Part1_Silver}
            position={[0, 0, -0.8723]}
            visible={false}
        />
        <mesh geometry={nodes.Part1.geometry} material={materials['R9_Part1_Gray.001']} ref={targetRef} />
    </group>
  )
}
