/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Body_White: THREE.Mesh
        Top_White: THREE.Mesh
        Body_White2: THREE.Mesh
        Body_Silver: THREE.Mesh
        Top_Silver: THREE.Mesh
        Body_Silver2: THREE.Mesh
        Button: THREE.Mesh
        Door: THREE.Mesh
        Body: THREE.Mesh
        Glass: THREE.Mesh
        Metal: THREE.Mesh
        Bottom: THREE.Mesh
        Door_01: THREE.Mesh
        Top: THREE.Mesh
        Door_02: THREE.Mesh
        Decal_L2: THREE.Mesh
        Decal_L: THREE.Mesh
        Decal_R: THREE.Mesh
    }
    materials: {
        Body_White: THREE.MeshStandardMaterial
        Top_White: THREE.MeshStandardMaterial
        Body_White2: THREE.MeshStandardMaterial
        Body_Silver: THREE.MeshStandardMaterial
        Top_Silver: THREE.MeshStandardMaterial
        Body_Silver2: THREE.MeshStandardMaterial
        Button: THREE.MeshStandardMaterial
        Glass: THREE.MeshStandardMaterial
        Metal: THREE.MeshStandardMaterial
        Bottom: THREE.MeshStandardMaterial
        Object007_Decal: THREE.MeshStandardMaterial
        Decal_L: THREE.MeshStandardMaterial
        Decal_R: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/m/td_washer_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/td_washer_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/washing_machine/td_washer_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const bodyRef = useRef<THREE.Mesh>(null!);
    const topRef = useRef<THREE.Mesh>(null!);
    const doorRef = useRef<THREE.Mesh>(null!);
    const door_01Ref = useRef<THREE.Mesh>(null!);
    const door_02Ref = useRef<THREE.Mesh>(null!);

    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'td_washer') {
            if (selectedColorIndex === 0) {
                bodyRef.current.material = materials.Body_Silver;
                topRef.current.material = materials.Top_Silver;

                doorRef.current.material = materials.Top_Silver;
                door_01Ref.current.material = materials.Body_Silver;
                door_02Ref.current.material = materials.Body_Silver2;

            } else if (selectedColorIndex === 1) {
                bodyRef.current.material = materials.Body_White;
                topRef.current.material = materials.Top_White;

                doorRef.current.material = materials.Body_White;
                door_01Ref.current.material = materials.Body_Silver;
                door_02Ref.current.material = materials.Body_Silver;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        materials.Top_Silver.color.setHex(0x333333);
        materials.Body_Silver.envMapIntensity = 3;
        materials.Top_Silver.roughness = 0.3;
        materials.Body_Silver.roughness = 0.4;
        materials.Body_Silver2.envMapIntensity = 3;
        materials.Top_Silver.envMapIntensity = 3;

        materials.Body_White.roughness = 0.1;
        materials.Body_White.metalness = 0.2;
        materials.Body_White.envMapIntensity = 3;
        materials.Body_White2.envMapIntensity = 3;
        materials.Top_White.envMapIntensity = 3;
        materials.Top_White.roughness = 0.3;
        materials.Body_White.roughness = 0.4;

        materials.Glass.roughness = 0.1;
        materials.Glass.metalness = 0;
        materials.Glass.opacity = 0.6;

        materials.Button.color.setHex(0xeeeeee);
        materials.Button.metalness = 0.5;
        materials.Button.roughness = 0.1;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} dispose={null} position={[0, 0, 0.6]} scale={1.05}>
            <mesh
                geometry={nodes.Body_White.geometry}
                material={materials.Body_White}
                userData={{ name: 'Body_White' }}
            />
            <mesh
                geometry={nodes.Top_White.geometry}
                material={materials.Top_White}
                userData={{ name: 'Top_White' }}
            />
            <mesh
                geometry={nodes.Body_White2.geometry}
                material={materials.Body_White2}
                userData={{ name: 'Body_White2' }}
            />
            <mesh
                geometry={nodes.Body_Silver.geometry}
                material={nodes.Body_Silver.material}
                userData={{ name: 'Body_Silver' }}
            />
            <mesh
                geometry={nodes.Top_Silver.geometry}
                material={nodes.Top_Silver.material}
                userData={{ name: 'Top_Silver' }}
            />
            <mesh
                geometry={nodes.Body_Silver2.geometry}
                material={nodes.Body_Silver2.material}
                userData={{ name: 'Body_Silver2' }}
            />
            <mesh
                geometry={nodes.Button.geometry}
                material={materials.Button}
                userData={{ name: 'Button' }}
            />
            <mesh
                geometry={nodes.Door.geometry}
                material={nodes.Door.material}
                userData={{ name: 'Door' }}
                ref={doorRef}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={nodes.Body.material}
                userData={{ name: 'Body' }}
                ref={bodyRef}
            />
            <mesh
                geometry={nodes.Glass.geometry}
                material={materials.Glass}
                userData={{ name: 'Glass' }}
            />
            <mesh
                geometry={nodes.Metal.geometry}
                material={materials.Metal}
                userData={{ name: 'Metal' }}
            />
            <mesh
                geometry={nodes.Bottom.geometry}
                material={materials.Bottom}
                userData={{ name: 'Bottom' }}
            />
            <mesh
                geometry={nodes.Door_01.geometry}
                material={nodes.Door_01.material}
                userData={{ name: 'Door_01' }}
                ref={door_01Ref}
            />
            <mesh
                geometry={nodes.Top.geometry}
                material={nodes.Top.material}
                userData={{ name: 'Top' }}
                ref={topRef}
            />
            <mesh
                geometry={nodes.Door_02.geometry}
                material={nodes.Door_02.material}
                userData={{ name: 'Door_02' }}
                ref={door_02Ref}
            />
            <mesh
                geometry={nodes.Decal_L2.geometry}
                material={materials.Object007_Decal}
                userData={{ name: 'Decal_L2' }}
            />
            <mesh
                geometry={nodes.Decal_L.geometry}
                material={materials.Decal_L}
                userData={{ name: 'Decal_L' }}
            />
            <mesh
                geometry={nodes.Decal_R.geometry}
                material={materials.Decal_R}
                userData={{ name: 'Decal_R' }}
            />
        </group>
    )
}
