/**
 * 이노베이션 갤러리 USP 버블 메세지 데이터
 */

import { LangType } from "../../stores/langStore";

import uspData_Cooking from "./uspData_Cooking";
import uspData_Homebrew from "./uspData_HomeBrew";
import uspData_WashernDryer from "./uspData_WashernDryer";
import uspData_Styler from "./uspData_Styler";
import uspData_VacuumCleaner from "./uspData_VacuumCleaner";
import uspData_AirConditioner from "./uspData_AirConditioner";
import uspData_Dishwasher from "./uspData_Dishwasher";
import { ProductsCategoryType } from "../products/productsData";
import uspData_Refrigerator from "./uspData_Refrigerator";
import uspData_AirCare from "./uspData_AirCare";
import uspData_WearableAirPurifier from "./uspData_WearableAirPurifier";
import uspData_WaterPurifier from "./uspData_WaterPurifier";
import uspData_MicrowaveOven from "./uspData_MicrowaveOven";
import uspData_TiiunMini from "./uspData_TiiunMini";
import uspData_Tiiun from "./uspData_Tiiun";

// export type UspDataCategoryType = (
//     "puricare_360" |
//     "puricare_mini"
//     );

export type UspDataItemType = {
  label: string;
  isRight?: boolean;
  x?: number;
  y?: number;
  title: React.ReactNode[] | string[]; // [en,kr,es]
  subMsg: React.ReactNode[] | string[]; // [en,kr,es]
  subMsg2?: React.ReactNode | string;
  videoUrl?: string;
  ga_id?: string;
};

export type UspPageDataType = {
  category: ProductsCategoryType; //제품 카테고리
  modelNo: string; //제품 번호
  bgImgUrl?: string; //배경 이미지 경로
  productImgUrl?: string; //제품 이미지 경로
  uspList?: UspDataItemType[]; //USP아이템 리스트
};

const uspData: UspPageDataType[] = [
  ...uspData_Refrigerator,
  ...uspData_Cooking,
  ...uspData_Homebrew,
  ...uspData_WashernDryer,
  ...uspData_Styler,
  ...uspData_VacuumCleaner,
  ...uspData_AirConditioner,
  ...uspData_Dishwasher,
  ...uspData_AirCare,
  ...uspData_WearableAirPurifier,
  ...uspData_WaterPurifier,
  ...uspData_MicrowaveOven,
  ...uspData_TiiunMini, //영상 바로 재생
  ...uspData_Tiiun, //영상 바로 재생
];

export function getUspData(category: ProductsCategoryType, modelNo: string) {
  const result = uspData
    .filter((val) => val.category === category)
    .filter((val2) => val2.modelNo === modelNo);
  return result && result.length > 0 ? result[0] : null;
}

export default uspData;
