import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {useSpring, animated} from "@react-spring/web";
import {IS_MOBILE, Z_INDEX_TOP_UI} from "../const";
import BackButton from "./BackButton";
import MainButton from "./MainButton";
import useHistoryStore from "../../../stores/historyStore";
import useProductStore from "../../../stores/productStore";
import {Image} from "../comps_styled/Image.styled";
import {Container} from "../comps_styled/Container.styled";
import {
    useModalTutorial,
    useModalUSP,
    useObjetInterior,
} from "../../../stores/modalStore";
import {useGuideMenuHidden} from "../../../stores/hiddenGuideMenu";
import useRightPanelStore from "../../../stores/rightPanelStore";
import useObjetProductStore from "../../../stores/objetProductStore";
import ObjetInteriorButtons from "../ObjetInteriorButtons";
import {ROOT_PATH_PREFIX} from "../../common/const";
import {GA_Event, sendGaEvent} from "../../../api/ga";
import {easeCubicOut} from "d3-ease";
import AudioButton from "../AudioButton";
import useResizeStore from "../../../stores/resizeStore";
import HoverButton from "./HoverButton";

const Click = (props) => {
    const {sizeRatio, left, top} = props;
    const styles = useSpring({
        loop: {reverse: true},
        from: {opacity: 0},
        to: {opacity: 1},
    });
    return (
        <>
            <animated.div style={{...styles}}>
                <img
                    src={ROOT_PATH_PREFIX + "/images/main/Click_Arrow.png"}
                    alt="click arrow"
                    role="img"
                    style={{
                        position: "relative",
                        width: (IS_MOBILE ? 200 : 150) * sizeRatio,
                        height: "auto",
                        right: `${IS_MOBILE ? -1 : 14.5 * sizeRatio}em`,
                        top: `${(IS_MOBILE ? 15 : 14.7) * sizeRatio}em`,
                    }}
                />
            </animated.div>
            <animated.div style={{...styles}}>
                <img
                    src={ROOT_PATH_PREFIX + "/images/main/Click_Arrow.png"}
                    alt="click arrow"
                    role="img"
                    style={{
                        position: "relative",
                        width: (IS_MOBILE ? 200 : 150) * sizeRatio,
                        height: "auto",
                        right: `${(IS_MOBILE ? 45 : 8) * sizeRatio}em`,
                        top: `${(IS_MOBILE ? 15 : 14.7) * sizeRatio}em`,
                    }}
                />
            </animated.div>
        </>
    );
};

/**
 * 상단 네비게이션 UI
 * @constructor
 */

type TopNaviProps = {
    isShowSelectPanel?: boolean;
} & JSX.IntrinsicElements["div"];

const TopNavi = ({isShowSelectPanel, ...rest}: TopNaviProps) => {
    const [loaded, setLoaded] = useState(false);
    const ref = useRef<HTMLDivElement>(null!);
    const setModalTutorial = useModalTutorial((state) => state.setIsOpen);
    const category = useProductStore((state) => state.selectedCategory?.category);
    const selectedRoom = useProductStore((state) => state.selectedRoom);
    const guideCategory = useGuideMenuHidden((state) => state.category);
    const setGuideCategory = useGuideMenuHidden((state) => state.setCategory);
    const setHiddenRightPanel = useRightPanelStore((state) => state.setIsShow);
    const isObjetMode = useRightPanelStore((state) => state.isObjetMode);
    const setIsShow = useRightPanelStore((state) => state.setIsShow);
    const isShow = useRightPanelStore((state) => state.isShow);
    const pushHistory = useHistoryStore((state) => state.pushHistory);
    const pathname = useHistoryStore((state) => state.pathname);
    const objetData = useObjetProductStore((state) => state.selectedCategory);
    const setObjetHidden = useObjetInterior((state) => state.setIsOpen);
    const resize = useResizeStore((state) => state.resize);
    const isOpenUSP = useModalUSP((state) => state.isOpen);

    // for GAEvent
    const ga_isThinq = useMemo(() => pathname.includes("/thinq"), [pathname]);
    const ga_isHome = useMemo(() => pathname.includes("/home"), [pathname]);
    const ga_isObjet = useMemo(
        () => pathname.includes("/objetcollection"),
        [pathname]
    );

    // useEffect(() => {
    //   console.log(isOpenUSP);
    // }, [isOpenUSP]);

    const isMain = useMemo(() => {
        return (
            pathname === "" ||
            pathname === "/innovation_awards" ||
            pathname === "/zepeto"
        );
    }, [pathname]);

    const isHome = useMemo(() => {
        return pathname === "/home" || pathname.includes("/thinq");
    }, [pathname]);

    const isProduct = useMemo(() => {
        return !!category;
    }, [category]);

    useEffect(() => {
        setObjetHidden(!isShow);
    }, [isShow]);

    const [showClick, setShowClick] = useState(true);
    useEffect(() => {
        if (pathname === "/innovation_awards" || pathname === "/zepeto") {
            setShowClick(false);
        } else if (pathname === "") {
            setShowClick(true);
        }
    }, [pathname]);

    const [clicked, setClicked] = useState({
        hide: false,
        volume: false,
        guide: false,
    });

    const clickEffects = (selected: string, status: boolean = true) => {
        setClicked({
            hide: false,
            volume: false,
            guide: false,
            [selected]: status,
        });
    };

    const spring = useSpring({
        opacity: isShowSelectPanel
            ? 1
            : isMain
                ? 1
                : isHome
                    ? 1
                    : !category && !isObjetMode
                        ? 1
                        : 0,
        onStart: (result) => {
            ref.current.style.display = "block";
        },
        onRest: (result) => {
            ref.current.style.display = isShowSelectPanel
                ? "block"
                : isMain
                    ? "block"
                    : isHome
                        ? "block"
                        : !category && !isObjetMode
                            ? "block"
                            : "none";
        },
        config: {easing: easeCubicOut},
    });

    // 오브제 진입시 UI숨김 버튼 리셋
    useEffect(() => {
        if (isObjetMode) {
            clickEffects("hide", false);
            setObjetHidden(false);
        }
    }, [isObjetMode]);

    useEffect(() => {
        clickEffects("hide", !guideCategory);
    }, [guideCategory]);

    useEffect(() => {
        if (!objetData) {
            clickEffects("hide", false);
        } else {
            clickEffects("hide", !objetData?.category);
        }
    }, [objetData]);

    const menuSpring = useSpring({
        opacity: !!objetData ? 0 : 1,
        config: {duration: 450, easing: easeCubicOut},
    });

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 200);
    }, []);

    if (!loaded) return <></>;
    return (
        <>
            {isMain ? (
                <TopNaviStyle
                    style={{
                        zIndex: Z_INDEX_TOP_UI,
                    }}
                    ref={ref}
                    {...rest}
                >
                    <div
                        style={{
                            display: "inline-flex",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                flex: 0.36,
                                paddingTop: `${resize.ratio * (IS_MOBILE ? 6 : 5.5)}em`,
                                paddingLeft: `${resize.ratio * (IS_MOBILE ? 10 : 6)}em`,
                            }}
                        >
                            <Image
                                alt="lg logo"
                                role="img"
                                width={`${resize.ratio * (IS_MOBILE ? 102 : 65)}em`}
                                height="auto"
                                src={ROOT_PATH_PREFIX + "/images/main2/main_logo_lg.png"}
                            />
                        </div>
                        <div style={{flex: 0.52}}/>
                        <div
                            style={{
                                display: "inline-flex",
                                flex: 0.12,
                                justifyContent: "flex-end",
                                paddingTop: `${resize.ratio * (IS_MOBILE ? 6 : 5)}em`,
                                paddingRight: `${resize.ratio * (IS_MOBILE ? 16 : 6)}em`,
                            }}
                        >
                            <Image
                                alt="innovation award"
                                role="button"
                                width={`${resize.ratio * (IS_MOBILE ? 23 : 19)}em`}
                                height={`${resize.ratio * (IS_MOBILE ? 16 : 13)}em`}
                                style={{
                                    pointerEvents: "visible",
                                    cursor: "pointer",
                                }}
                                src={ROOT_PATH_PREFIX + "/images/main2/Awards.png"}
                                onClick={() => {
                                    pushHistory(`${pathname}/innovation_awards`);
                                    sendGaEvent("main_awards");
                                }}
                            />
                            <Image
                                alt="lg home in metaverse"
                                role="button"
                                width={`${resize.ratio * (IS_MOBILE ? 22.5 : 18.5)}em`}
                                height={`${resize.ratio * (IS_MOBILE ? 16 : 13)}em`}
                                style={{
                                    pointerEvents: "visible",
                                    cursor: "pointer",
                                    marginLeft: `${resize.ratio * (IS_MOBILE ? 5 : 3)}em`,
                                }}
                                src={ROOT_PATH_PREFIX + "/images/main2/Metavere_icon_0103.png"}
                                onClick={() => {
                                    pushHistory(`${pathname}/zepeto`);
                                    sendGaEvent("main_metaverse");
                                }}
                            />
                            {showClick && (
                                <div
                                    style={{display: "flex", position: "absolute", right: 0}}
                                >
                                    <Click sizeRatio={resize.ratio}/>
                                </div>
                            )}
                        </div>
                    </div>
                </TopNaviStyle>
            ) : (
                <TopNaviStyle
                    style={{
                        zIndex: Z_INDEX_TOP_UI,
                        opacity: spring.opacity.to((value) => value),
                    }}
                    ref={ref}
                    {...rest}
                >
                    <BackButton/>
                    <MainButton isShowSelectPanel={isShowSelectPanel}/>
                </TopNaviStyle>
            )}
            <BottomNaviStyle
                ratio={resize.ratio}
                disabled={ga_isObjet && !!objetData}
                style={{
                    opacity: ga_isObjet ? menuSpring.opacity.to((value) => value) : 1,
                    zIndex: Z_INDEX_TOP_UI,
                    display: isMain
                        ? "none"
                        : isProduct
                            ? "flex"
                            : isObjetMode
                                ? "flex"
                                : "none",
                }}
            >
                <Container
                    style={{
                        flex: 1,
                        paddingBottom: `${(IS_MOBILE ? 7 : 3) * resize.ratio}%`,
                        paddingLeft: `${(IS_MOBILE ? 17 : 3.5) * resize.ratio}%`,
                    }}
                >
                    <HoverButton
                        alt={!clicked.hide ? "show user interface" : "hide user interface"}
                        clicked={clicked.hide}
                        defaultSrc={
                            ROOT_PATH_PREFIX + "/images/icons/btn_hide-ui_default.png"
                        }
                        hoverSrc={ROOT_PATH_PREFIX + "/images/icons/btn_hide-ui_press.png"}
                        pressSrc={ROOT_PATH_PREFIX + "/images/icons/btn_view-ui_press.png"}
                        onClick={() => {
                            clickEffects("hide", !clicked.hide);
                            setHiddenRightPanel(clicked.hide);
                            setIsShow(clicked.hide, isObjetMode);

                            if (isProduct) {
                                setGuideCategory(clicked.hide ? (category as string) : null);
                            }

                            if (isObjetMode) {
                                // console.log("getObjetHidden", getObjetHidden);
                                setObjetHidden(isShow);
                            }
                            //
                            ga_isHome &&
                            GA_Event(
                                "lg_home",
                                "settinigs",
                                !clicked.hide ? "hide_ui_on" : "hide_ui_off"
                            );
                            ga_isObjet &&
                            GA_Event(
                                "lg_objet",
                                "settinigs",
                                !clicked.hide ? "hide_ui_on" : "hide_ui_off"
                            );
                        }}
                    />
                    <AudioButton/>
                    <HoverButton
                        alt="show guide"
                        defaultSrc={
                            ROOT_PATH_PREFIX + "/images/icons/btn_guide_default.png"
                        }
                        hoverSrc={ROOT_PATH_PREFIX + "/images/icons/btn_guide_press.png"}
                        onClick={() => {
                            setModalTutorial(true);
                            //
                            ga_isHome && GA_Event("lg_home", "settinigs", "guide_on");
                            ga_isObjet && GA_Event("lg_objet", "settinigs", "guide_on");
                        }}
                    />
                </Container>
                <Container
                    style={{
                        flex: "auto",
                        paddingBottom: `${(IS_MOBILE ? 6.5 : 3) * resize.ratio}%`,
                        paddingLeft: `${(IS_MOBILE ? 8 : 3.5) * resize.ratio}%`,
                        // border: "1px solid tomato",
                    }}
                >
                    {isObjetMode && <ObjetInteriorButtons/>}
                </Container>
            </BottomNaviStyle>
        </>
    );
};

const TopNaviStyle = styled(animated.div)<Record<string, any>>`
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
`;

const BottomNaviStyle = styled(animated.div)<{
    ratio: number;
    disabled: boolean;
}>`
  position: absolute;
  flex-direction: row;
  width: 100%;
  left: 0;
  bottom: 0;
  /* border: 1px solid tomato; */

  button + button {
    margin-top: ${({ratio}) => ratio * (IS_MOBILE ? 0.65 : 0.25)}em;
  }

  button {
    pointer-events: ${({disabled}) => (disabled ? "none" : "visible")};
  }
`;

export default TopNavi;
