/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {useGLTF, useHelper} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from '../../components/common/const'
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";
import {SpotLightHelper} from 'three'

type GLTFResult = GLTF & {
    nodes: {
        mirror: THREE.Mesh
        Body: THREE.Mesh
        beige: THREE.Mesh
        Body001: THREE.Mesh
        brown: THREE.Mesh
        green: THREE.Mesh
        UI: THREE.Mesh
    }
    materials: {
        mirror: THREE.MeshStandardMaterial
        beige: THREE.MeshStandardMaterial
        brown: THREE.MeshStandardMaterial
        green: THREE.MeshStandardMaterial
        ['mat_ui.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/m/artcool_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/artcool_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_conditioner/artcool_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const bodyRef = useRef<THREE.Mesh>(null!);
    const body2Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);
    const [mirrorMatCopy] = useState(() => materials.mirror.clone());

    useEffect(() => {
        if (selectedProduct?.modelNo === 'artcool') {
            if (selectedColorIndex === 1) {
                bodyRef.current.material = materials.beige;
                body2Ref.current.material = materials.beige;
            } else if (selectedColorIndex === 0) {
                materials.mirror.map = null;
                materials.mirror.color.setHex(0x333333);
                materials.mirror.metalness = 1;
                materials.mirror.roughness = 0.2;
                bodyRef.current.material = materials.mirror;
                body2Ref.current.material = mirrorMatCopy;
            } else if (selectedColorIndex === 2) {
                bodyRef.current.material = materials.brown;
                body2Ref.current.material = materials.brown;
            } else if (selectedColorIndex === 3) {
                bodyRef.current.material = materials.green;
                body2Ref.current.material = materials.green;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        materials.mirror.map = null;
        materials.mirror.color.setHex(0x333333);
        materials.mirror.metalness = 1;
        materials.mirror.roughness = 0.2;

        bodyRef.current.material = materials.mirror;

        mirrorMatCopy.map = null;
        mirrorMatCopy.roughness = 0.8;
        mirrorMatCopy.color.setRGB(0.05, 0.05, 0.05);
        body2Ref.current.material = mirrorMatCopy;

        lightRef.current.target = bodyRef.current;

        return () => {
            disposeScene(scene);
            mirrorMatCopy.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


    const lightRef = useRef<THREE.SpotLight>(null!);
    // useHelper(lightRef, SpotLightHelper);

    return (
        <group ref={group} {...props} dispose={null}>

            <spotLight intensity={1} angle={90 * THREE.MathUtils.DEG2RAD} penumbra={1} distance={3} ref={lightRef}
                       position={[-0.7, 0.5, 2]}/>

            <mesh
                geometry={nodes.mirror.geometry}
                material={materials.mirror}
                position={[-0.0265, 0.1492, -0.084]}
                rotation={[-Math.PI, 0, 0]}
                scale={[0.0108, 0.0108, 0.0108]}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={nodes.Body.material}
                scale={[0.01, 0.01, 0.01]}
                ref={bodyRef}
            />
            <mesh
                geometry={nodes.beige.geometry}
                material={nodes.beige.material}
                position={[-0.0533, 0.1492, -0.084]}
                rotation={[-Math.PI, 0, 0]}
                scale={[0.0108, 0.0108, 0.0108]}
            />
            <mesh
                geometry={nodes.Body001.geometry}
                material={nodes.Body001.material}
                scale={[0.01, 0.01, 0.01]}
                ref={body2Ref}
            />
            <mesh
                geometry={nodes.brown.geometry}
                material={materials.brown}
                position={[-0.001, 0.1492, -0.084]}
                rotation={[-Math.PI, 0, 0]}
                scale={[0.0108, 0.0108, 0.0108]}
            />
            <mesh
                geometry={nodes.green.geometry}
                material={materials.green}
                position={[0.0237, 0.1492, -0.084]}
                rotation={[-Math.PI, 0, 0]}
                scale={[0.0108, 0.0108, 0.0108]}
            />
            <mesh
                geometry={nodes.UI.geometry}
                material={materials['mat_ui.001']}
                scale={[0.01, 0.01, 0.01]}
            />

            {/*<mesh*/}
            {/*    geometry={nodes.green.geometry}*/}
            {/*    material={materials.green}*/}
            {/*    position={[0.4544, 1.3889, 0.116]}*/}
            {/*    rotation={[-Math.PI, 0, 0]}*/}
            {/*    scale={[0.0108, 0.0108, 0.0108]}*/}
            {/*    userData={{ name: 'green' }}*/}
            {/*    visible={false}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.brown.geometry}*/}
            {/*    material={materials.brown}*/}
            {/*    position={[0.4297, 1.3889, 0.116]}*/}
            {/*    rotation={[-Math.PI, 0, 0]}*/}
            {/*    scale={[0.0108, 0.0108, 0.0108]}*/}
            {/*    userData={{ name: 'brown' }}*/}
            {/*    visible={false}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.Body.geometry}*/}
            {/*    material={nodes.Body.material}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'Body' }}*/}
            {/*    ref={bodyRef}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.beige.geometry}*/}
            {/*    material={nodes.beige.material}*/}
            {/*    position={[0.3773, 1.3889, 0.116]}*/}
            {/*    rotation={[-Math.PI, 0, 0]}*/}
            {/*    scale={[0.0108, 0.0108, 0.0108]}*/}
            {/*    userData={{ name: 'beige' }}*/}
            {/*    visible={false}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.mirror.geometry}*/}
            {/*    material={materials.mirror}*/}
            {/*    position={[0.4042, 1.3889, 0.116]}*/}
            {/*    rotation={[-Math.PI, 0, 0]}*/}
            {/*    scale={[0.0108, 0.0108, 0.0108]}*/}
            {/*    userData={{ name: 'mirror' }}*/}
            {/*    visible={false}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.UI.geometry}*/}
            {/*    material={materials['mat_ui.001']}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'UI' }}*/}
            {/*/>*/}
        </group>
    )
}
