/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {TextureLoader} from "three";
import {useEquirectEXR} from "../../../../utils/useEquirect";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        b_props_5004: THREE.Mesh
        d_ceiling_light004: THREE.Mesh
        c_chair004: THREE.Mesh
        a_glass004: THREE.Mesh
        b_props_2004: THREE.Mesh
        c_props_merged004: THREE.Mesh
        d_ceiling004: THREE.Mesh
        c_wall004: THREE.Mesh
        a_props_pot3004: THREE.Mesh
        cabinet_door_02003: THREE.Mesh
        d_floor004: THREE.Mesh
        c_cabinet_body004: THREE.Mesh
        c_props_4004: THREE.Mesh
        c_props_3004: THREE.Mesh
        a_glass009: THREE.Mesh
        a_props004: THREE.Mesh
    }
    materials: {
        b_mat_metal: THREE.MeshStandardMaterial
        mat_ceiling_light: THREE.MeshStandardMaterial
        c_props_chair: THREE.MeshStandardMaterial
        glass2: THREE.MeshStandardMaterial
        b_mat_ceramic: THREE.MeshStandardMaterial
        ['c_mat_props_merged.001']: THREE.MeshStandardMaterial
        mat_wall_white: THREE.MeshStandardMaterial
        ['plaster_ultra_fine_spray.001']: THREE.MeshStandardMaterial
        a_lambert2SG: THREE.MeshStandardMaterial
        ['b_mat_cabinet_door_3.001']: THREE.MeshStandardMaterial
        ['macael_marble_grid_tiles.001']: THREE.MeshStandardMaterial
        ['wood_ipe_2.001']: THREE.MeshStandardMaterial
        mat_white_marble: THREE.MeshStandardMaterial
        ['c_wood_ipe_2.002']: THREE.MeshStandardMaterial
        glass: THREE.MeshStandardMaterial
        a_mat_props: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/kitchen_vivid_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/kitchen_vivid_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/map/kitchen_cyclesbake_DIFFUSE.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_DIFFUSE.exr');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/map/kitchen_cyclesbake_AO.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_Environment_01.exr';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    // @ts-ignore
    const lightmapTex = useLoader(EXRLoader, LIGHT_MAP_URL,(loader:EXRLoader) => {
        if(IS_MOBILE){
            loader.setDataType(THREE.HalfFloatType);
        }else{
            loader.setDataType(THREE.FloatType);
        }
    });
    const aoTex = useLoader(TextureLoader, AO_URL);
    const envTex = useEquirectEXR(ENV_URL);
    const gl = useThree(state => state.gl);
    const scene2 = useThree(state => state.scene);

    useLayoutEffect(()=>{
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.needsUpdate = true;

        scene2.environment = envTex;
        // scene.background = envTex;
        gl.toneMappingExposure = 1.2;

        setupScene(scene, lightmapTex, aoTex);

        materials['b_mat_cabinet_door_3.001'].lightMapIntensity = 4;
        materials['wood_ipe_2.001'].lightMapIntensity = 4;
        materials['c_wood_ipe_2.002'].lightMapIntensity = 2;
        materials['plaster_ultra_fine_spray.001'].lightMapIntensity = 3;
        materials['macael_marble_grid_tiles.001'].aoMap = aoTex;
        materials['macael_marble_grid_tiles.001'].aoMapIntensity = 0.5;
        materials.mat_wall_white.aoMap = aoTex;
        materials.mat_wall_white.aoMapIntensity = 0.3;
    }, [lightmapTex, aoTex, envTex]);


    useEffect(() => {
        scene2.environment = envTex;

        return () => {
            disposeScene(scene);

            lightmapTex.dispose();
            aoTex.dispose();
            envTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            // //@ts-ignore
            // useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            // useLoader.clear(TextureLoader, AO_URL)
            // //@ts-ignore
            // useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh geometry={nodes.b_props_5004.geometry} material={materials.b_mat_metal} />
            <mesh geometry={nodes.d_ceiling_light004.geometry} material={materials.mat_ceiling_light} />
            <mesh geometry={nodes.c_chair004.geometry} material={materials.c_props_chair} />
            <mesh geometry={nodes.a_glass004.geometry} material={materials.glass2} />
            <mesh geometry={nodes.b_props_2004.geometry} material={materials.b_mat_ceramic} />
            <mesh
                geometry={nodes.c_props_merged004.geometry}
                material={materials['c_mat_props_merged.001']}

            />
            <mesh geometry={nodes.d_ceiling004.geometry} material={materials.mat_wall_white}  />
            <mesh
                geometry={nodes.c_wall004.geometry}
                material={materials['plaster_ultra_fine_spray.001']}
            />
            <mesh geometry={nodes.a_props_pot3004.geometry} material={materials.a_lambert2SG} />
            <mesh
                geometry={nodes.cabinet_door_02003.geometry}
                material={materials['b_mat_cabinet_door_3.001']}
            />
            <mesh
                geometry={nodes.d_floor004.geometry}
                material={materials['macael_marble_grid_tiles.001']}
            />
            <mesh geometry={nodes.c_cabinet_body004.geometry} material={materials['wood_ipe_2.001']} />
            <mesh geometry={nodes.c_props_4004.geometry} material={materials.mat_white_marble} />
            <mesh geometry={nodes.c_props_3004.geometry} material={materials['c_wood_ipe_2.002']} />
            <mesh geometry={nodes.a_glass009.geometry} material={materials.glass} />
            <mesh geometry={nodes.a_props004.geometry} material={materials.a_mat_props} />
        </group>
    )
}
