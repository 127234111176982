import {SpecboardsDataItemType} from "./specboardData";
import {Sup} from "../../components/common/comps_styled/Text.styled";
import React from "react";


/**
 * 전자레인지 스펙보드 데이터
 */
const specboardsData_MicrowaveOven: SpecboardsDataItemType = {
  category: "microwave_oven",
  specboardList: [
    {
      name: "1.7 Convection OTR (1.7 cu.ft)",
      modelNo: "convection",
      specboard_img_url: "/images/products/microwave_oven/convection/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Scallet2 Best (2.1 cu.ft)",
      modelNo: "scallet2_best",
      specboard_img_url: "/images/products/microwave_oven/scallet2_best/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Scallet2 Better (2.1 cu.ft)",
      modelNo: "scallet2_better",
      specboard_img_url: "/images/products/microwave_oven/scallet2_better/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Scallet2 Good (2.0 cu.ft)",
      modelNo: "scallet2_good",
      specboard_img_url: "/images/products/microwave_oven/scallet2_good/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Utopia3 (1.8 cu.ft)",
      modelNo: "utopia",
      specboard_img_url: "/images/products/microwave_oven/utopia/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
  ]
};

export default specboardsData_MicrowaveOven;
