import { ProductsDataItemType } from "./productsData";
import Convection from "../../products/microwave_oven/Convection";
import Scallet2_best from "../../products/microwave_oven/Scallet2_best";
import Scallet2_better from "../../products/microwave_oven/Scallet2_better";
import Utopia from "../../products/microwave_oven/Utopia";
import Scallet2_good from "../../products/microwave_oven/Scallet2_good";


/**
 * 전자레인지 데이터
 */
const productsData_MicrowaveOven: ProductsDataItemType = {
  category: "microwave_oven",
  ga_id: "cooking_mwo",
  label: "category_microwave_oven",
  category_img_url: "/images/products/microwave_oven/category_img.png",
  category_thumb_url: "/images/products/microwave_oven/category_thumb.png",
  category_glow_url: "/images/products/microwave_oven/category_glow.png",
  roomType: "kitchen",
  usp_transition: "/movies/usp/transition/main_ST_000_00000_1.webm",
  productList: [
    {
      name: "Scallet2 Best (2.1 cu.ft)",
      modelNo: "scallet2_best",
      ga_id: "scallet2_best",
      isCES: true,
      thumb_url:
        "/images/products/microwave_oven/scallet2_best/product_thumb.png",
      openExperience: false,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color:
            "/images/products/microwave_oven/scallet2_best/color_blackstain_anti.png",
          ga_id: "black_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color:
            "/images/products/microwave_oven/scallet2_best/color_stainsteel_anti.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Scallet2_best />,
    },
    {
      name: "1.7 Convection OTR (1.7 cu.ft)",
      modelNo: "convection",
      ga_id: "1.7_convection",
      isCES: true,
      thumb_url: "/images/products/microwave_oven/convection/product_thumb.png",
      openExperience: false,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color:
            "/images/products/microwave_oven/convection/color_blackstain_anti.png",
          ga_id: "black_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color:
            "/images/products/microwave_oven/convection/color_stainsteel_anti.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Convection />,
    },

    {
      name: "Scallet2 Better (2.1 cu.ft)",
      modelNo: "scallet2_better",
      ga_id: "scallet2_better",
      isCES: true,
      thumb_url:
        "/images/products/microwave_oven/scallet2_better/product_thumb.png",
      openExperience: false,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color:
            "/images/products/microwave_oven/scallet2_better/color_blackstain_anti.png",
          ga_id: "black_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color:
            "/images/products/microwave_oven/scallet2_better/color_stainsteel_anti.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Scallet2_better />,
    },
    {
      name: "Scallet2 Good (2.0 cu.ft)",
      modelNo: "scallet2_good",
      ga_id: "scallet2_good",
      isCES: true,
      thumb_url:
        "/images/products/microwave_oven/scallet2_good/product_thumb.png",
      openExperience: false,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color:
            "/images/products/microwave_oven/scallet2_good/color_blackstain_anti.png",
        },
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color:
            "/images/products/microwave_oven/scallet2_good/color_stainsteel_anti.png",
        },
      ],
      model: <Scallet2_good />,
    },
    {
      name: "Utopia3 (1.8 cu.ft)",
      modelNo: "utopia",
      ga_id: "utopia3",
      isCES: true,
      thumb_url: "/images/products/microwave_oven/utopia/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/microwave_oven/utopia/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color:
            "/images/products/microwave_oven/utopia/color_blackstain_anti.png",
          ga_id: "black_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color:
            "/images/products/microwave_oven/utopia/color_stainsteel_anti.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Utopia />,
    },
  ],
};

export default productsData_MicrowaveOven;
