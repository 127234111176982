/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
  nodes: {
    Ui_display: THREE.Mesh
    Door: THREE.Mesh
    Case001: THREE.Mesh
  }
  materials: {
    UI: THREE.MeshStandardMaterial
    Door: THREE.MeshStandardMaterial
    ['Case.001']: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/styler/m/baron_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/styler/baron_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult

    useEffect(() => {
        materials.Door.roughness = 0.1;
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


  return (
    <group ref={group} {...props} scale={0.94} position={[0, 0, 0.2]} dispose={null}>
      <mesh
        geometry={nodes.Ui_display.geometry}
        material={materials.UI}
        position={[0.3066, 1.6011, -0.0]}
        scale={[0.001, 0.001, 0.001]}
        userData={{ name: 'Ui_display' }}
      />
      <mesh
        geometry={nodes.Door.geometry}
        material={materials.Door}
        position={[0, 0.0175, -0.0635]}
        scale={[0.001, 0.001, 0.001]}
        userData={{ name: 'Door' }}
      />
      <mesh
        geometry={nodes.Case001.geometry}
        material={materials['Case.001']}
        position={[0, 0.0175, -0.0635]}
        scale={[0.001, 0.001, 0.001]}
        userData={{ name: 'Case.001' }}
      />
    </group>
  )
}

