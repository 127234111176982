import React, { useState } from "react";
import styled from "styled-components";
import useHistoryStore, { HistoryState } from "../../../stores/historyStore";
import { ProductsDataItemType } from "../../../api/products/productsData";
import useProductStore, { ProductState } from "../../../stores/productStore";
import useRightPanelStore, {
  RightPanelState,
} from "../../../stores/rightPanelStore";
import { animated, useSpring } from "@react-spring/web";
import { ROOT_PATH_PREFIX } from "../../common/const";
import { GA_Event } from "../../../api/ga";
import { useModalVimeo } from "../../../stores/modalStore";
import { getVimeoLink } from "../../../api/products/productVimeoDataForMobile";
import { useSelectMobileQuality } from "../../../stores/selectRouteForMobileStore";

type ProductButtonProps = {
  data: ProductsDataItemType;
} & JSX.IntrinsicElements["button"];

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const pushHistorySelector = (state: HistoryState) => state.pushHistory;

const Wrapper = styled(animated.button)<
  { drop?: number } & Record<string, any>
>`
  appearance: none;
  outline: none;
  border: none;
  pointer-events: visible;
  cursor: pointer;
  width: 100%;
  height: 100%;
  //background: red;
  background: rgba(255, 255, 255, 0);
  position: relative;
  padding: 0;
  margin: 0;

  img {
    height: 100%;
    filter: ${(props) => props.dropshadow};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const WrapperDiv = styled(animated.div)<
  { drop?: number } & Record<string, any>
>`
  appearance: none;
  outline: none;
  border: none;
  pointer-events: visible;
  cursor: pointer;
  width: 100%;
  height: 100%;
  // background: red;
  background: rgba(255, 255, 255, 0);
  position: relative;
  padding: 0;
  margin: 0;

  img {
    height: 100%;
    filter: ${(props) => props.dropshadow};
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const ProductButton = ({ data, ...rest }: ProductButtonProps) => {
  const [isHover, setIsHover] = useState(false);
  const delay = useState(Math.floor(Math.random() * 3000));
  const pushHistory = useHistoryStore(pushHistorySelector);

  const modalVimeo = useModalVimeo((state) => state.isOpen);
  const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
  const setVimeoId = useModalVimeo((state) => state.setId);
  const setVimeoGaId = useModalVimeo((state) => state.setGaId);
  const setVimeo = (id: string) => {
    setVimeoId(id);
    setModalVimeo(true);
  };

  const isNormalQuailty = useSelectMobileQuality(
    (state) => state.isNormalQuality
  );

  const opacity = useSpring({
    // opacity: isHover ? 1 : 0,
    // loop: { reverse: true },
    from: { opacity: 0 },
    to: [
      { opacity: 1 },
      { opacity: 0 },
      { opacity: 1 },
      { opacity: 0 },
      // { opacity: 1 },
    ],
    config: { duration: 1000 },
    delay: delay[0],
  });

  const hoverGlow = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 500 },
  });

  const onHover = () => {
    setIsHover(true);
  };

  return (
    <Wrapper
      {...rest}
      onClick={() => {
        GA_Event("lg_home", "product_image_select", data.ga_id ?? "", {
          dimension5: data.ga_id,
        });

        if (isNormalQuailty) {
          setVimeoGaId("");
          setVimeo(getVimeoLink(data.category));
          return;
        }

        pushHistory(`/home/${data.roomType}/${data.category}`);
      }}
    >
      <animated.div style={opacity}>
        <img
          alt={data.category.replaceAll("_", " ")}
          role="img"
          src={ROOT_PATH_PREFIX + data.category_glow_url}
        />
      </animated.div>

      <HoverGlow data={data} />
    </Wrapper>
  );
};

const HoverGlow = (props) => {
  const [{ opacity }, set] = useSpring(() => ({ opacity: 0 }));
  return (
    <animated.img
      alt={props.data.category.replaceAll("_", " ")}
      role="img"
      src={ROOT_PATH_PREFIX + props.data.category_glow_url}
      style={{ opacity: opacity.to((v) => `${v}`) }}
      onMouseOver={() => set({ opacity: 1 })}
      onMouseOut={() => set({ opacity: 0 })}
    />
  );
};

export default ProductButton;
