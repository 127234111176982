/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Glass: THREE.Mesh
        Dryer_Filter: THREE.Mesh
        Body_Bottom: THREE.Mesh
        Body_Bottom_Black: THREE.Mesh
        Body_Bottom_White: THREE.Mesh
        Decal_R: THREE.Mesh
        Decal_Bottom_R: THREE.Mesh
        Decal_Bottom_L: THREE.Mesh
        Decal_L: THREE.Mesh
        Inside_L: THREE.Mesh
        Inside_R: THREE.Mesh
        Body_Top_White: THREE.Mesh
        Body_Top: THREE.Mesh
        Body_Top_Black: THREE.Mesh
    }
    materials: {
        Glass: THREE.MeshStandardMaterial
        ['07 - Default.001']: THREE.MeshStandardMaterial
        Body_Bottom_Black: THREE.MeshStandardMaterial
        Body_Bottom_White: THREE.MeshStandardMaterial
        Decal_R: THREE.MeshStandardMaterial
        Decal_Bottom_R: THREE.MeshStandardMaterial
        Decal_Bottom_L: THREE.MeshStandardMaterial
        Decal_L: THREE.MeshStandardMaterial
        Inside_L: THREE.MeshStandardMaterial
        Inside_R: THREE.MeshStandardMaterial
        Body_Top_White: THREE.MeshStandardMaterial
        Body_Top_Black: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/m/victor2r27_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/victor2r27_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/washing_machine/victor2r27_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const part1Ref = useRef<THREE.Mesh>(null!);
    const part2Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'victor2r') {
            if (selectedColorIndex === 0) {
                materials.Body_Top_Black.roughness = 0.5;
                materials.Body_Bottom_Black.roughness = 0.5;
                materials.Body_Top_Black.envMapIntensity = 7;
                materials.Body_Bottom_Black.envMapIntensity = 7;

                part1Ref.current.material = materials.Body_Top_Black;
                part2Ref.current.material = materials.Body_Bottom_Black;
            } else if (selectedColorIndex === 1) {
                materials.Body_Top_White.envMapIntensity = 5;
                materials.Body_Bottom_White.envMapIntensity = 5;

                part1Ref.current.material = materials.Body_Top_White;
                part2Ref.current.material = materials.Body_Bottom_White;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        materials.Glass.opacity = 0.4;
        materials.Glass.roughness = 0.2;
        materials.Glass.color.setHex(0x111111);

        materials.Body_Top_Black.roughness = 0.5;
        materials.Body_Bottom_Black.roughness = 0.5;
        materials.Body_Top_Black.envMapIntensity = 7;
        materials.Body_Bottom_Black.envMapIntensity = 7;

        part1Ref.current.material = materials.Body_Top_Black;
        part2Ref.current.material = materials.Body_Bottom_Black;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} dispose={null} position={[0, 0.01, 0.55]} scale={1.015} >
            <mesh
                geometry={nodes.Glass.geometry}
                material={materials.Glass}
                position={[0.3372, 0.3301, -0.4702]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Glass' }}
            />
            <mesh
                geometry={nodes.Dryer_Filter.geometry}
                material={materials['07 - Default.001']}
                position={[0.3366, 0.3028, -0.088]}
                scale={[0.001, 0.001, 0.001]}
                userData={{ name: 'Dryer_Filter' }}
            />
            <mesh
                geometry={nodes.Body_Bottom.geometry}
                material={nodes.Body_Bottom.material}
                position={[-0.3619, 0, -0.3457]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Body_Bottom' }}
                ref={part2Ref}
            />
            <mesh
                geometry={nodes.Body_Bottom_Black.geometry}
                material={nodes.Body_Bottom_Black.material}
                position={[0.3019, -0.0321, -0.3385]}
                userData={{ name: 'Body_Bottom_Black' }}
                visible={false}
            />
            <mesh
                geometry={nodes.Body_Bottom_White.geometry}
                material={materials.Body_Bottom_White}
                position={[0.3019, -0.0321, -0.5398]}
                userData={{ name: 'Body_Bottom_White' }}
                visible={false}
            />
            <mesh
                geometry={nodes.Decal_R.geometry}
                material={materials.Decal_R}
                position={[0.3372, 0.3301, -0.4688]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Decal_R' }}
            />
            <mesh
                geometry={nodes.Decal_Bottom_R.geometry}
                material={materials.Decal_Bottom_R}
                position={[0.3352, 0, -0.35]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Decal_Bottom_R' }}
            />
            <mesh
                geometry={nodes.Decal_Bottom_L.geometry}
                material={materials.Decal_Bottom_L}
                position={[-0.3346, 0, -0.3457]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Decal_Bottom_L' }}
            />
            <mesh
                geometry={nodes.Decal_L.geometry}
                material={materials.Decal_L}
                position={[-0.3346, 0.3307, -0.4673]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Decal_L' }}
            />
            <mesh
                geometry={nodes.Inside_L.geometry}
                material={materials.Inside_L}
                position={[-0.3346, 0.3307, -0.4679]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Inside_L' }}
            />
            <mesh
                geometry={nodes.Inside_R.geometry}
                material={materials.Inside_R}
                position={[0.3366, 0.3301, -0.4628]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Inside_R' }}
            />
            <mesh
                geometry={nodes.Body_Top_White.geometry}
                material={materials.Body_Top_White}
                position={[0.004, -0.0321, -0.5061]}
                userData={{ name: 'Body_Top_White' }}
                visible={false}
            />
            <mesh
                geometry={nodes.Body_Top.geometry}
                material={nodes.Body_Top.material}
                position={[0.3372, 0.3301, -0.4702]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Body_Top' }}
                ref={part1Ref}
            />
            <mesh
                geometry={nodes.Body_Top_Black.geometry}
                material={nodes.Body_Top_Black.material}
                position={[0.0077, -0.0321, -0.3385]}
                userData={{ name: 'Body_Top_Black' }}
                visible={false}
            />
        </group>
    )
}
