import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useLangStore, { LangType } from "../../stores/langStore";
import useHistoryStore from "../../stores/historyStore";
import { Image } from "./comps_styled/Image.styled";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "./const";
import { sendGaEvent } from "../../api/ga";
import AudioButton from "./AudioButton";
import { useAudioStore } from "../../stores/AudioStore";
import useResizeStore from "../../stores/resizeStore";
import HoverButton from "./navigation/HoverButton";
import { useModalSurveyMain } from "../../stores/modalStore";
import HoverAudioButton from "./navigation/HoverAudioButton";

/**
 * 언어 설정 버튼 UI
 */

const LangButtons = () => {
  const resize = useResizeStore((state) => state.resize);
  const lang = useLangStore((state) => state.lang);
  const setLang = useLangStore((state) => state.setLang);
  const pathname = useHistoryStore((state) => state.pathname);
  const setSurveyOpen = useModalSurveyMain((state) => state.setIsOpen);
  const isSurveyCompleted = useModalSurveyMain((state) => state.isCompleted);
  const isMain = useMemo(
    () =>
      pathname === "" ||
      pathname === "/innovation_awards" ||
      pathname === "/zepeto",
    [pathname]
  );
  const [tabIdx, setTabIdx] = useState(0);
  const audio = useAudioStore((state) => state);

  function changeLanguage(langType: LangType) {
    setLang(langType);
  }

  useEffect(() => {
    if (pathname === "/innovation_awards" || pathname === "/zepeto") {
      setTabIdx(-1);
    } else {
      setTabIdx(0);
    }
  }, [pathname]);

  const [clicked, setClicked] = useState({
    en: false,
    es: false,
    kr: false,
  });

  const clickEffects = (selected: string, status: boolean = true) => {
    setClicked({
      en: false,
      es: false,
      kr: false,
      [selected]: status,
    });
  };

  useEffect(() => {
    clickEffects(lang, true);
  }, [lang]);

  return (
    <LangButtonsStyle
      ratio={resize.ratio}
      style={{
        display: isMain ? "flex" : "none",
      }}
    >
      <div>
        {/*<HoverButton*/}
        {/*  alt="select english"*/}
        {/*  clicked={clicked.en}*/}
        {/*  defaultSrc={ROOT_PATH_PREFIX + "/images/icons/btn_language_eng.png"}*/}
        {/*  hoverSrc={*/}
        {/*    ROOT_PATH_PREFIX + "/images/icons/btn_language_eng_press.png"*/}
        {/*  }*/}
        {/*  onClick={() => {*/}
        {/*    clickEffects("en", true);*/}
        {/*    changeLanguage("en");*/}
        {/*    sendGaEvent("main_language_en");*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<HoverButton*/}
        {/*  alt="select spanish"*/}
        {/*  clicked={clicked.es}*/}
        {/*  defaultSrc={ROOT_PATH_PREFIX + "/images/icons/btn_language_spn.png"}*/}
        {/*  hoverSrc={*/}
        {/*    ROOT_PATH_PREFIX + "/images/icons/btn_language_spn_press.png"*/}
        {/*  }*/}
        {/*  onClick={() => {*/}
        {/*    clickEffects("es", true);*/}
        {/*    changeLanguage("es");*/}
        {/*    sendGaEvent("main_language_es");*/}
        {/*  }}*/}
        {/*/>*/}
        {/*<HoverButton*/}
        {/*  alt="select korean"*/}
        {/*  clicked={clicked.kr}*/}
        {/*  defaultSrc={ROOT_PATH_PREFIX + "/images/icons/btn_language_kor.png"}*/}
        {/*  hoverSrc={*/}
        {/*    ROOT_PATH_PREFIX + "/images/icons/btn_language_kor_press.png"*/}
        {/*  }*/}
        {/*  onClick={() => {*/}
        {/*    clickEffects("kr", true);*/}
        {/*    changeLanguage("kr");*/}
        {/*    sendGaEvent("main_language_kr");*/}
        {/*  }}*/}
        {/*/>*/}
      </div>
      <div>
        <HoverAudioButton
          clicked={!audio.isPlaying}
          defaultSrc={
            ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_off_default.png"
          }
          hoverSrc={
            ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_off_hover.png"
          }
          pressSrc={
            ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_off_press.png"
          }
          clickDefaultSrc={
            ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_on_default.png"
          }
          clickHoverSrc={
            ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_on_hover.png"
          }
          clickPressSrc={
            ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_on_press.png"
          }
          onClick={() => {
            audio.isPlaying ? audio.pause() : audio.play();
            sendGaEvent(!audio.isPlaying ? "sound_on" : "sound_off");
          }}
        />

        {!isSurveyCompleted && (
          <HoverButton
            alt="visit other exhibitions"
            defaultSrc={ROOT_PATH_PREFIX + "/images/icons/btn_exit.png"}
            hoverSrc={ROOT_PATH_PREFIX + "/images/icons/btn_exit_press.png"}
            onClick={() => {
              setSurveyOpen(true);
              sendGaEvent("main_exit");
            }}
          />
        )}
      </div>
    </LangButtonsStyle>
  );
};

const LangButtonsStyle = styled.div<{ ratio: number }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding: 0 ${({ ratio }) => ratio * (IS_MOBILE ? 13 : 6)}em;
  bottom: ${({ ratio }) => ratio * (IS_MOBILE ? 5 : 3)}em;
  width: 100%;

  > div:first-child {
    display: inline-flex;
  }

  > div:last-child {
    display: inline-flex;
  }

  div > button + button {
    margin-left: ${({ ratio }) => ratio * (IS_MOBILE ? 3 : 1.9)}em;
  }
`;

export default LangButtons;
