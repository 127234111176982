import create from "zustand";
import { PANEL_WIDTH } from "../components/lghome/select_panel/SelectPanel";

/**
 * 오른쪽 패널 상태관리
 */
export type RightPanelState = {
  isShow: boolean;
  isOpen: boolean;
  isObjetMode: boolean;
  panelWidth: number;
  setPanelWidth: (val: number, isObjetMode?: boolean) => void;
  setIsShow: (val: boolean, isObjetMode?: boolean) => void;
  setIsOpen: (val: boolean, isObjetMode?: boolean) => void;
};

const useRightPanelStore = create<RightPanelState>((set) => ({
  isOpen: false,
  isShow: false,
  isObjetMode: false,
  panelWidth: PANEL_WIDTH,
  setPanelWidth: (val, isObjetMode = false) =>
    set({ panelWidth: val, isObjetMode: isObjetMode }),
  setIsShow: (val, isObjetMode = false) =>
    set({ isShow: val, isObjetMode: isObjetMode }),
  setIsOpen: (val, isObjetMode = false) =>
    set({ isOpen: val, isObjetMode: isObjetMode }),
}));

export default useRightPanelStore;
