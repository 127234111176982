import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {animated} from "@react-spring/web";
import {Image} from "../../../common/comps_styled/Image.styled";
import theme from "../../../../styles/theme.styled";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {CloseBtnStyle} from "./SpecBoard_M";


type CloseBtnProps = {} & JSX.IntrinsicElements["button"];
const CloseBtn = ({...rest}: CloseBtnProps) => {
    return (
        <CloseBtnStyle id="closeBtn" title="Close Specboard" {...rest}>
            <div className="icon">
                <div className="line line1"/>
                <div className="line line2"/>
            </div>
        </CloseBtnStyle>
    );
};

type SpecBoardProps = {
    specImgUrl: string;
    specImgAlt?: string;
    closeHandler: () => void;
} & JSX.IntrinsicElements["div"];

// Temp
const SpecBoard = ({specImgUrl, specImgAlt = '', closeHandler, ...rest}: SpecBoardProps) => {

    useEffect(() => {
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Wrapper {...rest}>
                <CloseBtn
                    style={{ top: "0.1em", right: "0.1em" }}
                    onClick={(e) => {
                        e.stopPropagation();
                        closeHandler();
                    }}
                />
                <Image
                    src={ROOT_PATH_PREFIX + specImgUrl}
                    alt={specImgAlt}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

const Wrapper = styled(animated.div)<Record<string, any>>`
  width: ${IS_MOBILE ? 22 : 45}em;
  height: auto;
  //background: #1d1d1d;
  position: absolute;
  right: ${IS_MOBILE ? 6.5 : 13}em;
`;


export default SpecBoard;
