import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_Tiiun: UspPageDataType[] = [
  {
    category: "tiiun",
    modelNo: "tiiun",
    bgImgUrl: "/images/usp/tiiun/tiiun_mini.jpg",
    uspList: [
      {
        label: "tiiun_usp_0",
        title: [
          <>
            Experience indoor gardening
            <br />
            and decorate your house.
          </>,
        ],
        subMsg: [<>Simple All-in-one</>],
        videoUrl: "https://vimeo.com/660939583/d464ae4326",
        x: 60,
        y: 406,
        ga_id: "simple_all-in-one",
      },
    ],
  },
];

export default uspData_Tiiun;
