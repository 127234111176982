import React, { useState } from "react";
import styled from "styled-components";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../const";
import CloseButton from "../navigation/CloseButton";
import useResizeStore from "../../../stores/resizeStore";
import { Text } from "../comps_styled/Text.styled";
import theme from "../../../styles/theme.styled";
import { Divider } from "../comps_styled/Divider.styled";
import { useModalSurveyStyler } from "../../../stores/modalStore";
import { Image } from "../comps_styled/Image.styled";
import { GA_Event } from "../../../api/ga";

const SurveyStyler = (props: JSX.IntrinsicElements["div"]) => {
  //
  const setIsOpen = useModalSurveyStyler((state) => state.setIsOpen);
  const setisCompleted = useModalSurveyStyler((state) => state.setIsCompleted);
  const resize = useResizeStore((state) => state.resize);

  const [answers, setAnswers] = useState({ answer1: "", answer2: "" });
  const questions = {
    q1: [
      "uniform",
      "luxury_brand",
      "sports_wear",
      "sports_gear",
      "outdoor_wear",
    ],
    q2: ["laundry_room", "mudroom", "garage", "entrance", "walk_in_closet"],
  };
  const names = {
    q1: [
      "Uniform",
      "Luxury Brand",
      "Sports Wear",
      "Sports Gear",
      "Outdoor Wear",
    ],
    q2: ["Laundry Room", "Mudroom", "Garage", "Entrance", "Walk-in Closet"],
  };

  return (
    <Wrapper>
      <Panel
        style={{
          width: 1568 * resize.ratio,
          height: (IS_MOBILE ? 960 : 858) * resize.ratio,
        }}
      >
        <CloseButton
          showLabel={!IS_MOBILE}
          style={{
            position: "absolute",
            zIndex: Z_INDEX_POPUP,
            top: "5%",
            right: "4%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
            GA_Event("survey", "styler_menu", "close");
          }}
        />
        <Text
          style={{ position: "relative", top: `${55 * resize.ratio}px` }}
          size={`${42 * resize.ratio}px`}
          color="white"
          weight={theme.font.weight.semibold}
        >
          How to keep your style stunning
        </Text>
        <div
          style={{
            position: "relative",
            width: (IS_MOBILE ? 1100 : 920) * resize.ratio,
            top: `${134 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 40 : 28) * resize.ratio}px`}
            color="white"
          >
            1. Which item would you want to style with LG Styler?
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: `${3 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
            }}
          >
            {questions.q1.map((data, index) => {
              return (
                <div key={index} style={{ width: `${resize.ratio * 129}px` }}>
                  <Image
                    alt={data.replaceAll("_", " ")}
                    role="img"
                    width={`${resize.ratio * 129}px`}
                    height={`${resize.ratio * 131}px`}
                    style={{
                      cursor: "pointer",
                      borderRadius: `${2.1 * resize.ratio}em`,
                      backgroundColor:
                        answers.answer1 === data
                          ? theme.colors.common.darkpink
                          : "transparent",
                    }}
                    src={
                      ROOT_PATH_PREFIX +
                      `/images/survey/styler/btn-q-1-0${index + 1}@2x.png`
                    }
                    onClick={() => {
                      setAnswers({ ...answers, answer1: data });
                      GA_Event("survey", "styler_answer_1", data);
                    }}
                  />
                  <Text
                    align="center"
                    size={`${(IS_MOBILE ? 3 : 1.8) * resize.ratio}em`}
                    style={{
                      paddingTop: `${resize.ratio * 1.2}em`,
                      color: "white",
                    }}
                  >
                    {names.q1[index]}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: (IS_MOBILE ? 1100 : 920) * resize.ratio,
            top: `${185 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 40 : 28) * resize.ratio}px`}
            color="white"
          >
            2. Where would you like to have your LG Styler?
          </Text>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: `${3 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
            }}
          >
            {questions.q2.map((data, index) => {
              return (
                <div key={index} style={{ width: `${resize.ratio * 129}px` }}>
                  <Image
                    alt={data.replaceAll("_", " ")}
                    role="img"
                    width={`${resize.ratio * 129}px`}
                    height={`${resize.ratio * 131}px`}
                    style={{
                      cursor: "pointer",
                      borderRadius: `${2.1 * resize.ratio}em`,
                      backgroundColor:
                        answers.answer2 === data
                          ? theme.colors.common.darkpink
                          : "transparent",
                    }}
                    src={
                      ROOT_PATH_PREFIX +
                      `/images/survey/styler/btn-q-2-0${index + 1}@2x.png`
                    }
                    onClick={() => {
                      setAnswers({ ...answers, answer2: data });
                      GA_Event("survey", "styler_answer_2", data);
                    }}
                  />
                  <Text
                    align="center"
                    size={`${(IS_MOBILE ? 3 : 1.8) * resize.ratio}em`}
                    style={{
                      paddingTop: `${resize.ratio * 1.2}em`,
                      color: "white",
                    }}
                  >
                    {names.q2[index]}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
        <SurveyButton
          ratio={resize.ratio}
          disabled={!(answers.answer1 !== "" && answers.answer2 !== "")}
          onClick={() => {
            setisCompleted(true);
            GA_Event("survey", "styler_menu", "save", {
              dimension16: "styler",
              dimension17: answers.answer1,
              dimension18: answers.answer2,
            });
          }}
        >
          Save and Exit
        </SurveyButton>
      </Panel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: visible;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

const SurveyButton = styled.button<{ ratio: number } & Record<string, any>>`
  position: absolute;
  bottom: ${({ ratio }) => ratio * 55}px;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 350 : 240)}px;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 80 : 56)}px;
  border-radius: ${({ ratio }) => (ratio * 10) / 2}px;
  background-color: "white";
  font-size: ${({ ratio }) => ratio * (IS_MOBILE ? 3.5 : 2.2)}em;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};

  :hover {
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.colors.common.darkpink};
    color: ${({ disabled }) => !disabled && "white"};
  }
`;

export default SurveyStyler;
