/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import { disposeScene } from '../../utils/disposeScene';
import { useLoader } from '@react-three/fiber';

type GLTFResult = GLTF & {
    nodes: {
        Door: THREE.Mesh
        door_white: THREE.Mesh
        ['case']: THREE.Mesh
        case_white: THREE.Mesh
        Chrom_Silver: THREE.Mesh
        case_black: THREE.Mesh
        Glass001: THREE.Mesh
        black_board: THREE.Mesh
        black_board_white: THREE.Mesh
        black_board_black: THREE.Mesh
        door_black: THREE.Mesh
        UI: THREE.Mesh
    }
    materials: {
        door_white: THREE.MeshStandardMaterial
        case_white: THREE.MeshStandardMaterial
        Chrom_Silver: THREE.MeshStandardMaterial
        case_black: THREE.MeshStandardMaterial
        ['Material.001']: THREE.MeshStandardMaterial
        black_board_white: THREE.MeshStandardMaterial
        black_board_black: THREE.MeshStandardMaterial
        door_black: THREE.MeshStandardMaterial
        UI: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/m/vs6_instaview_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/vs6_instaview_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult

    const caseRef = useRef<THREE.Mesh>(null!);
    const doorRef = useRef<THREE.Mesh>(null!);
    const blackBoardRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'vs6'){
            if(selectedColorIndex === 0){
                materials.Chrom_Silver.map = null;
                materials.Chrom_Silver.color.setHex(0x222222);
                materials.Chrom_Silver.metalness = 1;
                materials.Chrom_Silver.roughness = 0.5;

                caseRef.current.material = materials.case_white;
                doorRef.current.material = materials.door_white;
                blackBoardRef.current.material = materials.black_board_white;
            }
            else{
                materials.Chrom_Silver.map = null;
                materials.Chrom_Silver.color.setHex(0x050505);
                materials.Chrom_Silver.metalness = 1;
                materials.Chrom_Silver.roughness = 0.5;

                caseRef.current.material = materials.case_black;
                doorRef.current.material = materials.door_black;
                blackBoardRef.current.material = materials.black_board_black;
            }
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {

        materials.Chrom_Silver.map = null;
        materials.Chrom_Silver.color.setHex(0x222222);
        materials.Chrom_Silver.metalness = 1;
        materials.Chrom_Silver.roughness = 0.5;

        materials.Chrom_Silver.envMapIntensity = 4;
        materials.door_black.envMapIntensity = 3;
        materials.door_white.envMapIntensity = 10;
        materials.case_black.envMapIntensity = 2;
        materials.case_white.envMapIntensity = 2;
        materials.UI.envMapIntensity = 1;

        materials.black_board_black.envMapIntensity = 1;
        materials.black_board_white.envMapIntensity = 1;
        materials['Material.001'].roughness = 0.35;
        materials['Material.001'].envMapIntensity = 2;
        materials['Material.001'].emissiveIntensity = 0;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0, 0, 0.25]}>
            <mesh
                geometry={nodes.Door.geometry}
                material={nodes.Door.material}
                userData={{ name: 'Door' }}
                ref={doorRef}
            />
            <mesh
                geometry={nodes.door_white.geometry}
                material={nodes.door_white.material}
                position={[0.7924, 8.8901, 0]}
                scale={[0.0094, 0.0094, 0.0094]}
                userData={{ name: 'door_white' }}
            />
            <mesh
                geometry={nodes['case'].geometry}
                material={nodes['case'].material}
                userData={{ name: 'case' }}
                ref={caseRef}
            />
            <mesh
                geometry={nodes.case_white.geometry}
                material={nodes.case_white.material}
                position={[0.8684, 8.8901, 0]}
                scale={[0.0094, 0.0094, 0.0094]}
                userData={{ name: 'case_white' }}
            />
            <mesh
                geometry={nodes.Chrom_Silver.geometry}
                material={materials.Chrom_Silver}
                userData={{ name: 'Chrom_Silver' }}
            />
            <mesh
                geometry={nodes.case_black.geometry}
                material={materials.case_black}
                position={[0.8906, 8.8901, 0]}
                scale={[0.0094, 0.0094, 0.0094]}
                userData={{ name: 'case_black' }}
            />
            <mesh
                geometry={nodes.Glass001.geometry}
                material={materials['Material.001']}
                userData={{ name: 'Glass.001' }}
            />
            <mesh
                geometry={nodes.black_board.geometry}
                material={nodes.black_board.material}
                userData={{ name: 'black_board' }}

                ref={blackBoardRef}
            />
            <mesh
                geometry={nodes.black_board_white.geometry}
                material={nodes.black_board_white.material}
                position={[0.9238, 8.8901, 0]}
                scale={[0.0094, 0.0094, 0.0094]}
                userData={{ name: 'black_board_white' }}
            />
            <mesh
                geometry={nodes.black_board_black.geometry}
                material={materials.black_board_black}
                position={[0.9459, 8.8901, 0]}
                scale={[0.0094, 0.0094, 0.0094]}
                userData={{ name: 'black_board_black' }}
            />
            <mesh
                geometry={nodes.door_black.geometry}
                material={materials.door_black}
                position={[0.8146, 8.8901, 0]}
                scale={[0.0094, 0.0094, 0.0094]}
                userData={{ name: 'door_black' }}
            />
            <mesh geometry={nodes.UI.geometry} material={materials.UI} userData={{ name: 'UI' }} />
        </group>
    )
}
