import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react';
import {
    ContactShadows,
    MeshReflectorMaterial,
    Plane,
    Preload,
    Sky,
    Sphere,
    Text,
    useHelper,
    useTexture
} from "@react-three/drei";
import {getProductsData} from "../../../api/products/productsData";
import ProductContainer3D from "../../common/ProductContainer3D";
import {MathUtils} from "three";
import * as THREE from "three";
import Obstacle from "../../common/camera/Obstacle";
import {IS_MOBILE, Layer, ROOT_PATH_PREFIX} from "../../common/const";
import useCameraStore, {CameraState} from "../../../stores/cameraStore";
import useProductStore, {ProductState} from "../../../stores/productStore";
import useCameraControls from "../../../utils/useCameraControls";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";
import {useLoader} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {TextureLoader} from "three";
import ProductInfoButtonGroup2_PC from '../info/ProductInfoButtonGroup2';
import ProductInfoButtonGroup2_Mobile from "../info/mobile/ProductInfoButtonGroup2_M";
import {easeCubicOut} from "d3-ease";
import shallow from "zustand/shallow";
import LivingRoomModelVivid from './living/LivingRoomModelVivid';
import LivingRoomModelWarm from './living/LivingRoomModelWarm';
import LivingRoomModelDark from './living/LivingRoomModelDark';
import LivingRoomModelWood from './living/LivingRoomModelWood';
import LivingRoomModelMarble from './living/LivingRoomModelMarble';
import Tiiun from '../../../products/tiiun/Tiiun';
import TiiunMini from '../../../products/tiiun/TiiunMini';

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const cameraApiSelector = (state: CameraState) => state.api;
const selectedCategorySelector = (state: ProductState) => state.selectedCategory;
const selectedRoomSelector = (state: ProductState) => state.selectedRoom
const selectedProductSelector = (state: ProductState) => state.selectedProduct;

const ProductInfoButtonGroup2 = IS_MOBILE ? ProductInfoButtonGroup2_Mobile : ProductInfoButtonGroup2_PC;

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_DIFFUSE_m.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_DIFFUSE.exr');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_AO_m.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_Environment_01.exr';


const LivingRoom = React.memo(() => {

    const productData_air_care = useMemo(() => getProductsData("air_care"), []);
    const productData_wearable_air_purifier = useMemo(() => getProductsData("wearable_air_purifier"), []);
    const productData_mask_case = useMemo(() => getProductsData("mask_case"), []);
    const productData_tiiun = useMemo(() => getProductsData("tiiun"), []);
    const productData_tiiun_mini = useMemo(() => getProductsData("tiiun_mini"), []);
    const productData_air_conditioner = useMemo(() => getProductsData("air_conditioner"), []);


    const {setTargetPos, setLookPos, setTouchStart} = useCameraStore(cameraApiSelector);
    const selectedCategory = useProductStore(selectedCategorySelector);
    const selectedRoom = useProductStore(selectedRoomSelector);
    const selectedProduct = useProductStore(selectedProductSelector);
    const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();
    const lgHomeTheme = useSpaceThemeStore(state => state.lgHomeTheme);


    useLayoutEffect(() => {
        if (selectedRoom === 'living') {
            // cameraControls.setLookAt(0, 1, 0, -0.1, 1, 0, false);

            if (selectedCategory) {
                cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;

                if (selectedCategory.category === 'wearable_air_purifier') {
                    cameraControls.minAzimuthAngle = (-20 + 50) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 70) * THREE.MathUtils.DEG2RAD;
                }
                if (selectedCategory.category === 'mask_case') {
                    cameraControls.minAzimuthAngle = (-20 + 50) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 70) * THREE.MathUtils.DEG2RAD;
                } else if (selectedCategory.category === 'air_conditioner') {
                    if (selectedProduct?.modelNo === 'window_inverter') {
                        cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                        cameraControls.minPolarAngle = 60 * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxPolarAngle = 80 * THREE.MathUtils.DEG2RAD;
                    } else if (selectedProduct?.modelNo === 'heat_pump') {
                        cameraControls.minAzimuthAngle = (-20 + 50) * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxAzimuthAngle = (20 + 70) * THREE.MathUtils.DEG2RAD;
                        cameraControls.minPolarAngle = 60 * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
                    } else {
                        cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                        cameraControls.minPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxPolarAngle = 110 * THREE.MathUtils.DEG2RAD;
                    }
                } else if (selectedCategory.category === 'tiiun') {
                    // if(selectedProduct?.modelNo === 'tiiun_mini')
                    // {
                    //     cameraControls.minAzimuthAngle = (-20) * THREE.MathUtils.DEG2RAD;
                    //     cameraControls.maxAzimuthAngle = (70) * THREE.MathUtils.DEG2RAD;
                    // }
                    // else
                    if (selectedProduct?.modelNo === "tiiun") {
                        cameraControls.minAzimuthAngle = (-70 + 50) * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxAzimuthAngle = (20 + 50) * THREE.MathUtils.DEG2RAD;
                    }
                } else if (selectedCategory.category === 'tiiun_mini') {
                    if (selectedProduct?.modelNo === 'tiiun_mini') {
                        cameraControls.minAzimuthAngle = (-10) * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxAzimuthAngle = (50) * THREE.MathUtils.DEG2RAD;
                    }
                    // else if(selectedProduct?.modelNo === "tiiun" ) {
                    //     cameraControls.minAzimuthAngle = (-70 + 50) * THREE.MathUtils.DEG2RAD;
                    //     cameraControls.maxAzimuthAngle = (20 + 50) * THREE.MathUtils.DEG2RAD;
                    // }
                }

            } else {
                cameraControls.minAzimuthAngle = -Infinity;
                cameraControls.maxAzimuthAngle = Infinity;
                cameraControls.minPolarAngle = -Infinity;
                cameraControls.maxPolarAngle = Infinity;
            }
        }
    }, [selectedCategory, selectedRoom, selectedProduct]);

    useEffect(() => {
        return () => {
            console.log("거실공간 해제");
            //@ts-ignore
            useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, AO_URL)
            //@ts-ignore
            useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);

    const lightTargetRef2 = useRef<THREE.Object3D>(null!);
    const lightRef2 = useRef<THREE.SpotLight>(null!);
    // useHelper(lightRef2, SpotLightHelper);

    const lightRef = useRef<THREE.SpotLight>(null!);
    // useHelper(lightRef, SpotLightHelper);

    const lightTargetRef3 = useRef<THREE.Object3D>(null!);
    const lightRef3 = useRef<THREE.SpotLight>(null!);

    useEffect(() => {
        if (lightRef2.current) {
            lightRef2.current.target = lightTargetRef2.current;
        }
        if (lightRef3.current) {
            lightRef3.current.target = lightTargetRef3.current;
        }
    }, []);


    const {targetPos, lookPos, isOpenInfo} = useCameraStore(state => ({
        targetPos: state.targetPos,
        lookPos: state.lookPos,
        isOpenInfo: state.isOpenInfo
    }), shallow);

    //제품 선택 시 포커스 맞추기
    function focusToProduct(offset: [number, number, number] = [0, 0, 0]) {
        if (targetPos && lookPos) {
            rotateToTarget([targetPos.x + offset[0], targetPos.y + offset[1], targetPos.z + offset[2]], [lookPos.x + offset[0], lookPos.y + offset[1], lookPos.z + offset[2]], () => {
                cameraControls.enabled = true;
            }, 1, 1, easeCubicOut);
        }
    }

    return (
        <group>
            <spotLight position={[-13, 5, -2]} ref={lightRef} angle={0.5} penumbra={0.5} intensity={0.2}/>
            <pointLight position={[-9, 2, 2]} intensity={1} distance={3} color={"#eee2cf"}/>

            <spotLight position={[-3, 3, 0.8]} intensity={1} penumbra={0.5} angle={1} distance={4} ref={lightRef2}/>
            <spotLight position={[-9.2, 3, -1.49]}
                       intensity={1} penumbra={0.5} angle={1} distance={4} ref={lightRef3}/>

            <React.Suspense fallback={null}>
                {lgHomeTheme === 'vivid' && (<LivingRoomModelVivid/>)}
                {lgHomeTheme === 'warm' && (<LivingRoomModelWarm/>)}
                {lgHomeTheme === 'dark' && (<LivingRoomModelDark/>)}
                {lgHomeTheme === 'wood' && (<LivingRoomModelWood/>)}
                {lgHomeTheme === 'marble' && (<LivingRoomModelMarble/>)}
                <Tiiun/>
                <TiiunMini/>

                {!IS_MOBILE && (
                    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.32, 0]}>
                        <planeGeometry args={[20, 8.5]} />
                        {/*@ts-ignore*/}
                        <MeshReflectorMaterial
                            blur={[400, 100]}
                            resolution={1024}
                            mixBlur={1}
                            mixStrength={200}
                            mixContrast={0.5}
                            roughness={1}
                            depthScale={1}
                            minDepthThreshold={0.1}
                            maxDepthThreshold={1.5}
                            color="#151515"
                            metalness={1}
                            transparent={true}
                            opacity={(lgHomeTheme === 'vivid' || lgHomeTheme === 'dark' || lgHomeTheme === 'wood') ? 0.15 : 0}
                        />
                    </mesh>
                )}

                {/*<Axis visible={true}/>*/}
                <Sky distance={450000} sunPosition={[-1, 0.5, 0]} inclination={0} azimuth={0.15}/>
                <Preload all/>
            </React.Suspense>

            {/* 이동 제한을 위한 콜라이더 설치 */}
            <Obstacle size={[20, 4, 2]} position={[0, 2, -4.5]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            <Obstacle size={[20, 4, 2]} position={[0, 2, 2.9]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            <Obstacle size={[2, 4, 10]} position={[3.8, 2, 0]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            <Obstacle size={[2, 4, 10]} position={[-8, 2, 0]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>

            {/* 바닥 히트처리 */}
            <Plane args={[10, 5.6]} position={[-3, 0.33, -0.5]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow
                   onClick={(e) => {
                       // console.log(e);
                       e.stopPropagation();
                       if (e.delta < 10) {
                           setLookPos(null);
                           setTargetPos(e.point);
                       }
                   }}>
                <meshBasicMaterial color={"#ff3"} transparent={true} opacity={0} depthWrite={false} depthTest={false}/>
            </Plane>

            {/*제품 3D 컨테이너*/}
            <>
                {/* 공기청정기 */}
                <ProductContainer3D
                    args={[0.3, 1.2, 0.3]}
                    position={[-5, 0.94, 0.8]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={'air_care'}
                    data={productData_air_care}
                    matchingModelNo={['puricare_pet', 'puricare_stage1', 'puricare_stage2', 'aerotower']}
                    cameraTargetPos={[0, 0, 2]}
                    uiOffset={
                        (selectedProduct?.modelNo === 'puricare_stage1') ? [0, -0.43, -0.1] :
                            (selectedProduct?.modelNo === 'aerotower' ? [0.05, -0.06, -0.1] : [0, 0, -0.1])
                    }
                    uiScale={0.75}
                    uiInfoOffset={[0, 0, 0.8]}
                    showDebug={false}
                >
                    <mesh ref={lightTargetRef2} position={[0, 0, 0.3]}/>
                </ProductContainer3D>

                {/* 공기청정기2 */}
                <ProductContainer3D
                    args={[0.3, 0.3, 0.3]}
                    position={[-5.5, 0.8, 0]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={'air_care'}
                    data={productData_air_care}
                    matchingModelNo={['puricare_mini', 'puricare_mini_2nd']}
                    uiOffset={
                        (selectedProduct?.modelNo === 'puricare_mini') ? [0.1, -0.095, -0.15] : [0.1, -0.075, -0.15]
                    }
                    uiScale={0.35}
                    cameraTargetPos={[0, 0, 1]}
                    uiInfoOffset={[0, 0, 0.2]}
                    showDebug={false}
                >
                </ProductContainer3D>

                {/* 마스크 */}
                <ProductContainer3D
                    args={[0.5, 0.15, 0.15]}
                    position={[-3.7, 0.71, -2.05]}
                    rotation={[0, MathUtils.DEG2RAD * 55, 0]}
                    category={'wearable_air_purifier'}
                    data={productData_wearable_air_purifier}
                    showDebug={false}
                    uiOffset={[0, -0.08, -0.15]}
                    // uiRotation={[-0.25, 0.25, 0.15]}
                    uiRotation={[-THREE.MathUtils.DEG2RAD * 20, THREE.MathUtils.DEG2RAD * 3, 0]}
                    uiScale={0.5}
                    // cameraTargetPos={[0,0.5,2]}
                    cameraTargetPos={[0, 0.4, 1]}  // 211221
                    uiInfoOffset={[-0.3, -0.1, 0.6]} // 211222
                    specboardOffset={[-0.15, -0.03, 0]}
                >
                    <ProductInfoButtonGroup2
                        position={[0.4, 0.06, -0.1]}
                        scale={0.5}
                        rotation={[-THREE.MathUtils.DEG2RAD * 20, THREE.MathUtils.DEG2RAD * 3, 0]}
                        specboardOffset={[1.4, 0, 0]}
                        specImgUrl={
                            selectedProduct?.modelNo === 'wearable_2nd' ?
                                "/images/products/wearable_air_purifier/wearable_2nd_case/specboard.png" :
                                "/images/products/wearable_air_purifier/wearable_case/specboard.png"}
                        specboardCallback={(show: boolean) => {
                            if (!show) console.log(">스펙보드 닫힘!");
                            focusToProduct(show ? [0.2, -0.1, -0.6] : [0, 0, 0]);
                        }}
                    />
                </ProductContainer3D>

                {/* 티운 미니 */}
                <ProductContainer3D
                    args={[1.1, 0.3, 0.15]}
                    position={[-4.85, 0.96, -3.7]}
                    rotation={[0, 0, 0]}
                    category={'tiiun_mini'}
                    data={productData_tiiun_mini}
                    showDebug={false}
                    cameraTargetPos={[0, 0, 2.5]}
                    uiScale={0.7}  // 211221
                    uiOffset={[IS_MOBILE ? 1.5 : 1.35, -0.025, 0]}
                    uiInfoOffset={IS_MOBILE ? [0.8, 0, -0.6] : [0.6, 0, -0.6]}
                    specboardOffset={[IS_MOBILE ? 1.25 : 1.35, -0.03, 0]}
                >
                </ProductContainer3D>

                {/* 티운 */}
                <ProductContainer3D
                    args={[0.6, 0.8, 0.6]}
                    position={[-5.79, 0.73, -3.02]}
                    rotation={[0, 0.93, 0]}
                    category={'tiiun'}
                    data={productData_tiiun}
                    showDebug={false}
                    cameraTargetPos={[0.6, 0, 2.2]}
                    cameraLookOffset={[1.2, 0, 0]}
                    uiOffset={[0, 0.01, 0]}
                    uiScale={0.58}  // 211221
                    uiRotation={[0, -MathUtils.DEG2RAD * 5, 0]}
                    uiInfoOffset={[-1, 0, 1]}
                    // specboardOffset={[-0.15, -0.025, 0]}
                >
                </ProductContainer3D>

                {/* 에어콘 */}
                <ProductContainer3D
                    args={[0.85, 0.35, 0.2]}
                    position={[-8.975, 3, 0]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={'air_conditioner'}
                    data={productData_air_conditioner}
                    matchingModelNo={['dualcool', 'artcool']}
                    showDebug={false}
                    uiScale={0.7}
                    uiOffset={[0, -0.04, 0]}
                    cameraTargetPos={[0, 0, 2]}
                    uiInfoOffset={
                        IS_MOBILE ? [0, -0.25, 0.75] : [0, 0, 0.7]
                    }
                >
                </ProductContainer3D>

                {/* 에어콘2 */}
                <ProductContainer3D
                    args={[0.7, 0.4, 0.2]}
                    position={[-9.2, 1.26, -1.49]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={'air_conditioner'}
                    data={productData_air_conditioner}
                    matchingModelNo={['window_inverter']}
                    uiOffset={[0.05, 0, 0]}
                    uiRotation={[-MathUtils.DEG2RAD * 8, 0, 0]}
                    uiScale={0.57}
                    cameraTargetPos={[0, 0.3, 1.7]}
                    specboardOffset={[-0.15, -0.03, 0]}
                    showDebug={false}
                    uiInfoOffset={IS_MOBILE ? [0, -0.25, 0.75] : [0, 0, 0.7]}
                >
                    <mesh ref={lightTargetRef3}>
                    </mesh>
                </ProductContainer3D>

                {/* 에어콘2 */}
                <ProductContainer3D
                    args={[0.5, 1.34, 0.5]}
                    position={[-8.83, 0.99, -3.29]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={'air_conditioner'}
                    data={productData_air_conditioner}
                    matchingModelNo={['heat_pump']}
                    cameraTargetPos={[0, 0.3, 1.7]}
                    uiOffset={[0, -0.04, 0]}
                    uiScale={0.6}
                    uiRotation={[-0.15, 0, 0]}
                    showDebug={false}
                    uiInfoOffset={[0, 0, 0.5]}
                    specboardOffset={[-0.15, -0.04, 0]}
                >
                </ProductContainer3D>
            </>
        </group>
    );
});

export default LivingRoom;
