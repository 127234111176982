import {useMemo, useEffect, useState, useCallback, useRef} from "react";
import {useThree} from "@react-three/fiber";
import * as THREE from "three";
import CameraControls from "camera-controls";
import {useSpring} from "@react-spring/web";
import {easeCubicOut} from "d3-ease";


/**
 * 카메라 제어용 콤포넌트
 */

CameraControls.install({THREE: THREE});

type UseCameraControlsType = [
    cameraControls: CameraControls,
    rotateToTarget: (
        pos: [number, number, number],
        lookPos: [number, number, number],
        completeAction?: (() => void),
        zoom?: number,
        duration?: number,
        easing?: ((t: number) => number) | undefined
    ) => void,
    cancelAnimation: () => void,
    isTweening: React.MutableRefObject<boolean>
]
;

export class CameraControlsSingleton {
    private static instance: CameraControls;
    private static target = new THREE.Vector3();
    private static position = new THREE.Vector3();

    public static getInstance(camera: THREE.PerspectiveCamera | THREE.OrthographicCamera, domElement: HTMLCanvasElement): CameraControls {
        if (!this.instance) {
            this.instance = new CameraControls(camera, domElement);
            console.log("Camera-Controls 생성");
            window.addEventListener('keydown', CameraControlsSingleton.keydownHandler);
        }
        return this.instance;
    }

    private static keydownHandler(e: KeyboardEvent) {
        if (e.key === '1') {
            const cameraControls = CameraControlsSingleton.instance;
            cameraControls.getTarget(CameraControlsSingleton.target);
            cameraControls.getPosition(CameraControlsSingleton.position);
            console.log(
                "azimuthAngle: ", cameraControls.azimuthAngle * THREE.MathUtils.RAD2DEG,
                "\npolarAngle: ", cameraControls.polarAngle * THREE.MathUtils.RAD2DEG,
                "\ncamera_target: ", CameraControlsSingleton.target,
                "\ncamera_position: ", CameraControlsSingleton.position,
            );
        }
    }
}



const useCameraControls = (): UseCameraControlsType => {
    const gl = useThree(state => state.gl);
    const camera = useThree(state => state.camera);

    const cameraControls = useMemo(() => {
        // const controls = new CameraControls(camera, gl.domElement);
        const controls = CameraControlsSingleton.getInstance(camera, gl.domElement);
        controls.azimuthRotateSpeed = -0.3;
        controls.polarRotateSpeed = -0.3;
        controls.mouseButtons.middle = CameraControls.ACTION.NONE;
        controls.mouseButtons.right = CameraControls.ACTION.NONE;
        controls.mouseButtons.wheel = CameraControls.ACTION.ZOOM;
        controls.touches.two = CameraControls.ACTION.TOUCH_ZOOM;
        controls.maxZoom = 5;
        controls.minZoom = 1;

        return controls;
    }, [gl, camera]);

    const [spring, animate] = useSpring(() => ({
        from: {
            zoom: camera.zoom,
            posX: camera.position.x,
            posY: camera.position.y,
            posZ: camera.position.z,
            lookPosX: 0,
            lookPosY: 0,
            lookPosZ: 0,
        }
    }));

    const [camPos] = useState(() => new THREE.Vector3());
    const [camDir] = useState(() => new THREE.Vector3());

    useEffect(() => {
        cameraControls.azimuthRotateSpeed = -0.3 / camera.zoom;
        cameraControls.polarRotateSpeed = -0.3 / camera.zoom;
    }, [camera.zoom]);

    // useEffect(() => {
    //     return () => {
    //         animate({cancel: true});
    //         if (cameraControls) {
    //             cameraControls.dispose();
    //             // console.log("cameraControls disposed!");
    //         }
    //     }
    // }, []);

    const isTweening = useRef(false);


    //카메라를 목표지점으로 회전 및 줌 애니메이션
    const rotateToTarget = useCallback((
        pos: [number, number, number],
        lookPos: [number, number, number],
        completeAction?: (() => void),
        zoom = 1.25,
        duration = 1,
        easing = easeCubicOut
    ) => {
        console.log("rotateToTarget: ", spring);

        let _duration = duration;
        if (_duration < 0.1) _duration = 0.5;

        const [x, y, z] = pos;
        const [lookPosX, lookPosY, lookPosZ] = lookPos;

        cameraControls.getTarget(camDir);
        cameraControls.getPosition(camPos);

        // if(isTweening.current) return;
        isTweening.current = true;

        animate({cancel: true});
        animate({
            cancel: false,
            from: {
                zoom: camera.zoom,
                posX: camPos.x,
                posY: camPos.y,
                posZ: camPos.z,
                lookPosX: camDir.x,
                lookPosY: camDir.y,
                lookPosZ: camDir.z
            },
            posX: x,
            posY: y,
            posZ: z,
            lookPosX: lookPosX,
            lookPosY: lookPosY,
            lookPosZ: lookPosZ,
            zoom: zoom,
            onChange: result => {
                cameraControls.setTarget(result.value.lookPosX, result.value.lookPosY, result.value.lookPosZ, false);
                cameraControls.setPosition(result.value.posX, result.value.posY, result.value.posZ, false);
                cameraControls.zoomTo(result.value.zoom, false);
            },
            onStart: result => {
                cameraControls.enabled = false;
            },
            onRest: result => {
                isTweening.current = false;
                if (completeAction) completeAction();

                cameraControls.getTarget(camDir);
                cameraControls.getPosition(camPos);
                console.log(">>>>>>>>>>>>>>>>>>>>!!!!!!!!!!", camPos, camDir)
            },
            config: {duration: _duration * 1000, easing: easing}
        });
    }, []);

    const cancelAnimation = useCallback(() => {
        console.log("cancelAnimation: ", spring);

        animate({cancel: true});
    }, []);

    return [cameraControls, rotateToTarget, cancelAnimation, isTweening];
};

export default useCameraControls;
