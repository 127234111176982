import {ProductsDataItemType} from "./productsData";
import A9t from "../../products/vacuum_cleaner/A9t";
import A9k from "../../products/vacuum_cleaner/A9k";
import R9 from "../../products/vacuum_cleaner/R9";
import M9 from "../../products/vacuum_cleaner/M9";


/**
 * 청소기 데이터
 */
const productsData_VacuumCleaner: ProductsDataItemType = {
    category: "vacuum_cleaner",
    ga_id: "vacuum_cleaner",
    label: "category_vacuum_cleaner",
    category_img_url: "/images/products/vacuum_cleaner/category_img.png",
    category_thumb_url: "/images/products/vacuum_cleaner/category_thumb.png",
    category_glow_url: "/images/products/vacuum_cleaner/category_glow.png",
    roomType: "utility",
    usp_transition: "/movies/usp/transition/main_VC_000_00000.webm",
    productList: [
        {
            name: "A9T",
            modelNo: "a9t",
            ga_id: "a9t",
            isCES: true,
            thumb_url: "/images/products/vacuum_cleaner/a9t/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_deep_iron",
                    color: "/images/products/vacuum_cleaner/a9t/color_deep_iron.png",
                    ga_id: "deep_grey&iron_grey",
                },
            ],
            model: <A9t/>,
        },
        {
            name: "A9K",
            modelNo: "a9k",
            ga_id: "a9k",
            isCES: false,
            thumb_url: "/images/products/vacuum_cleaner/a9k/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_grey",
                    color: "/images/products/vacuum_cleaner/a9k/color_grey.png",
                    ga_id: "iron_grey",
                },
                {
                    name: <></>,
                    label: "home_exp_color_fantasy",
                    color: "/images/products/vacuum_cleaner/a9k/color_fantasy.png",
                    ga_id: "fantasy_silver",
                },
                {
                    name: <></>,
                    label: "home_exp_color_wine",
                    color: "/images/products/vacuum_cleaner/a9k/color_wine.png",
                    ga_id: "vintage_wine",
                },
                {
                    name: <></>,
                    label: "home_exp_color_full",
                    color: "/images/products/vacuum_cleaner/a9k/color_full.png",
                    ga_id: "full_black",
                },
            ],
            model: <A9k/>,
        },
        {
            name: "R9",
            modelNo: "r9",
            ga_id: "r9",
            // isCES: true, //유지/신규 둘다있어서 트루로 둠
            thumb_url: "/images/products/vacuum_cleaner/r9/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_grey",
                    color: "/images/products/vacuum_cleaner/r9/color_grey.png",
                    ga_id: "iron_grey",
                },
                {
                    name: <></>,
                    label: "home_exp_color_red",
                    color: "/images/products/vacuum_cleaner/r9/color_red.png",
                    ga_id: "bohemian_red",
                },
                {
                    name: <></>,
                    label: "home_exp_color_fantasy",
                    color: "/images/products/vacuum_cleaner/r9/color_fantasy.png",
                    ga_id: "fantasy_silver",
                },
            ],
            model: <R9/>,
        },
        {
            name: "M9",
            modelNo: "m9",
            ga_id: "m9",
            isCES: false,
            thumb_url: "/images/products/vacuum_cleaner/m9/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_grey",
                    color: "/images/products/vacuum_cleaner/m9/color_grey.png",
                    ga_id: "iron_grey",
                },
                {
                    name: <></>,
                    label: "home_exp_color_fantasy",
                    color: "/images/products/vacuum_cleaner/m9/color_fantasy.png",
                    ga_id: "fantasy_silver",
                },
                {
                    name: <></>,
                    label: "home_exp_color_pink",
                    color: "/images/products/vacuum_cleaner/m9/color_pink.png",
                    ga_id: "blossom_pink",
                },
                {
                    name: <></>,
                    label: "home_exp_color_wine",
                    color: "/images/products/vacuum_cleaner/m9/color_wine.png",
                    ga_id: "vintage_wine",
                },
            ],
            model: <M9/>,
        },
    ],
};

export default productsData_VacuumCleaner;
