import { ProductsCategoryType } from "../products/productsData";
import specboardsData_Refrigerator from "./specboardData_Refrigerator";
import React from "react";
import specboardsData_AirConditioner from "./specboardData_AirConditioner";
import specboardsData_Cooking from "./specboardData_Cooking";
import specboardsData_AirCare from "./specboardData_AirCare";
import specboardsData_DishWasher from "./specboardData_DishWasher";
import specboardsData_HomeBrew from "./specboardData_HomeBrew";
import specboardsData_MicrowaveOven from "./specboardData_MicrowaveOven";
import specboardsData_Styler from "./specboardData_Styler";
import specboardsData_Tiiun from "./specboardData_Tiiun";
import specboardsData_VacuumCleaner from "./specboardData_VacuumCleaner";
import specboardsData_WashernDryer from "./specboardData_WashernDryer";
import specboardsData_WaterPurifier from "./specboardData_WaterPurifier";
import specboardsData_WearableAirPurifier from "./specboardData_WearableAirPurifier";
import specboardsData_TiiunMini from "./specboardData_TiiunMini";

export type SpecboardsDataItemType = {
  category: ProductsCategoryType; //카테고리 타입
  specboardList?: SpecboardDataItemType[]; //카테고리 내 제품 데이터 리스트
};

export type SpecboardDataItemType = {
  name: string;
  modelNo?: string;
  specboard_img_url?: string;
  title_img_url: string;
  title_img_alt?: string;
  ces_award_img_url?: string;
  ces_award_img_alt?: string;
  spec_img_url?: string;
  spec_img_alt?: string;
  spec: string[] | React.ReactNode[];
  thinq_img_url?: string;
  thinq_img_alt?: string;
  thinq_spec: string[] | React.ReactNode[];
};

//제품 카테고리 데이터
const specboardData: SpecboardsDataItemType[] = [
  specboardsData_AirCare,
  specboardsData_AirConditioner,
  specboardsData_Cooking,
  specboardsData_DishWasher,
  specboardsData_HomeBrew,
  specboardsData_MicrowaveOven,
  specboardsData_Refrigerator,
  specboardsData_Styler,
  specboardsData_Tiiun,
  specboardsData_TiiunMini,
  specboardsData_VacuumCleaner,
  specboardsData_WashernDryer,
  specboardsData_WaterPurifier,
  specboardsData_WearableAirPurifier,
];

//카테고리 타입으로 제품 스펙보드 목록 데이터 가져오기
export function getSpecboardsData(category: ProductsCategoryType) {
  if (!category) return;
  const result = specboardData.filter((val) => val.category === category);
  return result && result.length > 0 ? result[0] : null;
}

//제품 데이터 리스트에서 모델번호로 단일 제품 스펙보드 데이터 가져오기
export function getSpecboardDataFromList(
  specboardList: SpecboardDataItemType[],
  modelNo: string
) {
  const result = specboardList?.filter((val) => val.modelNo === modelNo);
  return result && result.length > 0 ? result[0] : null;
}

export default specboardData;
