import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { GA_Event, sendGaEvent } from "../../api/ga";
import { useAudioStore } from "../../stores/AudioStore";
import { Image } from "./comps_styled/Image.styled";
import { ROOT_PATH_PREFIX } from "./const";
import useHistoryStore from "../../stores/historyStore";
import useResizeStore from "../../stores/resizeStore";
import HoverButton from "./navigation/HoverButton";
import HoverAudioButton from "./navigation/HoverAudioButton";

type Props = {
  tabIndex?: number;
  hover?: boolean;
  onMouseEnterHandler?: () => void;
  onMouseLeaveHandler?: () => void;
  onClickHandler?: () => void;
} & JSX.IntrinsicElements["button"];

const AudioButton = ({
  tabIndex = 0,
  hover,
  onMouseEnterHandler,
  onMouseLeaveHandler,
  onClickHandler,
  ...rest
}: Props) => {
  const [innerHover, setinnerHover] = useState(false);
  const audio = useAudioStore((state) => state);
  const pathname = useHistoryStore((state) => state.pathname);
  const isThinq = useMemo(() => pathname.includes("/thinq"), [pathname]);
  const isHome = useMemo(() => pathname.includes("/home"), [pathname]);
  const isObjet = useMemo(
    () => pathname.includes("/objetcollection"),
    [pathname]
  );
  // const resize = useResizeStore((state) => state.resize);

  return (
    <HoverAudioButton
      {...rest}
      clicked={!audio.isPlaying}
      defaultSrc={
        isThinq
          ? ROOT_PATH_PREFIX + "/images/icons/thinq/btn_volume_off_default.png"
          : ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_off_default.png"
      }
      hoverSrc={
        isThinq
          ? ROOT_PATH_PREFIX + "/images/icons/thinq/btn_volume_off_hover.png"
          : ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_off_hover.png"
      }
      pressSrc={
        isThinq
          ? ROOT_PATH_PREFIX + "/images/icons/thinq/btn_volume_off_press.png"
          : ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_off_press.png"
      }
      clickDefaultSrc={
        isThinq
          ? ROOT_PATH_PREFIX + "/images/icons/thinq/btn_volume_on_default.png"
          : ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_on_default.png"
      }
      clickHoverSrc={
        isThinq
          ? ROOT_PATH_PREFIX + "/images/icons/thinq/btn_volume_on_hover.png"
          : ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_on_hover.png"
      }
      clickPressSrc={
        isThinq
          ? ROOT_PATH_PREFIX + "/images/icons/thinq/btn_volume_on_press.png"
          : ROOT_PATH_PREFIX + "/images/icons/lang/btn_volume_on_press.png"
      }





      onClick={() => {
        onClickHandler && onClickHandler();
        !audio.isPlaying ? audio.play() : audio.pause();

        isHome &&
          GA_Event(
            "lg_home",
            "settinigs",
            !audio.isPlaying ? "sounds_on" : "sounds_off"
          );
        isObjet &&
          GA_Event(
            "lg_objet",
            "settinigs",
            !audio.isPlaying ? "sounds_on" : "sounds_off"
          );
        if (isThinq) {
          if (pathname.indexOf("/thinq/easy_convenient") > -1) {
            sendGaEvent(
              !audio.isPlaying
                ? "thinq_settinigs_easy&convenient_sound_on"
                : "thinq_settinigs_easy&convenient_sound_off"
            );
          } else if (pathname.indexOf("/thinq/unique_delightful") > -1) {
            sendGaEvent(
              !audio.isPlaying
                ? "thinq_settinigs_unique&delightful_sound_on"
                : "thinq_settinigs_unique&delightful_sound_off"
            );
          } else if (pathname === "/thinq") {
            sendGaEvent(
              !audio.isPlaying
                ? "thinq_settinigs_lg_thinq_main_sound_on"
                : "thinq_settinigs_lg_thinq_main_sound_off"
            );
          }
        }
      }}
    />
  );
};

export default AudioButton;
