import React from "react";
import styled from "styled-components";
import { ROOT_PATH_PREFIX } from "./const";

const WarningIe = () => {
  return (
    <Wrapper>
      <h1>BROWSER WARNING</h1>
      <p>You're currently using Internet Explorer to access this traning.</p>
      <img alt="icon ie" src={ROOT_PATH_PREFIX + "/images/etc/icon_ie.jpg"} />
      <p>
        To get the best experience, we recommend accessing it on Microsoft Edge,
        Google Chrome, Mozilla Firefox, or Safari.
      </p>
      <img
        alt="icon browsers"
        src={ROOT_PATH_PREFIX + "/images/etc/icon_browsers.jpg"}
        className="otherBrowsers"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: white;
  color: black;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 3rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  overflow-y: auto;

  h1 {
    font-size: 5rem;
    color: #009a92;
  }

  p {
    font-size: 3rem;
  }

  .otherBrowsers {
    max-width: 100%;
  }

  img {
    width: auto;
    height: auto;
  }
`;

export default WarningIe;
