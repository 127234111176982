/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        VC_05_Decal: THREE.Mesh
        VC_05: THREE.Mesh
        Mesh001: THREE.Mesh
        Mesh001_1: THREE.Mesh
        VC_05_UI: THREE.Mesh
        Part1: THREE.Mesh
        Plane003: THREE.Mesh
        Plane: THREE.Mesh
        Plane001: THREE.Mesh
        Plane002: THREE.Mesh
    }
    materials: {
        VC_05_Decal: THREE.MeshStandardMaterial
        Glass_01: THREE.MeshStandardMaterial
        Glass_02: THREE.MeshStandardMaterial
        UI: THREE.MeshStandardMaterial
        Part1: THREE.MeshStandardMaterial
        M9_Part1_Wine: THREE.MeshStandardMaterial
        M9_Part1_Pink: THREE.MeshStandardMaterial
        M9_Part1_Gray: THREE.MeshStandardMaterial
        M9_Part1_Silver: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/m/m9_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/m9_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  // const { nodes, materials, scene } = useGLTF(ROOT_PATH_PREFIX+'/models/vc/vc_05/vc_05.gltf') as GLTFResult
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);

    const targetRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'm9'){
            if(selectedColorIndex === 0){
                targetRef.current.material = materials.M9_Part1_Gray;
            }
            else if(selectedColorIndex === 1){
                targetRef.current.material = materials.M9_Part1_Silver;
            }
            else if(selectedColorIndex === 2){
                targetRef.current.material = materials.M9_Part1_Pink;
            }
            else{
                targetRef.current.material = materials.M9_Part1_Wine;
            }
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {
        targetRef.current.material = materials.M9_Part1_Gray;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

  return (
    <group ref={group} {...props} dispose={null}>
        <mesh
            geometry={nodes.VC_05_Decal.geometry}
            material={materials.VC_05_Decal}
            userData={{ name: 'VC_05_Decal' }}
        />
        <mesh
            geometry={nodes.VC_05.geometry}
            material={nodes.VC_05.material}
            userData={{ name: 'VC_05' }}>
            <group
                position={[-0.0004, 0.0576, -0.1427]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'VC_05_Glass' }}>
                <mesh geometry={nodes.Mesh001.geometry} material={materials.Glass_01} />
                <mesh geometry={nodes.Mesh001_1.geometry} material={materials.Glass_02} />
            </group>
            <mesh
                geometry={nodes.VC_05_UI.geometry}
                material={materials.UI}
                position={[-0.0564, 0.0435, -0.2698]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'VC_05_UI' }}
            />
        </mesh>
        <mesh
            geometry={nodes.Part1.geometry}
            material={materials.Part1}
            userData={{ name: 'Part1' }}
            ref={targetRef}
        />
        <mesh
            geometry={nodes.Plane003.geometry}
            material={materials.M9_Part1_Wine}
            position={[0, 0, -1.2121]}
            userData={{ name: 'Plane.003' }}
            visible={false}
        />
        <mesh
            geometry={nodes.Plane.geometry}
            material={materials.M9_Part1_Pink}
            position={[0, 0, -0.7893]}
            userData={{ name: 'Plane' }}
            visible={false}
        />
        <mesh
            geometry={nodes.Plane001.geometry}
            material={materials.M9_Part1_Gray}
            position={[0, 0, -0.9315]}
            userData={{ name: 'Plane.001' }}
            visible={false}
        />
        <mesh
            geometry={nodes.Plane002.geometry}
            material={materials.M9_Part1_Silver}
            position={[0, 0, -1.0623]}
            userData={{ name: 'Plane.002' }}
            visible={false}
        />
    </group>
  )
}
