import styled from "styled-components";

export const Divider = styled.hr<{
  width?: any;
  color?: string;
  borderwidth?: number;
}>`
  border: 0;
  border-top: ${({ borderwidth }) => borderwidth || 1}px solid
    ${({ color, theme }) => color || theme.colors.home.darkgray} !important;
  width: ${({ width }) => width || "100%"} !important;
  margin: 0;
  padding: 0;
`;

export const DividerVetical = styled.div<{
  height?: any;
  color?: string;
  borderwidth?: number;
}>`
  border-left: ${({ borderwidth }) => borderwidth || 1}px solid
    ${({ color, theme }) => color || theme.colors.home.darkgray} !important;
  height: ${({ height }) => height || "100%"} !important;
  margin: 0;
  padding: 0;
`;
