import create from "zustand";

/**
 * 공간 테마 상태관리
 */

export type LgHomeThemeType = "warm" | "vivid" | "dark" | "wood" | "marble";
export type ObjetThemeType = "blossom" | "natural" | "modern";

export type SpaceThemeState = {
  lgHomeTheme: LgHomeThemeType;
  objetTheme: ObjetThemeType;
  api: {
    setLgHomeTheme: (theme: LgHomeThemeType) => void;
    setObjetTheme: (theme: ObjetThemeType) => void;
  };
};

const useSpaceThemeStore = create<SpaceThemeState>((set) => ({
  lgHomeTheme: "dark",
  objetTheme: "natural",
  api: {
    setLgHomeTheme: (theme) => {
      set({ lgHomeTheme: theme.toLocaleLowerCase() as LgHomeThemeType });
    },
    setObjetTheme: (theme) => set({ objetTheme: theme }),
  },
}));

export type PartTextureType = {
  material: string;
  setMeterial: (value: string) => void;
};
export const useObjetWallTextureStore = create<PartTextureType>((set) => ({
  material: "",
  setMeterial: (value) => set({ material: value }),
}));

export const useObjetFloorTextureStore = create<PartTextureType>((set) => ({
  material: "",
  setMeterial: (value) => set({ material: value }),
}));

export default useSpaceThemeStore;
