import {UspDataItemType, UspPageDataType} from "./uspData";


//---------------------------------------------------[EN]
const uspData_Styler: UspPageDataType[] = [
    {
        category: "styler",
        modelNo:"essence",
        bgImgUrl:"/images/usp/styler/essence.jpg",
        uspList:[
            {
                label:"essence_usp_0",
                title: [<>Say goodbye to<br/>all the germs from outside.</>],
                subMsg: [<>TrueSteam</>],
                videoUrl: "https://vimeo.com/658884952/5f6d0492f9",
                x: 230,
                y: 292,
                ga_id: "truesteam"
            },
            {
                label:"essence_usp_0",
                title: [<>To get the dust out<br/>of your garments, Shake it!</>],
                subMsg: [<>Moving Hanger</>],
                videoUrl: "https://vimeo.com/658884917/f7365aec0b",
                x: 230,
                y: 456,
                ga_id: "moving_hanger"
            },
            {
                isRight: true,
                label:"essence_usp_0",
                title: [<>It can even dry?</>],
                subMsg: [<>Gentle Dry with HeatPump System</>],
                videoUrl: "https://vimeo.com/658884882/7ff069a986",
                x: 1185,
                y: 620,
                ga_id: "gentle_dry_with_heatpump_system"
            },
        ]
    },
    {
        category: "styler",
        modelNo:"baron",
        bgImgUrl:"/images/usp/styler/baron.jpg",
        uspList:[
            {
                label:"baron_usp_0",
                title: [<>Say goodbye to<br/>all the germs from outside.</>],
                subMsg: [<>TrueSteam</>],
                videoUrl: "https://vimeo.com/658885241/98f1397e7f",
                x: 230,
                y: 306,
                ga_id: "truesteam"
            },
            {
                label:"baron_usp_0",
                title: [<>To get the dust out<br/>of your garments, Shake it!</>],
                subMsg: [<>Moving Hanger</>],
                videoUrl: "https://vimeo.com/658885203/f27bf30773",
                x: 230,
                y: 456,
                ga_id: "moving_hanger"
            },
            {
                isRight: true,
                label:"baron_usp_0",
                title: [<>Manage your various styles<br/>of clothes all at once!</>],
                subMsg: [<>Large Capacity</>],
                videoUrl: "https://vimeo.com/658885154/b45a2f8870",
                x: 1185,
                y: 456,
                ga_id: "large_capacity"
            },
            {
                isRight: true,
                label:"baron_usp_0",
                title: [<>It can even dry?</>],
                subMsg: [<>Gentle Dry with HeatPump System</>],
                videoUrl: "https://vimeo.com/658879196/7426c0c6c2",
                x: 1185,
                y: 606,
                ga_id: "gentle_dry_with_heatpump_system"
            },
        ]
    },
];

export default uspData_Styler;
