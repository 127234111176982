import {ProductsDataItemType} from "./productsData";
import Essence from "../../products/styler/Essence";
import Baron from "../../products/styler/Baron";


/**
 * 스타일러 데이터
 */
const productsData_Styler: ProductsDataItemType = {
    category: "styler",
    ga_id: "styler",
    label: "category_styler",
    category_img_url: "/images/products/styler/category_img.png",
    category_thumb_url: "/images/products/styler/category_thumb.png",
    category_glow_url: "/images/products/styler/category_glow.png",
    roomType: "utility",
    usp_transition: "/movies/usp/transition/main_ST_000_00000_1.webm",
    productList: [
        {
            name: "Essence Refresh",
            modelNo: "essence",
            ga_id: "essence",
            isCES: false,
            thumb_url: "/images/products/styler/essence/product_thumb.png",
            ar_url: "http://lgevirtual.com/ar/styler",
            ar_text: "home_exp_ar_essence",
            openExperience: true,
            open_url: "/images/products/styler/essence/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_mirror",
                    color: "/images/products/styler/essence/color_mirror.png",
                    ga_id: "mirror",
                },
                {
                    name: <></>,
                    label: "home_exp_color_brown",
                    color: "/images/products/styler/essence/color_brown.png",
                    ga_id: "brown",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/styler/essence/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Essence/>,
        },
        {
            name: "Baron",
            modelNo: "baron",
            ga_id: "baron",
            isCES: true,
            thumb_url: "/images/products/styler/baron/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/styler/baron/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_mirror",
                    color: "/images/products/styler/baron/color_mirror.png",
                    ga_id: "mirror",
                },
            ],
            model: <Baron/>,
        },
    ],
};

export default productsData_Styler;
