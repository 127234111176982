/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {ObjetColorState, useObjetColorStore} from "../../../../stores/objetProductStore";
import {Objet_ColorCategoryType, tiiun_data} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {a} from "@react-spring/three";
import {animated, useSpring} from "@react-spring/web";
import {easeCubicInOut} from "d3-ease";
import {useModalTutorial} from "../../../../stores/modalStore";
import {TextureLoader} from "three";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import useHistoryStore from "../../../../stores/historyStore";

export type GLTFResult_ObjetModernProducts = GLTF & {
    nodes: {
        tiiun: THREE.Mesh
        harvester_glass: THREE.Mesh
        harvester_inner: THREE.Mesh
        dish_washer: THREE.Mesh
        Decal004: THREE.Mesh
        microwave_oven: THREE.Mesh
        Decal002: THREE.Mesh
        r9: THREE.Mesh
        Glass: THREE.Mesh
        Decal_merged: THREE.Mesh
        art: THREE.Mesh
        Glass001: THREE.Mesh
        Decal001: THREE.Mesh
        Body_merged001: THREE.Mesh
        air_conditioner_wall: THREE.Mesh
        UI: THREE.Mesh
        l_n_f: THREE.Mesh
        l_n_f_door_l: THREE.Mesh
        l_n_f_door_r: THREE.Mesh
        l_n_f_handle_metal: THREE.Mesh
        l_n_f_handle_plastic: THREE.Mesh
        air_conditioner_stand: THREE.Mesh
        UI001: THREE.Mesh
        Mesh1756: THREE.Mesh
        Mesh1756_1: THREE.Mesh
        Glass005: THREE.Mesh
        UI005: THREE.Mesh
        Door_Top005: THREE.Mesh
        Door_Bottom005: THREE.Mesh
        atom_u_sts: THREE.Mesh
        Iron001: THREE.Mesh
        Decal005: THREE.Mesh
        styler: THREE.Mesh
        Ui_display: THREE.Mesh
        Door: THREE.Mesh
        atom_4d_plus: THREE.Mesh
        BodySide001: THREE.Mesh
        Decal003: THREE.Mesh
        ['m-next3']: THREE.Mesh
        Glass016: THREE.Mesh
        Door_BL015: THREE.Mesh
        Door_BR015: THREE.Mesh
        Door_TL015: THREE.Mesh
        UI016: THREE.Mesh
        aero_tower: THREE.Mesh
        aero_360: THREE.Mesh
    }
    materials: {
        mat_harvester: THREE.MeshStandardMaterial
        mat_glass_dark: THREE.MeshStandardMaterial
        mat_harvester_inner: THREE.MeshStandardMaterial
        ['mat_body.003']: THREE.MeshStandardMaterial
        ['mat_decal.001']: THREE.MeshStandardMaterial
        mat_oven_body: THREE.MeshStandardMaterial
        mat_oven_decal: THREE.MeshStandardMaterial
        ['mat_body.002']: THREE.MeshStandardMaterial
        ['mat_glass.004']: THREE.MeshStandardMaterial
        mat_decal_merged: THREE.MeshStandardMaterial
        mat_body2_merged: THREE.MeshStandardMaterial
        ['Glass.002']: THREE.MeshStandardMaterial
        mat_ui_merged: THREE.MeshStandardMaterial
        ['mat_body_merged.001']: THREE.MeshStandardMaterial
        ['mat_artcool_body.001']: THREE.MeshStandardMaterial
        mat_artcool_ui: THREE.MeshStandardMaterial
        mat_lnf_body: THREE.MeshStandardMaterial
        mat_lnf_door_l: THREE.MeshStandardMaterial
        mat_lnf_door_r: THREE.MeshStandardMaterial
        mat_white_metal: THREE.MeshStandardMaterial
        mat_airconditioner_body: THREE.MeshStandardMaterial
        mat_airconditioner_ui: THREE.MeshStandardMaterial
        mat_washtower_body_top: THREE.MeshStandardMaterial
        mat_washtower_body_bottom: THREE.MeshStandardMaterial
        mat_washtower_glass: THREE.MeshStandardMaterial
        mat_washtower_ui: THREE.MeshStandardMaterial
        mat_washtower_door_top: THREE.MeshStandardMaterial
        mat_washtower_door_bottom: THREE.MeshStandardMaterial
        mat_atom_u_sts_body: THREE.MeshStandardMaterial
        mat_atom_u_sts_decal: THREE.MeshStandardMaterial
        mat_styler_body: THREE.MeshStandardMaterial
        mat_styler_ui: THREE.MeshStandardMaterial
        mat_styler_door: THREE.MeshStandardMaterial
        mat_artcool_body: THREE.MeshStandardMaterial
        mat_artcool_side: THREE.MeshStandardMaterial
        mat_artcool_decal: THREE.MeshStandardMaterial
        mat_mnext3_body: THREE.MeshStandardMaterial
        mat_mnext3_glass: THREE.MeshStandardMaterial
        mat_mnext3_door_bl: THREE.MeshStandardMaterial
        mat_mnext3_door_br: THREE.MeshStandardMaterial
        mat_mnext3_door_tl: THREE.MeshStandardMaterial
        mat_mnext3_ui: THREE.MeshStandardMaterial
        ['mat_body.005']: THREE.MeshStandardMaterial
        ['mat_body.006']: THREE.MeshStandardMaterial
    }
}


type GLTFResult = GLTF & {
    nodes: {
        tiiun: THREE.Mesh
        harvester_inner: THREE.Mesh
        harvester_glass: THREE.Mesh
    }
    materials: {
        ['mat_harvester.001']: THREE.MeshStandardMaterial
        mat_harvester_inner: THREE.MeshStandardMaterial
        mat_glass_dark: THREE.MeshStandardMaterial
    }
}


// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const tiiun_ColorCategorySelector = (state: ObjetColorState) => state.tiiun_ColorCategory;
const tiiun_ColorLabelSelector = (state: ObjetColorState) => state.tiiun_ColorLabel;


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_tiiun_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_tiiun_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Harvester_modern.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Harvester_modern.jpg');
const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/harvester/harvester_beige.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/harvester/harvester_beige.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/harvester/harvester_green.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/harvester/harvester_green.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL
    ]);

    const tiiun_ColorCategory = useObjetColorStore(tiiun_ColorCategorySelector);
    const tiiun_ColorLabel = useObjetColorStore(tiiun_ColorLabelSelector);
    const [isSet, setIsSet] = useState(false);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [spring, animate] = useSpring(() => ({from: {opacity: 0}}));
    const ref = useRef<THREE.Mesh>(null!);

    const changeTexture = (materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {
        // console.log(colorCategory, colorLabel);

        if (colorCategory === 'Nature') {
            lightmapTex.flipY = false;
            lightmapTex.encoding = THREE.sRGBEncoding;
            lightmapTex.needsUpdate = true;

            if (colorLabel === 'objet_color_beige') {
                if (!isFirst) setIsSet(true);
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                //@ts-ignore
                materials["mat_harvester.001"].lightMap = lightmapTex;
                materials['mat_harvester.001'].lightMapIntensity = 2;
                materials["mat_harvester.001"].map = colorTextures[0];
                materials["mat_harvester.001"].needsUpdate = true;

            } else {
                if (!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;

                materials["mat_harvester.001"].lightMap = lightmapTex;
                materials['mat_harvester.001'].lightMapIntensity = 2;
                materials["mat_harvester.001"].map = colorTextures[1];
                materials["mat_harvester.001"].needsUpdate = true;
            }
        }
    };

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials['mat_harvester.001'].lightMap = lightmapTex;
        materials['mat_harvester.001'].lightMapIntensity = 1;
        materials.mat_harvester_inner.lightMap = lightmapTex;
        materials.mat_harvester_inner.lightMapIntensity = 1;
        materials.mat_harvester_inner.emissiveIntensity = 1;
        materials.mat_glass_dark.color.setRGB(1, 1, 1);

    }, [lightmapTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);

            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }

            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/harvester/Lightmap_Harvester_modern.jpg');
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL
            ]);
        }
    }, []);

    useLayoutEffect(() => {
        changeTexture('mat_harvester.001', tiiun_ColorCategory, tiiun_ColorLabel);
    }, [tiiun_ColorCategory, tiiun_ColorLabel]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut},
                onRest: result => {
                    console.log(">>>>>", result);
                }
            });
        }
    }, [isOpenTutorial]);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                ref={ref}
                geometry={nodes.tiiun.geometry}
                material={materials['mat_harvester.001']}
                position={[4.73, 0.49, 1.7]}
                rotation={[0, Math.PI / 2, 0]}>
                <mesh
                    geometry={nodes.harvester_glass.geometry}
                    material={materials.mat_glass_dark}
                    position={[0.01, -0.04, 0.33]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.22, 0.22, 0.22]}
                    visible={isSet}
                />
                <mesh
                    geometry={nodes.harvester_inner.geometry}
                    material={materials.mat_harvester_inner}
                    position={[0.07, 0.03, 0.3]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.24, 0.24, 0.24]}
                />
            </mesh>

            <ObjetProductContainer3D
                args={[0.6, 0.8, 0.6]}
                position={[4.7314, 0.4, 1.6955]}
                rotation={[0, Math.PI / 2, 0]}
                data={tiiun_data}
                showDebug={false}
                cameraTargetPos={[-0.4, 0.8, 3]}
            />

            <mesh
                geometry={nodes.tiiun.geometry}
                position={[4.73, 0.49, 1.7]}
                rotation={[0, Math.PI / 2, 0]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={false} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0, 0]} scale={0.8} visible={!(isSet)} opacity={spring.opacity} color={pathname.indexOf('/tiiun') > -1 ? '#be1e42' : '#000'}/>

                <mesh
                    geometry={nodes.harvester_glass.geometry}
                    position={[0.01, -0.04, 0.33]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.22, 0.22, 0.22]}
                    visible={!isSet}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                </mesh>
                <mesh
                    geometry={nodes.harvester_inner.geometry}
                    position={[0.07, 0.03, 0.3]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.24, 0.24, 0.24]}
                    visible={!isSet}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                </mesh>
            </mesh>
        </group>
    )
}






