import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import useSpaceThemeStore, {
  LgHomeThemeType,
  SpaceThemeState,
} from "../../../stores/spaceThemeStore";
import useKeyPress from "../../../utils/useKeyPress";
import { randInt } from "three/src/math/MathUtils";
import {
  Center,
  Container,
  ListContainer,
} from "../../common/comps_styled/Container.styled";
import {
  InteriorBox,
  InteriorButton,
} from "../comps_styled/selectPanel/InteriorButton.styled";
import { Divider } from "../../common/comps_styled/Divider.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import theme from "../../../styles/theme.styled";
import useLangStore from "../../../stores/langStore";
import { getLangString, LangLabelType } from "../../../api/lang/langData";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import { Image } from "../../common/comps_styled/Image.styled";
import useRightPanelStore from "../../../stores/rightPanelStore";
import useResizeStore from "../../../stores/resizeStore";
import useProductStore from "../../../stores/productStore";
import { GA_Event } from "../../../api/ga";

/**
 * 인테리어 선택 UI
 * @constructor
 */

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const lgHomeThemeSelector = (state: SpaceThemeState) => state.lgHomeTheme;
const setLgHomeThemeSelector = (state: SpaceThemeState) =>
  state.api.setLgHomeTheme;

const SelectInterior = ({ ...rest }) => {
  const lgHomeTheme = useSpaceThemeStore(lgHomeThemeSelector); //현재 테마값
  const setLgHomeTheme = useSpaceThemeStore(setLgHomeThemeSelector); //테마 설정
  const lang = useLangStore((state) => state.lang);
  const resize = useResizeStore((state) => state.resize);

  // for GAEvent
  const [clickStatus, setClickStatus] = useState(false);
  const selectedProduct = useProductStore((state) => state.selectedProduct);
  const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);
  const ga_model = useProductStore((state) => state.selectedProduct?.ga_id);
  const ga_interior = useSpaceThemeStore((state) => state.lgHomeTheme);
  const ga_isNew = useProductStore((state) =>
    state.selectedProduct?.isCES ? "y" : "n"
  );
  const colorIndex = useProductStore((state) => state.selectedColorIndex);
  const ga_color = useMemo(
    () => selectedProduct?.colors[colorIndex].ga_id,
    [colorIndex, selectedProduct]
  );
  //
  useEffect(() => {
    GA_Event("lg_home", `${ga_product}_interior`, ga_interior, {
      dimension5: ga_product,
      dimension6: ga_interior,
      dimension7: ga_model,
      dimension8: ga_isNew,
      dimension9: ga_color,
    });
  }, [ga_interior, clickStatus]);
  //
  //

  useLayoutEffect(() => {
    console.log("선택된 인테리어 Theme: ", lgHomeTheme);
  }, [lgHomeTheme]);

  //space theme api 테스트용 코드 ---------------------[Start]
  //Z키 눌렀을 때 인테리어 테마 상태값 변경 시뮬레이션, 테마를 변경할 때 setLgHomeTheme을 호출하고
  //현재 테마값을 가져올 때 lgHomeTheme 사용
  const isPressZ = useKeyPress(["z", "Z"]);
  useEffect(() => {
    if (isPressZ) {
      const arr: LgHomeThemeType[] = [
        "warm",
        "vivid",
        "dark",
        "wood",
        "marble",
      ];
      const randomIdx = randInt(0, arr.length - 1);
      setLgHomeTheme(arr[randomIdx]);
    }
  }, [isPressZ]);
  //space theme api 테스트용 코드 ---------------------[End]

  const datas = [
    {
      label: "home_exp_interior_warm",
      imgsrc: "/images/etc/interior_buttons/btn_interior_01@2x.png",
      color: "black",
    },
    {
      label: "home_exp_interior_vivid",
      imgsrc: "/images/etc/interior_buttons/btn_interior_02@2x.png",
      color: "white",
    },
    {
      label: "home_exp_interior_dark",
      imgsrc: "/images/etc/interior_buttons/btn_interior_03@2x.png",
      color: "white",
    },
    {
      label: "home_exp_interior_wood",
      imgsrc: "/images/etc/interior_buttons/btn_interior_04@2x.png",
      color: "black",
    },
    {
      label: "home_exp_interior_marble",
      imgsrc: "/images/etc/interior_buttons/btn_interior_05@2x.png",
      color: "black",
    },
  ];

  return (
    <Container {...rest}>
      <Text
        size={`${(IS_MOBILE ? 3.5 : 2.8) * resize.ratio}em`}
        weight={theme.font.weight.semibold}
        style={{ paddingLeft: "2%" }}
      >
        {getLangString(lang, "home_exp_interior")?.text}
      </Text>
      <Divider
        borderwidth={2 * resize.ratio}
        style={{ marginTop: "2%", marginBottom: IS_MOBILE ? "2%" : "4.5%" }}
      />
      <ListContainer style={{ paddingLeft: "1.5%" }}>
        {datas.map((data, index) => {
          const { label, imgsrc, color } = data;
          const name = getLangString(lang, label as LangLabelType)?.text;

          return (
            <InteriorButton
              key={index}
              style={{
                width: "27%",
                marginRight: "4.3%",
                marginBottom: IS_MOBILE ? "1%" : "2%",
              }}
              selected={name?.toString().toLowerCase() === lgHomeTheme}
              onClick={() => {
                setLgHomeTheme(name as LgHomeThemeType);
                setClickStatus(!clickStatus);
              }}
            >
              {IS_MOBILE ? (
                <InteriorBox
                  ratio={resize.ratio}
                  className="box"
                  radius={`${0.5 * resize.ratio}em`}
                  style={{
                    marginBottom: "7%",
                    height: `${5.5 * resize.ratio}em`,
                    backgroundImage: `url(${ROOT_PATH_PREFIX + imgsrc})`,
                    backgroundSize: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    className="text"
                    color={color}
                    size={`${2.6 * resize.ratio}em`}
                  >
                    {name}
                  </Text>
                </InteriorBox>
              ) : (
                <>
                  <InteriorBox
                    ratio={resize.ratio}
                    className="box"
                    radius={`${0.5 * resize.ratio}em`}
                    style={{
                      marginBottom: "7%",
                      height: `${4 * resize.ratio}em`,
                      backgroundImage: `url(${ROOT_PATH_PREFIX + imgsrc})`,
                      backgroundSize: "100%",
                    }}
                  />
                  <Text className="text" size={`${1.6 * resize.ratio}em`}>
                    {name}
                  </Text>
                </>
              )}
            </InteriorButton>
          );
        })}
      </ListContainer>
    </Container>
  );
};

export default SelectInterior;
