import { animated, useSpring } from "@react-spring/web";
import { Billboard, Html } from "@react-three/drei";
import { easeCubicOut } from "d3-ease";
import React, { useEffect, useRef, useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useObjetInterior } from "../../../stores/modalStore";
import theme from "../../../styles/theme.styled";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import { Objet_MaterialsType } from "../../../api/objet/objetMaterialsData";
import HoverButton from "../../common/navigation/HoverButton";
import useResizeStore from "../../../stores/resizeStore";
import useObjetProductStore from "../../../stores/objetProductStore";
import { GA_Event } from "../../../api/ga";
import {
  useObjetFloorTextureStore,
  useObjetWallTextureStore,
} from "../../../stores/spaceThemeStore";

type Props = {
  position?: [x: number, y: number, z: number];
  data: Objet_MaterialsType;
  onSelectedCallback?: (selectedIdx: number) => void;
};

const ObjetRoomTexture = ({ position, data, onSelectedCallback }: Props) => {
  const [hoverIcon, setHoverIcon] = useState(false);
  const [show, setShow] = useState(false);
  const hidden = useObjetInterior((state) => state.isOpen);
  const resize = useResizeStore((state) => state.resize);
  const setWallMaterial = useObjetWallTextureStore(
    (state) => state.setMeterial
  );
  const setFloorMaterial = useObjetFloorTextureStore(
    (state) => state.setMeterial
  );

  useEffect(() => {
    if (hidden) setShow(false);
  }, [hidden]);

  const spring = useSpring({
    backgroundColor: show ? "rgba(255,255,255,1)" : "rgba(255,255,255,0)",
    width: show ? (IS_MOBILE ? 90 : 50) : IS_MOBILE ? 15 : 8,
    opacity: hidden ? 0 : 1,
    config: { duration: 230, easing: easeCubicOut },
  });

  const initHoverEffects = () => {
    setPress(-1);
  };

  const [selected, setSelected] = useState(0);
  const [press, setPress] = useState(0);
  const pressEffect = (index: number) => {
    setPress(index);
  };
  const selectEffect = (index: number) => {
    setShow(false);
    setSelected(index);
    if (onSelectedCallback) onSelectedCallback(index);
  };

  return (
    <Billboard position={position}>
      <Html>
        <ThemeProvider theme={theme}>
          <Wrapper
            ratio={resize.ratio}
            style={{
              pointerEvents: hidden ? "none" : "visible",
              opacity: spring.opacity.to((value) => value) as any,
              backgroundColor: spring.backgroundColor.to(
                (value) => value
              ) as any,
              width: spring.width.to(
                (value) => value * resize.ratio + "em"
              ) as any,
            }}
          >
            <HoverButton
              alt={
                show
                  ? `close ${data.part} meterials`
                  : `open ${data.part} meterials`
              }
              style={{
                flexShrink: 0,
                width: `${resize.ratio * (IS_MOBILE ? 10 : 5.15)}em`,
                height: `${resize.ratio * (IS_MOBILE ? 10 : 5.15)}em`,
              }}
              clicked={show}
              defaultSrc={
                ROOT_PATH_PREFIX + "/images/icons/svg/btn_texture_default.svg"
              }
              hoverSrc={
                ROOT_PATH_PREFIX + "/images/icons/svg/btn_texture_press.svg"
              }
              onClick={() => {
                if (show) {
                  GA_Event("lg_objet", data.part + "_material", "close");
                } else {
                  GA_Event("lg_objet", data.part + "_material", "open");
                }
                setShow(!show);
              }}
            />

            <Row
              style={{
                display: show ? "flex" : "none",
                padding: `0 ${resize.ratio * 1}em`,
              }}
            >
              {data?.materials.map((mat, index) => {
                return (
                  <HoverButton
                    alt={`${data.ga_id} ${mat.ga_id} on ${data.part}`}
                    key={index}
                    style={{
                      width: `${resize.ratio * 8.8 * (IS_MOBILE ? 1.8 : 1)}em`,
                      height: `${resize.ratio * 4.5 * (IS_MOBILE ? 1.8 : 1)}em`,
                      marginRight: `${resize.ratio * (IS_MOBILE ? 1.8 : 1)}em`,
                    }}
                    clicked={selected == index}
                    defaultSrc={ROOT_PATH_PREFIX + mat.default_url}
                    hoverSrc={ROOT_PATH_PREFIX + mat.hover_url}
                    onClick={() => {
                      if (data.part === "wall") {
                        setWallMaterial(data.ga_id + "_" + mat.ga_id);
                      } else {
                        setFloorMaterial(data.ga_id + "_" + mat.ga_id);
                      }
                      // GA_Event(
                      //   "lg_objet",
                      //   data.part + "_material",
                      //   data.ga_id + "_" + mat.ga_id
                      // );
                      selectEffect(index);
                    }}
                  />
                );
              })}
            </Row>
          </Wrapper>
        </ThemeProvider>
      </Html>
    </Billboard>
  );
};

const Wrapper = styled(animated.div)<{ ratio: number } & Record<string, any>>`
  display: flex;
  align-items: center;
  border-radius: ${({ ratio }) => ratio * (IS_MOBILE ? 7 : 3.8)}em;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 14 : 7.6)}em;
  padding: ${({ ratio }) => ratio * 0.6}em;
  background-color: white;
  /* border: 1px solid tomato; */
`;

const Row = styled.div<Record<string, any>>`
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;

  /* border: 1px solid tomato; */
`;

export default ObjetRoomTexture;
