import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Box, Preload, Plane} from "@react-three/drei";
import ProductContainer3D from "../../common/ProductContainer3D";
import * as THREE from "three";
import {MathUtils, SpotLightHelper} from "three";
import {getProductsData} from "../../../api/products/productsData";
import Obstacle from '../../common/camera/Obstacle';
import {IS_MOBILE, Layer, ROOT_PATH_PREFIX} from '../../common/const';
import useCameraControls from "../../../utils/useCameraControls";
import useCameraStore, {CameraState} from "../../../stores/cameraStore";
import useProductStore, {ProductState} from "../../../stores/productStore";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";
import {TextureLoader} from "three";
import {useLoader} from '@react-three/fiber';
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import KitchenRoomModelVivid from "./kitchen/KitchenRoomModelVivid";
import KitchenRoomModelDark from "./kitchen/KitchenRoomModelDark";
import KitchenRoomModelWarm from "./kitchen/KitchenRoomModelWarm";
import KitchenRoomModelMarble from "./kitchen/KitchenRoomModelMarble";
import KitchenRoomModelWood from "./kitchen/KitchenRoomModelWood";


// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const cameraApiSelector = (state: CameraState) => state.api;
const selectedCategorySelector = (state: ProductState) => state.selectedCategory;
const selectedRoomSelector = (state: ProductState) => state.selectedRoom

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_DIFFUSE_m.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_DIFFUSE.exr');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_AO_m.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_Environment_01.exr';


const Kitchen = React.memo(() => {
    const productData_refrigerator = useMemo(() => getProductsData("refrigerator"), []);
    const productData_dishwasher = useMemo(() => getProductsData("dishwasher"), []);
    const productData_cooking = useMemo(() => getProductsData('cooking'), []);
    const productData_microwave_oven = useMemo(() => getProductsData('microwave_oven'), []);
    const productData_homebrew = useMemo(() => getProductsData('homebrew'), []);
    const productData_water_purifier = useMemo(() => getProductsData('water_purifier'), []);

    const {setTargetPos, setLookPos, setTouchStart} = useCameraStore(cameraApiSelector);

    const refLightTargetRef = useRef<THREE.Object3D>(null!);
    const refLightRef = useRef<THREE.SpotLight>(null!);
    const dwLightTargetRef = useRef<THREE.Object3D>(null!);
    const dwLightRef = useRef<THREE.SpotLight>(null!);
    const ovenLightTargetRef = useRef<THREE.Object3D>(null!);
    const ovenLightRef = useRef<THREE.SpotLight>(null!);

    // useHelper(ovenLightRef, SpotLightHelper, "red");

    const selectedCategory = useProductStore(selectedCategorySelector);
    const selectedProduct = useProductStore(state => state.selectedProduct);
    const selectedRoom = useProductStore(selectedRoomSelector);
    const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();
    const lgHomeTheme = useSpaceThemeStore(state => state.lgHomeTheme);


    useEffect(() => {
        cameraControls.setLookAt(0, 1, 0, 0, 1, -1, false);
        if (refLightRef.current) {
            refLightRef.current.target = refLightTargetRef.current;
        }
        if (dwLightRef.current) {
            dwLightRef.current.target = dwLightTargetRef.current;
        }
        if (ovenLightRef.current) {
            ovenLightRef.current.target = ovenLightTargetRef.current;
        }
        return () => {
            console.log("키친공간 해제");
            //@ts-ignore
            useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, AO_URL)
            //@ts-ignore
            useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);

    useLayoutEffect(() => {
        if (selectedRoom === 'kitchen') {
            if (selectedCategory) {

                cameraControls.minAzimuthAngle = -20 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxAzimuthAngle = 20 * THREE.MathUtils.DEG2RAD;
                cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;

                if (selectedCategory.category === "microwave_oven") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.minPolarAngle = 80 * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxPolarAngle = 110 * THREE.MathUtils.DEG2RAD;
                } else if (selectedCategory.category === "cooking") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
                } else if (selectedCategory.category === "homebrew") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
                }
            } else {
                cameraControls.minAzimuthAngle = -Infinity;
                cameraControls.maxAzimuthAngle = Infinity;
                cameraControls.minPolarAngle = -Infinity;
                cameraControls.maxPolarAngle = Infinity;
            }
        }
    }, [selectedCategory, selectedRoom]);

    return (
        <group>
            <React.Suspense fallback={null}>
                <>
                    {lgHomeTheme === 'vivid' && (<KitchenRoomModelVivid/>)}
                    {lgHomeTheme === 'warm' && <KitchenRoomModelWarm/>}
                    {lgHomeTheme === 'dark' && <KitchenRoomModelDark/>}
                    {lgHomeTheme === 'wood' && <KitchenRoomModelWood/>}
                    {lgHomeTheme === 'marble' && <KitchenRoomModelMarble/>}
                </>
                <Preload all/>
            </React.Suspense>

            {/* 이동 제한을 위한 콜라이더 설치 */}
            <>
                <Obstacle size={[10, 4, 2]} position={[0, 2, -3.8]} color={"red"} type={"Static"} visible={false}
                          collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
                <Obstacle size={[10, 4, 2]} position={[0, 2, 3]} color={"red"} type={"Static"} visible={false}
                          collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
                <Obstacle size={[2, 4, 10]} position={[4.3, 2, 0]} color={"red"} type={"Static"} visible={false}
                          collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
                <Obstacle size={[2, 4, 10]} position={[-4.3, 2, 0]} color={"red"} type={"Static"} visible={false}
                          collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            </>

            {/* 바닥 히트처리 */}
            <Plane args={[6.7, 5]} position={[0, 0.1, 0.3]} rotation={[-Math.PI / 2, 0, 0]} receiveShadow
                   onClick={(e) => {
                       // console.log(e);
                       e.stopPropagation();
                       if (e.delta < 10) {
                           setLookPos(null);
                           setTargetPos(e.point);
                       }
                   }}>
                <meshBasicMaterial color={"#ff3"} transparent={true} opacity={0}/>
            </Plane>

            {/*제품 3D 컨테이너*/}
            <>
                <ProductContainer3D
                    args={[0.9, 1.7, 0.6]}
                    position={[1.42, 0.85, -2.95]}
                    // rotation={[0, -MathUtils.DEG2RAD * 30, 0]}
                    category={'refrigerator'}
                    data={productData_refrigerator}
                    // matchingModelNo={['vs6', 'ref_06', 'ref_01', 'vs4', 'p_next3_voice']}
                    uiOffset={[0, 0.07, 0]}
                    uiInfoOffset={[-0.8, 0, 0]} // 211222
                    cameraTargetPos={[0, 0, 3]}
                    showDebug={false}
                >
                    <Box args={[0.1, 0.1, 0.1]} position={[0, 0, 0]} ref={refLightTargetRef} visible={false}>
                        <meshBasicMaterial color={"#f00"}/>
                    </Box>
                    <spotLight position={[0, 1.5, 2]} ref={refLightRef} angle={0.5} penumbra={1} intensity={0.5}/>
                </ProductContainer3D>


                <ProductContainer3D
                    args={[0.58, 0.8, 0.5]}
                    position={[-1.79, 0.4, -3.1]}
                    category={"dishwasher"}
                    data={productData_dishwasher}
                    uiOffset={[0, 0.01, 0]}
                    uiScale={0.7}
                    showDebug={false}
                    cameraTargetPos={[0, 0, 2]}
                    uiInfoOffset={[-0.8, 0, 0]} // 211222
                >
                    <Box args={[0.1, 0.1, 0.1]} position={[0, 0, 0]} ref={dwLightTargetRef} visible={false}>
                        <meshBasicMaterial color={"#f00"}/>
                    </Box>
                    <spotLight position={[0, 2, 3]} ref={dwLightRef} angle={0.5} penumbra={1} intensity={0.3}/>
                </ProductContainer3D>

                <ProductContainer3D
                    args={[0.3, 0.4, 0.5]}
                    position={[-1, 1.04, -3.1]}
                    category={"water_purifier"}
                    // matchingModelNo={['atom_4d', 'atom_u']}
                    data={productData_water_purifier}
                    uiOffset={[0.1, 0.02, 0]}
                    uiInfoOffset={
                        IS_MOBILE ? [-0.6, -0.1, 0] : [-0.6, 0, 0]
                    }
                    uiScale={0.6}
                    cameraTargetPos={[0, 0, 2]}
                    showDebug={false}
                >
                </ProductContainer3D>

                <ProductContainer3D
                    args={[0.7, 1, 0.5]}
                    position={[-4.5, 0.5, -1.435]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={"cooking"}
                    data={productData_cooking}
                    uiOffset={[0, -0.135, 0]}
                    specboardOffset={
                        (selectedProduct?.modelNo === 'lupin_gas_single' ||
                            selectedProduct?.modelNo === 'lupin_elec_single') ?
                        [-0.15, 0, 0] :[-0.15, 0.15, 0]
                    }
                    showDebug={false}
                    uiScale={0.8}
                    uiInfoOffset={
                        IS_MOBILE ? [0, -0.2, 0.6] : [0, 0, 0.6]
                    } // 211222
                    cameraTargetPos={
                        IS_MOBILE ? [0, 0, 2] : [0, 0, 2.5]
                    }
                >
                    <Box args={[0.1, 0.1, 0.1]} position={[0, 1, 0]} ref={ovenLightTargetRef} visible={false}>
                        <meshBasicMaterial color={"#f00"}/>
                    </Box>
                    <spotLight position={[0, 2, 2]} ref={ovenLightRef} angle={0.7} penumbra={1} intensity={0.5}/>
                </ProductContainer3D>

                <ProductContainer3D
                    args={[0.7, 0.5, 0.5]}
                    position={[-4.65, 2.04, -1.435]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={"microwave_oven"}
                    // matchingModelNo={['convection', 'scallet2_good', 'utopia', 'scallet2_best', 'scallet2_better']}
                    data={productData_microwave_oven}
                    showDebug={false}
                    uiScale={0.6}
                    uiOffset={[0, -0.05, 0]}
                    cameraTargetPos={
                        IS_MOBILE ? [0, 0, 1.4] : [0, 0, 2]
                    }
                    uiInfoOffset={
                        IS_MOBILE ? [0, -0.1, 0.45] : [0, 0, 0.6]
                    } // 211222
                >
                </ProductContainer3D>

                <ProductContainer3D
                    args={[0.7, 0.5, 0.5]}
                    position={[-4.65, 1.1, -0.6]}
                    rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                    category={"homebrew"}
                    data={productData_homebrew}
                    // uiOffset={[0.15, -0.025, 0]}
                    uiOffset={[0, -0.01, 0]}
                    showDebug={false}
                    uiScale={0.8}
                    cameraTargetPos={[0, 0, 2]}
                    uiInfoOffset={[0, 0, 0.6]} // 211222
                >
                </ProductContainer3D>
            </>
        </group>
    );
});

export default Kitchen;
