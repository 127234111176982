import { animated } from "@react-spring/web";
import styled from "styled-components";
import { IS_MOBILE } from "../const";

export const Container = styled.div<{ isPanel?: boolean }>`
  display: flex;
  flex-direction: column;
  /* flex: auto; */
  width: 100%;
  /* border: 1px solid tomato; */

  /* padding: ${({ isPanel }) =>
    isPanel ? (IS_MOBILE ? "1.5% 3% 0 3%" : "8.4% 4% 0 4%") : 0}; */
  /* border: 1px solid red; */
`;

export const ListContainer = styled.span`
  display: flex;
  flex-wrap: wrap;
  /* align-content: flex-start; */
  /* border: 1px solid green; */
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid; */
`;

export const ContainerRow = styled.div<{}>`
  display: flex;
  flex-direction: row;
  flex: auto;
  width: 100%;
`;

export const ContainerAnimated = styled(animated.div)`
  display: flex;
`;
