import 'core-js/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'regenerator-runtime/runtime';
import '@ungap/global-this';


import React, {useMemo} from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from "react-router-dom";
import {IS_IPHONE} from "./components/common/const";
import WarningIe from "./components/common/WarningIE";


if (IS_IPHONE) {
    //@ts-ignore
    window.createImageBitmap = undefined;
}

//@ts-ignore
console.log(window.dataLayer);


const AppRoot = () => {
    const isIEBrowser = useMemo(() => {
        const agent = navigator.userAgent.toLowerCase();
        return (navigator.appName === 'Netscape' && agent.indexOf('trident') !== -1) || (agent.indexOf("msie") !== -1);
    }, []);

    return (
        <>
            {isIEBrowser ? <WarningIe/> : <App/>}
        </>
    );
}


ReactDOM.render(
    <React.StrictMode>
        <Router>
            <AppRoot/>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
