/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {air_care_data, Objet_ColorCategoryType} from "../../../../api/products/objetProductsData";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {LangLabelType} from "../../../../api/lang/langData";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {animated, useSpring} from "@react-spring/web";
import {useModalTutorial} from "../../../../stores/modalStore";
import {easeCubicInOut} from "d3-ease";
import {a} from '@react-spring/three';
import {GLTFResult_ObjetNaturalProducts} from "./Product_Harvester";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import useHistoryStore from "../../../../stores/historyStore";
import ObjetPlusButton from "../../ObjetPlusButton";


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Aero360.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Aero360.jpg');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/aero_360/green_Bake1_pbr_diffuse.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/aero_360/green_Bake1_pbr_diffuse.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/aero_360/red_Bake1_pbr_diffuse.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/aero_360/red_Bake1_pbr_diffuse.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([COLOR_0_URL, COLOR_1_URL]);

    const colorCategory = useObjetColorStore(state => state.air_care_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.air_care_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [COLOR_0_URL, COLOR_1_URL]);
        }
    }, []);

    const pathname = useHistoryStore(state => state.pathname);
    const isActiveLnF = useMemo(() => pathname.indexOf('lansen_freezer') > -1, [pathname]);

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsSet(true);

        if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_dark_green') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_redwood') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            }
        }
    }, [colorTextures, materials]);

    useLayoutEffect(() => {
        changeTexture('mat_body.006', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials["mat_body.006"].lightMap = lightmapTex;
        materials["mat_body.006"].lightMapIntensity = 2;
        materials["mat_body.006"].envMapIntensity = 2;

        colorTextures[0].flipY = false;
        colorTextures[0].encoding = THREE.sRGBEncoding;
        colorTextures[0].needsUpdate = true;
        materials[`mat_body.006`].map = colorTextures[0];

        changeTexture("mat_body.006", 'Nature', 'objet_color_dark_green', true);

    }, [lightmapTex]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);


    return (
        <group ref={group} {...props} visible={!isActiveLnF} dispose={null}>
            <mesh
                geometry={nodes.aero_360.geometry}
                material={materials['mat_body.006']}
                position={[1.0843, 0.305, -1.0346]}
                rotation={[0, -0.324, 0]}
            />

            {/* +버튼 */}
            <mesh
                geometry={nodes.aero_360.geometry}
                material={materials['mat_body.006']}
                position={[1.0843, 0.305, -1.0346]}
                rotation={[0, -0.324, 0]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.7, 0]} visible={!(isSet || isActiveLnF)} opacity={spring.opacity}
                                 color={(pathname.indexOf('/air_care_aero') < 0 && pathname.indexOf('/air_care') > -1) ? '#be1e42' : '#000'}/>
            </mesh>

            <ObjetProductContainer3D
                args={[0.3, 1.2, 0.3]}
                position={[1.07, 0.9, -0.76]}
                data={air_care_data}
                showDebug={false}
                cameraTargetPos={[-0.3, 0.3, 3]}
            />
        </group>
    )
}
