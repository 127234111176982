/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/**
 * MM Lupin2 Elec. S/in Single Good
 * */
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {useKTXLoader} from "../../utils/useKTXLoader";
import { disposeScene } from '../../utils/disposeScene';
import { useLoader } from '@react-three/fiber';

type GLTFResult = GLTF & {
  nodes: {
    Plane003: THREE.Mesh
    Plane002: THREE.Mesh
    Lupin_Elec_Single: THREE.Mesh
    Drawer: THREE.Mesh
    Door001002: THREE.Mesh
    Induction001: THREE.Mesh
    UI001: THREE.Mesh
  }
  materials: {
    MetalBlack: THREE.MeshStandardMaterial
    MetalSilver: THREE.MeshStandardMaterial
    ['Oven_05_Albedo.1001(top)']: THREE.MeshStandardMaterial
    Door_Glass: THREE.MeshStandardMaterial
    induction: THREE.MeshStandardMaterial
    UI: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/oven/m/lupin_elec_single_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/oven/lupin_elec_single_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  //@ts-ignore
  const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult
  // const {
  //   nodes,
  //   materials,
  //   scene
  // } = useKTXLoader<GLTFResult_Oven>(ROOT_PATH_PREFIX + '/models/products/oven/products_oven_ktx2.glb');

  const mesh1_Ref = useRef<THREE.Mesh>(null!);
  const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
  const selectedProduct = useProductStore(state => state.selectedProduct);

  useEffect(()=>{
    if(selectedProduct?.modelNo === 'lupin_elec_single'){
      materials.MetalBlack.color.setRGB(0.15, 0.15, 0.15);
      if(selectedColorIndex === 1){
        mesh1_Ref.current.material = materials.MetalSilver;
      }
      else{
        mesh1_Ref.current.material = materials.MetalBlack;
      }
      // mesh1_Ref.current.material.needsUpdate = true;
    }
  }, [selectedProduct, selectedColorIndex]);

  useEffect(() => {
    return () => {
      disposeScene(scene);
      useLoader.clear(GLTFLoader, GLTF_URL);
    }
  }, []);

  return (
      <group ref={group} {...props} position={[-0.003, -0.06, 0.32]} scale={[1.002,1,1]}>
        <mesh
            geometry={nodes.Plane003.geometry}
            material={materials.MetalBlack}
            userData={{ name: 'Plane.003' }}
        />
        <mesh
            geometry={nodes.Plane002.geometry}
            material={nodes.Plane002.material}
            userData={{ name: 'Plane.002' }}
        />
        <mesh
            geometry={nodes.Lupin_Elec_Single.geometry}
            material={materials['Oven_05_Albedo.1001(top)']}
            position={[0, 0.9333, -0.6788]}
            userData={{ name: 'Lupin_Elec_Single' }}

        >

          <mesh
              geometry={nodes.Drawer.geometry}
              material={nodes.Drawer.material}
              position={[0.0052, -0.9333, 0.6788]}
              userData={{ name: 'Drawer' }}
              ref={mesh1_Ref}
          />
          <mesh
              geometry={nodes.Door001002.geometry}
              material={materials.Door_Glass}
              position={[0.0052, -0.9333, 0.6788]}
              userData={{ name: 'Door001.002' }}

          />
          <mesh
              geometry={nodes.Induction001.geometry}
              material={materials.induction}
              position={[0.0052, -0.9333, 0.6788]}
              userData={{ name: 'Induction.001' }}

          />
          <mesh
              geometry={nodes.UI001.geometry}
              material={materials.UI}
              position={[0.0055, -0.902, 0.6836]}
              userData={{ name: 'UI.001' }}
          />
        </mesh>
      </group>
  )
}
