/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Glass: THREE.Mesh
    }
    materials: {
        mat_glass: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/natural_glass.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const {nodes, materials, scene} = useGLTF(GLTF_URL) as GLTFResult

    useLayoutEffect(() => {
        materials.mat_glass.color.setRGB(1, 1, 1);
        materials.mat_glass.roughness = 0;
        materials.mat_glass.metalness = 1;
        materials.mat_glass.transparent = true;
        materials.mat_glass.opacity = 0.1;
        materials.mat_glass.envMapIntensity = 5;
    }, []);

    useEffect(() => {
        return () => {
            disposeScene(scene);
        }
    }, []);
    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.Glass.geometry}
                material={materials.mat_glass}
                userData={{name: 'Glass'}}
            />
        </group>
    )
}
