/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/**
 * MM Lupin2 Gas S/in Single Good
 * */
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {useKTXLoader} from "../../utils/useKTXLoader";
import { useLoader } from '@react-three/fiber';
import { disposeScene } from '../../utils/disposeScene';


type GLTFResult = GLTF & {
    nodes: {
        Lupin_Gas_Single: THREE.Mesh
        Body004: THREE.Mesh
        Body003: THREE.Mesh
        Body006: THREE.Mesh
        Door001001: THREE.Mesh
        Decal004: THREE.Mesh
        Plane003: THREE.Mesh
        Plane002: THREE.Mesh
    }
    materials: {
        ['mat_black.001']: THREE.MeshStandardMaterial
        MetalBlack: THREE.MeshStandardMaterial
        MetalSilver: THREE.MeshStandardMaterial
        ['18_Body.001']: THREE.MeshStandardMaterial
        ['Glass.006']: THREE.MeshStandardMaterial
        ['body.006']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/oven/m/lupin_gas_single_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/oven/lupin_gas_single_ktx2.glb');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL);

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'lupin_gas_single') {
            materials.MetalBlack.color.setRGB(0.15, 0.15, 0.15);
            if (selectedColorIndex === 1) {
                mesh1_Ref.current.material = materials.MetalSilver;
            } else {
                mesh1_Ref.current.material = materials.MetalBlack;
            }
            // mesh1_Ref.current.material.needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} position={[0.012, -0.06, 0.32]}>
            <mesh
                geometry={nodes.Lupin_Gas_Single.geometry}
                material={materials['mat_black.001']}
                position={[0, 0.9263, -0.3846]}>

                <mesh
                    geometry={nodes.Body004.geometry}
                    material={nodes.Body004.material}
                    position={[-0.0097, -0.9263, 0.3846]}
                />
                <mesh
                    geometry={nodes.Body003.geometry}
                    material={nodes.Body003.material}
                    position={[-0.0088, -0.0704, 0.3043]}
                    scale={[0.01, 0.0103, 0.01]}
                    ref={mesh1_Ref}
                />
                <mesh
                    geometry={nodes.Body006.geometry}
                    material={materials['18_Body.001']}
                    position={[-0.0097, -0.9263, 0.3846]}
                />
                <mesh
                    geometry={nodes.Door001001.geometry}
                    material={materials['Glass.006']}
                    position={[-0.0097, -0.9263, 0.3846]}
                />
                <mesh
                    geometry={nodes.Decal004.geometry}
                    material={materials['body.006']}
                    position={[-0.0097, -0.9263, 0.3846]}
                />
            </mesh>
            <mesh geometry={nodes.Plane003.geometry} material={nodes.Plane003.material}/>
            <mesh geometry={nodes.Plane002.geometry} material={nodes.Plane002.material}/>
        </group>
    )
}
