/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        UI1: THREE.Mesh
        Inside_Board01: THREE.Mesh
        Object001: THREE.Mesh
        Sliver_Material: THREE.Mesh
        Arch137_Sink: THREE.Mesh
        water_tube: THREE.Mesh
        Black_Material02: THREE.Mesh
    }
    materials: {
        UI002: THREE.MeshStandardMaterial
        box: THREE.MeshStandardMaterial
        ['02 - Default']: THREE.MeshStandardMaterial
        ['body.002']: THREE.MeshStandardMaterial
        Sink: THREE.MeshStandardMaterial
        Water: THREE.MeshStandardMaterial
        ['Top.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/water_purifier/m/wat_02_ktx2.glb') : // 차이가 크지 않아서 같은걸 사용
    (ROOT_PATH_PREFIX + '/RP/products/water_purifier/m/wat_02_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    // const {nodes, materials, scene} = useGLTF(ROOT_PATH_PREFIX + '/models/wat/wat_02/wat_02.gltf') as GLTFResult
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult

    useLayoutEffect(()=>{
        materials['body.002'].roughness = 0;
        materials['body.002'].needsUpdate = true;
        materials['body.002'].metalness = 0.6;

    }, []);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            // useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/models/wat/wat_02/wat_02.gltf');
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} position={[0.12, -0.80, 0]} rotation={[0, 0.6, 0]} dispose={null}>
            <mesh
                geometry={nodes.UI1.geometry}
                material={materials.UI002}
                position={[0.0508, 0.8136, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{name: 'UI1'}}
            />

            <mesh
                geometry={nodes.Sliver_Material.geometry}
                material={materials['body.002']}
                position={[0.0508, 0.8136, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{name: 'Sliver_Material'}}
            />
            <mesh
                geometry={nodes.water_tube.geometry}
                material={materials.Water}
                position={[0.3265, 0.8136, -0.047]}
                scale={[0.01, 0.01, 0.01]}
                userData={{name: 'water_tube'}}
            />
            <mesh
                geometry={nodes.Black_Material02.geometry}
                material={materials['Top.001']}
                position={[0.4135, 0.8823, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{name: 'Black_Material02'}}
            />
        </group>
    )
}

