import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_WaterPurifier: UspPageDataType[] = [
  {
    category: "water_purifier",
    modelNo: "atom_4d",
    bgImgUrl: "/images/usp/water_purifier/atom_4d.jpg",
    uspList: [
      {
        label: "atom_4d_usp_0",
        title: [
          <>
            Without tank,
            <br />
            you can drink healthy water.
          </>,
        ],
        subMsg: [<>Tankless & 4-stage Filtration System</>],
        videoUrl: "https://vimeo.com/658876542/1c0d9e65c4",
        x: 288,
        y: 368,
        ga_id: "tankless&4_stage_filtration_system",
      },
      {
        label: "atom_4d_usp_0",
        title: [
          <>
            Got a sense! Where you
            <br />
            want, as much as you want
            <br />
            up to sterilization
          </>,
        ],
        subMsg: [<>Sterilization system & Stainless steel water pipe</>],
        videoUrl: "https://vimeo.com/658876564/e5a7ce463a",
        x: 288,
        y: 518,
        ga_id: "sterilization_system&stainless_steel_water_pipe",
      },
      {
        isRight: true,
        label: "atom_4d_usp_0",
        title: [
          <>
            Got a sense! Where you
            <br />
            want, as much as you want!
          </>,
        ],
        subMsg: [<>4-direction Moving Tap / Auto Cup Sensing</>],
        videoUrl: "https://vimeo.com/658876505/8ec33b3c55",
        x: 1129,
        y: 559,
        ga_id: "4-direction_moving_tap/auto_cup_sensing",
      },
    ],
  },
  {
    category: "water_purifier",
    modelNo: "atom_u",
    bgImgUrl: "/images/usp/water_purifier/atom_u.jpg",
    uspList: [
      {
        label: "atom_u_usp_0",
        title: [
          <>
            Swing the tap,
            <br />
            Enjoy the movement.
          </>,
        ],
        subMsg: [<>180° Dual Swing Faucet</>],
        videoUrl: "https://vimeo.com/658876798/f647817d8c",
        x: 213,
        y: 368,
        ga_id: "180°dual_swing_faucet",
      },
      {
        label: "atom_u_usp_0",
        title: [
          <>
            Got a sense! Where you
            <br />
            want, as much as you want
            <br />
            up to sterilization
          </>,
        ],
        subMsg: [<>Sterilization system & Stainless steel water pipe</>],
        videoUrl: "https://vimeo.com/658876886/ef97013bba",
        x: 213,
        y: 518,
        ga_id: "sterilization_system&stainless_steel_water_pipe",
      },
      {
        isRight: true,
        label: "atom_u_usp_0",
        title: [
          <>
            How Clean is your
            <br />
            drinking water?
          </>,
        ],
        subMsg: [<>Uvnano</>],
        videoUrl: "https://vimeo.com/661061467/a08305eb82",
        x: 1163,
        y: 456,
        ga_id: "uvnano",
      },
      {
        isRight: true,
        label: "atom_u_usp_0",
        title: [<>Need space? Why? Put it in.</>],
        subMsg: [<>Space Saving & Built-in Type</>],
        videoUrl: "https://vimeo.com/658876849/f454ecc8d9",
        x: 1163,
        y: 606,
        ga_id: "space_saving&built_in_type",
      },
    ],
  },
];

export default uspData_WaterPurifier;
