/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {useGLTF} from "@react-three/drei";


type GLTFResult = GLTF & {
    nodes: {
        Plane: THREE.Mesh
        scallet2_best: THREE.Mesh
        ['18_Body001']: THREE.Mesh
        ['18_Glass']: THREE.Mesh
        Decal001: THREE.Mesh
        Plane001: THREE.Mesh
    }
    materials: {
        ['MetalSilver.001']: THREE.MeshStandardMaterial
        ['18_Body.001']: THREE.MeshStandardMaterial
        ['18_Glass.001']: THREE.MeshStandardMaterial
        ['18_Metal.001']: THREE.MeshStandardMaterial
        ['MetalBlack.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/microwave/m/scallet2_best_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/microwave/scallet2_best_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/microwave/scallet2_best.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    // const {nodes, materials, scene} = useGLTF<GLTFResult>(GLTF_URL) as GLTFResult;
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct && selectedProduct?.modelNo === 'scallet2_best'){
            if(selectedColorIndex === 1){
                mesh1_Ref.current.material = materials['MetalSilver.001'];
            }
            else{
                mesh1_Ref.current.material = materials['MetalBlack.001'];
            }
            mesh1_Ref.current.material.needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        mesh1_Ref.current.material = materials['MetalBlack.001'];

        materials['18_Metal.001'].metalnessMap = materials['18_Metal.001'].map;
        materials['18_Metal.001'].emissiveIntensity = 3;
        materials['18_Metal.001'].roughness = 0.5;
        materials['18_Metal.001'].metalness = 0;
        materials['18_Metal.001'].needsUpdate = true;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0.008, -0.012, 0.27]} scale={[1.01,1,1]}>
            <mesh
                geometry={nodes.Plane.geometry}
                material={nodes.Plane.material}
                userData={{ name: 'Plane' }}
            />
            <mesh
                geometry={nodes.scallet2_best.geometry}
                material={materials['18_Body.001']}
                userData={{ name: 'scallet2_best' }}>
                <mesh
                    ref={mesh1_Ref}
                    geometry={nodes['18_Body001'].geometry}
                    material={nodes['18_Body001'].material}
                    userData={{ name: '18_Body.001' }}
                />
                <mesh
                    geometry={nodes['18_Glass'].geometry}
                    material={materials['18_Glass.001']}
                    userData={{ name: '18_Glass' }}
                />
                <mesh
                    geometry={nodes.Decal001.geometry}
                    material={materials['18_Metal.001']}
                    position={[-0.0172, 0, 0.0022]}
                    userData={{ name: 'Decal.001' }}
                />
            </mesh>
            <mesh
                geometry={nodes.Plane001.geometry}
                material={materials['MetalBlack.001']}
                userData={{ name: 'Plane.001' }}
            />

            <mesh position={[-0.005, 0.47, -0.215]}>
                <boxBufferGeometry args={[0.76, 0.1, 0.3]} />
                <meshStandardMaterial color={"#111"} />
            </mesh>
        </group>
    )
}
