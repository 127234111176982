import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import productsData, {
    getProductsData,
} from "../../../api/products/productsData";
import ProductButton from "./ProductButton";
import {Image} from "../../common/comps_styled/Image.styled";
import {ROOT_PATH_PREFIX} from "../../common/const";
import {getLangString} from "../../../api/lang/langData";
import useLangStore from "../../../stores/langStore";
import {Text} from "../../common/comps_styled/Text.styled";
import useResizeStore from "../../../stores/resizeStore";

/**
 * LG Home의 메인화면 (렌더링된 이미지로 구성)
 * @constructor
 */

const bgRatio = 1920 / 1080;

const LG_HomeMain = React.memo(() => {
    const titleRef = useRef<HTMLDivElement>(null!);
    const refrigeratorRef = useRef<HTMLDivElement>(null!);
    const tiiunRef = useRef<HTMLDivElement>(null!);
    const cookingRef = useRef<HTMLDivElement>(null!);
    const microwaveOvenRef = useRef<HTMLDivElement>(null!);
    const dishWasherRef = useRef<HTMLDivElement>(null!);
    const homebrewRef = useRef<HTMLDivElement>(null!);
    const waterPurifierRef = useRef<HTMLDivElement>(null!);
    const washernDryerRef = useRef<HTMLDivElement>(null!);
    const stylerRef = useRef<HTMLDivElement>(null!);
    const vacuumCleanerRef = useRef<HTMLDivElement>(null!);
    const airCareRef = useRef<HTMLDivElement>(null!);
    const wearableAirPurifierRef = useRef<HTMLDivElement>(null!);
    const airConditionerRef = useRef<HTMLDivElement>(null!);

    //버튼 레이아웃 업데이트
    function updateLayout(
        sizeRatio: number,
        topMargin: number,
        leftMargin: number,
        sizeW: number,
        sizeH: number
    ) {
        titleRef.current.style.left = `${leftMargin + 610 * sizeRatio}px`;
        titleRef.current.style.top = `${topMargin + 10 * sizeRatio}px`;
        titleRef.current.style.width = `${700 * sizeRatio}px`;
        titleRef.current.style.height = `${121 * sizeRatio}px`;

        refrigeratorRef.current.style.left = `${leftMargin + 770 * sizeRatio}px`;
        refrigeratorRef.current.style.top = `${topMargin + 421 * sizeRatio}px`;
        refrigeratorRef.current.style.width = `${136 * sizeRatio}px`;
        refrigeratorRef.current.style.height = `${290 * sizeRatio}px`;

        tiiunRef.current.style.left = `${leftMargin + 1250.3 * sizeRatio}px`;
        tiiunRef.current.style.top = `${topMargin + 483 * sizeRatio}px`;
        tiiunRef.current.style.width = `${186 * sizeRatio}px`;
        tiiunRef.current.style.height = `${91 * sizeRatio}px`;

        cookingRef.current.style.left = `${leftMargin + 86 * sizeRatio}px`;
        cookingRef.current.style.top = `${topMargin + 538 * sizeRatio}px`;
        cookingRef.current.style.width = `${198 * sizeRatio}px`;
        cookingRef.current.style.height = `${232 * sizeRatio}px`;

        microwaveOvenRef.current.style.left = `${leftMargin + 84 * sizeRatio}px`;
        microwaveOvenRef.current.style.top = `${topMargin + 345 * sizeRatio}px`;
        microwaveOvenRef.current.style.width = `${207 * sizeRatio}px`;
        microwaveOvenRef.current.style.height = `${134 * sizeRatio}px`;

        dishWasherRef.current.style.left = `${leftMargin + 625 * sizeRatio}px`;
        dishWasherRef.current.style.top = `${topMargin + 544 * sizeRatio}px`;
        dishWasherRef.current.style.width = `${113 * sizeRatio}px`;
        dishWasherRef.current.style.height = `${165 * sizeRatio}px`;

        homebrewRef.current.style.left = `${leftMargin + 525 * sizeRatio}px`;
        homebrewRef.current.style.top = `${topMargin + 460 * sizeRatio}px`;
        homebrewRef.current.style.width = `${120 * sizeRatio}px`;
        homebrewRef.current.style.height = `${115 * sizeRatio}px`;

        waterPurifierRef.current.style.left = `${leftMargin + 294 * sizeRatio}px`;
        waterPurifierRef.current.style.top = `${topMargin + 460 * sizeRatio}px`;
        waterPurifierRef.current.style.width = `${93 * sizeRatio}px`;
        waterPurifierRef.current.style.height = `${114 * sizeRatio}px`;

        washernDryerRef.current.style.left = `${leftMargin + 1545 * sizeRatio}px`;
        washernDryerRef.current.style.top = `${topMargin + 330 * sizeRatio}px`;
        washernDryerRef.current.style.width = `${187 * sizeRatio}px`;
        washernDryerRef.current.style.height = `${436 * sizeRatio}px`;

        stylerRef.current.style.left = `${leftMargin + 1696 * sizeRatio}px`;
        stylerRef.current.style.top = `${topMargin + 319 * sizeRatio}px`;
        stylerRef.current.style.width = `${160 * sizeRatio}px`;
        stylerRef.current.style.height = `${472 * sizeRatio}px`;

        vacuumCleanerRef.current.style.left = `${leftMargin + 1090 * sizeRatio}px`;
        vacuumCleanerRef.current.style.top = `${topMargin + 532 * sizeRatio}px`;
        vacuumCleanerRef.current.style.width = `${130 * sizeRatio}px`;
        vacuumCleanerRef.current.style.height = `${203 * sizeRatio}px`;

        airCareRef.current.style.left = `${leftMargin + 408 * sizeRatio}px`;
        airCareRef.current.style.top = `${topMargin + 502 * sizeRatio}px`;
        airCareRef.current.style.width = `${113 * sizeRatio}px`;
        airCareRef.current.style.height = `${372 * sizeRatio}px`;

        wearableAirPurifierRef.current.style.left = `${
            leftMargin + 1096 * sizeRatio
        }px`;
        wearableAirPurifierRef.current.style.top = `${
            topMargin + 790 * sizeRatio
        }px`;
        wearableAirPurifierRef.current.style.width = `${253 * sizeRatio}px`;
        wearableAirPurifierRef.current.style.height = `${103 * sizeRatio}px`;

        airConditionerRef.current.style.left = `${leftMargin + 1075 * sizeRatio}px`;
        airConditionerRef.current.style.top = `${topMargin + 241 * sizeRatio}px`;
        airConditionerRef.current.style.width = `${166 * sizeRatio}px`;
        airConditionerRef.current.style.height = `${111 * sizeRatio}px`;
    }

    const [isScale, setIsScale] = useState(false);
    const [backgroundSize, setBackgroundSize] = useState("cover");

    function resizeHandler() {
        const ratio = window.innerWidth / window.innerHeight;

        if (isScale) {
            setBackgroundSize("cover");
            if (ratio > bgRatio) {
                const sizeW = window.innerWidth;
                const sizeH = window.innerWidth / bgRatio;
                const sizeRatio = sizeW / 1920;
                const topMargin = (window.innerHeight - sizeH) * 0.5;
                const leftMargin = 0;
                updateLayout(sizeRatio, topMargin, leftMargin, sizeW, sizeH);
            } else {
                const sizeH = window.innerHeight;
                const sizeW = window.innerHeight * bgRatio;
                const sizeRatio = sizeH / 1080;
                const topMargin = 0;
                const leftMargin = (window.innerWidth - sizeW) * 0.5;
                updateLayout(sizeRatio, topMargin, leftMargin, sizeW, sizeH);
            }
        } else {
            setBackgroundSize("contain");
            //가로로 더 길어진 경우, 세로에 맞추고 가로에 여백이 생김
            if (ratio > bgRatio) {
                const sizeH = window.innerHeight;
                const sizeW = window.innerHeight * bgRatio;
                const sizeRatio = sizeH / 1080;
                const topMargin = 0;
                const leftMargin = (window.innerWidth - sizeW) * 0.5;
                updateLayout(sizeRatio, topMargin, leftMargin, sizeW, sizeH);
            }
            //세로로 더 길어진 경우, 가로에 맞추고 위아래에 여백이 생김
            else {
                const sizeW = window.innerWidth;
                const sizeH = window.innerWidth / bgRatio;
                const sizeRatio = sizeW / 1920;
                const topMargin = (window.innerHeight - sizeH) * 0.5;
                const leftMargin = 0;
                updateLayout(sizeRatio, topMargin, leftMargin, sizeW, sizeH);
            }
        }
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", resizeHandler);
        window.addEventListener('orientationchange', resizeHandler);
        window.dispatchEvent(new Event("resize"));
        return () => {
            window.removeEventListener("resize", resizeHandler);
            window.removeEventListener('orientationchange', resizeHandler);
        };
    }, []);

    //데이터
    const productData_refrigerator = useMemo(
        () => getProductsData("refrigerator"),
        []
    );
    const productData_tiiun = useMemo(() => getProductsData("tiiun_mini"), []);
    const productData_cooking = useMemo(() => getProductsData("cooking"), []);
    const productData_microwave_oven = useMemo(
        () => getProductsData("microwave_oven"),
        []
    );
    const productData_dishwasher = useMemo(
        () => getProductsData("dishwasher"),
        []
    );
    const productData_homebrew = useMemo(() => getProductsData("homebrew"), []);
    const productData_water_purifier = useMemo(
        () => getProductsData("water_purifier"),
        []
    );
    const productData_washer_n_dryer = useMemo(
        () => getProductsData("washer_n_dryer"),
        []
    );
    const productData_styler = useMemo(() => getProductsData("styler"), []);
    const productData_VacuumCleaner = useMemo(
        () => getProductsData("vacuum_cleaner"),
        []
    );
    const productData_air_care = useMemo(() => getProductsData("air_care"), []);
    const productData_WearableAirPurifier = useMemo(
        () => getProductsData("wearable_air_purifier"),
        []
    );
    const productData_air_conditioner = useMemo(
        () => getProductsData("air_conditioner"),
        []
    );
    const productData_mask_case = useMemo(() => getProductsData("mask_case"), []);
    const lang = useLangStore((state) => state.lang);
    const resize = useResizeStore((state) => state.resize);
    return (
        <Wrapper id="lghome-main" backgroundSize={backgroundSize}>
            <div className={"lghome-bg"}/>

            <div className={"title"} ref={titleRef}>
                <Image
                    alt="title"
                    role="img"
                    src={ROOT_PATH_PREFIX + "/images/main2/intro_LG Home_title_fit.png"}
                />
                <Text
                    style={{
                        filter: `drop-shadow(0 0  ${
                            resize.ratio * 15
                        }px rgba(0, 0, 0, 0.7))`,
                    }}
                    align="center"
                    color="white"
                    size={`${resize.ratio * 1.6}em`}
                >
                    {getLangString(lang, "home_main_click")?.text}
                </Text>
            </div>

            {/* 제품 카테고리 버튼 [Start] -------- */}
            <div className="box" ref={refrigeratorRef}>
                {productData_refrigerator && (
                    <ProductButton data={productData_refrigerator}/>
                )}
            </div>
            <div className="box" ref={tiiunRef}>
                {productData_tiiun && <ProductButton data={productData_tiiun}/>}
            </div>
            <div className="box" ref={cookingRef}>
                {productData_cooking && <ProductButton data={productData_cooking}/>}
            </div>
            <div className="box" ref={microwaveOvenRef}>
                {productData_microwave_oven && (
                    <ProductButton data={productData_microwave_oven}/>
                )}
            </div>
            <div className="box" ref={dishWasherRef}>
                {productData_dishwasher && (
                    <ProductButton data={productData_dishwasher}/>
                )}
            </div>
            <div className="box" ref={homebrewRef}>
                {productData_homebrew && <ProductButton data={productData_homebrew}/>}
            </div>
            <div className="box" ref={waterPurifierRef}>
                {productData_water_purifier && (
                    <ProductButton data={productData_water_purifier}/>
                )}
            </div>
            <div className="box" ref={washernDryerRef}>
                {productData_washer_n_dryer && (
                    <ProductButton data={productData_washer_n_dryer}/>
                )}
            </div>
            <div className="box" ref={stylerRef}>
                {productData_styler && <ProductButton data={productData_styler}/>}
            </div>
            <div className="box" ref={vacuumCleanerRef}>
                {productData_VacuumCleaner && (
                    <ProductButton data={productData_VacuumCleaner}/>
                )}
            </div>
            <div className="box" ref={airCareRef}>
                {productData_air_care && <ProductButton data={productData_air_care}/>}
            </div>
            <div className="box" ref={wearableAirPurifierRef}>
                {productData_WearableAirPurifier && (
                    <ProductButton data={productData_WearableAirPurifier}/>
                )}
            </div>
            <div className="box" ref={airConditionerRef}>
                {productData_air_conditioner && (
                    <ProductButton data={productData_air_conditioner}/>
                )}
            </div>

            {/* 제품 카테고리 버튼 [End] -------- */}
        </Wrapper>
    );
});

const Wrapper = styled.div<{ backgroundSize: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 2rem;
  background: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .title {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lghome-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    //max-width: 2048px;
    background-image: url("${ROOT_PATH_PREFIX}/images/etc/home_gate_default_bg_220103.png");
    background-size: ${(props) => props.backgroundSize};
    background-repeat: no-repeat;
    background-position: center;
  }

  //테스트 용
  .box {
    position: absolute;
    width: 10px;
    height: 10px;
    // background: rgba(255, 255, 0, 0.5);
  }
`;

export default LG_HomeMain;
