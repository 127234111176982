import create from "zustand";
import {ProductDataItemType, ProductsCategoryType, ProductsDataItemType, RoomType} from "../api/products/productsData";
import {randInt} from "three/src/math/MathUtils";

export const productDic = {};


/**
 * 제품/카테고리 선택 상태관리
 */
export type ProductState = {
    selectedCategory: ProductsDataItemType | null;      //선택된 카테고리 데이터
    selectedProduct: ProductDataItemType | null;        //선택된 제품 데이터
    prevSelectedProduct: ProductDataItemType | null;
    selectedColorIndex: number;                         //제품 컬러 인덱스
    selectedRoom: RoomType | null;                      //선택된 공간 타입
    prevSelectedRoom: RoomType | null;
    api: {
        selectCategory: (category: ProductsDataItemType | null, useRandom?: boolean) => void;       //카테고리 데이터 설정
        selectProduct: (data: ProductDataItemType | null) => void;             //제품 데이터 설정
        selectColorIndex: (idx: number) => void;                               //제품 컬러 인덱스 설정
        selectRoom: (roomType: RoomType | null) => void;                       //공간 선택
    }
};

const useProductStore = create<ProductState>((set, get) => ({
    selectedCategory: null,
    selectedProduct: null,
    prevSelectedProduct: null,
    selectedColorIndex: 0,
    selectedRoom: null,
    prevSelectedRoom: null,
    api: {
        selectCategory: (category, useRandom = true) => {

            if(category && category.productList && category.productList.length > 0){

                // console.log("2 >>>>>>", productDic[category.category]);

                const selectedProductInfo = productDic[category.category] ?? category.productList[0];
                productDic[category.category] = selectedProductInfo;
                set({
                    selectedCategory: category,
                    selectedProduct: selectedProductInfo,
                    prevSelectedProduct: get().selectedProduct,
                    selectedColorIndex: 0
                });
            }else{
                set({selectedCategory: category, selectedProduct:null, prevSelectedProduct: get().selectedProduct});
            }
        },
        selectProduct: data => {
            productDic[get().selectedCategory?.category as string] = data;
            set({selectedProduct: data, prevSelectedProduct: get().selectedProduct, selectedColorIndex: 0})
        },
        selectColorIndex: idx => set({selectedColorIndex: idx}),
        selectRoom: roomType => {
            const prevSelected = get().selectedRoom;
            set({selectedRoom: roomType, prevSelectedRoom: prevSelected});
        }
    }
}));

export default useProductStore;
