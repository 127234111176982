import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import useHistoryStore, {HistoryState} from "../../stores/historyStore";
import {Text, Box, useCursor} from "@react-three/drei";
import * as THREE from "three";
import useCameraStore, {CameraState} from "../../stores/cameraStore";
import useObjetProductStore, {
    ObjetProductState,
    useObjetColorStore,
} from "../../stores/objetProductStore";
import {Objet_CategoryItemType} from "../../api/products/objetProductsData";
import {Color, ThreeEvent} from "@react-three/fiber";
import Axis from "../../utils/Axis";
import useProductStore from "../../stores/productStore";
import {useObjetInterior} from "../../stores/modalStore";
import {GA_Event} from "../../api/ga";

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const pushHistorySelector = (state: HistoryState) => state.pushHistory;
const pathnameSelector = (state: HistoryState) => state.pathname;
const cameraApiSelector = (state: CameraState) => state.api;
const selectedCategorySelector = (state: ObjetProductState) =>
    state.selectedCategory;
const selectedRoomSelector = (state: ObjetProductState) => state.selectedRoom;

type ObjetProductContainer3DProps = {
    args?: [x: number, y: number, z: number];
    data?: Objet_CategoryItemType;
    color?: Color;
    showDebug?: boolean;
    cameraTargetPos?: [x: number, y: number, z: number];
    lookPosOffset?: [x: number, y: number, z: number];
    children?: React.ReactNode;
    selectMeCallback?: () => void;
} & Omit<JSX.IntrinsicElements["group"], "args">;

const ObjetProductContainer3D = ({
                                     args = [1, 1, 1],
                                     data,
                                     color,
                                     showDebug = true,
                                     cameraTargetPos = [0, 0, 4],
                                     lookPosOffset = [0, 0, 0],
                                     children,
                                     selectMeCallback,
                                     ...rest
                                 }: ObjetProductContainer3DProps) => {
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);

    const ref = useRef<THREE.Group>(null!);
    const cameraTargetRef = useRef<THREE.Mesh>(null!);

    const [cameraTargetWorldPos] = useState(() => new THREE.Vector3());
    const [worldPos] = useState(() => new THREE.Vector3());
    const {setTargetPos, setLookPos} = useCameraStore(cameraApiSelector);

    const pushHistory = useHistoryStore(pushHistorySelector);
    const pathname = useHistoryStore(pathnameSelector);
    const selectedCategory = useObjetProductStore(selectedCategorySelector); //선택된 카테고리
    const selectedRoom = useObjetProductStore(selectedRoomSelector); //선택된 공간

    const isMe = useMemo(
        () => selectedCategory?.category === data?.category,
        [selectedCategory, data]
    );
    const isObjet = useMemo(
        () => pathname.indexOf("/objetcollection") > -1,
        [pathname]
    );

    const selectMe = useCallback(() => {
        cameraTargetRef.current.getWorldPosition(cameraTargetWorldPos);
        ref.current.getWorldPosition(worldPos);
        worldPos.add(new THREE.Vector3(lookPosOffset[0], lookPosOffset[1], lookPosOffset[2]));
        setTargetPos(cameraTargetWorldPos);
        setLookPos(worldPos);
    }, []);

    useEffect(() => {
        if (isMe) selectMeCallback ? selectMeCallback() : selectMe();
    }, [isMe]);

    const onPointerOverHandler = useCallback((e: ThreeEvent<PointerEvent>) => {
        if (!ref.current) return;
        e.stopPropagation();
        setHovered(true);
    }, []);
    const onPointerOutHandler = useCallback((e: ThreeEvent<PointerEvent>) => {
        if (!ref.current) return;
        e.stopPropagation();
        setHovered(false);
    }, []);

    return (
        <group
            ref={ref}
            {...rest}
            onPointerOver={onPointerOverHandler}
            onPointerOut={onPointerOutHandler}
            onClick={(e) => {
                e.stopPropagation();

                if (data && e.delta < 10) {
                    if (isObjet) {
                        pushHistory(`/objetcollection/${selectedRoom}/${data.category}`);
                    }
                }
            }}
        >
            {/* 디버그용 */}
            <group visible={showDebug}>
                <Box args={args}>
                    <meshBasicMaterial wireframe={true} color={color}/>
                </Box>
                <Axis
                    xAxisVisible={false}
                    yAxisVisible={false}
                    position={[0, 0, args[2] * 0.5]}
                />
                <Text fontSize={0.04} position={[0, args[1] * 0.6, 0]}>
                    {data?.category}
                </Text>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={cameraTargetPos}
                    ref={cameraTargetRef}
                >
                    <meshBasicMaterial wireframe color={"#f00"}/>
                </Box>
            </group>
        </group>
    );
};

export default ObjetProductContainer3D;
