import { QuestionCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { GA_Event } from "../../../api/ga";
import { getLangString, LangLabelType } from "../../../api/lang/langData";
import {
  Objet_ColorCategoryType,
  Objet_ProductsCategoryType,
} from "../../../api/products/objetProductsData";
import { ProductColorType } from "../../../api/products/productsData";
import useLangStore, { LangState } from "../../../stores/langStore";
import { useModalBalloonClose } from "../../../stores/modalStore";
import useObjetProductStore, {
  ObjetColorState,
  useObjetColorStore,
} from "../../../stores/objetProductStore";
import useResizeStore from "../../../stores/resizeStore";
import useRightPanelStore from "../../../stores/rightPanelStore";
import useSpaceThemeStore, {
  useObjetFloorTextureStore,
  useObjetWallTextureStore,
} from "../../../stores/spaceThemeStore";
import theme from "../../../styles/theme.styled";
import {
  Container,
  ListContainer,
} from "../../common/comps_styled/Container.styled";
import { Divider } from "../../common/comps_styled/Divider.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import {
  InteriorBox,
  InteriorButton,
} from "../../lghome/comps_styled/selectPanel/InteriorButton.styled";
import Balloon from "./Balloon";

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const langSelector = (state: LangState) => state.lang;
const setObjetColorSelector = (state: ObjetColorState) =>
  state.api.setObjetColor;

type SelectObjetColorProps = {
  label: LangLabelType;
  productsCategory: Objet_ProductsCategoryType;
  colorCategory: Objet_ColorCategoryType;
  colors: ProductColorType[] | undefined;
  order: number;
  ga_id?: string;
} & JSX.IntrinsicElements["div"];

const SelectObjetColor = React.memo(
  ({
    label,
    productsCategory,
    colorCategory,
    colors,
    order,
    ga_id,
  }: SelectObjetColorProps) => {
    const [isRefrigerator, setIsRefrigerator] = useState<boolean>(false);
    const [isOpenBalloon, setIsOpenBalloon] = useState<boolean>(false);
    const modalBalloonClose = useModalBalloonClose((state) => state.isOpen);
    const setModalBalloonClose = useModalBalloonClose(
      (state) => state.setIsOpen
    );
    const lang = useLangStore(langSelector);
    const setObjetColor = useObjetColorStore(setObjetColorSelector);
    const resize = useResizeStore((state) => state.resize);

    const objetData = useObjetProductStore((state) => state.selectedCategory);
    const isObjetMode = useRightPanelStore((state) => state.isObjetMode);
    const searchObjetColor = useObjetColorStore(
      (state) => state.api.searchObjetColor
    );
    const searchedObjetColorCategory = useObjetColorStore(
      (state) => state.searchedObjetColorCategory
    );
    const searchedObjetColorLabel = useObjetColorStore(
      (state) => state.searchedObjetColorLabel
    );

    // for GAEvent
    const [clickStatus, setClickStatus] = useState(false);
    const [ga_color_id, set_ga_color_id] = useState<string>("");
    const objetProducts = useObjetProductStore((state) => state);
    const objetColors = useObjetColorStore((state) => state);
    const ga_product = objetProducts.selectedCategory?.ga_id;
    const ga_interior = useSpaceThemeStore((state) => state.objetTheme);
    const ga_wall_material = useObjetWallTextureStore(
      (state) => state.material
    );
    const ga_floor_material = useObjetFloorTextureStore(
      (state) => state.material
    );
    const colorsData = objetColors.api.getObjetColor(productsCategory);

    useEffect(() => {
      ga_color_id &&
        GA_Event(
          "lg_objet",
          `${ga_product}_material_color`,
          ga_id + "_" + ga_color_id,
          {
            ...objetColors.api.getAllColorsGA(),
            dimension10: ga_product,
            dimension11: ga_id,
            dimension12: ga_interior,
            dimension13: ga_wall_material,
            dimension14: ga_floor_material,
            dimension15: ga_color_id,
          }
        );
    }, [ga_color_id, clickStatus]);

    useEffect(() => {
      console.log(">>>>>", searchedObjetColorCategory, searchedObjetColorLabel);
    }, [searchedObjetColorCategory, searchedObjetColorLabel]);

    useEffect(() => {
      setIsRefrigerator(objetData?.label === "objet_category_refrigerator");
    }, [objetData]);

    useEffect(() => {
      !modalBalloonClose && setIsOpenBalloon(modalBalloonClose);
    }, [modalBalloonClose]);

    return (
      <Container
        style={{
          padding: IS_MOBILE ? "0 4%" : "8.2% 4% 0 4%",
        }}
      >
        <div
          style={{
            position: "relative",
            pointerEvents: "visible",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsOpenBalloon(true);
            setModalBalloonClose(true);
          }}
        >
          <Balloon
            colorCategory={colorCategory}
            isOpenBalloon={isOpenBalloon}
            isBottom={order === 3}
          />
          <div style={{ display: "inline-flex", alignItems: "center" }}>
            <Text
              size={`${(IS_MOBILE ? 3 : 2.5) * resize.ratio}em`}
              weight={theme.font.weight.semibold}
              style={{ paddingLeft: "2%" }}
            >
              {getLangString("en", label)?.text}
            </Text>
            <QuestionCircleOutlined
              style={{
                marginLeft: "8%",
                color: isOpenBalloon ? theme.colors.objet.pink : "#8f8f8f",
                fontSize: `${(IS_MOBILE ? 3 : 2.5) * resize.ratio}em`,
                pointerEvents: "visible",
              }}
            />
          </div>
        </div>

        <Divider
          borderwidth={2 * resize.ratio}
          color={theme.colors.objet.warmgray}
          style={{ marginTop: "2%", marginBottom: IS_MOBILE ? "2%" : "4%" }}
        />
        <ListContainer
          style={{
            padding: isRefrigerator ? "0 4.5%" : 0,
            paddingLeft: isRefrigerator ? 0 : "7.5%",
          }}
        >
          {colors?.map((data, index) => {
            const name = getLangString("en", data.label as LangLabelType)?.text;

            return (
              <InteriorButton
                key={index}
                style={{
                  width: isRefrigerator ? "29%" : "42%",
                  marginBottom: IS_MOBILE ? "3%" : "5%",
                }}
                selected={
                  data.label === colorsData?.colorLabel &&
                  colorCategory === colorsData?.colorCategory
                }
                onClick={(e) => {
                  set_ga_color_id(data.ga_id ?? "");
                  setObjetColor(
                    productsCategory,
                    colorCategory,
                    data.label,
                    ga_id + "_" + data.ga_id
                  );

                  searchObjetColor(productsCategory);

                  setClickStatus(!clickStatus);
                }}
              >
                <InteriorBox
                  ratio={resize.ratio}
                  radius={`${(IS_MOBILE ? 1 : 0.5) * resize.ratio}em`}
                  className="box"
                  objetMode={isObjetMode}
                  style={{
                    width: `${IS_MOBILE ? 12 * resize.ratio + "em" : "100%"}`,
                    height: `${(IS_MOBILE ? 5.5 : 4.2) * resize.ratio}em`,
                    marginBottom: "5%",
                    backgroundImage: `url(${ROOT_PATH_PREFIX + data.color})`,
                    backgroundSize: "110%",
                    backgroundPositionX: "10%",
                    backgroundPositionY: "10%",
                  }}
                />
                <Text
                  align="center"
                  size={`${(IS_MOBILE ? 1.8 : 1.5) * resize.ratio}em`}
                  className="text"
                >
                  {name}
                </Text>
              </InteriorButton>
            );
          })}
        </ListContainer>
      </Container>
    );
  }
);

export default SelectObjetColor;
