/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        UI: THREE.Mesh
        Body: THREE.Mesh
    }
    materials: {
        ['Air_09_Albedo.001']: THREE.MeshStandardMaterial
        Air_09_Albedo: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/m/dualcool_window_inverter_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/dualcool_window_inverter_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_conditioner/dualcool_window_inverter_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    useLayoutEffect(() => {
        materials.Air_09_Albedo.envMapIntensity = 2;
        materials['Air_09_Albedo.001'].envMapIntensity = 2;
        materials['Air_09_Albedo.001'].emissiveMap = materials['Air_09_Albedo.001'].map;
        materials['Air_09_Albedo.001'].emissive.setHex(0xffffff);
        materials['Air_09_Albedo.001'].emissiveIntensity = 1;
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.UI.geometry}
                material={materials['Air_09_Albedo.001']}
                userData={{name: 'UI'}}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={materials.Air_09_Albedo}
                userData={{name: 'Body'}}
            />
        </group>
    )
}
