import * as THREE from "three";


const cleanMaterial = material => {
    // console.log('dispose material!')
    material.dispose()

    // dispose textures
    for (const key of Object.keys(material)) {
        const value = material[key]
        if (value && typeof value === 'object' && 'minFilter' in value) {
            // console.log('dispose texture!')
            value.dispose()
        }
    }
}

export function disposeScene(scene:THREE.Group) {
    if(scene){
        scene.traverse((child)=> {

            //@ts-ignore
            if (!child.isMesh) return;

            // console.log('dispose geometry!');
            const mesh = child as THREE.Mesh;
            mesh.geometry.dispose();

            //@ts-ignore
            if (mesh.material.isMaterial) {
                cleanMaterial(mesh.material);
            } else {
                // an array of materials
                //@ts-ignore
                for (const material of mesh.material) {
                    cleanMaterial(material);
                }
            }
        })
        // scene.traverse((child)=>{
        //
        //     //@ts-ignore
        //     if(child.type === 'Mesh')
        //     {
        //         const mesh = child as THREE.Mesh;
        //         mesh.geometry.dispose();
        //
        //         const mat = mesh.material as THREE.MeshStandardMaterial;
        //         mat.map?.dispose();
        //         mat.lightMap?.dispose();
        //         mat.aoMap?.dispose();
        //         mat.roughnessMap?.dispose();
        //         mat.metalnessMap?.dispose();
        //         mat.normalMap?.dispose();
        //         mat.emissiveMap?.dispose();
        //         mat.alphaMap?.dispose();
        //         mat.envMap?.dispose();
        //         mat.bumpMap?.dispose();
        //         mat.displacementMap?.dispose();
        //         // mat.needsUpdate = true;
        //         mat.dispose();
        //     }
        // });
    }
}

export function setupScene(scene: THREE.Group, lightmapTex?:THREE.Texture, aoTex?:THREE.Texture, lightmapIntensity:number = 1, aomapIntensity:number = 0.5) {
    scene.traverse((child)=>{
        if(child.type === 'Mesh'){
            const mesh = child as THREE.Mesh;
            const mat = mesh.material as THREE.MeshStandardMaterial;
            if(!mat.transparent) {
                if(lightmapTex) {
                    mat.lightMap = lightmapTex;
                    mat.lightMapIntensity = lightmapIntensity;
                }
                if(aoTex) {
                    mat.aoMap = aoTex;
                    mat.aoMapIntensity = aomapIntensity;
                }
            }
        }
    })
}
