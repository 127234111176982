import React, { useEffect } from "react";
import styled from "styled-components";
import { Z_INDEX_BOTTOM_UI, Z_INDEX_TOP_UI } from "./const";
import LangButtons from "./LangButtons";
import ProductsCategoryPanel from "../lghome/products_panel/ProductsCategoryPanel";
import useHistoryStore from "../../stores/historyStore";

const BottomUI = () => {
  const pathname = useHistoryStore((state) => state.pathname);

  return (
    <Wrapper
      id="bottom-ui"
      style={{
        zIndex: Z_INDEX_TOP_UI - 10,
      }}
    >
      <LangButtons />

      {pathname === "/home" && <ProductsCategoryPanel />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  color: white;
`;

export default BottomUI;
