import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";
import ProductsToggleButton from "./ProductsToggleButton";
import ProductsCategoryList from "./ProductsCategoryList";
import { easeCubicOut } from "d3-ease";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";
import { IS_MOBILE } from "../../common/const";

/**
 * 하단 제품 카테고리 선택 UI
 * @constructor
 */

export const BOTTOM_PANEL_HEIGHT = IS_MOBILE ? 70 : 22.8;

const ProductsCategoryPanel = () => {
  const [isOpen, setIsOpen] = useState(false);
  const resize = useResizeStore((state) => state.resize);

  const spring = useSpring({
    bottom: isOpen ? 0 : -BOTTOM_PANEL_HEIGHT * resize.ratio,
    config: {
      easing: easeCubicOut,
    },
  });

  return (
    <Wrapper
      ratio={resize.ratio}
      style={{
        bottom: spring.bottom.to((value) => value + "em"),
      }}
    >
      <ProductsToggleButton
        selected={isOpen}
        onClick={() => {
          setIsOpen((prevState) => !prevState);
          GA_Event("lg_home", "select_products", "click");
        }}
      />
      <ProductsCategoryList />
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)<
  { ratio: number; selected?: boolean } & Record<string, any>
>`
  position: absolute;
  left: ${IS_MOBILE ? 3 : 0}%;
  bottom: 0;
  width: ${IS_MOBILE ? 94 : 100}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export default ProductsCategoryPanel;
