/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from '../../components/common/const'
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {disposeScene, setupScene} from "../../utils/disposeScene";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";
import {useEquirectEXR} from "../../utils/useEquirect";

type GLTFResult = GLTF & {
    nodes: {
        white: THREE.Mesh
        Body: THREE.Mesh
        silver: THREE.Mesh
    }
    materials: {
        white: THREE.MeshStandardMaterial
        silver: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/air_care/m/puricare360_1_stage2_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_care/puricare360_1_stage2_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_care/puricare360_1_stage2_ktx2.glb';
const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/products/air_care/puricare360_1_stage_Lightmap.jpg';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const lightTex = useTexture(LIGHT_MAP_URL);

    useLayoutEffect(() => {
        lightTex.flipY = false;
        lightTex.encoding = THREE.sRGBEncoding;
        lightTex.needsUpdate = true;
    }, [lightTex]);

    const bodyRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    //색상 변경 시
    useEffect(() => {
        if (selectedProduct?.modelNo === 'puricare_stage1') {
            if (selectedColorIndex === 0) {
                bodyRef.current.material = materials.silver;
            } else if (selectedColorIndex === 1) {
                bodyRef.current.material = materials.white;
            }
            materials.silver.lightMap = lightTex;
            materials.silver.lightMapIntensity = 2;
            materials.silver.metalness = 0.9;
            materials.white.lightMap = lightTex;
            materials.white.lightMapIntensity = 2;
            materials.white.metalness = 0;
            materials.white.roughness = 0.5;
        }
    }, [selectedProduct, selectedColorIndex, lightTex]);

    useEffect(() => {
        materials.silver.lightMap = lightTex;
        materials.silver.lightMapIntensity = 2;
        materials.silver.metalness = 0.9;
        bodyRef.current.material = materials.silver;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
            useTexture.clear(LIGHT_MAP_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.white.geometry}
                material={materials.white}
                position={[0.1508, 3.3719, 0]}
                scale={[0.0146, 0.0146, 0.0146]}
                userData={{name: 'white'}}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={materials.silver}
                position={[0, 0.0082, 0]}
                rotation={[-Math.PI, 0, -Math.PI]}
                scale={[0.01, 0.01, 0.01]}
                userData={{name: 'Body'}}
                ref={bodyRef}
            />
            <mesh
                geometry={nodes.silver.geometry}
                material={materials.silver}
                position={[0.0995, 3.3719, 0]}
                scale={[0.0146, 0.0146, 0.0146]}
                userData={{name: 'silver'}}
            />
        </group>
    )
}
