/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        Object001: THREE.Mesh
        black_Marterial4_Decal: THREE.Mesh
        Part1: THREE.Mesh
        Plane002: THREE.Mesh
        Object002_Decal: THREE.Mesh
        silver_Marterial001_Decal: THREE.Mesh
        _body_Decal: THREE.Mesh
        Plane: THREE.Mesh
        Plane001: THREE.Mesh
        Plane003: THREE.Mesh
        Plane004: THREE.Mesh
        Mesh: THREE.Mesh
        Mesh_1: THREE.Mesh
        black_Marterial006: THREE.Mesh
        black_Marterial4: THREE.Mesh
        Plane005: THREE.Mesh
        Plane006: THREE.Mesh
        Part2: THREE.Mesh
        Plane007: THREE.Mesh
    }
    materials: {
        Glass: THREE.MeshStandardMaterial
        Sliver_Decal: THREE.MeshStandardMaterial
        Part1: THREE.MeshStandardMaterial
        A9K_Part1_Pink: THREE.MeshStandardMaterial
        ['08 - Default_Decal']: THREE.MeshStandardMaterial
        head_Decal: THREE.MeshStandardMaterial
        ['08 - Default_Decal.001']: THREE.MeshStandardMaterial
        A9K_Part1_Black: THREE.MeshStandardMaterial
        A9K_Part1_Gray: THREE.MeshStandardMaterial
        A9K_Part1_Silver: THREE.MeshStandardMaterial
        A9K_Part2_Black: THREE.MeshStandardMaterial
        silver_Marterial1: THREE.MeshStandardMaterial
        Black_Marterial: THREE.MeshStandardMaterial
        ['Material #27']: THREE.MeshStandardMaterial
        Sliver: THREE.MeshStandardMaterial
        A9K_Part2_Gray: THREE.MeshStandardMaterial
        A9K_Part2_Pink: THREE.MeshStandardMaterial
        Part2: THREE.MeshStandardMaterial
        A9K_Part2_Silver: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/m/a9k_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/a9k_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);

    const targetRef = useRef<THREE.Mesh>(null!);
    const target2Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'a9k') {
            if (selectedColorIndex === 0) {
                targetRef.current.material = materials.A9K_Part1_Gray;
                target2Ref.current.material = materials.A9K_Part2_Gray;
            } else if (selectedColorIndex === 1) {
                targetRef.current.material = materials.A9K_Part1_Silver;
                target2Ref.current.material = materials.A9K_Part2_Silver;
            } else if (selectedColorIndex === 2) {
                targetRef.current.material = materials.A9K_Part1_Pink;
                target2Ref.current.material = materials.A9K_Part2_Pink;
            } else {
                targetRef.current.material = materials.A9K_Part1_Black;
                target2Ref.current.material = materials.A9K_Part2_Black;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} position={[0, 0, 0.23]} dispose={null}>
            <mesh
                geometry={nodes.Object001.geometry}
                material={materials.Glass}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Object001' }}
            />
            <mesh
                geometry={nodes.black_Marterial4_Decal.geometry}
                material={materials.Sliver_Decal}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'black_Marterial4_Decal' }}
            />
            <mesh
                geometry={nodes.Part1.geometry}
                material={materials.Part1}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Part1' }}
                ref={targetRef}
            />
            <mesh
                geometry={nodes.Plane002.geometry}
                material={materials.A9K_Part1_Pink}
                position={[0, 0, -1.4404]}
                userData={{ name: 'Plane.002' }}
            />
            <mesh
                geometry={nodes.Object002_Decal.geometry}
                material={materials['08 - Default_Decal']}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Object002_Decal' }}
            />
            <mesh
                geometry={nodes.silver_Marterial001_Decal.geometry}
                material={materials.head_Decal}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'silver_Marterial001_Decal' }}
            />
            <mesh
                geometry={nodes._body_Decal.geometry}
                material={materials['08 - Default_Decal.001']}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: '_body_Decal' }}
            />
            <mesh
                geometry={nodes.Plane.geometry}
                material={materials.A9K_Part1_Black}
                position={[0, 0, -1.0537]}
                userData={{ name: 'Plane' }}
            />
            <mesh
                geometry={nodes.Plane001.geometry}
                material={materials.A9K_Part1_Gray}
                position={[0, 0, -1.2307]}
                userData={{ name: 'Plane.001' }}
            />
            <mesh
                geometry={nodes.Plane003.geometry}
                material={materials.A9K_Part1_Silver}
                position={[0, 0, -1.6174]}
                userData={{ name: 'Plane.003' }}
            />
            <mesh
                geometry={nodes.Plane004.geometry}
                material={materials.A9K_Part2_Black}
                position={[-0.3021, 0, -1.0537]}
                userData={{ name: 'Plane.004' }}
            />
            <group
                position={[-0.0007, -0.1411, -0.0484]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'silver_Marterial' }}>
                <mesh geometry={nodes.Mesh.geometry} material={materials.silver_Marterial1} />
                <mesh geometry={nodes.Mesh_1.geometry} material={materials.Black_Marterial} />
            </group>
            <mesh
                geometry={nodes.black_Marterial006.geometry}
                material={materials['Material #27']}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'black_Marterial006' }}
            />
            <mesh
                geometry={nodes.black_Marterial4.geometry}
                material={materials.Sliver}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'black_Marterial4' }}
            />
            <mesh
                geometry={nodes.Plane005.geometry}
                material={materials.A9K_Part2_Gray}
                position={[-0.3021, 0, -1.2307]}
                userData={{ name: 'Plane.005' }}
            />
            <mesh
                geometry={nodes.Plane006.geometry}
                material={materials.A9K_Part2_Pink}
                position={[-0.3021, 0, -1.4404]}
                userData={{ name: 'Plane.006' }}
            />
            <mesh
                geometry={nodes.Part2.geometry}
                material={materials.Part2}
                position={[-0.0016, 0.0431, -0.003]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Part2' }}
                ref={target2Ref}
            />
            <mesh
                geometry={nodes.Plane007.geometry}
                material={materials.A9K_Part2_Silver}
                position={[-0.3021, 0, -1.6174]}
                userData={{ name: 'Plane.007' }}
            />
        </group>
    )
}
