const playVideo = (video: HTMLMediaElement, memo?: string) => {
    const playPromise = video.play();
    if (playPromise !== undefined) {
        playPromise.then(_ => {
            console.log("video play!", memo);
        }).catch(err => {
            console.log(err);
        });
    }
};

export default playVideo;
