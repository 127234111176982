import {ProductsDataItemType} from "./productsData";
import CW_Lupin2_Gas_Sin_Double_Best from "../../products/cooking/CW Lupin2 Gas Sin Double Best";
import CW_Lupin2_Elec_Sin_Double_Best from "../../products/cooking/CW Lupin2 Elec Sin Double Best";
import Lupin_gas_single from "../../products/cooking/MM Refresh Gas SIN Good";
import Lupin_elec_single from "../../products/cooking/MM Lupin2 Elec";
import Lupin_dual from "../../products/cooking/Lupin_Dual";
import Lupin_Slide from "../../products/cooking/Lupin_Slide";


/**
 * 오븐 데이터
 */
const productsData_Cooking: ProductsDataItemType = {
    category: "cooking",
    ga_id: "cooking_oven&range",
    label: "category_cooking",
    category_img_url: "/images/products/cooking/category_img.png",
    category_thumb_url: "/images/products/cooking/category_thumb.png",
    category_glow_url: "/images/products/cooking/category_glow.png",
    roomType: "kitchen",
    usp_transition: "/movies/usp/transition/main_CK_000_00000.webm",
    productList: [
        {
            name: "CW Lupin2 Gas S/in Double Best",
            modelNo: "lupin_gas_double",
            ga_id: "lupin2_gas_double_best",
            isCES: true,
            thumb_url: "/images/products/cooking/lupin_gas_double/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/cooking/lupin_gas_double/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blackstainsteel",
                    color:
                        "/images/products/cooking/lupin_gas_double/color_blackstain.png",
                    ga_id: "black_stainless_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_printproofsteel",
                    color: "/images/products/cooking/lupin_gas_double/color_steel.png",
                    ga_id: "printproof_stainless_steel",
                },
            ],
            model: <CW_Lupin2_Gas_Sin_Double_Best/>,
        },
        {
            name: "CW Lupin2 Elec. S/in Double Best",
            modelNo: "lupin_elec_double",
            ga_id: "lupin2_elec._double_best",
            isCES: true,
            thumb_url: "/images/products/cooking/lupin_elec_double/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blackstainsteel",
                    color:
                        "/images/products/cooking/lupin_elec_double/color_blackstain.png",
                    ga_id: "black_stainless_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_printproofsteel",
                    color: "/images/products/cooking/lupin_elec_double/color_steel.png",
                    ga_id: "printproof_stainless_steel",
                },
            ],
            model: <CW_Lupin2_Elec_Sin_Double_Best/>,
        },
        {
            name: "CW Lupin2 Dual Fuel S/in Single Better+",
            modelNo: "lupin_dual",
            ga_id: "lupin2_dual_fuel_single_better+",
            isCES: true,
            thumb_url: "/images/products/cooking/lupin_dual/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/cooking/lupin_dual/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blackstainsteel",
                    color: "/images/products/cooking/lupin_dual/color_blackstain.png",
                    ga_id: "black_stainless_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_printproofsteel",
                    color: "/images/products/cooking/lupin_dual/color_steel.png",
                    ga_id: "printproof_stainless_steel",
                },
            ],
            model: <Lupin_dual/>,
        },
        {
            name: "MM Lupin2 Gas S/in Single Good",
            modelNo: "lupin_gas_single",
            ga_id: "lupin2_gas_single_good",
            isCES: true,
            thumb_url: "/images/products/cooking/lupin_gas_single/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/cooking/lupin_gas_single/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blackstainsteel",
                    color:
                        "/images/products/cooking/lupin_gas_single/color_blackstain.png",
                    ga_id: "black_stainless_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_printproofsteel",
                    color: "/images/products/cooking/lupin_gas_single/color_steel.png",
                    ga_id: "printproof_stainless_steel",
                },
            ],
            model: <Lupin_gas_single/>,
        },
        {
            name: "MM Lupin2 Elec. S/in Single Good",
            modelNo: "lupin_elec_single",
            ga_id: "lupin2_elec._single_good",
            isCES: true,
            thumb_url: "/images/products/cooking/lupin_elec_single/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/cooking/lupin_elec_single/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blackstainsteel",
                    color:
                        "/images/products/cooking/lupin_elec_single/color_blackstain.png",
                    ga_id: "black_stainless_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_printproofsteel",
                    color: "/images/products/cooking/lupin_elec_single/color_steel.png",
                    ga_id: "printproof_stainless_steel",
                },
            ],
            model: <Lupin_elec_single/>,
        },
        {
            name: "Lupin2 Slide in Best",
            modelNo: "lupin_slide",
            ga_id: "lupin2_gas_best",
            isCES: false,
            thumb_url: "/images/products/cooking/lupin_slide/product_thumb.png",
            ar_url: "http://lgevirtual.com/ar/instaviewrange/gas",
            ar_text: "home_exp_ar_lupin_slide",
            openExperience: true,
            open_url: "/images/products/cooking/lupin_slide/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_printproofsteel",
                    color: "/images/products/cooking/lupin_elec_single/color_steel.png",
                    ga_id: "printproof_stainless_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_blackstainsteel",
                    color:
                        "/images/products/cooking/lupin_slide/color_blackstainsteel.png",
                    ga_id: "black_stainless_steel",
                }
            ],
            model: <Lupin_Slide/>,
        },
    ],
};

export default productsData_Cooking;
