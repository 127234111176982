import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Canvas} from "@react-three/fiber";
import qs from "qs";
import {
    BrowserRouter as Router,
    useLocation,
    useHistory,
} from "react-router-dom";
import {Debug, Physics} from "@react-three/cannon";
import "./styles/App.css";
import DebugGl from "./utils/DebugGL";
import TopUI from "./components/common/TopUI";
import BottomUI from "./components/common/BottomUI";
import LG_ThinQ2 from "./components/lgthinq/LG_ThinQ2";
import LG_Home from "./components/lghome/LG_Home";
import LG_ObjetCollection from "./components/objetcollection/LG_ObjetCollection";
import useLangStore from "./stores/langStore";
import useHistoryStore from "./stores/historyStore";
import Player from "./components/common/camera/Player";
import {ThemeProvider} from "styled-components";
import theme from "./styles/theme.styled";
import Modal from "./components/common/navigation/Modal";
import Main2 from "./components/main/Main2";
import {
    IS_APPLE_DEVICE, IS_CHROME,
    IS_MOBILE,
    ROOT_PATH_PREFIX,
    Z_INDEX_POPUP,
} from "./components/common/const";
import productsData from "./api/products/productsData";
import objetProductsData from "./api/products/objetProductsData";
import Landscape from "./components/common/Landscape";
import LoaderView from "./components/common/LoaderView";
import {GA_PageView} from "./api/ga";
import {Stats} from "@react-three/drei";

const shuffleData = () => {
    console.log("++++ shuffle home products data +++++");
    productsData.map((v, i) => {
        v.productList?.sort(() => Math.random() - 0.5);
        // v.productList?.map((vv, ii) => {
        //     vv.colors?.sort(() => Math.random() - 0.5);
        // });
    });
    console.log("++++ shuffle objet products data +++++");
    objetProductsData.map((v, i) => {
        v.colorData?.sort(() => Math.random() - 0.5);
        v.colorData?.map((vv, ii) => {
            vv.colors?.sort(() => Math.random() - 0.5);
        });
    });
};
shuffleData();

const App = React.memo(() => {
    const [height, setHeight] = useState(window.innerHeight);
    const pathname = useHistoryStore((state) => state.pathname);

    function resizeHandler() {
        setHeight(window.innerHeight);
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", resizeHandler);
        window.dispatchEvent(new Event("resize"));

        return () => {
            window.removeEventListener("resize", resizeHandler);
        };
    }, []);

    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(() => setLoaded(true), 600);
    }, []);

    const Blinder = () => (
        <div
            style={{
                position: "absolute",
                zIndex: Z_INDEX_POPUP - 1,
                backgroundColor: "black",
                width: "100%",
                height: "100%",
            }}
        />
    );

    const main = () => {
        return (
            <>
                {!loaded && Blinder()}
                <Modal/>
                <TopUI/>

                <Canvas
                    dpr={window.devicePixelRatio}
                    gl={{antialias: true}}
                    camera={{position: [0, 0, 1], fov: 50}}
                    id={"canvas-container"}
                    mode={"concurrent"}
                    // shadows
                >
                    <color attach={"background"} args={["#a19798"]}/>

                    <Physics gravity={[0, 0, 0]}>
                        {/*<Debug color={"#0f0"}>*/}
                        <Router>
                            <ControlHistory/>
                            {(pathname === "/" ||
                                pathname === "" ||
                                pathname === "/innovation_awards" ||
                                pathname === "/zepeto") && <Main2/>}
                            {pathname.indexOf("/thinq") > -1 && <LG_ThinQ2/>}
                            {pathname.indexOf("/home") > -1 && <LG_Home/>}
                            {pathname.indexOf("/objetcollection") > -1 && (
                                <LG_ObjetCollection/>
                            )}
                        </Router>

                        <Player position={[0, 0, 0]} baseHeight={1.3}/>
                        {/*</Debug>*/}
                    </Physics>

                    <DebugGl/>
                    {/*<Stats/>*/}
                </Canvas>

                <LoaderView/>

                <BottomUI/>
            </>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <>
                <section
                    aria-label={""}
                    style={{
                        backgroundColor: "#858289",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                    }}
                >
                    {IS_MOBILE && <Landscape/>}
                    {main()}
                </section>
            </>
        </ThemeProvider>
    );
});

/**
 * 히스토리 제어
 * @constructor
 */
const ControlHistory = React.memo(() => {
    const location = useLocation();
    const history = useHistory();

    const setLang = useLangStore((state) => state.setLang);
    const lang = useLangStore((state) => state.lang);
    const pathname = useHistoryStore((state) => state.pathname);
    const pushHistory = useHistoryStore((state) => state.pushHistory);
    const isFirst = useRef(true);

    const [utm_source, setUtmSource] = useState("");
    const [utm_medium, setUtmMedium] = useState("");
    const [utm_content, setUtmContent] = useState("");
    const [utm_campaign, setUtmCampaign] = useState("");

    useLayoutEffect(() => {
        const query = qs.parse(location.search, {
            ignoreQueryPrefix: true,
        });

        if (query.utm_source) setUtmSource(query.utm_source);
        if (query.utm_medium) setUtmMedium(query.utm_medium);
        if (query.utm_content) setUtmContent(query.utm_content);
        if (query.utm_campaign) setUtmCampaign(query.utm_campaign);

        if (query.lang) {
            if (query.lang.startsWith("es")) {
                setLang("es"); //스페인어
            } else if (query.lang.startsWith("kr")) {
                setLang("kr"); //한국어
            } else {
                setLang("en"); //영어
            }
        } else {
            setLang("en"); //영어
        }
        if (query.pathname) {
            pushHistory(query.pathname);
        } else {
            pushHistory("");
        }
    }, [location]);

    //버튼 클릭 시 url이 바뀌도록 하는 부분
    useEffect(() => {
        if (lang) {

            //GA 페이지뷰
            let result =
                ROOT_PATH_PREFIX + `/index.html?pathname=${pathname}&lang=${lang}`;
            if (utm_source) result += "&utm_source=" + utm_source;
            if (utm_medium) result += "&utm_medium=" + utm_medium;
            if (utm_content) result += "&utm_content=" + utm_content;
            if (utm_campaign) result += "&utm_campaign=" + utm_campaign;

            let exhibitionType: string | undefined = undefined;
            if (pathname.indexOf("home") > -1) exhibitionType = "lg_home";
            else if (pathname.indexOf("objetcollection") > -1)
                exhibitionType = "lg_objet";
            else if (pathname.indexOf("thinq") > -1) exhibitionType = "lg_thinq";


            let isSend = false;
            if (IS_MOBILE && IS_APPLE_DEVICE) {
                isSend = true;
            } else if (IS_APPLE_DEVICE && !IS_CHROME) {
                isSend = true;
            } else {
                if (!isFirst.current) isSend = true;
            }

            if (isSend) {
                GA_PageView(
                    pathname === "" || pathname === "/" ? "/main" : pathname,
                    result,
                    {
                        dimension3: lang as string,
                        dimension4: exhibitionType,
                    }
                );
            }
            isFirst.current = false;

            history.push(result);
        }
    }, [pathname, lang]);

    return <></>;
});

export default App;
