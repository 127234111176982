/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {TextureLoader} from "three";
import {useEquirectEXR} from "../../../../utils/useEquirect";
import {GLTFLoader} from "three-stdlib";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {useKTXLoader} from "../../../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        f_window_frame005: THREE.Mesh
        glass005: THREE.Mesh
        c_props_books005: THREE.Mesh
        d_shelf_01010: THREE.Mesh
        e_wall_01_deco011: THREE.Mesh
        d_ceiling005: THREE.Mesh
        a_sofa_module_02007: THREE.Mesh
        d_ceiling_module006: THREE.Mesh
        e_floor005: THREE.Mesh
        b_plant_leaves_02005: THREE.Mesh
        b_props_module_02005: THREE.Mesh
        b_plant_leaves_01005: THREE.Mesh
        a_sofa_module_01005: THREE.Mesh
        a_sofa_module_03005: THREE.Mesh
        a_sofa_module_03001: THREE.Mesh
        e_wall_01_deco014: THREE.Mesh
        d_shelf_01015: THREE.Mesh
        c_props_module_01006: THREE.Mesh
        b_plant_leaves_03005: THREE.Mesh
    }
    materials: {
        mat_window_frame: THREE.MeshStandardMaterial
        mat_glass: THREE.MeshStandardMaterial
        mat_books: THREE.MeshStandardMaterial
        mat_dark_plaster_wall: THREE.MeshStandardMaterial
        mat_light_green_plaster_wall: THREE.MeshStandardMaterial
        mat_ceiling: THREE.MeshStandardMaterial
        mat_sofa_module_02: THREE.MeshStandardMaterial
        ['mat_ceiling_module.002']: THREE.MeshStandardMaterial
        mat_floor: THREE.MeshStandardMaterial
        mat_plant_leaves_02: THREE.MeshStandardMaterial
        mat_props_module_02: THREE.MeshStandardMaterial
        mat_plant_leaves_01: THREE.MeshStandardMaterial
        mat_sofa_01: THREE.MeshStandardMaterial
        mat_sofa_module_03: THREE.MeshStandardMaterial
        mat_white_wood_wall: THREE.MeshStandardMaterial
        ['mat_wood_wall.001']: THREE.MeshStandardMaterial
        mat_props_module_01: THREE.MeshStandardMaterial
        mat_plant_leaves_03: THREE.MeshStandardMaterial
    }
}


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/m/living_warm_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/living_warm_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_DIFFUSE_m.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_DIFFUSE.jpg');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_AO_m.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_Environment_01.exr';


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);
    const aoTex = useLoader(TextureLoader, AO_URL);
    const envTex = useEquirectEXR(ENV_URL);

    const gl = useThree(state => state.gl);
    const scene2 = useThree(state => state.scene);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        scene2.environment = envTex;
        gl.toneMappingExposure = 1.2;

        setupScene(scene, lightmapTex, aoTex);
        materials.mat_dark_plaster_wall.lightMapIntensity = 2;
        materials.mat_floor.aoMapIntensity = 1;
        materials.mat_floor.lightMapIntensity = 2;
        materials.mat_sofa_module_03.lightMapIntensity = 2;
        materials.mat_sofa_module_02.lightMapIntensity = 2;
        materials['mat_wood_wall.001'].lightMapIntensity = 2;
        materials.mat_props_module_01.lightMapIntensity = 2;
        materials.mat_ceiling.lightMapIntensity = 2;
        materials['mat_ceiling_module.002'].lightMapIntensity = 2;

    }, [lightmapTex, envTex, aoTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);

            lightmapTex.dispose();
            aoTex.dispose();
            envTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            // //@ts-ignore
            // useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            // useLoader.clear(TextureLoader, AO_URL)
            // //@ts-ignore
            // useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} dispose={null}>
            <mesh geometry={nodes.f_window_frame005.geometry} material={materials.mat_window_frame} />
            <mesh geometry={nodes.glass005.geometry} material={materials.mat_glass} />
            <mesh geometry={nodes.c_props_books005.geometry} material={materials.mat_books} />
            <mesh geometry={nodes.d_shelf_01010.geometry} material={materials.mat_dark_plaster_wall} />
            <mesh
                geometry={nodes.e_wall_01_deco011.geometry}
                material={materials.mat_light_green_plaster_wall}
            />
            <mesh geometry={nodes.d_ceiling005.geometry} material={materials.mat_ceiling} />
            <mesh geometry={nodes.a_sofa_module_02007.geometry} material={materials.mat_sofa_module_02} />
            <mesh
                geometry={nodes.d_ceiling_module006.geometry}
                material={materials['mat_ceiling_module.002']}
            />
            <mesh geometry={nodes.e_floor005.geometry} material={materials.mat_floor} />
            <mesh
                geometry={nodes.b_plant_leaves_02005.geometry}
                material={materials.mat_plant_leaves_02}
            />
            <mesh
                geometry={nodes.b_props_module_02005.geometry}
                material={materials.mat_props_module_02}
            />
            <mesh
                geometry={nodes.b_plant_leaves_01005.geometry}
                material={materials.mat_plant_leaves_01}
            />
            <mesh geometry={nodes.a_sofa_module_01005.geometry} material={materials.mat_sofa_01} />
            <mesh
                geometry={nodes.a_sofa_module_03005.geometry}
                material={nodes.a_sofa_module_03005.material}
            />
            <mesh
                geometry={nodes.a_sofa_module_03001.geometry}
                material={nodes.a_sofa_module_03001.material}
                position={[-3.5823, 0.7553, -2.4954]}
                rotation={[0, -1.0908, 0]}
            />
            <mesh geometry={nodes.e_wall_01_deco014.geometry} material={materials.mat_white_wood_wall} />
            <mesh geometry={nodes.d_shelf_01015.geometry} material={materials['mat_wood_wall.001']} />
            <mesh
                geometry={nodes.c_props_module_01006.geometry}
                material={materials.mat_props_module_01}
            />
            <mesh
                geometry={nodes.b_plant_leaves_03005.geometry}
                material={materials.mat_plant_leaves_03}
            />
        </group>
    )
}
