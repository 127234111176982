/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from '../../components/common/const'
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
  nodes: {
    Body: THREE.Mesh
    UI: THREE.Mesh
  }
  materials: {
    mat_body: THREE.MeshStandardMaterial
    mat_ui: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/m/dualcool_air_purifier_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/dualcool_air_purifier_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_conditioner/dualcool_air_purifier_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  //@ts-ignore
  const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

  useLayoutEffect(()=>{
      materials.mat_body.envMapIntensity = 2;
  }, []);

  return (
      <group ref={group} {...props} dispose={null}>
        <mesh
            geometry={nodes.Body.geometry}
            material={materials.mat_body}
            userData={{ name: 'Body' }}
        />
        <mesh geometry={nodes.UI.geometry} material={materials.mat_ui} userData={{ name: 'UI' }} />
      </group>
  )
}
