/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Mesh005: THREE.Mesh
        Mesh005_1: THREE.Mesh
        FX_Dryer_Body_White: THREE.Mesh
        FX_Dryer_Front_Black: THREE.Mesh
        FX_Dryer_Front_White: THREE.Mesh
        FX_Dryer_Body_Black: THREE.Mesh
        FX_Dryer_Front001: THREE.Mesh
        FX_Washer_Inside: THREE.Mesh
        FX_Dryer_Case_Black: THREE.Mesh
        FX_Dryer_Case001: THREE.Mesh
        FX_Dryer_Case_White: THREE.Mesh
        FX_Dryer_Inside: THREE.Mesh
        FX_Washer_UI: THREE.Mesh
        FX_Dryer_UI: THREE.Mesh
        Mesh007: THREE.Mesh
        Mesh007_1: THREE.Mesh
        Mesh007_2: THREE.Mesh
        Mesh007_3: THREE.Mesh
    }
    materials: {
        Glass: THREE.MeshStandardMaterial
        ['Glass.005']: THREE.MeshStandardMaterial
        FX_Dryer_Body_White: THREE.MeshStandardMaterial
        FX_Dryer_Front_Black: THREE.MeshStandardMaterial
        FX_Dryer_Front_White: THREE.MeshStandardMaterial
        FX_Dryer_Body_Black: THREE.MeshStandardMaterial
        ['Inside.005']: THREE.MeshStandardMaterial
        FX_Dryer_Case_Black: THREE.MeshStandardMaterial
        FX_Dryer_Case_White: THREE.MeshStandardMaterial
        Inside: THREE.MeshStandardMaterial
        ['UI.005']: THREE.MeshStandardMaterial
        UI: THREE.MeshStandardMaterial
        Case_Decal: THREE.MeshStandardMaterial
        Front_Decal: THREE.MeshStandardMaterial
        ['case.006']: THREE.MeshStandardMaterial
        ['Front.006']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/m/fx_dryer_set_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/washing_machine/fx_dryer_set_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/models/products/washing_machine/fx_dryer_set_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const part1Ref = useRef<THREE.Mesh>(null!);
    const part2Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'fx') {
            if (selectedColorIndex === 0) {
                part1Ref.current.material = materials.FX_Dryer_Case_Black
                part2Ref.current.material = materials.FX_Dryer_Body_Black;
            } else if (selectedColorIndex === 1) {
                part1Ref.current.material = materials.FX_Dryer_Case_White
                part2Ref.current.material = materials.FX_Dryer_Body_White;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        materials.Inside.metalness = 1;
        materials.Inside.roughness = 0;
        materials['Inside.005'].metalness = 1;
        materials['Inside.005'].roughness = 0;
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0, 0, 0.5]}>
            <group userData={{ name: 'FX_Dryer_Glass' }}>
                <mesh geometry={nodes.Mesh005.geometry} material={materials.Glass} />
                <mesh geometry={nodes.Mesh005_1.geometry} material={materials['Glass.005']} />
            </group>
            <mesh
                geometry={nodes.FX_Dryer_Body_White.geometry}
                material={materials.FX_Dryer_Body_White}
                position={[0.2417, 0, -2.2425]}
                userData={{ name: 'FX_Dryer_Body_White' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_Front_Black.geometry}
                material={materials.FX_Dryer_Front_Black}
                position={[0, 0, -2.0232]}
                userData={{ name: 'FX_Dryer_Front_Black' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_Front_White.geometry}
                material={materials.FX_Dryer_Front_White}
                position={[0, 0, -2.2425]}
                userData={{ name: 'FX_Dryer_Front_White' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_Body_Black.geometry}
                material={nodes.FX_Dryer_Body_Black.material}
                position={[0.2417, 0, -2.0232]}
                userData={{ name: 'FX_Dryer_Body_Black' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_Front001.geometry}
                material={nodes.FX_Dryer_Front001.material}
                userData={{ name: 'FX_Dryer_Front.001' }}
                ref={part2Ref}
            />
            <mesh
                geometry={nodes.FX_Dryer_Inside.geometry}
                material={materials.Inside}
                userData={{ name: 'FX_Dryer_Inside' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_Case_Black.geometry}
                material={nodes.FX_Dryer_Case_Black.material}
                position={[-0.2577, 0, -2.0232]}
                userData={{ name: 'FX_Dryer_Case_Black' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_Case001.geometry}
                material={nodes.FX_Dryer_Case001.material}
                userData={{ name: 'FX_Dryer_Case.001' }}
                ref={part1Ref}
            />
            <mesh
                geometry={nodes.FX_Dryer_Case_White.geometry}
                material={materials.FX_Dryer_Case_White}
                position={[-0.2577, 0, -2.2425]}
                userData={{ name: 'FX_Dryer_Case_White' }}
            />
            <mesh
                geometry={nodes.FX_Washer_Inside.geometry}
                material={materials['Inside.005']}
                position={[0.0118, 0, -0.0104]}
                userData={{ name: 'FX_Washer_Inside' }}
            />
            <mesh
                geometry={nodes.FX_Dryer_UI.geometry}
                material={materials.UI}
                position={[-0.0007, -0.001, -0.0037]}
                userData={{ name: 'FX_Dryer_UI' }}
            />
            <mesh
                geometry={nodes.FX_Washer_UI.geometry}
                material={materials['UI.005']}
                position={[0.0159, -0.001, -0.0367]}
                userData={{ name: 'FX_Washer_UI' }}
            />
            <group userData={{ name: 'FX_Dryer_Case_Decal' }}>
                <mesh geometry={nodes.Mesh007.geometry} material={materials.Case_Decal} />
                <mesh geometry={nodes.Mesh007_1.geometry} material={materials.Front_Decal} />
                <mesh geometry={nodes.Mesh007_2.geometry} material={materials['case.006']} />
                <mesh geometry={nodes.Mesh007_3.geometry} material={materials['Front.006']} />
            </group>
        </group>
    )
}
