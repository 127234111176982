/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/**
 * CW Lupin2 Dual Fuel S/in Single Better+
 * */
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {useKTXLoader} from "../../utils/useKTXLoader";
import { disposeScene } from '../../utils/disposeScene';
import { useLoader } from '@react-three/fiber';

type GLTFResult = GLTF & {
  nodes: {
    MetalBlack: THREE.Mesh
    MetalSilver: THREE.Mesh
    Lupin_dual: THREE.Mesh
    Lupin2_Slide_in_Best002: THREE.Mesh
    Lupin2_Slide_in_Best003: THREE.Mesh
    LG_LOGO_DECAL001: THREE.Mesh
    Lupin_Gas_Single: THREE.Mesh
    Mesh058: THREE.Mesh
    Mesh058_1: THREE.Mesh
    Object001: THREE.Mesh
  }
  materials: {
    MetalBlack: THREE.MeshStandardMaterial
    MetalSilver: THREE.MeshStandardMaterial
    ['Decal.001']: THREE.MeshStandardMaterial
    LG_Logo_Decal: THREE.MeshStandardMaterial
    ['mat_black.001']: THREE.MeshStandardMaterial
    ['Oven_05_Albedo.020']: THREE.MeshStandardMaterial
    ['Black-Board']: THREE.MeshStandardMaterial
    Map: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/oven/lupin_dual_new.glb';
// const GLTF_URL = IS_MOBILE ?
//     (ROOT_PATH_PREFIX + '/RP/products/oven/m/lupin_dual_ktx2.glb') :
//     (ROOT_PATH_PREFIX + '/RP/products/oven/lupin_dual_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  //@ts-ignore
  const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult

  const mesh1_Ref = useRef<THREE.Mesh>(null!);
  const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
  const selectedProduct = useProductStore(state => state.selectedProduct);

  useEffect(()=>{
    if(selectedProduct?.modelNo === 'lupin_dual'){
      materials.MetalBlack.color.setRGB(0.15, 0.15, 0.15);
      if(selectedColorIndex === 1){
        mesh1_Ref.current.material = materials.MetalSilver;
      }
      else{
        mesh1_Ref.current.material = materials.MetalBlack;
      }
      // mesh1_Ref.current.material.needsUpdate = true;
    }
  }, [selectedProduct, selectedColorIndex]);

  useEffect(() => {
    return () => {
      disposeScene(scene);
      useLoader.clear(GLTFLoader, GLTF_URL);
    }
  }, []);

  return (
      <group ref={group} {...props} position={[-0.0065, -0.06, 0.32]} scale={[1.005,1,1]}>
        <mesh geometry={nodes.MetalBlack.geometry} material={nodes.MetalBlack.material} />
        <mesh geometry={nodes.MetalSilver.geometry} material={nodes.MetalSilver.material} />
        <mesh
            geometry={nodes.Lupin_dual.geometry}
            material={materials['Decal.001']}
            position={[0, 0.8805, -0.0945]}
            rotation={[-0.1401, 0, 0]}>
          <mesh
              geometry={nodes.Lupin2_Slide_in_Best002.geometry}
              material={nodes.Lupin2_Slide_in_Best002.material}
              position={[0.0083, -0.8662, -0.0294]}
              rotation={[0.1401, 0, 0]}
              scale={[0.01, 0.0098, 0.01]}
              ref={mesh1_Ref}
          />
          <mesh
              geometry={nodes.Lupin2_Slide_in_Best003.geometry}
              material={nodes.Lupin2_Slide_in_Best003.material}
              position={[0.0083, -0.8662, -0.0294]}
              rotation={[0.1401, 0, 0]}
              scale={[0.01, 0.0098, 0.01]}
          />
          <mesh
              geometry={nodes.LG_LOGO_DECAL001.geometry}
              material={materials.LG_Logo_Decal}
              position={[0, -0.6264, -0.0704]}
              rotation={[1.7109, 0, 0]}
              scale={[0.075, 0.9992, 0.0734]}
          />
          <mesh
              geometry={nodes.Lupin_Gas_Single.geometry}
              material={materials['mat_black.001']}
              position={[0.0154, 0.0904, -0.2977]}
              rotation={[0.1401, 0, 0]}
          />
          <group
              position={[0.0083, -0.8662, -0.0294]}
              rotation={[0.1401, 0, 0]}
              scale={[0.01, 0.0098, 0.01]}>
            <mesh geometry={nodes.Mesh058.geometry} material={materials['Oven_05_Albedo.020']} />
            <mesh geometry={nodes.Mesh058_1.geometry} material={materials['Black-Board']} />
          </group>
          <mesh
              geometry={nodes.Object001.geometry}
              material={materials.Map}
              position={[0.0083, -0.8662, -0.0294]}
              rotation={[0.1401, 0, 0]}
              scale={[0.0101, 0.0098, 0.01]}
          />
        </mesh>
      </group>
  )
}
