import {ProductsDataItemType} from "./productsData";
import Atom_4d from "../../products/water_purifier/Atom_4d";
import Atom_u from "../../products/water_purifier/Atom_u";


/**
 * 정수기 데이터
 */
const productsData_WaterPurifier: ProductsDataItemType = {
    category: "water_purifier",
    ga_id: "water_care",
    label: "category_water_purifier",
    category_img_url: "/images/products/water_purifier/category_img.png",
    category_thumb_url: "/images/products/water_purifier/category_thumb.png",
    category_glow_url: "/images/products/water_purifier/category_glow.png",
    roomType: "kitchen",
    usp_transition: "/movies/usp/transition/main_WP_000_00000.webm",
    productList: [
        {
            name: "ATOM 4D+",
            modelNo: "atom_4d",
            ga_id: "atom_4d+",
            isCES: true,
            thumb_url: "/images/products/water_purifier/atom_4d/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_silver",
                    color: "/images/products/water_purifier/atom_4d/color_silver.png",
                    ga_id: "silver",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/water_purifier/atom_4d/color_white.png",
                    ga_id: "white",
                },
                {
                    name: <></>,
                    label: "home_exp_color_navy",
                    color: "/images/products/water_purifier/atom_4d/color_navy.png",
                    ga_id: "navy",
                },
            ],
            model: <Atom_4d/>,
        },
        {
            name: "ATOM U STS",
            modelNo: "atom_u",
            ga_id: "atom_u_sts",
            isCES: true,
            thumb_url: "/images/products/water_purifier/atom_u/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_silver",
                    color: "/images/products/water_purifier/atom_u/color_silver.png",
                    ga_id: "silver",
                },
            ],
            model: <Atom_u/>,
        },
    ],
};

export default productsData_WaterPurifier;
