import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "../../common/comps_styled/Container.styled";
import AwardsItem from "./AwardsItem";
import { Text } from "../../common/comps_styled/Text.styled";
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/pagination/pagination.min.css";
import "../../../styles/swiper.awards.css";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../../common/const";
import { Image } from "../../common/comps_styled/Image.styled";
import theme from "../../../styles/theme.styled";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";

const AwardsContents = () => {
  const [swiper, setSwiper] = useState<any>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [reload, setReload] = useState(false);
  const resize = useResizeStore((state) => state.resize);

  useEffect(() => {
    setTimeout(() => {
      setReload(true);
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }, []);

  return (
    <Wrapper>
      <NavigationContainer
        selectedIndex={selectedIndex}
        pageTotal={swiper?.slides.length}
      >
        {selectedIndex !== 0 && (
          <PrevButton
            ratio={resize.ratio}
            onClick={() => {
              swiper?.slidePrev();
              GA_Event(
                "ha_virtual_studio",
                "best_of_innovation_menu",
                "previous"
              );
            }}
          >
            <Image
              alt="previous"
              role="img"
              src={ROOT_PATH_PREFIX + "/images/icons/svg/btn_scroll_left.svg"}
            />
          </PrevButton>
        )}
        {selectedIndex !== swiper?.slides.length - 1 && (
          <NextButton
            ratio={resize.ratio}
            onClick={() => {
              swiper?.slideNext();
              GA_Event("ha_virtual_studio", "best_of_innovation_menu", "next");
            }}
          >
            <Image
              alt="next"
              role="img"
              src={ROOT_PATH_PREFIX + "/images/icons/svg/btn_scroll_right.svg"}
            />
          </NextButton>
        )}
      </NavigationContainer>

      <Container>
        <InnerContainer style={{ flex: 1 }}>
          <Image
            alt="title"
            role="img"
            src={ROOT_PATH_PREFIX + "/images/innovation_awards/award_title.png"}
          />
        </InnerContainer>
        <Swiper
          style={{
            width: "100%",
          }}
          spaceBetween={34}
          slidesPerView={1}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onSlideChange={(e) => {
            setSelectedIndex(e.activeIndex);
          }}
        >
          <SwiperSlide>
            <InnerContainer>
              <AwardsItem
                model="product_01"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_01.png"
                }
              />
              <AwardsItem
                model="product_02"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_02.png"
                }
              />
              <AwardsItem
                showButton={false}
                model="product_03"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_03.png"
                }
              />
            </InnerContainer>
          </SwiperSlide>
          <SwiperSlide>
            <InnerContainer>
              <AwardsItem
                model="product_04"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_04.png"
                }
              />
              <AwardsItem
                model="product_05"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_05.png"
                }
              />
              <AwardsItem
                model="product_06"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_06.png"
                }
              />
            </InnerContainer>
          </SwiperSlide>
          <SwiperSlide>
            <InnerContainer2>
              <AwardsItem
                model="product_07"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_07.png"
                }
              />
              <AwardsItem
                model="product_08"
                modelPath={
                  ROOT_PATH_PREFIX +
                  "/images/innovation_awards/award_product_08.png"
                }
              />
            </InnerContainer2>
          </SwiperSlide>
        </Swiper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #fff;
  /* font-size: 2rem; */
  display: flex;
  /* position: relative; */
  /* border: 1px solid red; */
`;

// const Title = styled(Text)`
//   position: absolute;
//   font-size: 270%;
//   top: 4%;
//   width: 100%;
//   filter: drop-shadow(0 0 0.3rem ${({ theme }) => theme.colors.common.gold});
// `;

const InnerContainer = styled(Container)`
  position: relative;
  flex-direction: row;
  left: 6%;
  padding: 0 0.4%;
  width: 88%;
  height: 100%;
  /* border: 1px solid red; */
`;

const InnerContainer2 = styled(InnerContainer)`
  padding: 0 15%;
  /* border: 1px solid; */
`;

const InnerContainer1 = styled(InnerContainer)`
  padding: 0 29.4%;
`;

const NavigationContainer = styled.div<{
  selectedIndex?: number;
  pageTotal: number;
}>`
  pointer-events: none;
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  display: flex;
  justify-content: ${({ selectedIndex, pageTotal }) =>
    selectedIndex !== 0 && selectedIndex !== pageTotal - 1
      ? "space-between"
      : selectedIndex === 0
      ? "flex-end"
      : "flex-start"};
  align-items: center;
  width: 100%;
  height: 100%;
`;

const PrevButton = styled.button<{ ratio: number }>`
  display: flex;
  align-items: center;
  margin: ${IS_MOBILE ? 1.5 : 3}%;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 5 : 3)}em;
  height: auto;
  background-color: transparent;
  cursor: pointer;
  pointer-events: visible;
  /* border: 1px solid white; */
`;

const NextButton = styled(PrevButton)``;

export default AwardsContents;

/*
<Wrapper>
      <NavigationContainer
        selectedIndex={selectedIndex}
        pageTotal={swiper?.slides.length}
      >
        {selectedIndex !== 0 && (
          <PrevButton
            onClick={() => {
              swiper?.slidePrev();
            }}
          >
            <Image src="/images/icons/svg/btn_scroll_left.svg" />
          </PrevButton>
        )}
        {selectedIndex !== swiper?.slides.length - 1 && (
          <NextButton
            onClick={() => {
              swiper?.slideNext();
            }}
          >
            <Image src="/images/icons/svg/btn_scroll_right.svg" />
          </NextButton>
        )}
      </NavigationContainer>
      <Container>
        <Title color={theme.colors.common.gold} align="center">
          CES 2022 Innovation Awards
        </Title>

        <Swiper
          style={{
            width: "100%",
            height: "100%",
          }}
          spaceBetween={34}
          slidesPerView={1}
          onSwiper={(swiper) => {
            setSwiper(swiper);
          }}
          onSlideChange={(e) => {
            setSelectedIndex(e.activeIndex);
          }}
          id={"Swiper-" + Math.floor(Math.random() * 500)}
        >
          {tempArr.map((value, index) => {
            return (
              <SwiperSlide id={"slide-" + Math.floor(Math.random() * 500)} key={index}>
                <InnerContainer>
                  <AwardsItem
                    modelPath="/images/innovation_awards/img_award_01@2x.png"
                    titlePath="/images/innovation_awards/title_award_01@2x.png"
                    contents={datas}
                  />
                  <AwardsItem
                    modelPath="/images/innovation_awards/img_award_02@2x.png"
                    titlePath="/images/innovation_awards/title_award_02@2x.png"
                    contents={datas}
                  />
                  <AwardsItem
                    modelPath="/images/innovation_awards/img_award_03@2x.png"
                    titlePath="/images/innovation_awards/title_award_03@2x.png"
                    contents={datas}
                  />
                </InnerContainer>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </Wrapper>
*/
