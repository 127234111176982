import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import styled from "styled-components";
import useProductStore, { ProductState } from "../../../stores/productStore";
import theme from "../../../styles/theme.styled";
import {
  Center,
  Container,
  ListContainer,
} from "../../common/comps_styled/Container.styled";
import { Divider } from "../../common/comps_styled/Divider.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { Image } from "../../common/comps_styled/Image.styled";
import { getLangString } from "../../../api/lang/langData";
import useLangStore from "../../../stores/langStore";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import useResizeStore from "../../../stores/resizeStore";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";
import { GA_Event } from "../../../api/ga";

/**
 * 제품 선택 UI
 * @constructor
 */

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const selectedCategorySelector = (state: ProductState) =>
  state.selectedCategory;
const selectedProductSelector = (state: ProductState) => state.selectedProduct;
const selectProductSelector = (state: ProductState) => state.api.selectProduct;

const SelectProduct = ({ ...rest }) => {
  const selectedCategory = useProductStore(selectedCategorySelector); //선택된 카테고리 데이터
  const selectedProduct = useProductStore(selectedProductSelector); //선택된 제품 데이터
  const selectProduct = useProductStore(selectProductSelector); //제품 선택
  const lang = useLangStore((state) => state.lang);
  const resize = useResizeStore((state) => state.resize);

  // for GAEvent
  const [clickStatus, setClickStatus] = useState(false);
  const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);
  const ga_model = useProductStore((state) => state.selectedProduct?.ga_id);
  const ga_interior = useSpaceThemeStore((state) => state.lgHomeTheme);
  const ga_isNew = useProductStore((state) =>
    state.selectedProduct?.isCES ? "y" : "n"
  );
  const colorIndex = useProductStore((state) => state.selectedColorIndex);
  const ga_color = useMemo(
    () => selectedProduct?.colors[colorIndex].ga_id,
    [colorIndex, selectedProduct]
  );
  //
  useEffect(() => {
    GA_Event("lg_home", `${ga_product}_model`, ga_model ?? "", {
      dimension5: ga_product,
      dimension6: ga_interior,
      dimension7: ga_model,
      dimension8: ga_isNew,
      dimension9: ga_color,
    });
  }, [ga_model, clickStatus]);
  //

  // useLayoutEffect(() => {
  //   console.log("현재 선택된 카테고리 데이터: ", selectedCategory, "\n현재 선택된 제품 데이터: ", selectedProduct);
  // }, [selectedCategory, selectedProduct]);

  return (
    <Container {...rest}>
      <Text
        size={`${(IS_MOBILE ? 3.5 : 2.8) * resize.ratio}em`}
        weight={theme.font.weight.semibold}
        style={{ paddingLeft: "2%" }}
      >
        {getLangString(lang, "home_exp_product")?.text}
      </Text>
      <Divider
        borderwidth={2 * resize.ratio}
        style={{ marginTop: "2%", marginBottom: IS_MOBILE ? "2.5%" : "3.5%" }}
      />
      <ListContainer>
        {selectedCategory &&
          selectedCategory.productList &&
          selectedProduct &&
          selectedCategory.productList.map((value, index) => {
            return (
              <div
                key={index}
                style={{
                  flexDirection: "column",
                  width: "31%",
                  margin: "0 1%",
                }}
              >
                <Center
                  style={{
                    height: "15%",
                    marginBottom: "3%",
                  }}
                >
                  <Image
                    alt="CES indicator"
                    role="img"
                    src={
                      // ROOT_PATH_PREFIX + "/images/icons/svg/icon_crs2022.svg"
                      ROOT_PATH_PREFIX + "/images/icons/icon_crs2022@2x.png"
                    }
                    size={IS_MOBILE ? "100%" : "85%"}
                    style={{ opacity: value?.isCES ? 1 : 0 }}
                  />
                </Center>

                <ProductButton
                  ratio={resize.ratio}
                  style={{
                    height: IS_MOBILE ? `${resize.ratio * 12.5}em` : "none",
                  }}
                  selected={value.name === selectedProduct.name}
                  onClick={() => {
                    selectProduct(value);
                    setClickStatus(!clickStatus);
                  }}
                >
                  <Image
                    alt={value.name}
                    role="img"
                    src={ROOT_PATH_PREFIX + value.thumb_url}
                    // title={value.name}
                    // size={`${resize.ratio * (IS_MOBILE ? 180 : 100)}%`}
                  />
                </ProductButton>
              </div>
            );
          })}
      </ListContainer>
    </Container>
  );
};

const ProductButton = styled.button<{ ratio: number; selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  cursor: pointer;
  pointer-events: visible;
  background-color: transparent;

  outline: ${({ selected, theme, ratio }) =>
    selected
      ? `${ratio * (IS_MOBILE ? 4 : 3)}px solid ${theme.colors.home.pink}`
      : `none`}!important;

  &:hover {
    outline: ${({ theme, ratio }) =>
      `${ratio * (IS_MOBILE ? 4 : 3)}px solid ${
        theme.colors.home.pink
      }`} !important;
  }

  &:focus-visible {
    outline: ${({ theme, ratio }) =>
      `${ratio * (IS_MOBILE ? 4 : 3)}px solid ${
        theme.colors.home.pink
      }`} !important;
  }
`;

export default SelectProduct;
