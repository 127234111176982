/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {disposeScene} from "../../utils/disposeScene";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        black: THREE.Mesh
        Body: THREE.Mesh
        white: THREE.Mesh
        UI: THREE.Mesh
        dark_blue: THREE.Mesh
        rose_gold: THREE.Mesh
    }
    materials: {
        black: THREE.MeshStandardMaterial
        white: THREE.MeshStandardMaterial
        mat_ui: THREE.MeshStandardMaterial
        dark_blue: THREE.MeshStandardMaterial
        rose_glod: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/air_care/m/puricare_mini_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_care/puricare_mini_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_care/puricare_mini_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    const bodyRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    //색상 변경 시
    useEffect(() => {
        if (selectedProduct?.modelNo === 'puricare_mini') {
            if (selectedColorIndex === 0) {
                bodyRef.current.material = materials.white;
            } else if (selectedColorIndex === 1) {
                materials.black.map = null;
                materials.black.color.setRGB(0.06, 0.06, 0.06);
                materials.black.needsUpdate = true;
                bodyRef.current.material = materials.black;
            }else if (selectedColorIndex === 2) {
                bodyRef.current.material = materials.rose_glod;
            }else if (selectedColorIndex === 3) {
                bodyRef.current.material = materials.dark_blue;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        bodyRef.current.material = materials.white;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.black.geometry}
                material={materials.black}
                position={[0.25, 1.08, 0]}
                scale={[0.02, 0.02, 0.02]}
                visible={false}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={nodes.Body.material}
                scale={[0.01, 0.01, 0.01]}
                ref={bodyRef}
            />
            <mesh
                geometry={nodes.white.geometry}
                material={nodes.white.material}
                position={[0.19, 1.08, 0]}
                scale={[0.02, 0.02, 0.02]}
                visible={false}
            />
            <mesh geometry={nodes.UI.geometry} material={materials.mat_ui} scale={[0.01, 0.01, 0.01]} />
            <mesh
                geometry={nodes.dark_blue.geometry}
                material={materials.dark_blue}
                position={[0.36, 1.08, 0]}
                scale={[0.02, 0.02, 0.02]}
                visible={false}
            />
            <mesh
                geometry={nodes.rose_gold.geometry}
                material={materials.rose_glod}
                position={[0.31, 1.08, 0]}
                scale={[0.02, 0.02, 0.02]}
                visible={false}
            />
        </group>
    )
}
