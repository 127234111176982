/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        UI_1: THREE.Mesh
        Body: THREE.Mesh
        UI_2: THREE.Mesh
    }
    materials: {
        ['Decal.001']: THREE.MeshStandardMaterial
        mat_body: THREE.MeshStandardMaterial
        ['Material.002']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/m/inverter_heat_pump_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/air_conditioner/inverter_heat_pump_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_conditioner/inverter_heat_pump_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    useLayoutEffect(()=>{
        materials.mat_body.envMapIntensity = 2;
        materials['Material.002'].envMapIntensity = 2;
        materials.mat_body.metalness = 1;
        materials.mat_body.roughness = 0.4;

    }, []);

    return (
        <group ref={group} {...props} position={[0,0,0.2]} dispose={null}>
            <mesh
                geometry={nodes.UI_1.geometry}
                material={materials['Decal.001']}
                userData={{ name: 'UI_1' }}
            />
            <mesh
                geometry={nodes.Body.geometry}
                material={materials.mat_body}
                userData={{ name: 'Body' }}
            />
            <mesh
                geometry={nodes.UI_2.geometry}
                material={materials['Material.002']}
                userData={{ name: 'UI_2' }}
            />
        </group>
    )
}
