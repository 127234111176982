import styled from "styled-components";
import { IS_MOBILE } from "../../../common/const";

export const ColorButton = styled.button<{ selected?: boolean }>`
  flex-direction: row;
  display: flex;
  flex: auto;
  align-items: center;
  cursor: pointer;
  pointer-events: visible;
  background-color: transparent;
  /* border: 1px solid tomato; */

  .circle {
    border-color: ${({ selected, theme }) =>
      selected && theme.colors.home.pink};
  }

  .text {
    color: ${({ selected, theme }) =>
      !IS_MOBILE && selected && theme.colors.home.pink};
  }

  &:hover {
    .circle {
      border-color: ${({ theme }) => theme.colors.home.pink};
    }
    .text {
      color: ${({ theme }) => !IS_MOBILE && theme.colors.home.pink};
    }
  }

  &:focus-visible {
    .circle {
      border-color: ${({ theme }) => theme.colors.home.pink};
    }
    .text {
      color: ${({ theme }) => !IS_MOBILE && theme.colors.home.pink};
    }
  }
`;

export const ColorCircle = styled.span<{ ratio: number }>`
  width: ${({ ratio }) => ratio * 3.8}em;
  height: ${({ ratio }) => ratio * 3.8}em;
  border-radius: ${({ ratio }) => (ratio * 3.8) / 2}em;
  border: ${({ ratio }) => ratio * (IS_MOBILE ? 4 : 3)}px solid black;
  margin-right: 10%;
  background-color: gray;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ColorRect = styled.span<{ ratio: number }>`
  display: flex;
  width: ${({ ratio }) => ratio * 16}em;
  height: ${({ ratio }) => ratio * 5}em;
  border-radius: ${({ ratio }) => ratio * 1}em;
  border: ${({ ratio }) => ratio * (IS_MOBILE ? 4 : 3)}px solid black;
  margin-right: 10%;
  background-color: gray;
  justify-content: center;
  align-items: center;

  .text {
    /* border: 1px solid white; */
  }
`;
