import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import useHistoryStore from "../../../stores/historyStore";
import {
  useModalSurveyCleaner,
  useModalSurveyStyler,
  useModalUSP,
  useModalVimeo,
} from "../../../stores/modalStore";
import useProductStore from "../../../stores/productStore";
import { Image } from "../../common/comps_styled/Image.styled";
import {
  IS_APPLE_DEVICE,
  IS_CHROME,
  IS_IPHONE,
  IS_MOBILE,
  ROOT_PATH_PREFIX,
  Z_INDEX_POPUP,
  Z_INDEX_TOP_UI,
} from "../../common/const";
import BalloonButton from "./BalloonButton";
import { getUspData, UspPageDataType } from "../../../api/usp/uspData";
import useLangStore from "../../../stores/langStore";
import AudioButton from "../../common/AudioButton";
import BackButton from "../../common/navigation/BackButton";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";

const USPBoard = (props: JSX.IntrinsicElements["div"]) => {
  const [showUSP, setShowUSP] = useState(false);
  const [hasSurvey, setHasSurvey] = useState(false);
  const [uspData, setUspData] = useState<UspPageDataType | null>(null);
  const lang = useLangStore((state) => state.lang);

  // survey
  const modalSurveyStylerSetOpen = useModalSurveyStyler(
    (state) => state.setIsOpen
  );
  const modalSurveyStylerOpen = useModalSurveyStyler((state) => state.isOpen);
  const modalSurveyStylerCompleted = useModalSurveyStyler(
    (state) => state.isCompleted
  );
  const modalSurveyCleanerSetOpen = useModalSurveyCleaner(
    (state) => state.setIsOpen
  );
  const modalSurveyCleanerOpen = useModalSurveyCleaner((state) => state.isOpen);
  const modalSurveyCleanerCompleted = useModalSurveyCleaner(
    (state) => state.isCompleted
  );
  // survey

  const pushHistory = useHistoryStore((state) => state.pushHistory);
  const pathname = useHistoryStore((state) => state.pathname);
  const setModalUSP = useModalUSP((state) => state.setIsOpen);
  const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
  const setVimeoId = useModalVimeo((state) => state.setId);
  const setVimeoGaId = useModalVimeo((state) => state.setGaId);
  const product = useProductStore((state) => state);
  const ratio = useResizeStore((state) => state.resize.ratio);

  // console.log(product.selectedCategory?.category);

  let transitionTime = useMemo(() => {
    if (IS_APPLE_DEVICE && !IS_CHROME) {
      setShowUSP(true);
      return 0;
    }

    let t = 0;
    switch (product.selectedCategory?.category) {
      case "tiiun_mini":
        t = 2.6;
        break;
      case "cooking":
        t = 2.1;
        break;
      case "dishwasher":
        t = 4;
        break;
      case "air_conditioner":
        t = 3.6;
        break;
      case "washer_n_dryer":
        t = 2.8;
        break;
      case "vacuum_cleaner":
        t = 1.9;
        break;
      default:
        t = 2.75;
        break;
    }
    return t;
  }, [product]);

  const uspURL: string =
    product?.selectedCategory?.usp_transition ??
    "/movies/usp/transition/main_AC_000_00000.webm";
  const videoRef = useRef<HTMLVideoElement>(null);
  const onPauseVideo = (video, listener) => {
    const ct = video.currentTime;
    if (ct > transitionTime) {
      setShowUSP(true);
      video.removeEventListener("timeupdate", listener);
    }
  };
  const onTimeUpdate = (e) => {
    onPauseVideo(e.currentTarget, onTimeUpdate);
  };

  const setVimeo = (id: string, ga_id?: string) => {
    setVimeoId(id);
    setVimeoGaId(ga_id ?? "");
    setModalVimeo(true);
  };

  useEffect(() => {
    if (product.selectedCategory) {
      if (product.selectedProduct?.modelNo) {
        // console.log(product);
        const usp = getUspData(
          product?.selectedCategory.category,
          product?.selectedProduct?.modelNo
        );
        setUspData(usp);
        // console.log(usp);
      }
    }

    videoRef.current?.addEventListener("timeupdate", onTimeUpdate);
    return () => {
      videoRef.current?.removeEventListener("timeupdate", onTimeUpdate);
    };
  }, []);

  useEffect(() => {
    if (uspData?.category === "tiiun") {
      const data = uspData?.uspList;
      if (data?.length !== 0) {
        //
        GA_Event(
          "lg_home",
          "indoor_gardening_appliance_tech",
          "simple_all-in-one"
        );
        //
        setVimeo(
          (data && data[0].videoUrl) ?? "",
          "indoor_gardening_appliance_tech,simple_all-in-one"
        );
        setModalUSP(false);
      }
    }
  }, [uspData]);

  const [resize, setResize] = useState({
    sizeRatio: 0,
    topMargin: 0,
    leftMargin: 0,
    sizeW: 0,
    sizeH: 0,
  });
  const [isScale, setIsScale] = useState(false);
  const [backgroundSize, setBackgroundSize] = useState("cover");
  const bgRatio = 1920 / 1080;
  function resizeHandler() {
    const ratio = window.innerWidth / window.innerHeight;

    if (isScale) {
      setBackgroundSize("cover");
      if (ratio > bgRatio) {
        const sizeW = window.innerWidth;
        const sizeH = window.innerWidth / bgRatio;
        const sizeRatio = sizeW / 1920;
        const topMargin = (window.innerHeight - sizeH) * 0.5;
        const leftMargin = 0;
        setResize({ sizeRatio, topMargin, leftMargin, sizeW, sizeH });
      } else {
        const sizeH = window.innerHeight;
        const sizeW = window.innerHeight * bgRatio;
        const sizeRatio = sizeH / 1080;
        const topMargin = 0;
        const leftMargin = (window.innerWidth - sizeW) * 0.5;
        setResize({ sizeRatio, topMargin, leftMargin, sizeW, sizeH });
      }
    } else {
      //가로로 더 길어진 경우, 세로에 맞추고 가로에 여백이 생김
      setBackgroundSize("contain");
      if (ratio > bgRatio) {
        const sizeH = window.innerHeight;
        const sizeW = window.innerHeight * bgRatio;
        const sizeRatio = sizeH / 1080;
        const topMargin = 0;
        const leftMargin = (window.innerWidth - sizeW) * 0.5;
        setResize({ sizeRatio, topMargin, leftMargin, sizeW, sizeH });
      }
      //세로로 더 길어진 경우, 가로에 맞추고 위아래에 여백이 생김
      else {
        const sizeW = window.innerWidth;
        const sizeH = window.innerWidth / bgRatio;
        const sizeRatio = sizeW / 1920;
        const topMargin = (window.innerHeight - sizeH) * 0.5;
        const leftMargin = 0;
        setResize({ sizeRatio, topMargin, leftMargin, sizeW, sizeH });
      }
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", resizeHandler);
    window.dispatchEvent(new Event("resize"));
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return (
    <>
      {!IS_APPLE_DEVICE && (
        <Wrapper showPointer={showUSP}>
          <video
            ref={videoRef}
            autoPlay
            muted
            style={{ objectFit: "cover" }}
            width="100%"
            height="100%"
            onEnded={(e) => {
              e.currentTarget.style.display = "none";
              // setModalUSP(false);
            }}
          >
            <source src={ROOT_PATH_PREFIX + uspURL} type="video/webm" />
          </video>
        </Wrapper>
      )}
      {showUSP && (
        <USPContainer>
          <div
            style={{
              zIndex: Z_INDEX_TOP_UI,
            }}
          >
            <BackButton
              onClickHandler={() => {
                if (hasSurvey) {
                  setModalUSP(false);
                  return;
                }
                //
                if (product.selectedProduct?.modelNo === "a9t") {
                  if (modalSurveyCleanerCompleted) {
                    setModalUSP(false);
                    return;
                  }
                  modalSurveyCleanerSetOpen(true);
                  setHasSurvey(true);
                } else if (
                  product.selectedProduct?.modelNo === "essence" ||
                  product.selectedProduct?.modelNo === "baron"
                ) {
                  if (modalSurveyStylerCompleted) {
                    setModalUSP(false);
                    return;
                  }
                  modalSurveyStylerSetOpen(true);
                  setHasSurvey(true);
                } else {
                  setModalUSP(false);
                }
              }}
            />
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              zIndex: Z_INDEX_TOP_UI,
              padding: `${ratio * 6.2}em ${ratio * (IS_MOBILE ? 14.5 : 6)}em`,
            }}
          >
            <AudioButton />
          </div>
          <BalloonContainer
            style={{
              width: resize.sizeW,
              height: resize.sizeH,
            }}
          >
            {uspData?.uspList?.map((data, index) => {
              let mainId = 0;
              let subId = 0;
              if (data.title.length > 2 && lang === "es") mainId = 2;
              if (data.title.length > 1 && lang === "kr") mainId = 1;
              if (data?.subMsg.length > 2 && lang === "es") subId = 2;
              if (data?.subMsg.length > 1 && lang === "kr") subId = 1;

              const main = data.title[mainId];
              const sub = data.subMsg[subId];
              //const px = `${(data.isRight ? 1250 : 165) * resize.sizeRatio}px`;
              const px = `${(data?.x ?? 0) * resize.sizeRatio}px`;
              const py = `${(data?.y ?? 0) * resize.sizeRatio}px`;

              return (
                <BalloonButton
                  key={index}
                  mainText={main}
                  subText={sub}
                  gaid={data.ga_id}
                  position={data.isRight ? "right" : "left"}
                  vimeoId={data.videoUrl}
                  x={px}
                  y={py}
                  sizeRatio={resize.sizeRatio}
                />
              );
            })}
          </BalloonContainer>
          <Image
            alt={uspData?.modelNo.replaceAll("_", " ")}
            role="img"
            src={ROOT_PATH_PREFIX + uspData?.bgImgUrl}
            resizemode={backgroundSize}
          />
        </USPContainer>
      )}
    </>
  );
};

const Wrapper = styled.div<{ showPointer?: boolean }>`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: ${({ showPointer }) => (showPointer ? "none" : "visible")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const USPContainer = styled(Wrapper)`
  z-index: ${Z_INDEX_POPUP - 1};
  background: rgba(0, 0, 0, 1);
  pointer-events: visible;
`;

const BalloonContainer = styled.div`
  position: absolute;
  /* & div + div {
    margin-left: 70%;
  }

  & div:last-child {
    bottom: -12.5rem;
  } */
`;

const BalloonBox = styled.div`
  position: relative;

  & > button {
    margin-bottom: 6%;
  }
`;

export default USPBoard;
