import React, {useEffect} from 'react';
import {useThree} from "@react-three/fiber";

const DebugGl = () => {
    const gl = useThree(state => state.gl);
    function keydownHandler(e:KeyboardEvent) {
        if(e.key === 'q' || e.key === 'Q')
        {
            console.log(gl.info);
        }
    }
    useEffect(()=>{
        window.addEventListener('keydown', keydownHandler);
        return () => {
            window.removeEventListener('keydown', keydownHandler);
        }
    }, [gl]);

    return (
        <group/>
    );
};

export default DebugGl;
