/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Box, Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {GLTFResult_ObjetModernProducts} from "./Product_Harvester";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {TextureLoader} from "three";
import {easeCubicInOut} from "d3-ease";
import {dryer_data, Objet_ColorCategoryType, washer_data} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {a} from "@react-spring/three";
import useCameraStore, {CameraState} from "../../../../stores/cameraStore";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";


const cameraApiSelector = (state: CameraState) => state.api;


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_WashTower_modern.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_WashTower_modern.jpg');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_bottom_Albedo_Beige.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_bottom_Albedo_Beige.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_bottom_Albedo_Bonatic.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_bottom_Albedo_Bonatic.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_bottom_Albedo_Brown.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_bottom_Albedo_Brown.jpg');
const COLOR_3_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_bottom_Albedo_Pink.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_bottom_Albedo_Pink.jpg');
const COLOR_4_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_bottom_Albedo_Red.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_bottom_Albedo_Red.jpg');

const COLOR_5_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_top_Albedo_Beige.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_top_Albedo_Beige.jpg');
const COLOR_6_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_top_Albedo_Bonatic.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_top_Albedo_Bonatic.jpg');
const COLOR_7_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_top_Albedo_Brown.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_top_Albedo_Brown.jpg');
const COLOR_8_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_top_Albedo_Pink.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_top_Albedo_Pink.jpg');
const COLOR_9_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/washtower/Wash_Tower_top_Albedo_Red.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/washtower/Wash_Tower_top_Albedo_Red.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult_ObjetModernProducts
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL,
        COLOR_8_URL, COLOR_9_URL
    ]);


    const colorCategory = useObjetColorStore(state => state.washer_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.washer_ColorLabel);

    const colorCategoryDryer = useObjetColorStore(state => state.dryer_ColorCategory);
    const colorLabelDryer = useObjetColorStore(state => state.dryer_ColorLabel);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isWasherSet, setIsWasherSet] = useState(false);
    const [isDryerSet, setIsDryerSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const [cameraTargetWorldPos] = useState(() => new THREE.Vector3());
    const [worldPos] = useState(() => new THREE.Vector3());
    const {setTargetPos, setLookPos} = useCameraStore(cameraApiSelector);
    const cameraTargetRef = useRef<THREE.Mesh>(null!);
    const lookTargetRef = useRef<THREE.Mesh>(null!);

    const selectPlusHandler = useCallback(() => {
        if (!cameraTargetRef.current) return;

        cameraTargetRef.current.getWorldPosition(cameraTargetWorldPos);
        lookTargetRef.current.getWorldPosition(worldPos);
        worldPos.add(new THREE.Vector3(0, 0, 0));
        setTargetPos(cameraTargetWorldPos);
        setLookPos(worldPos);
    }, []);

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsWasherSet(true);

        if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_beige') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials.mat_washtower_door_bottom.map = colorTextures[0];
            } else if (colorLabel === 'objet_color_green') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials.mat_washtower_door_bottom.map = colorTextures[1];
            } else if (colorLabel === 'objet_color_clay_brown') {
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials.mat_washtower_door_bottom.map = colorTextures[2];
            } else if (colorLabel === 'objet_color_pink') {
                colorTextures[3].flipY = false;
                colorTextures[3].encoding = THREE.sRGBEncoding;
                colorTextures[3].needsUpdate = true;
                materials.mat_washtower_door_bottom.map = colorTextures[3];
            } else if (colorLabel === 'objet_color_redwood') {
                colorTextures[4].flipY = false;
                colorTextures[4].encoding = THREE.sRGBEncoding;
                colorTextures[4].needsUpdate = true;
                materials.mat_washtower_door_bottom.map = colorTextures[4];
            }
        }
    }, []);

    const changeDryerTexture = useCallback((materialName: string, colorCategoryDryer?: Objet_ColorCategoryType, colorLabelDryer?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategoryDryer !== undefined && colorLabelDryer !== undefined && !isFirst) setIsDryerSet(true);

        if (colorCategoryDryer === 'Nature') {
            if (colorLabelDryer === 'objet_color_beige') {
                colorTextures[5].flipY = false;
                colorTextures[5].encoding = THREE.sRGBEncoding;
                colorTextures[5].needsUpdate = true;
                materials.mat_washtower_door_top.map = colorTextures[5];
            } else if (colorLabelDryer === 'objet_color_green') {
                colorTextures[6].flipY = false;
                colorTextures[6].encoding = THREE.sRGBEncoding;
                colorTextures[6].needsUpdate = true;
                materials.mat_washtower_door_top.map = colorTextures[6];
            } else if (colorLabelDryer === 'objet_color_clay_brown') {
                colorTextures[7].flipY = false;
                colorTextures[7].encoding = THREE.sRGBEncoding;
                colorTextures[7].needsUpdate = true;
                materials.mat_washtower_door_top.map = colorTextures[7];
            } else if (colorLabelDryer === 'objet_color_pink') {
                colorTextures[8].flipY = false;
                colorTextures[8].encoding = THREE.sRGBEncoding;
                colorTextures[8].needsUpdate = true;
                materials.mat_washtower_door_top.map = colorTextures[8];
            } else if (colorLabelDryer === 'objet_color_redwood') {
                colorTextures[9].flipY = false;
                colorTextures[9].encoding = THREE.sRGBEncoding;
                colorTextures[9].needsUpdate = true;
                materials.mat_washtower_door_top.map = colorTextures[9];
            }
        }

    }, []);

    //워셔 색상 변경
    useLayoutEffect(() => {
        changeTexture('', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    //드라이어 색상 변경
    useLayoutEffect(() => {
        changeDryerTexture('', colorCategoryDryer, colorLabelDryer);
    }, [colorCategoryDryer, colorLabelDryer]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isWasherSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut},
                // onRest: result => {
                //     console.log(">>>>>", result);
                // }
            });
        }
    }, [isOpenTutorial]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials.mat_washtower_body_top.lightMap = lightmapTex;
        materials.mat_washtower_body_bottom.lightMap = lightmapTex;
        materials.mat_washtower_door_top.lightMap = lightmapTex;
        materials.mat_washtower_door_top.lightMapIntensity = 2;
        materials.mat_washtower_door_bottom.lightMap = lightmapTex;
        materials.mat_washtower_door_bottom.lightMapIntensity = 2;

    }, [lightmapTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL,
                COLOR_8_URL, COLOR_9_URL
            ]);
        }
    }, []);

    const [idx, setIdx] = useState(-1);
    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <group position={[-2.5312, 1.1082, -6.4713]} rotation={[0, Math.PI / 2, 0]}>
                <mesh geometry={nodes.Mesh1756.geometry} material={materials.mat_washtower_body_top}/>
                <mesh geometry={nodes.Mesh1756_1.geometry} material={materials.mat_washtower_body_bottom}/>
                <mesh
                    geometry={nodes.Glass005.geometry}
                    material={materials.mat_washtower_glass}
                    position={[-0.0004, 0.03, 0.3967]}
                />
                <mesh
                    geometry={nodes.UI005.geometry}
                    material={materials.mat_washtower_ui}
                    position={[-0.0004, -0.902, 1.0184]}
                />
                <mesh
                    geometry={nodes.Door_Top005.geometry}
                    material={materials.mat_washtower_door_top}
                    position={[-0.0004, -0.902, 1.0184]}
                />
                <mesh
                    geometry={nodes.Door_Bottom005.geometry}
                    material={materials.mat_washtower_door_bottom}
                    position={[-0.0004, -0.902, 1.0184]}
                />
                <ObjetProductContainer3D
                    args={[0.68, 0.8, 0.01]}
                    position={[0, 0.55, 0.4]}
                    data={dryer_data}
                    showDebug={false}
                    cameraTargetPos={[0, 0, 3]}
                    selectMeCallback={() => {
                        setIdx(0);
                        selectPlusHandler();
                    }}
                />
                <ObjetProductContainer3D
                    args={[0.68, 0.95, 0.01]}
                    position={[0, -0.45, 0.4]}
                    data={washer_data}
                    showDebug={false}
                    cameraTargetPos={[0, 0, 3]}
                    selectMeCallback={() => {
                        setIdx(1);
                        selectPlusHandler();
                    }}
                />

                <mesh
                    geometry={nodes.Door_Top005.geometry}
                    material={materials.mat_washtower_door_top}
                    position={[-0.0004, -0.902, 1.0184]}
                    visible={!isDryerSet}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                    <ObjetPlusButton position={[0, 1.45, -0.7]} scale={0.8} visible={!(isDryerSet)}
                                     opacity={spring.opacity}
                                     color={(idx === 0 && pathname.indexOf('dryer') > -1) ? '#be1e42' : '#fefefe'}/>
                </mesh>
                <mesh
                    geometry={nodes.Door_Bottom005.geometry}
                    material={materials.mat_washtower_door_bottom}
                    position={[-0.0004, -0.902, 1.0184]}
                    visible={!isWasherSet}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                    <ObjetPlusButton position={[0, 0.5, -0.7]} scale={0.8} visible={!(isWasherSet)}
                                     opacity={spring.opacity}
                                     color={(idx === 1 && pathname.indexOf('washer') > -1) ? '#be1e42' : '#fefefe'}/>
                </mesh>
            </group>

            {/* 카메라 타겟 */}
            <group position={[-2.5312, 1.1082, -6.4713]} rotation={[0, Math.PI / 2, 0]}>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={[0, 0, 0]}
                    ref={lookTargetRef}
                >ss
                    <meshBasicMaterial wireframe color={"#0f0"}/>
                </Box>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={[0, 0, 4]}
                    ref={cameraTargetRef}
                >
                    <meshBasicMaterial wireframe color={"#f00"}/>
                </Box>
            </group>
        </group>
    )
}
