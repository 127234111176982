import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_Cooking: UspPageDataType[] = [
  {
    category: "cooking",
    modelNo: "lupin_gas_double",
    bgImgUrl: "/images/usp/cooking/lupin_gas_double.jpg",
    uspList: [
      {
        label: "lupin_gas_double_usp_0",
        title: [
          <>
            Cleaning?
            <br />
            With water is perfect.
          </>,
        ],
        subMsg: [<>EasyClean® for Cooktop</>],
        videoUrl: "https://vimeo.com/659597182/dfe5b752e4",
        x: 150,
        y: 368,
        ga_id: "easyclean",
      },
      {
        label: "lupin_gas_double_usp_0",
        title: [
          <>
            Wonder how are your foods
            <br />
            going? Knock Twice.
          </>,
        ],
        subMsg: [<>InstaView Window</>],
        videoUrl: "https://vimeo.com/659879526/b5b787bfbc",
        x: 150,
        y: 518,
        ga_id: "instaview_window",
      },
      {
        isRight: true,
        label: "lupin_gas_double_usp_0",
        title: [
          <>
            Don't need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/659597216/3da66bb8ff",
        x: 1255,
        y: 368,
        ga_id: "air_fry",
      },
      {
        isRight: true,
        label: "lupin_gas_double_usp_0",
        title: [<>Perfect Steak at home?</>],
        subMsg: [<>Air sous vide</>],
        videoUrl: "https://vimeo.com/659597248/f88de4e661",
        x: 1255,
        y: 518,
        ga_id: "air_sous-vide",
      },
      {
        isRight: true,
        label: "lupin_gas_double_usp_0",
        title: [
          <>
            Enjoy hot water faster
            <br />
            whenever you need it.
          </>,
        ],
        subMsg: [<>UltraHeat™ 24K BTU Power Burner</>],
        videoUrl: "https://vimeo.com/659597275/2a59ce9381",
        x: 1255,
        y: 643,
        ga_id: "ultraheat_24k_btu_power_burner",
      },
    ],
  },
  {
    category: "cooking",
    modelNo: "lupin_elec_double",
    bgImgUrl: "/images/usp/cooking/lupin_elec_double.jpg",
    uspList: [
      {
        label: "lupin_elec_double_usp_0",
        title: [
          <>
            Wonder how are your foods
            <br />
            going? Knock Twice.
          </>,
        ],
        subMsg: [<>InstaView Window</>],
        videoUrl: "https://vimeo.com/659599315/77928ef242",
        x: 150,
        y: 354,
        ga_id: "instaview_window",
      },
      {
        label: "lupin_elec_double_usp_0",
        title: [
          <>
            Do you still wait long time
            <br />
            for boiling water?
          </>,
        ],
        subMsg: [<>UltraHeat™ Element(3.6kW)</>],
        videoUrl: "https://vimeo.com/659599405/66d67a7cb0",
        x: 150,
        y: 504,
        ga_id: "ultraheat_element",
      },
      {
        isRight: true,
        label: "lupin_elec_double_usp_0",
        title: [
          <>
            Don't need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/659599343/2bfe274ada",
        x: 1255,
        y: 354,
        ga_id: "air_fry",
      },
      {
        isRight: true,
        label: "lupin_elec_double_usp_0",
        title: [<>Perfect Steak at home?</>],
        subMsg: [<>Air sous vide</>],
        videoUrl: "https://vimeo.com/659599379/d8a61b18d9",
        x: 1255,
        y: 504,
        ga_id: "air_sous-vide",
      },
    ],
  },
  {
    category: "cooking",
    modelNo: "lupin_dual",
    bgImgUrl: "/images/usp/cooking/lupin_dual.jpg",
    uspList: [
      {
        label: "lupin_dual_usp_0",
        title: [
          <>
            Cleaning?
            <br />
            With water is perfect.
          </>,
        ],
        subMsg: [<>EasyClean® for Cooktop</>],
        videoUrl: "https://vimeo.com/659601068/3d33f2552e",
        x: 210,
        y: 354,
        ga_id: "easyclean_for_cooktop",
      },
      {
        label: "lupin_dual_usp_0",
        title: [
          <>
            Wonder how are your foods
            <br />
            going? Knock Twice.
          </>,
        ],
        subMsg: [<>InstaView Window</>],
        videoUrl: "https://vimeo.com/659890048/aff0514878",
        x: 210,
        y: 504,
        ga_id: "instaview_window",
      },
      {
        isRight: true,
        label: "lupin_dual_usp_0",
        title: [
          <>
            Don't need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/659890078/bf30dae392",
        x: 1255,
        y: 354,
        ga_id: "air_fry",
      },
      {
        isRight: true,
        label: "lupin_dual_usp_0",
        title: [<>Perfect Steak at home?</>],
        subMsg: [<>Air sous vide</>],
        videoUrl: "https://vimeo.com/659890101/c1b8c16aa9",
        x: 1255,
        y: 504,
        ga_id: "air_sous-vide",
      },
    ],
  },
  {
    category: "cooking",
    modelNo: "lupin_gas_single",
    bgImgUrl: "/images/usp/cooking/lupin_gas_single_good.jpg",
    uspList: [
      {
        label: "lupin_gas_single_usp_0",
        title: [
          <>
            Don't need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/659890458/b472c77044",
        x: 195,
        y: 518,
        ga_id: "air_fry",
      },
      {
        isRight: true,
        label: "lupin_gas_double_usp_0",
        title: [
          <>
            Cleaning?
            <br />
            With water is perfect.
          </>,
        ],
        subMsg: [<>EasyClean® & Self Clean Oven</>],
        videoUrl: "https://vimeo.com/659890477/af60e8458d",
        x: 1215,
        y: 436,
        ga_id: "easyclean&self_clean_oven",
      },
    ],
  },
  {
    category: "cooking",
    modelNo: "lupin_elec_single",
    bgImgUrl: "/images/usp/cooking/lupin_elec_single_good.jpg",
    uspList: [
      {
        label: "lupin_elec_single_usp_0",
        title: [
          <>
            Don't need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/659890611/3431c1babf",
        x: 195,
        y: 518,
        ga_id: "air_fry",
      },
      {
        isRight: true,
        label: "lupin_elec_single_usp_0",
        title: [
          <>
            Cleaning?
            <br />
            With water is perfect.
          </>,
        ],
        subMsg: [<>EasyClean® & Self Clean Oven</>],
        videoUrl: "https://vimeo.com/659890632/9df1063a9e",
        x: 1215,
        y: 436,
        ga_id: "easyclean&self_clean_oven",
      },
    ],
  },
  {
    category: "cooking",
    modelNo: "lupin_slide",
    bgImgUrl: "/images/usp/cooking/lupin_slide.jpg",
    uspList: [
      {
        label: "lupin_slide_usp_0",
        title: [
          <>
            Cleaning?
            <br />
            With water is perfect.
          </>,
        ],
        subMsg: [<>EasyClean</>],
        videoUrl: "https://vimeo.com/658492547/ab26c1c17c",
        x: 195,
        y: 354,
        ga_id: "easyclean",
      },
      {
        label: "lupin_slide_usp_0",
        title: [
          <>
            Don't need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/658492948/1814c2c73b",
        x: 195,
        y: 504,
        ga_id: "air_fry",
      },
      {
        isRight: true,
        label: "lupin_slide_usp_0",
        title: [<>Perfect Steak at home?</>],
        subMsg: [<>Air sous vide</>],
        videoUrl: "https://vimeo.com/658492980/580b967597",
        x: 1210,
        y: 436,
        ga_id: "air_sous-vide",
      },
      {
        isRight: true,
        label: "lupin_slide_usp_0",
        title: [
          <>
            Wonder how are your foods
            <br />
            going? Knock Twice.
          </>,
        ],
        subMsg: [<>InstaView Window</>],
        videoUrl: "https://vimeo.com/658493024/9c71f4e930",
        x: 1210,
        y: 561,
        ga_id: "instaview_window",
      },
    ],
  },
];

export default uspData_Cooking;
