import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import styled, {ThemeProvider} from "styled-components";
import {animated} from "@react-spring/web";
import {Image} from "../../common/comps_styled/Image.styled";
import theme from "../../../styles/theme.styled";
import useProductStore from "../../../stores/productStore";
import {
    getSpecboardDataFromList,
    getSpecboardsData,
    SpecboardDataItemType,
} from "../../../api/specboard/specboardData";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../common/const";
import {getProductDataFromList} from "../../../api/products/productsData";
import productsData_MaskCase from "../../../api/products/productData_MaskCase";

type CloseBtnProps = {} & JSX.IntrinsicElements["button"];
const CloseBtn = ({...rest}: CloseBtnProps) => {
    return (
        <CloseBtnStyle id="closeBtn" title="Close Specboard" {...rest}>
            <div className="icon">
                <div className="line line1"/>
                <div className="line line2"/>
            </div>
        </CloseBtnStyle>
    );
};

type SpecBoardProps = {
    specImgUrl: string;
    specImgAlt?: string;
    closeHandler: () => void;
} & JSX.IntrinsicElements["div"];

// Temp
const SpecBoard = ({specImgUrl, specImgAlt = '', closeHandler, ...rest}: SpecBoardProps) => {

    useEffect(() => {
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Wrapper {...rest}>
                <CloseBtn
                    style={{top: "-0.5em", right: "-0.5em"}}
                    onClick={(e) => {
                        e.stopPropagation();
                        closeHandler();
                    }}
                />
                <Image
                    src={ROOT_PATH_PREFIX + specImgUrl}
                    alt={specImgAlt}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

const Wrapper = styled(animated.div)<Record<string, any>>`
  width: ${IS_MOBILE ? 55 : 45}em;
  height: auto;
  background: #1d1d1d;
  position: absolute;
  right: ${IS_MOBILE ? 12 : 10}em;
`;

const CloseBtnStyle = styled.button<Record<string, any>>`
  position: absolute;
  top: 0;
  right: 0;
  width: 3em;
  height: 3em;
  margin: 0.7em;
  background-color: rgba(0, 0, 0, 0);
  //background: red;
  outline: none;
  border: none;
  border-radius: 3rem;
  pointer-events: visible;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    position: absolute;
    width: 2em;
    height: 2em;
    display: flex;
    justify-content: center;
    align-items: center;

    .line {
      background: #fff;
      height: 0.1em;
      min-height: 1.5px;
      /* width: 1.2em; */
      width: 2em;
      position: absolute;
    }

    .line1 {
      transform: rotate(45deg);
    }

    .line2 {
      transform: rotate(-45deg);
    }
  }

  :focus-visible {
    background-color: rgba(0, 0, 0, 1);
    filter: drop-shadow(0 1px 15px rgba(255, 255, 255, 1));
  }

  :hover {
    .line {
      background: #ac2551;
    }
  }
`;

export default SpecBoard;
