import React, { useEffect, useLayoutEffect, useState } from "react";
import {MeshReflectorMaterial, Plane, Preload, Sky } from "@react-three/drei";
import useCameraControls from "../../../utils/useCameraControls";
import { Layer, ROOT_PATH_PREFIX } from "../../common/const";
import ObjetRoomTexture from "./ObjetRoomTexture";
import {natural_floor_data, natural_wall_data} from "../../../api/objet/objetMaterialsData";
import * as THREE from "three";
import useObjetProductStore, {ObjetProductState} from "../../../stores/objetProductStore";
import Obstacle from "../../common/camera/Obstacle";
import useCameraStore, { CameraState } from "../../../stores/cameraStore";
import EnvLoader from "./natural/EnvLoader";
import NaturalRoomFloor from "./natural/NaturalRoomFloor";
import NaturalRoomGlass from "./natural/NaturalRoomGlass";
import NaturalRoomWall from "./natural/NaturalRoomWall";
import NaturalRoomSofa from "./natural/NaturalRoomSofa";
import NaturalRoomKitchen from "./natural/NaturalRoomKitchen";
import NaturalRoomProps from "./natural/NaturalRoomProps";
import NaturalRoomProducts from "./natural/NaturalRoomProducts";


const selectedCategorySelector = (state: ObjetProductState) => state.selectedCategory;
const selectedRoomSelector = (state: ObjetProductState) => state.selectedRoom;
const cameraApiSelector = (state: CameraState) => state.api;



const NaturalRoom = () => {
  const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();
  const selectedCategory = useObjetProductStore(selectedCategorySelector);
  const selectedRoom = useObjetProductStore(selectedRoomSelector); //선택된 공간 타입
  const { setTargetPos, setLookPos, setTouchStart } = useCameraStore(cameraApiSelector);

  //카메라 앵글 제한
  useLayoutEffect(() => {
    if (selectedRoom === "natural") {
      if (selectedCategory) {
        cameraControls.minAzimuthAngle = -20 * THREE.MathUtils.DEG2RAD;
        cameraControls.maxAzimuthAngle = 20 * THREE.MathUtils.DEG2RAD;
        cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
        cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;

        if (selectedCategory.category === "lansen_freezer_r") {
          cameraControls.minAzimuthAngle =
            (-20 + 270) * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = (20 + 270) * THREE.MathUtils.DEG2RAD;
        } else if (selectedCategory.category === "lansen_freezer_l") {
          cameraControls.minAzimuthAngle =
            (-20 + 270) * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = (20 + 270) * THREE.MathUtils.DEG2RAD;
        } else if (selectedCategory.category === "water_purifier") {
          cameraControls.minAzimuthAngle =
            (-20 + 270) * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = (40 + 270) * THREE.MathUtils.DEG2RAD;
        } else if (selectedCategory.category === "water_purifier_undersink") {
          cameraControls.minAzimuthAngle =
            (-20 + 270) * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = (20 + 270) * THREE.MathUtils.DEG2RAD;
          cameraControls.minPolarAngle = 40 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
        } else if (selectedCategory.category === "vacuum_cleaner_robot") {
          cameraControls.minAzimuthAngle = 280 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = 360 * THREE.MathUtils.DEG2RAD;
          cameraControls.minPolarAngle = 10 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxPolarAngle = 80 * THREE.MathUtils.DEG2RAD;
        } else if (selectedCategory.category === "air_conditioner_wall") {
          cameraControls.minPolarAngle = 80 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxPolarAngle = 100 * THREE.MathUtils.DEG2RAD;
        } else if (selectedCategory.category === "air_care") {
          // cameraControls.minPolarAngle = 80 * THREE.MathUtils.DEG2RAD;
          // cameraControls.maxPolarAngle = 110 * THREE.MathUtils.DEG2RAD;
          cameraControls.minAzimuthAngle = 300 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = 400 * THREE.MathUtils.DEG2RAD;
        }
        else if (selectedCategory.category === "styler") {
          cameraControls.minAzimuthAngle = -10 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxAzimuthAngle = 20 * THREE.MathUtils.DEG2RAD;
          cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
          cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
        }
      } else {
        cameraControls.minAzimuthAngle = -Infinity;
        cameraControls.maxAzimuthAngle = Infinity;
        cameraControls.minPolarAngle = -Infinity;
        cameraControls.maxPolarAngle = Infinity;
      }
    }
  }, [selectedCategory, selectedRoom]);

  const [interiorWallIndex, setInteriorWallIndex] = useState(0);
  const [interiorFloorIndex, setInteriorFloorIndex] = useState(0);

  return (
    <group position={[0, 0, 8]}>
      <React.Suspense fallback={null}>
        <EnvLoader />
        <group scale={0.75}>
          <NaturalRoomGlass />
          <NaturalRoomFloor interiorIdx={interiorFloorIndex} />
          <NaturalRoomWall interiorIdx={interiorWallIndex} />
          <NaturalRoomSofa interiorIdx={interiorFloorIndex} />
          <NaturalRoomKitchen interiorIdx={interiorWallIndex} />
          <NaturalRoomProps />
          <NaturalRoomProducts />
          <Sky
            distance={450000}
            sunPosition={[-1, 0.5, 0]}
            inclination={0}
            azimuth={0.15}
          />
        </group>

        <Preload all />

        <>
          <Obstacle
            size={[20, 4, 2]}
            position={[0, 2, -4.5 + 8]}
            color={"red"}
            type={"Static"}
            visible={false}
            collisionFilterGroup={Layer.TriggerArea | Layer.Character}
          />
          <Obstacle
            size={[20, 4, 2]}
            position={[0, 2, 4.5 + 8]}
            color={"red"}
            type={"Static"}
            visible={false}
            collisionFilterGroup={Layer.TriggerArea | Layer.Character}
          />
          <Obstacle
            size={[2, 4, 10]}
            position={[3.8, 2, 8]}
            color={"red"}
            type={"Static"}
            visible={false}
            collisionFilterGroup={Layer.TriggerArea | Layer.Character}
          />
          <Obstacle
            size={[2, 4, 10]}
            position={[-3.8, 2, 8]}
            color={"red"}
            type={"Static"}
            visible={false}
            collisionFilterGroup={Layer.TriggerArea | Layer.Character}
          />
        </>

        <Preload all />
      </React.Suspense>

      <>
        <ObjetRoomTexture
          position={[-0.75, 2.5, -3.8]}
          data={natural_wall_data}
          onSelectedCallback={(selectedIdx) => {
            console.log("selected texture: ", selectedIdx);
            setInteriorWallIndex(selectedIdx);
          }}
        />
        <ObjetRoomTexture
          position={[0, 0.4, 1.2]}
          data={natural_floor_data}
          onSelectedCallback={(selectedIdx) => {
            console.log("selected texture: ", selectedIdx);
            setInteriorFloorIndex(selectedIdx);
          }}
        />
      </>

      <Plane
        args={[5.5, 6.5]}
        position={[0, 0, 0]}
        rotation={[-Math.PI / 2, 0, 0]}
        onClick={(e) => {
          console.log(e);
          e.stopPropagation();
          if (e.delta < 5) {
            setLookPos(null);
            setTargetPos(e.point);
          }
        }}
        visible={false}
        renderOrder={999}
      >
        <meshBasicMaterial
          color={"#ff3"}
          transparent={true}
          opacity={0.8}
          depthTest={true}
        />
      </Plane>
    </group>
  );
};

export default NaturalRoom;
