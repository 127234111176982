import React, {useEffect, useLayoutEffect, useState} from 'react';
import {MeshReflectorMaterial, Environment, Plane, Preload, Sphere, Text, useTexture} from "@react-three/drei";
import ObjetRoomTexture from "./ObjetRoomTexture";
import {
  modern_floor_data,
  modern_wall_data,
} from "../../../api/objet/objetMaterialsData";
import ModernRoomModel01 from "./modern/ModernRoomModel01_1";
import ModernRoomModel02 from "./modern/ModernRoomModel02_1";
import Obstacle from "../../common/camera/Obstacle";
import {IS_MOBILE, Layer, ROOT_PATH_PREFIX} from "../../common/const";
import * as THREE from "three";
import useCameraControls from "../../../utils/useCameraControls";
import useObjetProductStore, {ObjetProductState} from "../../../stores/objetProductStore";
import useCameraStore, {CameraState} from "../../../stores/cameraStore";
import EnvLoader from './modern/EnvLoader';
import ModernRoomProducts from './modern/ModernRoomProducts';


const selectedCategorySelector = (state: ObjetProductState) =>
    state.selectedCategory;
const selectedRoomSelector = (state: ObjetProductState) => state.selectedRoom;
const cameraApiSelector = (state: CameraState) => state.api;




const ModernRoom = () => {

    const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();
    const selectedCategory = useObjetProductStore(selectedCategorySelector);
    const selectedRoom = useObjetProductStore(selectedRoomSelector); //선택된 공간 타입
    const {setTargetPos, setLookPos, setTouchStart} = useCameraStore(cameraApiSelector);

    //카메라 앵글 제한
    useLayoutEffect(() => {
        if (selectedRoom === "modern") {
            if (selectedCategory) {
                cameraControls.minAzimuthAngle = -20 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxAzimuthAngle = 20 * THREE.MathUtils.DEG2RAD;
                cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;

                if (selectedCategory.category === "lansen_freezer_r") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                } else if (selectedCategory.category === "lansen_freezer_l") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                }
                else if (selectedCategory.category === "refrigerator_bl" ||
                    selectedCategory.category === "refrigerator_br" ||
                    selectedCategory.category === "refrigerator_tl" ||
                    selectedCategory.category === "water_purifier_undersink") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.minPolarAngle = 60 * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
                }
                else if (selectedCategory.category === "air_conditioner_wall" || selectedCategory.category === "air_care_aero" || selectedCategory.category === "air_care" || selectedCategory.category === "tiiun" || selectedCategory.category === "dishwasher") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                    if(selectedCategory.category === "air_conditioner_wall"){
                        cameraControls.minPolarAngle = 90 * THREE.MathUtils.DEG2RAD;
                        cameraControls.maxPolarAngle = 110 * THREE.MathUtils.DEG2RAD;
                    }
                }

                else if (selectedCategory.category === "water_purifier" || selectedCategory.category === "microwave_oven" || selectedCategory.category === "styler" || selectedCategory.category === "washer" || selectedCategory.category === "dryer") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;

                }
                else if (selectedCategory.category === "vacuum_cleaner_robot") {
                    cameraControls.minAzimuthAngle = (-40 + 40) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (40 + 40) * THREE.MathUtils.DEG2RAD;
                    cameraControls.minPolarAngle = 10 * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxPolarAngle = 80 * THREE.MathUtils.DEG2RAD;
                }
            } else {
                cameraControls.minAzimuthAngle = -Infinity;
                cameraControls.maxAzimuthAngle = Infinity;
                cameraControls.minPolarAngle = -Infinity;
                cameraControls.maxPolarAngle = Infinity;
            }
        }
    }, [selectedCategory, selectedRoom]);

    const [interiorWallIndex, setInteriorWallIndex] = useState(0);
    const [interiorFloorIndex, setInteriorFloorIndex] = useState(0);


    return (
        <group>
            <React.Suspense fallback={null}>
                <EnvLoader/>

                <ModernRoomModel01 interiorIdx={interiorWallIndex} />
                <ModernRoomModel02 interiorFloorIdx={interiorFloorIndex} interiorWallIdx={interiorWallIndex} />
                <ModernRoomProducts />

                {!IS_MOBILE && (
                    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0.001, 0]}>
                        <planeGeometry args={[18, 24]} />
                        {/*@ts-ignore*/}
                        <MeshReflectorMaterial
                            blur={[400, 100]}
                            resolution={1024}
                            mixBlur={1}
                            mixStrength={150}
                            mixContrast={1}
                            roughness={1}
                            depthScale={1}
                            minDepthThreshold={0.1}
                            maxDepthThreshold={1.5}
                            color="#151515"
                            metalness={0.5}
                            transparent={true}
                            opacity={0.2}
                        />
                    </mesh>
                )}
                <>
                {/*<Axis visible={true} />*/}
                <Obstacle
                    size={[7.5, 4, 12]}
                    position={[5.35, 2, -9]}
                    color={"red"}
                    type={"Static"}
                    visible={false}
                    collisionFilterGroup={Layer.TriggerArea | Layer.Character}
                />

                <Obstacle
                    size={[20, 4, 2]}
                    position={[0, 2, -9]}
                    color={"red"}
                    type={"Static"}
                    visible={false}
                    collisionFilterGroup={Layer.TriggerArea | Layer.Character}
                />
                <Obstacle
                    size={[20, 4, 2]}
                    position={[0, 2, 12]}
                    color={"blue"}
                    type={"Static"}
                    visible={false}
                    collisionFilterGroup={Layer.TriggerArea | Layer.Character}
                />
                <Obstacle
                    size={[2, 4, 50]}
                    position={[9.5, 2, 8]}
                    color={"blue"}
                    type={"Static"}
                    visible={false}
                    collisionFilterGroup={Layer.TriggerArea | Layer.Character}
                />
                <Obstacle
                    size={[2, 4, 50]}
                    position={[-2.3, 2, 8]}
                    color={"blue"}
                    type={"Static"}
                    visible={false}
                    collisionFilterGroup={Layer.TriggerArea | Layer.Character}
                />

                <Obstacle
                    size={[5, 4, 1]}
                    position={[0, 2, 7.5]}
                    color={"red"}
                    type={"Static"}
                    visible={false}
                    collisionFilterGroup={Layer.TriggerArea | Layer.Character}
                />
                </>

                <Preload all />
            </React.Suspense>

            {/*<Axis visible={true} />*/}
            <ObjetRoomTexture position={[-2, 3, 4]} data={modern_wall_data} onSelectedCallback={(selectedIdx) => {
                console.log("selected texture: ", selectedIdx);
                setInteriorWallIndex(selectedIdx);
            }}/>
            <ObjetRoomTexture position={[2, 0, 3.5]} data={modern_floor_data} onSelectedCallback={(selectedIdx) => {
                console.log("selected texture: ", selectedIdx);
                setInteriorFloorIndex(selectedIdx);
            }}/>

            <Plane
                args={[12, 12]}
                position={[2, 0, 1]}
                rotation={[-Math.PI / 2, 0, 0]}
                onClick={(e) => {
                    console.log(e);
                    e.stopPropagation();
                    if (e.delta < 5) {
                        setLookPos(null);
                        setTargetPos(e.point);
                    }
                }}
                visible={false}
                renderOrder={999}
            >
                <meshBasicMaterial
                    color={"#ff3"}
                    transparent={true}
                    opacity={0.8}
                    depthTest={false}
                />
            </Plane>
        </group>
    );
};

export default ModernRoom;
