import React, { ReactElement, useState } from "react";
import styled from "styled-components";
import useHistoryStore, { HistoryState } from "../../../stores/historyStore";
import useResizeStore from "../../../stores/resizeStore";
import theme from "../../../styles/theme.styled";
import { Image } from "../../common/comps_styled/Image.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { ROOT_PATH_PREFIX } from "../../common/const";
import useProductStore, { productDic } from "../../../stores/productStore";
import {
  getProductDataFromList,
  getProductsData,
  ProductDataItemType,
  ProductsCategoryType,
  ProductsDataItemType,
} from "../../../api/products/productsData";
import productsData_Cooking from "../../../api/products/productsData_Cooking";
import sleep from "../../../utils/sleep";
import { GA_Event } from "../../../api/ga";
import { useModalVimeo } from "../../../stores/modalStore";
import { getVimeoCESLink } from "../../../api/products/productVimeoDataForMobile";
import { useSelectMobileQuality } from "../../../stores/selectRouteForMobileStore";
import useLangStore from "../../../stores/langStore";
import { getLangString } from "../../../api/lang/langData";

interface Props {
  model: string;
  modelPath: string;
  showButton?: boolean;
}

const pushHistorySelector = (state: HistoryState) => state.pushHistory;

function AwardsItem({
  model,
  modelPath,
  showButton = true,
}: Props): ReactElement {
  const lang = useLangStore((state) => state.lang);
  const pushHistory = useHistoryStore(pushHistorySelector);
  const resize = useResizeStore((state) => state.resize);
  const selectProduct = useProductStore((state) => state.api.selectProduct);
  const [hover, setHover] = useState(false);

  const modalVimeo = useModalVimeo((state) => state.isOpen);
  const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
  const setVimeoId = useModalVimeo((state) => state.setId);
  const setVimeoGaId = useModalVimeo((state) => state.setGaId);
  const setVimeo = (id: string) => {
    setVimeoId(id);
    setModalVimeo(true);
  };

  const isNormalQuailty = useSelectMobileQuality(
    (state) => state.isNormalQuality
  );

  function jumpToPath(
    category: ProductsCategoryType,
    modelNo: string,
    path: string
  ) {
    const productsData = getProductsData(category);
    if (productsData) {
      //@ts-ignore
      const searchResult = getProductDataFromList(
        productsData.productList as ProductDataItemType[],
        modelNo
      );
      // console.log("#####" , searchResult);
      productDic[category] = searchResult;
      selectProduct(searchResult);
    }
    pushHistory(path);
  }

  const productName = {
    product_01: "refrigerator objet collection",
    product_02: "gas slide-in double range",
    product_03: "gas slide-in range",
    product_04: "microwave over the range",
    product_05: "tiiun",
    product_06: "tiiun mini",
    product_07: "wash tower",
    product_08: "puricare 360",
  };

  return (
    <Wrapper>
      <ItemContainer>
        <Image alt={productName[model]} role="img" src={modelPath} />
        {showButton && (
          <ButtonContainer ratio={resize.ratio}>
            <DiscoverButton
              onMouseOver={() => {
                setHover(true);
              }}
              onMouseLeave={() => {
                setHover(false);
              }}
              onClick={() => {
                // console.log("click", model);
                if (model === "product_01") {
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "refrigerator_f/door"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("refrigerator_f/door"));
                    return;
                  }
                  //
                  pushHistory("/objetcollection/natural/refrigerator_tl");
                } else if (model === "product_02") {
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "lupin2_gas_double_best"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("lupin2_gas_double_best"));
                    return;
                  }
                  //
                  jumpToPath(
                    "cooking",
                    "lupin_gas_double",
                    "/home/kitchen/cooking"
                  );
                } else if (model === "product_04") {
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "scallet2_best"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("scallet2_good"));
                    return;
                  }
                  //
                  jumpToPath(
                    "cooking",
                    "scallet2_good",
                    "/home/kitchen/microwave_oven"
                  );
                } else if (model === "product_05") {
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "tiiun"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("tiiun"));
                    return;
                  }
                  //
                  pushHistory("/home/living/tiiun");
                } else if (model === "product_06") {
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "tiiun_mini"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("tiiun_mini"));
                    return;
                  }
                  //
                  pushHistory("/home/living/tiiun_mini");
                } else if (model === "product_07") {
                  //
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "washtower_27"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("washtower_27"));
                    return;
                  }
                  //
                  jumpToPath(
                    "washer_n_dryer",
                    "washtower_27",
                    "/home/utility/washer_n_dryer"
                  );
                } else if (model === "product_08") {
                  //
                  GA_Event(
                    "ha_virtual_studio",
                    "best_of_innovation_model",
                    "air_care_360_tower"
                  );
                  //
                  if (isNormalQuailty) {
                    pushHistory("");
                    setVimeoGaId("");
                    setVimeo(getVimeoCESLink("air_care_360_tower"));
                    return;
                  }
                  //
                  pushHistory("/objetcollection/natural");
                }
              }}
            >
              <Text
                style={{ position: "absolute" }}
                size={`${1.35 * resize.ratio}em`}
                weight={theme.font.weight.semibold}
              >
                {getLangString(lang, "discover")?.text}
              </Text>
              <Image
                alt="button shape"
                role="img"
                src={`${ROOT_PATH_PREFIX}/images/icons/svg/btn_award_box_${
                  hover ? "press" : "default"
                }.svg`}
              />
            </DiscoverButton>
          </ButtonContainer>
        )}
      </ItemContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const ItemContainer = styled.div``;

const DiscoverButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 40%;
  height: 100%;
  background-color: transparent;
  pointer-events: visible;
  cursor: pointer;
`;

const ButtonContainer = styled.div<{ ratio: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${({ ratio }) => ratio * 1.5}em 0;
`;

export default AwardsItem;
