/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {GLTFResult_ObjetModernProducts} from "./Product_Harvester";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {Objet_ColorCategoryType, water_purifier_data} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import {TextureLoader} from "three";
import {easeCubicInOut} from "d3-ease";
import {a} from "@react-spring/three";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_beige_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_beige_body_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_beige_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_beige_body_merged_BaseColor.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_cotton_sky_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_cotton_sky_body_BaseColor.jpg');
const COLOR_3_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_matcotton_sky_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_matcotton_sky_body_merged_BaseColor.jpg');
const COLOR_4_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_green_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_green_body_BaseColor.jpg');
const COLOR_5_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_green_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_green_body_merged_BaseColor.jpg');
const COLOR_6_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_pale_rose_body_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_pale_rose_body_BaseColor.jpg');
const COLOR_7_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom4d_plus/atom_4d_plus_mat_pale_rose_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom4d_plus/atom_4d_plus_mat_pale_rose_body_merged_BaseColor.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()

    //@ts-ignore
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult_ObjetModernProducts

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.water_purifier_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.water_purifier_ColorLabel);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {
        console.log(colorCategory, colorLabel, isFirst)
        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsSet(true);

        if (colorCategory === 'Calming') {
            if (colorLabel === 'objet_color_beige') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials.mat_artcool_side.map = colorTextures[0];
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials.mat_artcool_body.map = colorTextures[1];
            } else if (colorLabel === 'objet_color_green') {
                colorTextures[4].flipY = false;
                colorTextures[4].encoding = THREE.sRGBEncoding;
                colorTextures[4].needsUpdate = true;
                materials.mat_artcool_side.map = colorTextures[4];
                colorTextures[5].flipY = false;
                colorTextures[5].encoding = THREE.sRGBEncoding;
                colorTextures[5].needsUpdate = true;
                materials.mat_artcool_body.map = colorTextures[5];
            } else if (colorLabel === 'objet_color_cotton_sky') {
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials.mat_artcool_side.map = colorTextures[2];
                colorTextures[3].flipY = false;
                colorTextures[3].encoding = THREE.sRGBEncoding;
                colorTextures[3].needsUpdate = true;
                materials.mat_artcool_body.map = colorTextures[3];
            } else if (colorLabel === 'objet_color_pale_rose') {
                colorTextures[6].flipY = false;
                colorTextures[6].encoding = THREE.sRGBEncoding;
                colorTextures[6].needsUpdate = true;
                materials.mat_artcool_side.map = colorTextures[6];
                colorTextures[7].flipY = false;
                colorTextures[7].encoding = THREE.sRGBEncoding;
                colorTextures[7].needsUpdate = true;
                materials.mat_artcool_body.map = colorTextures[7];
            }
            materials.mat_artcool_body.envMapIntensity = 2;
            materials.mat_artcool_side.envMapIntensity = 2;
        }
    }, []);

    useLayoutEffect(() => {
        changeTexture('', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut},
                onRest: result => {
                    console.log(">>>>>", result);
                }
            });
        }
    }, [isOpenTutorial]);

    useLayoutEffect(() => {
        materials.mat_artcool_body.envMapIntensity = 3;
        materials.mat_artcool_side.envMapIntensity = 3;
        materials.mat_artcool_body.roughness = 0.4;
        materials.mat_artcool_side.roughness = 0.7;
    }, []);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }

            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL
            ]);
        }
    }, []);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} >
            <mesh
                geometry={nodes.atom_4d_plus.geometry}
                material={materials.mat_artcool_body}
                position={[0.587, 1.1648, 0.7075]}
                rotation={[0, Math.PI * 0.5, 0]}
                scale={[0.01, 0.01, 0.01]}>
                <mesh
                    geometry={nodes.BodySide001.geometry}
                    material={materials.mat_artcool_side}
                    position={[0.6742, 7.3732, -6.2489]}
                />
                <mesh
                    geometry={nodes.Decal003.geometry}
                    material={materials.mat_artcool_decal}
                    position={[-1.1169, 20.5642, 8.118]}
                    visible={isSet}
                />
            </mesh>

            <ObjetProductContainer3D
                args={[0.17, 0.4, 0.4]}
                position={[0.623, 1.24, 0.71]}
                rotation={[0, Math.PI * 0.5, 0]}
                data={water_purifier_data}
                showDebug={false}
                cameraTargetPos={[0, 0, 2]}
            />

            <mesh
                geometry={nodes.atom_4d_plus.geometry}
                position={[0.587, 1.1648, 0.7075]}
                rotation={[0, Math.PI / 2, 0]}
                scale={[0.01, 0.01, 0.01]}
                visible={!isSet}
            >
                <mesh
                    geometry={nodes.BodySide001.geometry}
                    position={[0.6742, 7.3732, -6.2489]}
                    visible={!isSet}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                </mesh>

                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 8, 0]}
                                 scale={80}
                                 visible={!(isSet)}
                                 opacity={spring.opacity}
                                 color={pathname.indexOf('/water_purifier_undersink') < 0 && pathname.indexOf('/water_purifier') > -1 ? '#be1e42' : '#000'}/>
            </mesh>

        </group>
    )
}
