import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';
import {useFrame} from "@react-three/fiber";

/**
 * 키보드 입력 Hook
 * @param key
 */
const useKeyPress = (key: string[] | string): boolean => {

    const [keyPressed, setKeyPressed] = useState(false);
    const keys: string[] = useMemo(() => Array.isArray(key) ? key : [key], [key]);

    let isPressed = useRef(false);

    const downHandler = useCallback((e: KeyboardEvent) => {
        if (keys.includes(e.key)) {
            if (!isPressed.current) {
                setKeyPressed(true);
                isPressed.current = true;
            }
        }
    }, [keys]);

    const upHandler = useCallback((e: KeyboardEvent) => {
        if (keys.includes(e.key)) {
            if (isPressed.current) {
                setKeyPressed(false);
                isPressed.current = false;
            }
        }
    }, [keys]);

    const blurHandler = useCallback(() => {
        setKeyPressed(false);
        isPressed.current = false;
    }, []);

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        window.addEventListener('blur', blurHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
            window.removeEventListener('blur', blurHandler);
        }
    }, []);

    return keyPressed;
};

/**
 * 키보드 방향키의 가중치를 넘겨주는 Hook
 */
const useInput = (): [React.MutableRefObject<number>, React.MutableRefObject<number>, boolean, boolean] => {

    const pressLeft = useKeyPress(["a", "A", "ArrowLeft"]);
    const pressRight = useKeyPress(["d", "D", "ArrowRight"]);
    const pressUp = useKeyPress(["w", "W", "ArrowUp"]);
    const pressDown = useKeyPress(["s", "S", "ArrowDown"]);
    const isPressShift = useKeyPress("Shift");

    const horizontal = useRef(0);
    const vertical = useRef(0);
    const targetHorizontal = useRef(0);
    const targetVertical = useRef(0);

    const isPress = useMemo(() => {
        return pressLeft || pressRight || pressUp || pressDown;
    }, [pressLeft, pressRight, pressUp, pressDown]);

    const lastUpdate = useRef(0);

    useFrame(({clock}, delta) => {

        if (clock.elapsedTime - lastUpdate.current > 0.033) {
            if (pressLeft) targetHorizontal.current = -1;
            else if (pressRight) targetHorizontal.current = 1;
            else targetHorizontal.current = 0;

            if (pressUp) targetVertical.current = -1;
            else if (pressDown) targetVertical.current = 1;
            else targetVertical.current = 0;

            horizontal.current += (targetHorizontal.current - horizontal.current) * 0.15;
            if (Math.abs(horizontal.current) < 0.01) horizontal.current = 0;

            vertical.current += (targetVertical.current - vertical.current) * 0.15;
            if (Math.abs(vertical.current) < 0.01) vertical.current = 0;

            // console.log(">>>>", pressRight, isPressShift);

            lastUpdate.current = clock.elapsedTime;
        }
    });

    return [horizontal, vertical, isPress, isPressShift];
}

export {useInput};

export default useKeyPress;
