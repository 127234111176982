import create from "zustand";

/**
 * 모달 상태관리
 */
export type ModalState = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

export type ServeyState = {
  isOpen: boolean;
  isCompleted: boolean;
  setIsOpen: (val: boolean) => void;
  setIsCompleted: (val: boolean) => void;
};

export type ModalVimeoState = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  id: string;
  setId: (val: string) => void;
  ga_id: string;
  setGaId: (val: string) => void;
};

export const useModalBalloonClose = create<ModalState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useModalMainIntro = create<ModalState>((set) => ({
  isOpen: true,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useModalOpen = create<ModalState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useModalDesign = create<ModalState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useModalUSP = create<ModalState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useModalTutorial = create<ModalState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useObjetInterior = create<ModalState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
}));

export const useModalVimeo = create<ModalVimeoState>((set) => ({
  isOpen: false,
  setIsOpen: (val) => set({ isOpen: val }),
  id: "",
  setId: (val) => set({ id: val }),
  ga_id: "",
  setGaId: (val) => set({ ga_id: val }),
}));

export const useModalSurveyMain = create<ServeyState>((set) => ({
  isOpen: false,
  isCompleted: false,
  setIsOpen: (val) => set({ isOpen: val }),
  setIsCompleted: (val) => set({ isCompleted: val }),
}));

export const useModalSurveyStyler = create<ServeyState>((set) => ({
  isOpen: false,
  isCompleted: false,
  setIsOpen: (val) => set({ isOpen: val }),
  setIsCompleted: (val) => set({ isCompleted: val }),
}));

export const useModalSurveyStylerBaron = create<ServeyState>((set) => ({
  isOpen: false,
  isCompleted: false,
  setIsOpen: (val) => set({ isOpen: val }),
  setIsCompleted: (val) => set({ isCompleted: val }),
}));

export const useModalSurveyCleaner = create<ServeyState>((set) => ({
  isOpen: false,
  isCompleted: false,
  setIsOpen: (val) => set({ isOpen: val }),
  setIsCompleted: (val) => set({ isCompleted: val }),
}));
