import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_Refrigerator: UspPageDataType[] = [
  {
    category: "refrigerator",
    modelNo: "vs6",
    bgImgUrl: "/images/usp/refrigerator/vs6.jpg",
    uspList: [
      {
        label: "vs6_usp_0",
        title: [
          <>
            Checking inside of
            <br />
            the fridge door? Knock twice.
          </>,
        ], //[en,kr,es]
        subMsg: [<>InstaView Door-in-Door</>],
        videoUrl: "https://vimeo.com/658852319/57f51c9642",
        x: 178,
        y: 226,
        ga_id: "instaview_door-in-door",
      },
      {
        label: "vs6_usp_0",
        title: [<>Enjoy your chilled drink longer.</>],
        subMsg: [<>Craft Ice</>],
        videoUrl: "https://vimeo.com/658869070/3c6053e130",
        x: 178,
        y: 376,
        ga_id: "craft_ice",
      },
      {
        label: "vs6_usp_0",
        title: [
          <>
            For the freshness,
            <br />
            just set up the temperature.
          </>,
        ],
        subMsg: [<>LINEARCooling</>],
        videoUrl: "https://vimeo.com/658869110/54686388c3",
        x: 178,
        y: 500,
        ga_id: "linearcooling",
      },
      {
        isRight: true,
        label: "vs6_usp_0",
        title: [
          <>
            Shhhh, this is the know-how
            <br />
            of fast cooling.
          </>,
        ],
        subMsg: [<>DoorCooling+</>],
        videoUrl: "https://vimeo.com/658869155/bd62b1c2ad",
        x: 1217,
        y: 376,
        ga_id: "doorcooling+",
      },
      {
        isRight: true,
        label: "vs6_usp_0",
        title: [
          <>
            Are you sure your
            <br />
            water dispenser clean enough?
          </>,
        ],
        subMsg: [<>Uvnano</>],
        videoUrl: "https://vimeo.com/658494069/14d941d5f1",
        x: 1217,
        y: 526,
        ga_id: "uvnano",
      },
    ],
  },
  {
    category: "refrigerator",
    modelNo: "ref_06", //a-next4
    bgImgUrl: "/images/usp/refrigerator/a_next4.jpg",
    uspList: [
      {
        label: "ref_06_usp_0",
        title: [
          <>
            Checking inside of
            <br />
            the fridge door? Knock twice.
          </>,
        ],
        subMsg: [<>InstaView Door-in-Door</>],
        videoUrl: "https://vimeo.com/656554257/db1568f2d1",
        x: 178,
        y: 226,
        ga_id: "instaview_door-in-door",
      },
      {
        label: "ref_06_usp_0",
        title: [
          <>
            Magic drawers that you can
            <br />
            set up the temperatures.
          </>,
        ],
        subMsg: [<>Full-Convert</>],
        videoUrl: "https://vimeo.com/656554296/79c1ee2c7d",
        x: 178,
        y: 376,
        ga_id: "full_convert",
      },
      {
        label: "ref_06_usp_0",
        title: [<>Enjoy your chilled drink longer.</>],
        subMsg: [<>Craft Ice</>],
        videoUrl: "https://vimeo.com/656554329/33aa77e6d1",
        x: 178,
        y: 526,
        ga_id: "craft_ice",
      },
      {
        isRight: true,
        label: "ref_06_usp_0",
        title: [
          <>
            For the freshness,
            <br />
            just set up the temperature.
          </>,
        ],
        subMsg: [<>LinearCooling</>],
        videoUrl: "https://vimeo.com/656554369/18f76e781b",
        x: 1217,
        y: 293,
        ga_id: "linearcooling",
      },
      {
        isRight: true,
        label: "ref_06_usp_0",
        title: [
          <>
            Shhhh, this is the know-how
            <br />
            of fast cooling.
          </>,
        ],
        subMsg: [<>DoorCooling+</>],
        videoUrl: "https://vimeo.com/656554407/bb376cc370",
        x: 1217,
        y: 443,
        ga_id: "doorcooling+",
      },
      {
        isRight: true,
        label: "ref_06_usp_0",
        title: [
          <>
            Are you sure your
            <br />
            water dispenser clean enough?
          </>,
        ],
        subMsg: [<>UVnano</>],
        videoUrl: "https://vimeo.com/656554445/150b3b7a3b",
        x: 1217,
        y: 593,
        ga_id: "uvnano",
      },
      {
        isRight: true,
        label: "ref_06_usp_0",
        title: [
          <>
            Enjoy your water more clean
            <br />
            with 3 fileration system
          </>,
        ],
        subMsg: [<>Water Filter</>],
        videoUrl: "https://vimeo.com/656554491/00a0640345",
        x: 1217,
        y: 743,
        ga_id: "water_filter",
      },
    ],
  },
  {
    category: "refrigerator",
    modelNo: "ref_01", //p-next3
    bgImgUrl: "/images/usp/refrigerator/p_next3.jpg",
    uspList: [
      {
        label: "ref01_usp_0",
        title: [
          <>
            Are you actually care about
            <br />
            your food expiration dates?
          </>,
        ],
        subMsg: [<>Smart InstaView</>],
        videoUrl: "https://vimeo.com/654017386/d636e7e05a",
        x: 178,
        y: 226,
        ga_id: "smart_instaview",
      },
      {
        label: "ref01_usp_0",
        title: [
          <>
            Checking inside of
            <br />
            the fridge door? Knock twice.
          </>,
        ],
        subMsg: [<>InstaView Door-in-Door</>],
        videoUrl: "https://vimeo.com/654017782/55122892e1",
        x: 178,
        y: 376,
        ga_id: "instaview_door-in-door",
      },
      {
        label: "ref01_usp_0",
        title: [
          <>
            For the freshness,
            <br />
            just set up the temperature.
          </>,
        ],
        subMsg: [<>LINEARCooling</>],
        videoUrl: "https://vimeo.com/654017823/55ed23b790",
        x: 178,
        y: 526,
        ga_id: "linearcooling",
      },
      {
        isRight: true,
        label: "ref01_usp_0",
        title: [
          <>
            Shhhh, this is the know-how
            <br />
            of fast cooling.
          </>,
        ],
        subMsg: [<>DoorCooling+</>],
        videoUrl: "https://vimeo.com/654017847/d683de1bca",
        x: 1217,
        y: 376,
        ga_id: "doorcooling+",
      },
      {
        isRight: true,
        label: "ref01_usp_0",
        title: [
          <>
            Are you sure your
            <br />
            water dispenser clean enough?
          </>,
        ],
        subMsg: [<>Uvnano</>],
        videoUrl: "https://vimeo.com/654017876/4d99a7af57",
        x: 1217,
        y: 526,
        ga_id: "uvnano",
      },
    ],
  },
  {
    category: "refrigerator",
    modelNo: "vs4",
    bgImgUrl: "/images/usp/refrigerator/vs4.jpg",
    uspList: [
      {
        label: "vs4_usp_0",
        title: [
          <>
            Checking inside of
            <br />
            the fridge door? Knock twice.
          </>,
        ],
        subMsg: [<>InstaView Door-in-Door</>],
        videoUrl: "https://vimeo.com/656554730/34885f949f",
        x: 178,
        y: 251,
        ga_id: "instaview_door-in-door",
      },
      {
        label: "vs4_usp_0",
        title: [<>Enjoy your chilled drink longer.</>],
        subMsg: [<>Craft Ice</>],
        videoUrl: "https://vimeo.com/656554777/79d02f0f6b",
        x: 178,
        y: 401,
        ga_id: "craft_ice",
      },
      {
        label: "vs4_usp_0",
        title: [
          <>
            For the freshness,
            <br />
            just set up the temperature.
          </>,
        ],
        subMsg: [<>LINEARCooling</>],
        videoUrl: "https://vimeo.com/656554821/18b7c3bb68",
        x: 178,
        y: 526,
        ga_id: "linearcooling",
      },
      {
        isRight: true,
        label: "vs4_usp_0",
        title: [
          <>
            Shhhh, this is the know-how
            <br />
            of fast cooling.
          </>,
        ],
        subMsg: [<>DoorCooling+</>],
        videoUrl: "https://vimeo.com/656554879/579197afca",
        x: 1223,
        y: 376,
        ga_id: "doorcooling",
      },
      {
        isRight: true,
        label: "vs4_usp_0",
        title: [
          <>
            Are you sure your
            <br />
            water dispenser clean enough?
          </>,
        ],
        subMsg: [<>Uvnano</>],
        videoUrl: "https://vimeo.com/656554908/477b55cdf5",
        x: 1223,
        y: 526,
        ga_id: "uvnano",
      },
    ],
  },
  {
    category: "refrigerator",
    modelNo: "p_next3_voice",
    bgImgUrl: "/images/usp/refrigerator/p_next3_voice.jpg",
    uspList: [
      {
        label: "p_next3_voice_usp_0",
        title: [
          <>
            If your both hands full,
            <br />
            how do you open?
          </>,
        ],
        subMsg: [<>Voice Control</>],
        videoUrl: "https://vimeo.com/656554015/a551c206fc",
        x: 178,
        y: 226,
        ga_id: "voice_control",
      },
      {
        label: "p_next3_voice_usp_0",
        title: [
          <>
            Checking inside of
            <br />
            the fridge door? Knock twice.
          </>,
        ],
        subMsg: [<>InstaView Door-in-Door</>],
        videoUrl: "https://vimeo.com/656554077/bedee2bf0b",
        x: 178,
        y: 376,
        ga_id: "instaview_door-in-door",
      },
      {
        label: "p_next3_voice_usp_0",
        title: [
          <>
            For the freshness,
            <br />
            just set up the temperature.
          </>,
        ],
        subMsg: [<>LINEARCooling</>],
        videoUrl: "https://vimeo.com/656554120/8d9906f7f4",
        x: 178,
        y: 526,
        ga_id: "linearcooling",
      },
      {
        isRight: true,
        label: "p_next3_voice_usp_0",
        title: [
          <>
            Shhhh, this is the know-how
            <br />
            of fast cooling.
          </>,
        ],
        subMsg: [<>DoorCooling+</>],
        videoUrl: "https://vimeo.com/656554153/e6baaa5286",
        x: 1217,
        y: 376,
        ga_id: "doorcooling+",
      },
      {
        isRight: true,
        label: "p_next3_voice_usp_0",
        title: [
          <>
            Are you sure your
            <br />
            water dispenser clean enough?
          </>,
        ],
        subMsg: [<>Uvnano</>],
        videoUrl: "https://vimeo.com/656554193/11590d0510",
        x: 1217,
        y: 526,
        ga_id: "uvnano",
      },
    ],
  },
];

export default uspData_Refrigerator;
