import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_Dishwasher: UspPageDataType[] = [
  {
    category: "dishwasher",
    modelNo: "xd2_steam",
    bgImgUrl: "/images/usp/dishwasher/xd2_steam.jpg",
    uspList: [
      {
        label: "xd2_steam_usp_0",
        title: [
          <>
            Clean your dishes
            <br />
            every corner without
            <br />
            any tiny stains.
          </>,
        ],
        subMsg: [<>QuadWash Pro</>],
        videoUrl: "https://vimeo.com/658495410/3a73f43cfc",
        x: 148,
        y: 343,
        ga_id: "quadwash_pro",
      },
      {
        label: "xd2_steam_usp_0",
        title: [
          <>
            Feel the clean dishes with
            <br />
            powerful drying.
          </>,
        ],
        subMsg: [<>Dynamic Heat Dry</>],
        videoUrl: "https://vimeo.com/658495365/42a8471cee",
        x: 148,
        y: 530,
        ga_id: "dynamic_heat_dry",
      },
      {
        isRight: true,
        label: "xd2_steam_usp_0",
        title: [<>Can you dishwasher sterilize?</>],
        subMsg: [<>TrueSteam</>],
        videoUrl: "https://vimeo.com/658495442/182a468299",
        x: 1257,
        y: 606,
        ga_id: "truesteam",
      },
    ],
  },
  {
    category: "dishwasher",
    modelNo: "xd3",
    bgImgUrl: "/images/usp/dishwasher/xd3.jpg",
    uspList: [
      {
        label: "xd3_usp_0",
        title: [
          <>
            Would be awesome
            <br />
            if we do not dish-wash twice.
          </>,
        ],
        subMsg: [<>QuadWash</>],
        videoUrl: "https://vimeo.com/654774067/3c08545d45",
        x: 148,
        y: 343,
        ga_id: "quadwash",
      },
      {
        label: "xd3_usp_0",
        title: [
          <>
            Feel the dishes,
            <br />
            they are crisply dried.
          </>,
        ],
        subMsg: [<>Dynamic Dry</>],
        videoUrl: "https://vimeo.com/654774028/fed2a6fb2f",
        x: 148,
        y: 493,
        ga_id: "dynamic_dry",
      },
      {
        isRight: true,
        label: "xd3_usp_0",
        title: [<>Can you dishwasher sterilize?</>],
        subMsg: [<>TrueSteam</>],
        videoUrl: "https://vimeo.com/654774103/8d3fe53101",
        x: 1257,
        y: 606,
        ga_id: "truesteam",
      },
    ],
  },
  {
    category: "dishwasher",
    modelNo: "xd3_steam",
    bgImgUrl: "/images/usp/dishwasher/xd3_steam.jpg",
    uspList: [
      {
        label: "xd3_steam_usp_0",
        title: [
          <>
            Clean your dishes
            <br />
            every corner without
            <br />
            any tiny stains.
          </>,
        ],
        subMsg: [<>QuadWash Pro</>],
        videoUrl: "https://vimeo.com/658499653/991d445a6c",
        x: 148,
        y: 343,
        ga_id: "quadwash_pro",
      },
      {
        label: "xd3_steam_usp_0",
        title: [
          <>
            Feel the clean dishes with
            <br />
            powerful drying.
          </>,
        ],
        subMsg: [<>Dynamic Dry</>],
        videoUrl: "https://vimeo.com/658499616/8582ee5539",
        x: 148,
        y: 530,
        ga_id: "dynamic_dry",
      },
      {
        isRight: true,
        label: "xd3_steam_usp_0",
        title: [<>Can you dishwasher sterilize?</>],
        subMsg: [<>TrueSteam</>],
        videoUrl: "https://vimeo.com/658499676/afb8fb968b",
        x: 1257,
        y: 606,
        ga_id: "truesteam",
      },
    ],
  },
  {
    category: "dishwasher",
    modelNo: "xd4_steam",
    bgImgUrl: "/images/usp/dishwasher/xd4_steam.jpg",
    uspList: [
      {
        label: "xd4_steam_usp_0",
        title: [
          <>
            Would be awesome
            <br />
            if we do not dish-wash twice.
          </>,
        ],
        subMsg: [<>QuadWash</>],
        videoUrl: "https://vimeo.com/658500097/56f263dd74",
        x: 148,
        y: 343,
        ga_id: "quadwash",
      },
      {
        label: "xd4_steam_usp_0",
        title: [
          <>
            Feel the dishes,
            <br />
            they are crisply dried.
          </>,
        ],
        subMsg: [<>Dynamic Dry</>],
        videoUrl: "https://vimeo.com/658500057/1052c301a5",
        x: 148,
        y: 493,
        ga_id: "dynamic_dry",
      },
      {
        isRight: true,
        label: "xd4_usp_0",
        title: [<>Can you dishwasher sterilize?</>],
        subMsg: [<>TrueSteam</>],
        videoUrl: "https://vimeo.com/658500127/1e67932caf",
        x: 1257,
        y: 606,
        ga_id: "truesteam",
      },
    ],
  },
  {
    category: "dishwasher",
    modelNo: "xd5",
    bgImgUrl: "/images/usp/dishwasher/xd5.jpg",
    uspList: [
      {
        label: "xd5_usp_0",
        title: [
          <>
            Would be awesome
            <br />
            if we do not dish-wash twice.
          </>,
        ],
        subMsg: [<>QuadWash</>],
        videoUrl: "https://vimeo.com/654774176/fc8f773c99",
        x: 148,
        y: 472,
        ga_id: "quadwash",
      },
      {
        isRight: true,
        label: "xd5_usp_0",
        title: [
          <>
            Feel the dishes,
            <br />
            they are crisply dried.
          </>,
        ],
        subMsg: [<>Dynamic Dry</>],
        videoUrl: "https://vimeo.com/654774140/47e87e07b2",
        x: 1257,
        y: 606,
        ga_id: "dynamic_dry",
      },
    ],
  },
  {
    category: "dishwasher",
    modelNo: "xd6",
    bgImgUrl: "/images/usp/dishwasher/xd6.jpg",
    uspList: [
      {
        label: "xd6_usp_0",
        title: [
          <>
            Would be awesome
            <br />
            if we do not dish-wash twice.
          </>,
        ],
        subMsg: [<>QuadWash</>],
        videoUrl: "https://vimeo.com/654774216/d12035e326",
        x: 148,
        y: 472,
        ga_id: "quadwash",
      },
      {
        isRight: true,
        label: "xd6_usp_0",
        title: [
          <>
            Feel the dishes,
            <br />
            they are crisply dried.
          </>,
        ],
        subMsg: [<>Dynamic Dry</>],
        videoUrl: "https://vimeo.com/654774201/3095df5e3e",
        x: 1257,
        y: 606,
        ga_id: "dynamic_dry",
      },
    ],
  },
];

export default uspData_Dishwasher;
