/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Box, Html, useGLTF, useTexture} from '@react-three/drei'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader} from "@react-three/fiber";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {
    refrigerator_tl_data,
    refrigerator_bl_data,
    refrigerator_br_data,
    Objet_ColorCategoryType
} from "../../../../api/products/objetProductsData";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {LangLabelType} from "../../../../api/lang/langData";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {useSpring, animated} from "@react-spring/web";
import {a} from "@react-spring/three";
import {useModalTutorial} from "../../../../stores/modalStore";
import {easeCubicInOut} from "d3-ease";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useCameraStore, {CameraState} from "../../../../stores/cameraStore";
import useHistoryStore from "../../../../stores/historyStore";
import {GLTF} from 'three-stdlib/loaders/GLTFLoader';


type GLTFResult = GLTF & {
    nodes: {
        m_next3: THREE.Mesh
        Door_BL015: THREE.Mesh
        Door_BR015: THREE.Mesh
        Door_TL015: THREE.Mesh
        Glass016: THREE.Mesh
        UI016: THREE.Mesh
    }
    materials: {
        mat_mnext3_body: THREE.MeshStandardMaterial
        mat_mnext3_door_bl: THREE.MeshStandardMaterial
        mat_mnext3_door_br: THREE.MeshStandardMaterial
        mat_mnext3_door_tl: THREE.MeshStandardMaterial
        mat_mnext3_glass: THREE.MeshStandardMaterial
        mat_mnext3_ui: THREE.MeshStandardMaterial
    }
}


const cameraApiSelector = (state: CameraState) => state.api;

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_mnext3_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_mnext3_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Mnext3.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Mnext3.jpg');

const COLOR_0_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Beige.jpg';
const COLOR_1_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Black.jpg';
const COLOR_2_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Botanic.jpg';
const COLOR_3_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Clay_Brown.jpg';
const COLOR_4_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Grey.jpg';
const COLOR_5_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Manhattan_Midnight.jpg';
const COLOR_6_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Mint.jpg';
const COLOR_7_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Pink.jpg';
const COLOR_8_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Red_Wood.jpg';
const COLOR_9_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Sand.jpg';
const COLOR_10_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Sliver.jpg';
const COLOR_11_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Solid_Green.jpg';
const COLOR_12_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Solid_Sliver.jpg';
const COLOR_13_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_Stone.jpg';
const COLOR_14_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/m_next3/M-Next3 InstaView_Albedo_White.jpg';


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>(null!)
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL,
        COLOR_8_URL, COLOR_9_URL, COLOR_10_URL, COLOR_11_URL, COLOR_12_URL, COLOR_13_URL, COLOR_14_URL
    ]);

    const colorCategoryTL = useObjetColorStore(state => state.refrigerator_tl_ColorCategory);
    const colorLabelTL = useObjetColorStore(state => state.refrigerator_tl_ColorLabel);
    const colorCategoryBL = useObjetColorStore(state => state.refrigerator_bl_ColorCategory);
    const colorLabelBL = useObjetColorStore(state => state.refrigerator_bl_ColorLabel);
    const colorCategoryBR = useObjetColorStore(state => state.refrigerator_br_ColorCategory);
    const colorLabelBR = useObjetColorStore(state => state.refrigerator_br_ColorLabel);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isDoorOneSet, setIsDoorOneSet] = useState(false);
    const [isDoorTwoSet, setIsDoorTwoSet] = useState(false);
    const [isDoorThrSet, setIsDoorThrSet] = useState(false);

    const [cameraTargetWorldPos] = useState(() => new THREE.Vector3());
    const [worldPos] = useState(() => new THREE.Vector3());
    const {setTargetPos, setLookPos} = useCameraStore(cameraApiSelector);
    const cameraTargetRef = useRef<THREE.Mesh>(null!);
    const lookTargetRef = useRef<THREE.Mesh>(null!);

    const selectPlusHandler = useCallback(() => {
        if (!cameraTargetRef.current) return;

        cameraTargetRef.current.getWorldPosition(cameraTargetWorldPos);
        lookTargetRef.current.getWorldPosition(worldPos);
        worldPos.add(new THREE.Vector3(0, 0, 0));
        setTargetPos(cameraTargetWorldPos);
        setLookPos(worldPos);
    }, []);

    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const deactiveDoorMarker = useCallback((doorIdx?: number) => {
        if (doorIdx === 0) {
            setIsDoorOneSet(true);
        } else if (doorIdx === 1) {
            setIsDoorTwoSet(true);
        } else if (doorIdx === 2) {
            setIsDoorThrSet(true);
        }
    }, []);

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, doorIdx?: number, isFirst: boolean = false) => {

        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) deactiveDoorMarker(doorIdx);

        if (colorCategory === 'Fenix') {
            if (colorLabel === 'objet_color_botanic') {
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[2];
            } else if (colorLabel === 'objet_color_sand') {
                colorTextures[9].flipY = false;
                colorTextures[9].encoding = THREE.sRGBEncoding;
                colorTextures[9].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[9];
            } else if (colorLabel === 'objet_color_stone') {
                colorTextures[13].flipY = false;
                colorTextures[13].encoding = THREE.sRGBEncoding;
                colorTextures[13].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[13];
            }
        } else if (colorCategory === 'Solid') {
            if (colorLabel === 'objet_color_midnight') {
                colorTextures[5].flipY = false;
                colorTextures[5].encoding = THREE.sRGBEncoding;
                colorTextures[5].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[5];
            } else if (colorLabel === 'objet_color_silver') {
                colorTextures[12].flipY = false;
                colorTextures[12].encoding = THREE.sRGBEncoding;
                colorTextures[12].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[12];
            } else if (colorLabel === 'objet_color_green') {
                colorTextures[11].flipY = false;
                colorTextures[11].encoding = THREE.sRGBEncoding;
                colorTextures[11].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[11];
            }
        } else if (colorCategory === 'Mist') {
            if (colorLabel === 'objet_color_beige') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_pink') {
                colorTextures[7].flipY = false;
                colorTextures[7].encoding = THREE.sRGBEncoding;
                colorTextures[7].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[7];
            } else if (colorLabel === 'objet_color_mint') {
                colorTextures[6].flipY = false;
                colorTextures[6].encoding = THREE.sRGBEncoding;
                colorTextures[6].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[6];
            } else if (colorLabel === 'objet_color_silver') {
                colorTextures[10].flipY = false;
                colorTextures[10].encoding = THREE.sRGBEncoding;
                colorTextures[10].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[10];
            } else if (colorLabel === 'objet_color_redwood') {
                colorTextures[8].flipY = false;
                colorTextures[8].encoding = THREE.sRGBEncoding;
                colorTextures[8].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[8];
            } else if (colorLabel === 'objet_color_clay_brown') {
                colorTextures[3].flipY = false;
                colorTextures[3].encoding = THREE.sRGBEncoding;
                colorTextures[3].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[3];
            }
        } else if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_gray') {
                colorTextures[4].flipY = false;
                colorTextures[4].encoding = THREE.sRGBEncoding;
                colorTextures[4].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[4];
            } else if (colorLabel === 'objet_color_black') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            } else if (colorLabel === 'objet_color_white') {
                colorTextures[14].flipY = false;
                colorTextures[14].encoding = THREE.sRGBEncoding;
                colorTextures[14].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[14];
            }
        }
    }, []);

    useLayoutEffect(() => {
        changeTexture("mat_mnext3_door_tl", colorCategoryTL, colorLabelTL, 0);
    }, [colorCategoryTL, colorLabelTL]);

    useLayoutEffect(() => {
        changeTexture("mat_mnext3_door_bl", colorCategoryBL, colorLabelBL, 1);
    }, [colorCategoryBL, colorLabelBL]);

    useLayoutEffect(() => {
        changeTexture("mat_mnext3_door_br", colorCategoryBR, colorLabelBR, 2);
    }, [colorCategoryBR, colorLabelBR]);


    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials.mat_mnext3_body.lightMap = lightmapTex;
        materials.mat_mnext3_door_tl.lightMap = lightmapTex;
        materials.mat_mnext3_door_bl.lightMap = lightmapTex;
        materials.mat_mnext3_door_br.lightMap = lightmapTex;
        materials.mat_mnext3_glass.lightMap = lightmapTex;

        materials.mat_mnext3_door_tl.lightMapIntensity = 1;
        materials.mat_mnext3_door_bl.lightMapIntensity = 1;
        materials.mat_mnext3_door_br.lightMapIntensity = 1;

        materials.mat_mnext3_door_tl.roughness = 0.3;
        materials.mat_mnext3_door_bl.roughness = 0.3;
        materials.mat_mnext3_door_br.roughness = 0.3;

    }, [lightmapTex]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isDoorOneSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut},
            });
        }
    }, [isOpenTutorial]);


    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL,
                COLOR_8_URL, COLOR_9_URL, COLOR_10_URL, COLOR_11_URL, COLOR_12_URL, COLOR_13_URL, COLOR_14_URL
            ])
        }
    }, []);

    const [idx, setIdx] = useState(-1);
    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.m_next3.geometry}
                material={materials.mat_mnext3_body}
                position={[0.59, 0.3999, -5.9879]}>
                <mesh geometry={nodes.Door_BL015.geometry} material={materials.mat_mnext3_door_bl}/>
                <mesh geometry={nodes.Door_BR015.geometry} material={materials.mat_mnext3_door_br}/>
                <mesh geometry={nodes.Door_TL015.geometry} material={materials.mat_mnext3_door_tl}/>
                <mesh geometry={nodes.Glass016.geometry} material={materials.mat_mnext3_glass}/>
                <mesh
                    geometry={nodes.UI016.geometry}
                    material={materials.mat_mnext3_ui}
                    position={[0.045, 0, 0.0006]}
                />
            </mesh>

            <>
                <ObjetProductContainer3D
                    args={[0.45, 0.98, 0.01]}
                    position={[0.36, 1.762, -6.0]}
                    data={refrigerator_tl_data}
                    showDebug={false}
                    cameraTargetPos={[0, 0, 3]}
                    selectMeCallback={() => {
                        setIdx(0);
                        selectPlusHandler();
                    }}
                />
                <ObjetProductContainer3D
                    args={[0.45, 0.75, 0.01]}
                    position={[0.36, 0.825, -6.0]}
                    data={refrigerator_bl_data}
                    showDebug={false}
                    cameraTargetPos={[0, 0.5, 3]}
                    selectMeCallback={() => {
                        setIdx(1);
                        selectPlusHandler();
                    }}
                />
                <ObjetProductContainer3D
                    args={[0.45, 0.75, 0.01]}
                    position={[0.815, 0.825, -6.0]}
                    data={refrigerator_br_data}
                    showDebug={false}
                    cameraTargetPos={[0, 0.5, 3]}
                    selectMeCallback={() => {
                        setIdx(2);
                        selectPlusHandler();
                    }}
                />
            </>

            <mesh
                geometry={nodes.m_next3.geometry}
                position={[0.59, 0.3999, -5.9879]}
                visible={!isDoorOneSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
            </mesh>

            <mesh
                geometry={nodes.UI016.geometry}
                position={[0.59, 0.3999, -5.9879]}
                visible={!isDoorOneSet}
                scale={1.3}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
            </mesh>

            <mesh
                geometry={nodes.Door_TL015.geometry}
                position={[0.59, 0.3999, -5.9879]}
                userData={{name: 'Door_TL.015'}}
                visible={!isDoorOneSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[-0.225, 1.4, 0]} visible={!(isDoorOneSet)} opacity={spring.opacity}
                                 color={(idx === 0 && pathname.indexOf('refrigerator_tl') > -1) ? '#be1e42' : '#333'}/>
            </mesh>

            <mesh
                geometry={nodes.Door_BL015.geometry}
                material={materials.mat_mnext3_door_bl}
                position={[0.59, 0.3999, -5.9879]}
                userData={{name: 'Door_BL.015'}}
                visible={!isDoorTwoSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[-0.225, 0.45, 0]} visible={!(isDoorTwoSet)} opacity={spring.opacity}
                                 color={(idx === 1 && pathname.indexOf('refrigerator_bl') > -1) ? '#be1e42' : '#333'}/>
            </mesh>

            <mesh
                geometry={nodes.Door_BR015.geometry}
                material={materials.mat_mnext3_door_br}
                position={[0.59, 0.3999, -5.9879]}
                userData={{name: 'Door_BR.015'}}
                visible={!isDoorThrSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0.225, 0.45, 0]} visible={!(isDoorThrSet)} opacity={spring.opacity}
                                 color={(idx === 2 && pathname.indexOf('refrigerator_br') > -1) ? '#be1e42' : '#333'}/>
            </mesh>

            {/* 카메라 타겟 */}
            <group position={[0.36, 1.4, -6.0]}>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={[0, 0, 0]}
                    ref={lookTargetRef}
                >
                    <meshBasicMaterial wireframe color={"#0f0"}/>
                </Box>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={[0, 0, 4]}
                    ref={cameraTargetRef}
                >
                    <meshBasicMaterial wireframe color={"#f00"}/>
                </Box>
            </group>
        </group>
    )
}
