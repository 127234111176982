import { ProductsCategoryType } from "./productsData";

export const getVimeoLink = (product: ProductsCategoryType) => {
  switch (product) {
    case "air_care":
      return "https://vimeo.com/662009665/861c709fe1";
    case "air_conditioner":
      return "https://vimeo.com/662009622/87a9ba8b6e";
    case "cooking":
      return "https://vimeo.com/662009917/7229926fdd";
    case "dishwasher":
      return "https://vimeo.com/662009879/7217744af9";
    case "homebrew":
      return "https://vimeo.com/662009960/674e5200dd";
    case "microwave_oven":
      return "https://vimeo.com/662009816/bd2b0e79eb";
    case "refrigerator":
      return "https://vimeo.com/662009851/61ffccb2bc";
    case "styler":
      return "https://vimeo.com/662009570/15ef4c4ecf";
    case "tiiun":
      return "https://vimeo.com/662009697/aeb1a178c0";
    case "tiiun_mini":
      return "https://vimeo.com/662009697/aeb1a178c0";
    case "vacuum_cleaner":
      return "https://vimeo.com/662009601/caf3aad1b8";
    case "washer_n_dryer":
      return "https://vimeo.com/662009368/02eec1dc07";
    case "water_purifier":
      return "https://vimeo.com/662009786/7bddbb30cc";
    case "wearable_air_purifier":
      return "https://vimeo.com/662009756/585a63f2b2";
    default:
      return "";
  }
};

export type ProductsCESType =
  | "refrigerator_f/door" //냉장고
  | "lupin2_gas_double_best" //오븐
  | "scallet2_good" //전자렌지
  | "washtower_27" //세탁기
  | "air_care_360_tower" //공기청정기
  | "tiiun" //식물재배기
  | "tiiun_mini"; //식물재배기 (미니)

export const getVimeoCESLink = (product: ProductsCESType) => {
  switch (product) {
    case "refrigerator_f/door":
      return "https://vimeo.com/662024131/f040e2068c";
    case "lupin2_gas_double_best":
      return "https://vimeo.com/662024199/fde707fef7";
    case "scallet2_good":
      return "https://vimeo.com/662024112/41f63ff39c";
    case "washtower_27":
      return "https://vimeo.com/662024246/b4e76fb2c9";
    case "air_care_360_tower":
      return "https://vimeo.com/662024161/cbbf690430";
    case "tiiun":
      return "https://vimeo.com/662024040/68bb813bcf";
    case "tiiun_mini":
      return "https://vimeo.com/662024079/1ed377ddc4";
    default:
      return "";
  }
};
