/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {useGLTF} from "@react-three/drei";

type GLTFResult = GLTF & {
    nodes: {
        scallet2_good: THREE.Mesh
        ['20_Glass']: THREE.Mesh
        Decal: THREE.Mesh
        ['20_Metal']: THREE.Mesh
        Plane: THREE.Mesh
        Plane001: THREE.Mesh
    }
    materials: {
        ['18_Body']: THREE.MeshStandardMaterial
        ['20_Glass']: THREE.MeshStandardMaterial
        ['20_Metal.001']: THREE.MeshStandardMaterial
        MetalSilver: THREE.MeshStandardMaterial
        MetalBlack: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/microwave/m/scallet2_good_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/microwave/scallet2_good_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/microwave/scallet2_good.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;
    // const {nodes, materials, scene} = useGLTF<GLTFResult>(GLTF_URL) as GLTFResult;

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct && selectedProduct?.modelNo === 'scallet2_good'){
            if(selectedColorIndex === 1){
                mesh1_Ref.current.material = materials.MetalSilver;
            }
            else{
                mesh1_Ref.current.material = materials.MetalBlack;
            }
            mesh1_Ref.current.material.needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        mesh1_Ref.current.material = materials.MetalBlack;

        materials['20_Metal.001'].metalnessMap = materials['20_Metal.001'].map;
        materials['20_Metal.001'].emissiveIntensity = 3;
        materials['20_Metal.001'].roughness = 0.5;
        materials['20_Metal.001'].metalness = 0;
        materials['20_Metal.001'].needsUpdate = true;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0.008, -0.015, 0.27]} scale={[1.01,1,1]}>
            <mesh
                geometry={nodes.scallet2_good.geometry}
                material={materials['18_Body']}
                userData={{ name: 'scallet2_good' }}>
                <mesh
                    geometry={nodes['20_Glass'].geometry}
                    material={materials['20_Glass']}
                    userData={{ name: '20_Glass' }}
                />
                <mesh
                    geometry={nodes.Decal.geometry}
                    material={materials['20_Metal.001']}
                    position={[-0.0161, 0, 0.0013]}
                    userData={{ name: 'Decal' }}
                />
                <mesh
                    ref={mesh1_Ref}
                    geometry={nodes['20_Metal'].geometry}
                    material={nodes['20_Metal'].material}
                    userData={{ name: '20_Metal' }}

                />
            </mesh>
            <mesh
                geometry={nodes.Plane.geometry}
                material={nodes.Plane.material}
                userData={{ name: 'Plane' }}
            />
            <mesh
                geometry={nodes.Plane001.geometry}
                material={materials.MetalBlack}
                userData={{ name: 'Plane.001' }}
            />

            <mesh position={[-0.005, 0.47, -0.215]}>
                <boxBufferGeometry args={[0.76, 0.1, 0.3]} />
                <meshStandardMaterial color={"#111"} />
            </mesh>
        </group>
    )
}

// /*
// Auto-generated by: https://github.com/pmndrs/gltfjsx
// */
//
// import * as THREE from 'three'
// import React, {useEffect, useRef} from 'react'
// import { useGLTF } from '@react-three/drei'
// import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
// import {ROOT_PATH_PREFIX} from "../../components/common/const";
// import {disposeScene} from "../../utils/disposeScene";
// import {useLoader} from "@react-three/fiber";
// import {GLTFLoader} from "three-stdlib";
//
// type GLTFResult = GLTF & {
//   nodes: {
//     ['20_Glass']: THREE.Mesh
//     ['20_Metal001']: THREE.Mesh
//     ['20_Body']: THREE.Mesh
//     ['20_Metal']: THREE.Mesh
//   }
//   materials: {
//     ['20_Glass']: THREE.MeshStandardMaterial
//     ['20_Metal.001']: THREE.MeshStandardMaterial
//     ['20_Body']: THREE.MeshStandardMaterial
//     ['20_Metal']: THREE.MeshStandardMaterial
//   }
// }
//
// export default function Model(props: JSX.IntrinsicElements['group']) {
//   const group = useRef<THREE.Group>()
//   const { nodes, materials,scene } = useGLTF(ROOT_PATH_PREFIX + '/models/12.14/scarlett_good/scarlett_good.gltf') as GLTFResult
//
//     useEffect(() => {
//         return () => {
//             disposeScene(scene);
//             useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/models/12.14/scarlett_good/scarlett_good.gltf');
//         }
//     }, []);
//
//   return (
//     <group ref={group} {...props} position={[0,-0.03,0.23]} dispose={null}>
//       <mesh
//         geometry={nodes['20_Glass'].geometry}
//         material={materials['20_Glass']}
//         position={[-0.0916, 0.1833, -0.0617]}
//         scale={[0.01, 0.01, 0.01]}
//         userData={{ name: '20_Glass' }}
//       />
//       <mesh
//         geometry={nodes['20_Metal001'].geometry}
//         material={materials['20_Metal.001']}
//         position={[-0.0916, 0.1833, -0.0631]}
//         scale={[0.01, 0.01, 0.01]}
//         userData={{ name: '20_Metal.001' }}
//       />
//       <mesh
//         geometry={nodes['20_Body'].geometry}
//         material={materials['20_Body']}
//         position={[-0.4688, 0.4184, -0.4038]}
//         scale={[0.01, 0.01, 0.01]}
//         userData={{ name: '20_Body' }}
//       />
//       <mesh
//         geometry={nodes['20_Metal'].geometry}
//         material={materials['20_Metal']}
//         position={[-0.0916, 0.1833, -0.0631]}
//         scale={[0.01, 0.01, 0.01]}
//         userData={{ name: '20_Metal' }}
//       />
//         <mesh position={[0, 0.47, -0.06]}>
//             <boxBufferGeometry args={[0.76, 0.1, 0.1]} />
//             <meshStandardMaterial color={"#111"} />
//         </mesh>
//     </group>
//   )
// }
//
// // useGLTF.preload('/models/12.14/scarlett_good/scarlett_good.gltf')
