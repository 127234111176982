import {Color} from "@react-three/fiber";
import {useBox} from "@react-three/cannon";
import React from "react";

type ObstacleBoxProps = {
    type?: "Static" | "Dynamic" | "Kinematic" | undefined;
    isTrigger?: boolean | undefined;
    position?: [x: number, y: number, z: number];
    color?: Color;
    wireframe?: boolean;
    collisionFilterGroup?: number | undefined;
    collisionFilterMask?: number | undefined;
    size: [number, number, number];
} & JSX.IntrinsicElements['mesh'];
export const Obstacle = ({
                             type,
                             isTrigger = false,
                             position = [0, 0, 0],
                             color,
                             wireframe = false,
                             size = [1, 1, 1],
                             collisionFilterGroup,
                             collisionFilterMask,
                             ...rest
                         }: ObstacleBoxProps) => {
    const [ref, api] = useBox(() => ({
        mass: 1,
        type: type,
        isTrigger: isTrigger,
        position: position,
        args: [size[0] * 1.01, size[1] * 1.01, size[2] * 1.01],
        collisionFilterGroup: collisionFilterGroup,
        collisionFilterMask: collisionFilterMask
    }));

    return (
        <mesh ref={ref} position={position} {...rest}>
            <boxBufferGeometry args={size}/>
            <meshBasicMaterial color={color} wireframe={true} opacity={0.5} transparent={true}/>
        </mesh>
    );
}

export default Obstacle;
