import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_TiiunMini: UspPageDataType[] = [
  {
    category: "tiiun_mini",
    modelNo: "tiiun_mini",
    bgImgUrl: "/images/usp/tiiun/tiiun_mini.jpg",
    uspList: [
      {
        isRight: true,
        label: "tiiun_usp_0",
        title: [
          <>
            Experience indoor gardening
            <br />
            and decorate your house.
          </>,
        ],
        subMsg: [<>Simple All-in-one</>],
        videoUrl: "https://vimeo.com/659596880/afacb43620",
        x: 1330,
        y: 406,
        ga_id: "simple_all-in-one",
      },
    ],
  },
];

export default uspData_TiiunMini;
