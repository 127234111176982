/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {air_care_aero_data, Objet_ColorCategoryType} from "../../../../api/products/objetProductsData";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {LangLabelType} from "../../../../api/lang/langData";
import {disposeScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {animated, useSpring} from "@react-spring/web";
import {useModalTutorial} from "../../../../stores/modalStore";
import {easeCubicInOut} from "d3-ease";
import {a} from '@react-spring/three';
import {GLTFResult_ObjetNaturalProducts} from './Product_Harvester';
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_AeroTower.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_AeroTower.jpg');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/aero_tower/white_mat_body_BaseColor.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/aero_tower/white_mat_body_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/aero_tower/red_Bake1_pbr_diffuse.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/aero_tower/red_Bake1_pbr_diffuse.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/aero_tower/green_Bake1_pbr_diffuse.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/aero_tower/green_Bake1_pbr_diffuse.jpg');
const COLOR_3_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/aero_tower/brown_Bake1_pbr_diffuse.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/aero_tower/brown_Bake1_pbr_diffuse.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL]);

    const colorCategory = useObjetColorStore(state => state.air_care_aero_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.air_care_aero_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({from: {opacity: 0}}));

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory === 'Nature') {
            if (colorLabel === 'objet_color_cream_white') {
                if (!isFirst) setIsSet(true);
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_redwood') {
                if (!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            } else if (colorLabel === 'objet_color_green') {
                if (!isFirst) setIsSet(true);
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[2];
            } else if (colorLabel === 'objet_color_clay_brown') {
                if (!isFirst) setIsSet(true);
                colorTextures[3].flipY = false;
                colorTextures[3].encoding = THREE.sRGBEncoding;
                colorTextures[3].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[3];
            }
        }
    }, []);

    useLayoutEffect(() => {
        changeTexture('mat_body.005', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials["mat_body.005"].lightMap = lightmapTex;

        changeTexture("mat_body.005", "Nature", "objet_color_cream_white", true);
    }, [lightmapTex]);


    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL]);
        }
    }, []);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.aero_tower.geometry}
                material={materials['mat_body.005']}
                position={[-2.1699, 0.305, 0.7231]}
            />

            <mesh
                geometry={nodes.aero_tower.geometry}
                position={[-2.1699, 0.305, 0.7231]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.7, 0]} visible={!(isSet)} opacity={spring.opacity} color={pathname.indexOf('/air_care_aero') > -1 ? '#be1e42' : '#000'} />
            </mesh>

            <ObjetProductContainer3D
                args={[0.25, 1.1, 0.25]}
                position={[-2.1699, 0.89, 0.7231]}
                data={air_care_aero_data}
                showDebug={false}
                cameraTargetPos={[0, 0.3, 3]}
            />
        </group>
    )
}
