import React, { useState } from "react";
import styled from "styled-components";
import { useModalSurveyMain } from "../../../stores/modalStore";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../../common/const";
import CloseButton from "../../common/navigation/CloseButton";
import useResizeStore from "../../../stores/resizeStore";
import { Text } from "../comps_styled/Text.styled";
import theme from "../../../styles/theme.styled";
import { Divider } from "../comps_styled/Divider.styled";
import { CheckOutlined } from "@ant-design/icons";
import { GA_Event } from "../../../api/ga";
import { Image } from "../comps_styled/Image.styled";

const SurveyMain = (props: JSX.IntrinsicElements["div"]) => {
  //
  const setIsOpen = useModalSurveyMain((state) => state.setIsOpen);
  const setisCompleted = useModalSurveyMain((state) => state.setIsCompleted);
  const resize = useResizeStore((state) => state.resize);

  const [answers, setAnswers] = useState({
    answer1: "",
    answer2: "",
    answer3: "",
  });
  const questions = {
    q1: ["1", "2", "3", "4", "5"],
    q2: ["premium", "smart", "innovation", "contemporary", "advanced"],
    q3: [
      "regrigerator",
      "indoor_gardening_appliance",
      "cooking",
      "dishwasher",
      "water_purifier",
      "washtower_27",
      "fx_washer/dryer",
      "styler",
      "vacuum_cleaner",
      "air_conditioner",
      "air_care",
      "thinq",
      "objet_collection",
    ],
  };

  const names = {
    q1: ["1", "2", "3", "4", "5"],
    q2: ["Premium", "Smart", "Innovation", "Contemporary", "Advanced"],
    q3: [],
  };

  return (
    <Wrapper>
      <Panel
        style={{
          width: 1568 * resize.ratio,
          height: (IS_MOBILE ? 1050 : 971) * resize.ratio,
        }}
      >
        <SkipButton
          ratio={resize.ratio}
          onClick={(e) => {
            // GA_Event("survey", "exit_menu", "skip");
            window.location.href =
              "https://www.lg.com/global/exhibition/homeappliance/index.html";
          }}
        >
          <Image
            alt="skip"
            role="img"
            src={ROOT_PATH_PREFIX + "/images/survey/main/skip_btn.png"}
          />
        </SkipButton>
        <CloseButton
          showLabel={!IS_MOBILE}
          style={{
            position: "absolute",
            zIndex: Z_INDEX_POPUP,
            top: "5%",
            right: "4%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
            GA_Event("survey", "exit_menu", "close");
          }}
        />
        <Text
          style={{ position: "relative", top: `${45 * resize.ratio}px` }}
          size={`${42 * resize.ratio}px`}
          color="white"
          weight={theme.font.weight.semibold}
        >
          LG CES SURVEY
        </Text>
        <div
          style={{
            position: "relative",
            width: (IS_MOBILE ? 1300 : 1122) * resize.ratio,
            top: `${80 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 35 : 28) * resize.ratio}px`}
            color="white"
          >
            1. What is your level of satisfaction for LG Home appliance in
            virtual experience?
          </Text>
          <div
            style={{
              padding: `${2 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
            }}
          >
            <Divider
              style={{
                position: "absolute",
                top: `${resize.ratio * (IS_MOBILE ? 8 : 6.7)}em`,
                zIndex: -1,
              }}
              width={(IS_MOBILE ? 1200 : 1050) * resize.ratio}
              color="white"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {questions.q1.map((data, index) => {
                return (
                  <div key={index}>
                    <CircleButton
                      aria-label={names.q1[index]}
                      role="button"
                      ratio={resize.ratio}
                      clicked={answers.answer1 === data}
                      onClick={() => {
                        setAnswers({ ...answers, answer1: data });
                        GA_Event("survey", "exit_answer_1", data);
                      }}
                    >
                      <CheckOutlined
                        className="icon"
                        style={{
                          opacity: answers.answer1 === data ? 1 : 0,
                        }}
                      />
                    </CircleButton>
                    <Text
                      align="center"
                      size={`${(IS_MOBILE ? 3 : 2.2) * resize.ratio}em`}
                      style={{
                        paddingTop: `${resize.ratio * 1.2}em`,
                        color: "white",
                      }}
                    >
                      {names.q1[index]}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: (IS_MOBILE ? 1300 : 1122) * resize.ratio,
            top: `${110 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 35 : 28) * resize.ratio}px`}
            color="white"
          >
            2. What value are you reminded of when you experience LG Home
            appliance?
          </Text>
          <div
            style={{
              display: "flex",
              padding: `${2 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
              justifyContent: "space-between",
            }}
          >
            {questions.q2.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <CircleButton
                    aria-label={names.q2[index]}
                    role="button"
                    ratio={resize.ratio}
                    clicked={answers.answer2 === data}
                    onClick={() => {
                      setAnswers({ ...answers, answer2: data });
                      GA_Event("survey", "exit_answer_2", data);
                    }}
                  >
                    <CheckOutlined
                      className="icon"
                      style={{
                        opacity: answers.answer2 === data ? 1 : 0,
                      }}
                    />
                  </CircleButton>
                  <Text
                    align="center"
                    size={`${(IS_MOBILE ? 3 : 2.2) * resize.ratio}em`}
                    style={{
                      paddingLeft: `${resize.ratio * 1}em`,
                      color: "white",
                    }}
                  >
                    {names.q2[index]}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: (IS_MOBILE ? 1300 : 1122) * resize.ratio,
            top: `${150 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 35 : 28) * resize.ratio}px`}
            color="white"
          >
            3. What was your favorite product?
          </Text>
          <div
            style={{
              display: "flex",
              padding: `${2 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {questions.q3.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: `${
                      (index === 12
                        ? IS_MOBILE
                          ? 286 + (IS_MOBILE ? 45 : 5) * resize.ratio
                          : 286
                        : 129) * resize.ratio
                    }px`,
                    height: `${175 * resize.ratio}px`,
                    padding: `${3 * resize.ratio}px`,
                    border: `${1 * resize.ratio}px solid white`,
                    borderRadius: `${1.5 * resize.ratio}em`,
                    marginRight: `${(IS_MOBILE ? 45 : 5) * resize.ratio}px`,
                    marginBottom: `${2 * resize.ratio}em`,
                    pointerEvents: "visible",
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setAnswers({ ...answers, answer3: data });
                    GA_Event("survey", "exit_answer_3", data);
                  }}
                >
                  <Image
                    alt={data.replaceAll("_", " ")}
                    role="img"
                    width={`${
                      (index === 12 ? 276 : 129) *
                      resize.ratio *
                      (IS_MOBILE ? 0.8 : 1)
                    }px`}
                    height={`${125 * resize.ratio * (IS_MOBILE ? 0.8 : 1)}px`}
                    src={
                      ROOT_PATH_PREFIX + `/images/survey/main/p${index + 1}.png`
                    }
                  />
                  <CircleButton
                    aria-label={data.replaceAll("_", " ")}
                    role="button"
                    ratio={resize.ratio}
                    clicked={answers.answer3 === data}
                  >
                    <CheckOutlined
                      className="icon"
                      style={{
                        opacity: answers.answer3 === data ? 1 : 0,
                      }}
                    />
                  </CircleButton>
                </div>
              );
            })}
          </div>
        </div>
        <SurveyButton
          ratio={resize.ratio}
          disabled={
            !(
              answers.answer1 !== "" &&
              answers.answer2 !== "" &&
              answers.answer3 !== ""
            )
          }
          onClick={() => {
            setisCompleted(true);
            GA_Event("survey", "exit_menu", "save_and_exit", {
              dimension40: answers.answer1,
              dimension41: answers.answer2,
              dimension42: answers.answer3,
            });
            window.location.href =
              "https://www.lg.com/global/exhibition/homeappliance/index.html";
          }}
        >
          Save and Exit
        </SurveyButton>
      </Panel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: visible;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

const CircleButton = styled.button<{ ratio: number; clicked: boolean }>`
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 50 : 35)}px;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 50 : 35)}px;
  background-color: ${({ clicked }) =>
    clicked ? theme.colors.home.pink : "black"};
  border-radius: ${({ ratio }) => (ratio * (IS_MOBILE ? 50 : 35)) / 2}px;
  border: ${({ ratio }) => ratio * 3}px solid white !important;
  cursor: pointer;
  padding: 0;

  .icon {
    color: white;
    font-size: ${({ ratio }) => ratio * (IS_MOBILE ? 32 : 20)}px;
  }
`;

const SurveyButton = styled.button<{ ratio: number } & Record<string, any>>`
  position: absolute;
  bottom: ${({ ratio }) => ratio * 45}px;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 350 : 240)}px;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 80 : 56)}px;
  border-radius: ${({ ratio }) => (ratio * 10) / 2}px;
  background-color: "white";
  font-size: ${({ ratio }) => ratio * (IS_MOBILE ? 3.5 : 2.2)}em;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};

  :hover {
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.colors.common.darkpink};
    color: ${({ disabled }) => !disabled && "white"};
  }
`;

const SkipButton = styled.button<{ ratio: number } & Record<string, any>>`
  position: absolute;
  padding: 0;
  z-index: ${Z_INDEX_POPUP};
  right: ${({ ratio }) => ratio * 55}px;
  bottom: ${({ ratio }) => ratio * 55}px;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 203 * 1.2 : 203)}px;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 43 * 1.2 : 43)}px;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};
`;

export default SurveyMain;
