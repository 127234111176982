import React, { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { useSpring, animated } from "@react-spring/web";
import { easeCubicIn, easeCubicOut } from "d3-ease";
import { Divider } from "../../common/comps_styled/Divider.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import theme from "../../../styles/theme.styled";
import { Container } from "../../common/comps_styled/Container.styled";
import { LeftCircleOutlined, RightOutlined } from "@ant-design/icons";
import useRightPanelStore, {
  RightPanelState,
} from "../../../stores/rightPanelStore";
import useLangStore from "../../../stores/langStore";
import { getLangString, LangDataType } from "../../../api/lang/langData";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import useResizeStore from "../../../stores/resizeStore";
import { Image } from "../../common/comps_styled/Image.styled";

type SelectPanelToggleButtonProps = {
  isOpen?: boolean;
  onClick?: () => void;
} & JSX.IntrinsicElements["button"];

const isObjetModeSelector = (state: RightPanelState) => state.isObjetMode;
const SelectPanelToggleButton = ({
  isOpen = false,
  onClick,
  ...rest
}: SelectPanelToggleButtonProps) => {
  const isObjetMode = useRightPanelStore(isObjetModeSelector);
  const lang = useLangStore((state) => state.lang);
  const resize = useResizeStore((state) => state.resize);

  const RenderTitle = ({
    title,
    index,
  }: {
    title: React.ReactNode;
    index: number;
  }) => {
    return (
      <Container
        key={index}
        style={{
          flex:
            index === 0 ? 1 : index === 1 ? 0.31 : index === 2 ? 0.52 : 0.17,
          alignItems: "center",
        }}
      >
        <Divider
          borderwidth={resize.ratio * 2}
          width={IS_MOBILE ? "40%" : "55%"}
          color={theme.colors.common.gray}
        />
        <LeftCircleOutlined
          style={{
            fontSize: `${(IS_MOBILE ? 3.5 : 2.6) * resize.ratio}em`,
            margin: "0.65em 0 0.5em 0",
            color: theme.colors.common.gray,
          }}
        />
        <Text
          size={`${(IS_MOBILE ? 2.5 : 1.85) * resize.ratio}em`}
          weight={theme.font.weight.bold}
          align="center"
          lineHeight={1.1}
          color={theme.colors.home.darkblue}
        >
          {title}
        </Text>
      </Container>
    );
  };

  return (
    <Wrapper {...rest} radius={`${1.8 * resize.ratio}em`} onClick={onClick}>
      {isOpen ? (
        <Image
          alt="close select panel"
          role="img"
          width={IS_MOBILE ? `${3 * resize.ratio}em` : `${2 * resize.ratio}em`}
          height={IS_MOBILE ? `${3 * resize.ratio}em` : `${2 * resize.ratio}em`}
          src={ROOT_PATH_PREFIX + "/images/icons/btn_select-window@2x.png"}
        />
      ) : isObjetMode ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <RenderTitle
            title={
              getLangString("en", "objet_main_select_material_n_color")?.text
            }
            index={0}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "2.6em 0",
          }}
        >
          <RenderTitle
            title={getLangString("en", "home_exp_select_interior")?.text}
            index={1}
          />
          <RenderTitle
            title={getLangString("en", "home_exp_select_product")?.text}
            index={2}
          />
          <RenderTitle
            title={getLangString("en", "home_exp_select_color")?.text}
            index={3}
          />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(animated.button)<
  {
    ismobile: boolean | undefined;
    selected: boolean;
    radius?: string;
  } & Record<string, any>
>`
  height: 100%;
  background: #eaeaea;
  border-top-left-radius: ${({ radius }) => radius ?? 0};
  border-bottom-left-radius: ${({ radius }) => radius ?? 0};
  cursor: pointer;
  pointer-events: visible;

  display: flex;
  justify-content: center;
  align-items: center;

  // border: 1px solid tomato !important;
`;

export default SelectPanelToggleButton;
