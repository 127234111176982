/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {Shadow} from "@react-three/drei";

type GLTFResult = GLTF & {
    nodes: {
        Plane: THREE.Mesh
        Mesh003: THREE.Mesh
        Mesh003_1: THREE.Mesh
        Mask_New001: THREE.Mesh
        Plane001: THREE.Mesh
        Mask_New002: THREE.Mesh
    }
    materials: {
        Mask_Black: THREE.MeshStandardMaterial
        ['Mask_Case.002']: THREE.MeshStandardMaterial
        ['Mask_Case.003']: THREE.MeshStandardMaterial
        Mask_White: THREE.MeshStandardMaterial
        ['Mask.003']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/wearable_air_purifier/m/wap_2nd_ktx2_2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/wearable_air_purifier/wap_2nd_ktx2_2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);

    useEffect(()=>{
        scene.traverse((child)=>{
            if(child.type === 'Mesh'){
                const mesh = child as THREE.Mesh;
                const mat = mesh.material as THREE.MeshStandardMaterial;
                if(!mat.transparent) {
                    mat.envMapIntensity = 1;
                }
            }
        })
        materials['Mask_Case.002'].envMapIntensity = 1;
    }, []);


    const part1Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'wearable_2nd') {
            if (selectedColorIndex === 0) {
                part1Ref.current.material = materials.Mask_White;
            } else if (selectedColorIndex === 1) {
                part1Ref.current.material = materials.Mask_Black;
            }

            materials["Mask_Case.002"].envMapIntensity = 6;
            materials.Mask_White.envMapIntensity = 5;
            materials.Mask_Black.roughness = 0.8;
            materials.Mask_Black.envMapIntensity = 0.9;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        part1Ref.current.material = materials.Mask_White;
        materials["Mask_Case.002"].envMapIntensity = 6;
        materials.Mask_White.envMapIntensity = 5;
        materials.Mask_Black.roughness = 0.8;
        materials.Mask_Black.envMapIntensity = 0.9;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0.1, 0, 0]}>
            <mesh
                geometry={nodes.Plane.geometry}
                material={materials.Mask_Black}
                position={[0.0193, 0.0569, -0.0892]}
                scale={[0.0086, 0.0086, 0.0086]}
                userData={{ name: 'Plane' }}
            />
            <group
                position={[-0.2636, 0, 0.0016]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Mask_New' }}>
                <mesh geometry={nodes.Mesh003.geometry} material={materials['Mask_Case.002']} />
                <mesh geometry={nodes.Mesh003_1.geometry} material={materials['Mask_Case.003']} />
            </group>
            <mesh
                geometry={nodes.Mask_New001.geometry}
                material={nodes.Mask_New001.material}
                position={[-0.2636, 0, 0.0016]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Mask_New.001' }}
                ref={part1Ref}
            />
            <mesh
                geometry={nodes.Plane001.geometry}
                material={nodes.Plane001.material}
                position={[0.0002, 0.0569, -0.0892]}
                scale={[0.0086, 0.0086, 0.0086]}
                userData={{ name: 'Plane.001' }}
            />
            <mesh
                geometry={nodes.Mask_New002.geometry}
                material={materials['Mask.003']}
                position={[-0.2636, 0, 0.0016]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Mask_New.002' }}
            />
            <Shadow rotation-x={-Math.PI / 2} scale={0.25} position={[-0.27, 0, -0.08]} opacity={0.2}/>
            <Shadow rotation-x={-Math.PI / 2} scale={0.25} position={[0, 0, -0.08]} opacity={0.2}/>
        </group>
    )
}
