/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {GLTFResult_ObjetModernProducts} from "./Product_Harvester";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {Objet_ColorCategoryType, vacuum_cleaner_stand_data} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import {TextureLoader} from "three";
import {easeCubicInOut} from "d3-ease";
import {a} from "@react-spring/three";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Art_modern.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Art_modern.jpg');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/art/beige_mat_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/art/beige_mat_body_merged_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/art/beige_mat_body2_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/art/beige_mat_body2_merged_BaseColor.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/art/green_mat_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/art/green_mat_body_merged_BaseColor.jpg');
const COLOR_3_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/art/green_mat_body2_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/art/green_mat_body2_merged_BaseColor.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult_ObjetModernProducts
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.vacuum_cleaner_stand_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.vacuum_cleaner_stand_ColorLabel);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [isSet, setIsSet] = useState(false);

    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory === 'Calming') {
            if (colorLabel === 'objet_color_green') {
                if (!isFirst) setIsSet(true);
                colorTextures[3].flipY = false;
                colorTextures[3].encoding = THREE.sRGBEncoding;
                colorTextures[3].needsUpdate = true;
                materials.mat_body2_merged.map = colorTextures[3];

                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[2];
            } else {
                if (!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials.mat_body2_merged.map = colorTextures[1];

                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            }
        }

    }, []);


    useLayoutEffect(() => {
        changeTexture('mat_body_merged.001', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut},
                onRest: result => {
                    console.log(">>>>>", result);
                }
            });
        }
    }, [isOpenTutorial]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL
            ]);
        }
    }, []);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials.mat_body2_merged.lightMap = lightmapTex;
        materials.mat_body2_merged.lightMapIntensity = 0.5;
        materials.mat_body2_merged.envMapIntensity = 1;
        materials["mat_body_merged.001"].lightMap = lightmapTex;
        materials["mat_body_merged.001"].lightMapIntensity = 0.5;
        materials["mat_body_merged.001"].envMapIntensity = 1;
        materials['Glass.002'].roughness = 0;
        materials['Glass.002'].envMapIntensity = 6;

    }, [lightmapTex]);


    const pathname = useHistoryStore(state => state.pathname);


    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.art.geometry}
                material={materials.mat_body2_merged}
                position={[5.2364, 0.0818, -3.0023]}>
                <mesh
                    geometry={nodes.Glass001.geometry}
                    material={materials['Glass.002']}
                    position={[0.0002, -0.0879, 0.0612]}
                />
                <mesh
                    geometry={nodes.Decal001.geometry}
                    material={materials.mat_ui_merged}
                    position={[0.0006, 0.9115, -0.0243]}
                    rotation={[-Math.PI, 0, 0]}
                />
                <mesh
                    geometry={nodes.Body_merged001.geometry}
                    material={materials['mat_body_merged.001']}
                    position={[0.0002, -0.0879, 0.0658]}
                />
            </mesh>

            <mesh
                geometry={nodes.art.geometry}
                // material={materials.mat_body2_merged}
                position={[5.2364, 0.0818, -3.0023]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.6, -0.2]} scale={0.8} visible={!(isSet)} opacity={spring.opacity}
                                 color={pathname.indexOf('/vacuum_cleaner_stand') > -1 ? '#be1e42' : '#000'}/>

                <mesh
                    geometry={nodes.Body_merged001.geometry}
                    // material={materials['mat_body_merged.001']}
                    position={[0.0002, -0.0879, 0.0658]}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} transparent={true} opacity={spring.opacity}/>
                </mesh>
            </mesh>

            <ObjetProductContainer3D
                args={[0.25, 1.2, 0.3]}
                position={[5.2364, 0.6, -3.3]}
                data={vacuum_cleaner_stand_data}
                showDebug={false}
                cameraTargetPos={[1, 0.15, 3]}
            />

        </group>
    )
}
