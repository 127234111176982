import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { useThree } from "@react-three/fiber";
import useCameraStore, { CameraState } from "../../stores/cameraStore";
import useObjetProductStore, {
  ObjetColorState,
  ObjetProductState,
  useObjetColorStore,
} from "../../stores/objetProductStore";
import BlossomRoom from "./room/BlossomRoom";
import NaturalRoom from "./room/NaturalRoom";
import ModernRoom from "./room/ModernRoom";
import useHistoryStore, { HistoryState } from "../../stores/historyStore";
import {
  getObjetProductData,
  Objet_ProductsCategoryType,
  Objet_RoomType,
} from "../../api/products/objetProductsData";
import useRightPanelStore, {
  RightPanelState,
} from "../../stores/rightPanelStore";
import qs from "qs";
import { useLocation } from "react-router-dom";
import { IS_MOBILE } from "../common/const";
import { GA_Event } from "../../api/ga";
import useSpaceThemeStore, {
  ObjetThemeType,
  useObjetFloorTextureStore,
  useObjetWallTextureStore,
} from "../../stores/spaceThemeStore";
import { useModalVimeo } from "../../stores/modalStore";
import { useSelectMobileQuality } from "../../stores/selectRouteForMobileStore";
import useResizeStore from "../../stores/resizeStore";
import useLangStore from "../../stores/langStore";


/**
 * 오브제 컬랙션 전시관
 * @constructor
 */

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const changeRoomSelector = (state: ObjetProductState) => state.api.changeRoom;
const selectCategorySelector = (state: ObjetProductState) =>
  state.api.selectCategory;
const selectedCategorySelector = (state: ObjetProductState) =>
  state.selectedCategory;
const cameraApiSelector = (state: CameraState) => state.api;
const pushHistorySelector = (state: HistoryState) => state.pushHistory;
const setIsOpenSelector = (state: RightPanelState) => state.setIsOpen;
const setIsShowSelector = (state: RightPanelState) => state.setIsShow;
const searchObjetColorSelector = (state: ObjetColorState) =>
  state.api.searchObjetColor;

let hasShowIntro = false;

const LG_ObjetCollection = () => {
  const location = useLocation();
  const gl = useThree((state) => state.gl);
  const resize = useResizeStore((state) => state.resize);
  const lang = useLangStore((state) => state.lang);
  const { setTargetPos, setLookPos, setTouchStart } =
    useCameraStore(cameraApiSelector);
  // const selectedRoom = useObjetProductStore(selectedRoomSelector); //선택된 공간 타입
  const selectCategory = useObjetProductStore(selectCategorySelector); //카테고리 선택
  const selectedCategory = useObjetProductStore(selectedCategorySelector);
  const changeRoom = useObjetProductStore(changeRoomSelector); //공간타입 변경
  const pushHistory = useHistoryStore(pushHistorySelector);
  const setIsOpen = useRightPanelStore(setIsOpenSelector); //오른쪽 패널 열기 (함수)
  const setIsShow = useRightPanelStore(setIsShowSelector); //오른쪽 패널 활성/비활성 (함수)
  const searchObjetColor = useObjetColorStore(searchObjetColorSelector);
  const objetTheme = useSpaceThemeStore((state) => state.objetTheme);
  const setObjetTheme = useSpaceThemeStore((state) => state.api.setObjetTheme);
  const isNormalQulity = useSelectMobileQuality(
    (state) => state.isNormalQuality
  );

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  //첫 진입 시 location.pathname으로 부터 category 이름을 추출하여 카테고리 초기화
  const searchedCategoryName: Objet_ProductsCategoryType | undefined =
    useMemo(() => {
      if (query.pathname.indexOf("refrigerator_tl") > -1)
        return "refrigerator_tl";
      else if (query.pathname.indexOf("refrigerator_bl") > -1)
        return "refrigerator_bl";
      else if (query.pathname.indexOf("refrigerator_br") > -1)
        return "refrigerator_br";
      else if (query.pathname.indexOf("dishwasher") > -1) return "dishwasher";
      else if (query.pathname.indexOf("water_purifier_undersink") > -1)
        return "water_purifier_undersink";
      else if (query.pathname.indexOf("water_purifier") > -1)
        return "water_purifier";
      else if (query.pathname.indexOf("washer") > -1) return "washer";
      else if (query.pathname.indexOf("dryer") > -1) return "dryer";
      else if (query.pathname.indexOf("styler") > -1) return "styler";
      else if (query.pathname.indexOf("vacuum_cleaner_stand") > -1)
        return "vacuum_cleaner_stand";
      else if (query.pathname.indexOf("vacuum_cleaner_robot") > -1)
        return "vacuum_cleaner_robot";
      else if (query.pathname.indexOf("microwave_oven") > -1)
        return "microwave_oven";
      else if (query.pathname.indexOf("air_conditioner_stand") > -1)
        return "air_conditioner_stand";
      else if (query.pathname.indexOf("air_conditioner_wall") > -1)
        return "air_conditioner_wall";
      else if (query.pathname.indexOf("air_care_aero") > -1)
        return "air_care_aero";
      else if (query.pathname.indexOf("air_care") > -1) return "air_care";
      else if (query.pathname.indexOf("tiiun") > -1) return "tiiun";
      else if (query.pathname.indexOf("lansen_freezer_l") > -1)
        return "lansen_freezer_l";
      else if (query.pathname.indexOf("lansen_freezer_r") > -1)
        return "lansen_freezer_r";
    }, [query]);

  const searchedRoomType: Objet_RoomType | undefined = useMemo(() => {
    if (query.pathname.indexOf("/blossom") > -1) return "blossom";
    else if (query.pathname.indexOf("/natural") > -1) return "natural";
    else if (query.pathname.indexOf("/modern") > -1) return "modern";
  }, [query]);

  // const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();

  // for GAEvent
  const pathname = useHistoryStore((state) => state.pathname);
  const isObjet = useMemo(
    () => pathname.includes("/objetcollection"),
    [pathname]
  );
  const objetProducts = useObjetProductStore((state) => state);
  const objetColors = useObjetColorStore((state) => state);
  const ga_product = objetProducts.selectedCategory?.ga_id;
  const ga_interior = useSpaceThemeStore((state) => state.objetTheme);
  const ga_wall_material = useObjetWallTextureStore((state) => state.material);
  const ga_floor_material = useObjetFloorTextureStore(
    (state) => state.material
  );

  useEffect(() => {
    isObjet &&
      ga_product &&
      GA_Event("lg_objet", "product_select", ga_product, {
        ...objetColors.api.getAllColorsGA(),
        dimension10: ga_product,
        dimension11: "",
        dimension12: ga_interior,
        dimension13: ga_wall_material,
        dimension14: ga_floor_material,
        dimension15: "",
      });
  }, [ga_product]);

  useEffect(() => {
    isObjet &&
      ga_interior &&
      GA_Event("lg_objet", "interior_concept", ga_interior, {
        ...objetColors.api.getAllColorsGA(),
        dimension10: ga_product,
        dimension11: "",
        dimension12: ga_interior,
        dimension13: ga_wall_material,
        dimension14: ga_floor_material,
        dimension15: "",
      });
  }, [ga_interior]);

  useEffect(() => {
    isObjet &&
      ga_wall_material &&
      GA_Event("lg_objet", "wall_material", ga_wall_material, {
        ...objetColors.api.getAllColorsGA(),
        dimension10: ga_product,
        dimension11: "",
        dimension12: ga_interior,
        dimension13: ga_wall_material,
        dimension14: ga_floor_material,
        dimension15: "",
      });
  }, [ga_wall_material]);

  useEffect(() => {
    isObjet &&
      ga_floor_material &&
      GA_Event("lg_objet", "floor_material", ga_floor_material, {
        ...objetColors.api.getAllColorsGA(),
        dimension10: ga_product,
        dimension11: "",
        dimension12: ga_interior,
        dimension13: ga_wall_material,
        dimension14: ga_floor_material,
        dimension15: "",
      });
  }, [ga_floor_material]);

  useLayoutEffect(() => {
    setObjetTheme(searchedRoomType as ObjetThemeType);
    changeRoom(searchedRoomType ? searchedRoomType : null);
  }, [searchedRoomType]);

  useLayoutEffect(() => {
    if (searchedCategoryName) {
      const categoryData = getObjetProductData(searchedCategoryName);
      if (categoryData) {
        searchObjetColor(searchedCategoryName);
        selectCategory(categoryData);
        setIsOpen(true, true); //오른쪽 패널 열린 상태로
      } else {
        selectCategory(null);
        setIsOpen(false, true);
        setIsShow(true, true); //오브제컬랙션에서는 오른쪽 패널 계속 노출
        setTargetPos(null);
        setLookPos(null);
      }
    } else {
      selectCategory(null);
      setIsOpen(false, true);
      setIsShow(true, true); //오브제컬랙션에서는 오른쪽 패널 계속 노출
      setTargetPos(null);
      setLookPos(null);
    }
  }, [searchedCategoryName]);

  useEffect(() => {
    gl.setClearColor("#333");
  }, [gl]);

  const modalVimeo = useModalVimeo((state) => state.isOpen);
  const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
  const setVimeoId = useModalVimeo((state) => state.setId);
  const setVimeoGaId = useModalVimeo((state) => state.setGaId);
  const setVimeo = (id: string) => {
    setVimeoId(id);
    setModalVimeo(true);
  };

  useEffect(() => {
    if (IS_MOBILE) {
      if (isNormalQulity) {
        setVimeoGaId("");
        setVimeo("https://vimeo.com/662025136/18b1f7013f");
      } else {
        if (!hasShowIntro) {
          setVimeoGaId("exhibition_product");
          setVimeo("https://vimeo.com/659012390/245303727c");
          hasShowIntro = true;
        }
      }
    } else {
      if (!hasShowIntro) {
        setVimeoGaId("exhibition_product");
        setVimeo("https://vimeo.com/659012390/245303727c");
        hasShowIntro = true;
      }
    }
  }, []);

  const getRoom = () => {
    return (
      <>
        {/* {pathname.indexOf("/objetcollection") > -1 && (
          <Html center position={[0, 5.65, 0]}>
            <ThemeProvider theme={theme}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Image
                  alt="title"
                  role="img"
                  width={`${resize.ratio * 570}px`}
                  height={`${resize.ratio * 121}px`}
                  src={
                    ROOT_PATH_PREFIX +
                    "/images/objet/LG object Collection_logo.png"
                  }
                />
                <Text
                  style={{
                    filter: `drop-shadow(0 0  ${
                      resize.ratio * 15
                    }px rgba(0, 0, 0, 0.7))`,
                  }}
                  align="center"
                  color="white"
                  size={`${resize.ratio * 2.75}em`}
                >
                  {getLangString(lang, "objet_main_click")?.text}
                </Text>
              </div>
            </ThemeProvider>
          </Html>
        )} */}
        {objetTheme === "blossom" && <BlossomRoom />}
        {objetTheme === "natural" && <NaturalRoom />}
        {objetTheme === "modern" && <ModernRoom />}
      </>
    );
  };

  return (
    <group>
      <React.Suspense fallback={null}>
        {!modalVimeo && hasShowIntro && getRoom()}
      </React.Suspense>
    </group>
  );
};

export default LG_ObjetCollection;
