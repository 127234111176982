import create from "zustand";
import { ProductsCategoryType } from "../api/products/productsData";

/**
 * 모달 상태관리
 */
export type GuideMenuState = {
  category: string | null;
  setCategory: (category: string | null) => void;
};

export const useGuideMenuHidden = create<GuideMenuState>((set) => ({
  category: "",
  setCategory: (category) => set({ category: category }),
}));
