import {SpecboardsDataItemType} from "./specboardData";
import {Sup} from "../../components/common/comps_styled/Text.styled";
import React from "react";

/**
 * 청소기 스펙보드 데이터
 */
const specboardsData_VacuumCleaner: SpecboardsDataItemType = {
  category: "vacuum_cleaner",
  specboardList: [
    {
      name: "A9T",
      modelNo: "a9t",
      specboard_img_url: "/images/products/vacuum_cleaner/a9t/specboard_2.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "A9K",
      modelNo: "a9k",
      specboard_img_url: "/images/products/vacuum_cleaner/a9k/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "R9",
      modelNo: "r9",
      specboard_img_url: "/images/products/vacuum_cleaner/r9/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "M9",
      modelNo: "m9",
      specboard_img_url: "/images/products/vacuum_cleaner/m9/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
  ],
};

export default specboardsData_VacuumCleaner;
