/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {
    air_conditioner_wall_data,
    Objet_ColorCategoryType,
    vacuum_cleaner_stand_data
} from "../../../../api/products/objetProductsData";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {disposeScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {animated, useSpring} from "@react-spring/web";
import {useModalTutorial} from "../../../../stores/modalStore";
import {TextureLoader} from "three";
import {a} from "@react-spring/three";
import {LangLabelType} from "../../../../api/lang/langData";
import {easeCubicInOut} from "d3-ease";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "./Product_Harvester";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
// const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/artcool/Lightmap_Artcool.jpg';
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Artcool.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Artcool.jpg');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/artcool/ARTCOOL(Object)_Albedo.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/artcool/ARTCOOL(Object)_Albedo.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/artcool/ARTCOOL(Object)_Albedo_Calming_Green.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/artcool/ARTCOOL(Object)_Albedo_Calming_Green.jpg');



export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.air_conditioner_wall_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.air_conditioner_wall_ColorLabel);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [spring, animate] = useSpring(() => ({
        from:{
            opacity: 0
        }
    }));

    const [isSet, setIsSet] = useState(false);


    const changeTexture = useCallback((materialName:string, colorCategory?:Objet_ColorCategoryType, colorLabel?:LangLabelType, isFirst:boolean = false) => {

        if(colorCategory === 'Calming')
        {
            if(colorLabel === 'objet_color_green')
            {
                if(!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            }
            else{
                if(!isFirst) setIsSet(true);
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            }
        }
    }, [colorTextures, materials]);

    useLayoutEffect(()=>{
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials['mat_artcool_body.001'].lightMap = lightmapTex;
        materials['mat_artcool_body.001'].lightMapIntensity = 1;
    }, [lightmapTex]);

    useLayoutEffect(()=>{
        changeTexture('mat_artcool_body.001', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useEffect(()=>{
        animate({cancel:true});
        if(!isOpenTutorial){
            animate({
                cancel:false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config:{duration:3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);

    useEffect(()=>{
        return ()=>{
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL
            ]);
        }
    }, []);

    const pathname = useHistoryStore(state => state.pathname);


    return (
        <group ref={group} {...props} dispose={null}
        >
            <mesh
                geometry={nodes.artcool.geometry}
                material={materials['mat_artcool_body.001']}
                position={[1.6655, 2.6292, -6.8241]}>
                <mesh geometry={nodes.UI.geometry} material={materials.mat_artcool_ui} />
            </mesh>


            <mesh
                geometry={nodes.artcool.geometry}
                position={[1.6655, 2.6292, -6.8241]}
                userData={{ name: ' Dualcool_AP12RT' }}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true} opacity={spring.opacity} />
                <ObjetPlusButton position={[0, 0, 0]} visible={!(isSet)} opacity={spring.opacity} color={pathname.indexOf('/air_conditioner_wall') > -1 ? '#be1e42' : '#000'}/>
            </mesh>

            <ObjetProductContainer3D
                args={[1, 0.3, 0.3]}
                position={[1.67, 2.63, -6.82]}
                data={air_conditioner_wall_data}
                showDebug={false}
                cameraTargetPos={[0, 0, 3]}
            />

        </group>
    )
}
