import {GA_DataType} from "./index";

//GA검색용 키워드
export type GA_NameType_ThinQ = (
    "thinq_menu_back_to_previous" |
    "thinq_menu_main" |
    "thinq_menu_easy&convenient_back_to_previous" |
    "thinq_menu_easy&convenient_main" |
    "thinq_menu_unique&delightful_back_to_previous" |
    "thinq_menu_unique&delightful_main" |
    "thinq_feature_select_easy&convenient" |
    "thinq_feature_select_unique&delightful" |
    "thinq_easy&convenient_remote_control" |
    "thinq_easy&convenient_voice_control" |
    "thinq_easy&convenient_mode&automation" |
    "thinq_easy&convenient_thinq_recipe" |
    "thinq_easy&convenient_thinq_store" |
    "thinq_easy&convenient_energy_dr" |
    "thinq_unique&delightful_upgradable_appliance" |
    "thinq_unique&delightful_proactive_care" |
    "thinq_unique&delightful_smart_scan" |
    "thinq_unique&delightful_insights" |
    "thinq_unique&delightful_smart_life" |
    "thinq_settinigs_lg_thinq_main_sound_on" |
    "thinq_settinigs_lg_thinq_main_sound_off" |
    "thinq_settinigs_easy&convenient_sound_on" |
    "thinq_settinigs_easy&convenient_sound_off" |
    "thinq_settinigs_unique&delightful_sound_on" |
    "thinq_settinigs_unique&delightful_sound_off" |
    "thinq_upgradable_appliance_adjust_brightness" |
    "thinq_upgradable_appliance_pet_care_course" |
    "thinq_upgradable_appliance_splash_screen/washer_ending_melody" |
    "thinq_upgradable_appliance_voice_control" |
    "thinq_upgradable_appliance_dosage_adjustment" |
    "thinq_upgradable_appliance_operating_mode"
    );

export type GA_EventAction_ThinQ = (
    "menu" |
    "feature_select" |
    "easy&convenient" |
    "unique&delightful" |
    "settinigs" |
    "upgradable_appliance"
    );


export const GA_DATA_THINQ: GA_DataType[] = [
    {
        name: "thinq_menu_back_to_previous",
        category: "lg_thinq",
        action: "menu",
        label: "back_to_previous"
    },
    {
        name: "thinq_menu_main",
        category: "lg_thinq",
        action: "menu",
        label: "main"
    },
    {
        name: "thinq_menu_easy&convenient_back_to_previous",
        category: "lg_thinq",
        action: "menu",
        label: "easy&convenient_back_to_previous"
    },
    {
        name: "thinq_menu_easy&convenient_main",
        category: "lg_thinq",
        action: "menu",
        label: "easy&convenient_main"
    },
    {
        name: "thinq_menu_unique&delightful_back_to_previous",
        category: "lg_thinq",
        action: "menu",
        label: "unique&delightful_back_to_previous"
    },
    {
        name: "thinq_menu_unique&delightful_main",
        category: "lg_thinq",
        action: "menu",
        label: "unique&delightful_main"
    },
    {
        name: "thinq_feature_select_easy&convenient",
        category: "lg_thinq",
        action: "feature_select",
        label: "easy&convenient"
    },
    {
        name: "thinq_feature_select_unique&delightful",
        category: "lg_thinq",
        action: "feature_select",
        label: "unique&delightful"
    },
    {
        name: "thinq_easy&convenient_remote_control",
        category: "lg_thinq",
        action: "easy&convenient",
        label: "remote_control"
    },
    {
        name: "thinq_easy&convenient_voice_control",
        category: "lg_thinq",
        action: "easy&convenient",
        label: "voice_control"
    },
    {
        name: "thinq_easy&convenient_mode&automation",
        category: "lg_thinq",
        action: "easy&convenient",
        label: "mode&automation"
    },
    {
        name: "thinq_easy&convenient_thinq_recipe",
        category: "lg_thinq",
        action: "easy&convenient",
        label: "thinq_recipe"
    },
    {
        name: "thinq_easy&convenient_thinq_store",
        category: "lg_thinq",
        action: "easy&convenient",
        label: "thinq_store"
    },
    {
        name: "thinq_easy&convenient_energy_dr",
        category: "lg_thinq",
        action: "easy&convenient",
        label: "energy_dr"
    },
    {
        name: "thinq_unique&delightful_upgradable_appliance",
        category: "lg_thinq",
        action: "unique&delightful",
        label: "upgradable_appliance"
    },
    {
        name: "thinq_unique&delightful_proactive_care",
        category: "lg_thinq",
        action: "unique&delightful",
        label: "proactive_care"
    },
    {
        name: "thinq_unique&delightful_smart_scan",
        category: "lg_thinq",
        action: "unique&delightful",
        label: "smart_scan"
    },
    {
        name: "thinq_unique&delightful_insights",
        category: "lg_thinq",
        action: "unique&delightful",
        label: "insights_report"
    },
    {
        name: "thinq_unique&delightful_smart_life",
        category: "lg_thinq",
        action: "unique&delightful",
        label: "smart_life"
    },
    {
        name: "thinq_settinigs_lg_thinq_main_sound_on",
        category: "lg_thinq",
        action: "settinigs",
        label: "lg_thinq_main_sound_on"
    },
    {
        name: "thinq_settinigs_unique&delightful_sound_off",
        category: "lg_thinq",
        action: "settinigs",
        label: "unique&delightful_sound_off"
    },
    {
        name: "thinq_upgradable_appliance_adjust_brightness",
        category: "lg_thinq",
        action: "upgradable_appliance",
        label: "adjust_brightness"
    },
    {
        name: "thinq_upgradable_appliance_pet_care_course",
        category: "lg_thinq",
        action: "upgradable_appliance",
        label: "pet_care_course"
    },
    {
        name: "thinq_upgradable_appliance_splash_screen/washer_ending_melody",
        category: "lg_thinq",
        action: "upgradable_appliance",
        label: "splash_screen/washer_ending_melody"
    },
    {
        name: "thinq_upgradable_appliance_voice_control",
        category: "lg_thinq",
        action: "upgradable_appliance",
        label: "voice_control"
    },
    {
        name: "thinq_upgradable_appliance_dosage_adjustment",
        category: "lg_thinq",
        action: "upgradable_appliance",
        label: "dosage_adjustment"
    },
    {
        name: "thinq_upgradable_appliance_operating_mode",
        category: "lg_thinq",
        action: "upgradable_appliance",
        label: "operating_mode"
    },
];
