/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/**
 * Lupin2 Slide in Best
 * */
import * as THREE from 'three'
import React, {useEffect, useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from '../../components/common/const'
import useProductStore from '../../stores/productStore'
import { disposeScene } from '../../utils/disposeScene'
import { useLoader } from '@react-three/fiber'
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
  nodes: {
    Lupin_Slide: THREE.Mesh
    Door001: THREE.Mesh
    Top002: THREE.Mesh
    LG_LOGO_DECAL002: THREE.Mesh
    UI002: THREE.Mesh
    Object001001: THREE.Mesh
    Plane003: THREE.Mesh
    Plane002: THREE.Mesh
  }
  materials: {
    mat_darkgray: THREE.MeshStandardMaterial
    MetalSilver: THREE.MeshStandardMaterial
    ['mat_black.001']: THREE.MeshStandardMaterial
    LG_Logo_Decal: THREE.MeshStandardMaterial
    UI: THREE.MeshStandardMaterial
    Map: THREE.MeshStandardMaterial
    MetalBlack: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/oven/m/lupin_slide_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/oven/lupin_slide_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  //@ts-ignore
  const {nodes, materials, scene} = useKTXLoader(GLTF_URL);

  const mesh1_Ref = useRef<THREE.Mesh>(null!);
  const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
  const selectedProduct = useProductStore(state => state.selectedProduct);

  useEffect(()=>{
    if(selectedProduct?.modelNo === 'lupin_slide'){
      materials.MetalBlack.color.setRGB(0.15, 0.15, 0.15);
      if(selectedColorIndex === 0){
        mesh1_Ref.current.material = materials.MetalSilver;
      }
      else{
        mesh1_Ref.current.material = materials.MetalBlack;
      }
      // mesh1_Ref.current.material.needsUpdate = true;
    }
  }, [selectedProduct, selectedColorIndex]);

  useEffect(() => {
    mesh1_Ref.current.material = materials.MetalSilver;

    return () => {
      disposeScene(scene);
      useLoader.clear(GLTFLoader, GLTF_URL);
    }
  }, []);

  return (
      <group ref={group} {...props} position={[0, -0.04, 0.32]}>
        <mesh
            geometry={nodes.Lupin_Slide.geometry}
            material={materials.mat_darkgray}
            position={[0, 0.4288, -0.2922]}

        >

          <mesh
              geometry={nodes.Door001.geometry}
              material={nodes.Door001.material}
              position={[-0.0012, -0.227, 0.1965]}
              scale={[0.01, 0.01, 0.01]}
              ref={mesh1_Ref}
          />
          <mesh
              geometry={nodes.Top002.geometry}
              material={materials['mat_black.001']}
              position={[-0.0015, -0.4288, 0.2922]}
          />
          <mesh
              geometry={nodes.LG_LOGO_DECAL002.geometry}
              material={materials.LG_Logo_Decal}
              position={[0, -0.199, 0.2382]}
              rotation={[Math.PI / 2, 0, 0]}
          />
          <mesh
              geometry={nodes.UI002.geometry}
              material={materials.UI}
              position={[0, -0.4187, 0.3212]}
          />
          <mesh
              geometry={nodes.Object001001.geometry}
              material={materials.Map}
              position={[-0.0022, 0.0546, 0.2358]}
              scale={[0.0102, 0.0102, 0.01]}
          />
        </mesh>
        <mesh geometry={nodes.Plane003.geometry} material={materials.MetalBlack} />
        <mesh geometry={nodes.Plane002.geometry} material={nodes.Plane002.material} />
      </group>
  )
}
