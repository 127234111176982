/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {useGLTF} from "@react-three/drei";

type GLTFResult = GLTF & {
    nodes: {
        Plane: THREE.Mesh
        scallet2_better: THREE.Mesh
        ['19_Door']: THREE.Mesh
        ['19_Door001']: THREE.Mesh
        ['19_Metal001']: THREE.Mesh
        Plane001: THREE.Mesh
    }
    materials: {
        MetalSilver: THREE.MeshStandardMaterial
        ['18_Body']: THREE.MeshStandardMaterial
        ['19_Door.003']: THREE.MeshStandardMaterial
        ['19_Metal.003']: THREE.MeshStandardMaterial
        MetalBlack: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/microwave/m/scallet2_better_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/microwave/scallet2_better_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/microwave/scallet2_better.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;
    // const {nodes, materials, scene} = useGLTF<GLTFResult>(GLTF_URL) as GLTFResult;

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct){
            if(selectedProduct?.modelNo === 'scallet2_better'){
                if(selectedColorIndex === 1){
                    mesh1_Ref.current.material = materials.MetalSilver;
                }
                else{
                    mesh1_Ref.current.material = materials.MetalBlack;
                }
                mesh1_Ref.current.material.needsUpdate = true;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        mesh1_Ref.current.material = materials.MetalBlack;
        materials['19_Metal.003'].metalnessMap = materials['19_Metal.003'].map;
        materials['19_Metal.003'].emissiveIntensity = 3;
        materials['19_Metal.003'].roughness = 0.5;
        materials['19_Metal.003'].metalness = 0;
        materials['19_Metal.003'].needsUpdate = true;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0.008, -0.013, 0.27]} scale={[1.01,1,1]}>
            <mesh
                geometry={nodes.Plane.geometry}
                material={nodes.Plane.material}
                userData={{ name: 'Plane' }}
            />
            <mesh
                ref={mesh1_Ref}
                geometry={nodes.scallet2_better.geometry}
                material={nodes.scallet2_better.material}
                userData={{ name: 'scallet2_better' }}>
                <mesh
                    geometry={nodes['19_Door'].geometry}
                    material={materials['18_Body']}
                    userData={{ name: '19_Door' }}

                />
                <mesh
                    geometry={nodes['19_Door001'].geometry}
                    material={materials['19_Door.003']}
                    userData={{ name: '19_Door.001' }}

                />
                <mesh
                    geometry={nodes['19_Metal001'].geometry}
                    material={materials['19_Metal.003']}
                    position={[0, 0, 0.001]}
                    userData={{ name: '19_Metal.001' }}

                />
            </mesh>
            <mesh
                geometry={nodes.Plane001.geometry}
                material={materials.MetalBlack}
                userData={{ name: 'Plane.001' }}

            />

            <mesh position={[-0.005, 0.47, -0.215]}>
                <boxBufferGeometry args={[0.76, 0.1, 0.3]} />
                <meshStandardMaterial color={"#111"} />
            </mesh>
        </group>
    )
}


// /*
// Auto-generated by: https://github.com/pmndrs/gltfjsx
// */
//
// import * as THREE from 'three'
// import React, {useEffect, useRef} from 'react'
// import { useGLTF } from '@react-three/drei'
// import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
// import {ROOT_PATH_PREFIX} from "../../components/common/const";
// import {disposeScene} from "../../utils/disposeScene";
// import {useLoader} from "@react-three/fiber";
// import {GLTFLoader} from "three-stdlib";
//
// type GLTFResult = GLTF & {
//   nodes: {
//     ['19_Metal001']: THREE.Mesh
//     ['19_Door']: THREE.Mesh
//     ['19_Body']: THREE.Mesh
//     ['19_Metal']: THREE.Mesh
//     ['19_Door001']: THREE.Mesh
//   }
//   materials: {
//     ['19_Metal.001']: THREE.MeshStandardMaterial
//     ['19_Door']: THREE.MeshStandardMaterial
//     ['19_Body']: THREE.MeshStandardMaterial
//     ['19_Metal']: THREE.MeshStandardMaterial
//     ['19_Door.001']: THREE.MeshStandardMaterial
//   }
// }
//
// export default function Model(props: JSX.IntrinsicElements['group']) {
//   const group = useRef<THREE.Group>()
//   const { nodes, materials, scene } = useGLTF(ROOT_PATH_PREFIX+'/models/11.26/scallet2_better/scaller2_better.gltf') as GLTFResult
//
//     useEffect(() => {
//         return () => {
//             disposeScene(scene);
//             useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/models/11.26/scallet2_better/scaller2_better.gltf');
//         }
//     }, []);
//
//   return (
//     <group ref={group} {...props} position={[0,-0.028,0.27]} dispose={null}>
//       <mesh
//         geometry={nodes['19_Metal001'].geometry}
//         material={materials['19_Metal.001']}
//         position={[-0.0915, 0.2114, -0.0605]}
//         scale={[0.001, 0.001, 0.001]}
//         userData={{ name: '19_Metal.001' }}
//       />
//       <mesh
//         geometry={nodes['19_Door'].geometry}
//         material={materials['19_Door']}
//         position={[-0.0915, 0.2114, -0.0605]}
//         scale={[0.001, 0.001, 0.001]}
//         userData={{ name: '19_Door' }}
//       />
//       <mesh
//         geometry={nodes['19_Body'].geometry}
//         material={materials['19_Body']}
//         position={[-0.4688, 0.4508, -0.4038]}
//         scale={[0.01, 0.01, 0.01]}
//         userData={{ name: '19_Body' }}
//       />
//       <mesh
//         geometry={nodes['19_Metal'].geometry}
//         material={materials['19_Metal']}
//         position={[-0.0915, 0.2114, -0.0605]}
//         scale={[0.001, 0.001, 0.001]}
//         userData={{ name: '19_Metal' }}
//       />
//       <mesh
//         geometry={nodes['19_Door001'].geometry}
//         material={materials['19_Door.001']}
//         position={[-0.0915, 0.2114, -0.0605]}
//         scale={[0.001, 0.001, 0.001]}
//         userData={{ name: '19_Door.001' }}
//       />
//         <mesh position={[0, 0.47, -0.06]}>
//             <boxBufferGeometry args={[0.76, 0.1, 0.1]} />
//             <meshStandardMaterial color={"#111"} />
//         </mesh>
//     </group>
//   )
// }
