import {ProductColorType} from "../productsData";

//Refrigerator
export const Refrigerator_Fenix_Botanic: ProductColorType = {
    name: <>Botanic</>,
    label: 'objet_color_botanic',
    color: '/images/objet/fenix/refrigerator/botanic.png',
    ga_id: "botanic"
};
export const Refrigerator_Fenix_Sand: ProductColorType = {
    name: <>Sand</>,
    label: 'objet_color_sand',
    color: '/images/objet/fenix/refrigerator/sand.png',
    ga_id: "sand"
};
export const Refrigerator_Fenix_Stone: ProductColorType = {
    name: <>Stone</>,
    label: 'objet_color_stone',
    color: '/images/objet/fenix/refrigerator/stone.png',
    ga_id: "stone"
};
