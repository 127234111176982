import {UspDataItemType, UspPageDataType} from "./uspData";


//---------------------------------------------------[EN]
const uspData_VacuumCleaner: UspPageDataType[] = [
    {
        category: "vacuum_cleaner",
        modelNo:"a9k",
        bgImgUrl:"/images/usp/vacuum_cleaner/a9k.jpg",
        uspList:[
            {
                label:"a9k_usp_0",
                title: [<>You can clean without<br/>wetting the mop</>],
                subMsg: [<>Power Drive Mop</>],
                videoUrl: "https://vimeo.com/654776976/7738caa5de",
                x: 347,
                y: 690,
                ga_id: "power_drive_mop"
            },
            {
                isRight: true,
                label:"a9k_usp_0",
                title: [<>There's an easier way to<br/>empty the dustbin?</>],
                subMsg: [<>Kompressor</>],
                videoUrl: "https://vimeo.com/654776940/2471c5b191",
                x: 1059,
                y: 232,
                ga_id: "kompressor"
            },
        ]
    },
    {
        category: "vacuum_cleaner",
        modelNo:"a9t",
        bgImgUrl:"/images/usp/vacuum_cleaner/a9t.jpg",
        uspList:[
            {
                label:"a9t_usp_0",
                title: [<>Still using hands<br/>to empty the dustbin?</>],
                subMsg: [<>Hygienic Dust Emptying</>],
                videoUrl: "https://vimeo.com/658885615/10584ec9e6",
                x: 300,
                y: 220,
                ga_id: "hygienic_dust_emptying"
            },
            {
                label:"a9t_usp_0",
                title: [<>Takes care of even<br/>the dust pack</>],
                subMsg: [<>UV sterilization</>],
                videoUrl: "https://vimeo.com/658885695/a0f455c6d6",
                x: 300,
                y: 370,
                ga_id: "uv_sterilization"
            },
            {
                isRight: true,
                label:"a9t_usp_0",
                title: [<>Ever seen accessories<br/>this diverse?</>],
                subMsg: [<>Stores accessories up to 6 and<br/>charges extra battery</>],
                videoUrl: "https://vimeo.com/658885649/997e68f8e4",
                x: 1095,
                y: 650,
                ga_id: "stores_accessories_up_to_6_and_charges_extra_battery"
            },
        ]
    },
    {
        category: "vacuum_cleaner",
        modelNo:"r9",
        bgImgUrl:"/images/usp/vacuum_cleaner/r9.jpg",
        uspList:[
            {
                isRight: true,
                label:"r9_usp_0",
                title: [<>The robot vacuum adapted<br/>itself to our home</>],
                subMsg: [<>3D Dual eye (3D View + 3D sensor)</>],
                videoUrl: "https://vimeo.com/654777003/e29c0943d9",
                x: 1265,
                y: 310,
                ga_id: "3d_dual_eye"
            },
        ]
    },
    {
        category: "vacuum_cleaner",
        modelNo:"m9",
        bgImgUrl:"/images/usp/vacuum_cleaner/m9.jpg",
        uspList:[
            {
                label:"m9_usp_0",
                title: [<>Take a deep nap while the<br/>house is being cleaned.</>],
                subMsg: [<>Low Noise</>],
                videoUrl: "https://vimeo.com/654777075/d18e128dda",
                x: 109,
                y: 391,
                ga_id: "low_noise"
            },
            {
                isRight: true,
                label:"m9_usp_0",
                title: [<>Cleans even the smudges</>],
                subMsg: [<>Dual Spin Mop</>],
                videoUrl: "https://vimeo.com/654777043/6a1ea27fb9",
                x: 1296,
                y: 555,
                ga_id: "dual_spin_mop"
            },
        ]
    },
];

export default uspData_VacuumCleaner;
