/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
/**
 * CW Lupin2 Gas S/in Double Best
 * */
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {useKTXLoader} from "../../utils/useKTXLoader";
import {disposeScene} from "../../utils/disposeScene";
import { useLoader } from '@react-three/fiber';


type GLTFResult = GLTF & {
    nodes: {
        Plane002: THREE.Mesh
        Plane003: THREE.Mesh
        Mesh035: THREE.Mesh
        Mesh035_1: THREE.Mesh
        Door_Glass: THREE.Mesh
        Top: THREE.Mesh
        LG_LOGO_DECAL004: THREE.Mesh
        UI: THREE.Mesh
    }
    materials: {
        MetalSilver: THREE.MeshStandardMaterial
        MetalBlack: THREE.MeshStandardMaterial
        mat_darkgray: THREE.MeshStandardMaterial
        ['Glass.001']: THREE.MeshStandardMaterial
        ['mat_black.001']: THREE.MeshStandardMaterial
        LG_Logo_Decal: THREE.MeshStandardMaterial
        UI: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/oven/m/oven_gas_sin_double_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/oven/oven_gas_sin_double_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    //@ts-ignore
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult;

    const mesh1_Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'lupin_gas_double'){
            materials.MetalBlack.color.setRGB(0.15, 0.15, 0.15);
            if(selectedColorIndex === 1){
                mesh1_Ref.current.material = materials.MetalSilver;
            }
            else{
                mesh1_Ref.current.material = materials.MetalBlack;
            }
            // mesh1_Ref.current.material.needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} position={[0, -0.035, 0.32]}>
            <mesh
                geometry={nodes.Plane002.geometry}
                material={nodes.Plane002.material}
                userData={{ name: 'Plane.002' }}
            />
            <mesh
                geometry={nodes.Plane003.geometry}
                material={materials.MetalBlack}
                userData={{ name: 'Plane.003' }}
            />
            <group userData={{ name: 'CW_Lupin2_Gas_Sin_Double_Best' }}>
                <mesh geometry={nodes.Mesh035.geometry} material={nodes.Mesh035.material} ref={mesh1_Ref} />
                <mesh geometry={nodes.Mesh035_1.geometry} material={materials.mat_darkgray} />
                <mesh
                    geometry={nodes.Door_Glass.geometry}
                    material={materials['Glass.001']}
                    userData={{ name: 'Door_Glass' }}
                />
                <mesh
                    geometry={nodes.Top.geometry}
                    material={materials['mat_black.001']}
                    userData={{ name: 'Top' }}
                />
                <mesh
                    geometry={nodes.LG_LOGO_DECAL004.geometry}
                    material={materials.LG_Logo_Decal}
                    position={[0, 0.072, -0.0779]}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={0.08}
                    userData={{ name: 'LG_LOGO_DECAL.004' }}
                />
                <mesh
                    geometry={nodes.UI.geometry}
                    material={materials.UI}
                    position={[0, 0.0016, 0.0016]}
                    userData={{ name: 'UI' }}
                />
            </group>
        </group>
    )
}
