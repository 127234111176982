import {ProductsDataItemType} from "./productsData";
import Td_washer from "../../products/washer_n_dryer/Td_washer";
import Washtower from "../../products/washer_n_dryer/Washtower";
import Victor2r from "../../products/washer_n_dryer/Victor2r";
import Fx from "../../products/washer_n_dryer/Fx";


/**
 * 세탁기 데이터
 */
const productsData_WashernDryer: ProductsDataItemType = {
    category: "washer_n_dryer",
    ga_id: "washer_dryer",
    label: "category_washer_n_dryer",
    category_img_url: "/images/products/washer_n_dryer/category_img.png",
    category_thumb_url: "/images/products/washer_n_dryer/category_thumb.png",
    category_glow_url: "/images/products/washer_n_dryer/category_glow.png",
    roomType: "utility",
    usp_transition: "/movies/usp/transition/main_WM_000_00000.webm",
    productList: [
        {
            name: "WashTower 27",
            modelNo: "washtower",
            ga_id: "washtower_27",
            isCES: true,
            thumb_url: "/images/products/washer_n_dryer/washtower/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/washer_n_dryer/washtower/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blacksteel",
                    color:
                        "/images/products/washer_n_dryer/washtower/color_black_pcm.png",
                    ga_id: "black_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color:
                        "/images/products/washer_n_dryer/washtower/color_white_pcm.png",
                    ga_id: "white",
                },
            ],
            model: <Washtower/>,
        },
        {
            name: "FX Washer & Dryer",
            modelNo: "fx",
            ga_id: "fx_washer/dryer",
            isCES: true,
            thumb_url: "/images/products/washer_n_dryer/fx/product_thumb.png",
            openExperience: true,
            open_url: "/images/products/washer_n_dryer/fx/open.png",
            design_url: "/images/products/washer_n_dryer/fx/design.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_blacksteel",
                    color: "/images/products/washer_n_dryer/fx/color_black_pcm.png",
                    ga_id: "black_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/washer_n_dryer/fx/color_white_pcm.png",
                    ga_id: "white",
                },
            ],
            model: <Fx/>,
        },
        {
            name: "Victor2R Best 27 4in1",
            modelNo: "victor2r",
            ga_id: "victor2r_best_washer/dryer",
            isCES: false,
            thumb_url: "/images/products/washer_n_dryer/victor2r/product_thumb.png",
            ar_url: "http://lgevirtual.com/ar/toploadwasher",
            ar_text: "home_exp_ar_victor2r",
            openExperience: true,
            open_url: "/images/products/washer_n_dryer/victor2r/open.png",
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_black",
                    color: "/images/products/washer_n_dryer/victor2r/color_black_pcm.png",
                    ga_id: "black_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/washer_n_dryer/victor2r/color_white_pcm.png",
                    ga_id: "white",
                },
            ],
            model: <Victor2r/>,
        },
        {
            name: "TD27 pro2 pair",
            modelNo: "td_washer",
            ga_id: "td27_pro2_washer/dryer",
            isCES: false,
            thumb_url: "/images/products/washer_n_dryer/td27/product_thumb.png",
            ar_url: "http://lgevirtual.com/ar/toploadwasher",
            ar_text: "home_exp_ar_td_washer",
            ar_url_2: "http://lgevirtual.com/ar/toploaddryer",
            ar_text_2: (
                <>
                    See what Washer & Dryer will look like in your space without app
                    download
                </>
            ),
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_graphite",
                    color: "/images/products/washer_n_dryer/td27/color_graphite.png",
                    ga_id: "graphite_steel",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/washer_n_dryer/td27/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Td_washer/>,
        },
    ],
};

export default productsData_WashernDryer;
