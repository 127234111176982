/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {ROOT_PATH_PREFIX} from "../../components/common/const";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {disposeScene} from "../../utils/disposeScene";
import {useKTXLoader} from "../../utils/useKTXLoader";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    // nodes: {
    //     Black: THREE.Mesh
    //     BRep_19: THREE.Mesh
    //     gold: THREE.Mesh
    //     mint: THREE.Mesh
    //     lavender: THREE.Mesh
    //     mango: THREE.Mesh
    //     Top: THREE.Mesh
    // }
    // materials: {
    //     ['Black.001']: THREE.MeshStandardMaterial
    //     gold: THREE.MeshStandardMaterial
    //     mint: THREE.MeshStandardMaterial
    //     lavender: THREE.MeshStandardMaterial
    //     mango: THREE.MeshStandardMaterial
    //     ['Top.001']: THREE.MeshStandardMaterial
    // }

    nodes: {
        ['05']: THREE.Mesh
        ['01']: THREE.Mesh
        ['02']: THREE.Mesh
        pick_gold: THREE.Mesh
        pick_mint: THREE.Mesh
        pick_laverder: THREE.Mesh
        pick_mango: THREE.Mesh
    }
    materials: {
        ['Black.002']: THREE.MeshStandardMaterial
        pick_gold: THREE.MeshStandardMaterial
        pick_mint: THREE.MeshStandardMaterial
        pick_lavender: THREE.MeshStandardMaterial
        pick_mango: THREE.MeshStandardMaterial
    }
}

//
const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/air_care/puricare_mini2_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    //@ts-ignore
    // const {nodes, materials, scene} = useGLTF<GLTFResult>(GLTF_URL);

    const topRef = useRef<THREE.Mesh>(null!);
    const bodyRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    //색상 변경 시
    useEffect(() => {
        if (selectedProduct?.modelNo === 'puricare_mini_2nd') {
            if (selectedColorIndex === 0) {
                // materials.gold.roughness = 0.3;
                // bodyRef.current.material = materials.gold;
                // materials["Top.001"].color.setRGB(0.1, 0.1, 0.1);

                topRef.current.material = materials.pick_gold;
                bodyRef.current.material = materials.pick_gold;
                // nodes["01"].material = materials.pick_gold;
                // nodes["02"].material = materials.pick_gold;
                materials['Black.002'].color.setRGB(0.1, 0.1, 0.1);


            } else if (selectedColorIndex === 1) {


                // bodyRef.current.material = materials.mint;
                // materials["Top.001"].color.setRGB(1,1,1);
                topRef.current.material = materials.pick_mint;
                bodyRef.current.material = materials.pick_mint;
                // nodes["01"].material = materials.pick_mint;
                // nodes["02"].material = materials.pick_mint
                materials['Black.002'].color.setRGB(1, 1, 1)
            }else if (selectedColorIndex === 2) {

                topRef.current.material = materials.pick_mango;
                bodyRef.current.material = materials.pick_mango;
                // bodyRef.current.material = materials.mango;
                // materials["Top.001"].color.setRGB(1,1,1);
                // nodes["01"].material = materials.pick_mango;
                // nodes["02"].material = materials.pick_mango;
                materials['Black.002'].color.setRGB(1, 1, 1,);
            }else if (selectedColorIndex === 3) {
                // bodyRef.current.material = materials.lavender;
                // materials["Top.001"].color.setRGB(1,1,1);

                topRef.current.material = materials.pick_lavender;
                bodyRef.current.material = materials.pick_lavender;
                // nodes["01"].material = materials.pick_lavender;
                // nodes["02"].material = materials.pick_lavender;
                materials['Black.002'].color.setRGB(1, 1, 1,);
            }
            // materials.pick_mint.needsUpdate = true;
            // materials.pick_lavender.needsUpdate = true;
            // materials.pick_mango.needsUpdate = true;
            // materials.pick_gold.needsUpdate = true;
            // materials['Black.002'].needsUpdate = true;
        }
    }, [selectedProduct, selectedColorIndex]);


    useEffect(() => {
        // materials.gold.metalness = 1;
        // materials.gold.roughness = 0.3;
        // materials.gold.envMapIntensity = 1;
        // materials.gold.metalnessMap = null;
        // materials.gold.roughnessMap = null;
        // materials.gold.needsUpdate = true;
        // bodyRef.current.material = materials.gold;
        nodes["01"].material = materials.pick_gold;
        nodes["02"].material = materials.pick_gold;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

  return (
    <group ref={group} {...props}  dispose={null}>
      {/*<mesh*/}
      {/*    geometry={nodes.Black.geometry}*/}
      {/*    material={materials['Black.001']}*/}
      {/*    scale={[0.001, 0.001, 0.001]}*/}
      {/*    userData={{ name: 'Black' }}*/}
      {/*    ref={topRef}*/}
      {/*    />*/}
      {/*<mesh*/}
      {/*    geometry={nodes.BRep_19.geometry}*/}
      {/*    material={nodes.BRep_19.material}*/}
      {/*    scale={[0.001, 0.001, 0.001]}*/}
      {/*    userData={{ name: 'BRep_19' }}*/}
      {/*    ref={bodyRef}*/}
      {/*/>*/}
      {/*<mesh*/}
      {/*    geometry={nodes.gold.geometry}*/}
      {/*    material={nodes.gold.material}*/}
      {/*    position={[0, 1.2483, 0]}*/}
      {/*    scale={[0.0115, 0.0115, 0.0115]}*/}
      {/*    userData={{ name: 'gold' }}*/}
      {/*    visible={false}*/}
      {/*/>*/}
      {/*<mesh*/}
      {/*    geometry={nodes.mint.geometry}*/}
      {/*    material={materials.mint}*/}
      {/*    position={[0.0312, 1.2483, 0]}*/}
      {/*    scale={[0.0115, 0.0115, 0.0115]}*/}
      {/*    userData={{ name: 'mint' }}*/}
      {/*    visible={false}*/}
      {/*/>*/}
      {/*<mesh*/}
      {/*    geometry={nodes.lavender.geometry}*/}
      {/*    material={materials.lavender}*/}
      {/*    position={[0.0772, 1.2483, 0]}*/}
      {/*    scale={[0.0115, 0.0115, 0.0115]}*/}
      {/*    userData={{ name: 'lavender' }}*/}
      {/*    visible={false}*/}
      {/*/>*/}
      {/*<mesh*/}
      {/*    geometry={nodes.mango.geometry}*/}
      {/*    material={materials.mango}*/}
      {/*    position={[0.1312, 1.2483, 0]}*/}
      {/*    scale={[0.0115, 0.0115, 0.0115]}*/}
      {/*    userData={{ name: 'mango' }}*/}
      {/*    visible={false}*/}
      {/*/>*/}
      {/*<mesh*/}
      {/*    geometry={nodes.Top.geometry}*/}
      {/*    material={materials['Top.001']}*/}
      {/*    scale={[0.001, 0.001, 0.001]}*/}
      {/*    userData={{ name: 'Top' }}*/}
      {/*/>*/}
        <mesh geometry={nodes['05'].geometry} material={materials['Black.002']} />
        <mesh geometry={nodes['01'].geometry} material={nodes['01'].material} ref={topRef} />
        <mesh geometry={nodes['02'].geometry} material={nodes['02'].material} ref={bodyRef}/>
        <mesh geometry={nodes.pick_gold.geometry} material={nodes.pick_gold.material} />
        <mesh geometry={nodes.pick_mint.geometry} material={materials.pick_mint} />
        <mesh geometry={nodes.pick_laverder.geometry} material={materials.pick_lavender} />
        <mesh geometry={nodes.pick_mango.geometry} material={materials.pick_mango} />

    </group>
  )
}

