/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene} from "../../../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {GLTFResult_ObjetModernProducts} from "./Product_Harvester";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {Objet_ColorCategoryType, styler_data} from "../../../../api/products/objetProductsData";
import {LangLabelType} from "../../../../api/lang/langData";
import {easeCubicInOut} from "d3-ease";
import {TextureLoader} from "three";
import {a} from "@react-spring/three";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_products_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Styler_modern.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Styler_modern.jpg');

const DECAL_URL = ROOT_PATH_PREFIX + "/models/rooms/objetcollection/natural/styler/tex/54_ui_on-1.png";

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/styler/BaronObject_Albedo_1.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/styler/BaronObject_Albedo_1.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/styler/Baron(Object)_Albedo_1_Beige.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/styler/Baron(Object)_Albedo_1_Beige.jpg');
const COLOR_2_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/styler/styler_mist_clay_brown.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/styler/styler_mist_clay_brown.jpg');
const COLOR_3_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/styler/styler_mist_redwood.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/styler/styler_mist_redwood.jpg');
const COLOR_4_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/styler/styler_glass_ivory.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/styler/styler_glass_ivory.jpg');
const COLOR_5_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/styler/styler_glass_black.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/styler/styler_glass_black.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult_ObjetModernProducts
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);
    const decalTex = useTexture(DECAL_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.styler_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.styler_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [spring, animate] = useSpring(() => ({from: {opacity: 0}}));
    const [isSet, setIsSet] = useState(false);

    function setupTex(tex: THREE.Texture) {
        tex.flipY = false;
        tex.encoding = THREE.sRGBEncoding;
        tex.needsUpdate = true;
    }

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsSet(true);

        materials[`${materialName}`].color.setRGB(0.5, 0.5, 0.5);
        materials.mat_styler_ui.color.setHex(0xffffff);

        if (colorCategory === 'Mist') {
            materials.mat_styler_body.color.setRGB(0, 0, 0);
            materials.mat_styler_body.needsUpdate = true;

            if (colorLabel === 'objet_color_green') {
                setupTex(colorTextures[0]);
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_beige') {
                materials.mat_styler_ui.color.setHex(0x1111111);
                setupTex(colorTextures[1]);
                materials[`${materialName}`].map = colorTextures[1];

            } else if (colorLabel === 'objet_color_clay_brown') {
                setupTex(colorTextures[2]);
                materials[`${materialName}`].map = colorTextures[2];
            } else if (colorLabel === 'objet_color_redwood') {
                setupTex(colorTextures[3]);
                materials[`${materialName}`].map = colorTextures[3];
            }
            materials[`${materialName}`].metalness = 0;
            materials[`${materialName}`].roughness = 1;

        } else if (colorCategory === 'Glass') {
            materials[`${materialName}`].metalness = 0;
            materials[`${materialName}`].roughness = 0.5;
            materials[`${materialName}`].clearcoat = 0;

            materials.mat_styler_body.color.setRGB(1, 1, 1);
            materials.mat_styler_body.needsUpdate = true;

            if (colorLabel === 'objet_color_ivory') {
                setupTex(colorTextures[4]);
                materials[`${materialName}`].map = colorTextures[4];
            } else if (colorLabel === 'objet_color_linen_black') {
                setupTex(colorTextures[5]);
                materials[`${materialName}`].map = colorTextures[5];
            } else if (colorLabel === 'objet_color_brown') {
                materials[`${materialName}`].map = null;
                materials[`${materialName}`].color.setHex(0x161111);
            } else if (colorLabel === 'objet_color_charcoal_grey') {
                materials[`${materialName}`].map = null;
                materials[`${materialName}`].color.setHex(0x111111);
            } else if (colorLabel === 'objet_color_black') {
                materials[`${materialName}`].map = null;
                materials[`${materialName}`].color.setHex(0x050505);
            } else if (colorLabel === 'objet_color_white') {
                materials[`${materialName}`].map = null;
                materials[`${materialName}`].color.setHex(0xeeeeee);
                materials.mat_styler_ui.color.setHex(0x1111111);
            } else if (colorLabel === 'objet_color_black_mirror') {
                materials[`${materialName}`].map = null;
                materials[`${materialName}`].color.setHex(0x333333);
                materials[`${materialName}`].metalness = 1;
                materials[`${materialName}`].roughness = 0;
            }
        }
        materials[`${materialName}`].needsUpdate = true;
    }, []);

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 2000,
                config: {duration: 1000, easing: easeCubicInOut},
                // onRest: result => {
                //     console.log(">>>>>", result);
                // }
            });
        }
    }, [isOpenTutorial]);

    useLayoutEffect(() => {
        changeTexture("mat_styler_door", colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        decalTex.flipY = false;
        decalTex.needsUpdate = true;
        materials.mat_styler_ui.map = decalTex;
        materials.mat_styler_ui.roughness = 1;

        materials.mat_styler_body.lightMap = lightmapTex;
        materials.mat_styler_body.lightMapIntensity = 0.4;
        materials.mat_styler_door.lightMap = lightmapTex;
        materials.mat_styler_door.lightMapIntensity = 1;
        // materials.mat_styler_door.metalness = 0.2;
        // materials.mat_styler_door.roughness = 1;

        materials.mat_styler_body.map = null;
        materials.mat_styler_body.roughness = 0.8;
        materials.mat_styler_body.metalness = 0.5;
        materials.mat_styler_body.envMapIntensity = 0.3;

    }, [lightmapTex, decalTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            decalTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, DECAL_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL
            ]);
        }
    }, []);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.styler.geometry}
                material={materials.mat_styler_body}
                position={[-2.4418, 1.1606, -7.1282]}
                rotation={[0, Math.PI / 2, 0]}>
                <mesh
                    geometry={nodes.Ui_display.geometry}
                    material={materials.mat_styler_ui}
                    position={[-0.0005, -1.0614, 0.3229]}
                />
                <mesh
                    geometry={nodes.Door.geometry}
                    material={materials.mat_styler_door}
                    position={[-0.0005, -1.0614, 0.3229]}
                >
                    <ObjetProductContainer3D
                        args={[0.55, 2, 0.01]}
                        position={[0, 1, 0]}
                        data={styler_data}
                        showDebug={false}
                        cameraTargetPos={[0, 0, 3]}
                    />
                </mesh>
            </mesh>

            <mesh
                geometry={nodes.styler.geometry}
                material={materials.mat_styler_body}
                position={[-2.4418, 1.1606, -7.1282]}
                rotation={[0, Math.PI / 2, 0]}
                visible={!isSet}
            >
                <mesh
                    geometry={nodes.Door.geometry}
                    material={materials.mat_styler_door}
                    position={[-0.0005, -1.0614, 0.3229]}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                    <ObjetPlusButton position={[0, 1, 0]} scale={0.8} visible={!(isSet)} opacity={spring.opacity}
                                     color={pathname.indexOf('/styler') > -1 ? '#be1e42' : '#000'}/>
                </mesh>
            </mesh>
        </group>
    )
}
