import {SphereProps, useSphere} from "@react-three/cannon";
import {useInput} from "../../../utils/useKeyPress";
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import * as THREE from "three";
import {useFrame, useThree} from "@react-three/fiber";
import useCameraControls from "../../../utils/useCameraControls";
import Axis from "../../../utils/Axis";
import {Layer} from "../const";
import useCameraStore, {CameraState} from "../../../stores/cameraStore";
import shallow from "zustand/shallow";
import {easeCubicInOut} from "d3-ease";
import useHistoryStore, {HistoryState} from "../../../stores/historyStore";
import sleep from "../../../utils/sleep";
import useRightPanelStore, {RightPanelState} from "../../../stores/rightPanelStore";
import qs from "qs";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";

/**
 * 카메라 추적 대상
 */

type PlayerProps = {
    baseHeight?: number;    //카메라 기본 높이
    speed?: number;
} & SphereProps & JSX.IntrinsicElements['group'];

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const pathnameSelector = (state: HistoryState) => state.pathname;
const prevPathnameSelector = (state: HistoryState) => state.prevPathname;
const cameraApiSelector = (state: CameraState) => state.api;
const pushHistorySelector = (state: HistoryState) => state.pushHistory;
const isOpenSelector = (state: RightPanelState) => state.isOpen;
// const setIsOpenSelector = (state: RightPanelState) => state.setIsOpen;
// const selectCategorySelector = (state: ProductState) => state.api.selectCategory;


const Player = React.memo(({baseHeight = 2, speed = 2, ...props}: PlayerProps) => {

    //캐논 물리엔진 연결
    const [ref, api] = useSphere(() => ({
        mass: 0.1,
        position: [0, 1, 0],
        angularDamping: 0.99,
        linearDamping: 0.9998, //1에 가까울 수록 마찰이 커지고 0에 가까울 수록 미끄러짐
        fixedRotation: true,
        collisionFilterMask: Layer.Character,
        onCollide: e => {
            console.log(e);
        },
        args: [0.1],
        type: "Dynamic",
        ...props
    }));

    const pathname = useHistoryStore(pathnameSelector);
    const prevPathname = useHistoryStore(prevPathnameSelector);
    const pushHistory = useHistoryStore(pushHistorySelector);
    const isPressBack = useHistoryStore(state => state.isPressBack);
    const objetTheme = useSpaceThemeStore(state => state.objetTheme);

    const [h, v, isPressed, isPressShift] = useInput();  //키보드 방향 가중치
    const [forceDir] = useState(() => new THREE.Vector3());
    const [upVec] = useState(() => new THREE.Vector3(0, 1, 0));
    const [axisX] = useState(() => new THREE.Vector3());
    const [axisY] = useState(() => new THREE.Vector3());
    const [axisZ] = useState(() => new THREE.Vector3());
    const [camPos] = useState(() => new THREE.Vector3());
    const [camDir] = useState(() => new THREE.Vector3());

    const camera = useThree(state => state.camera);
    const axisRef = useRef<THREE.Group>(null!);
    const pos = useRef([0, 0, 0]);
    const tempTargetPos = useRef<THREE.Vector3 | null>();

    const [cameraControls, rotateToTarget, cancelAnimation, isTweening] = useCameraControls();
    const {targetPos, lookPos, touchStart} = useCameraStore(state => ({
        targetPos: state.targetPos,
        lookPos: state.lookPos,
        touchStart: state.touchStart
    }), shallow);
    const {setTouchStart} = useCameraStore(cameraApiSelector);
    const isOpenRightPanel = useRightPanelStore(isOpenSelector);

    const isReady = useRef(false);

    function reset() {
        cancelAnimation();
        h.current = 0;
        v.current = 0;
        forceDir.set(0, 0, 0);
    }

    //카메라 설정 초기화
    function resetCameraSetting() {
        reset();
        api.position.set(0, 0, 0);
        pos.current = [0, 0, 0];
        cameraControls.reset(false);
        cameraControls.setLookAt(0, 0, 10.001, pos.current[0], pos.current[1], pos.current[2], false);
        cameraControls.enabled = false;
    }

    //제품 선택 시 포커스 맞추기
    function focusToProduct() {
        if (targetPos && lookPos) {
            rotateToTarget([targetPos.x, targetPos.y, targetPos.z], [lookPos.x, lookPos.y, lookPos.z], () => {
                cameraControls.enabled = true;
                api.position.set(targetPos.x, targetPos.y, targetPos.z);
                // console.log("azimuthAngle>>>>>>>", cameraControls.azimuthAngle);
            }, 1, 1.5, easeCubicInOut);

            // cameraControls.setLookAt(targetPos.x, targetPos.y, targetPos.z, lookPos.x, lookPos.y, lookPos.z, true);
        }
    }

    //히스토리 한 단계 뒤로
    function goBackHistory() {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        const {pathname} = query;
        const comp = pathname.split('/');
        const lastItem = comp.pop();

        //이미 한번 빠져나온 이후라면 다음 과정 무시
        if (lastItem === 'kitchen' || lastItem === 'living' || lastItem === 'utility' ||
            lastItem === 'blossom' || lastItem === 'natural' || lastItem === 'modern') {
            if (tempTargetPos.current && touchStart) {
                revertCameraState(tempTargetPos.current);
            }
            return;
        }
        let newPathname = comp.join('/');
        pushHistory(newPathname);
    }

    useEffect(() => {

        // if(pathname.indexOf('/objetcollection') > -1)
        // {
        if (isPressBack) {
            console.log("백버튼 눌렀음!");
            if (pathname.indexOf('objetcollection') > -1) {
                if (objetTheme === 'natural') {
                    api.position.set(0.3, 1, 11.5);
                    pos.current = [0.3, 1, 11.5];
                    cameraControls.setLookAt(0.3, 1, 11.5, 0.3, 1, 11.49, true);
                } else if (objetTheme === 'modern') {
                    api.position.set(7.3, 1, 5.5);
                    pos.current = [7.3, 1, 5.5];
                    cameraControls.setLookAt(7.3, 1, 5.5, 7.27, 1, 5.475, true);
                }
            } else if (pathname.indexOf('/home/living') > -1) {
                api.position.set(0, 1.3, -0.6);
                pos.current = [0, 1.3, -0.6];
                cameraControls.setLookAt(-1.25, 1.3, -1.38, -1.35, 1.3, -1.38, true);
            }
        }
        // }
    }, [pathname, isPressBack, objetTheme]);

    useEffect(() => {
        if (pathname === '' || pathname === '/') {
            resetCameraSetting();
        }
    }, [pathname])

    useEffect(() => {
        async function delayAction() {
            await sleep(1000);
            isReady.current = true;
        }

        delayAction();
    }, []);


    //공간 변경 시 진입 위치 수정
    useEffect(() => {
        if (!isReady.current) return;
        if (objetTheme === 'natural') {
            console.log("네추럴 공간으로!");
            api.position.set(0.3, 1, 11.5);
            pos.current = [0.3, 1, 11.5];
            cameraControls.setLookAt(0.3, 1, 11.5, 0.3, 1, 11.49, false);
        } else if (objetTheme === 'modern') {
            console.log("모던 공간으로!");
            api.position.set(7.3 + 0.5, 1 + 0.3, 5.5 - 1.5);
            pos.current = [7.3, 1 + 0.3 + 0.5, 5.5 - 1.5];
            cameraControls.setLookAt(7.3 + 0.5, 1 + 0.3 + 0.002, 5.5 - 1.5,
                7.27 + 0.5, 1 + 0.3, 5.475 - 1.5, false);
        }
    }, [objetTheme])


    //카메라 1인칭 시점으로 되돌리기
    async function revertCameraState(_targetPos: THREE.Vector3 | null = null, isFirst: boolean = false) {

        // if(isTweening.current) {
        console.log("애니메이션 중이니까 트윈 취소!");
        cancelAnimation();
        // }

        //키보드로 이동 시
        if (!_targetPos) {
            reset();

            cameraControls.getPosition(camPos);

            api.position.set(camPos.x, baseHeight, camPos.z);
            pos.current = [camPos.x, baseHeight, camPos.z];
            const dirVec = new THREE.Vector3();
            const [newForwardVec] = updateDirection();

            dirVec.subVectors(lookPos ? lookPos : newForwardVec, camPos).normalize().setY(0);
            const tPos = camPos.clone().add(dirVec.multiplyScalar(0.1));

            cameraControls.enabled = true;
            // if (isFirst) {
            // await sleep(100);
            // }

            cameraControls.setLookAt(camPos.x, baseHeight, camPos.z, tPos.x, baseHeight, tPos.z, !isFirst);   //setLookAt은 moveTo와 setPosition을 합친 형태이다.

            // console.log("isFirst >>>>>", isFirst, camPos, tPos);

            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>!", camPos, tPos, !isFirst);
        }
        //마우스 클릭으로 이동 시
        else {
            cameraControls.enabled = false;

            cameraControls.getPosition(camPos);
            cameraControls.getTarget(camDir);
            api.position.set(_targetPos.x, baseHeight, _targetPos.z);
            pos.current = [_targetPos.x, baseHeight, _targetPos.z];

            const dirVec = new THREE.Vector3();
            dirVec.subVectors(_targetPos, camPos).normalize().setY(0);
            const tPos = _targetPos.clone().add(dirVec.multiplyScalar(0.1));
            setTouchStart(false);

            rotateToTarget([_targetPos.x, baseHeight, _targetPos.z], [tPos.x, baseHeight, tPos.z], () => {
                cameraControls.enabled = true;
            }, 1, 2, easeCubicInOut);
            console.log("B >>>>>>>>>>>>>>>>");
        }

        if (isFirst) {
            if (pathname.indexOf('objetcollection/natural') > -1) {
                await sleep(100);
                cameraControls.setLookAt(0.3, 1, 11.5, 0.3, 1, 11.49, false);
            } else if (pathname.indexOf('objetcollection/modern') > -1) {
                await sleep(100);
                cameraControls.setLookAt(7.3 + 0.5, 1 + 0.3 + 0.002, 5.5 - 1.5, 7.27 + 0.5, 1 + 0.3, 5.475 - 1.5, false);
            } else {
                // cameraControls.setLookAt(0, baseHeight, 0, 0, baseHeight, -0.01, false);
                cameraControls.setLookAt(0, baseHeight, 0, 0.01, baseHeight, 0, false);
            }

            cameraControls.getPosition(camPos);
            api.position.set(camPos.x, baseHeight, camPos.z);
            pos.current = [camPos.x, baseHeight, camPos.z];
        }
    }

    useLayoutEffect(() => {
        const unsubscribe = api.position.subscribe(value => {
            pos.current = value;
            updateDirection();
            tempTargetPos.current = null;
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useLayoutEffect(() => {
        //키보드 이동 시 카메라 이전 상태로 되돌아가기
        if (isPressed && lookPos) {
            goBackHistory();
        }
        //터치 시 이동 처리
        else if (touchStart && targetPos && !lookPos) {
            tempTargetPos.current = targetPos;
            goBackHistory();
        }
        //제품 선택 시
        else if (targetPos && lookPos) {
            focusToProduct();
        }
    }, [targetPos, lookPos, touchStart, isPressed, cameraControls]);    //targetPos는 마우스 클릭 시 설정됨


    //카메라 정면 방향으로 방향 벡터 수정
    const updateDirection = useCallback(() => {
        camera.matrix.extractBasis(axisX, axisY, axisZ);
        const newForwardVec = axisX.clone().cross(upVec);

        axisRef.current.position.set(0, 0, 0);
        axisRef.current.lookAt(newForwardVec);
        axisRef.current.position.set(pos.current[0], pos.current[1], pos.current[2]);

        return [newForwardVec, axisX];
    }, [axisX, axisY, axisZ, camera.matrix, upVec]);


    const is3D = useRef(false);

    //페이지 경로 바뀔 때,
    useLayoutEffect(() => {
        if (pathname === '' || pathname === '/' || pathname === '/home' || pathname.indexOf('/thinq') > -1) {
            is3D.current = false;
            resetCameraSetting();
        } else {
            is3D.current = true;
            let isFirst = (prevPathname === '' || prevPathname === '/home' || prevPathname === '/objetcollection');

            //3D공간 첫 진입 시 카메라 초기화
            if (
                prevPathname === '' || prevPathname === '/home' ||
                pathname === '/home/kitchen' || pathname === '/home/living' || pathname === '/home/utility' ||
                pathname.indexOf('/objetcollection') > -1
            ) {
                revertCameraState(tempTargetPos.current, isFirst);
                tempTargetPos.current = null;
            } else {
                cameraControls.enabled = true;
            }
        }
    }, [pathname, prevPathname]);


    useFrame((state, delta) => {
        cameraControls.update(delta);

        if (!is3D.current) return;
        if (isTweening.current) return;

        //키보드 눌렀을 때
        if (!touchStart && isPressed && cameraControls.enabled) {
            // const [newForwardVec] = updateDirection();
            forceDir.set(h.current, 0, v.current);
            let forceLen = forceDir.length() * speed;
            if (isPressShift) forceLen *= 3;
            const forceNewDir = forceDir.clone().transformDirection(axisRef.current.matrixWorld).normalize().multiplyScalar(forceLen);
            // api.applyForce(forceNewDir.toArray(), [0, 0, 0]);
            api.position.set(pos.current[0] + forceNewDir.x * delta, baseHeight, pos.current[2] + forceNewDir.z * delta);
            // api.position.set(pos.current[0], 2, pos.current[2]);
            cameraControls.moveTo(pos.current[0], pos.current[1], pos.current[2], true);
        }
    });

    // function onHitHandler(e: RayhitEvent) {
    //     // console.log(e);
    // }

    return (
        <>
            {/*<Obstacle size={[2, 4, 2]} position={[3, 2, 2]} color={"red"} onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    if (e.delta < 10){*/}

            {/*    }*/}
            {/*}} type={"Static"} collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>*/}

            <group ref={ref}>
                {/*<mesh castShadow visible={true}>*/}
                {/*    <sphereBufferGeometry args={[0.1, 32, 32]}/>*/}
                {/*    <meshStandardMaterial color={"#fff"} wireframe/>*/}
                {/*</mesh>*/}
                {/*<Raycast*/}
                {/*    from={[0, 3.5, 0]}*/}
                {/*    to={[0, 0, 0]}*/}
                {/*    collisionFilterMask={Layer.TriggerArea}*/}
                {/*    resultCallback={onHitHandler}*/}
                {/*    showRay={true}*/}
                {/*    showInfo={true}*/}
                {/*/>*/}
            </group>
            <Axis ref={axisRef} size={1} visible={false}/>
        </>
    );
});


export default Player;
