import { GA_DataType } from "./index";

//GA검색용 키워드
export type GA_NameType_Main =
  | "main_awards"
  | "main_metaverse"
  | "main_lgthinq"
  | "main_lghome"
  | "main_objetcollection"
  | "main_language_en"
  | "main_language_es"
  | "main_language_kr"
  | "main_exit";

export type GA_EventAction_Main =
  | "exhibition_awards"
  | "exhibition_metaverse"
  | "exhibition_click"
  | "exhibition_language"
  | "exhibition_exit"
  | "exhibition_product";

export const GA_DATA_MAIN: GA_DataType[] = [
  {
    name: "main_awards",
    category: "ha_virtual_studio",
    action: "exhibition_awards",
    label: "best_of_innovation",
  },
  {
    name: "main_metaverse",
    category: "ha_virtual_studio",
    action: "exhibition_metaverse",
    label: "lghome_in_metaverse",
  },
  {
    name: "main_lgthinq",
    category: "ha_virtual_studio",
    action: "exhibition_product",
    label: "lg_thinq",
  },
  {
    name: "main_lghome",
    category: "ha_virtual_studio",
    action: "exhibition_product",
    label: "lg_home",
  },
  {
    name: "main_objetcollection",
    category: "ha_virtual_studio",
    action: "exhibition_product",
    label: "lg_objet_collection",
  },
  {
    name: "main_language_en",
    category: "ha_virtual_studio",
    action: "exhibition_language",
    label: "en",
  },
  {
    name: "main_language_es",
    category: "ha_virtual_studio",
    action: "exhibition_language",
    label: "es",
  },
  {
    name: "main_language_kr",
    category: "ha_virtual_studio",
    action: "exhibition_language",
    label: "kr",
  },
  {
    name: "main_exit",
    category: "ha_virtual_studio",
    action: "exhibition_exit",
    label: "exit",
  },
];
