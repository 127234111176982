import {SpecboardsDataItemType} from "./specboardData";
import {Sup} from "../../components/common/comps_styled/Text.styled";
import React from "react";

/**
 * 에어컨 스펙보드 데이터
 */
const specboardsData_AirConditioner: SpecboardsDataItemType = {
  category: "air_conditioner",
  specboardList: [
    {
      name: "Window Inverter",
      modelNo: "window_inverter",
      specboard_img_url: "/images/products/air_conditioner/window_inverter/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "ARTCOOL",
      modelNo: "artcool",
      specboard_img_url: "/images/products/air_conditioner/artcool/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "DUALCOOL",
      modelNo: "dualcool",
      specboard_img_url: "/images/products/air_conditioner/dualcool/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Inverter Heat Pump Water Heater",
      modelNo: "heat_pump",
      specboard_img_url: "/images/products/air_conditioner/heat_pump/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
  ],
};

export default specboardsData_AirConditioner;
