/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {useLoader} from "@react-three/fiber";
import {TextureLoader} from "three";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";

type GLTFResult = GLTF & {
    nodes: {
        Photo001: THREE.Mesh
        PhotoFrame_Inner: THREE.Mesh
        PhotoFrame_Inner001: THREE.Mesh
        PhotoFrame_Inner002: THREE.Mesh
        Photo: THREE.Mesh
        PhotoFrame: THREE.Mesh
        PhotoFrame001: THREE.Mesh
        PhotoFrame002: THREE.Mesh
        Photo002: THREE.Mesh
        Flower05: THREE.Mesh
        front1: THREE.Mesh
        polySurface1307: THREE.Mesh
        table_lag: THREE.Mesh
        table_lag001: THREE.Mesh
        polySurface153: THREE.Mesh
        polySurface153018: THREE.Mesh
        polySurface153012: THREE.Mesh
        polySurface153014: THREE.Mesh
        polySurface153001: THREE.Mesh
        Circle041: THREE.Mesh
        Rectangle2100660132: THREE.Mesh
        Object1746167694001: THREE.Mesh
        Rectangle2100660994: THREE.Mesh
        Rectangle2100660990: THREE.Mesh
        Rectangle2100660989: THREE.Mesh
        Box_01: THREE.Mesh
        Object1746168650: THREE.Mesh
        Hanger001: THREE.Mesh
        Wall_kitchen_Type_A: THREE.Mesh
        Wall_kitchen_Type_B: THREE.Mesh
        Wall_shelf_Type_B: THREE.Mesh
        Art_wall_back_Type_B: THREE.Mesh
        Wall_kitchen_Type_C: THREE.Mesh
        Art_wall_Type_B: THREE.Mesh
        Rectangle2100661034: THREE.Mesh
        Wall_shelf_Type_A: THREE.Mesh
        Wall_shelf_Type_C: THREE.Mesh
        Rectangle2100661035: THREE.Mesh
        Wall_wide_Type_A: THREE.Mesh
        Wall_wide_Type_B: THREE.Mesh
        Wall_wide_Type_C: THREE.Mesh
        Flower_bed: THREE.Mesh
        Art_wall_Type_A: THREE.Mesh
        Art_wall_back_Type_C: THREE.Mesh
        Circle042: THREE.Mesh
        Rectangle2100659564: THREE.Mesh
        Rectangle2100659565: THREE.Mesh
        Art_wall_Type_C: THREE.Mesh
        Art_wall_back_Type_A: THREE.Mesh
        Soil_01: THREE.Mesh
        Rectangle2100660140: THREE.Mesh
        carpet: THREE.Mesh
        module_01_merged: THREE.Mesh
    }
    materials: {
        picture2: THREE.MeshStandardMaterial
        ['Material #27']: THREE.MeshStandardMaterial
        picture3: THREE.MeshStandardMaterial
        border: THREE.MeshStandardMaterial
        ['Material #295']: THREE.MeshStandardMaterial
        ['Bunana.001']: THREE.MeshStandardMaterial
        ['Monstera.001']: THREE.MeshStandardMaterial
        mate_white_plastic: THREE.MeshStandardMaterial
        mat_black_metal: THREE.MeshStandardMaterial
        ['mat_white_metal.001']: THREE.MeshStandardMaterial
        ['mat_black_plastic.002']: THREE.MeshStandardMaterial
        ['sofa_002.001']: THREE.MeshStandardMaterial
        ['sofa_002.002']: THREE.MeshStandardMaterial
        ['Wall kitchen_Type_A']: THREE.MeshStandardMaterial
        mat_metal: THREE.MeshStandardMaterial
        mat_gray: THREE.MeshStandardMaterial
        ['mat_glass.001']: THREE.MeshStandardMaterial
        ['Box_Set_001.001']: THREE.MeshStandardMaterial
        mat_white: THREE.MeshStandardMaterial
        mat_black_plastic: THREE.MeshStandardMaterial
        ['Wall kitchen_Type_B']: THREE.MeshStandardMaterial
        ['Wall shelf_Type_B']: THREE.MeshStandardMaterial
        ['Art wall back_Type_B']: THREE.MeshStandardMaterial
        ['Wall kitchen_Type_C']: THREE.MeshStandardMaterial
        ['Art wall_Type_B']: THREE.MeshStandardMaterial
        ['Wall shelf_Type_A']: THREE.MeshStandardMaterial
        ['Wall shelf_Type_C']: THREE.MeshStandardMaterial
        ['Wall wide_Type_A']: THREE.MeshStandardMaterial
        ['Wall wide_Type_B']: THREE.MeshStandardMaterial
        ['Wall wide_Type_C.001']: THREE.MeshStandardMaterial
        ['mat_floor_granite.001']: THREE.MeshStandardMaterial
        ['Art wall_Type_A']: THREE.MeshStandardMaterial
        ['Art wall back_Type_C']: THREE.MeshStandardMaterial
        mat_wall2: THREE.MeshStandardMaterial
        ['07 - Dq121']: THREE.MeshStandardMaterial
        ['Art wall_Type_C']: THREE.MeshStandardMaterial
        ['Art wall back_Type_A']: THREE.MeshStandardMaterial
        ['Soil_001.001']: THREE.MeshStandardMaterial
        mat_white_marble: THREE.MeshStandardMaterial
        mat_carpet: THREE.MeshStandardMaterial
        mat_module_01_merged: THREE.MeshStandardMaterial
    }
}

type ModelProps = {
    interiorIdx?: number;
} & JSX.IntrinsicElements['group'];

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_01_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_01_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/map/modern_01_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/map/modern_01_cyclesbake_DIFFUSE.jpg');

const AO_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/map/modern_01_cyclesbake_AO.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/map/modern_01_cyclesbake_AO.jpg');


export default function Model({interiorIdx = 0, ...props}: ModelProps) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);
    const aoTex = useLoader(TextureLoader, AO_MAP_URL);

    const wallWideMat = useMemo(() => {
        if(interiorIdx === 0) return materials['Wall wide_Type_A'];
        else if(interiorIdx === 1) return materials['Wall wide_Type_B'];
        else return materials['Wall wide_Type_C.001'];
    }, [interiorIdx]);

    const wallShelfMat = useMemo(() => {
        if(interiorIdx === 0) return materials['Wall shelf_Type_A'];
        else if(interiorIdx === 1) return materials['Wall shelf_Type_B'];
        else return materials['Wall shelf_Type_C'];
    }, [interiorIdx]);

    const kitchenMat = useMemo(() => {
        if(interiorIdx === 0) return materials['Wall kitchen_Type_A'];
        else if(interiorIdx === 1) return materials['Wall kitchen_Type_B'];
        else return materials['Wall kitchen_Type_C'];
    }, [interiorIdx]);


    useLayoutEffect(() => {

        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        setupScene(scene, lightmapTex);
        // materials.mat_module_01_merged.aoMap = aoTex;
        // materials.mat_module_01_merged.aoMapIntensity = 0.3;
        materials.mat_module_01_merged.lightMapIntensity = 10;
        materials['sofa_002.001'].lightMapIntensity = 10;
        materials['sofa_002.002'].lightMapIntensity = 10;
        materials['sofa_002.001'].aoMapIntensity = 0.2;
        materials['sofa_002.002'].aoMapIntensity = 0.8;
        materials.mate_white_plastic.lightMapIntensity = 10;
        materials.mat_module_01_merged.lightMapIntensity = 10;
        materials.mat_carpet.lightMapIntensity = 15;
        materials.mat_carpet.aoMap = aoTex;
        materials.mat_carpet.aoMapIntensity = 0.3;
        materials.mat_black_metal.roughness = 0.1;

        materials['Wall shelf_Type_A'].lightMapIntensity = 5;
        materials['Wall shelf_Type_B'].lightMapIntensity = 1;
        materials['Wall shelf_Type_C'].lightMapIntensity = 5;

        materials['Wall shelf_Type_B'].metalness = 0.1;
        materials['Wall shelf_Type_B'].roughness = 0.1;
        materials['Wall shelf_Type_B'].aoMap = aoTex;
        materials['Wall shelf_Type_B'].aoMapIntensity = 1;
        materials['Wall shelf_Type_C'].aoMap = aoTex;
        materials['Wall shelf_Type_C'].aoMapIntensity = 0.5;


        materials['Wall kitchen_Type_A'].lightMapIntensity = 10;
        materials['Wall kitchen_Type_B'].lightMapIntensity = 3;
        materials['Wall kitchen_Type_C'].lightMapIntensity = 3;

        materials["Bunana.001"].envMapIntensity = 1.5;
        materials["Bunana.001"].lightMapIntensity = 20;
        materials["Monstera.001"].envMapIntensity = 1.5;
        materials["Monstera.001"].lightMapIntensity = 20;
        materials["07 - Dq121"].lightMapIntensity = 10;
        materials["mat_floor_granite.001"].lightMapIntensity = 10;

    }, [lightmapTex, aoTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            aoTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, AO_MAP_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} dispose={null} position={[0,0.001,0]}>
            <group
                position={[0.1579, 2.0718, -11.8369]}
                rotation={[0, -1.5705, 0]}
                scale={[0.001, 0.0011, 0.001]}>
                <mesh geometry={nodes.Photo001.geometry} material={materials.picture2} />
            </group>
            <group
                position={[-0.8051, 2.0718, -11.8364]}
                rotation={[0, -1.5705, 0]}
                scale={[0.001, 0.0011, 0.001]}>
                <mesh
                    geometry={nodes.PhotoFrame_Inner.geometry}
                    material={nodes.PhotoFrame_Inner.material}
                />
                <mesh
                    geometry={nodes.PhotoFrame_Inner001.geometry}
                    material={nodes.PhotoFrame_Inner001.material}
                />
                <mesh
                    geometry={nodes.PhotoFrame_Inner002.geometry}
                    material={nodes.PhotoFrame_Inner002.material}
                />
                <mesh geometry={nodes.Photo.geometry} material={materials.picture3} />
                <mesh geometry={nodes.PhotoFrame.geometry} material={nodes.PhotoFrame.material} />
                <mesh geometry={nodes.PhotoFrame001.geometry} material={nodes.PhotoFrame001.material} />
                <mesh geometry={nodes.PhotoFrame002.geometry} material={nodes.PhotoFrame002.material} />
            </group>
            <group
                position={[1.1228, 2.0718, -11.8364]}
                rotation={[0, -1.5705, 0]}
                scale={[0.001, 0.0011, 0.001]}>
                <mesh geometry={nodes.Photo002.geometry} material={materials['Material #295']} />
            </group>
            <group
                position={[-33.632, 0, 2.7904]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}>
                <mesh geometry={nodes.Flower05.geometry} material={materials['Bunana.001']} />
                <mesh geometry={nodes.front1.geometry} material={materials['Monstera.001']} />
                <mesh geometry={nodes.polySurface1307.geometry} material={materials.mate_white_plastic} />
                <mesh geometry={nodes.table_lag.geometry} material={nodes.table_lag.material} />
                <mesh geometry={nodes.table_lag001.geometry} material={materials['mat_white_metal.001']} />
                <mesh geometry={nodes.polySurface153.geometry} material={nodes.polySurface153.material} />
                <mesh
                    geometry={nodes.polySurface153018.geometry}
                    material={materials['mat_black_plastic.002']}
                />
                <mesh
                    geometry={nodes.polySurface153012.geometry}
                    material={nodes.polySurface153012.material}
                />
                <mesh
                    geometry={nodes.polySurface153014.geometry}
                    material={nodes.polySurface153014.material}
                />
                <mesh geometry={nodes.polySurface153001.geometry} material={materials['sofa_002.002']} />
            </group>
            <mesh geometry={nodes.Circle041.geometry} material={nodes.Circle041.material} />
            <mesh
                geometry={nodes.Rectangle2100660132.geometry}
                material={kitchenMat}
            />
            <mesh geometry={nodes.Object1746167694001.geometry} material={materials.mat_metal} />
            <mesh geometry={nodes.Rectangle2100660994.geometry} material={materials.mat_gray} />
            <mesh
                geometry={nodes.Rectangle2100660990.geometry}
                material={nodes.Rectangle2100660990.material}
            />
            <mesh geometry={nodes.Rectangle2100660989.geometry} material={materials['mat_glass.001']} />
            <mesh geometry={nodes.Box_01.geometry} material={materials['Box_Set_001.001']} />
            <mesh geometry={nodes.Object1746168650.geometry} material={materials.mat_white} />
            <mesh geometry={nodes.Hanger001.geometry} material={materials.mat_black_plastic} />
            <mesh
                geometry={nodes.Wall_kitchen_Type_A.geometry}
                material={nodes.Wall_kitchen_Type_A.material}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Wall_kitchen_Type_B.geometry}
                material={materials['Wall kitchen_Type_B']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Wall_shelf_Type_B.geometry}
                material={materials['Wall shelf_Type_B']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Art_wall_back_Type_B.geometry}
                material={materials['Art wall back_Type_B']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Wall_kitchen_Type_C.geometry}
                material={materials['Wall kitchen_Type_C']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Art_wall_Type_B.geometry}
                material={materials['Art wall_Type_B']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Rectangle2100661034.geometry}
                material={wallShelfMat}
            />
            <mesh
                geometry={nodes.Wall_shelf_Type_A.geometry}
                material={nodes.Wall_shelf_Type_A.material}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Wall_shelf_Type_C.geometry}
                material={materials['Wall shelf_Type_C']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Rectangle2100661035.geometry}
                material={wallWideMat}
            />
            <mesh
                geometry={nodes.Wall_wide_Type_A.geometry}
                material={nodes.Wall_wide_Type_A.material}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Wall_wide_Type_B.geometry}
                material={materials['Wall wide_Type_B']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Wall_wide_Type_C.geometry}
                material={materials['Wall wide_Type_C.001']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh geometry={nodes.Flower_bed.geometry} material={materials['mat_floor_granite.001']} />
            <mesh
                geometry={nodes.Art_wall_Type_A.geometry}
                material={materials['Art wall_Type_A']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Art_wall_back_Type_C.geometry}
                material={materials['Art wall back_Type_C']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh geometry={nodes.Circle042.geometry} material={materials.mat_wall2} />
            <mesh
                geometry={nodes.Rectangle2100659564.geometry}
                material={nodes.Rectangle2100659564.material}
            />
            <mesh
                geometry={nodes.Rectangle2100659565.geometry}
                material={nodes.Rectangle2100659565.material}
            />
            <mesh
                geometry={nodes.Art_wall_Type_C.geometry}
                material={materials['Art wall_Type_C']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh
                geometry={nodes.Art_wall_back_Type_A.geometry}
                material={materials['Art wall back_Type_A']}
                position={[-15.1784, -10.5993, 0]}
            />
            <mesh geometry={nodes.Soil_01.geometry} material={materials['Soil_001.001']} />
            <mesh geometry={nodes.Rectangle2100660140.geometry} material={materials.mat_white_marble} />
            <mesh geometry={nodes.carpet.geometry} material={materials.mat_carpet} />
            <mesh geometry={nodes.module_01_merged.geometry} material={materials.mat_module_01_merged} />
        </group>
    )
}
