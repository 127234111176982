/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import useProductStore from "../../stores/productStore";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    // nodes: {
    //     drip_tray_bottom: THREE.Mesh
    //     top_cover: THREE.Mesh
    //     front_head: THREE.Mesh
    //     drip_tray_top: THREE.Mesh
    //     body_side: THREE.Mesh
    //     front_head001: THREE.Mesh
    //     top_cover001: THREE.Mesh
    //     body_side001: THREE.Mesh
    // }
    // materials: {
    //     Bottom: THREE.MeshStandardMaterial
    //     Top: THREE.MeshStandardMaterial
    //     Front: THREE.MeshStandardMaterial
    //     tray: THREE.MeshStandardMaterial
    //     body: THREE.MeshStandardMaterial
    //     ['Front.001']: THREE.MeshStandardMaterial
    //     ['Top.001']: THREE.MeshStandardMaterial
    //     ['body.001']: THREE.MeshStandardMaterial
    // }
    nodes: {
        Tray: THREE.Mesh
        drip_tray_bottom: THREE.Mesh
        front_head: THREE.Mesh
        top_cover: THREE.Mesh
        front_head_Decal: THREE.Mesh
        top_cover_Decal: THREE.Mesh
        body_side: THREE.Mesh
    }
    materials: {
        ['Tray.002']: THREE.MeshStandardMaterial
        ['Bottom.002']: THREE.MeshStandardMaterial
        ['Front.002']: THREE.MeshStandardMaterial
        ['Top.002']: THREE.MeshStandardMaterial
        Front_head_Decal: THREE.MeshStandardMaterial
        Top_Cover_Decal: THREE.MeshStandardMaterial
        ['body.002']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/water_purifier/m/atom_4d_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/water_purifier/m/atom_4d_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    // const { nodes, materials, scene } = useGLTF(ROOT_PATH_PREFIX+'/models/products/water_purifier/wat_01/wat_01.gltf') as GLTFResult
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult

    useEffect(() => {
        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
            // useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/models/products/water_purifier/wat_01/wat_01.gltf');
        }
    }, []);

    useEffect(()=>{
        materials["body.002"].metalness = 1;
        materials["body.002"].metalness = 1;
        materials["body.002"].roughness = 0.45;
        materials["Front.002"].metalness = 1;
        materials["Front.002"].roughness = 0.45;
        materials["Bottom.002"].roughness = 0.45;
        materials["Bottom.002"].metalness = 1;
        materials["Top.002"].roughness = 0.5;

        // materials["body.001"].metalness = 1;
        // materials.body.metalness = 1;
        // materials.body.roughness = 0.45;
        // materials.Front.metalness = 1;
        // materials.Front.roughness = 0.45;
        // materials.Bottom.roughness = 0.45;
        // materials.Bottom.metalness = 1;
        // materials.Top.roughness = 0.5;

    }, [materials]);



    // const part1Ref = useRef<THREE.Mesh>(null!);
    // const part2Ref = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(() => {
        if (selectedProduct?.modelNo === 'atom_4d') {
            if (selectedColorIndex === 0) {
                materials["body.002"].color.setHex(0xcccccc);
                materials["Bottom.002"].color.setHex(0xcccccc);
                materials["Front.002"].color.setHex(0xcccccc);
                materials["Tray.002"].color.setHex(0xcccccc);

                materials["Front.002"].metalness = 1;
                materials["body.002"].metalness = 1;
                materials["Front.002"].roughness = 0.5;

                // materials.Front.metalness = 1;
                // materials.body.metalness = 1;
                // materials.Front.color.setHex(0xcccccc);
                // materials.Front.roughness = 0.5;
                // materials.body.color.setHex(0xcccccc);
            } else if (selectedColorIndex === 1) {
                materials["body.002"].color.setHex(0xffffff);
                materials["Bottom.002"].color.setHex(0xffffff);
                materials["Front.002"].color.setHex(0xffffff);
                materials["Tray.002"].color.setHex(0xffffff);

                materials["Front.002"].metalness = 0.1;
                materials["body.002"].metalness = 0.1;
                materials["Front.002"].roughness = 0.1;

                // materials.Front.metalness = 0.1;
                // materials.body.metalness = 0.1;
                // materials.Front.roughness = 0.1;
                // materials.Front.color.setHex(0xffffff);
                // materials.body.color.setHex(0xffffff);
            }else if(selectedColorIndex === 2){
                materials["body.002"].color.setHex(0x101a31);
                materials["Bottom.002"].color.setHex(0x101a31);
                materials["Front.002"].color.setHex(0x101a31);
                materials["Tray.002"].color.setHex(0x101a31);
                // materials["Front.002"].metalness = 0.1;
                // materials["body.002"].metalness = 0.1;
                // materials["Front.002"].roughness = 0.1;
                materials["Front.002"].metalness = 1;
                materials["body.002"].metalness = 1;
                materials["Front.002"].roughness = 0.5;

                // materials.Front.metalness = 0.1;
                // materials.body.metalness = 0.1;
                // materials.Front.roughness = 0.1;
                // materials.Front.color.setHex(0x101a31);
                // materials.body.color.setHex(0x101a31);
            }
        }
    }, [selectedProduct, selectedColorIndex]);







    return (
        <group ref={group} {...props} position={[0, 0, 0.25]} dispose={null}>
            {/*<mesh*/}
            {/*    geometry={nodes.drip_tray_bottom.geometry}*/}
            {/*    material={materials.body}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'drip_tray_bottom' }}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.top_cover.geometry}*/}
            {/*    material={materials.Top}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'top_cover' }}*/}

            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.front_head.geometry}*/}
            {/*    material={materials.Front}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'front_head' }}*/}

            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.drip_tray_top.geometry}*/}
            {/*    material={materials.body}*/}

            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'drip_tray_top' }}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.body_side.geometry}*/}
            {/*    material={materials.body}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'body_side' }}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.front_head001.geometry}*/}
            {/*    material={materials['Front.001']}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'front_head.001' }}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.top_cover001.geometry}*/}
            {/*    material={materials['Top.001']}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'top_cover.001' }}*/}
            {/*/>*/}
            {/*<mesh*/}
            {/*    geometry={nodes.body_side001.geometry}*/}
            {/*    material={materials['body.001']}*/}
            {/*    scale={[0.01, 0.01, 0.01]}*/}
            {/*    userData={{ name: 'body_side.001' }}*/}
            {/*/>*/}

            <mesh
                geometry={nodes.drip_tray_bottom.geometry}
                material={materials['Bottom.002']}
                position={[0, 0.0119, -0.2237]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.Tray.geometry}
                material={materials['Tray.002']}
                position={[0, 0.0115, -0.2235]}
                scale={[0.001, 0.001, 0.001]}
            />
            <mesh
                geometry={nodes.front_head.geometry}
                material={materials['Front.002']}
                position={[0, 0.0119, -0.2237]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.top_cover.geometry}
                material={materials['Top.002']}
                position={[0, 0.0119, -0.2237]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.front_head_Decal.geometry}
                material={materials.Front_head_Decal}
                position={[0, 0.0119, -0.2237]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.top_cover_Decal.geometry}
                material={materials.Top_Cover_Decal}
                position={[0, 0.0119, -0.2237]}
                scale={[0.01, 0.01, 0.01]}
            />
            <mesh
                geometry={nodes.body_side.geometry}
                material={materials['body.002']}
                position={[0, 0.0119, -0.2237]}
                scale={[0.01, 0.01, 0.01]}
            />
        </group>
    )
}

