/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import {useGLTF} from '@react-three/drei'
import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        Object001: THREE.Mesh
        Head_Glass001: THREE.Mesh
        black_Marterial006: THREE.Mesh
        Low_body: THREE.Mesh
        black_Marterial4: THREE.Mesh
        black_Marterial4_Decal: THREE.Mesh
        Front: THREE.Mesh
        body_Decal: THREE.Mesh
        body: THREE.Mesh
        Object002: THREE.Mesh
        silver_Marterial: THREE.Mesh
        Head_Gray_Decal: THREE.Mesh
        Head_Gray: THREE.Mesh
    }
    materials: {
        Glass: THREE.MeshStandardMaterial
        Glass2: THREE.MeshStandardMaterial
        ['Material #25']: THREE.MeshStandardMaterial
        Charger: THREE.MeshStandardMaterial
        Sliver: THREE.MeshStandardMaterial
        Sliver_Decal: THREE.MeshStandardMaterial
        Front: THREE.MeshStandardMaterial
        body_Decal: THREE.MeshStandardMaterial
        body: THREE.MeshStandardMaterial
        ['08 - Default']: THREE.MeshStandardMaterial
        head: THREE.MeshStandardMaterial
        Head_Decal: THREE.MeshStandardMaterial
        Head: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/m/a9t_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/vacuum_cleaner/a9t_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    // const { nodes, materials, scene } = useGLTF(ROOT_PATH_PREFIX+'/models/12.14/a9t/a9t.gltf') as GLTFResult
    const {
        nodes,
        materials,
        scene
    } = useKTXLoader<GLTFResult>(GLTF_URL);


    useEffect(() => {

        materials.Glass2.roughness = 0;
        materials.Glass2.envMapIntensity = 2;
        materials.Glass2.opacity = 0.9;
        materials.head.color.setRGB(1, 1, 1);
        materials.head.envMapIntensity = 2;
        materials.Head_Decal.envMapIntensity = 3;
        materials.body.envMapIntensity = 3;
        materials.Charger.envMapIntensity = 2;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} position={[0, 0, 0.23]} dispose={null}>
            <mesh geometry={nodes.Object001.geometry} material={materials.Glass}/>
            <mesh geometry={nodes.Head_Glass001.geometry} material={materials.Glass2}/>
            <mesh geometry={nodes.black_Marterial4.geometry} material={materials.Sliver}/>
            <mesh geometry={nodes.Low_body.geometry} material={materials.Charger}/>
            <mesh geometry={nodes.Front.geometry} material={materials.Front}/>
            <mesh geometry={nodes.black_Marterial4_Decal.geometry} material={materials.Sliver_Decal}/>
            <mesh geometry={nodes.black_Marterial006.geometry} material={materials['Material #25']}/>
            <mesh geometry={nodes.silver_Marterial.geometry} material={materials.head}/>
            {/*<mesh geometry={nodes.body_Decal.geometry} material={materials.body_Decal}/>*/}
            <mesh geometry={nodes.Object002.geometry} material={materials['08 - Default']}/>
            <mesh geometry={nodes.body.geometry} material={materials.body}/>
            <mesh geometry={nodes.Head_Gray_Decal.geometry} material={materials.Head_Decal}/>
            <mesh geometry={nodes.Head_Gray.geometry} material={materials.Head}/>
        </group>
    )
}

// useGLTF.preload('/models/12.14/a9t/a9t.gltf')
