import { SpecboardsDataItemType } from "./specboardData";
import { Sup } from "../../components/common/comps_styled/Text.styled";
import React from "react";

/**
 * 냉장고 스펙보드 데이터
 */
const specboardsData_Refrigerator: SpecboardsDataItemType = {
  category: "refrigerator",
  specboardList: [
    {
      name: "P-Next3 InstaView ThinQ 3.0",
      modelNo: "ref_01",
      specboard_img_url: "/images/products/refrigerator/p_next3/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>jkhkhhh</>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [<>LG ThinQ app</>, <>works with AI speakers</>],
    },
    {
      name: "A-Next4 InstaView",
      modelNo: "ref_06",
      specboard_img_url: "/images/products/refrigerator/a_next4/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [<>LG ThinQ app</>, <>works with AI speakers</>],
    },
    {
      name: "VS6 InstaView",
      modelNo: "vs6",
      specboard_img_url: "/images/products/refrigerator/vs6/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [<>LG ThinQ app</>, <>works with AI speakers</>],
    },
    {
      name: "VS4 InstaView",
      modelNo: "vs4",
      specboard_img_url: "/images/products/refrigerator/vs4/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [<>LG ThinQ app</>, <>works with AI speakers</>],
    },
    {
      name: "P-Next3 InstaView  w/Voice Recognition",
      modelNo: "p_next3_voice",
      specboard_img_url: "/images/products/refrigerator/p_next3_voice/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [<>LG ThinQ app</>, <>works with AI speakers</>],
    },
  ],
};

export default specboardsData_Refrigerator;
