import {ProductsDataItemType} from "./productsData";
import Heat_pump from "../../products/air_conditioner/Heat_pump";
import Dualcool from "../../products/air_conditioner/Dualcool";
import Window_inverter from "../../products/air_conditioner/Window_inverter";
import Artcool from "../../products/air_conditioner/Artcool";


/**
 * 에어컨 데이터
 */
const productsData_AirConditioner: ProductsDataItemType = {
    category: "air_conditioner",
    ga_id: "rac",
    label: "category_air_conditioner",
    category_img_url: "/images/products/air_conditioner/category_img.png",
    category_thumb_url: "/images/products/air_conditioner/category_thumb.png",
    category_glow_url: "/images/products/air_conditioner/category_glow.png",
    roomType: "living",
    usp_transition: "/movies/usp/transition/main_RAC_000_00000.webm",
    productList: [
        {
            name: "Dualcool w/ Air Purifier",
            modelNo: "dualcool",
            ga_id: "dualcool",
            isCES: false,
            thumb_url: "/images/products/air_conditioner/dualcool/product_thumb.png",
            ar_url: "http://lgevirtual.com/ar/dualcool",
            ar_text: "home_exp_ar_dualcool",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/air_conditioner/dualcool/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Dualcool/>,
        },
        {
            name: "Dualcool Window Inverter",
            modelNo: "window_inverter",
            ga_id: "window_inverter",
            isCES: false,
            thumb_url:
                "/images/products/air_conditioner/window_inverter/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color:
                        "/images/products/air_conditioner/window_inverter/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Window_inverter/>,
        },
        {
            name: "Inverter Heat Pump Water Heater",
            modelNo: "heat_pump",
            ga_id: "inverter_heat_pump_water_heater",
            isCES: false,
            thumb_url: "/images/products/air_conditioner/heat_pump/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_silver",
                    color: "/images/products/air_conditioner/heat_pump/color_silver.png",
                    ga_id: "silver",
                },
            ],
            model: <Heat_pump/>,
        },
        {
            name: "ARTCOOL",
            modelNo: "artcool",
            ga_id: "artcool",
            isCES: true,
            thumb_url: "/images/products/air_conditioner/artcool/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_mirror",
                    color: "/images/products/air_conditioner/artcool/color_mirror.png",
                    ga_id: "mirror",
                },
                {
                    name: <></>,
                    label: "home_exp_color_beige",
                    color: "/images/products/air_conditioner/artcool/color_beige.png",
                    ga_id: "beige",
                },
                {
                    name: <></>,
                    label: "home_exp_color_brown",
                    color: "/images/products/air_conditioner/artcool/color_brown.png",
                    ga_id: "brown",
                },
                {
                    name: <></>,
                    label: "home_exp_color_green",
                    color: "/images/products/air_conditioner/artcool/color_green.png",
                    ga_id: "green",
                },
            ],
            model: <Artcool/>,
        },
    ],
};

export default productsData_AirConditioner;
