import { animated } from "@react-spring/web";
import styled from "styled-components";

export const Text = styled(animated.div)<{
  size?: any;
  weight?: any;
  color?: string;
  align?: string;
  lineHeight?: any;
}>`
  color: ${({ color, theme }) => color || theme.colors.home.darkgray};
  font-size: ${({ size, theme }) => size || theme.font.size.md};
  font-weight: ${({ weight }) => weight || "normal"};
  text-align: ${({ align }) => align || "left"};
  line-height: ${({ lineHeight }) => lineHeight || "normal"};
  /* border: 1px solid black; */
`;

export const Sup = styled(Text)`
  font-size: 0.5em;
  vertical-align: top;
  color: inherit;
  display: inline-block;
  position: relative;
  top: -0.3em;
`;
