/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene, setupScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        living_tiiun_mini_body: THREE.Mesh
        living_tiiun_mini_led: THREE.Mesh
        living_tiiun_mini_flower: THREE.Mesh
    }
    materials: {
        ['mini_base.005']: THREE.MeshStandardMaterial
        ['mini_base_Led.005']: THREE.MeshStandardMaterial
        ['mini_base_flower.005']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/tiiun/living_tiiun_mini_ktx2.glb';
// const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/products/tiiun/tiiun_mini/tex/tiiun_mini_cyclesbake_DIFFUSE.jpg';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult
    // const lightmapTex = useTexture(LIGHT_MAP_URL);

    useLayoutEffect(() => {
        // lightmapTex.flipY = false;
        // lightmapTex.encoding = THREE.sRGBEncoding;
        // lightmapTex.needsUpdate = true;

        // setupScene(scene, lightmapTex);
    }, []);

    useEffect(() => {
        materials['mini_base.005'].envMapIntensity = 4;
        materials['mini_base_flower.005'].envMapIntensity = 2;

        return () => {
            disposeScene(scene);
            // lightmapTex.dispose();
        }
    }, []);
    return (
        <group ref={group} {...props}>
            <group>
                <mesh
                    geometry={nodes.living_tiiun_mini_body.geometry}
                    material={materials['mini_base.005']}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}
                />
                <mesh
                    geometry={nodes.living_tiiun_mini_led.geometry}
                    material={materials['mini_base_Led.005']}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}
                />
                <mesh
                    geometry={nodes.living_tiiun_mini_flower.geometry}
                    material={materials['mini_base_flower.005']}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}
                />
            </group>

            <group position={[0.55,0,0]}>
                <mesh
                    geometry={nodes.living_tiiun_mini_body.geometry}
                    material={materials['mini_base.005']}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}
                />
                <mesh
                    geometry={nodes.living_tiiun_mini_led.geometry}
                    material={materials['mini_base_Led.005']}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}
                />
                <mesh
                    geometry={nodes.living_tiiun_mini_flower.geometry}
                    material={materials['mini_base_flower.005']}
                    rotation={[Math.PI / 2, 0, 0]}
                    scale={[0.01, 0.01, 0.01]}
                />
            </group>
        </group>
    )
}
