import React from 'react';
import * as THREE from "three";
import {Line} from "@react-three/drei";

type AxisProps = {
    size?: number;
    lineWidth?: number;
    depthTest?: boolean;
    xAxisColor?: string | number | THREE.Color | undefined;
    yAxisColor?: string | number | THREE.Color | undefined;
    zAxisColor?: string | number | THREE.Color | undefined;
    xAxisVisible?: boolean;
    yAxisVisible?: boolean;
    zAxisVisible?: boolean;
} & Pick<JSX.IntrinsicElements['group'], 'position' | 'rotation' | 'visible'>;

const Axis = React.forwardRef<THREE.Group, AxisProps>(({
                                                           size = 1,
                                                           lineWidth = 0.5,
                                                           depthTest = false,
                                                           xAxisColor = "red",
                                                           yAxisColor = "green",
                                                           zAxisColor = "blue",
                                                           xAxisVisible = true,
                                                           yAxisVisible = true,
                                                           zAxisVisible = true,
                                                           ...rest
                                                       }, ref) => {
    return (
        <group ref={ref} {...rest}>
            {xAxisVisible && (<Line points={[[0, 0, 0], [size, 0, 0]]} color={xAxisColor} lineWidth={lineWidth} depthTest={depthTest} renderOrder={999} />)}
            {yAxisVisible && (<Line points={[[0, 0, 0], [0, size, 0]]} color={yAxisColor} lineWidth={lineWidth} depthTest={depthTest} renderOrder={999} />)}
            {zAxisVisible && (<Line points={[[0, 0, 0], [0, 0, size]]} color={zAxisColor} lineWidth={lineWidth} depthTest={depthTest} renderOrder={999} />)}
        </group>
    );
});

export default Axis;
