import React, {
    ReactElement,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import {IS_APPLE_DEVICE, IS_MOBILE, Z_INDEX_POPUP} from "../common/const";
import {useModalTutorial, useModalVimeo} from "../../stores/modalStore";
import {Image} from "../common/comps_styled/Image.styled";
import Vimeo from "@u-wave/react-vimeo";
import CloseButton from "../common/navigation/CloseButton";
import useHistoryStore from "../../stores/historyStore";
import {GA_Event} from "../../api/ga";
import useProductStore from "../../stores/productStore";
import useSpaceThemeStore from "../../stores/spaceThemeStore";
import sleep from "../../utils/sleep";

type Props = {} & JSX.IntrinsicElements["div"];

export default function VimeoPlayer({}: Props): ReactElement {
    const bgRatio = 1920 / 1080;
    const pathname = useHistoryStore((state) => state.pathname);
    const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
    const vimeoId = useModalVimeo((state) => state.id);
    const gaId = useModalVimeo((state) => state.ga_id);
    const [sizeRatio, setSizeRatio] = useState(0);

    // for GAEvent
    const selectedProduct = useProductStore((state) => state.selectedProduct);
    const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);
    const ga_model = useProductStore((state) => state.selectedProduct?.ga_id);
    const ga_interior = useSpaceThemeStore((state) => state.lgHomeTheme);
    const ga_isNew = useProductStore((state) =>
        state.selectedProduct?.isCES ? "y" : "n"
    );
    const colorIndex = useProductStore((state) => state.selectedColorIndex);
    const ga_color = useMemo(
        () => selectedProduct?.colors[colorIndex].ga_id,
        [colorIndex, selectedProduct]
    );
    //


    function resizeHandler() {
        const ratio = window.innerWidth / window.innerHeight;
        //가로로 더 길어진 경우, 세로에 맞추고 가로에 여백이 생김
        if (ratio > bgRatio) {
            const sizeH = window.innerHeight;
            const sizeW = window.innerHeight * bgRatio;
            const sr = sizeH / 1080;
            const topMargin = 0;
            const leftMargin = (window.innerWidth - sizeW) * 0.5;
            setSizeRatio(sr);
        }
        //세로로 더 길어진 경우, 가로에 맞추고 위아래에 여백이 생김
        else {
            const sizeW = window.innerWidth;
            const sizeH = window.innerWidth / bgRatio;
            const sr = sizeW / 1920;
            const topMargin = (window.innerHeight - sizeH) * 0.5;
            const leftMargin = 0;
            setSizeRatio(sr);
        }
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", resizeHandler);
        window.addEventListener('orientationchange', resizeHandler);
        window.dispatchEvent(new Event("resize"));
        return () => {
            window.removeEventListener("resize", resizeHandler);
            window.removeEventListener('orientationchange', resizeHandler);
        };
    }, []);

    let needGa = 5;

    const startTime = () => {
        needGa = 5;
    };

    const updateTime = (e) => {
        if (needGa < e.percent * 100)
            needGa = Math.floor((e.percent * 100) / 5) * 5;

        /*
        // LG Home
        if (pathname.indexOf("/home") > -1) {
          if (needGa < e.percent) {
            GA_Event("lg_home", gaId, needGa + "%", {
              dimension5: ga_product,
              dimension6: ga_interior,
              dimension7: ga_model,
              dimension8: ga_isNew,
              dimension9: ga_color,
            });
            needGa += 0.05;
          }
        }
        // LG Thinq
        if (pathname.indexOf("/thinq") > -1) {
          if (needGa < e.percent) {
            GA_Event("lg_thinq", gaId, needGa + "%");
            needGa += 0.05;
          }
        }
    */
    };

    const endTime = () => {
        setModalVimeo(false);
        if (!gaId || gaId === "") return;
        // LG Home
        if (pathname.indexOf("/home") > -1) {
            if (!!ga_product) {
                GA_Event("lg_home", gaId, "99%", {
                    dimension5: ga_product,
                    dimension6: ga_interior,
                    dimension7: ga_model,
                    dimension8: ga_isNew,
                    dimension9: ga_color,
                });
            } else {
                GA_Event("lg_home", gaId, "99%");
            }
        }
        // LG Thinq
        if (pathname.indexOf("/thinq") > -1) {
            GA_Event("lg_thinq", gaId, "99%");
        }
        // LG Objet
        if (pathname.indexOf("/objetcollection") > -1) {
            GA_Event("lg_objet", gaId, "99%");
        }
    };

    //iFrame의 Title 속성 제거
    useEffect(()=>{
        async function check() {
            await sleep(1000);
            const result = document.querySelector('[data-vimeo-initialized="true"]')?.getElementsByTagName("iframe");
            if(result){
                result[0].title="";
            }
        }
        check();
    }, []);

    return (
        <Wrapper id="vimeoplayer">
            {sizeRatio > 0 && (
                <Vimeo
                    video={vimeoId}
                    width={1920 * sizeRatio}
                    height={1080 * sizeRatio}
                    autoplay={true}
                    // muted={IS_APPLE_DEVICE}
                    loop={false}
                    onPlay={(e) => {
                        startTime();
                    }}
                    onEnd={(e) => {
                        endTime();
                    }}
                    onTimeUpdate={(e) => {
                        updateTime(e);
                    }}
                />
            )}

            <Close>
                <CloseButton
                    onClick={(e) => {
                        e.stopPropagation();
                        setModalVimeo(false);
                        if (!gaId || gaId === "") return;

                        if (pathname.indexOf("/home") > -1) {
                            if (!!ga_product) {
                                GA_Event("lg_home", gaId, needGa + "%", {
                                    dimension5: ga_product,
                                    dimension6: ga_interior,
                                    dimension7: ga_model,
                                    dimension8: ga_isNew,
                                    dimension9: ga_color,
                                });
                            } else {
                                GA_Event("lg_home", gaId, needGa + "%");
                            }
                        }
                        // LG Thinq
                        if (pathname.indexOf("/thinq") > -1) {
                            GA_Event("lg_thinq", gaId, needGa + "%");
                        }

                        // LG Objet
                        if (pathname.indexOf("/objetcollection") > -1) {
                            GA_Event("lg_objet", gaId, needGa + "%");
                        }
                    }}
                />
            </Close>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  pointer-events: visible;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Close = styled.div`
  & {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
    pointer-events: none;
  }

  & button {
    position: relative;
    top: 5%;
    right: 4%;
  }
`;
