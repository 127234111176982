/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
// import {KTX2Loader} from "three-stdlib";
import {useLoader, useThree} from "@react-three/fiber";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../utils/useKTXLoader";

type GLTFResult = GLTF & {
  nodes: {
    Mesh028: THREE.Mesh
    Mesh028_1: THREE.Mesh
    REF_01_Glass: THREE.Mesh
    REF_01_Logo: THREE.Mesh
    REF_01_Door_L_Bottom: THREE.Mesh
    REF_01_Door_L_Bottom_Glass: THREE.Mesh
    REF_01_Door_R_Bottom: THREE.Mesh
    REF_01_Door_R_Bottom_Glass: THREE.Mesh
    REF_01_Door_R_Top: THREE.Mesh
    REF_01_Door_R_Top_Glass: THREE.Mesh
    Mesh032: THREE.Mesh
    Mesh032_1: THREE.Mesh
    UI001: THREE.Mesh
    REF_01_Door_L_Top_01: THREE.Mesh
    LOGO: THREE.Mesh
    REF_01_Door_L_Top_01_Glass: THREE.Mesh
    REF_01_Door_L_Top_02: THREE.Mesh
    _REF_01_Door_L_Top_01_Logo: THREE.Mesh
    REF_01_Door_L_Top_02_Glass: THREE.Mesh
  }
  materials: {
    ['REF_01_1001_Albedo.001']: THREE.MeshStandardMaterial
    ['Metal.001']: THREE.MeshStandardMaterial
    ['Glass.002']: THREE.MeshPhysicalMaterial
    ['Glass_Logo.002']: THREE.MeshStandardMaterial
    ['REF_01_1002_Albedo.001']: THREE.MeshStandardMaterial
    ['Glass_Logo.001']: THREE.MeshStandardMaterial
    UI002: THREE.MeshStandardMaterial
    ['REF_01_1002_Albedo.002']: THREE.MeshStandardMaterial
    ['Black_Glass.001']: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/m/p_next3_voice_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/p_next3_voice_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult;

  useEffect(()=>{
    materials["REF_01_1001_Albedo.001"].envMapIntensity = 2;
    materials["REF_01_1002_Albedo.001"].envMapIntensity = 2;
    materials["REF_01_1002_Albedo.002"].envMapIntensity = 2;
    materials["REF_01_1002_Albedo.001"].roughness = 0.6;
    materials["REF_01_1002_Albedo.002"].roughness = 0.6;

    return () => {
      disposeScene(scene);
      useLoader.clear(GLTFLoader, GLTF_URL);
    }
  }, []);

  return (
      <group ref={group} {...props}  dispose={null}>
        <group scale={[0.01, 0.01, 0.01]}>
          <mesh geometry={nodes.Mesh028.geometry} material={materials['REF_01_1001_Albedo.001']} />
          <mesh geometry={nodes.Mesh028_1.geometry} material={materials['Metal.001']} />
          <mesh
              geometry={nodes.REF_01_Glass.geometry}
              material={nodes.REF_01_Glass.material}
              position={[0, 3.0993, -21.4335]}
          />
          <mesh
              geometry={nodes.REF_01_Logo.geometry}
              material={materials['Glass_Logo.002']}
              position={[-0.0004, 162.0583, -55.4809]}
          />
          <mesh
              geometry={nodes.REF_01_Door_L_Bottom.geometry}
              material={nodes.REF_01_Door_L_Bottom.material}
              position={[-43.7618, 43.4914, 23.1761]}>
            <mesh
                geometry={nodes.REF_01_Door_L_Bottom_Glass.geometry}
                material={nodes.REF_01_Door_L_Bottom_Glass.material}
                position={[21.2046, -3.1981, -9.1733]}
                rotation={[-Math.PI / 2, 0, -Math.PI]}
                scale={[-100.0001, -100.0001, -100.0001]}
            />
          </mesh>
          <mesh
              geometry={nodes.REF_01_Door_R_Bottom.geometry}
              material={nodes.REF_01_Door_R_Bottom.material}
              position={[43.5779, 43.4914, 23.1761]}>
            <mesh
                geometry={nodes.REF_01_Door_R_Bottom_Glass.geometry}
                material={nodes.REF_01_Door_R_Bottom_Glass.material}
                position={[-21.1515, -3.1977, -9.1733]}
                scale={[100.0001, 100.0001, 100.0001]}
            />
          </mesh>
          <mesh
              geometry={nodes.REF_01_Door_R_Top.geometry}
              material={nodes.REF_01_Door_R_Top.material}
              position={[-43.7618, 131.9726, 23.1761]}>
            <mesh
                geometry={nodes.REF_01_Door_R_Top_Glass.geometry}
                material={nodes.REF_01_Door_R_Top_Glass.material}
                position={[21.4932, -4.847, -17.8391]}
            />
            <group position={[21.7463, 15.6111, -13.534]} scale={[0.6859, 1.6797, 1.0581]}>
              <mesh geometry={nodes.Mesh032.geometry} material={nodes.Mesh032.material} />
              <mesh geometry={nodes.Mesh032_1.geometry} material={nodes.Mesh032_1.material} />
            </group>
            <mesh
                geometry={nodes.UI001.geometry}
                material={nodes.UI001.material}
                position={[22.3723, -8.2701, 6.4939]}
            />
          </mesh>
          <mesh
              geometry={nodes.REF_01_Door_L_Top_01.geometry}
              material={nodes.REF_01_Door_L_Top_01.material}
              position={[43.761, 131.5259, 22.772]}>
            <mesh
                geometry={nodes.LOGO.geometry}
                material={nodes.LOGO.material}
                position={[-3.4001, 41.4701, 4.5326]}
            />
            <mesh
                geometry={nodes.REF_01_Door_L_Top_01_Glass.geometry}
                material={materials['Black_Glass.001']}
                position={[-20.6104, -1.4767, 4.7756]}
                scale={[76.3592, 78.3776, 76.3592]}
            />
          </mesh>
          <mesh
              geometry={nodes.REF_01_Door_L_Top_02.geometry}
              material={nodes.REF_01_Door_L_Top_02.material}
              position={[43.761, 132.1566, 23.1761]}>
            <mesh
                geometry={nodes._REF_01_Door_L_Top_01_Logo.geometry}
                material={nodes._REF_01_Door_L_Top_01_Logo.material}
                position={[-20.6264, 0.078, -12.3743]}
            />
            <mesh
                geometry={nodes.REF_01_Door_L_Top_02_Glass.geometry}
                material={nodes.REF_01_Door_L_Top_02_Glass.material}
                position={[-20.6264, 0.078, -12.3743]}
            />
          </mesh>
        </group>
      </group>
  )
}

// useGLTF.preload('/models/products/refrigerator/ref_01/ref_01_compressed.glb')
