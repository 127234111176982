import { ProductsDataItemType } from "./productsData";

/**
 * 에어컨 데이터
 */
const productsData_MaskCase: ProductsDataItemType = {
  category: "mask_case",
  label: "category_mask_case",
  category_img_url: "/images/products/mask_case/category_img.png",
  category_thumb_url: "/images/products/mask_case/category_thumb.png",
  category_glow_url: "/images/products/mask_case/category_glow.png",
  roomType: "living",
  usp_transition: "/movies/usp/transition/main_RAC_000_00000.webm",
  productList: [
    {
      name: "ARTCOOL",
      modelNo: "DW_01",
      thumb_url: "/images/products/air_conditioner/p_next3/product_thumb.png",
      ar_url: "https://www.naver.com/",
      openExperience: false,
      colors: [
        {
          name: (
            <>
              Beige
            </>
          ),
          color: "#e9e6e1",
        },
        {
            name: (
              <>
                Mirror
              </>
            ),
            color: "#c6c7c9",
          },
          {
            name: (
              <>
                Brown
              </>
            ),
            color: "#beb0a3",
          },
          {
            name: (
              <>
                Green
              </>
            ),
            color: "#596362",
          },
      ],
      // model: <WapCase />
    },
  ],
};

export default productsData_MaskCase;
