import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_AirConditioner: UspPageDataType[] = [
  {
    category: "air_conditioner",
    modelNo: "window_inverter",
    bgImgUrl: "/images/usp/air_conditioner/window_inverter.jpg",
    uspList: [
      {
        label: "window_inverter_usp_0",
        title: [<>So quiet! Is AC On?</>],
        subMsg: [<>Lo-Decibel</>],
        videoUrl: "https://vimeo.com/654777820/a37d63d2ed",
        x: 121,
        y: 352,
        ga_id: "lo_decibel",
      },
      {
        isRight: true,
        label: "window_inverter_usp_0",
        title: [<>Here is how we saving energy.</>],
        subMsg: [<>High Energy Efficiency</>],
        videoUrl: "https://vimeo.com/654777781/04dc73ce0f",
        x: 1290,
        y: 516,
        ga_id: "high_energy_efficiency",
      },
    ],
  },
  {
    category: "air_conditioner",
    modelNo: "dualcool",
    bgImgUrl: "/images/usp/air_conditioner/dualcool.jpg",
    uspList: [
      {
        label: "dualcool_usp_0",
        title: [
          <>
            Sure, managing air quality is
            <br />
            no problem.
          </>,
        ],
        subMsg: [<>PM 1.0 Sensor + Micro Dust Filter</>],
        videoUrl: "https://vimeo.com/654777738/881579caaf",
        x: 63,
        y: 384,
        ga_id: "pm_1.0_sensor+micro_dust_filter",
      },
      {
        isRight: true,
        label: "dualcool_usp_0",
        title: [<>How clean is your AC?</>],
        subMsg: [<>UVnano</>],
        videoUrl: "https://vimeo.com/654777763/44f97d5ae4",
        x: 1339,
        y: 518,
        ga_id: "uvnano",
      },
    ],
  },
  {
    category: "air_conditioner",
    modelNo: "heat_pump",
    bgImgUrl: "/images/usp/air_conditioner/heat_pump.jpg",
    uspList: [
      {
        label: "heat_pump_usp_0",
        title: [
          <>
            Is it possible to use less energy
            <br />
            but have a strong power?
          </>,
        ],
        subMsg: [<>Inverter Heatpump Water Heater</>],
        videoUrl: "https://vimeo.com/654777853/945bcef78c",
        x: 263,
        y: 451,
        ga_id: "inverter_heat_pump_water_heater",
      },
    ],
  },
  {
    category: "air_conditioner",
    modelNo: "artcool",
    bgImgUrl: "/images/usp/air_conditioner/artcool.jpg",
    uspList: [
      {
        label: "artcool_usp_0",
        title: [
          <>
            Complete your interior with
            <br />
            your favorite color AC
          </>,
        ],
        subMsg: [<>Colorful Design</>],
        videoUrl: "https://vimeo.com/658886255/3b99a586f1",
        x: 63,
        y: 366,
        ga_id: "colorful_design",
      },
      {
        label: "artcool_usp_0",
        title: [
          <>
            Do you know the AC that care
            <br />
            and cleans the air you breath!
          </>,
        ],
        subMsg: [<>Ionizer</>],
        videoUrl: "https://vimeo.com/658886284/8567836cbd",
        x: 63,
        y: 516,
        ga_id: "ionizer",
      },
      {
        isRight: true,
        label: "artcool_usp_0",
        title: [<>How clean is your AC?</>],
        subMsg: [<>UVnano</>],
        videoUrl: "https://vimeo.com/658886319/60668fea6d",
        x: 1339,
        y: 486,
        ga_id: "uvnano",
      },
    ],
  },
];

export default uspData_AirConditioner;
