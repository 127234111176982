import create from "zustand";
import qs from "qs";


/**
 * 페이지 이동 상태관리
 */

export type HistoryState = {
    pathname: string;
    prevPathname: string;
    pushHistory: (path: string) => void;
    goBack: () => void;
    isTweening: boolean;
    setIsTweening: (value: boolean) => void;
    isPressBack? : boolean;
};

const useHistoryStore = create<HistoryState>((set, get) => ({
    pathname: '',
    prevPathname: '',
    isTweening: false,
    setIsTweening: value => set({isTweening: value}),
    pushHistory: path => {
        const temp = get().pathname;
        if (temp !== path) {
            set({pathname: path, prevPathname: temp, isPressBack: false});
            // console.log("path:", path, "prev:", temp);
        }
    },
    goBack: () => {
        const query = qs.parse(window.location.search, {
            ignoreQueryPrefix: true
        });
        const {pathname} = query;
        const comp = pathname.split('/');
        comp.pop();
        let newPathname = comp.join('/');
        // if(newPathname === '/home/kitchen' || newPathname === '/home/living' || newPathname === '/home/utility'){
        //    comp.pop();
        //    newPathname = comp.join('/');
        // }
        if(newPathname === '/objetcollection')
        {
            comp.pop();
            newPathname = comp.join('/');
        }
        get().pushHistory(newPathname);
        set({isPressBack: true});
    }
    // goBack: () => {
    //     // 홈 URL이 아닌 서브 URL로 처음 진입 시에도 뒤로가기가 정상적으로 동작하게 하기 위해
    //     // window.location 객체의 값을 분석하여 뒤로가기 구현
    //     const originLen = window.location.origin.length;
    //     const currentPathname = window.location.href.substring(originLen + 3);
    //     const comp = currentPathname.split('/');
    //     comp.pop();
    //
    //     let newPathname = comp.join('/');
    //     if(newPathname === '/home/kitchen' || newPathname === '/home/living' || newPathname === '/home/utility'){
    //        comp.pop();
    //        newPathname = comp.join('/');
    //     }
    //     if(newPathname === '/objetcollection')
    //     {
    //         comp.pop();
    //         newPathname = comp.join('/');
    //     }
    //     get().pushHistory(newPathname);
    // }
}));

export default useHistoryStore;
