import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import useHistoryStore from "../../stores/historyStore";
import {useModalMainIntro} from "../../stores/modalStore";
import {ROOT_PATH_PREFIX, Z_INDEX_POPUP} from "../common/const";
import {GA_PageView} from "../../api/ga";
import useLangStore from "../../stores/langStore";
import qs from "qs";
import {useLocation} from "react-router-dom";


interface Props {
}

const MainIntro = (props: Props) => {
    const location = useLocation();

    const setModalMainIntro = useModalMainIntro((state) => state.setIsOpen);
    const modalMainIntro = useModalMainIntro((state) => state.isOpen);
    const wrapRef = useRef<HTMLDivElement>(null);
    const delayTimer = useRef<NodeJS.Timeout>();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    const [utm_source, setUtmSource] = useState("");
    const [utm_medium, setUtmMedium] = useState("");
    const [utm_content, setUtmContent] = useState("");
    const [utm_campaign, setUtmCampaign] = useState("");

    useLayoutEffect(()=>{
        if (query.utm_source) setUtmSource(query.utm_source);
        if (query.utm_medium) setUtmMedium(query.utm_medium);
        if (query.utm_content) setUtmContent(query.utm_content);
        if (query.utm_campaign) setUtmCampaign(query.utm_campaign);
    }, [query]);

    useEffect(() => {
        return () => {
            delayTimer.current && clearTimeout(delayTimer.current);
            setModalMainIntro(false);
        };
    }, []);

    if (!modalMainIntro) return <></>;

    return (
        <Wrapper ref={wrapRef}>
            <video
                autoPlay
                muted
                playsInline
                style={{objectFit: "cover"}}
                width="100%"
                height="100%"
                onLoadStart={(e) => {
                    // console.log(">>>>>>>>>>>인트로 시작!", query.lang)
                    let result = ROOT_PATH_PREFIX + `/index.html?pathname=&lang=${query.lang}`;
                    if (utm_source) result += "&utm_source=" + utm_source;
                    if (utm_medium) result += "&utm_medium=" + utm_medium;
                    if (utm_content) result += "&utm_content=" + utm_content;
                    if (utm_campaign) result += "&utm_campaign=" + utm_campaign;

                    GA_PageView(
                        "/intro",
                        result,
                        {
                            dimension3: query.lang as string
                        }
                    );

                    delayTimer.current = setTimeout(() => {
                        wrapRef.current?.style?.setProperty(
                            "background",
                            "rgba(0, 0, 0, 0)"
                        );
                        wrapRef.current?.style?.setProperty("display", "block");
                    }, 100);
                }}
                onEnded={(e) => {
                    // console.log(">>>>>>>>>>>인트로 종료!", query.lang)
                    let result = ROOT_PATH_PREFIX + `/index.html?pathname=&lang=${query.lang}`;
                    if (utm_source) result += "&utm_source=" + utm_source;
                    if (utm_medium) result += "&utm_medium=" + utm_medium;
                    if (utm_content) result += "&utm_content=" + utm_content;
                    if (utm_campaign) result += "&utm_campaign=" + utm_campaign;

                    GA_PageView(
                        "/main",
                        result,
                        {
                            dimension3: query.lang as string
                        }
                    );
                    setModalMainIntro(false);
                }}
            >
                <source
                    src={ROOT_PATH_PREFIX + "/movies/main/Intro_Short_0000_alpha.webm"}
                    type="video/webm"
                />
            </video>
        </Wrapper>
    );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  pointer-events: visible;
  display: none;
`;

export default MainIntro;
