//-------------------------------------------- Mist
import {ProductColorType} from "../productsData";

//Freenze & Freezer
export const Freeze_Mist_Black: ProductColorType = {
    name: <>Black</>,
    label: 'objet_color_black',
    color: '/images/objet/mist/freeze_n_freezer/black.png',
    ga_id: "black"
};
export const Freeze_Mist_Clay_Brown: ProductColorType = {
    name: <>Clay Brown</>,
    label: 'objet_color_clay_brown',
    color: '/images/objet/mist/freeze_n_freezer/clay_brown.png',
    ga_id: "clay_brown"
};
export const Freeze_Mist_Gray: ProductColorType = {
    name: <>Gray</>,
    label: 'objet_color_gray',
    color: '/images/objet/mist/freeze_n_freezer/gray.png',
    ga_id: "gray"
};
export const Freeze_Mist_Redwood: ProductColorType = {
    name: <>RedWood</>,
    label: 'objet_color_redwood',
    color: '/images/objet/mist/freeze_n_freezer/redwood.png',
    ga_id: "redwood"
};
export const Freeze_Mist_Silver: ProductColorType = {
    name: <>Silver</>,
    label: 'objet_color_silver',
    color: '/images/objet/mist/freeze_n_freezer/silver.png',
    ga_id: "silver"
};
export const Freeze_Mist_White: ProductColorType = {
    name: <>White</>,
    label: 'objet_color_white',
    color: '/images/objet/mist/freeze_n_freezer/white.png',
    ga_id: "white"
};

//Microwave Oven
export const MicrowaveOven_Mist_Beige: ProductColorType = {
    name: <>Beige</>,
    label: 'objet_color_beige',
    color: '/images/objet/mist/microwave_oven/beige.png',
    ga_id: "beige"
};

//Refrigerator
export const Refrigerator_Mist_Beige: ProductColorType = {
    name: <>Gray</>,
    label: 'objet_color_beige',
    color: '/images/objet/mist/refrigerator/beige.png',
    ga_id: "beige"
};
export const Refrigerator_Mist_Clay_Brown: ProductColorType = {
    name: <>Clay Brown</>,
    label: 'objet_color_clay_brown',
    color: '/images/objet/mist/refrigerator/clay_brown.png',
    ga_id: "clay_brown"
};
export const Refrigerator_Mist_Mint: ProductColorType = {
    name: <>Mint</>,
    label: 'objet_color_mint',
    color: '/images/objet/mist/refrigerator/mint.png',
    ga_id: "mint"
};
export const Refrigerator_Mist_Pink: ProductColorType = {
    name: <>Pink</>,
    label: 'objet_color_pink',
    color: '/images/objet/mist/refrigerator/pink.png',
    ga_id: "pink"
};
export const Refrigerator_Mist_Redwood: ProductColorType = {
    name: <>Redwood</>,
    label: 'objet_color_redwood',
    color: '/images/objet/mist/refrigerator/redwood.png',
    ga_id: "redwood"
};
export const Refrigerator_Mist_Silver: ProductColorType = {
    name: <>Silver</>,
    label: 'objet_color_silver',
    color: '/images/objet/mist/refrigerator/silver.png',
    ga_id: "silver"
};

//Styler
export const Styler_Mist_Beige: ProductColorType = {
    name: <>Beige</>,
    label: 'objet_color_beige',
    color: '/images/objet/mist/styler/beige.png',
    ga_id: "beige"
};
export const Styler_Mist_Clay_Brown: ProductColorType = {
    name: <>Clay Brown</>,
    label: 'objet_color_clay_brown',
    color: '/images/objet/mist/styler/clay_brown.png',
    ga_id: "clay_brown"
};
export const Styler_Mist_Green: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_green',
    color: '/images/objet/mist/styler/green.png',
    ga_id: "green"
};
export const Styler_Mist_Redwood: ProductColorType = {
    name: <>RedWood</>,
    label: 'objet_color_redwood',
    color: '/images/objet/mist/styler/redwood.png',
    ga_id: "redwood"
};