import React, { useState } from "react";
import styled from "styled-components";
import useResizeStore from "../../../stores/resizeStore";
import { Image } from "../comps_styled/Image.styled";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../const";

type Props = {
  clicked?: boolean;
  defaultSrc: string;
  hoverSrc: string;
  pressSrc: string;
  clickDefaultSrc: string;
  clickHoverSrc: string;
  clickPressSrc: string;
} & JSX.IntrinsicElements["button"];

const HoverAudioButton = ({
  clicked = false,
  defaultSrc,
  hoverSrc,
  pressSrc,
  clickDefaultSrc,
  clickHoverSrc,
  clickPressSrc,
  ...rest
}: Props) => {
  const [hover, setHover] = useState(false);
  const resize = useResizeStore((state) => state.resize);

  return (
    <Wrapper
      ratio={resize.ratio}
      {...rest}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {!clicked ? (
        // <Image src={pressSrc ?? hoverSrc} />
        <Image
          alt="audio off"
          role="button"
          src={hover ? clickHoverSrc : clickDefaultSrc}
        />
      ) : (
        <Image
          alt="audio on"
          role="button"
          src={hover ? hoverSrc : defaultSrc}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.button<{ ratio: number } & Record<string, any>>`
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 19 : 10.7)}em;
  padding: 0;
  background-color: transparent;
  pointer-events: visible;
  cursor: pointer;
`;

export default HoverAudioButton;
