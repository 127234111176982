/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "../../components/objetcollection/room/natural/Product_Harvester";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {TextureLoader} from "three";
import useProductStore from "../../stores/productStore";

type GLTFResult = GLTF & {
    nodes: {
        xd5_back: THREE.Mesh
        xd5_body: THREE.Mesh
        black: THREE.Mesh
        printproof_stainless_steel: THREE.Mesh
        black_sts: THREE.Mesh
        white: THREE.Mesh
        xd5_decal: THREE.Mesh
    }
    materials: {
        ['mat_black.003']: THREE.MeshStandardMaterial
        ['DW_04_Albedo_Decal.001']: THREE.MeshStandardMaterial

        black: THREE.MeshStandardMaterial
        printproof_stainless_steel: THREE.MeshStandardMaterial
        black_sts: THREE.MeshStandardMaterial
        white: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/m/xd5_ktx2_2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/xd5_ktx2_2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd5_ktx2_2.glb';
const AO_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd3_steam_AO.jpg';

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const aoTex = useTexture(AO_URL);

    const doorRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'xd5'){
            if(selectedColorIndex === 0){
                doorRef.current.material = materials.printproof_stainless_steel;
            }
            else if(selectedColorIndex === 1){
                doorRef.current.material = materials.black_sts;
            }
            else if(selectedColorIndex === 2){
                doorRef.current.material = materials.black;
            }
            else{
                doorRef.current.material = materials.white;
            }
        }
    }, [selectedProduct, selectedColorIndex]);


    useLayoutEffect(() => {
        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        materials.printproof_stainless_steel.aoMap = aoTex;
        materials.black_sts.aoMap = aoTex;
        materials.black.aoMap = aoTex;
        materials.white.aoMap = aoTex;

        materials.printproof_stainless_steel.aoMapIntensity = 2;
        materials.black_sts.aoMapIntensity = 2;
        materials.black.aoMapIntensity = 2;
        materials.white.aoMapIntensity = 2;

        materials.black_sts.map = null;
        materials.black_sts.color.setRGB(0.15,0.15,0.15);

        materials.black.map = null;
        materials.black.color.setRGB(0.05,0.05,0.05);

    }, [aoTex]);

    useEffect(() => {
        doorRef.current.material = materials.printproof_stainless_steel;

        return () => {
            disposeScene(scene);
            aoTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, AO_URL);
        }
    }, []);

  return (
      <group ref={group} {...props} dispose={null}
             position={[-0.002,0,0.3]}
             scale={[1.01,1,1]}
             // position={[1.787, 0.005, 3.1]}
      >
          <mesh
              geometry={nodes.xd5_back.geometry}
              material={materials['mat_black.003']}
              position={[0.0009, 0.3843, -0.2302]}
              scale={[0.967, 0.967, 0.967]}
              userData={{ name: 'xd5_back' }}
          />
          <mesh
              geometry={nodes.xd5_body.geometry}
              material={nodes.xd5_body.material}
              position={[0.0002, 0.6047, -0.0112]}
              scale={[0.967, 0.967, 0.967]}
              userData={{ name: 'xd5_body' }}
              ref={doorRef}
          />
          <mesh
              geometry={nodes.black.geometry}
              material={nodes.black.material}
              position={[0.2125, 4.2842, 0]}
              scale={[0.0141, 0.0141, 0.0141]}
              userData={{ name: 'black' }}
          />
          <mesh
              geometry={nodes.printproof_stainless_steel.geometry}
              material={materials.printproof_stainless_steel}
              position={[0.1228, 4.2842, 0]}
              scale={[0.0141, 0.0141, 0.0141]}
              userData={{ name: 'printproof_stainless_steel' }}
          />
          <mesh
              geometry={nodes.black_sts.geometry}
              material={materials.black_sts}
              position={[0.1712, 4.2842, 0]}
              scale={[0.0141, 0.0141, 0.0141]}
              userData={{ name: 'black_sts' }}
          />
          <mesh
              geometry={nodes.white.geometry}
              material={materials.white}
              position={[0.2663, 4.2842, 0]}
              scale={[0.0141, 0.0141, 0.0141]}
              userData={{ name: 'white' }}
          />
          <mesh
              geometry={nodes.xd5_decal.geometry}
              material={materials['DW_04_Albedo_Decal.001']}
              position={[0.1321, 0.7071, 0.001]}
              scale={[0.967, 0.967, 0.967]}
              userData={{ name: 'xd5_decal' }}
          />
      </group>
  )
}
