import { ProductsDataItemType } from "./productsData";
import Homebrew from "../../products/homebrew/Homebrew";


/**
 * 홈브루 데이터
 */
const productsData_HomeBrew: ProductsDataItemType = {
  category: "homebrew",
  ga_id: "homebrew",
  label: "category_homebrew",
  category_img_url: "/images/products/homebrew/category_img.png",
  category_thumb_url: "/images/products/homebrew/category_thumb.png",
  category_glow_url: "/images/products/homebrew/category_glow.png",
  roomType: "kitchen",
  usp_transition: "/movies/usp/transition/main_HB_000_00000.webm",
  productList: [
    {
      name: "HomeBrew",
      modelNo: "homebrew",
      ga_id: "homebrew",
      isCES: false,
      thumb_url: "/images/products/homebrew/homebrew/product_thumb.png",
      ar_url: "http://lgevirtual.com/ar/homebrew",
      ar_text: "home_exp_ar_homebrew",
      openExperience: false,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_steel",
          color: "/images/products/homebrew/homebrew/color_steel.png",
          ga_id: "stainless_steel",
        },
      ],
      model: <Homebrew />,
    },
  ],
};

export default productsData_HomeBrew;
