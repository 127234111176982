import React, { useLayoutEffect } from "react";
import { Text } from "@react-three/drei";
import ObjetProductContainer3D from "../../common/ObjetProductContainer3D";
import {
  refrigerator_tl_data,
  refrigerator_br_data,
  refrigerator_bl_data,
  tiiun_data,
} from "../../../api/products/objetProductsData";
import useCameraControls from "../../../utils/useCameraControls";
import ObjetRoomTexture from "./ObjetRoomTexture";
import {
  blossom_floor_data,
  blossom_wall_data,
} from "../../../api/objet/objetMaterialsData";

const BlossomRoom = React.memo(() => {
  const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();

  useLayoutEffect(() => {
    // cameraControls.setLookAt(0, 1.3, 0, 0, 1.3, -5);
  }, []);

  return (
    <group>
      {/*<Text fontSize={0.5} position={[0, 1, 0]}>Blossom</Text>*/}

      <React.Suspense fallback={null}></React.Suspense>

      <ObjetRoomTexture position={[-1, 1.5, 0]} data={blossom_wall_data} />
      <ObjetRoomTexture position={[0, 1, 0]} data={blossom_floor_data} />

      <ObjetProductContainer3D
        args={[1, 2, 0.2]}
        position={[1, 3, -3]}
        data={refrigerator_tl_data}
      />

      <ObjetProductContainer3D
        args={[1, 2, 0.2]}
        position={[1, 1, -3]}
        data={refrigerator_bl_data}
      />

      <ObjetProductContainer3D
        args={[1, 2, 0.2]}
        position={[2, 1, -3]}
        data={refrigerator_br_data}
      />

      <ObjetProductContainer3D
        args={[0.5, 1, 0.5]}
        position={[0, 0.5, 1]}
        data={tiiun_data}
      />
    </group>
  );
});

export default BlossomRoom;
