import {ProductsDataItemType} from "./productsData";
import Aerotower from "../../products/air_care/Aerotower";
import Puricare_pet from "../../products/air_care/Puricare_pet";
import Puricare_stage1 from "../../products/air_care/Puricare_stage1";
import Puricare_mini_2nd from "../../products/air_care/Puricare_mini_2nd";
import Puricare_mini from "../../products/air_care/Puricare_mini";


/**
 * 공기청정기 데이터
 */
const productsData_AirCare: ProductsDataItemType = {
    category: "air_care",
    ga_id: "air_care",
    label: "category_air_care",
    category_img_url: "/images/products/air_care/category_img.png",
    category_thumb_url: "/images/products/air_care/category_thumb.png",
    category_glow_url: "/images/products/air_care/category_glow.png",
    roomType: "living",
    usp_transition: "/movies/usp/transition/main_AC_000_00000.webm",
    productList: [
        {
            name: "Aero Tower", //63
            modelNo: "aerotower",
            ga_id: "aero_tower",
            isCES: true,
            thumb_url: "/images/products/air_care/aerotower/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_beige",
                    color: "/images/products/air_care/aerotower/color_beige.png",
                    ga_id: "beige",
                },
                {
                    name: <></>,
                    label: "home_exp_color_silver",
                    color: "/images/products/air_care/aerotower/color_silver.png",
                    ga_id: "silver",
                },
            ],
            model: <Aerotower/>,
        },
        {
            name: "PuriCare 360 Air Purifier Pet", //69
            modelNo: "puricare_pet",
            ga_id: "puricare_tower",
            isCES: false,
            thumb_url: "/images/products/air_care/puricare_pet/product_thumb.png",
            ar_url: "http://lgevirtual.com/ar/puricareairpurifier",
            ar_text: "home_exp_ar_puricare_pet",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_silver",
                    color: "/images/products/air_care/puricare_pet/color_silver.png",
                    ga_id: "silver",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/air_care/puricare_pet/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Puricare_pet/>,
        },
        {
            name: "Puricare 360 1-stage", //70
            modelNo: "puricare_stage1",
            ga_id: "puricare_stand",
            isCES: false,
            thumb_url: "/images/products/air_care/puricare_stage1/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_silver",
                    color: "/images/products/air_care/puricare_stage1/color_silver.png",
                    ga_id: "silver",
                },
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/air_care/puricare_stage1/color_white.png",
                    ga_id: "white",
                },
            ],
            model: <Puricare_stage1/>,
        },
        {
            name: "PuriCare Mini 2nd", //71
            modelNo: "puricare_mini_2nd",
            ga_id: "puricare_mini_2nd",
            isCES: true,
            thumb_url:
                "/images/products/air_care/puricare_mini_2nd/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_gold",
                    color: "/images/products/air_care/puricare_mini_2nd/color_gold.png",
                    ga_id: "gold",
                },
                {
                    name: <></>,
                    label: "home_exp_color_mint",
                    color: "/images/products/air_care/puricare_mini_2nd/color_mint.png",
                    ga_id: "mint",
                },
                {
                    name: <></>,
                    label: "home_exp_color_mango",
                    color: "/images/products/air_care/puricare_mini_2nd/color_mango.png",
                    ga_id: "mango",
                },
                {
                    name: <></>,
                    label: "home_exp_color_lavender",
                    color:
                        "/images/products/air_care/puricare_mini_2nd/color_lavender.png",
                    ga_id: "lavender",
                },
            ],
            model: <Puricare_mini_2nd/>,
        },
        {
            name: "PuriCare Mini", //72
            modelNo: "puricare_mini",
            ga_id: "puricare_mini",
            isCES: false,
            thumb_url: "/images/products/air_care/puricare_mini/product_thumb.png",
            openExperience: false,
            colors: [
                {
                    name: <></>,
                    label: "home_exp_color_white",
                    color: "/images/products/air_care/puricare_mini/color_white.png",
                    ga_id: "white",
                },
                {
                    name: <></>,
                    label: "home_exp_color_black",
                    color: "/images/products/air_care/puricare_mini/color_black.png",
                    ga_id: "black",
                },
                {
                    name: <></>,
                    label: "home_exp_color_rose_gold",
                    color: "/images/products/air_care/puricare_mini/color_rose_gold.png",
                    ga_id: "rose_gold",
                },
                {
                    name: <></>,
                    label: "home_exp_color_dark",
                    color: "/images/products/air_care/puricare_mini/color_dark.png",
                    ga_id: "dark_blue",
                },
            ],
            model: <Puricare_mini/>,
        },
    ],
};

export default productsData_AirCare;
