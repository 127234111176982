/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from '../../../common/const'
import {useLoader, useThree} from "@react-three/fiber";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import useHistoryStore from "../../../../stores/historyStore";
import {GLTFResult_NaturalRoom} from "./NaturalRoomFloor";

type GLTFResult = GLTF & {
    nodes: {
        d_kitchen_module_02: THREE.Mesh
        Plane016: THREE.Mesh
        Plane017: THREE.Mesh
        Plane015: THREE.Mesh
        d_kitchen_module_01: THREE.Mesh
        d_sofa_module_03: THREE.Mesh
    }
    materials: {
        d_mat_white_marble: THREE.MeshStandardMaterial
        kitchen_module_01_B: THREE.MeshStandardMaterial
        kitchen_module_01_C: THREE.MeshStandardMaterial
        kitchen_module_01_A: THREE.MeshStandardMaterial
        d_mat_sofa_module_03: THREE.MeshStandardMaterial
    }
}

type ModelProps = {
    interiorIdx?: number;
} & JSX.IntrinsicElements['group'];

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_kitchen.glb';

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_kitchen_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_kitchen_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Kitchen_Bake1_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Kitchen_Bake1_cyclesbake_DIFFUSE.jpg');

export default function Model({interiorIdx = 0, ...props}: ModelProps) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const pathname = useHistoryStore(state => state.pathname);
    const isActiveLnF = useMemo(() => pathname.indexOf('lansen_freezer') > -1, [pathname]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

        // materials.d_mat_white_marble.lightMapIntensity = 3;
        // materials.d_mat_kitchen_module_01.lightMapIntensity = 3;
        // materials.d_mat_sofa_module_03.lightMapIntensity = 5;
        materials.kitchen_module_01_A.lightMapIntensity = 3;
        materials.kitchen_module_01_B.lightMapIntensity = 3;
        materials.kitchen_module_01_C.lightMapIntensity = 3;
        materials.d_mat_white_marble.lightMapIntensity = 3;
        materials.d_mat_sofa_module_03.lightMapIntensity = 5;

    }, [lightmapTex]);

    useEffect(() => {
        // materials.d_mat_white_marble.lightMapIntensity = 3;
        // materials.d_mat_kitchen_module_01.lightMapIntensity = 3;
        // materials.d_mat_sofa_module_03.lightMapIntensity = 5;

        return () => {
            disposeScene(scene);
            lightmapTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
        }
    }, []);


    const kitchenMat = useMemo(()=>{
        if(interiorIdx === 0) return materials.kitchen_module_01_A;
        else if(interiorIdx === 1) return materials.kitchen_module_01_B;
        else return materials.kitchen_module_01_C;
    }, [interiorIdx]);





    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.d_kitchen_module_02.geometry}
                material={materials.d_mat_white_marble}
                userData={{ name: 'd_kitchen_module_02' }}
            />
            <mesh
                geometry={nodes.Plane016.geometry}
                material={materials.kitchen_module_01_B}
                position={[-1.4911, -8.7426, 0.3951]}
                userData={{ name: 'Plane.016' }}
            />
            <mesh
                geometry={nodes.Plane017.geometry}
                material={materials.kitchen_module_01_C}
                position={[-1.4911, -8.7426, 1.6449]}
                userData={{ name: 'Plane.017' }}
            />
            <mesh
                geometry={nodes.Plane015.geometry}
                material={nodes.Plane015.material}
                position={[-1.4911, -8.7426, -0.8209]}
                userData={{ name: 'Plane.015' }}
            />
            <mesh
                geometry={nodes.d_kitchen_module_01.geometry}
                material={kitchenMat}
                position={[2.3973, 2.5426, -2.9074]}
                userData={{ name: 'd_kitchen_module_01' }}
                visible={!isActiveLnF}
            />
            <mesh
                geometry={nodes.d_sofa_module_03.geometry}
                material={materials.d_mat_sofa_module_03}
                position={[-3.7124, 0.4, 3.7865]}
                userData={{ name: 'd_sofa_module_03' }}
            />
        </group>
    )
}
