import styled from "styled-components";
import { IS_MOBILE } from "../../../common/const";

export const InteriorButton = styled.button<{ selected?: boolean }>`
  flex-direction: column;
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: visible;
  background-color: transparent;
  padding: 0;
  margin: 0 2%;
  /* border: 1px solid red; */

  .box {
    border-color: ${({ selected, theme }) =>
      selected && theme.colors.home.pink};
  }

  .text {
    color: ${({ selected, theme }) =>
      !IS_MOBILE && selected && theme.colors.home.pink};
  }

  &:hover {
    .box {
      border-color: ${({ theme }) => theme.colors.home.pink};
    }

    .text {
      color: ${({ theme }) => !IS_MOBILE && theme.colors.home.pink};
    }
  }

  &:focus-visible {
    .box {
      border-color: ${({ theme }) => theme.colors.home.pink};
    }
    .text {
      color: ${({ theme }) => !IS_MOBILE && theme.colors.home.pink};
    }
  }
`;

export const InteriorBox = styled.div<{
  ratio: number;
  radius?: string;
  objetMode?: boolean;
}>`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: ${({ radius }) => radius ?? 0};
  border: ${({ ratio }) => ratio * (IS_MOBILE ? 4 : 3)}px solid
    ${({ objetMode }) => (objetMode ? "white" : "black")};
  overflow: hidden;
  background-color: ${({ color }) => color || "white"};
`;
