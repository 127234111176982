import React from "react";
import { useModalBalloonClose } from "../../../stores/modalStore";
import { Z_INDEX_TOP_UI } from "../../common/const";

type Props = () => {};

const BalloonClose = () => {
  const setModalBalloonClose = useModalBalloonClose((state) => state.setIsOpen);

  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: Z_INDEX_TOP_UI + 1000,
        pointerEvents: "visible",
      }}
      onClick={() => {
        setModalBalloonClose(false);
      }}
    />
  );
};

export default BalloonClose;
