import React, {useEffect, useLayoutEffect, useMemo, useRef} from "react";
import styled from "styled-components";
import {Html, Plane, useGLTF, useHelper, useTexture} from "@react-three/drei";
import * as THREE from "three";
import {useFrame, useThree} from "@react-three/fiber";
import {
    useLocation,
} from "react-router-dom";
import {DirectionalLightHelper} from "three";
import useCameraStore, {CameraState} from "../../stores/cameraStore";
import LG_HomeMain from "./main/LG_HomeMain";
import useProductStore, {ProductState} from "../../stores/productStore";

// import loadable from "@loadable/component";

import LivingRoom from "./room/LivingRoom";
import Kitchen from "./room/Kitchen";
import UtilityRoom from "./room/UtilityRoom";

import {
    getProductsData,
    ProductsCategoryType,
    RoomType,
} from "../../api/products/productsData";
import useRightPanelStore, {
    RightPanelState,
} from "../../stores/rightPanelStore";
import useHistoryStore, {HistoryState} from "../../stores/historyStore";
import {useGuideMenuHidden} from "../../stores/hiddenGuideMenu";
import qs from "qs";
import {useModalVimeo} from "../../stores/modalStore";
import useSpaceThemeStore from "../../stores/spaceThemeStore";

// const LivingRoom = loadable(()=>import('./room/LivingRoom'));
// const Kitchen = loadable(() => import('./room/Kitchen'));
// const UtilityRoom = loadable(() => import('./room/UtilityRoom'));

/**
 * LG 홈 전시관
 * @constructor
 */

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const cameraApiSelector = (state: CameraState) => state.api;
const selectCategorySelector = (state: ProductState) =>
    state.api.selectCategory;
const selectRoomSelector = (state: ProductState) => state.api.selectRoom;
const selectedRoomSelector = (state: ProductState) => state.selectedRoom;
// const selectedCategorySelector = (state: ProductState) => state.selectedCategory;
// const selectedProductSelector = (state: ProductState) => state.selectedProduct;
const setIsOpenSelector = (state: RightPanelState) => state.setIsOpen;
const setIsShowSelector = (state: RightPanelState) => state.setIsShow;
const prevPathnameSelector = (state: HistoryState) => state.prevPathname;

// useTexture.preload('/models/rooms/lghome/kitchen/Lightmap_2_2.exr');
// useTexture.preload('/models/rooms/lghome/kitchen/AO_2.png');
// useTexture.preload('/models/rooms/lghome/kitchen/Environment.exr');
// useTexture.preload('/models/rooms/lghome/kitchen/Environment_001.exr');
let hasShowIntro = false;

const LG_Home = React.memo(() => {
    const location = useLocation();
    const gl = useThree((state) => state.gl);
    // const lang = useLangStore(state => state.lang);

    const lightRef = useRef<THREE.Light>(null!);
    useHelper(lightRef, DirectionalLightHelper);

    const modalVimeo = useModalVimeo((state) => state.isOpen);
    const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
    const setVimeoId = useModalVimeo((state) => state.setId);
    const setVimeoGaId = useModalVimeo((state) => state.setGaId);
    const setVimeo = (id: string) => {
        setVimeoId(id);
        setModalVimeo(true);
    };
    useEffect(() => {
        if (!hasShowIntro) {
            setVimeoGaId("exhibition_product");
            setVimeo("https://vimeo.com/662243429/9d271e0751");
            hasShowIntro = true;
        }
    }, []);

    useEffect(() => {
        gl.setClearColor("#333");
    }, [gl]);

    const {setTargetPos, setLookPos, setTouchStart} = useCameraStore(cameraApiSelector);
    // const selectedCategory = useProductStore(selectedCategorySelector);     //선택된 카테고리 데이터
    // const selectedProduct = useProductStore(selectedProductSelector);       //선택된 제품 데이터
    const selectedRoom = useProductStore(selectedRoomSelector); //선택된 공간 타입
    const selectCategory = useProductStore(selectCategorySelector); //카테고리 선택하기 (함수)
    const selectRoom = useProductStore(selectRoomSelector); //공간 타입 설정하기 (함수)
    const setIsOpen = useRightPanelStore(setIsOpenSelector); //오른쪽 패널 열기 (함수)
    const setIsShow = useRightPanelStore(setIsShowSelector); //오른쪽 패널 활성/비활성 (함수)
    const pathname = useHistoryStore((state) => state.pathname);
    const prevPathname = useHistoryStore(prevPathnameSelector);
    const setGuideCategory = useGuideMenuHidden((state) => state.setCategory);
    const setLgHomeTheme = useSpaceThemeStore(state => state.api.setLgHomeTheme);

    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true,
    });
    // console.log(">>>>>>>>>>", query.pathname);

    //첫 진입 시 location.pathname으로 부터 category 이름을 추출하여 카테고리 초기화
    const searchedCategoryName: ProductsCategoryType | undefined = useMemo(() => {
        if(!query.pathname) return undefined;

        if (query.pathname.indexOf("refrigerator") > -1) return "refrigerator";
        else if (query.pathname.indexOf("cooking") > -1) return "cooking";
        else if (query.pathname.indexOf("microwave_oven") > -1)
            return "microwave_oven";
        else if (query.pathname.indexOf("dishwasher") > -1) return "dishwasher";
        else if (query.pathname.indexOf("water_purifier") > -1)
            return "water_purifier";
        else if (query.pathname.indexOf("homebrew") > -1) return "homebrew";
        else if (query.pathname.indexOf("washer_n_dryer") > -1)
            return "washer_n_dryer";
        else if (query.pathname.indexOf("styler") > -1) return "styler";
        else if (query.pathname.indexOf("vacuum_cleaner") > -1)
            return "vacuum_cleaner";
        else if (query.pathname.indexOf("air_conditioner") > -1)
            return "air_conditioner";
        else if (query.pathname.indexOf("air_care") > -1) return "air_care";
        else if (query.pathname.indexOf("wearable_air_purifier") > -1)
            return "wearable_air_purifier";
        else if (query.pathname.indexOf("tiiun_mini") > -1) return "tiiun_mini";
        else if (query.pathname.indexOf("tiiun") > -1) return "tiiun";
    }, [query]);

    const searchedRoomType: RoomType | undefined = useMemo(() => {
        if (query.pathname.indexOf("/kitchen") > -1) return "kitchen";
        else if (query.pathname.indexOf("/living") > -1) return "living";
        else if (query.pathname.indexOf("/utility") > -1) return "utility";
    }, [query]);

    useLayoutEffect(() => {
        if(searchedRoomType === 'kitchen'){
            setLgHomeTheme('vivid');
        }
        else if(searchedRoomType === 'living')
        {
            setLgHomeTheme('warm');
        }
        else{
            setLgHomeTheme('wood');
        }
        selectRoom(searchedRoomType ? searchedRoomType : null);
    }, [searchedRoomType]);

    // LG Home에서 처음 클릭 여부
    const isClickHomeFirstRef = useRef(true);
    useLayoutEffect(() => {
        isClickHomeFirstRef.current = prevPathname === "/home";
    }, [prevPathname]);

    useLayoutEffect(() => {
        if (searchedCategoryName) {
            const categoryData = getProductsData(searchedCategoryName);

            if (categoryData) {
                // selectCategory(categoryData, isClickHomeFirstRef.current);   //카테고리 선택
                setGuideCategory(categoryData.category as string);
                selectCategory(categoryData, false);
                setIsOpen(true); //오른쪽 패널 열린 상태로
            } else {
                selectCategory(null);
                setIsOpen(false); //오른쪽 패널 닫힌 상태로
                setIsShow(false);
                setTargetPos(null);
                setLookPos(null);
            }
        } else {
            selectCategory(null);
            setIsOpen(false); //오른쪽 패널 닫힌 상태로
            setIsShow(true);
            setTargetPos(null);
            setLookPos(null);
        }
    }, [searchedCategoryName]);

    useEffect(() => {
        return () => {
            console.log("LG Home 해제!");
            selectCategory(null);
            setIsOpen(false); //오른쪽 패널 닫힌 상태로
            setIsShow(false);
            setTargetPos(null);
            setLookPos(null);
            selectRoom(null);
        };
    }, []);

    const getRoom = () => {
        return (
            <>
                {pathname.indexOf("/home") > -1 && (
                    <Html
                        fullscreen
                        center={false}
                        transform={false}
                        style={{
                            pointerEvents: "none",
                            display: pathname === "/home" ? "block" : "none",
                        }}
                    >
                        <LG_HomeMain/>
                    </Html>
                )}

                {selectedRoom === "living" && <LivingRoom/>}
                {selectedRoom === "kitchen" && <Kitchen/>}
                {selectedRoom === "utility" && <UtilityRoom/>}
            </>
        );
    };

    return (
        <>
            {getRoom()}
        </>
    );
    // return <group>{!modalVimeo && hasShowIntro && getRoom()}</group>;
});

export default LG_Home;
