//-------------------------------------------- Solid
import {ProductColorType} from "../productsData";

//스타일러
export const Styler_Glass_Espresso: ProductColorType = {
    name: <>Espresso</>,
    label: 'objet_color_espresso',
    color: '/images/objet/glass/styler/espresso.png',
    ga_id: "espresso"
};
export const Styler_Glass_Brown: ProductColorType = {
    name: <>Brown</>,
    label: 'objet_color_brown',
    color: '/images/objet/glass/styler/espresso.png',
    ga_id: "brown"
};
export const Styler_Glass_Ivory: ProductColorType = {
    name: <>Ivory</>,
    label: 'objet_color_ivory',
    color: '/images/objet/glass/styler/ivory.png',
    ga_id: "ivory"
};
export const Styler_Glass_Linen_Black: ProductColorType = {
    name: <>Linen<br/>black</>,
    label: 'objet_color_linen_black',
    color: '/images/objet/glass/styler/linen_black.png',
    ga_id: "linen_black"
};
export const Styler_Glass_Black: ProductColorType = {
    name: <>Black</>,
    label: 'objet_color_black',
    color: '/images/objet/glass/styler/linen_black.png',
    ga_id: "black"
};
export const Styler_Glass_Metallic_Charcoal: ProductColorType = {
    name: <>Metallic<br/>Charcoal</>,
    label: 'objet_color_charcoal',
    color: '/images/objet/glass/styler/metallic_charcoal.png',
    ga_id: "charcoal_grey"
};
export const Styler_Glass_Charcoal_Grey: ProductColorType = {
    name: <>Charcoal<br/>Grey</>,
    label: 'objet_color_charcoal_grey',
    color: '/images/objet/glass/styler/metallic_charcoal.png',
    ga_id: "charcoal_grey"
};
export const Styler_Glass_Mirror: ProductColorType = {
    name: <>Mirror</>,
    label: 'objet_color_mirror',
    color: '/images/objet/glass/styler/mirror.png',
    ga_id: "mirror"
};
export const Styler_Glass_White: ProductColorType = {
    name: <>Espresso</>,
    label: 'objet_color_white',
    color: '/images/objet/glass/styler/white.png',
    ga_id: "white"
};
export const Styler_Glass_Black_Mirror: ProductColorType = {
    name: <>Black Mirror</>,
    label: 'objet_color_black_mirror',
    color: '/images/objet/glass/styler/mirror.png',
    ga_id: "black_mirror"
};