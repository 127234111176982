/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {useKTXLoader} from "../../utils/useKTXLoader";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Chrom_Silver: THREE.Mesh
        door_Plastic_L: THREE.Mesh
        glow_light: THREE.Mesh
        Silicon00: THREE.Mesh
        Silver_Plastic: THREE.Mesh
        Mesh005: THREE.Mesh
        Mesh005_1: THREE.Mesh
        black_board: THREE.Mesh
        black_metal: THREE.Mesh
        grass001: THREE.Mesh
        Chrom: THREE.Mesh
        inside03: THREE.Mesh
        Mesh018: THREE.Mesh
        Mesh018_1: THREE.Mesh
        Chrom_Silver001: THREE.Mesh
        door_Plastic_R: THREE.Mesh
        Silicon01: THREE.Mesh
        Silver_Plastic01: THREE.Mesh
        door_R: THREE.Mesh
        door_R01: THREE.Mesh
        black_Plastic: THREE.Mesh
        ['case']: THREE.Mesh
        Silver_metal: THREE.Mesh
        Grass: THREE.Mesh
        Object001: THREE.Mesh
        Object002: THREE.Mesh
        Grass_Decal: THREE.Mesh
    }
    materials: {
        ['Water Iron']: THREE.MeshStandardMaterial
        ['Door Plastic']: THREE.MeshStandardMaterial
        Light: THREE.MeshStandardMaterial
        Slicon: THREE.MeshStandardMaterial
        ['Sliver 2']: THREE.MeshStandardMaterial
        ['Front Door 1']: THREE.MeshStandardMaterial
        ['Inside 1']: THREE.MeshStandardMaterial
        ['Black Board']: THREE.MeshStandardMaterial
        Glass: THREE.MeshStandardMaterial
        Chrome: THREE.MeshStandardMaterial
        Slicon2: THREE.MeshStandardMaterial
        Sliver: THREE.MeshStandardMaterial
        Wheel: THREE.MeshStandardMaterial
        Case: THREE.MeshStandardMaterial
        Glass_Front: THREE.MeshStandardMaterial
        UI: THREE.MeshStandardMaterial
        ['Glass_Front_Decal.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/m/vs4_instaview_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/refrigerator/vs4_instaview_ktx2.glb');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL);

    useEffect(()=>{
        materials['Sliver 2'].roughness = 0.8;
        materials['Sliver 2'].metalness = 0.4;
        materials['Sliver 2'].color.setHex(0x999999);
        materials['Glass_Front_Decal.001'].roughness = 0.3;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[0, 0, 0.25]}>

            <mesh position={[0, 0.42, -0.1]}>
                <boxBufferGeometry args={[0.5, 0.72 ,0.1]}/>
                <meshBasicMaterial color={"#999"} />
            </mesh>
            <mesh position={[-0.05, 1.325, -0.1]} >
                <boxBufferGeometry args={[0.2, 0.92 ,0.1]}/>
                <meshBasicMaterial color={"#999"} depthTest={true} depthWrite={true}/>
            </mesh>

            <group
                position={[0, 0, 0.057]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'VS4_root' }}>

                <group
                    position={[-43.7926, -11.7598, -175]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    userData={{ name: 'left_door_root' }}>
                    <mesh
                        geometry={nodes.Chrom_Silver.geometry}
                        material={nodes.Chrom_Silver.material}
                        position={[37.5926, -171.9253, -6.0906]}
                        scale={[0.1, 0.1, 0.1]}
                        userData={{ name: 'Chrom_Silver' }}
                    />
                    <mesh
                        geometry={nodes.door_Plastic_L.geometry}
                        material={nodes.door_Plastic_L.material}
                        position={[37.5926, -171.9253, -6.0906]}
                        scale={[0.1, 0.1, 0.1]}
                        userData={{ name: 'door_Plastic_L' }}

                    />
                    <mesh
                        geometry={nodes.glow_light.geometry}
                        material={materials.Light}
                        position={[18.6048, -116.3553, -8.2906]}
                        userData={{ name: 'glow_light' }}

                    />
                    <mesh
                        geometry={nodes.Silicon00.geometry}
                        material={nodes.Silicon00.material}
                        position={[37.5926, -171.9253, -6.0906]}
                        scale={[0.1, 0.1, 0.1]}
                        userData={{ name: 'Silicon00' }}

                    />
                    <mesh
                        geometry={nodes.Silver_Plastic.geometry}
                        material={materials['Sliver 2']}
                        position={[37.5926, -171.9253, -6.0906]}
                        scale={[0.1, 0.1, 0.1]}
                        userData={{ name: 'Silver_Plastic' }}

                        // visible={false}
                    />
                    <group
                        position={[37.5926, -171.9253, -6.0906]}
                        scale={[0.1, 0.1, 0.1]}
                        userData={{ name: 'door_L' }}>
                        <mesh geometry={nodes.Mesh005.geometry} material={nodes.Mesh005.material} />
                        <mesh geometry={nodes.Mesh005_1.geometry} material={nodes.Mesh005_1.material} />
                    </group>
                </group>
                <group
                    position={[44.0981, -11.9463, -175]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    userData={{ name: 'right_door_root' }}>
                    <group position={[0.2116, 2.0923, 2.8807]} userData={{ name: 'grass_door' }}>
                        <mesh
                            geometry={nodes.black_board.geometry}
                            material={nodes.black_board.material}
                            position={[-50.5098, -174.0175, -8.7848]}
                            userData={{ name: 'black_board' }}
                        />
                        <mesh
                            geometry={nodes.black_metal.geometry}
                            material={nodes.black_metal.material}
                            position={[-50.5098, -174.0175, -8.7848]}
                            userData={{ name: 'black_metal' }}
                        />
                        <mesh
                            geometry={nodes.grass001.geometry}
                            material={materials.Glass}
                            position={[-50.5098, -174.0175, -5.5071]}
                            scale={[0.1, 0.1, 0.1]}
                            userData={{ name: 'grass.001' }}
                        />
                        <mesh
                            geometry={nodes.Chrom.geometry}
                            material={nodes.Chrom.material}
                            position={[-50.5098, -174.0175, -8.7848]}
                            userData={{ name: 'Chrom' }}
                        />
                        <mesh
                            geometry={nodes.inside03.geometry}
                            material={nodes.inside03.material}
                            position={[-50.5098, -174.0175, -8.7848]}
                            userData={{ name: 'inside03' }}
                        />
                        <group position={[-50.5098, -174.0175, -8.7848]} userData={{ name: 'Silicon03' }}>
                            <mesh geometry={nodes.Mesh018.geometry} material={nodes.Mesh018.material} />
                            <mesh geometry={nodes.Mesh018_1.geometry} material={materials.Slicon2} />
                        </group>
                    </group>
                    <mesh
                        geometry={nodes.Chrom_Silver001.geometry}
                        material={nodes.Chrom_Silver001.material}
                        position={[-50.2981, -171.9253, -5.9041]}
                        userData={{ name: 'Chrom_Silver.001' }}
                    />
                    <mesh
                        geometry={nodes.door_Plastic_R.geometry}
                        material={nodes.door_Plastic_R.material}
                        position={[-50.2981, -171.9253, -5.9041]}
                        userData={{ name: 'door_Plastic_R' }}
                    />
                    <mesh
                        geometry={nodes.Silicon01.geometry}
                        material={nodes.Silicon01.material}
                        position={[-50.2981, -171.9253, -5.9041]}
                        userData={{ name: 'Silicon01' }}
                    />
                    <mesh
                        geometry={nodes.Silver_Plastic01.geometry}
                        material={materials.Sliver}
                        position={[-50.2981, -171.9253, -5.9041]}
                        userData={{ name: 'Silver_Plastic01' }}
                    />
                    <mesh
                        geometry={nodes.door_R.geometry}
                        material={nodes.door_R.material}
                        position={[-50.2981, -171.9253, -5.9041]}
                        userData={{ name: 'door_R' }}
                    />
                    <mesh
                        geometry={nodes.door_R01.geometry}
                        material={nodes.door_R01.material}
                        position={[-50.2981, -171.9253, -5.9041]}
                        userData={{ name: 'door_R01' }}
                    />
                </group>
                <mesh
                    geometry={nodes.black_Plastic.geometry}
                    material={materials.Wheel}
                    position={[0, -119.3832, -84.3925]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    userData={{ name: 'black_Plastic' }}
                />
                <mesh
                    geometry={nodes['case'].geometry}
                    material={materials.Case}
                    position={[-6.2, -17.8504, -3.0747]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    userData={{ name: 'case' }}
                />
                <mesh
                    geometry={nodes.Silver_metal.geometry}
                    material={nodes.Silver_metal.material}
                    position={[0, -49.6488, -0.4815]}
                    rotation={[-Math.PI / 2, 0, 0]}
                    userData={{ name: 'Silver_metal' }}
                />
            </group>
            <mesh
                geometry={nodes.Grass.geometry}
                material={materials.Glass_Front}
                position={[-0.062, 0.0307, -0.1249]}
                scale={[0.001, 0.001, 0.001]}
                userData={{ name: 'Grass' }}
            />
            <mesh
                geometry={nodes.Object001.geometry}
                material={nodes.Object001.material}
                position={[-0.062, 0.0307, -0.121]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Object001' }}
            />
            <mesh
                geometry={nodes.Object002.geometry}
                material={nodes.Object002.material}
                position={[-0.062, 0.0307, -0.1241]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Object002' }}
            />
            <mesh
                geometry={nodes.Grass_Decal.geometry}
                material={materials['Glass_Front_Decal.001']}
                position={[-0.062, 0.0307, -0.1249]}
                scale={[0.001, 0.001, 0.001]}
                userData={{ name: 'Grass_Decal' }}
            />
        </group>
    )
}




// /*
// Auto-generated by: https://github.com/pmndrs/gltfjsx
// */
//
// import * as THREE from 'three'
// import React, {useEffect, useRef} from 'react'
// import {useGLTF, useAnimations} from '@react-three/drei'
// import {GLTF} from 'three-stdlib/loaders/GLTFLoader'
// import {ROOT_PATH_PREFIX} from "../../components/common/const";
// import {disposeScene} from "../../utils/disposeScene";
// import {useLoader} from "@react-three/fiber";
// import {GLTFLoader} from "three-stdlib";
//
// type GLTFResult = GLTF & {
//     nodes: {
//         glow_light: THREE.Mesh
//         Silicon00: THREE.Mesh
//         Object003: THREE.Mesh
//         door_Plastic_L: THREE.Mesh
//         Silver_Plastic: THREE.Mesh
//         Object001001: THREE.Mesh
//         Chrom_Silver: THREE.Mesh
//         Mesh015: THREE.Mesh
//         Mesh015_1: THREE.Mesh
//         grass: THREE.Mesh
//         Mesh030: THREE.Mesh
//         Mesh030_1: THREE.Mesh
//         inside03: THREE.Mesh
//         black_board: THREE.Mesh
//         black_metal: THREE.Mesh
//         Chrom: THREE.Mesh
//         grass_basket01: THREE.Mesh
//         Silicon01: THREE.Mesh
//         Silver_Plastic01: THREE.Mesh
//         door_Plastic_R: THREE.Mesh
//         inside01: THREE.Mesh
//         Object002: THREE.Mesh
//         Chrom_Silver001: THREE.Mesh
//         door_R: THREE.Mesh
//         door_R01: THREE.Mesh
//         grass001: THREE.Mesh
//         chrom_inside: THREE.Mesh
//         grass_basket: THREE.Mesh
//         black_Plastic: THREE.Mesh
//         inside00: THREE.Mesh
//         Silver_metal: THREE.Mesh
//         UI02: THREE.Mesh
//         case1: THREE.Mesh
//     }
//     materials: {
//         Light: THREE.MeshStandardMaterial
//         Slicon: THREE.MeshStandardMaterial
//         Glass: THREE.MeshStandardMaterial
//         ['Door Plastic']: THREE.MeshStandardMaterial
//         ['Sliver 2']: THREE.MeshStandardMaterial
//         UI03: THREE.MeshStandardMaterial
//         ['Water Iron']: THREE.MeshStandardMaterial
//         ['Front Door 1']: THREE.MeshStandardMaterial
//         ['Inside 1']: THREE.MeshStandardMaterial
//         Slicon2: THREE.MeshStandardMaterial
//         ['Black Board']: THREE.MeshStandardMaterial
//         Chrome: THREE.MeshStandardMaterial
//         Sliver: THREE.MeshStandardMaterial
//         Bitmap: THREE.MeshStandardMaterial
//         Iron: THREE.MeshStandardMaterial
//         Wheel: THREE.MeshStandardMaterial
//         Case: THREE.MeshStandardMaterial
//     }
// }
//
// export default function Model(props: JSX.IntrinsicElements['group']) {
//     const group = useRef<THREE.Group>()
//     const {nodes, materials, scene} = useGLTF(ROOT_PATH_PREFIX+'/models/ref/ref_13/ref_13.gltf') as GLTFResult
//
//
//     useEffect(() => {
//         return () => {
//             disposeScene(scene);
//             useLoader.clear(GLTFLoader, ROOT_PATH_PREFIX + '/models/ref/ref_13/ref_13.gltf');
//         }
//     }, []);
//
//
//     return (
//         <group ref={group} {...props} position={[0, 0, 0.31]} dispose={null}>
//             <group
//                 rotation={[Math.PI / 2, 0, 0]}
//                 scale={[0.01, 0.01, 0.01]}
//                 userData={{name: 'VS4_root'}}>
//                 <group
//                     name="left_door_root"
//                     position={[-43.7926, -11.7598, -175]}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'left_door_root'}}>
//                     <mesh
//                         name="glow_light"
//                         geometry={nodes.glow_light.geometry}
//                         material={materials.Light}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'glow_light'}}
//                     />
//                     <mesh
//                         name="Silicon00"
//                         geometry={nodes.Silicon00.geometry}
//                         material={nodes.Silicon00.material}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'Silicon00'}}
//                     />
//                     <mesh
//                         geometry={nodes.Object003.geometry}
//                         material={nodes.Object003.material}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'Object003'}}
//                     />
//                     <mesh
//                         name="door_Plastic_L"
//                         geometry={nodes.door_Plastic_L.geometry}
//                         material={nodes.door_Plastic_L.material}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'door_Plastic_L'}}
//                     />
//                     <mesh
//                         name="Silver_Plastic"
//                         geometry={nodes.Silver_Plastic.geometry}
//                         material={materials['Sliver 2']}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'Silver_Plastic'}}
//                     />
//                     <mesh
//                         geometry={nodes.Object001001.geometry}
//                         material={nodes.Object001001.material}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'Object001.001'}}
//                     />
//                     <mesh
//                         name="Chrom_Silver"
//                         geometry={nodes.Chrom_Silver.geometry}
//                         material={nodes.Chrom_Silver.material}
//                         position={[43.7926, -175, 11.7598]}
//                         userData={{name: 'Chrom_Silver'}}
//                     />
//                     <group name="door_L" position={[43.7926, -175, 11.7598]} userData={{name: 'door_L'}}>
//                         <mesh
//                             geometry={nodes.Mesh015.geometry}
//                             material={nodes.Mesh015.material}
//                         />
//                         <mesh
//                             geometry={nodes.Mesh015_1.geometry}
//                             material={nodes.Mesh015_1.material}
//                         />
//                     </group>
//                 </group>
//                 <group
//                     name="right_door_root"
//                     position={[44.0981, -11.9463, -175]}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'right_door_root'}}>
//                     <group
//                         name="grass_door"
//                         position={[0.2116, 2.0923, 2.8807]}
//                         userData={{name: 'grass_door'}}>
//                         <mesh
//                             name="grass"
//                             geometry={nodes.grass.geometry}
//                             material={nodes.grass.material}
//                             position={[-24.4098, -47.6875, 50.524]}
//                             userData={{name: 'grass'}}
//                         />
//                         <group
//                             name="Silicon03"
//                             position={[-44.3097, -177.0923, 9.0656]}
//                             userData={{name: 'Silicon03'}}>
//                             <mesh
//                                 geometry={nodes.Mesh030.geometry}
//                                 material={nodes.Mesh030.material}
//                             />
//                             <mesh
//                                 geometry={nodes.Mesh030_1.geometry}
//                                 material={materials.Slicon2}
//                             />
//                         </group>
//                         <mesh
//                             name="inside03"
//                             geometry={nodes.inside03.geometry}
//                             material={nodes.inside03.material}
//                             position={[-44.3097, -177.0923, 9.0656]}
//                             userData={{name: 'inside03'}}
//                         />
//                         <mesh
//                             name="black_board"
//                             geometry={nodes.black_board.geometry}
//                             material={nodes.black_board.material}
//                             position={[-44.3097, -177.0923, 9.0656]}
//                             userData={{name: 'black_board'}}
//                         />
//                         <mesh
//                             name="black_metal"
//                             geometry={nodes.black_metal.geometry}
//                             material={nodes.black_metal.material}
//                             position={[-44.3097, -177.0923, 9.0656]}
//                             userData={{name: 'black_metal'}}
//                         />
//                         <mesh
//                             name="Chrom"
//                             geometry={nodes.Chrom.geometry}
//                             material={nodes.Chrom.material}
//                             position={[-44.3097, -177.0923, 9.0656]}
//                             userData={{name: 'Chrom'}}
//                         />
//                     </group>
//                     <mesh
//                         name="grass_basket01"
//                         geometry={nodes.grass_basket01.geometry}
//                         material={nodes.grass_basket01.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'grass_basket01'}}
//                     />
//                     <mesh
//                         name="Silicon01"
//                         geometry={nodes.Silicon01.geometry}
//                         material={nodes.Silicon01.material}
//                         position={[-44.0981, -175, -36.4348]}
//                         userData={{name: 'Silicon01'}}
//                     />
//                     <mesh
//                         name="Silver_Plastic01"
//                         geometry={nodes.Silver_Plastic01.geometry}
//                         material={materials.Sliver}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'Silver_Plastic01'}}
//                     />
//                     <mesh
//                         name="door_Plastic_R"
//                         geometry={nodes.door_Plastic_R.geometry}
//                         material={nodes.door_Plastic_R.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'door_Plastic_R'}}
//                     />
//                     <mesh
//                         name="inside01"
//                         geometry={nodes.inside01.geometry}
//                         material={nodes.inside01.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'inside01'}}
//                     />
//                     <mesh
//                         geometry={nodes.Object002.geometry}
//                         material={nodes.Object002.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'Object002'}}
//                     />
//                     <mesh
//                         geometry={nodes.Chrom_Silver001.geometry}
//                         material={nodes.Chrom_Silver001.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'Chrom_Silver.001'}}
//                     />
//                     <mesh
//                         name="door_R"
//                         geometry={nodes.door_R.geometry}
//                         material={nodes.door_R.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'door_R'}}
//                     />
//                     <mesh
//                         name="door_R01"
//                         geometry={nodes.door_R01.geometry}
//                         material={nodes.door_R01.material}
//                         position={[-44.0981, -175, 11.9463]}
//                         userData={{name: 'door_R01'}}
//                     />
//                     <mesh
//                         geometry={nodes.grass001.geometry}
//                         material={materials.Bitmap}
//                         position={[-44.0979, -175.0002, 11.9468]}
//                         userData={{name: 'grass001'}}
//                     />
//                 </group>
//                 <mesh
//                     name="chrom_inside"
//                     geometry={nodes.chrom_inside.geometry}
//                     material={materials.Iron}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'chrom_inside'}}
//                 />
//                 <mesh
//                     name="grass_basket"
//                     geometry={nodes.grass_basket.geometry}
//                     material={nodes.grass_basket.material}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'grass_basket'}}
//                 />
//                 <mesh
//                     name="black_Plastic"
//                     geometry={nodes.black_Plastic.geometry}
//                     material={materials.Wheel}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'black_Plastic'}}
//                 />
//                 <mesh
//                     name="inside00"
//                     geometry={nodes.inside00.geometry}
//                     material={nodes.inside00.material}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'inside00'}}
//                 />
//                 <mesh
//                     name="Silver_metal"
//                     geometry={nodes.Silver_metal.geometry}
//                     material={nodes.Silver_metal.material}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'Silver_metal'}}
//                 />
//                 <mesh
//                     name="UI02"
//                     geometry={nodes.UI02.geometry}
//                     material={nodes.UI02.material}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'UI02'}}
//                 />
//                 <mesh
//                     name="case1"
//                     geometry={nodes.case1.geometry}
//                     material={materials.Case}
//                     rotation={[-Math.PI / 2, 0, 0]}
//                     userData={{name: 'case1'}}
//                 />
//             </group>
//         </group>
//     )
// }
