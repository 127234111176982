import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import useHistoryStore from "../../../stores/historyStore";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../../common/const";
import useResizeStore from "../../../stores/resizeStore";
import CloseButton from "../../common/navigation/CloseButton";
import ZepetoContents from "./ZepetoContents";
import { GA_Event } from "../../../api/ga";

const POPUP_SIZE_W = 1481;
const POPUP_SIZE_H = 843;

const ZepetoPanel = () => {
  const [isReady, setIsReady] = useState(false);
  const resize = useResizeStore((state) => state.resize);
  const [closed, setClosed] = useState(false);

  return (
    <Wrapper>
      <PopupContainer ratio={resize.ratio} style={{ position: "relative" }}>
        <CloseButton
          showLabel={false}
          rounded={true}
          style={{
            position: "absolute",
            zIndex: Z_INDEX_POPUP,
            right: "4%",
            top: "5.5%",
          }}
          onClick={() => {
            setClosed(true);
            GA_Event("ha_virtual_studio", "lghome_in_metaverse", "close");
          }}
        />
        <BlankButton
          ratio={resize.ratio}
          bottom={9}
          left={22}
          className="google"
          onClick={() => {
            window.open(
              "https://play.google.com/store/apps/details?id=me.zepeto.main",
              "_blank"
            );
          }}
        />
        <BlankButton
          ratio={resize.ratio}
          bottom={9}
          left={33}
          className="apple"
          onClick={() => {
            window.open(
              "https://apps.apple.com/us/app/zepeto/id1350301428",
              "_blank"
            );
          }}
        />
        <ZepetoContents isClosed={closed} />
      </PopupContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: visible;
  z-index: ${Z_INDEX_POPUP};
`;

const PopupContainer = styled.div<{ ratio: number }>`
  width: ${({ ratio }) => POPUP_SIZE_W * ratio * (IS_MOBILE ? 1.05 : 1)}px;
  height: ${({ ratio }) => POPUP_SIZE_H * ratio * (IS_MOBILE ? 1.05 : 1)}px;
  /* left: ${({ ratio }) => (IS_MOBILE ? 9 : 6) * ratio}em; */
  left: ${({ ratio }) =>
    (window.innerWidth - POPUP_SIZE_W * ratio * (IS_MOBILE ? 1.05 : 1)) / 2}px;
  top: ${({ ratio }) => (IS_MOBILE ? 6 : 20.5) * ratio}em;
  background: #000;
  background-image: url(${ROOT_PATH_PREFIX +
  "/images/zepeto/zepeto-pop-up_bg.png"});
  background-size: 100%;

  filter: drop-shadow(0 0 1em rgba(0, 0, 0, 0.5));

  flex-direction: column;
  align-items: flex-end;
`;

const BlankButton = styled.div<{ ratio: number; bottom: number; left: number }>`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  background-color: transparent;
  width: ${({ ratio }) => ratio * 10.5}em;
  height: ${({ ratio }) => ratio * 10.5}em;
  bottom: ${({ bottom, ratio }) => ratio * bottom}em;
  left: ${({ left, ratio }) => ratio * left}em;
  pointer-events: "visible";
  cursor: pointer;
  /* border: 1px solid tomato; */
`;

export default ZepetoPanel;
