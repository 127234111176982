import React from "react";
import styled from "styled-components";
import productsData from "../../../api/products/productsData";
import useResizeStore from "../../../stores/resizeStore";
import { IS_MOBILE } from "../../common/const";
import ProductsCategoryItem from "./ProductsCategoryItem";
import { BOTTOM_PANEL_HEIGHT } from "./ProductsCategoryPanel";

/**
 * 제품 카테고리 목록
 * @constructor
 */
const ProductsCategoryList = () => {
  const resize = useResizeStore((state) => state.resize);
  return (
    <Wrapper ratio={resize.ratio}>
      {productsData &&
        productsData.map((value, index) => {
          if (value.category === "mask_case") return;
          if (value.category === "tiiun") return;
          return <ProductsCategoryItem data={value} key={index} />;
        })}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ ratio: number }>`
  width: 100%;
  height: ${({ ratio }) => ratio * BOTTOM_PANEL_HEIGHT}em;
  display: ${IS_MOBILE ? "flex" : "inline-flex"};
  flex-wrap: ${IS_MOBILE ? "wrap" : "no-wrap"};
  justify-content: ${IS_MOBILE ? "flex-start" : "space-between"};
  align-items: center;
  padding: ${IS_MOBILE ? 3 : 0}% ${IS_MOBILE ? 4 : 3}% ${IS_MOBILE ? 1.5 : 0}%
    ${IS_MOBILE ? 4 : 3}%;
  background: #fff;
  opacity: 0.9;
  border-top-right-radius: ${({ ratio }) => ratio * (IS_MOBILE ? 2 : 0)}em;
`;

export default ProductsCategoryList;
