import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ProductsDataItemType } from "../../../api/products/productsData";
import useHistoryStore from "../../../stores/historyStore";
import { Center, Container } from "../../common/comps_styled/Container.styled";
import { Image } from "../../common/comps_styled/Image.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import useLangStore, { LangState } from "../../../stores/langStore";
import {
  getLangString,
  LangDataType,
  LangLabelType,
} from "../../../api/lang/langData";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";
import { useModalVimeo } from "../../../stores/modalStore";
import { getVimeoLink } from "../../../api/products/productVimeoDataForMobile";
import { useSelectMobileQuality } from "../../../stores/selectRouteForMobileStore";

type ProductsCategoryItemProps = {
  data: ProductsDataItemType;
} & JSX.IntrinsicElements["button"];

const langSelector = (state: LangState) => state.lang;

const ProductsCategoryItem = ({ data, ...rest }: ProductsCategoryItemProps) => {
  const pushHistory = useHistoryStore((state) => state.pushHistory);
  const nameWrapped = data.label ? data.label : "";
  const lang = useLangStore(langSelector);
  const [label, setLabel] = useState<React.ReactNode | string>("");
  const resize = useResizeStore((state) => state.resize);

  const modalVimeo = useModalVimeo((state) => state.isOpen);
  const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
  const setVimeoId = useModalVimeo((state) => state.setId);
  const setVimeoGaId = useModalVimeo((state) => state.setGaId);
  const setVimeo = (id: string) => {
    setVimeoId(id);
    setModalVimeo(true);
  };

  const isNormalQuailty = useSelectMobileQuality(
    (state) => state.isNormalQuality
  );

  useEffect(() => {
    const temp = getLangString(lang, nameWrapped);
    if (temp?.text) setLabel(temp.text);
  }, [lang]);

  const screenRatio = useMemo(
    () => window.innerWidth / window.innerHeight,
    [window.innerWidth, window.innerHeight]
  );

  return (
    <Wrapper
      ratio={resize.ratio}
      screenRatio={screenRatio}
      {...rest}
      className="category-item"
      onClick={() => {
        GA_Event("lg_home", "select_products", data.ga_id ?? "", {
          dimension5: data.ga_id,
        });

        if (isNormalQuailty) {
          setVimeoGaId("");
          setVimeo(getVimeoLink(data.category));
          return;
        }

        pushHistory(`/home/${data.roomType}/${data.category}`);
      }}
    >
      <Image
        className="product"
        src={ROOT_PATH_PREFIX + data.category_thumb_url}
        alt={data.category}
      />
      <div className="nameContainer">
        <Text className="name" lineHeight={0.95} color="black">
          {label}
        </Text>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.button<
  { ratio: number; screenRatio: number } & Record<string, any>
>`
  display: flex;
  flex-direction: column;
  width: ${({ ratio, screenRatio }) =>
    ratio * (IS_MOBILE ? (screenRatio > 2.1 ? 19.6 : 16.3) : 9)}em;
  align-items: center;
  background-color: transparent;
  pointer-events: visible;
  cursor: pointer;

  & .product {
    width: ${({ ratio, screenRatio }) =>
      ratio * (IS_MOBILE ? (screenRatio > 2.1 ? 15 : 13) : 8.2)}em;
    height: ${({ ratio, screenRatio }) =>
      ratio * (IS_MOBILE ? (screenRatio > 2.1 ? 15 : 13) : 8.2)}em;
    border-radius: ${({ ratio, screenRatio }) =>
      (ratio * (IS_MOBILE ? (screenRatio > 2.1 ? 15 : 13) : 8.2)) / 2}em;
    object-fit: contain;
    overflow: hidden;
    background: #c4c4c4;
  }

  & .nameContainer {
    width: 100%;
    height: ${({ ratio }) => ratio * (IS_MOBILE ? 6 : 4)}em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
  }

  & .name {
    font-size: ${({ ratio }) => ratio * (IS_MOBILE ? 2.5 : 1.65)}em;
    text-align: center;
  }

  &:hover {
    .product {
      outline: 2px solid ${({ theme }) => theme.colors.home.pink};
    }

    .name {
      color: ${({ theme }) => theme.colors.home.pink};
    }
  }

  &:focus-visible {
    .product {
      outline: 2px solid ${({ theme }) => theme.colors.home.pink};
    }

    .name {
      color: ${({ theme }) => theme.colors.home.pink};
    }
  }
`;

export default ProductsCategoryItem;
