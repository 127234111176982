import React, { useState } from "react";
import styled from "styled-components";
import { IS_MOBILE, ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../const";
import CloseButton from "../navigation/CloseButton";
import useResizeStore from "../../../stores/resizeStore";
import { Text } from "../comps_styled/Text.styled";
import theme from "../../../styles/theme.styled";
import { Divider } from "../comps_styled/Divider.styled";
import { useModalSurveyCleaner } from "../../../stores/modalStore";
import { Image } from "../comps_styled/Image.styled";
import { CheckOutlined } from "@ant-design/icons";
import { GA_Event } from "../../../api/ga";

const SurveyCleaner = (props: JSX.IntrinsicElements["div"]) => {
  //
  const setIsOpen = useModalSurveyCleaner((state) => state.setIsOpen);
  const setisCompleted = useModalSurveyCleaner((state) => state.setIsCompleted);
  const resize = useResizeStore((state) => state.resize);

  const [answers, setAnswers] = useState({ answer1: "", answer2: "" });
  const questions = {
    q1: ["yes", "no"],
    q2: [
      "carpet/universal_nozzle",
      "power_mop_nozzle",
      "two_in_one_combination_tool",
      "cervice_tool",
      "pet_nozzle",
      "slim_hard_floor_nozzle",
    ],
  };
  const names = {
    q1: ["YES", "NO"],
    q2: [
      <>
        Carpet / universal
        <br />
        Nozzle
      </>,
      <>
        Power mop
        <br />
        Nozzle
      </>,
      <>
        Two-in-One
        <br />
        Combination tool
      </>,
      <>Cervice tool</>,
      <>Pet Nozzle</>,
      <>
        Slim hard floor
        <br />
        Nozzle
      </>,
    ],
  };

  return (
    <Wrapper>
      <Panel
        style={{
          width: 1568 * resize.ratio,
          height: (IS_MOBILE ? 960 : 858) * resize.ratio,
        }}
      >
        <CloseButton
          showLabel={!IS_MOBILE}
          style={{
            position: "absolute",
            zIndex: Z_INDEX_POPUP,
            top: "5%",
            right: "4%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(false);
            GA_Event("survey", "vacuum_cleaner_menu", "close");
          }}
        />
        <Text
          style={{ position: "relative", top: `${55 * resize.ratio}px` }}
          size={`${42 * resize.ratio}px`}
          color="white"
          weight={theme.font.weight.semibold}
        >
          How to keep your house perfectly clean
        </Text>
        <div
          style={{
            position: "relative",
            width: 1300 * resize.ratio,
            top: `${134 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 40 : 28) * resize.ratio}px`}
            color="white"
          >
            1. Would you like to purchase All-in-One Tower station seperately?
          </Text>
          <div
            style={{
              display: "flex",
              padding: `${3 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
            }}
          >
            <Image
              alt="all in one tower"
              role="img"
              width={`${resize.ratio * 58}px`}
              height={`${resize.ratio * 187}px`}
              src={
                ROOT_PATH_PREFIX +
                "/images/survey/cleaner/img-all-in-one-tower@2x.png"
              }
            />
            <div
              style={{
                display: "flex",
                marginLeft: `${128 * resize.ratio}px`,
              }}
            >
              {questions.q1.map((data, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: `${resize.ratio * 87}px`,
                    }}
                  >
                    <CircleButton
                      aria-label={names.q1[index]}
                      role="button"
                      ratio={resize.ratio}
                      clicked={answers.answer1 === data}
                      onClick={() => {
                        setAnswers({ ...answers, answer1: data });
                        GA_Event("survey", "vacuum_cleaner_answer_1", data);
                      }}
                    >
                      <CheckOutlined
                        className="icon"
                        style={{
                          opacity: answers.answer1 === data ? 1 : 0,
                        }}
                      />
                    </CircleButton>
                    <Text
                      align="center"
                      size={`${4 * resize.ratio}em`}
                      style={{
                        padding: `0 ${resize.ratio * 1}em`,
                        color: "white",
                      }}
                    >
                      {names.q1[index]}
                    </Text>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            position: "relative",
            width: 1300 * resize.ratio,
            top: `${165 * resize.ratio}px`,
          }}
        >
          <Text
            size={`${(IS_MOBILE ? 40 : 28) * resize.ratio}px`}
            color="white"
          >
            2. Which of the vacuum nozzle will help you better?
          </Text>
          <div
            style={{
              display: "flex",
              padding: `${3 * resize.ratio}em ${2.6 * resize.ratio}em 0 ${
                2.6 * resize.ratio
              }em`,
              justifyContent: "space-between",
            }}
          >
            {questions.q2.map((data, index) => {
              return (
                <div
                  key={index}
                  style={{
                    width: `${resize.ratio * 129}px`,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Image
                    alt={data}
                    role="img"
                    width={`${resize.ratio * 129}px`}
                    height={`${resize.ratio * 131}px`}
                    style={{
                      cursor: "pointer",
                      borderRadius: `${2.1 * resize.ratio}em`,
                      outline: `${resize.ratio * 3}px solid ${
                        answers.answer2 === data
                          ? theme.colors.common.darkpink
                          : "white"
                      }`,
                    }}
                    src={
                      ROOT_PATH_PREFIX +
                      `/images/survey/cleaner/btn-0${index + 1}.png`
                    }
                    onClick={() => {
                      setAnswers({ ...answers, answer2: data });
                      GA_Event("survey", "vacuum_cleaner_answer_2", data);
                    }}
                  />
                  <Text
                    align="center"
                    size={`${(IS_MOBILE ? 3 : 1.6) * resize.ratio}em`}
                    style={{
                      paddingTop: `${resize.ratio * 1.2}em`,
                      color: "white",
                    }}
                  >
                    {names.q2[index]}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
        <SurveyButton
          ratio={resize.ratio}
          disabled={!(answers.answer1 !== "" && answers.answer2 !== "")}
          onClick={() => {
            console.log("answers", answers);
            setisCompleted(true);
            GA_Event("survey", "vacuum_cleaner_menu", "save", {
              dimension16: "vacuum_cleaner",
              dimension17: answers.answer1,
              dimension18: answers.answer2,
            });
          }}
        >
          Save and Exit
        </SurveyButton>
      </Panel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: visible;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Panel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
`;

const CircleButton = styled.button<{ ratio: number; clicked: boolean }>`
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 50 : 35)}px;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 50 : 35)}px;
  background-color: ${({ clicked }) =>
    clicked ? theme.colors.home.pink : "black"};
  border-radius: ${({ ratio }) => (ratio * (IS_MOBILE ? 50 : 35)) / 2}px;
  border: ${({ ratio }) => ratio * 3}px solid white !important;
  cursor: pointer;
  padding: 0;

  .icon {
    color: white;
    font-size: ${({ ratio }) => ratio * (IS_MOBILE ? 32 : 20)}px;
  }
`;

const SurveyButton = styled.button<{ ratio: number } & Record<string, any>>`
  position: absolute;
  bottom: ${({ ratio }) => ratio * 55}px;
  width: ${({ ratio }) => ratio * (IS_MOBILE ? 350 : 240)}px;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 80 : 56)}px;
  border-radius: ${({ ratio }) => (ratio * 10) / 2}px;
  background-color: "white";
  font-size: ${({ ratio }) => ratio * (IS_MOBILE ? 3.5 : 2.2)}em;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  cursor: ${({ disabled }) => (disabled ? "normal" : "pointer")};

  :hover {
    background-color: ${({ disabled, theme }) =>
      !disabled && theme.colors.common.darkpink};
    color: ${({ disabled }) => !disabled && "white"};
  }
`;

export default SurveyCleaner;
