import React from "react";

/**
 * 다국어 데이터 제어용
 */
import { LangType } from "../../stores/langStore";

export type LangLabelType =
  | ""
  | "select_interior"
  | "category_dishwasher"
  | "category_refrigerator"
  | "category_washer_n_dryer"
  | "category_air_care"
  | "category_air_conditioner"
  | "category_cooking"
  | "category_homebrew"
  | "category_microwave_oven"
  | "category_styler"
  | "category_tiiun"
  | "category_vacuum_cleaner"
  | "category_water_purifier"
  | "category_wearable_air_purifier"
  | "category_mask_case"
  //공통
  | "main_back"
  | "main_main"
  | "guide_pre"
  | "guide_next"
  | "guide_info"
  | "guide_usp"
  | "guide_open"
  | "guide_ar"
  | "guide_back"
  | "guide_main"
  | "guide_move"
  | "guide_rotate"
  | "guide_hide"
  | "guide_off"
  | "guide_guide"
  | "guide_close"
  | "guide_touch"
  | "guide_zoom"
  | "guide_swipe"
  | "main_hide"
  | "main_off"
  | "main_on"
  | "main_guide"
  | "close"
  | "discover"
  //home
  | "home_main_click"
  | "home_main_select"
  | "home_guide_title"
  | "home_guide_info"
  | "home_guide_usp"
  | "home_guide_open"
  | "home_guide_ar"
  | "home_guide_info_desc"
  | "home_guide_usp_desc"
  | "home_guide_oepn_desc"
  | "home_guide_ar_desc"
  | "home_guide_interior"
  | "home_guide_product"
  | "home_guide_color"
  | "home_guide_touch"
  | "home_guide_zoom"
  | "home_guide_swipe"
  | "home_exp_interior"
  | "home_exp_product"
  | "home_exp_color"
  | "home_exp_select_interior"
  | "home_exp_select_product"
  | "home_exp_select_color"
  | "home_exp_interior_warm"
  | "home_exp_interior_vivid"
  | "home_exp_interior_dark"
  | "home_exp_interior_wood"
  | "home_exp_interior_marble"
  | "home_exp_interior_white"
  | "home_exp_color_steel"
  | "home_exp_color_matteblack"
  | "home_exp_color_printproof"
  | "home_exp_color_printproofsteel"
  | "home_exp_color_blacksteel"
  | "home_exp_color_white"
  | "home_exp_color_mirror"
  | "home_exp_color_brown"
  | "home_exp_color_white"
  | "home_exp_color_grey"
  | "home_exp_color_red"
  | "home_exp_color_fantasy"
  | "home_exp_color_beige"
  | "home_exp_color_green"
  | "home_exp_color_silver"
  | "home_exp_color_black"
  | "home_exp_color_blackstain"
  | "home_exp_color_blackstainsteel"
  | "home_exp_color_blackstain_anti"
  | "home_exp_color_stainsteel_anti"
  | "home_exp_color_navy"
  | "home_exp_color_sts"
  | "home_exp_color_cbeige"
  | "home_exp_color_cgreen"
  | "home_exp_color_ccotton"
  | "home_exp_color_crose"
  | "home_exp_color_wine"
  | "home_exp_color_full"
  | "home_exp_color_pink"
  | "home_exp_color_rose_gold"
  | "home_exp_color_dark"
  | "home_exp_color_black_pcm"
  | "home_exp_color_white_pcm"
  | "home_exp_color_graphite"
  | "home_exp_color_beige_abs"
  | "home_exp_color_brown_tbd"
  | "home_exp_color_black_tbd"
  | "home_exp_color_deep_iron"
  | "home_exp_color_gold"
  | "home_exp_color_mint"
  | "home_exp_color_mango"
  | "home_exp_color_lavender"
  | "home_exp_color_nature_green"
  | "home_exp_open_close"
  | "home_exp_ar_title"
  | "home_exp_ar_info"
  | "home_main_choose"
  //AR
  | "home_exp_ar_puricare_pet"
  | "home_exp_ar_dualcool"
  | "home_exp_ar_lupin_slide"
  | "home_exp_ar_xd3"
  | "home_exp_ar_homebrew"
  | "home_exp_ar_ref_01"
  | "home_exp_ar_vs4"
  | "home_exp_ar_essence"
  | "home_exp_ar_victor2r"
  | "home_exp_ar_td_washer"
  | "home_exp_ar_wearable"
  //objet
  | "objet_main_click"
  | "objet_guide_title"
  | "objet_guide_step1"
  | "objet_guide_step1_desc"
  | "objet_guide_step2"
  | "objet_guide_step2_desc"
  | "objet_guide_step3"
  | "objet_guide_step3_desc"
  | "objet_guide_wall"
  | "objet_guide_interior"
  | "objet_guide_floor"
  | "objet_main_title"
  | "objet_main_select"
  | "objet_main_select_material_n_color"
  | "objet_main_floor_blossom"
  | "objet_main_floor_natural"
  | "objet_main_floor_modern"
  | "objet_main_choose"
  | "objet_main_wall_concrete"
  | "objet_main_wall_wood"
  | "objet_main_wall_brick"
  | "objet_main_floor_wood"
  | "objet_main_floor_herringbone"
  | "objet_main_floor_fabric"
  | "objet_main_freezer"
  | "objet_category_refrigerator"
  | "objet_category_dishwasher"
  | "objet_category_water_purifier"
  | "objet_category_water_purifier_undersink"
  | "objet_category_washer"
  | "objet_category_dryer"
  | "objet_category_washer_n_dryer"
  | "objet_category_styler"
  | "objet_category_vacuum_cleaner_stand"
  | "objet_category_vacuum_cleaner_robot"
  | "objet_category_vacuum_cleaner"
  | "objet_category_microwave_oven"
  | "objet_category_air_conditioner_stand"
  | "objet_category_air_conditioner_wall"
  | "objet_category_air_conditioner"
  | "objet_category_air_care_aero"
  | "objet_category_air_care"
  | "objet_category_tiiun"
  | "objet_category_lansen_freezer"
  | "objet_material_fenix"
  | "objet_material_fenix_title"
  | "objet_material_fenix_info"
  | "objet_material_solid"
  | "objet_material_solid_title"
  | "objet_material_solid_info"
  | "objet_material_mist"
  | "objet_material_mist_title"
  | "objet_material_mist_info"
  | "objet_material_nature"
  | "objet_material_nature_title"
  | "objet_material_nature_info"
  | "objet_material_calming"
  | "objet_material_calming_title"
  | "objet_material_calming_info"
  | "objet_material_glass"
  | "objet_material_glass_info"
  | "objet_color_botanic"
  | "objet_color_sand"
  | "objet_color_brick"
  | "objet_color_midnight"
  | "objet_color_silver"
  | "objet_color_green"
  | "objet_color_beige"
  | "objet_color_pink"
  | "objet_color_mint"
  | "objet_color_silver"
  | "objet_color_redwood"
  | "objet_color_clay_brown"
  | "objet_color_gray"
  | "objet_color_black"
  | "objet_color_white"
  | "objet_color_dark_green"
  | "objet_color_cream_white"
  | "objet_color_red"
  | "objet_color_cotton_sky"
  | "objet_color_pale_rose"
  | "objet_color_linen_black"
  | "objet_color_espresso"
  | "objet_color_ivory"
  | "objet_color_charcoal"
  | "objet_color_charcoal_grey"
  | "objet_color_mirror"
  | "objet_color_black_mirror"
  | "objet_color_stone"
  | "objet_color_manhattan_midnight"
  | "objet_color_brown"
  | "objet_color_metallic_charcoal"

  //로딩
  | "road_home"
  | "road_objet"
  | "road_thinq"
  | "road_wait";

export type LangDataType = {
  langType: LangType;
  label: LangLabelType; //검색용 키워드
  text: React.ReactNode | string; //실제 다국어 데이터
};

const langData: LangDataType[] = [
  // EN
  {
    langType: "en",
    label: "select_interior",
    text: `Select Interior`,
  },
  {
    langType: "en",
    label: "category_dishwasher",
    text: `Dishwasher`,
  },
  {
    langType: "en",
    label: "category_refrigerator",
    text: `Refrigerator`,
  },
  {
    langType: "en",
    label: "category_washer_n_dryer",
    text: `Washer & Dryer`,
  },
  {
    langType: "en",
    label: "category_air_care",
    text: `Air Care`,
  },
  {
    langType: "en",
    label: "category_air_conditioner",
    text: `Air Conditioner`,
  },
  {
    langType: "en",
    label: "category_cooking",
    text: `Oven`,
  },
  {
    langType: "en",
    label: "category_homebrew",
    text: `HomeBrew`,
  },
  {
    langType: "en",
    label: "category_microwave_oven",
    text: `Microwave Oven`,
  },
  {
    langType: "en",
    label: "category_styler",
    text: `Styler`,
  },
  {
    langType: "en",
    label: "category_tiiun",
    text: `Indoor\nGardening`,
  },
  {
    langType: "en",
    label: "category_vacuum_cleaner",
    text: `Vacuum Cleaner`,
  },
  {
    langType: "en",
    label: "category_water_purifier",
    text: `Water Purifier`,
  },
  {
    langType: "en",
    label: "category_wearable_air_purifier",
    text: `Wearable Air Purifier`,
  },
  //로딩
  {
    langType: "en",
    label: "road_home",
    text: "Experience various products and its innovative functions.",
  },
  {
    langType: "en",
    label: "road_objet",
    text: "Create your own collection by applying various colors and materials.",
  },
  {
    langType: "en",
    label: "road_thinq",
    text: "Experience various Smart Functions customized for you.",
  },
  {
    langType: "en",
    label: "road_wait",
    text: "Please wait a moment. We are preparing Virtual Exhibition for you.",
  },
  //공통
  {
    langType: "en",
    label: "main_back",
    text: "BACK TO PREVIOUS",
  },
  {
    langType: "en",
    label: "main_main",
    text: "MAIN",
  },
  {
    langType: "en",
    label: "guide_pre",
    text: "PREVIOUS",
  },
  {
    langType: "en",
    label: "guide_next",
    text: "NEXT",
  },
  {
    langType: "en",
    label: "guide_info",
    text: "Info",
  },
  {
    langType: "en",
    label: "guide_usp",
    text: "USP",
  },
  {
    langType: "en",
    label: "guide_open",
    text: "Open",
  },
  {
    langType: "en",
    label: "guide_ar",
    text: "AR",
  },
  {
    langType: "en",
    label: "guide_back",
    text: "Go to previous page",
  },
  {
    langType: "en",
    label: "guide_main",
    text: "Go to main page",
  },
  {
    langType: "en",
    label: "guide_move",
    text: "WASD Move",
  },
  {
    langType: "en",
    label: "guide_rotate",
    text: "Click to Rotate",
  },
  {
    langType: "en",
    label: "guide_hide",
    text: "Hide all the button",
  },
  {
    langType: "en",
    label: "guide_off",
    text: "Turn on & off the BGM",
  },
  {
    langType: "en",
    label: "guide_guide",
    text: "Check out the user guide",
  },
  {
    langType: "en",
    label: "guide_close",
    text: "CLOSE",
  },
  {
    langType: "en",
    label: "guide_touch",
    text: "Touch",
  },
  {
    langType: "en",
    label: "guide_zoom",
    text: "Zoom",
  },
  {
    langType: "en",
    label: "guide_swipe",
    text: "Swipe",
  },
  {
    langType: "en",
    label: "main_hide",
    text: "Hide UI",
  },
  {
    langType: "en",
    label: "main_off",
    text: "Off",
  },
  {
    langType: "en",
    label: "main_on",
    text: "On",
  },
  {
    langType: "en",
    label: "main_guide",
    text: "Guide",
  },
  {
    langType: "en",
    label: "close",
    text: "CLOSE",
  },
  {
    langType: "en",
    label: "discover",
    text: "Discover",
  },
  // HOME
  {
    langType: "en",
    label: "home_main_click",
    text: `Please click on the product you're curious about.`, //you're
  },
  {
    langType: "en",
    label: "home_main_select",
    text: "Select Products",
  },
  {
    langType: "en",
    label: "home_guide_title",
    text: "LG Home User Guide",
  },
  {
    langType: "en",
    label: "home_guide_info",
    text: "Info",
  },
  {
    langType: "en",
    label: "home_guide_usp",
    text: "USP",
  },
  {
    langType: "en",
    label: "home_guide_open",
    text: "Open",
  },
  {
    langType: "en",
    label: "home_guide_ar",
    text: "AR",
  },
  {
    langType: "en",
    label: "home_guide_info_desc",
    text: "Check out specification",
  },
  {
    langType: "en",
    label: "home_guide_usp_desc",
    text: "Check out key features",
  },
  {
    langType: "en",
    label: "home_guide_oepn_desc",
    text: "See inside",
  },
  {
    langType: "en",
    label: "home_guide_ar_desc",
    text: "Experience AR",
  },
  {
    langType: "en",
    label: "home_guide_interior",
    text: "Change interior colors",
  },
  {
    langType: "en",
    label: "home_guide_product",
    text: "Change product",
  },
  {
    langType: "en",
    label: "home_guide_color",
    text: "Change product color",
  },
  {
    langType: "en",
    label: "home_guide_touch",
    text: "Touch",
  },
  {
    langType: "en",
    label: "home_guide_zoom",
    text: "Zoom",
  },
  {
    langType: "en",
    label: "home_guide_swipe",
    text: "Swipe",
  },
  {
    langType: "en",
    label: "home_exp_interior",
    text: "Select Interior",
  },
  {
    langType: "en",
    label: "home_exp_product",
    text: "Select Product",
  },
  {
    langType: "en",
    label: "home_exp_color",
    text: "Select Product Color",
  },
  {
    langType: "en",
    label: "home_exp_select_interior",
    text: (
      <>
        Select
        <br />
        Interior
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_select_product",
    text: (
      <>
        Select
        <br />
        Product
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_select_color",
    text: (
      <>
        Select
        <br />
        Product
        <br />
        Color
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_interior_warm",
    text: "Warm",
  },
  {
    langType: "en",
    label: "home_exp_interior_vivid",
    text: "Vivid",
  },
  {
    langType: "en",
    label: "home_exp_interior_dark",
    text: "Dark",
  },
  {
    langType: "en",
    label: "home_exp_interior_wood",
    text: "Wood",
  },
  {
    langType: "en",
    label: "home_exp_interior_marble",
    text: "Marble",
  },
  {
    langType: "en",
    label: "home_exp_interior_white",
    text: "White",
  },
  //HOME COLOR
  {
    langType: "en",
    label: "home_exp_color_steel",
    text: (
      <>
        Stainless
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_matteblack",
    text: (
      <>
        Matte
        <br />
        Black
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_printproofsteel",
    text: (
      <>
        PrintProof
        <br />
        Stainless Steel
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_printproof",
    text: (
      <>
        PrintProof
        <br />
        Stainless
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_blacksteel",
    text: (
      <>
        Black
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_white",
    text: "White",
  },
  {
    langType: "en",
    label: "home_exp_color_navy",
    text: "Navy",
  },
  {
    langType: "en",
    label: "home_exp_color_mirror",
    text: "Mirror",
  },
  {
    langType: "en",
    label: "home_exp_color_brown",
    text: "Brown",
  },
  {
    langType: "en",
    label: "home_exp_color_white",
    text: "White",
  },
  {
    langType: "en",
    label: "home_exp_color_grey",
    text: (
      <>
        Iron
        <br />
        Grey
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_red",
    text: (
      <>
        Bohemian
        <br />
        Red
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_fantasy",
    text: (
      <>
        Fantasy
        <br />
        Silver
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_beige",
    text: "Beige",
  },
  {
    langType: "en",
    label: "home_exp_color_green",
    text: "Green",
  },
  {
    langType: "en",
    label: "home_exp_color_silver",
    text: "Silver",
  },
  {
    langType: "en",
    label: "home_exp_color_black",
    text: "Black",
  },
  {
    langType: "en",
    label: "home_exp_color_blackstainsteel",
    text: (
      <>
        Black
        <br />
        Stainless Steel
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_blackstain",
    text: (
      <>
        Black
        <br />
        Stainless
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_wine",
    text: (
      <>
        Vintage
        <br />
        Wine
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_full",
    text: (
      <>
        Full
        <br />
        Black
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_pink",
    text: (
      <>
        Blossom
        <br />
        Pink
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_rose_gold",
    text: (
      <>
        Rose
        <br />
        Gold
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_dark",
    text: (
      <>
        Dark
        <br />
        Blue
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_black_pcm",
    text: (
      <>
        Black Steel
        <br />/ PCM
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_white_pcm",
    text: (
      <>
        White
        <br />/ PCM
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_graphite",
    text: (
      <>
        Graphite
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_color_deep_iron",
    text: "Deep Grey&Iron Grey",
  },
  {
    langType: "en",
    label: "home_exp_color_gold",
    text: "Gold",
  },
  {
    langType: "en",
    label: "home_exp_color_mint",
    text: "Mint",
  },
  {
    langType: "en",
    label: "home_exp_color_mango",
    text: "Mango",
  },
  {
    langType: "en",
    label: "home_exp_color_lavender",
    text: "Lavender",
  },
  {
    langType: "en",
    label: "home_exp_color_nature_green",
    text: (
      <>
        Nature
        <br />
        Green
      </>
    ),
  },

  //
  {
    langType: "en",
    label: "home_exp_open_close",
    text: "CLOSE",
  },
  {
    langType: "en",
    label: "home_exp_ar_title",
    text: "AR Experience",
  },
  {
    langType: "en",
    label: "home_exp_ar_info",
    text: "See what LG refrigerator will look like in your space without app download",
  },
  {
    langType: "en",
    label: "home_main_choose",
    text: "Choose your product.",
  },
  // AR
  {
    langType: "en",
    label: "home_exp_ar_puricare_pet",
    text: (
      <>
        See what Air Purifier will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_dualcool",
    text: (
      <>
        See what Air Conditioner will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_lupin_slide",
    text: (
      <>
        See what Oven will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_xd3",
    text: (
      <>
        See what Dishwasher will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_homebrew",
    text: (
      <>
        See what Homebrew will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_ref_01",
    text: (
      <>
        See what LG refrigerator will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_vs4",
    text: (
      <>
        See what LG refrigerator will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_essence",
    text: (
      <>
        See what Styler will look
        <br />
        like in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_victor2r",
    text: (
      <>
        See what Washer & Dryer will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_td_washer",
    text: (
      <>
        See what Washer & Dryer will look like in your space without app
        download
      </>
    ),
  },
  {
    langType: "en",
    label: "home_exp_ar_wearable",
    text: (
      <>
        See what Wearable Air Purifier will look like in your space without app
        download
      </>
    ),
  },
  // OBJET
  {
    langType: "en",
    label: "objet_main_click",
    text: "Change the interior and product",
  },
  {
    langType: "en",
    label: "objet_guide_title",
    text: "LG Objet Collection User Guide",
  },
  {
    langType: "en",
    label: "objet_guide_step1",
    text: "Step 1",
  },
  {
    langType: "en",
    label: "objet_guide_step1_desc",
    text: "Select product",
  },
  {
    langType: "en",
    label: "objet_guide_step2",
    text: "Step 2",
  },
  {
    langType: "en",
    label: "objet_guide_step2_desc",
    text: "Description of meterial",
  },
  {
    langType: "en",
    label: "objet_guide_step3",
    text: "Step 3",
  },
  {
    langType: "en",
    label: "objet_guide_step3_desc",
    text: "Select the product meterial and color",
  },
  {
    langType: "en",
    label: "objet_guide_wall",
    text: "Change wall material",
  },
  {
    langType: "en",
    label: "objet_guide_interior",
    text: "Change interior concept",
  },
  {
    langType: "en",
    label: "objet_guide_floor",
    text: "Change floor material",
  },
  {
    langType: "en",
    label: "objet_main_title",
    text: "Change the interior and product",
  },
  {
    langType: "en",
    label: "objet_main_select",
    text: "Select Material & Color",
  },
  {
    langType: "en",
    label: "objet_main_select_material_n_color",
    text: (
      <>
        Select Material
        <br />&<br />
        Color
      </>
    ),
  },
  {
    langType: "en",
    label: "objet_main_floor_blossom",
    text: "Blossom",
  },
  {
    langType: "en",
    label: "objet_main_floor_natural",
    text: "Natural",
  },
  {
    langType: "en",
    label: "objet_main_floor_modern",
    text: "Modern",
  },
  {
    langType: "en",
    label: "objet_main_choose",
    text: "Choose your product.",
  },
  {
    langType: "en",
    label: "objet_main_wall_concrete",
    text: "Concete",
  },
  {
    langType: "en",
    label: "objet_main_wall_wood",
    text: "Wood",
  },
  {
    langType: "en",
    label: "objet_main_wall_brick",
    text: "Stone Bricks",
  },
  {
    langType: "en",
    label: "objet_main_floor_wood",
    text: "Wood",
  },
  {
    langType: "en",
    label: "objet_main_floor_herringbone",
    text: "Herringbone",
  },
  {
    langType: "en",
    label: "objet_main_floor_fabric",
    text: "Fabric",
  },
  {
    langType: "en",
    label: "objet_category_refrigerator",
    text: "Refrigerator",
  },
  {
    langType: "en",
    label: "objet_category_dishwasher",
    text: "Dishwasher",
  },
  {
    langType: "en",
    label: "objet_category_water_purifier",
    text: "Water Purifier",
  },
  {
    langType: "en",
    label: "objet_category_water_purifier_undersink",
    text: "Water Purifier",
  },
  {
    langType: "en",
    label: "objet_category_washer",
    text: "Washer",
  },
  {
    langType: "en",
    label: "objet_category_dryer",
    text: "Dryer",
  },
  {
    langType: "en",
    label: "objet_category_washer_n_dryer",
    text: "Washer & Dryer",
  },
  {
    langType: "en",
    label: "objet_category_styler",
    text: "Styler",
  },
  {
    langType: "en",
    label: "objet_category_vacuum_cleaner_stand",
    text: "Vacuum Cleaner",
  },
  {
    langType: "en",
    label: "objet_category_vacuum_cleaner_robot",
    text: "Vacuum Cleaner",
  },
  {
    langType: "en",
    label: "objet_category_vacuum_cleaner",
    text: "Vacuum Cleaner",
  },
  {
    langType: "en",
    label: "objet_category_microwave_oven",
    text: "Microwave Oven",
  },
  {
    langType: "en",
    label: "objet_category_air_conditioner_stand",
    text: "Air Conditioner",
  },
  {
    langType: "en",
    label: "objet_category_air_conditioner_wall",
    text: "Air Conditioner",
  },
  {
    langType: "en",
    label: "objet_category_air_conditioner",
    text: "Air Conditioner",
  },
  {
    langType: "en",
    label: "objet_category_air_care_aero",
    text: "Air Care",
  },
  {
    langType: "en",
    label: "objet_category_air_care",
    text: "Air Care",
  },
  {
    langType: "en",
    label: "objet_category_tiiun",
    text: "Indoor Gardening",
  },
  {
    langType: "en",
    label: "objet_category_lansen_freezer",
    text: "Freeze & Freezer",
  },
  {
    langType: "en",
    label: "objet_material_fenix",
    text: "Fenix",
  },
  {
    langType: "en",
    label: "objet_material_fenix_title",
    text: "",
  },
  {
    langType: "en",
    label: "objet_material_fenix_info",
    text: "Premium material which allows you experience its self-restoration ability and a touch of softness on the fingertips.",
  },
  {
    langType: "en",
    label: "objet_material_solid",
    text: "Solid",
  },
  {
    langType: "en",
    label: "objet_material_solid_title",
    text: "",
  },
  {
    langType: "en",
    label: "objet_material_solid_info",
    text: `Perfect Matt Finish which bears the metal's elegance and its trendy mood. Matt Finish Stainless creates the beauty and modenity of the space.`,
  },
  {
    langType: "en",
    label: "objet_material_mist",
    text: "Mist",
  },
  {
    langType: "en",
    label: "objet_material_mist_title",
    text: "",
  },
  {
    langType: "en",
    label: "objet_material_mist_info",
    text: "Matt finish Glass which harmonize the space with its mist like softness.",
  },
  {
    langType: "en",
    label: "objet_material_nature",
    text: "Nature",
  },
  {
    langType: "en",
    label: "objet_material_nature_title",
    text: "",
  },
  {
    langType: "en",
    label: "objet_material_nature_info",
    text: "Metal Texture modeled after nature gives a sense of comfort wherever it is placed.",
  },
  {
    langType: "en",
    label: "objet_material_calming",
    text: "Calming",
  },
  {
    langType: "en",
    label: "objet_material_calming_title",
    text: "",
  },
  {
    langType: "en",
    label: "objet_material_calming_info",
    text: "Light, Durable, Transparent and Smooth characteristics of material create a calm and cleanly organized space.",
  },
  {
    langType: "en",
    label: "objet_material_glass",
    text: "Glass",
  },
  {
    langType: "en",
    label: "objet_material_glass_info",
    text: "Scratch-resistant tempered glass door.",
  },
  {
    langType: "en",
    label: "objet_color_botanic",
    text: "Botanic",
  },
  {
    langType: "en",
    label: "objet_color_sand",
    text: "Sand",
  },
  {
    langType: "en",
    label: "objet_color_brick",
    text: "Stone Bricks",
  },
  {
    langType: "en",
    label: "objet_color_midnight",
    text: "Manhattan Midnight",
  },
  {
    langType: "en",
    label: "objet_color_silver",
    text: "Silver",
  },
  {
    langType: "en",
    label: "objet_color_green",
    text: "Green",
  },
  {
    langType: "en",
    label: "objet_color_beige",
    text: "Beige",
  },
  {
    langType: "en",
    label: "objet_color_pink",
    text: "Pink",
  },
  {
    langType: "en",
    label: "objet_color_mint",
    text: "Mint",
  },
  {
    langType: "en",
    label: "objet_color_silver",
    text: "Silver",
  },
  {
    langType: "en",
    label: "objet_color_redwood",
    text: "RedWood",
  },
  {
    langType: "en",
    label: "objet_color_clay_brown",
    text: "Clay Brown",
  },
  {
    langType: "en",
    label: "objet_color_gray",
    text: "Gray",
  },
  {
    langType: "en",
    label: "objet_color_black",
    text: "Black",
  },
  {
    langType: "en",
    label: "objet_color_white",
    text: "White",
  },
  {
    langType: "en",
    label: "objet_color_dark_green",
    text: "Dark Green",
  },
  {
    langType: "en",
    label: "objet_color_cream_white",
    text: "Cream White",
  },
  {
    langType: "en",
    label: "objet_color_red",
    text: "Red",
  },
  {
    langType: "en",
    label: "objet_color_cotton_sky",
    text: "Cotton Sky",
  },
  {
    langType: "en",
    label: "objet_color_pale_rose",
    text: "Pale Rose",
  },
  {
    langType: "en",
    label: "objet_color_linen_black",
    text: "Linen Black",
  },
  {
    langType: "en",
    label: "objet_color_espresso",
    text: "Espresso",
  },
  {
    langType: "en",
    label: "objet_color_ivory",
    text: "Ivory",
  },
  {
    langType: "en",
    label: "objet_color_charcoal",
    text: "Metallic Charcoal",
  },
  {
    langType: "en",
    label: "objet_color_charcoal_grey",
    text: "Charcoal Grey",
  },
  {
    langType: "en",
    label: "objet_color_mirror",
    text: "Mirror",
  },
  {
    langType: "en",
    label: "objet_color_black_mirror",
    text: "Black Mirror",
  },
  {
    langType: "en",
    label: "objet_color_stone",
    text: "Stone",
  },
  {
    langType: "en",
    label: "objet_color_manhattan_midnight",
    text: (
      <>
        Manhattan
        <br />
        Midnight
      </>
    ),
  },
  {
    langType: "en",
    label: "objet_color_redwood",
    text: "RedWood",
  },
  {
    langType: "en",
    label: "objet_color_brown",
    text: "Brown",
  },
  //
  // KR
  {
    langType: "kr",
    label: "select_interior",
    text: `인테리어를 선택하세요`,
  },
  {
    langType: "kr",
    label: "category_dishwasher",
    text: `식기세척기`,
  },
  {
    langType: "kr",
    label: "category_refrigerator",
    text: `냉장고`,
  },
  {
    langType: "kr",
    label: "category_washer_n_dryer",
    text: `워시타워`,
  },
  {
    langType: "kr",
    label: "category_air_care",
    text: `공기청정기`,
  },
  {
    langType: "kr",
    label: "category_air_conditioner",
    text: `에어컨`,
  },
  {
    langType: "kr",
    label: "category_cooking",
    text: `오븐`,
  },
  {
    langType: "kr",
    label: "category_homebrew",
    text: `홈브루`,
  },
  {
    langType: "kr",
    label: "category_microwave_oven",
    text: `전자레인지`,
  },
  {
    langType: "kr",
    label: "category_styler",
    text: `스타일러`,
  },
  {
    langType: "kr",
    label: "category_tiiun",
    text: `식물재배기`,
  },
  {
    langType: "kr",
    label: "category_vacuum_cleaner",
    text: `청소기`,
  },
  {
    langType: "kr",
    label: "category_water_purifier",
    text: `정수기`,
  },
  {
    langType: "kr",
    label: "category_wearable_air_purifier",
    text: `마스크`,
  },
  //로딩
  {
    langType: "kr",
    label: "road_home",
    text: "다양한 소재와 색상을 입혀 나만의 오브제 컬렉션을 만들어보세요.",
  },
  {
    langType: "kr",
    label: "road_objet",
    text: "다양한 색상과 소재를 적용하여 나만의 컬렉션을 만들어보세요.",
  },
  {
    langType: "kr",
    label: "road_thinq",
    text: "나만의 다양한 스마트 기능을 경험해보세요.",
  },
  {
    langType: "kr",
    label: "road_wait",
    text: "잠시만 기다려주세요 당신을 위한 가상 전시를 준비하고 있습니다.",
  },
  //공통
  {
    langType: "kr",
    label: "main_back",
    text: "돌아가기",
  },
  {
    langType: "kr",
    label: "main_main",
    text: "메인으로",
  },
  {
    langType: "kr",
    label: "guide_pre",
    text: "앞으로",
  },
  {
    langType: "kr",
    label: "guide_next",
    text: "NEXT",
  },
  {
    langType: "kr",
    label: "guide_info",
    text: "Info",
  },
  {
    langType: "kr",
    label: "guide_usp",
    text: "USP",
  },
  {
    langType: "kr",
    label: "guide_open",
    text: "Open",
  },
  {
    langType: "kr",
    label: "guide_ar",
    text: "AR",
  },
  {
    langType: "kr",
    label: "guide_back",
    text: "이전 페이지로",
  },
  {
    langType: "kr",
    label: "guide_main",
    text: "메인 페이지로",
  },
  {
    langType: "kr",
    label: "guide_move",
    text: "WASD로 이동하기",
  },
  {
    langType: "kr",
    label: "guide_rotate",
    text: "드래그하여 회전하기",
  },
  {
    langType: "kr",
    label: "guide_hide",
    text: "모든 버튼 숨기기",
  },
  {
    langType: "kr",
    label: "guide_off",
    text: "BGM을 끄고 켜기",
  },
  {
    langType: "kr",
    label: "guide_guide",
    text: "유저 가이드 확인하기",
  },
  {
    langType: "kr",
    label: "guide_close",
    text: "닫기",
  },
  {
    langType: "kr",
    label: "guide_touch",
    text: "터치",
  },
  {
    langType: "kr",
    label: "guide_zoom",
    text: "줌",
  },
  {
    langType: "kr",
    label: "guide_swipe",
    text: "스와이프",
  },
  {
    langType: "kr",
    label: "main_hide",
    text: "UI 감추기",
  },
  {
    langType: "kr",
    label: "main_off",
    text: "Off",
  },
  {
    langType: "kr",
    label: "main_on",
    text: "On",
  },
  {
    langType: "kr",
    label: "main_guide",
    text: "가이드",
  },
  {
    langType: "kr",
    label: "close",
    text: "닫기",
  },
  {
    langType: "kr",
    label: "discover",
    text: "Discover",
  },
  // HOME
  {
    langType: "kr",
    label: "home_main_click",
    text: "궁금하신 제품을 클릭해 주세요.",
  },
  {
    langType: "kr",
    label: "home_main_select",
    text: "제품 선택",
  },
  {
    langType: "kr",
    label: "home_guide_title",
    text: "LG Home 유저 가이드",
  },
  {
    langType: "kr",
    label: "home_guide_info",
    text: "Info",
  },
  {
    langType: "kr",
    label: "home_guide_usp",
    text: "USP",
  },
  {
    langType: "kr",
    label: "home_guide_open",
    text: "Open",
  },
  {
    langType: "kr",
    label: "home_guide_ar",
    text: "AR",
  },
  {
    langType: "kr",
    label: "home_guide_info_desc",
    text: "정보보기",
  },
  {
    langType: "kr",
    label: "home_guide_usp_desc",
    text: "핵심 기능 확인하기",
  },
  {
    langType: "kr",
    label: "home_guide_oepn_desc",
    text: "안쪽 보기",
  },
  {
    langType: "kr",
    label: "home_guide_ar_desc",
    text: "AR 체험하기",
  },
  {
    langType: "kr",
    label: "home_guide_interior",
    text: "인테리어 변경하기",
  },
  {
    langType: "kr",
    label: "home_guide_product",
    text: "제품 바꾸기",
  },
  {
    langType: "kr",
    label: "home_guide_color",
    text: "제품 색상 바꾸기",
  },
  {
    langType: "kr",
    label: "home_guide_touch",
    text: "터치",
  },
  {
    langType: "kr",
    label: "home_guide_zoom",
    text: "줌",
  },
  {
    langType: "kr",
    label: "home_guide_swipe",
    text: "스와이프",
  },
  {
    langType: "kr",
    label: "home_exp_interior",
    text: "인테리어 선택",
  },
  {
    langType: "kr",
    label: "home_exp_product",
    text: "제품 선택",
  },
  {
    langType: "kr",
    label: "home_exp_color",
    text: "제품 색상 선택",
  },
  {
    langType: "kr",
    label: "home_exp_select_interior",
    text: (
      <>
        인테리어
        <br />
        선택
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_select_product",
    text: (
      <>
        제품
        <br />
        선택
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_select_color",
    text: (
      <>
        제품
        <br />
        색상
        <br />
        선택
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_interior_warm",
    text: "Warm",
  },
  {
    langType: "kr",
    label: "home_exp_interior_vivid",
    text: "Vivid",
  },
  {
    langType: "kr",
    label: "home_exp_interior_dark",
    text: "Dark",
  },
  {
    langType: "kr",
    label: "home_exp_interior_wood",
    text: "Wood",
  },
  {
    langType: "kr",
    label: "home_exp_interior_marble",
    text: "Marble",
  },
  {
    langType: "kr",
    label: "home_exp_interior_white",
    text: "White",
  },
  //HOME COLOR
  {
    langType: "kr",
    label: "home_exp_color_steel",
    text: (
      <>
        Stainless
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_matteblack",
    text: (
      <>
        Matte
        <br />
        Black
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_printproofsteel",
    text: (
      <>
        PrintProof
        <br />
        Stainless Steel
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_printproof",
    text: (
      <>
        PrintProof
        <br />
        Stainless
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_blacksteel",
    text: (
      <>
        Black
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_white",
    text: "White",
  },
  {
    langType: "kr",
    label: "home_exp_color_navy",
    text: "Navy",
  },
  {
    langType: "kr",
    label: "home_exp_color_mirror",
    text: "Mirror",
  },
  {
    langType: "kr",
    label: "home_exp_color_brown",
    text: "Brown",
  },
  {
    langType: "kr",
    label: "home_exp_color_white",
    text: "White",
  },
  {
    langType: "kr",
    label: "home_exp_color_grey",
    text: (
      <>
        Iron
        <br />
        Grey
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_red",
    text: (
      <>
        Bohemian
        <br />
        Red
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_fantasy",
    text: (
      <>
        Fantasy
        <br />
        Silver
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_beige",
    text: "Beige",
  },
  {
    langType: "kr",
    label: "home_exp_color_green",
    text: "Green",
  },
  {
    langType: "kr",
    label: "home_exp_color_silver",
    text: "Silver",
  },
  {
    langType: "kr",
    label: "home_exp_color_black",
    text: "Black",
  },
  {
    langType: "kr",
    label: "home_exp_color_blackstainsteel",
    text: (
      <>
        Black
        <br />
        Stainless Steel
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_blackstain",
    text: (
      <>
        Black
        <br />
        Stainless
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_wine",
    text: (
      <>
        Vintage
        <br />
        Wine
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_full",
    text: (
      <>
        Full
        <br />
        Black
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_pink",
    text: (
      <>
        Blossom
        <br />
        Pink
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_rose_gold",
    text: (
      <>
        Rose
        <br />
        Gold
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_dark",
    text: (
      <>
        Dark
        <br />
        Blue
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_black_pcm",
    text: (
      <>
        Black Steel
        <br />/ PCM
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_white_pcm",
    text: (
      <>
        White
        <br />/ PCM
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_graphite",
    text: (
      <>
        Graphite
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_color_deep_iron",
    text: "Deep Grey&Iron Grey",
  },
  {
    langType: "kr",
    label: "home_exp_color_gold",
    text: "Gold",
  },
  {
    langType: "kr",
    label: "home_exp_color_mint",
    text: "Mint",
  },
  {
    langType: "kr",
    label: "home_exp_color_mango",
    text: "Mango",
  },
  {
    langType: "kr",
    label: "home_exp_color_lavender",
    text: "Lavender",
  },
  {
    langType: "kr",
    label: "home_exp_color_nature_green",
    text: (
      <>
        Nature
        <br />
        Green
      </>
    ),
  },

  //
  {
    langType: "kr",
    label: "home_exp_open_close",
    text: "닫기",
  },
  {
    langType: "kr",
    label: "home_exp_ar_title",
    text: "AR 체험하기",
  },
  {
    langType: "kr",
    label: "home_exp_ar_info",
    text: "앱을 다운로드하지 않고 LG 냉장고가 여러분의 공간에서 어떤 모습을 보여줄지 확인하세요.",
  },
  {
    langType: "kr",
    label: "home_main_choose",
    text: "제품을 선택하세요.",
  },
  // AR
  {
    langType: "kr",
    label: "home_exp_ar_puricare_pet",
    text: (
      <>
        See what Air Purifier will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_dualcool",
    text: (
      <>
        See what Air Conditioner will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_lupin_slide",
    text: (
      <>
        See what Oven will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_xd3",
    text: (
      <>
        See what Dishwasher will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_homebrew",
    text: (
      <>
        See what Homebrew will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_ref_01",
    text: (
      <>
        See what LG refrigerator will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_vs4",
    text: (
      <>
        See what LG refrigerator will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_essence",
    text: (
      <>
        See what Styler will look
        <br />
        like in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_victor2r",
    text: (
      <>
        See what Washer & Dryer will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_td_washer",
    text: (
      <>
        See what Washer & Dryer will look like in your space without app
        download
      </>
    ),
  },
  {
    langType: "kr",
    label: "home_exp_ar_wearable",
    text: (
      <>
        See what Wearable Air Purifier will look like in your space without app
        download
      </>
    ),
  },
  // OBJET
  {
    langType: "kr",
    label: "objet_main_click",
    text: "Change the interior and product",
  },
  {
    langType: "kr",
    label: "objet_guide_title",
    text: "LG Objet 콜렉션 유저 가이드",
  },
  {
    langType: "kr",
    label: "objet_guide_step1",
    text: "Step 1",
  },
  {
    langType: "kr",
    label: "objet_guide_step1_desc",
    text: "제품선택",
  },
  {
    langType: "kr",
    label: "objet_guide_step2",
    text: "Step 2",
  },
  {
    langType: "kr",
    label: "objet_guide_step2_desc",
    text: "소재에 대한 설명",
  },
  {
    langType: "kr",
    label: "objet_guide_step3",
    text: "Step 3",
  },
  {
    langType: "kr",
    label: "objet_guide_step3_desc",
    text: "제품의 소재와 컬러를 선택",
  },
  {
    langType: "kr",
    label: "objet_guide_wall",
    text: "벽 재질 변경",
  },
  {
    langType: "kr",
    label: "objet_guide_interior",
    text: "인테리어 콘셉트 변경",
  },
  {
    langType: "kr",
    label: "objet_guide_floor",
    text: "바닥 재질 변경",
  },
  {
    langType: "kr",
    label: "objet_main_title",
    text: "인테리어와 제품 변경",
  },
  {
    langType: "kr",
    label: "objet_main_select",
    text: "재질과 색상 선택하기",
  },
  {
    langType: "kr",
    label: "objet_main_select_material_n_color",
    text: (
      <>
        재질과
        <br />
        색상
        <br />
        선택하기
      </>
    ),
  },
  {
    langType: "kr",
    label: "objet_main_floor_blossom",
    text: "Blossom",
  },
  {
    langType: "kr",
    label: "objet_main_floor_natural",
    text: "Natural",
  },
  {
    langType: "kr",
    label: "objet_main_floor_modern",
    text: "Modern",
  },
  {
    langType: "kr",
    label: "objet_main_choose",
    text: (
      <>
        당신의 제품을 선택하고
        <br />
        자신만의
        <br />
        LG 가구 콘셉트 어플라이언스를
        <br />
        만드세요.
      </>
    ),
  },
  {
    langType: "kr",
    label: "objet_main_wall_concrete",
    text: "Concete",
  },
  {
    langType: "kr",
    label: "objet_main_wall_wood",
    text: "Wood",
  },
  {
    langType: "kr",
    label: "objet_main_wall_brick",
    text: "Stone Bricks",
  },
  {
    langType: "kr",
    label: "objet_main_floor_wood",
    text: "Wood",
  },
  {
    langType: "kr",
    label: "objet_main_floor_herringbone",
    text: "Herringbone",
  },
  {
    langType: "kr",
    label: "objet_main_floor_fabric",
    text: "Fabric",
  },
  {
    langType: "kr",
    label: "objet_category_refrigerator",
    text: "냉장고",
  },
  {
    langType: "kr",
    label: "objet_category_dishwasher",
    text: "식기세척기",
  },
  {
    langType: "kr",
    label: "objet_category_water_purifier",
    text: "정수기",
  },
  {
    langType: "kr",
    label: "objet_category_water_purifier_undersink",
    text: "정수기",
  },
  {
    langType: "kr",
    label: "objet_category_washer",
    text: "세탁기",
  },
  {
    langType: "kr",
    label: "objet_category_dryer",
    text: "건조기",
  },
  {
    langType: "kr",
    label: "objet_category_washer_n_dryer",
    text: "워시타워",
  },
  {
    langType: "kr",
    label: "objet_category_styler",
    text: "스타일러",
  },
  {
    langType: "kr",
    label: "objet_category_vacuum_cleaner_stand",
    text: "청소기 스탠드",
  },
  {
    langType: "kr",
    label: "objet_category_vacuum_cleaner_robot",
    text: "로봇청소기",
  },
  {
    langType: "kr",
    label: "objet_category_vacuum_cleaner",
    text: "청소기",
  },
  {
    langType: "kr",
    label: "objet_category_microwave_oven",
    text: "전자레인지",
  },
  {
    langType: "kr",
    label: "objet_category_air_conditioner_stand",
    text: "스탠드형 에어컨",
  },
  {
    langType: "kr",
    label: "objet_category_air_conditioner_wall",
    text: "벽걸이 에어컨",
  },
  {
    langType: "kr",
    label: "objet_category_air_care_aero",
    text: "공기청정기 aero",
  },
  {
    langType: "kr",
    label: "objet_category_air_care",
    text: "공기청정기",
  },
  {
    langType: "kr",
    label: "objet_category_tiiun",
    text: "식물재배기",
  },
  {
    langType: "kr",
    label: "objet_category_lansen_freezer",
    text: "lansen 프리저",
  },
  {
    langType: "kr",
    label: "objet_main_freezer",
    text: "프리즈 & 프리저",
  },
  {
    langType: "kr",
    label: "objet_material_fenix",
    text: "Fenix",
  },
  {
    langType: "kr",
    label: "objet_material_fenix_title",
    text: "새로운 소재로 경험하는 완벽함",
  },
  {
    langType: "kr",
    label: "objet_material_fenix_info",
    text: "오랜 연구로 탄생한 매트한 질감의 신개념 소재. 프리미엄 브랜드에서 사용되는 고급 소재로, 미세 생활 스크래치에 강하고 손끝을 스치는 부드러움을 경험할 수 있습니다. 이탈리아 아프라사에서 개발한 수퍼매트신소재입니다.",
  },
  {
    langType: "kr",
    label: "objet_material_solid",
    text: "Solid",
  },
  {
    langType: "kr",
    label: "objet_material_solid_title",
    text: "순수한 스테인리스의 견고함",
  },
  {
    langType: "kr",
    label: "objet_material_solid_info",
    text: "메탈 본연의 세련됨과 트렌드를 담은 무광의 완벽한 조화. 매트한 스테인리스가 모던하면서도 아름다운 공간을 연출합니다.",
  },
  {
    langType: "kr",
    label: "objet_material_mist",
    text: "Mist",
  },
  {
    langType: "kr",
    label: "objet_material_mist_title",
    text: "고운 안개를 닮은 부드러움",
  },
  {
    langType: "kr",
    label: "objet_material_mist_info",
    text: "고운 안개처럼 부드러운 터치감을 느낄 수 있는 매트한 유리소재로, 공간에 은은하게 어우러집니다. 아름다운 질감을 표현하기 위해 수많은 연구를 거쳐 탄생하였습니다.",
  },
  {
    langType: "kr",
    label: "objet_material_nature",
    text: "Nature",
  },
  {
    langType: "kr",
    label: "objet_material_nature_title",
    text: "자연의 감성을 담은 편안함",
  },
  {
    langType: "kr",
    label: "objet_material_nature_info",
    text: "자연을 본뜬 질감의 메탈 소재로 어느 공간에 두어도 차분하고 자연스럽게 잘 어울립니다. 관리가 편하여 오래보아도 가치가 변하지 않습니다.",
  },
  {
    langType: "kr",
    label: "objet_material_calming",
    text: "Calming",
  },
  {
    langType: "kr",
    label: "objet_material_calming_title",
    text: "정돈된 차분함",
  },
  {
    langType: "kr",
    label: "objet_material_calming_info",
    text: "편안하고 포근한 질감으로 안락한 느낌을 선사합니다. 어떤 분위기의 인테리어에도 잘 어울려 공간에 안정감을 더하고 자연스럽게 녹아 듭니다.",
  },
  {
    langType: "kr",
    label: "objet_material_glass",
    text: "Glass",
  },
  {
    langType: "kr",
    label: "objet_color_botanic",
    text: "Botanic",
  },
  {
    langType: "kr",
    label: "objet_color_sand",
    text: "Sand",
  },
  {
    langType: "kr",
    label: "objet_color_brick",
    text: "Stone Bricks",
  },
  {
    langType: "kr",
    label: "objet_color_midnight",
    text: "Manhattan Midnight",
  },
  {
    langType: "kr",
    label: "objet_color_silver",
    text: "Silver",
  },
  {
    langType: "kr",
    label: "objet_color_green",
    text: "Green",
  },
  {
    langType: "kr",
    label: "objet_color_beige",
    text: "Beige",
  },
  {
    langType: "kr",
    label: "objet_color_pink",
    text: "Pink",
  },
  {
    langType: "kr",
    label: "objet_color_mint",
    text: "Mint",
  },
  {
    langType: "kr",
    label: "objet_color_silver",
    text: "Silver",
  },
  {
    langType: "kr",
    label: "objet_color_redwood",
    text: "RedWood",
  },
  {
    langType: "kr",
    label: "objet_color_clay_brown",
    text: "Clay Brown",
  },
  {
    langType: "kr",
    label: "objet_color_gray",
    text: "Gray",
  },
  {
    langType: "kr",
    label: "objet_color_black",
    text: "Black",
  },
  {
    langType: "kr",
    label: "objet_color_white",
    text: "Whire",
  },
  {
    langType: "kr",
    label: "objet_color_dark_green",
    text: "Dark Green",
  },
  {
    langType: "kr",
    label: "objet_color_cream_white",
    text: "Cream White",
  },
  {
    langType: "kr",
    label: "objet_color_red",
    text: "Red",
  },
  {
    langType: "kr",
    label: "objet_color_cotton_sky",
    text: "Cotton Sky",
  },
  {
    langType: "kr",
    label: "objet_color_pale_rose",
    text: "Pale Rose",
  },
  {
    langType: "kr",
    label: "objet_color_linen_black",
    text: "linen Black",
  },
  {
    langType: "kr",
    label: "objet_color_espresso",
    text: "Espresso",
  },
  {
    langType: "kr",
    label: "objet_color_ivory",
    text: "Ivory",
  },
  {
    langType: "kr",
    label: "objet_color_charcoal",
    text: "Metallic Charcoal",
  },
  {
    langType: "kr",
    label: "objet_color_mirror",
    text: "Mirror",
  },
  //
  // ES
  {
    langType: "es",
    label: "select_interior",
    text: `Select Interior`,
  },
  {
    langType: "es",
    label: "category_dishwasher",
    text: `Dishwasher`,
  },
  {
    langType: "es",
    label: "category_refrigerator",
    text: `Refrigerator`,
  },
  {
    langType: "es",
    label: "category_washer_n_dryer",
    text: `Washer & Dryer`,
  },
  {
    langType: "es",
    label: "category_air_care",
    text: `Air Care`,
  },
  {
    langType: "es",
    label: "category_air_conditioner",
    text: `Air Conditioner`,
  },
  {
    langType: "es",
    label: "category_cooking",
    text: `Oven`,
  },
  {
    langType: "es",
    label: "category_homebrew",
    text: `HomeBrew`,
  },
  {
    langType: "es",
    label: "category_microwave_oven",
    text: `Microwave Oven`,
  },
  {
    langType: "es",
    label: "category_styler",
    text: `Styler`,
  },
  {
    langType: "es",
    label: "category_tiiun",
    text: `Indoor\nGardening`,
  },
  {
    langType: "es",
    label: "category_vacuum_cleaner",
    text: `Vacuum Cleaner`,
  },
  {
    langType: "es",
    label: "category_water_purifier",
    text: `Water Purifier`,
  },
  {
    langType: "es",
    label: "category_wearable_air_purifier",
    text: `Wearable Air Purifier`,
  },
  //로딩
  {
    langType: "es",
    label: "road_home",
    text: "Experience various products and its innovative functions.",
  },
  {
    langType: "es",
    label: "road_objet",
    text: "Create your own collection by applying various colors and materials.",
  },
  {
    langType: "es",
    label: "road_thinq",
    text: "Experience various Smart Functions customized for you.",
  },
  {
    langType: "es",
    label: "road_wait",
    text: "Please wait a moment. We are preparing Virtual Exhibition for you.",
  },
  //공통
  {
    langType: "es",
    label: "main_back",
    text: "BACK TO PREVIOUS",
  },
  {
    langType: "es",
    label: "main_main",
    text: "MAIN",
  },
  {
    langType: "es",
    label: "guide_pre",
    text: "PREVIOUS",
  },
  {
    langType: "es",
    label: "guide_next",
    text: "NEXT",
  },
  {
    langType: "es",
    label: "guide_info",
    text: "Info",
  },
  {
    langType: "es",
    label: "guide_usp",
    text: "USP",
  },
  {
    langType: "es",
    label: "guide_open",
    text: "Open",
  },
  {
    langType: "es",
    label: "guide_ar",
    text: "AR",
  },
  {
    langType: "es",
    label: "guide_back",
    text: "Go to previous page",
  },
  {
    langType: "es",
    label: "guide_main",
    text: "Go to main page",
  },
  {
    langType: "es",
    label: "guide_move",
    text: "WASD Move",
  },
  {
    langType: "es",
    label: "guide_rotate",
    text: "Click to Rotate",
  },
  {
    langType: "es",
    label: "guide_hide",
    text: "Hide all the button",
  },
  {
    langType: "es",
    label: "guide_off",
    text: "Turn on & off the BGM",
  },
  {
    langType: "es",
    label: "guide_guide",
    text: "Check out the user guide",
  },
  {
    langType: "es",
    label: "guide_close",
    text: "CLOSE",
  },
  {
    langType: "es",
    label: "guide_touch",
    text: "Touch",
  },
  {
    langType: "es",
    label: "guide_zoom",
    text: "Zoom",
  },
  {
    langType: "es",
    label: "guide_swipe",
    text: "Swipe",
  },
  {
    langType: "es",
    label: "main_hide",
    text: "Hide UI",
  },
  {
    langType: "es",
    label: "main_off",
    text: "Off",
  },
  {
    langType: "es",
    label: "main_on",
    text: "On",
  },
  {
    langType: "es",
    label: "main_guide",
    text: "Guide",
  },
  {
    langType: "es",
    label: "close",
    text: "CLOSE",
  },
  {
    langType: "es",
    label: "discover",
    text: "Discover",
  },
  // HOME
  {
    langType: "es",
    label: "home_main_click",
    text: `Please click on the product you're curious about.`, //you're
  },
  {
    langType: "es",
    label: "home_main_select",
    text: "Select Products",
  },
  {
    langType: "es",
    label: "home_guide_title",
    text: "LG Home User Guide",
  },
  {
    langType: "es",
    label: "home_guide_info",
    text: "Info",
  },
  {
    langType: "es",
    label: "home_guide_usp",
    text: "USP",
  },
  {
    langType: "es",
    label: "home_guide_open",
    text: "Open",
  },
  {
    langType: "es",
    label: "home_guide_ar",
    text: "AR",
  },
  {
    langType: "es",
    label: "home_guide_info_desc",
    text: "Check out specification",
  },
  {
    langType: "es",
    label: "home_guide_usp_desc",
    text: "Check out key features",
  },
  {
    langType: "es",
    label: "home_guide_oepn_desc",
    text: "See inside",
  },
  {
    langType: "es",
    label: "home_guide_ar_desc",
    text: "Experience AR",
  },
  {
    langType: "es",
    label: "home_guide_interior",
    text: "Change interior colors",
  },
  {
    langType: "es",
    label: "home_guide_product",
    text: "Change product",
  },
  {
    langType: "es",
    label: "home_guide_color",
    text: "Change product color",
  },
  {
    langType: "es",
    label: "home_guide_touch",
    text: "Touch",
  },
  {
    langType: "es",
    label: "home_guide_zoom",
    text: "Zoom",
  },
  {
    langType: "es",
    label: "home_guide_swipe",
    text: "Swipe",
  },
  {
    langType: "es",
    label: "home_exp_interior",
    text: "Select Interior",
  },
  {
    langType: "es",
    label: "home_exp_product",
    text: "Select Product",
  },
  {
    langType: "es",
    label: "home_exp_color",
    text: "Select Product Color",
  },
  {
    langType: "es",
    label: "home_exp_select_interior",
    text: (
      <>
        Select
        <br />
        Interior
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_select_product",
    text: (
      <>
        Select
        <br />
        Product
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_select_color",
    text: (
      <>
        Select
        <br />
        Product
        <br />
        Color
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_interior_warm",
    text: "Warm",
  },
  {
    langType: "es",
    label: "home_exp_interior_vivid",
    text: "Vivid",
  },
  {
    langType: "es",
    label: "home_exp_interior_dark",
    text: "Dark",
  },
  {
    langType: "es",
    label: "home_exp_interior_wood",
    text: "Wood",
  },
  {
    langType: "es",
    label: "home_exp_interior_marble",
    text: "Marble",
  },
  {
    langType: "es",
    label: "home_exp_interior_white",
    text: "White",
  },
  //HOME COLOR
  {
    langType: "es",
    label: "home_exp_color_steel",
    text: (
      <>
        Stainless
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_matteblack",
    text: (
      <>
        Matte
        <br />
        Black
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_printproofsteel",
    text: (
      <>
        PrintProof
        <br />
        Stainless Steel
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_printproof",
    text: (
      <>
        PrintProof
        <br />
        Stainless
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_blacksteel",
    text: (
      <>
        Black
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_white",
    text: "White",
  },
  {
    langType: "es",
    label: "home_exp_color_navy",
    text: "Navy",
  },
  {
    langType: "es",
    label: "home_exp_color_mirror",
    text: "Mirror",
  },
  {
    langType: "es",
    label: "home_exp_color_brown",
    text: "Brown",
  },
  {
    langType: "es",
    label: "home_exp_color_white",
    text: "White",
  },
  {
    langType: "es",
    label: "home_exp_color_grey",
    text: (
      <>
        Iron
        <br />
        Grey
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_red",
    text: (
      <>
        Bohemian
        <br />
        Red
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_fantasy",
    text: (
      <>
        Fantasy
        <br />
        Silver
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_beige",
    text: "Beige",
  },
  {
    langType: "es",
    label: "home_exp_color_green",
    text: "Green",
  },
  {
    langType: "es",
    label: "home_exp_color_silver",
    text: "Silver",
  },
  {
    langType: "es",
    label: "home_exp_color_black",
    text: "Black",
  },
  {
    langType: "es",
    label: "home_exp_color_blackstainsteel",
    text: (
      <>
        Black
        <br />
        Stainless Steel
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_blackstain",
    text: (
      <>
        Black
        <br />
        Stainless
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_wine",
    text: (
      <>
        Vintage
        <br />
        Wine
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_full",
    text: (
      <>
        Full
        <br />
        Black
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_pink",
    text: (
      <>
        Blossom
        <br />
        Pink
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_rose_gold",
    text: (
      <>
        Rose
        <br />
        Gold
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_dark",
    text: (
      <>
        Dark
        <br />
        Blue
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_black_pcm",
    text: (
      <>
        Black Steel
        <br />/ PCM
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_white_pcm",
    text: (
      <>
        White
        <br />/ PCM
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_graphite",
    text: (
      <>
        Graphite
        <br />
        Steel
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_color_deep_iron",
    text: "Deep Grey&Iron Grey",
  },
  {
    langType: "es",
    label: "home_exp_color_gold",
    text: "Gold",
  },
  {
    langType: "es",
    label: "home_exp_color_mint",
    text: "Mint",
  },
  {
    langType: "es",
    label: "home_exp_color_mango",
    text: "Mango",
  },
  {
    langType: "es",
    label: "home_exp_color_lavender",
    text: "Lavender",
  },
  {
    langType: "es",
    label: "home_exp_color_nature_green",
    text: (
      <>
        Nature
        <br />
        Green
      </>
    ),
  },

  //
  {
    langType: "es",
    label: "home_exp_open_close",
    text: "CLOSE",
  },
  {
    langType: "es",
    label: "home_exp_ar_title",
    text: "AR Experience",
  },
  {
    langType: "es",
    label: "home_exp_ar_info",
    text: "See what LG refrigerator will look like in your space without app download",
  },
  {
    langType: "es",
    label: "home_main_choose",
    text: "Choose your product.",
  },
  // AR
  {
    langType: "es",
    label: "home_exp_ar_puricare_pet",
    text: (
      <>
        See what Air Purifier will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_dualcool",
    text: (
      <>
        See what Air Conditioner will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_lupin_slide",
    text: (
      <>
        See what Oven will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_xd3",
    text: (
      <>
        See what Dishwasher will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_homebrew",
    text: (
      <>
        See what Homebrew will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_ref_01",
    text: (
      <>
        See what LG refrigerator will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_vs4",
    text: (
      <>
        See what LG refrigerator will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_essence",
    text: (
      <>
        See what Styler will look
        <br />
        like in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_victor2r",
    text: (
      <>
        See what Washer & Dryer will look like
        <br />
        in your space without app download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_td_washer",
    text: (
      <>
        See what Washer & Dryer will look like in your space without app
        download
      </>
    ),
  },
  {
    langType: "es",
    label: "home_exp_ar_wearable",
    text: (
      <>
        See what Wearable Air Purifier will look like in your space without app
        download
      </>
    ),
  },
  // OBJET
  {
    langType: "es",
    label: "objet_main_click",
    text: "Change the interior and product",
  },
  {
    langType: "es",
    label: "objet_guide_title",
    text: "LG Objet Collection User Guide",
  },
  {
    langType: "es",
    label: "objet_guide_step1",
    text: "Step 1",
  },
  {
    langType: "es",
    label: "objet_guide_step1_desc",
    text: "Select product",
  },
  {
    langType: "es",
    label: "objet_guide_step2",
    text: "Step 2",
  },
  {
    langType: "es",
    label: "objet_guide_step2_desc",
    text: "Description of meterial",
  },
  {
    langType: "es",
    label: "objet_guide_step3",
    text: "Step 3",
  },
  {
    langType: "es",
    label: "objet_guide_step3_desc",
    text: "Select the product meterial and color",
  },
  {
    langType: "es",
    label: "objet_guide_wall",
    text: "Change wall material",
  },
  {
    langType: "es",
    label: "objet_guide_interior",
    text: "Change interior concept",
  },
  {
    langType: "es",
    label: "objet_guide_floor",
    text: "Change floor material",
  },
  {
    langType: "es",
    label: "objet_main_title",
    text: "Change the interior and product",
  },
  {
    langType: "es",
    label: "objet_main_select",
    text: "Select Material & Color",
  },
  {
    langType: "es",
    label: "objet_main_select_material_n_color",
    text: (
      <>
        Select Material
        <br />&<br />
        Color
      </>
    ),
  },
  {
    langType: "es",
    label: "objet_main_floor_blossom",
    text: "Blossom",
  },
  {
    langType: "es",
    label: "objet_main_floor_natural",
    text: "Natural",
  },
  {
    langType: "es",
    label: "objet_main_floor_modern",
    text: "Modern",
  },
  {
    langType: "es",
    label: "objet_main_choose",
    text: "Choose your product.",
  },
  {
    langType: "es",
    label: "objet_main_wall_concrete",
    text: "Concete",
  },
  {
    langType: "es",
    label: "objet_main_wall_wood",
    text: "Wood",
  },
  {
    langType: "es",
    label: "objet_main_wall_brick",
    text: "Stone Bricks",
  },
  {
    langType: "es",
    label: "objet_main_floor_wood",
    text: "Wood",
  },
  {
    langType: "es",
    label: "objet_main_floor_herringbone",
    text: "Herringbone",
  },
  {
    langType: "es",
    label: "objet_main_floor_fabric",
    text: "Fabric",
  },
  {
    langType: "es",
    label: "objet_category_refrigerator",
    text: "Refrigerator",
  },
  {
    langType: "es",
    label: "objet_category_dishwasher",
    text: "Dishwasher",
  },
  {
    langType: "es",
    label: "objet_category_water_purifier",
    text: "Water Purifier",
  },
  {
    langType: "es",
    label: "objet_category_water_purifier_undersink",
    text: "Water Purifier",
  },
  {
    langType: "es",
    label: "objet_category_washer",
    text: "Washer",
  },
  {
    langType: "es",
    label: "objet_category_dryer",
    text: "Dryer",
  },
  {
    langType: "es",
    label: "objet_category_washer_n_dryer",
    text: "Washer & Dryer",
  },
  {
    langType: "es",
    label: "objet_category_styler",
    text: "Styler",
  },
  {
    langType: "es",
    label: "objet_category_vacuum_cleaner_stand",
    text: "Vacuum Cleaner",
  },
  {
    langType: "es",
    label: "objet_category_vacuum_cleaner_robot",
    text: "Vacuum Cleaner",
  },
  {
    langType: "es",
    label: "objet_category_vacuum_cleaner",
    text: "Vacuum Cleaner",
  },
  {
    langType: "es",
    label: "objet_category_microwave_oven",
    text: "Microwave Oven",
  },
  {
    langType: "es",
    label: "objet_category_air_conditioner_stand",
    text: "Air Conditioner",
  },
  {
    langType: "es",
    label: "objet_category_air_conditioner_wall",
    text: "Air Conditioner",
  },
  {
    langType: "es",
    label: "objet_category_air_conditioner",
    text: "Air Conditioner",
  },
  {
    langType: "es",
    label: "objet_category_air_care_aero",
    text: "Air Care",
  },
  {
    langType: "es",
    label: "objet_category_air_care",
    text: "Air Care",
  },
  {
    langType: "es",
    label: "objet_category_tiiun",
    text: "Indoor Gardening",
  },
  {
    langType: "es",
    label: "objet_category_lansen_freezer",
    text: "Freeze & Freezer",
  },
  {
    langType: "es",
    label: "objet_material_fenix",
    text: "Fenix",
  },
  {
    langType: "es",
    label: "objet_material_fenix_title",
    text: "",
  },
  {
    langType: "es",
    label: "objet_material_fenix_info",
    text: "Premium material which allows you experience its self-restoration ability and a touch of softness on the fingertips.",
  },
  {
    langType: "es",
    label: "objet_material_solid",
    text: "Solid",
  },
  {
    langType: "es",
    label: "objet_material_solid_title",
    text: "",
  },
  {
    langType: "es",
    label: "objet_material_solid_info",
    text: `Perfect Matt Finish which bears the metal's elegance and its trendy mood. Matt Finish Stainless creates the beauty and modenity of the space.`,
  },
  {
    langType: "es",
    label: "objet_material_mist",
    text: "Mist",
  },
  {
    langType: "es",
    label: "objet_material_mist_title",
    text: "",
  },
  {
    langType: "es",
    label: "objet_material_mist_info",
    text: "Matt finish Glass which harmonize the space with its mist like softness.",
  },
  {
    langType: "es",
    label: "objet_material_nature",
    text: "Nature",
  },
  {
    langType: "es",
    label: "objet_material_nature_title",
    text: "",
  },
  {
    langType: "es",
    label: "objet_material_nature_info",
    text: "Metal Texture modeled after nature gives a sense of comfort wherever it is placed.",
  },
  {
    langType: "es",
    label: "objet_material_calming",
    text: "Calming",
  },
  {
    langType: "es",
    label: "objet_material_calming_title",
    text: "",
  },
  {
    langType: "es",
    label: "objet_material_calming_info",
    text: "Light, Durable, Transparent and Smooth characteristics of material create a calm and cleanly organized space.",
  },
  {
    langType: "es",
    label: "objet_material_glass",
    text: "Glass",
  },
  {
    langType: "es",
    label: "objet_material_glass_info",
    text: "Scratch-resistant tempered glass door.",
  },
  {
    langType: "es",
    label: "objet_color_botanic",
    text: "Botanic",
  },
  {
    langType: "es",
    label: "objet_color_sand",
    text: "Sand",
  },
  {
    langType: "es",
    label: "objet_color_brick",
    text: "Stone Bricks",
  },
  {
    langType: "es",
    label: "objet_color_midnight",
    text: "Manhattan Midnight",
  },
  {
    langType: "es",
    label: "objet_color_silver",
    text: "Silver",
  },
  {
    langType: "es",
    label: "objet_color_green",
    text: "Green",
  },
  {
    langType: "es",
    label: "objet_color_beige",
    text: "Beige",
  },
  {
    langType: "es",
    label: "objet_color_pink",
    text: "Pink",
  },
  {
    langType: "es",
    label: "objet_color_mint",
    text: "Mint",
  },
  {
    langType: "es",
    label: "objet_color_silver",
    text: "Silver",
  },
  {
    langType: "es",
    label: "objet_color_redwood",
    text: "RedWood",
  },
  {
    langType: "es",
    label: "objet_color_clay_brown",
    text: "Clay Brown",
  },
  {
    langType: "es",
    label: "objet_color_gray",
    text: "Gray",
  },
  {
    langType: "es",
    label: "objet_color_black",
    text: "Black",
  },
  {
    langType: "es",
    label: "objet_color_white",
    text: "White",
  },
  {
    langType: "es",
    label: "objet_color_dark_green",
    text: "Dark Green",
  },
  {
    langType: "es",
    label: "objet_color_cream_white",
    text: "Cream White",
  },
  {
    langType: "es",
    label: "objet_color_red",
    text: "Red",
  },
  {
    langType: "es",
    label: "objet_color_cotton_sky",
    text: "Cotton Sky",
  },
  {
    langType: "es",
    label: "objet_color_pale_rose",
    text: "Pale Rose",
  },
  {
    langType: "es",
    label: "objet_color_linen_black",
    text: "Linen Black",
  },
  {
    langType: "es",
    label: "objet_color_espresso",
    text: "Espresso",
  },
  {
    langType: "es",
    label: "objet_color_ivory",
    text: "Ivory",
  },
  {
    langType: "es",
    label: "objet_color_charcoal",
    text: "Metallic Charcoal",
  },
  {
    langType: "es",
    label: "objet_color_charcoal_grey",
    text: "Charcoal Grey",
  },
  {
    langType: "es",
    label: "objet_color_mirror",
    text: "Mirror",
  },
  {
    langType: "es",
    label: "objet_color_black_mirror",
    text: "Black Mirror",
  },
  {
    langType: "es",
    label: "objet_color_stone",
    text: "Stone",
  },
  {
    langType: "es",
    label: "objet_color_manhattan_midnight",
    text: (
      <>
        Manhattan
        <br />
        Midnight
      </>
    ),
  },
  {
    langType: "es",
    label: "objet_color_redwood",
    text: "RedWood",
  },
  {
    langType: "es",
    label: "objet_color_brown",
    text: "Brown",
  },
];

//언어 타입과 키워드 라벨을 넣어서 다국어 텍스트 가져오기
export function getLangString(langType: LangType, label: LangLabelType) {
  const result = langData
    .filter((val) => val.langType === langType)
    .filter((val2) => val2.label === label);
  return result && result.length > 0 ? result[0] : null;
}

export default langData;
