import styled from "styled-components";
import React, {useEffect, useLayoutEffect, useState, useRef} from "react";
import {Html, useCursor} from "@react-three/drei";
import useHistoryStore from "../../stores/historyStore";
import {useSpring, animated} from "@react-spring/web";
import playVideo from "../../utils/playVideo";
import {IS_APPLE_DEVICE, IS_CHROME, ROOT_PATH_PREFIX} from "../common/const";
import {sendGaEvent} from "../../api/ga";
import {useModalVimeo} from "../../stores/modalStore";
import {useSelectMobileQuality} from "../../stores/selectRouteForMobileStore";
import sleep from "../../utils/sleep";

const Main2 = () => {
    const bgRatio = 1920 / 1080;
    const [sizeRatio, setSizeRatio] = useState(0);
    const [top, setTop] = useState(0);
    const [showClick, setShowClick] = useState(false);

    function resizeHandler() {
        const ratio = window.innerWidth / window.innerHeight;
        //가로로 더 길어진 경우, 세로에 맞추고 가로에 여백이 생김
        if (ratio > bgRatio) {
            const sizeH = window.innerHeight;
            const sr = sizeH / 1080;
            setSizeRatio(sr);
            setTop(window.innerHeight * 0.568);
        }
        //세로로 더 길어진 경우, 가로에 맞추고 위아래에 여백이 생김
        else {
            const sizeW = window.innerWidth;
            const sr = sizeW / 1920;
            setSizeRatio(sr);
            setTop(
                (window.innerHeight - window.innerWidth / 1.77777777778) * 0.5 +
                627 * sr
            );
        }
    }

    useLayoutEffect(() => {
        // async function delayAction() {
        //     for (let i = 0; i < 5; i++) {
        //         await sleep(500);
        //         window.dispatchEvent(new Event("resize"));
        //     }
        // }

        window.addEventListener("resize", resizeHandler);
        window.addEventListener('orientationchange', resizeHandler);
        window.dispatchEvent(new Event("resize"));

        // delayAction();

        return () => {
            window.removeEventListener("resize", resizeHandler);
            window.removeEventListener('orientationchange', resizeHandler);
        };
    }, []);

    useEffect(() => {
        setShowClick(true);
        return () => {
            setShowClick(false);
        };
    }, []);

    return (
        <group>
            <Html fullscreen center={false} transform={false}>
                <Wrapper>
                    <Contents sizeRatio={sizeRatio}>
                        {showClick && <Click sizeRatio={sizeRatio}/>}
                        <MainButton sizeRatio={sizeRatio}/>
                        <img
                            alt="title thinq"
                            role="img"
                            src={ROOT_PATH_PREFIX + "/images/main2/title_thinq.png"}
                            style={{
                                position: "absolute",
                                width: 265 * sizeRatio,
                                height: 80 * sizeRatio,
                                left: 164 * sizeRatio,
                                top: 295 * sizeRatio,
                            }}
                        />
                        <img
                            alt="title home"
                            role="img"
                            src={ROOT_PATH_PREFIX + "/images/main2/title_home.png"}
                            style={{
                                position: "absolute",
                                width: 271 * sizeRatio,
                                height: 50 * sizeRatio,
                                left: 824 * sizeRatio,
                                top: 329 * sizeRatio,
                            }}
                        />
                        <img
                            alt="title objet"
                            role="img"
                            src={ROOT_PATH_PREFIX + "/images/main2/title_objet.png"}
                            style={{
                                position: "absolute",
                                width: 445 * sizeRatio,
                                height: 74 * sizeRatio,
                                left: 1426 * sizeRatio,
                                top: 302 * sizeRatio,
                            }}
                        />
                    </Contents>
                    <Floor top={top}/>
                </Wrapper>
            </Html>
        </group>
    );
};

const MainButton = (props) => {
    const {sizeRatio} = props;
    const [hoverd, setHovered] = useState(false);
    const [thinqhoverd, setThinqHovered] = useState(false);
    const [homehoverd, setHomeHovered] = useState(false);
    const [objethoverd, setObjetHovered] = useState(false);
    const pushHistory = useHistoryStore((state) => state.pushHistory);
    useCursor(hoverd);

    const thinqRef = useRef<HTMLVideoElement>(null!);
    const homeRef = useRef<HTMLVideoElement>(null!);
    const objetRef = useRef<HTMLVideoElement>(null!);

    const modalVimeo = useModalVimeo((state) => state.isOpen);
    const setModalVimeo = useModalVimeo((state) => state.setIsOpen);
    const setVimeoId = useModalVimeo((state) => state.setId);
    const setVimeoGaId = useModalVimeo((state) => state.setGaId);
    const setVimeo = (id: string) => {
        setVimeoId(id);
        setModalVimeo(true);
    };

    const isNormalQuailty = useSelectMobileQuality(
        (state) => state.isNormalQuality
    );

    return (
        <>
            <video
                ref={thinqRef}
                style={{
                    position: "absolute",
                    width: 589 * sizeRatio,
                    height: 641 * sizeRatio,
                    left: 58 * sizeRatio,
                    top: 390 * sizeRatio,
                }}
                muted
                poster={ROOT_PATH_PREFIX + "/images/main2/Space_LG_ThinQ_0000.png"}
            >
                <source
                    src={ROOT_PATH_PREFIX + "/images/main2/Space_LG_ThinQ_0000.webm"}
                    type={"video/webm"}
                />
            </video>
            <video
                ref={homeRef}
                style={{
                    position: "absolute",
                    width: 600 * sizeRatio,
                    height: 618 * sizeRatio,
                    left: 660 * sizeRatio,
                    top: 412 * sizeRatio,
                }}
                muted
                poster={ROOT_PATH_PREFIX + "/images/main2/Space_LG_Home_0000.png"}
            >
                <source
                    src={ROOT_PATH_PREFIX + "/images/main2/Space_LG_Home_0000.webm"}
                    type={"video/webm"}
                />
            </video>
            <video
                ref={objetRef}
                style={{
                    position: "absolute",
                    width: 605 * sizeRatio,
                    height: 631 * sizeRatio,
                    left: 1272 * sizeRatio,
                    top: 403 * sizeRatio,
                }}
                muted
                poster={ROOT_PATH_PREFIX + "/images/main2/Space_LG_Objet_0000.png"}
            >
                <source
                    src={ROOT_PATH_PREFIX + "/images/main2/Space_LG_Objet_0000.webm"}
                    type={"video/webm"}
                />
            </video>
            <animated.img
                alt="space lg thinq"
                role="button"
                src={ROOT_PATH_PREFIX + "/images/main/Space_LG_ThinQ_glow.png"}
                style={{
                    opacity: thinqhoverd ? 1 : 0,
                    position: "absolute",
                    width: 623 * sizeRatio,
                    height: 450 * sizeRatio,
                    left: 40 * sizeRatio,
                    top: 374 * sizeRatio,
                    transition: "opacity 1s",
                }}
                onClick={(e) => {
                    pushHistory("/thinq");
                    sendGaEvent("main_lgthinq");
                }}
                onMouseOver={(e) => {
                    const t = thinqRef.current;
                    //MAC OS의 Safari 브라우저에서는 알파채널 webm 재생이 안되어 비디오 재생을 막음
                    if (IS_APPLE_DEVICE && !IS_CHROME) {
                    } else {
                        playVideo(t);
                    }
                    setHovered(true);
                    setThinqHovered(true);
                }}
                onMouseOut={(e) => {
                    const t = thinqRef.current;
                    if (IS_APPLE_DEVICE && !IS_CHROME) {
                    } else {
                        t.pause();
                        t.currentTime = 0;
                    }
                    setHovered(false);
                    setThinqHovered(false);
                }}
            />
            <animated.img
                alt="space lg home"
                role="button"
                src={ROOT_PATH_PREFIX + "/images/main/Space_LG_Home_glow.png"}
                style={{
                    opacity: homehoverd ? 1 : 0,
                    position: "absolute",
                    width: 632 * sizeRatio,
                    height: 340 * sizeRatio,
                    left: 644 * sizeRatio,
                    top: 394 * sizeRatio,
                    transition: "opacity 1s",
                }}
                onClick={(e) => {
                    pushHistory("/home");
                    sendGaEvent("main_lghome");
                }}
                onMouseOver={(e) => {
                    const t = homeRef.current;
                    //MAC OS의 Safari 브라우저에서는 알파채널 webm 재생이 안되어 비디오 재생을 막음
                    if (IS_APPLE_DEVICE && !IS_CHROME) {
                    } else {
                        playVideo(t);
                    }
                    setHovered(true);
                    setHomeHovered(true);
                }}
                onMouseOut={(e) => {
                    const t = homeRef.current;
                    if (IS_APPLE_DEVICE && !IS_CHROME) {
                    } else {
                        t.pause();
                        t.currentTime = 0;
                    }
                    setHovered(false);
                    setHomeHovered(false);
                }}
            />
            <animated.img
                alt="space lg objet"
                role="button"
                src={ROOT_PATH_PREFIX + "/images/main/Space_LG_Object_glow.png"}
                style={{
                    opacity: objethoverd ? 1 : 0,
                    position: "absolute",
                    width: 642 * sizeRatio,
                    height: 438 * sizeRatio,
                    left: 1254 * sizeRatio,
                    top: 385 * sizeRatio,
                    transition: "opacity 1s",
                }}
                onClick={(e) => {
                    sendGaEvent("main_objetcollection");
                    if (isNormalQuailty) {
                        setVimeoGaId("");
                        setVimeo("https://vimeo.com/662025136/18b1f7013f");
                        return;
                    }
                    pushHistory("/objetcollection/natural");
                }}
                onMouseOver={(e) => {
                    const t = objetRef.current;
                    //MAC OS의 Safari 브라우저에서는 알파채널 webm 재생이 안되어 비디오 재생을 막음
                    if (IS_APPLE_DEVICE && !IS_CHROME) {
                    } else {
                        playVideo(t);
                    }
                    setHovered(true);
                    setObjetHovered(true);
                }}
                onMouseOut={(e) => {
                    const t = objetRef.current;
                    if (IS_APPLE_DEVICE && !IS_CHROME) {
                    } else {
                        t.pause();
                        t.currentTime = 0;
                    }
                    setHovered(false);
                    setObjetHovered(false);
                }}
            />
        </>
    );
};

const Click = (props) => {
    const {sizeRatio, left, top} = props;
    const styles = useSpring({
        loop: {reverse: true},
        from: {opacity: 0},
        to: {opacity: 1},
    });
    return (
        <>
            <animated.div style={{...styles}}>
                <img
                    alt="click indicator"
                    role="img"
                    src={ROOT_PATH_PREFIX + "/images/main/Click.png"}
                    style={{
                        position: "absolute",
                        width: 150 * sizeRatio,
                        height: 100 * sizeRatio,
                        left: 317 * sizeRatio,
                        top: 782 * sizeRatio,
                    }}
                />
            </animated.div>
            <animated.div style={{...styles}}>
                <img
                    alt="click indicator"
                    role="img"
                    src={ROOT_PATH_PREFIX + "/images/main/Click.png"}
                    style={{
                        position: "absolute",
                        width: 150 * sizeRatio,
                        height: 100 * sizeRatio,
                        left: 886 * sizeRatio,
                        top: 727 * sizeRatio,
                    }}
                />
            </animated.div>
            <animated.div style={{...styles}}>
                <img
                    alt="click indicator"
                    role="img"
                    src={ROOT_PATH_PREFIX + "/images/main/Click.png"}
                    style={{
                        position: "absolute",
                        width: 150 * sizeRatio,
                        height: 100 * sizeRatio,
                        left: 1427 * sizeRatio,
                        top: 782 * sizeRatio,
                    }}
                />
            </animated.div>
        </>
    );
};

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 2rem;
  background: #9b98a1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Contents = styled.div<{
    sizeRatio?: number;
}>`
  width: ${({sizeRatio}) => (sizeRatio ? 1920 * sizeRatio : 1920)}px;
  height: ${({sizeRatio}) => (sizeRatio ? 1080 * sizeRatio : 1080)}px;
  position: relative;
  z-index: 2;
`;

const Floor = styled.div<{
    top?: number;
}>`
  position: absolute;
  top: ${({top}) => (top ? top : window.innerHeight * 0.568)}px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #858289;
  z-index: 1;
`;

export default Main2;
