import {
    GA_DATA_MAIN,
    GA_EventAction_Main,
    GA_NameType_Main,
} from "./gaData_Main";
import {
    GA_DATA_THINQ,
    GA_EventAction_ThinQ,
    GA_NameType_ThinQ,
} from "./gaData_ThinQ";
import {DOMAIN_URL} from "../../components/common/const";

export type GA_EventCategory = "ha_virtual_studio" | "lg_thinq";
export type GA_EventAction =
    | "exhibition_setting"
    | GA_EventAction_Main
    | GA_EventAction_ThinQ;
export type GA_NameType =
    | "sound_on"
    | "sound_off"
    | GA_NameType_Main
    | GA_NameType_ThinQ;

export type GA_DataType = {
    name: GA_NameType;
    category: GA_EventCategory;
    action: GA_EventAction;
    label: string;
};

const GA_DATA: GA_DataType[] = [
    {
        name: "sound_on",
        category: "ha_virtual_studio",
        action: "exhibition_setting",
        label: "sound_on",
    },
    {
        name: "sound_off",
        category: "ha_virtual_studio",
        action: "exhibition_setting",
        label: "sound_off",
    },
    ...GA_DATA_MAIN,
    ...GA_DATA_THINQ,
];

function getGAData(name: string) {
    const result = GA_DATA.filter((val) => val.name === name);
    return result && result.length > 0 ? result[0] : null;
}

//정해진 이름으로 GA이벤트 전송
export function sendGaEvent(name: GA_NameType) {
    const gaData = getGAData(name);
    if (gaData) {
        GA_Event(gaData.category, gaData.action, gaData.label);
    }
}

//메뉴얼하게 직접 해당 메서드 호출도 가능
export function GA_Event(
    category: string,
    action: string,
    label: string,
    arg?: object
) {
    //@ts-ignore
    const dataLayer = window.dataLayer;
    if (dataLayer) {
        const data = {
            event: "ga_event",
            category: category,
            action: action,
            label: label,
            ...arg,
        };
        console.log(data);

        dataLayer.push(data);

        // console.log(dataLayer);
    }
}

export function GA_PageView(title: string, location: string, arg?: object) {
    //@ts-ignore
    const dataLayer = window.dataLayer;
    if (dataLayer) {
        const data = {
            event: "ga_virtual",
            title: title === '' ? '/main' : title,
            location: DOMAIN_URL + location,
            ...arg
        }
        console.log(data);
        dataLayer.push(data);
    }
}
