import create from "zustand";
import {
  Objet_CategoryItemType,
  Objet_ColorCategoryType,
  Objet_ProductsCategoryType,
  Objet_RoomType,
} from "../api/products/objetProductsData";
import { LangLabelType } from "../api/lang/langData";

/**
 * 오브제 컬랙션 제품/공간 선택 상태관리
 */
export type ObjetProductState = {
  selectedCategory: Objet_CategoryItemType | null; //선택된 제품
  selectedRoom: Objet_RoomType | null; //선택된 공간 타입

  api: {
    selectCategory: (data: Objet_CategoryItemType | null) => void;
    changeRoom: (roomType: Objet_RoomType | null) => void;
  };
};

const useObjetProductStore = create<ObjetProductState>((set) => ({
  selectedCategory: null,
  selectedRoom: "natural",

  api: {
    selectCategory: (data) => {
      set({ selectedCategory: data });
    },
    changeRoom: (roomType) => {
      set({ selectedRoom: roomType });
    },
  },
}));

export type ObjetColorState = {
  refrigerator_tl_ColorCategory?: Objet_ColorCategoryType;
  refrigerator_tl_ColorLabel?: LangLabelType;
  refrigerator_tl_ga_id?: string;

  refrigerator_bl_ColorCategory?: Objet_ColorCategoryType;
  refrigerator_bl_ColorLabel?: LangLabelType;
  refrigerator_bl_ga_id?: string;

  refrigerator_br_ColorCategory?: Objet_ColorCategoryType;
  refrigerator_br_ColorLabel?: LangLabelType;
  refrigerator_br_ga_id?: string;

  dishwasher_ColorCategory?: Objet_ColorCategoryType;
  dishwasher_ColorLabel?: LangLabelType;
  dishwasher_ga_id?: string;

  water_purifier_ColorCategory?: Objet_ColorCategoryType;
  water_purifier_ColorLabel?: LangLabelType;
  water_purifier_ga_id?: string;

  water_purifier_undersink_ColorCategory?: Objet_ColorCategoryType;
  water_purifier_undersink_ColorLabel?: LangLabelType;
  water_purifier_undersink_ga_id?: string;

  washer_ColorCategory?: Objet_ColorCategoryType;
  washer_ColorLabel?: LangLabelType;
  washer_ga_id?: string;

  dryer_ColorCategory?: Objet_ColorCategoryType;
  dryer_ColorLabel?: LangLabelType;
  dryer_ga_id?: string;

  styler_ColorCategory?: Objet_ColorCategoryType;
  styler_ColorLabel?: LangLabelType;
  styler_ga_id?: string;

  vacuum_cleaner_stand_ColorCategory?: Objet_ColorCategoryType;
  vacuum_cleaner_stand_ColorLabel?: LangLabelType;
  vacuum_cleaner_stand_ga_id?: string;

  vacuum_cleaner_robot_ColorCategory?: Objet_ColorCategoryType;
  vacuum_cleaner_robot_ColorLabel?: LangLabelType;
  vacuum_cleaner_robot_ga_id?: string;

  microwave_oven_ColorCategory?: Objet_ColorCategoryType;
  microwave_oven_ColorLabel?: LangLabelType;
  microwave_oven_ga_id?: string;

  air_conditioner_stand_ColorCategory?: Objet_ColorCategoryType;
  air_conditioner_stand_ColorLabel?: LangLabelType;
  air_conditioner_stand_ga_id?: string;

  air_conditioner_wall_ColorCategory?: Objet_ColorCategoryType;
  air_conditioner_wall_ColorLabel?: LangLabelType;
  air_conditioner_wall_ga_id?: string;

  air_care_ColorCategory?: Objet_ColorCategoryType;
  air_care_ColorLabel?: LangLabelType;
  air_care_ga_id?: string;

  air_care_aero_ColorCategory?: Objet_ColorCategoryType;
  air_care_aero_ColorLabel?: LangLabelType;
  air_care_aero_ga_id?: string;

  tiiun_ColorCategory?: Objet_ColorCategoryType;
  tiiun_ColorLabel?: LangLabelType;
  tiiun_ga_id?: string;

  lansen_freezer_l_ColorCategory?: Objet_ColorCategoryType;
  lansen_freezer_l_ColorLabel?: LangLabelType;
  lansen_freezer_l_ga_id?: string;

  lansen_freezer_r_ColorCategory?: Objet_ColorCategoryType;
  lansen_freezer_r_ColorLabel?: LangLabelType;
  lansen_freezer_r_ga_id?: string;

  searchedObjetColorCategory?: Objet_ColorCategoryType;
  searchedObjetColorLabel?: LangLabelType;

  api: {
    getObjetColor: (productsCategory: Objet_ProductsCategoryType) => any;
    setObjetColor: (
      productsCategory: Objet_ProductsCategoryType,
      colorCategory: Objet_ColorCategoryType,
      colorLabel?: LangLabelType,
      ga_id?: string
    ) => void;
    searchObjetColor: (productsCategory: Objet_ProductsCategoryType) => void;
    resetAllColors: () => void;
    getAllColorsGA: () => any;
  };
};
export const useObjetColorStore = create<ObjetColorState>((set, get) => ({
  api: {
    searchObjetColor: (productsCategory) => {
      console.log("오브제 컬러 검색~!", productsCategory);

      if (productsCategory === "refrigerator_tl") {
        set({
          searchedObjetColorCategory: get().refrigerator_tl_ColorCategory,
          searchedObjetColorLabel: get().refrigerator_tl_ColorLabel,
        });
      } else if (productsCategory === "refrigerator_bl") {
        set({
          searchedObjetColorCategory: get().refrigerator_bl_ColorCategory,
          searchedObjetColorLabel: get().refrigerator_bl_ColorLabel,
        });
      } else if (productsCategory === "refrigerator_br") {
        set({
          searchedObjetColorCategory: get().refrigerator_br_ColorCategory,
          searchedObjetColorLabel: get().refrigerator_br_ColorLabel,
        });
      } else if (productsCategory === "tiiun") {
        set((state) => ({
          searchedObjetColorCategory: state.tiiun_ColorCategory,
          searchedObjetColorLabel: state.tiiun_ColorLabel,
        }));
      }
    },
    getObjetColor: (productsCategory) => {
      if (productsCategory === "refrigerator_tl")
        return {
          colorCategory: get().refrigerator_tl_ColorCategory,
          colorLabel: get().refrigerator_tl_ColorLabel,
          colorGAID: get().refrigerator_tl_ga_id,
        };
      else if (productsCategory === "refrigerator_bl")
        return {
          colorCategory: get().refrigerator_bl_ColorCategory,
          colorLabel: get().refrigerator_bl_ColorLabel,
          colorGAID: get().refrigerator_bl_ga_id,
        };
      else if (productsCategory === "refrigerator_br")
        return {
          colorCategory: get().refrigerator_br_ColorCategory,
          colorLabel: get().refrigerator_br_ColorLabel,
          colorGAID: get().refrigerator_br_ga_id,
        };
      else if (productsCategory === "dishwasher")
        return {
          colorCategory: get().dishwasher_ColorCategory,
          colorLabel: get().dishwasher_ColorLabel,
          colorGAID: get().dishwasher_ga_id,
        };
      else if (productsCategory === "water_purifier")
        return {
          colorCategory: get().water_purifier_ColorCategory,
          colorLabel: get().water_purifier_ColorLabel,
          colorGAID: get().water_purifier_ga_id,
        };
      else if (productsCategory === "water_purifier_undersink")
        return {
          colorCategory: get().water_purifier_undersink_ColorCategory,
          colorLabel: get().water_purifier_undersink_ColorLabel,
          colorGAID: get().water_purifier_undersink_ga_id,
        };
      else if (productsCategory === "washer")
        return {
          colorCategory: get().washer_ColorCategory,
          colorLabel: get().washer_ColorLabel,
          colorGAID: get().washer_ga_id,
        };
      else if (productsCategory === "dryer")
        return {
          colorCategory: get().dryer_ColorCategory,
          colorLabel: get().dryer_ColorLabel,
          colorGAID: get().dryer_ga_id,
        };
      else if (productsCategory === "styler")
        return {
          colorCategory: get().styler_ColorCategory,
          colorLabel: get().styler_ColorLabel,
          colorGAID: get().styler_ga_id,
        };
      else if (productsCategory === "vacuum_cleaner_stand")
        return {
          colorCategory: get().vacuum_cleaner_stand_ColorCategory,
          colorLabel: get().vacuum_cleaner_stand_ColorLabel,
          colorGAID: get().vacuum_cleaner_stand_ga_id,
        };
      else if (productsCategory === "vacuum_cleaner_robot")
        return {
          colorCategory: get().vacuum_cleaner_robot_ColorCategory,
          colorLabel: get().vacuum_cleaner_robot_ColorLabel,
          colorGAID: get().vacuum_cleaner_robot_ga_id,
        };
      else if (productsCategory === "microwave_oven")
        return {
          colorCategory: get().microwave_oven_ColorCategory,
          colorLabel: get().microwave_oven_ColorLabel,
          colorGAID: get().microwave_oven_ga_id,
        };
      else if (productsCategory === "air_conditioner_stand")
        return {
          colorCategory: get().air_conditioner_stand_ColorCategory,
          colorLabel: get().air_conditioner_stand_ColorLabel,
          colorGAID: get().air_conditioner_stand_ga_id,
        };
      else if (productsCategory === "air_conditioner_wall")
        return {
          colorCategory: get().air_conditioner_wall_ColorCategory,
          colorLabel: get().air_conditioner_wall_ColorLabel,
          colorGAID: get().air_conditioner_wall_ga_id,
        };
      else if (productsCategory === "air_care")
        return {
          colorCategory: get().air_care_ColorCategory,
          colorLabel: get().air_care_ColorLabel,
          colorGAID: get().air_care_ga_id,
        };
      else if (productsCategory === "air_care_aero")
        return {
          colorCategory: get().air_care_aero_ColorCategory,
          colorLabel: get().air_care_aero_ColorLabel,
          colorGAID: get().air_care_aero_ga_id,
        };
      else if (productsCategory === "tiiun")
        return {
          colorCategory: get().tiiun_ColorCategory,
          colorLabel: get().tiiun_ColorLabel,
          colorGAID: get().tiiun_ga_id,
        };
      else if (productsCategory === "lansen_freezer_l")
        return {
          colorCategory: get().lansen_freezer_l_ColorCategory,
          colorLabel: get().lansen_freezer_l_ColorLabel,
          colorGAID: get().lansen_freezer_l_ga_id,
        };
      else if (productsCategory === "lansen_freezer_r")
        return {
          colorCategory: get().lansen_freezer_r_ColorCategory,
          colorLabel: get().lansen_freezer_r_ColorLabel,
          colorGAID: get().lansen_freezer_r_ga_id,
        };
    },
    setObjetColor: (
      productsCategory,
      colorCategory,
      colorLabel,
      ga_id?: string
    ) => {
      // const path = `${productsCategory}/${colorCategory}/${colorLabel}`;
      // set({selectedObjetColorInfo: path});

      if (productsCategory === "refrigerator_tl")
        set({
          refrigerator_tl_ColorCategory: colorCategory,
          refrigerator_tl_ColorLabel: colorLabel,
          refrigerator_tl_ga_id: ga_id,
        });
      else if (productsCategory === "refrigerator_bl")
        set({
          refrigerator_bl_ColorCategory: colorCategory,
          refrigerator_bl_ColorLabel: colorLabel,
          refrigerator_bl_ga_id: ga_id,
        });
      else if (productsCategory === "refrigerator_br")
        set({
          refrigerator_br_ColorCategory: colorCategory,
          refrigerator_br_ColorLabel: colorLabel,
          refrigerator_br_ga_id: ga_id,
        });
      else if (productsCategory === "dishwasher")
        set({
          dishwasher_ColorCategory: colorCategory,
          dishwasher_ColorLabel: colorLabel,
          dishwasher_ga_id: ga_id,
        });
      else if (productsCategory === "water_purifier")
        set({
          water_purifier_ColorCategory: colorCategory,
          water_purifier_ColorLabel: colorLabel,
          water_purifier_ga_id: ga_id,
        });
      else if (productsCategory === "water_purifier_undersink")
        set({
          water_purifier_undersink_ColorCategory: colorCategory,
          water_purifier_undersink_ColorLabel: colorLabel,
          water_purifier_undersink_ga_id: ga_id,
        });
      else if (productsCategory === "washer")
        set({
          washer_ColorCategory: colorCategory,
          washer_ColorLabel: colorLabel,
          washer_ga_id: ga_id,
        });
      else if (productsCategory === "dryer")
        set({
          dryer_ColorCategory: colorCategory,
          dryer_ColorLabel: colorLabel,
          dryer_ga_id: ga_id,
        });
      else if (productsCategory === "styler")
        set({
          styler_ColorCategory: colorCategory,
          styler_ColorLabel: colorLabel,
          styler_ga_id: ga_id,
        });
      else if (productsCategory === "vacuum_cleaner_stand")
        set({
          vacuum_cleaner_stand_ColorCategory: colorCategory,
          vacuum_cleaner_stand_ColorLabel: colorLabel,
          vacuum_cleaner_stand_ga_id: ga_id,
        });
      else if (productsCategory === "vacuum_cleaner_robot")
        set({
          vacuum_cleaner_robot_ColorCategory: colorCategory,
          vacuum_cleaner_robot_ColorLabel: colorLabel,
          vacuum_cleaner_robot_ga_id: ga_id,
        });
      else if (productsCategory === "microwave_oven")
        set({
          microwave_oven_ColorCategory: colorCategory,
          microwave_oven_ColorLabel: colorLabel,
          microwave_oven_ga_id: ga_id,
        });
      else if (productsCategory === "air_conditioner_stand")
        set({
          air_conditioner_stand_ColorCategory: colorCategory,
          air_conditioner_stand_ColorLabel: colorLabel,
          air_conditioner_stand_ga_id: ga_id,
        });
      else if (productsCategory === "air_conditioner_wall")
        set({
          air_conditioner_wall_ColorCategory: colorCategory,
          air_conditioner_wall_ColorLabel: colorLabel,
          air_conditioner_wall_ga_id: ga_id,
        });
      else if (productsCategory === "air_care")
        set({
          air_care_ColorCategory: colorCategory,
          air_care_ColorLabel: colorLabel,
          air_care_ga_id: ga_id,
        });
      else if (productsCategory === "air_care_aero")
        set({
          air_care_aero_ColorCategory: colorCategory,
          air_care_aero_ColorLabel: colorLabel,
          air_care_aero_ga_id: ga_id,
        });
      else if (productsCategory === "tiiun")
        set({
          tiiun_ColorCategory: colorCategory,
          tiiun_ColorLabel: colorLabel,
          tiiun_ga_id: ga_id,
        });
      else if (productsCategory === "lansen_freezer_l")
        set({
          lansen_freezer_l_ColorCategory: colorCategory,
          lansen_freezer_l_ColorLabel: colorLabel,
          lansen_freezer_l_ga_id: ga_id,
        });
      else if (productsCategory === "lansen_freezer_r")
        set({
          lansen_freezer_r_ColorCategory: colorCategory,
          lansen_freezer_r_ColorLabel: colorLabel,
          lansen_freezer_r_ga_id: ga_id,
        });
    },
    resetAllColors: () => set({}, true),
    getAllColorsGA: () => {
      return {
        dimension21: get().refrigerator_tl_ga_id,
        dimension22: get().refrigerator_bl_ga_id,
        dimension23: get().refrigerator_br_ga_id,
        dimension24: get().dishwasher_ga_id,
        dimension25: get().water_purifier_ga_id,
        dimension26: get().water_purifier_undersink_ga_id,
        dimension27: get().washer_ga_id,
        dimension28: get().dryer_ga_id,
        dimension29: get().styler_ga_id,
        dimension30: get().vacuum_cleaner_stand_ga_id,
        dimension31: get().vacuum_cleaner_robot_ga_id,
        dimension32: get().microwave_oven_ga_id,
        dimension33: get().air_conditioner_stand_ga_id,
        dimension34: get().air_conditioner_wall_ga_id,
        dimension35: get().air_care_ga_id,
        dimension36: get().air_care_aero_ga_id,
        dimension37: get().tiiun_ga_id,
        dimension38: get().lansen_freezer_l_ga_id,
        dimension39: get().lansen_freezer_r_ga_id,
      };
    },
  },
}));

export default useObjetProductStore;
