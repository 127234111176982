/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Box, Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {
    lansen_freezer_l_data,
    lansen_freezer_r_data,
    Objet_ColorCategoryType
} from "../../../../api/products/objetProductsData";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {TextureLoader} from "three";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {LangLabelType} from "../../../../api/lang/langData";
import {easeCubicInOut} from "d3-ease";
import {a} from "@react-spring/three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "./Product_Harvester";
import useCameraStore, {CameraState} from "../../../../stores/cameraStore";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";


const cameraApiSelector = (state: CameraState) => state.api;


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
// const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/l_n_f/Lightmap_LnF.exr';
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_LnF.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_LnF.jpg');

const COLOR_0_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Silver.jpg';
const COLOR_1_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Green.jpg';
const COLOR_2_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Beige.jpg';
const COLOR_3_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Pink.jpg';
const COLOR_4_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Mint.jpg';
const COLOR_5_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Mist_Silver.jpg';
const COLOR_6_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Red_Wood.jpg';
const COLOR_7_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Clay_Brown.jpg';
const COLOR_8_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Grey.jpg';
const COLOR_9_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_Black.jpg';
const COLOR_10_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/map/l_n_f/l_n_f_White.jpg';


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([
        COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL,
        COLOR_8_URL, COLOR_9_URL, COLOR_10_URL
    ]);

    const colorCategory = useObjetColorStore(state => state.lansen_freezer_l_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.lansen_freezer_l_ColorLabel);
    const colorCategoryR = useObjetColorStore(state => state.lansen_freezer_r_ColorCategory);
    const colorLabelR = useObjetColorStore(state => state.lansen_freezer_r_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [spring, animate] = useSpring(() => ({from: {opacity: 0}}));
    const [spring2, animate2] = useSpring(() => ({from: {opacity: 0}}));
    const [isSet, setIsSet] = useState(false);
    const [isSet2, setIsSet2] = useState(false);

    const [cameraTargetWorldPos] = useState(() => new THREE.Vector3());
    const [worldPos] = useState(() => new THREE.Vector3());
    const {setTargetPos, setLookPos} = useCameraStore(cameraApiSelector);
    const cameraTargetRef = useRef<THREE.Mesh>(null!);
    const lookTargetRef = useRef<THREE.Mesh>(null!);

    const selectPlusHandler = useCallback(() => {
        cameraTargetRef.current.getWorldPosition(cameraTargetWorldPos);
        lookTargetRef.current.getWorldPosition(worldPos);
        worldPos.add(new THREE.Vector3(0, 0, 0));
        setTargetPos(cameraTargetWorldPos);
        setLookPos(worldPos);
    }, []);

    const changeTexture = (materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {

        if (!isFirst && colorCategory && colorLabel) {
            if (materialName === 'mat_lnf_door_l') {
                setIsSet(true);
            } else {
                setIsSet2(true);
            }
        }
        console.log(materialName, colorCategory, colorLabel);

        if (colorCategory === 'Solid') {
            if (colorLabel === 'objet_color_silver') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_mint') {
                colorTextures[4].flipY = false;
                colorTextures[4].encoding = THREE.sRGBEncoding;
                colorTextures[4].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[4];
            } else if (colorLabel === 'objet_color_beige') {
                colorTextures[2].flipY = false;
                colorTextures[2].encoding = THREE.sRGBEncoding;
                colorTextures[2].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[2];
            } else if (colorLabel === 'objet_color_pink') {
                colorTextures[3].flipY = false;
                colorTextures[3].encoding = THREE.sRGBEncoding;
                colorTextures[3].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[3];
            } else if (colorLabel === 'objet_color_green') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            }
        } else if (colorCategory === 'Mist') {
            if (colorLabel === 'objet_color_white') {
                colorTextures[10].flipY = false;
                colorTextures[10].encoding = THREE.sRGBEncoding;
                colorTextures[10].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[10];
            } else if (colorLabel === 'objet_color_gray') {
                colorTextures[8].flipY = false;
                colorTextures[8].encoding = THREE.sRGBEncoding;
                colorTextures[8].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[8];
            } else if (colorLabel === 'objet_color_black') {
                colorTextures[9].flipY = false;
                colorTextures[9].encoding = THREE.sRGBEncoding;
                colorTextures[9].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[9];
            } else if (colorLabel === 'objet_color_redwood') {
                colorTextures[6].flipY = false;
                colorTextures[6].encoding = THREE.sRGBEncoding;
                colorTextures[6].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[6];
            } else if (colorLabel === 'objet_color_clay_brown') {
                colorTextures[7].flipY = false;
                colorTextures[7].encoding = THREE.sRGBEncoding;
                colorTextures[7].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[7];
            } else {
                colorTextures[5].flipY = false;
                colorTextures[5].encoding = THREE.sRGBEncoding;
                colorTextures[5].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[5];
            }
        }
        materials[`${materialName}`].needsUpdate = true;
    };

    useLayoutEffect(() => {
        changeTexture("mat_lnf_door_l", colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useLayoutEffect(() => {
        changeTexture("mat_lnf_door_r", colorCategoryR, colorLabelR);
    }, [colorCategoryR, colorLabelR]);

    useLayoutEffect(() => {
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;
        // setupScene(scene, lightmapTex);
        materials.mat_lnf_body.lightMap = lightmapTex;
        materials.mat_lnf_door_l.lightMap = lightmapTex;
        materials.mat_lnf_door_r.lightMap = lightmapTex;

    }, [lightmapTex]);


    useEffect(() => {
        animate({cancel: true});
        animate2({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut},
            });
            animate2({
                cancel: false,
                opacity: !isSet2 ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut},
            });
        }
    }, [isOpenTutorial]);

    useEffect(() => {
        materials.mat_lnf_door_l.color.setRGB(1, 1, 1);
        materials.mat_lnf_door_r.color.setRGB(1, 1, 1);

        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [
                COLOR_0_URL, COLOR_1_URL, COLOR_2_URL, COLOR_3_URL, COLOR_4_URL, COLOR_5_URL, COLOR_6_URL, COLOR_7_URL,
                COLOR_8_URL, COLOR_9_URL, COLOR_10_URL
            ]);
        }
    }, []);

    const [idx, setIdx] = useState(-1);
    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} scale={[1.2, 1.06, 1.1]} position={[-0.8, -0.1, 0.1]} dispose={null}>
            <mesh
                geometry={nodes.l_n_f.geometry}
                material={nodes.l_n_f.material}
                position={[4.3792, 0.4, -1.2283]}>
                <mesh
                    geometry={nodes.l_n_f_door_l.geometry}
                    material={materials.mat_lnf_door_l}
                    position={[-0.2629, 0.9445, -0.2988]}
                />
                <mesh
                    geometry={nodes.l_n_f_door_r.geometry}
                    material={materials.mat_lnf_door_r}
                    position={[-0.2629, 0.9445, 0.2988]}
                />
                <mesh
                    geometry={nodes.l_n_f_handle_metal.geometry}
                    material={materials.mat_white_metal}
                    position={[-0.3049, 1.1021, 0.0009]}
                />
                <mesh
                    geometry={nodes.l_n_f_handle_plastic.geometry}
                    material={nodes.l_n_f_handle_plastic.material}
                    position={[-0.2779, 1.1018, 0.0009]}
                />
            </mesh>

            <ObjetProductContainer3D
                args={[0.58, 1.8, 0.01]}
                position={[4.12, 1.35, -1.53]}
                rotation={[0, -Math.PI * 0.5, 0]}
                data={lansen_freezer_l_data}
                showDebug={false}
                cameraTargetPos={[0, 0.3, 3]}
                selectMeCallback={() => {
                    setIdx(0);
                    selectPlusHandler();
                }}
            />
            <ObjetProductContainer3D
                args={[0.58, 1.8, 0.01]}
                position={[4.12, 1.35, -0.93]}
                rotation={[0, -Math.PI * 0.5, 0]}
                data={lansen_freezer_r_data}
                showDebug={false}
                cameraTargetPos={[0.5, 0.3, 3]}
                selectMeCallback={() => {
                    setIdx(1);
                    selectPlusHandler();
                }}
            />


            <group position={[4.3792, 0.4, -1.2283]}>
                <mesh
                    geometry={nodes.l_n_f_door_l.geometry}
                    position={[-0.2629, 0.9445, -0.2988]}
                    visible={!isSet}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                    <ObjetPlusButton position={[0, 0, 0]} visible={!(isSet)} opacity={spring.opacity}
                                     color={(idx === 0 && pathname.indexOf('lansen_freezer_l') > -1) ? '#be1e42' : '#333'}/>
                </mesh>

                <mesh
                    geometry={nodes.l_n_f_door_r.geometry}
                    position={[-0.2629, 0.9445, 0.2988]}
                    visible={!isSet2}
                >
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring2.opacity}/>
                    <ObjetPlusButton position={[0, 0, 0]} visible={!(isSet2)} opacity={spring2.opacity}
                                     color={(idx === 1 && pathname.indexOf('lansen_freezer_r') > -1) ? '#be1e42' : '#333'}/>
                </mesh>
            </group>

            {/* 카메라 타겟 */}
            <group position={[4.12, 1.35, -1.25]}
                   rotation={[0, -Math.PI * 0.5, 0]}>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={[0, 0, 0]}
                    ref={lookTargetRef}
                >
                    <meshBasicMaterial wireframe color={"#0f0"}/>
                </Box>
                <Box
                    args={[0.1, 0.1, 0.1]}
                    visible={false}
                    position={[0, 0, 3]}
                    ref={cameraTargetRef}
                >
                    <meshBasicMaterial wireframe color={"#f00"}/>
                </Box>
            </group>

        </group>
    )
}
