/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "../../components/objetcollection/room/natural/Product_Harvester";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene, setupScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {TextureLoader} from "three";
import useProductStore from "../../stores/productStore";


type GLTFResult = GLTF & {
    nodes: {
        xd2_steam_back: THREE.Mesh
        xd2_steam_body: THREE.Mesh
        xd2_steam_decal: THREE.Mesh
        black_sts: THREE.Mesh
        stainless_steel: THREE.Mesh
    }
    materials: {
        mat_black: THREE.MeshStandardMaterial
        Door_Decal: THREE.MeshStandardMaterial
        black_sts: THREE.MeshStandardMaterial
        stainless_steel: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/m/xd2_steam_ktx2_2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/xd2_steam_ktx2_2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd2_steam_ktx2_2.glb';
const AO_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd2_steam_AO.jpg';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const aoTex = useTexture(AO_URL);

    const doorRef = useRef<THREE.Mesh>(null!);
    const selectedColorIndex = useProductStore(state => state.selectedColorIndex);
    const selectedProduct = useProductStore(state => state.selectedProduct);

    useEffect(()=>{
        if(selectedProduct?.modelNo === 'xd4_steam'){
            if(selectedColorIndex === 0){
                doorRef.current.material = materials.stainless_steel;
            }
            else{
                doorRef.current.material = materials.black_sts;
            }
        }
    }, [selectedProduct, selectedColorIndex]);

    useLayoutEffect(()=>{
        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        materials.black_sts.aoMap = aoTex;
        materials.black_sts.aoMapIntensity = 2;
        materials.black_sts.map = null;
        materials.black_sts.color.setRGB(0.15,0.15,0.15);
        materials.stainless_steel.aoMap = aoTex;
        materials.stainless_steel.aoMapIntensity = 2;
        materials.Door_Decal.emissiveIntensity = 0.3;

    }, [aoTex]);

    useEffect(() => {
        doorRef.current.material = materials.stainless_steel;

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, AO_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[-0.01,0,0.3]}>
            <mesh
                geometry={nodes.xd2_steam_back.geometry}
                material={materials.mat_black}
                position={[0.01, 0.39, -0.21]}
                userData={{ name: 'xd2_steam_back' }}
            />
            <mesh
                geometry={nodes.xd2_steam_body.geometry}
                material={nodes.xd2_steam_body.material}
                position={[0.03, 0.65, 0.01]}
                userData={{ name: 'xd2_steam_body' }}
                ref={doorRef}
            />
            <mesh
                geometry={nodes.black_sts.geometry}
                material={nodes.black_sts.material}
                position={[0.34, 8.47, 0]}
                scale={[0.03, 0.03, 0.03]}
                userData={{ name: 'black_sts' }}
            />
            <mesh
                geometry={nodes.stainless_steel.geometry}
                material={materials.stainless_steel}
                position={[0, 8.47, 0]}
                scale={[0.03, 0.03, 0.03]}
                userData={{ name: 'stainless_steel' }}
            />
            <mesh
                geometry={nodes.xd2_steam_decal.geometry}
                material={materials.Door_Decal}
                position={[0.1669, 0.7391, 0.0024]}
                userData={{ name: 'xd2_steam_decal' }}
            />

            <mesh position={[0, 0.3,-0.6]}>
                <boxBufferGeometry args={[1,1,1]} />
                <meshBasicMaterial color={'#000'} />
            </mesh>
        </group>
    )
}
