/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import {Environment, useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {TextureLoader} from "three";
import {useEquirectEXR} from "../../../../utils/useEquirect";
import {GLTFLoader} from "three-stdlib";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {useKTXLoader} from "../../../../utils/useKTXLoader";



type GLTFResult = GLTF & {
    nodes: {
        f_window_frame003: THREE.Mesh
        glass003: THREE.Mesh
        c_props_books003: THREE.Mesh
        d_ceiling003: THREE.Mesh
        d_shelf_01003: THREE.Mesh
        d_ceiling_module003: THREE.Mesh
        a_sofa_module_01003: THREE.Mesh
        b_plant_leaves_02003: THREE.Mesh
        b_plant_leaves_01003: THREE.Mesh
        b_props_module_02003: THREE.Mesh
        e_floor003: THREE.Mesh
        d_shelf_01004: THREE.Mesh
        c_props_module_01004: THREE.Mesh
        a_sofa_module_03003: THREE.Mesh
        a_sofa_module_03001: THREE.Mesh
        a_sofa_module_02005: THREE.Mesh
        b_plant_leaves_03003: THREE.Mesh
    }
    materials: {
        mat_window_frame: THREE.MeshStandardMaterial
        mat_glass: THREE.MeshStandardMaterial
        mat_books: THREE.MeshStandardMaterial
        mat_ceiling: THREE.MeshStandardMaterial
        mat_dark_plaster_wall: THREE.MeshStandardMaterial
        ['mat_ceiling_module.001']: THREE.MeshStandardMaterial
        mat_sofa_01: THREE.MeshStandardMaterial
        mat_plant_leaves_02: THREE.MeshStandardMaterial
        mat_plant_leaves_01: THREE.MeshStandardMaterial
        mat_props_module_02: THREE.MeshStandardMaterial
        mat_white_marble_2: THREE.MeshStandardMaterial
        mat_wood_wall: THREE.MeshStandardMaterial
        mat_props_module_01: THREE.MeshStandardMaterial
        mat_sofa_module_03: THREE.MeshStandardMaterial
        mat_sofa_module_02: THREE.MeshStandardMaterial
        mat_plant_leaves_03: THREE.MeshStandardMaterial
    }
}


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/m/living_dark_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/living_dark_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_DIFFUSE_m.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_DIFFUSE.jpg');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_AO_m.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/living/map/living_cyclesbake_Environment_01.exr';


const Model = React.memo((props: JSX.IntrinsicElements['group']) => {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);
    const aoTex = useLoader(TextureLoader, AO_URL);
    const envTex = useEquirectEXR(ENV_URL);

    const gl = useThree(state => state.gl);
    const scene2 = useThree(state => state.scene);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        scene2.environment = envTex;
        gl.toneMappingExposure = 1.2;

        setupScene(scene, lightmapTex, aoTex);

        materials.mat_dark_plaster_wall.lightMapIntensity = 3;
        materials.mat_white_marble_2.aoMapIntensity = 1.5;
        materials.mat_white_marble_2.lightMapIntensity = 1;
        materials["mat_ceiling_module.001"].lightMapIntensity = 2;
        materials.mat_sofa_module_03.lightMapIntensity = 2;
        materials.mat_sofa_module_02.lightMapIntensity = 2;
        materials.mat_wood_wall.lightMapIntensity = 2;
        materials.mat_ceiling.lightMapIntensity = 2;

    }, [lightmapTex, aoTex, envTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);

            lightmapTex.dispose();
            aoTex.dispose();
            envTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            // //@ts-ignore
            // useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            // useLoader.clear(TextureLoader, AO_URL)
            // //@ts-ignore
            // useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh geometry={nodes.f_window_frame003.geometry} material={materials.mat_window_frame} />
            <mesh geometry={nodes.glass003.geometry} material={materials.mat_glass} />
            <mesh geometry={nodes.c_props_books003.geometry} material={materials.mat_books} />
            <mesh geometry={nodes.d_ceiling003.geometry} material={materials.mat_ceiling} />
            <mesh geometry={nodes.d_shelf_01003.geometry} material={materials.mat_dark_plaster_wall} />
            <mesh
                geometry={nodes.d_ceiling_module003.geometry}
                material={materials['mat_ceiling_module.001']}
            />
            <mesh geometry={nodes.a_sofa_module_01003.geometry} material={materials.mat_sofa_01} />
            <mesh
                geometry={nodes.b_plant_leaves_02003.geometry}
                material={materials.mat_plant_leaves_02}
            />
            <mesh
                geometry={nodes.b_plant_leaves_01003.geometry}
                material={materials.mat_plant_leaves_01}
            />
            <mesh
                geometry={nodes.b_props_module_02003.geometry}
                material={materials.mat_props_module_02}
            />
            <mesh geometry={nodes.e_floor003.geometry} material={materials.mat_white_marble_2} />
            <mesh geometry={nodes.d_shelf_01004.geometry} material={materials.mat_wood_wall} />
            <mesh
                geometry={nodes.c_props_module_01004.geometry}
                material={materials.mat_props_module_01}
            />
            <mesh
                geometry={nodes.a_sofa_module_03003.geometry}
                material={nodes.a_sofa_module_03003.material}
            />
            <mesh
                geometry={nodes.a_sofa_module_03001.geometry}
                material={nodes.a_sofa_module_03001.material}
                position={[-3.5823, 0.7553, -2.4954]}
                rotation={[0, -1.0908, 0]}
            />
            <mesh geometry={nodes.a_sofa_module_02005.geometry} material={materials.mat_sofa_module_02} />
            <mesh
                geometry={nodes.b_plant_leaves_03003.geometry}
                material={materials.mat_plant_leaves_03}
            />
        </group>
    )
});

export default Model;
