import React from "react";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";
import { easeExpOut } from "d3-ease";
import { DownCircleOutlined } from "@ant-design/icons";
import { Text } from "../../common/comps_styled/Text.styled";
import {
  Center,
  ContainerAnimated,
  ContainerRow,
} from "../../common/comps_styled/Container.styled";
import theme from "../../../styles/theme.styled";
import useResizeStore from "../../../stores/resizeStore";
import { IS_MOBILE } from "../../common/const";

/**
 * 제품 선택 UI 토글 버튼
 */
type ProductsToggleButtonProps = {
  selected?: boolean;
  onClick?: () => void;
} & JSX.IntrinsicElements["button"];

const ProductsToggleButton = ({
  selected = false,
  onClick,
  ...rest
}: ProductsToggleButtonProps) => {
  const resize = useResizeStore((state) => state.resize);

  return (
    <Wrapper
      ratio={resize.ratio}
      selected={selected}
      onClick={onClick}
      {...rest}
      style={{
        backgroundColor: "white",
        opacity: 0.9,
      }}
    >
      <ContainerRow style={{ alignItems: "center" }}>
        <Text
          color={theme.colors.home.pink}
          size={`${resize.ratio * (IS_MOBILE ? 2.6 : 2.2)}em`}
          weight={theme.font.weight.normal}
          style={{ marginRight: 12 }}
        >
          Select Products
        </Text>
        <DownCircleOutlined
          style={{
            fontSize: `${resize.ratio * (IS_MOBILE ? 3.5 : 2.5)}em`,
            color: theme.colors.home.pink,
            transform: `rotate(${selected ? "0.5turn" : "0turn"})`,
            transition: "transform 500ms ease",
          }}
        />
      </ContainerRow>
    </Wrapper>
  );
};

const Wrapper = styled(animated.button)<
  { ratio: number; selected: boolean } & Record<string, any>
>`
  display: flex;
  padding: ${IS_MOBILE ? 1.1 : 1}% ${IS_MOBILE ? 3.6 : 2}%;
  cursor: pointer;
  border-top-right-radius: ${({ ratio }) => ratio * (IS_MOBILE ? 1.5 : 1)}em;
  border-top-left-radius: ${({ ratio }) => ratio * (IS_MOBILE ? 1.5 : 1)}em;
  border-bottom: ${({ ratio }) => ratio * (IS_MOBILE ? 6 : 4)}px solid
    ${({ theme }) => theme.colors.home.pink}!important;
  margin-left: ${IS_MOBILE ? 0 : 3}%;
  pointer-events: visible;
  height: ${({ ratio }) => ratio * (IS_MOBILE ? 8 : 6.1)}em;

  //background: ${(props) => (!props.selected ? "#F51C63" : "#fff")};
  //   color: ${(props) => (props.selected ? "#F51C63" : "#fff")};

  :focus-visible {
    filter: drop-shadow(0 0 10px rgba(255, 255, 255, 1));
  }
`;

export default ProductsToggleButton;
