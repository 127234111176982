import React, {
    ReactElement,
    useEffect,
    useLayoutEffect,
    useState,
} from "react";
import {useAudioStore} from "../../../stores/AudioStore";
import useHistoryStore from "../../../stores/historyStore";
import {
    useModalBalloonClose,
    useModalDesign,
    useModalMainIntro,
    useModalOpen,
    useModalSurveyCleaner,
    useModalSurveyMain,
    useModalSurveyStyler,
    useModalTutorial,
    useModalUSP,
    useModalVimeo,
} from "../../../stores/modalStore";
import useProductStore from "../../../stores/productStore";
import useResizeStore from "../../../stores/resizeStore";
import useRightPanelStore from "../../../stores/rightPanelStore";
import {useModalSelectRouteMobile} from "../../../stores/selectRouteForMobileStore";
import DesignBoard from "../../lghome/info/DesignBoard";
import OpenBoard from "../../lghome/info/OpenBoard";
import USPBoard from "../../lghome/info/USPBoard";
import Tutorial from "../../lghome/tutorial/Tutorial";
import VimeoPlayer from "../../lgthinq/VimeoPlayer";
import InnovationAwardsPanel from "../../main/innovation_awards/InnovationAwardsPanel";
import MainIntro from "../../main/MainIntro";
import MainRouteForMobile from "../../main/MainRouteForMobile";
import ZepetoPanel from "../../main/zepeto/ZepetoPanel";
import BalloonClose from "../../objetcollection/select_panel/BalloonClose";
import {
    IS_APPLE_DEVICE,
    IS_CHROME,
    IS_IPHONE,
    IS_MOBILE,
    ROOT_PATH_PREFIX,
} from "../const";
import SurveyCleaner from "../survey/SurveyCleaner";
import SurveyMain from "../survey/SurveyMain";
import SurveyStyler from "../survey/SurveyStyler";

interface Props {
}

function Modal({}: Props): ReactElement {
    // For Open on objet close
    const modalBalloonClose = useModalBalloonClose((state) => state.isOpen);
    // For Open on objet close
    // For Open on guide menu
    const modalMainIntro = useModalMainIntro((state) => state.isOpen);
    const setModalMainIntro = useModalMainIntro((state) => state.setIsOpen);
    // For Open on guide menu
    // For Open on survey for main
    const modalSurveyMainOpen = useModalSurveyMain((state) => state.isOpen);
    const modalSurveyMainCompleted = useModalSurveyMain(
        (state) => state.isCompleted
    );
    const modalSurveyStylerOpen = useModalSurveyStyler((state) => state.isOpen);
    const modalSurveyStylerCompleted = useModalSurveyStyler(
        (state) => state.isCompleted
    );
    const modalSurveyCleanerOpen = useModalSurveyCleaner((state) => state.isOpen);
    const modalSurveyCleanerCompleted = useModalSurveyCleaner(
        (state) => state.isCompleted
    );
    // For Open on survey for main
    // For Open on guide menu
    const modalOpen = useModalOpen((state) => state.isOpen);
    // For Open on guide menu
    // For Design on guide menu
    const modalDesign = useModalDesign((state) => state.isOpen);
    // For Design on guide menu
    // For USP on guide menu
    const modalUSP = useModalUSP((state) => state.isOpen);
    // For USP on guide menu
    const modalVimeo = useModalVimeo((state) => state.isOpen);
    // For selecting quality for mobile on main
    const modalMobileQuality = useModalSelectRouteMobile((state) => state.isOpen);

    // For Tutorial Modal
    const [isFirstTutorial, setIsFirstTutorial] = useState({
        home: true,
        objet: true,
    });
    const modalTutorial = useModalTutorial((state) => state.isOpen);
    const setModalTutorial = useModalTutorial((state) => state.setIsOpen);
    const category = useProductStore((state) => state.selectedCategory?.category);
    const pathname = useHistoryStore((state) => state.pathname);
    const isObjetMode = useRightPanelStore((state) => state.isObjetMode);
    const [isKeepPlay, setIsKeepPlay] = useState(false);

    useEffect(() => {
        if (category && isFirstTutorial.home) {
            setModalTutorial(true);
            setIsFirstTutorial({...isFirstTutorial, home: false});
        }

        if (isObjetMode && isFirstTutorial.objet) {
            setModalTutorial(true);
            setIsFirstTutorial({...isFirstTutorial, objet: false});
        }
    }, [category, isObjetMode]);

    // For Audio
    const getURL = useAudioStore((state) => state.path);
    const setURL = useAudioStore((state) => state.setURL);
    const setMuted = useAudioStore((state) => state.setMuted);
    const play = useAudioStore((state) => state.play);
    const pause = useAudioStore((state) => state.pause);
    const isPlaying = useAudioStore((state) => state.isPlaying);

    useEffect(() => {
        setIsKeepPlay(false);
        switch (pathname) {
            case "":
                getURL !== ROOT_PATH_PREFIX + "/audio/ThinQ_Home_BGM_Loop (Main).wav" &&
                setURL(ROOT_PATH_PREFIX + "/audio/ThinQ_Home_BGM_Loop (Main).wav");
                break;
            case "/thinq":
                getURL !== ROOT_PATH_PREFIX + "/audio/ThinQ_Movie_BGM_Loop_A.wav" &&
                setURL(ROOT_PATH_PREFIX + "/audio/ThinQ_Movie_BGM_Loop_A.wav");
                break;
            case "/thinq/easy_convenient":
            case "/thinq/unique_delightful":
                setIsKeepPlay(true);
                break;
            case "/home":
                getURL !== ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav" &&
                setURL(ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav");
                break;
            case "/home/kitchen/refrigerator":
            case "/home/utility/washer_n_dryer":
            case "/home/kitchen/dishwasher":
            case "/home/utility/styler":
                modalUSP
                    ? setURL(
                        ROOT_PATH_PREFIX +
                        "/audio/InnovationGallery(usp)/InnovationGallery1_BGM_Loop.wav"
                    )
                    : getURL !== ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav" &&
                    setURL(ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav");
                setIsKeepPlay(true);
                break;
            case "/home/kitchen/cooking":
            case "/home/kitchen/homebrew":
            case "/home/utility/vacuum_cleaner":
                modalUSP
                    ? setURL(
                        ROOT_PATH_PREFIX +
                        "/audio/InnovationGallery(usp)/InnovationGallery2_BGM_Loop.wav"
                    )
                    : getURL !== ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav" &&
                    setURL(ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav");
                setIsKeepPlay(true);
                break;
            case "/home/living/air_conditioner":
            case "/home/living/air_care":
            case "/home/living/wearable_air_purifier":
            case "/home/kitchen/water_purifier":
            case "/home/living/mask_case":
            case "/home/kitchen/lansen_freezer":
            case "/home/kitchen/tiiun":
            case "/home/kitchen/microwave_oven":
                modalUSP
                    ? setURL(
                        ROOT_PATH_PREFIX +
                        "/audio/InnovationGallery(usp)/InnovationGallery3_BGM_Loop.wav"
                    )
                    : getURL !== ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav" &&
                    setURL(ROOT_PATH_PREFIX + "/audio/Home_BGM_Loop_03.wav");
                setIsKeepPlay(true);
                break;
            case "/objetcollection/natural":
            case "/objetcollection/blossom":
            case "/objetcollection/modern":
                getURL !==
                ROOT_PATH_PREFIX + "/audio/Wedding Emotional Cinematic_loop.wav" &&
                setURL(
                    ROOT_PATH_PREFIX + "/audio/Wedding Emotional Cinematic_loop.wav"
                );
                break;
            default:
                break;
        }
        isPlaying && play();
        // console.log("pathname : ", pathname, " / ");
    }, [pathname, modalUSP]);
    //

    useEffect(() => {
        setMuted(pathname.includes("/zepeto"));
    }, [pathname]);

    useEffect(() => {
        console.log("modalVimeo: ", modalVimeo, ", isKeepPlay: " + isKeepPlay);
        if (!isKeepPlay) setMuted(modalVimeo);
    }, [modalVimeo]);

    // for resize
    const setPanelWidth = useRightPanelStore((state) => state.setPanelWidth);
    const setResize = useResizeStore((state) => state.setResize);
    const resize = useResizeStore((state) => state.resize);
    const [isScale, setIsScale] = useState(false);
    const bgRatio = 1920 / 1080;

    function resizeHandler() {
        const ratio = window.innerWidth / window.innerHeight;

        if (isScale) {
            if (ratio > bgRatio) {
                const sizeW = window.innerWidth;
                const sizeH = window.innerWidth / bgRatio;
                const sizeRatio = sizeW / 1920;
                setResize(sizeW, sizeH, sizeRatio);
            } else {
                const sizeH = window.innerHeight;
                const sizeW = window.innerHeight * bgRatio;
                const sizeRatio = sizeH / 1080;
                setResize(sizeW, sizeH, sizeRatio);
            }
        } else {
            //가로로 더 길어진 경우, 세로에 맞추고 가로에 여백이 생김
            if (ratio > bgRatio) {
                const sizeH = window.innerHeight;
                const sizeW = window.innerHeight * bgRatio;
                const sizeRatio = sizeH / 1080;
                setResize(sizeW, sizeH, sizeRatio);
            }
            //세로로 더 길어진 경우, 가로에 맞추고 위아래에 여백이 생김
            else {
                const sizeW = window.innerWidth;
                const sizeH = window.innerWidth / bgRatio;
                const sizeRatio = sizeW / 1920;
                setResize(sizeW, sizeH, sizeRatio);
            }
        }
    }

    useLayoutEffect(() => {
        window.addEventListener("resize", resizeHandler);
        window.addEventListener('orientationchange', resizeHandler);
        window.dispatchEvent(new Event("resize"));
        return () => {
            window.removeEventListener("resize", resizeHandler);
            window.removeEventListener('orientationchange', resizeHandler);
        };
    }, []);

    const getMainIntro = () => {
        if (IS_MOBILE && IS_APPLE_DEVICE) return <></>;
        else if (IS_APPLE_DEVICE && !IS_CHROME) return <></>;
        else if (pathname === "" && modalMainIntro) return <MainIntro/>;
        else return <></>;
    };

    return (
        <>
            {getMainIntro()}
            {/*{IS_IPHONE && pathname === "" && modalMobileQuality && (*/}
            {/*  <MainRouteForMobile />*/}
            {/*)}*/}
            {modalBalloonClose && <BalloonClose/>}
            {modalOpen && <OpenBoard/>}
            {modalDesign && <DesignBoard/>}
            {modalUSP && <USPBoard/>}
            {modalTutorial && <Tutorial/>}
            {modalVimeo && <VimeoPlayer/>}
            {pathname === "/innovation_awards" && <InnovationAwardsPanel/>}
            {pathname === "/zepeto" && <ZepetoPanel/>}
            {!modalSurveyMainCompleted && modalSurveyMainOpen && <SurveyMain/>}
            {!modalSurveyStylerCompleted && modalSurveyStylerOpen && <SurveyStyler/>}
            {!modalSurveyCleanerCompleted && modalSurveyCleanerOpen && (
                <SurveyCleaner/>
            )}
        </>
    );
}

export default Modal;
