/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {ROOT_PATH_PREFIX} from "../../components/common/const";
import useProductStore from "../../stores/productStore";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        Handle_Black001: THREE.Mesh
        Handle_Steel_Body001: THREE.Mesh
        Steel001: THREE.Mesh
        Plastic001: THREE.Mesh
        Plane: THREE.Mesh
        Body_cover_UI: THREE.Mesh
        Body_JogDial001: THREE.Mesh
        Holder_black_Decal001: THREE.Mesh
        Steel_low: THREE.Mesh
    }
    materials: {
        handle: THREE.MeshStandardMaterial
        mouth: THREE.MeshStandardMaterial
        Steel: THREE.MeshStandardMaterial
        ['body.003']: THREE.MeshStandardMaterial
        logo: THREE.MeshStandardMaterial
        UI1: THREE.MeshStandardMaterial
        button: THREE.MeshStandardMaterial
        ['bodyDecal.003']: THREE.MeshStandardMaterial
        ['Steel.001']: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = ROOT_PATH_PREFIX + '/RP/products/home_brew/homebrew_new_ktx2.glb';

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL) as GLTFResult;

    useEffect(() => {

        materials.Steel.map = null;
        materials.Steel.color.setRGB(1,1,1);
        materials.Steel.metalness = 1;
        materials.Steel.metalnessMap = null;
        materials.Steel.roughness = 0.25;
        materials.Steel.roughnessMap = null;

        if(materials["Steel.001"].normalMap){
            materials["Steel.001"].normalMap.minFilter = THREE.LinearFilter;
        }

        return () => {
            disposeScene(scene);
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null} position={[-0.1, 0, 0.3]} >
            <mesh geometry={nodes.Handle_Black001.geometry} material={materials.handle} />
            <mesh geometry={nodes.Handle_Steel_Body001.geometry} material={materials.mouth} />
            <mesh geometry={nodes.Steel001.geometry} material={materials.Steel} />
            <mesh geometry={nodes.Plastic001.geometry} material={materials['body.003']} />
            <mesh geometry={nodes.Plane.geometry} material={materials.logo} />
            <mesh geometry={nodes.Body_cover_UI.geometry} material={materials.UI1} />
            <mesh geometry={nodes.Body_JogDial001.geometry} material={materials.button} />
            <mesh geometry={nodes.Holder_black_Decal001.geometry} material={materials['bodyDecal.003']} />
            <mesh geometry={nodes.Steel_low.geometry} material={materials['Steel.001']} />
        </group>
    )
}
