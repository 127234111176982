import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import useProductStore, { ProductState } from "../../../stores/productStore";
import useKeyPress from "../../../utils/useKeyPress";
import {
  Container,
  ListContainer,
} from "../../common/comps_styled/Container.styled";
import { Text } from "../../common/comps_styled/Text.styled";
import { Divider } from "../../common/comps_styled/Divider.styled";
import {
  ColorButton,
  ColorCircle,
  ColorRect,
} from "../comps_styled/selectPanel/ColorButton.styled";
import theme from "../../../styles/theme.styled";
import useLangStore from "../../../stores/langStore";
import { getLangString, LangLabelType } from "../../../api/lang/langData";
import { Image } from "../../common/comps_styled/Image.styled";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import useResizeStore from "../../../stores/resizeStore";
import { GA_Event } from "../../../api/ga";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";

/**
 * 제품 색상 선택 UI
 * @constructor
 */

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const selectedColorIndexSelector = (state: ProductState) =>
  state.selectedColorIndex;
const selectColorIndexSelector = (state: ProductState) =>
  state.api.selectColorIndex;
const selectedProductSelector = (state: ProductState) => state.selectedProduct;

const SelectProductColor = ({ ...rest }) => {
  const selectedProduct = useProductStore(selectedProductSelector); //현재 선택된 제품 데이터
  const selectedColorIndex = useProductStore(selectedColorIndexSelector); //현재 제품 색상 상태값
  const selectColorIndex = useProductStore(selectColorIndexSelector); //제품 색상 상태값 변경
  const lang = useLangStore((state) => state.lang);
  const resize = useResizeStore((state) => state.resize);

  // for GAEvent
  const [clickStatus, setClickStatus] = useState(false);
  const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);
  const ga_model = useProductStore((state) => state.selectedProduct?.ga_id);
  const ga_interior = useSpaceThemeStore((state) => state.lgHomeTheme);
  const ga_isNew = useProductStore((state) =>
    state.selectedProduct?.isCES ? "y" : "n"
  );
  const colorIndex = useProductStore((state) => state.selectedColorIndex);
  const ga_color = useMemo(
    () => selectedProduct?.colors[colorIndex].ga_id,
    [colorIndex, selectedProduct]
  );

  //
  useEffect(() => {
    GA_Event("lg_home", `${ga_product}_color`, ga_color ?? "", {
      dimension5: ga_product,
      dimension6: ga_interior,
      dimension7: ga_model,
      dimension8: ga_isNew,
      dimension9: ga_color,
    });
  }, [ga_color, clickStatus]);

  useLayoutEffect(() => {
    // console.log("현재 선택된 제품 데이터: ", selectedProduct);
    //selectedProduct 값이 변경될 경우
    //selectedProduct.colors 배열의 컬러 정보를 가져와서 컬러 버튼 정보 갱신해야함.
  }, [selectedProduct]);

  useLayoutEffect(() => {
    // console.log("선택된 제품 색상 Index: ", selectedColorIndex);
  }, [selectedColorIndex]);

  //space theme api 테스트용 코드 ---------------------[Start]
  //X키 눌렀을 때 제품 색상 상태값 변경 시뮬레이션, 색상 상태값을 변경할 때 selectColorIndex을 호출하고
  //현재 색상 상태값을 가져올 때 selectedColorIndex 사용
  const isPressX = useKeyPress(["x", "X"]);
  const isPressC = useKeyPress(["c", "C"]);
  useEffect(() => {
    if (isPressX) {
      selectColorIndex(0);
    } else if (isPressC) {
      selectColorIndex(1);
    }
  }, [isPressX, isPressC]);
  //space theme api 테스트용 코드 ---------------------[End]

  return (
    <Container {...rest}>
      <Text
        size={`${(IS_MOBILE ? 3.5 : 2.8) * resize.ratio}em`}
        weight={theme.font.weight.semibold}
        style={{ paddingLeft: "2%" }}
      >
        {getLangString(lang, "home_exp_color")?.text}
      </Text>
      <Divider
        borderwidth={2 * resize.ratio}
        style={{ marginTop: "2%", marginBottom: IS_MOBILE ? "1.5%" : "5%" }}
      />
      <ListContainer>
        {selectedProduct &&
          selectedProduct.colors &&
          selectedProduct.colors.map((value, index) => {
            const isBlackFontColor =
              value.label === "home_exp_color_silver" ||
              value.label === "home_exp_color_white" ||
              value.label === "home_exp_color_beige";

            return (
              <ColorButton
                key={index}
                style={{ flex: 1, marginBottom: IS_MOBILE ? "1%" : "2%" }}
                selected={index === selectedColorIndex}
                onClick={() => {
                  selectColorIndex(index);
                  setClickStatus(!clickStatus);
                }}
              >
                {!IS_MOBILE ? (
                  <>
                    <Container style={{ flex: 1, marginRight: 5 }}>
                      <ColorCircle
                        className="circle"
                        ratio={resize.ratio}
                        style={{
                          backgroundImage: `url(${
                            ROOT_PATH_PREFIX + value.color
                          })`,
                          backgroundSize: "100%",
                        }}
                      />
                    </Container>
                    <Container>
                      <Text
                        className="text"
                        lineHeight={0.9}
                        size={`${1.6 * resize.ratio}em`}
                      >
                        {
                          getLangString(lang, value.label as LangLabelType)
                            ?.text
                        }
                      </Text>
                    </Container>
                  </>
                ) : (
                  <div>
                    <ColorRect
                      ratio={resize.ratio}
                      className="circle"
                      style={{
                        backgroundImage: `url(${
                          ROOT_PATH_PREFIX + value.color
                        })`,
                        backgroundPositionX: "50%",
                        backgroundPositionY: "50%",
                        backgroundSize: "200%",
                      }}
                    >
                      {console.log(value.label)}
                      <Text
                        className="text"
                        lineHeight={0.8}
                        color={isBlackFontColor ? "black" : "white"}
                        size={`${resize.ratio * 1.8}em`}
                        align="center"
                      >
                        {
                          getLangString(lang, value.label as LangLabelType)
                            ?.text
                        }
                      </Text>
                    </ColorRect>
                  </div>
                )}
              </ColorButton>
            );
          })}
      </ListContainer>
    </Container>
  );
};

export default SelectProductColor;
