import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_WearableAirPurifier: UspPageDataType[] = [
  {
    category: "wearable_air_purifier",
    modelNo: "wearable_2nd",
    bgImgUrl: "/images/usp/wearable_air_purifier/wearable_2nd.jpg",
    uspList: [
      {
        label: "wearable_usp_0",
        title: [<>You can breath cleaner air.</>],
        subMsg: [<>Through Air Purification</>],
        videoUrl: "https://vimeo.com/658887806/0af7535f0a",
        x: 200,
        y: 310,
        ga_id: "thorough_air_purification",
      },
      {
        label: "wearable_usp_0",
        title: [
          <>
            Breathe comfy while
            <br />
            Wearable Air Purifier.
          </>,
        ],
        subMsg: [<>Comfortable Breathing</>],
        videoUrl: "https://vimeo.com/658887717/6b747fd948",
        x: 200,
        y: 435,
        ga_id: "comfortable_breathing",
      },
      {
        isRight: true,
        label: "wearable_usp_0",
        title: [
          <>
            A perfect fit, for your face.
            <br />
            Free size!
          </>,
        ],
        subMsg: [<>Ergonomic Design</>],
        videoUrl: "https://vimeo.com/658887754/255b8e1adc",
        x: 1185,
        y: 220,
        ga_id: "ergonomic_design",
      },
      {
        isRight: true,
        label: "wearable_2nd_usp_0",
        title: [
          <>
            Cannot hear well
            <br />
            due to face mask?
          </>,
        ],
        subMsg: [<>VOICE ON</>],
        videoUrl: "https://vimeo.com/659899762/fb4eeaf54c",
        x: 1185,
        y: 370,
        ga_id: "voice_on",
      },
      {
        label: "wearable_usp_0",
        title: [
          <>
            Only use this as a mask
            <br />
            case? Nope!
          </>,
        ],
        subMsg: [<>UV LED</>],
        videoUrl: "https://vimeo.com/658887928/c8ec34d261",
        x: 200,
        y: 585,
        ga_id: "uv_led",
      },
      {
        isRight: true,
        label: "wearable_usp_0",
        title: [
          <>
            How to take care of
            <br />
            Wearable Air Purifier?
          </>,
        ],
        subMsg: [<>Internal Drying & Charging</>],
        videoUrl: "https://vimeo.com/658888474/cf3ec32b34",
        x: 1185,
        y: 522,
        ga_id: "internal_drying&charging",
      },
    ],
  },
  {
    category: "wearable_air_purifier",
    modelNo: "wearable",
    bgImgUrl: "/images/usp/wearable_air_purifier/wearable.jpg",
    uspList: [
      {
        label: "wearable_2nd_usp_0",
        title: [<>You can breath cleaner air.</>],
        subMsg: [<>Through Air Purification</>],
        videoUrl: "https://vimeo.com/659898393/09b00f5439",
        x: 200,
        y: 310,
        ga_id: "thorough_air_purification",
      },
      {
        label: "wearable_2nd_usp_0",
        title: [
          <>
            Breathe comfy while
            <br />
            Wearable Air Purifier.
          </>,
        ],
        subMsg: [<>Comfortable Breathing</>],
        videoUrl: "https://vimeo.com/659898355/50e840d211",
        x: 200,
        y: 435,
        ga_id: "comfortable_breathing",
      },
      {
        isRight: true,
        label: "wearable_2nd_usp_0",
        title: [
          <>
            A perfect fit, for your face.
            <br />
            Free size!
          </>,
        ],
        subMsg: [<>Ergonomic Design</>],
        videoUrl: "https://vimeo.com/659898375/5576a93c71",
        x: 1185,
        y: 353,
        ga_id: "ergonomic_design",
      },
      // {
      //   isRight: true,
      //   label: "wearable_2nd_usp_0",
      //   title: [
      //     <>
      //       Cannot hear well
      //       <br />
      //       due to face mask?
      //     </>,
      //   ],
      //   subMsg: [<>VOICE ON</>],
      //   videoUrl: "https://vimeo.com/659899762/fb4eeaf54c",
      //   x: 1185,
      //   y: 353,
      //   ga_id: "voice_on",
      // },
      {
        label: "wearable_usp_0",
        title: [
          <>
            Only use this as a mask
            <br />
            case? Nope!
          </>,
        ],
        subMsg: [<>UV LED</>],
        videoUrl: "https://vimeo.com/659898407/75befa64b4",
        x: 200,
        y: 585,
        ga_id: "uv_led",
      },
      {
        isRight: true,
        label: "wearable_usp_0",
        title: [
          <>
            How to take care of
            <br />
            Wearable Air Purifier?
          </>,
        ],
        subMsg: [<>Internal Drying & Charging</>],
        videoUrl: "https://vimeo.com/659898976/f262bcebc1",
        x: 1185,
        y: 508,
        ga_id: "internal_drying&charging",
      },
    ],
  },
];

export default uspData_WearableAirPurifier;
