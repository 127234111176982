/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {IS_LOW_TIER, IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import useHistoryStore from "../../../../stores/historyStore";

// type GLTFResult = GLTF & {
//     nodes: {
//         a_floor: THREE.Mesh
//         a_marble: THREE.Mesh
//         a_ceiling_outer: THREE.Mesh
//         a_ceiling: THREE.Mesh
//         Plane: THREE.Mesh
//         Plane001: THREE.Mesh
//     }
//     materials: {
//         a_mat_herringbone: THREE.MeshStandardMaterial
//         a_mat_wood_ipe: THREE.MeshStandardMaterial
//         a_mat_fabric: THREE.MeshStandardMaterial
//         a_mat_outer_wall: THREE.MeshStandardMaterial
//         a_mat_white_marble: THREE.MeshStandardMaterial
//     }
// }

export type GLTFResult_NaturalRoom = GLTF & {
    nodes: {
        d_kitchen_module_02: THREE.Mesh
        a_ceiling_outer: THREE.Mesh
        a_ceiling: THREE.Mesh
        Plane002: THREE.Mesh
        Plane: THREE.Mesh
        Plane001: THREE.Mesh
        b_shelf: THREE.Mesh
        b_wall_outer: THREE.Mesh
        b_wall: THREE.Mesh
        e_props_module_02: THREE.Mesh
        d_kitchen_module_01: THREE.Mesh
        e_deco_ivyPot_leaf: THREE.Mesh
        c_sofa_module_01: THREE.Mesh
        e_deco_ivyPot_0: THREE.Mesh
        d_sofa_module_03: THREE.Mesh
        e_props_module_01: THREE.Mesh
        a_floor: THREE.Mesh
        b_wood_frame: THREE.Mesh
        c_sofa_module_02: THREE.Mesh
        a_marble: THREE.Mesh
        b_wall_deco: THREE.Mesh
    }
    materials: {
        d_mat_white_marble: THREE.MeshStandardMaterial
        a_mat_outer_wall: THREE.MeshStandardMaterial
        ['a_mat_white_marble.001']: THREE.MeshStandardMaterial
        a_mat_herringbone: THREE.MeshStandardMaterial
        a_mat_fabric: THREE.MeshStandardMaterial
        b_mat_wallpaper: THREE.MeshStandardMaterial
        e_mat_props_module_02: THREE.MeshStandardMaterial
        d_mat_kitchen_module_01: THREE.MeshStandardMaterial
        e_ivyLeaf: THREE.MeshStandardMaterial
        c_mat_sofa_module_01: THREE.MeshStandardMaterial
        e_ivyPot: THREE.MeshStandardMaterial
        d_mat_sofa_module_03: THREE.MeshStandardMaterial
        e_mat_props_module_01: THREE.MeshStandardMaterial
        a_mat_wood_ipe: THREE.MeshStandardMaterial
        b_mat_wood_ipe: THREE.MeshStandardMaterial
        c_mat_sofa_module_02: THREE.MeshStandardMaterial
        a_mat_white_marble: THREE.MeshStandardMaterial
        mat_wood_ipe: THREE.MeshStandardMaterial
    }
}


type GLTFResult = GLTF & {
    nodes: {
        Plane009: THREE.Mesh
        a_ceiling_outer: THREE.Mesh
        a_ceiling: THREE.Mesh
        a_floor: THREE.Mesh
        Plane019: THREE.Mesh
        Plane008: THREE.Mesh
        a_marble: THREE.Mesh
    }
    materials: {
        floor_C: THREE.MeshStandardMaterial
        a_mat_outer_wall: THREE.MeshStandardMaterial
        floor_A: THREE.MeshStandardMaterial
        floor_B: THREE.MeshStandardMaterial
        a_mat_white_marble: THREE.MeshStandardMaterial
    }
}

type ModelProps = {
    interiorIdx?: number;
} & JSX.IntrinsicElements['group'];

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_floor.glb';
const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_floor_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_floor_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Floor_Bake1_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Floor_Bake1_cyclesbake_DIFFUSE.jpg');

export default function Model({interiorIdx = 0, ...props}: ModelProps) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult;
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const pathname = useHistoryStore(state => state.pathname);
    const isActiveLnF = useMemo(() => pathname.indexOf('lansen_freezer') > -1, [pathname]);

    useLayoutEffect(()=>{
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

        // materials.a_mat_wood_ipe.lightMapIntensity = 5;
        // materials.a_mat_white_marble.lightMapIntensity = 5;
        // materials.a_mat_outer_wall.lightMapIntensity = 5;
        materials.floor_A.lightMapIntensity = 5;
        materials.floor_B.lightMapIntensity = 5;
        materials.floor_C.lightMapIntensity = 5;
        materials.a_mat_white_marble.lightMapIntensity = 5;
        materials.a_mat_outer_wall.lightMapIntensity = 3;

    }, [lightmapTex]);

    useLayoutEffect(()=>{
        materials.a_mat_white_marble.lightMapIntensity = isActiveLnF ? 0 : 5;
        materials.a_mat_white_marble.envMapIntensity = isActiveLnF ? 6 : 1;
        materials.a_mat_white_marble.needsUpdate = true;

    }, [isActiveLnF]);

    useEffect(()=>{
        return ()=>{
            disposeScene(scene);
            lightmapTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
        }
    }, []);

    useEffect(()=>{
        // materials.a_mat_herringbone.map?.repeat.set(4,4);
    }, []);

    const floorMat = useMemo(()=>{
        if(interiorIdx === 0) return materials.floor_A;
        else if(interiorIdx === 1) return materials.floor_B;
        else return materials.floor_C;
    }, [interiorIdx]);


    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.Plane009.geometry}
                material={materials.floor_C}
                position={[-5.0004, -8.7426, 1.6653]}
                userData={{ name: 'Plane.009' }}
            />
            <mesh
                geometry={nodes.a_ceiling_outer.geometry}
                material={nodes.a_ceiling_outer.material}
                userData={{ name: 'a_ceiling_outer' }}
            />
            <mesh
                geometry={nodes.a_ceiling.geometry}
                material={nodes.a_ceiling.material}
                userData={{ name: 'a_ceiling' }}
            />
            <mesh
                geometry={nodes.a_floor.geometry}
                material={floorMat}
                userData={{ name: 'a_floor' }}
            />
            <mesh
                geometry={nodes.Plane019.geometry}
                material={nodes.Plane019.material}
                position={[-4.9947, -8.7426, -0.8209]}
                userData={{ name: 'Plane.019' }}
            />
            <mesh
                geometry={nodes.Plane008.geometry}
                material={materials.floor_B}
                position={[-4.9947, -8.7426, 0.3951]}
                userData={{ name: 'Plane.008' }}
            />
            <mesh
                geometry={nodes.a_marble.geometry}
                material={materials.a_mat_white_marble}
                userData={{ name: 'a_marble' }}
            />
        </group>
    )
}
