import { ProductsDataItemType } from "./productsData";
import React from "react";
import VS6_InstaView from "../../products/ref/VS6_InstaView";
import VS4_InstaView from "../../products/ref/VS4_InstaView";
import A_nex4 from "../../products/ref/A_nex4";
import P_next3_voice from "../../products/ref/P_next3_voice";
import P_next3 from "../../products/ref/P_next3";

/**
 * 냉장고 데이터
 */
const productsData_Refrigerator: ProductsDataItemType = {
  category: "refrigerator",
  ga_id: "refrigerator",
  label: "category_refrigerator",
  category_img_url: "/images/products/refrigerator/category_img.png",
  category_thumb_url: "/images/products/refrigerator/category_thumb.png",
  category_glow_url: "/images/products/refrigerator/category_glow.png",
  roomType: "kitchen",
  usp_transition: "/movies/usp/transition/main_REF_000_00000.webm",
  productList: [
    {
      name: "VS6 InstaView",
      modelNo: "vs6",
      ga_id: "vs6_instaview",
      isCES: true,
      thumb_url: "/images/products/refrigerator/vs6/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/refrigerator/vs6/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color: "/images/products/refrigerator/vs6/color_steel.png",
          ga_id: "printproof_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color: "/images/products/refrigerator/vs6/color_blackstainsteel.png",
          ga_id: "black_stainless_steel",
        },
      ],
      model: <VS6_InstaView />,
    },
    {
      name: "A-Next4 InstaView",
      modelNo: "ref_06",
      ga_id: "a-next4_instaview",
      // isCES: true,
      thumb_url: "/images/products/refrigerator/a_next4/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/refrigerator/a_next4/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_steel",
          color: "/images/products/refrigerator/a_next4/color_steel.png",
          ga_id: "stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color:
            "/images/products/refrigerator/a_next4/color_blackstainsteel.png",
          ga_id: "black_stainless_steel",
        },
      ],
      model: <A_nex4 />,
    },
    {
      name: "P-Next3 InstaView ThinQ 3.0",
      modelNo: "ref_01",
      ga_id: "p-next3_instaview_thinq_3.0",
      isCES: false,
      thumb_url: "/images/products/refrigerator/p_next3/product_thumb.png",
      ar_url: "http://lgevirtual.com/ar/instaviewthinq",
      ar_text: "home_exp_ar_ref_01",
      openExperience: true,
      open_url: "/images/products/refrigerator/p_next3/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_matteblack",
          color: "/images/products/refrigerator/p_next3/color_matteblack.png",
          ga_id: "matte_black",
        },
      ],
      model: <P_next3 />,
    },
    {
      name: "VS4 InstaView",
      modelNo: "vs4",
      ga_id: "vs4_instaview",
      isCES: false,
      thumb_url: "/images/products/refrigerator/vs4/product_thumb.png",
      ar_url: "http://lgevirtual.com/ar/instaviewdoorindoor/sxs",
      ar_text: "home_exp_ar_vs4",
      openExperience: true,
      open_url: "/images/products/refrigerator/vs4/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color: "/images/products/refrigerator/vs4/color_steel.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <VS4_InstaView />,
    },
    {
      name: "P-Next3 InstaView  w/Voice Recognition",
      modelNo: "p_next3_voice",
      ga_id: "p-next3_instaview_w/voice_recognition",
      isCES: false,
      thumb_url:
        "/images/products/refrigerator/p_next3_voice/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/refrigerator/p_next3_voice/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_matteblack",
          color: "/images/products/refrigerator/p_next3/color_matteblack.png",
          ga_id: "matte_black",
        },
      ],
      model: <P_next3_voice />,
    },
  ],
};

export default productsData_Refrigerator;
