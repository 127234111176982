import React from "react";
import productsData_Refrigerator from "./productsData_Refrigerator";
import productsData_DishWasher from "./productsData_DishWasher";
import productsData_WashernDryer from "./productsData_WashernDryer";
import productsData_AirCare from "./productsData_AirCare";
import { LangLabelType } from "../lang/langData";
import productsData_AirConditioner from "./productsData_AirConditioner";
//import productsData_Tiiun, {productsData_TiiunMini} from "./productsData_Tiiun";
import productsData_Tiiun from "./productsData_Tiiun";
import productsData_Cooking from "./productsData_Cooking";
import productsData_MicrowaveOven from "./productsData_MicrowaveOven";
import productsData_HomeBrew from "./productsData_HomeBrew";
import productsData_WaterPurifier from "./productsData_WaterPurifier";
import productsData_Styler from "./productsData_Styler";
import productsData_VacuumCleaner from "./productsData_VacuumCleaner";
import productsData_WearableAirPurifier from "./productsData_WearableAirPurifier";
import productsData_MaskCase from "./productData_MaskCase";
import productsData_TiiunMini from "./productsData_TiiunMini";

/**
 * LG Home용 제품 데이터
 */

//카테고리 타입
export type ProductsCategoryType =
  | "refrigerator" //냉장고
  | "cooking" //오븐
  | "dishwasher" //식기세척기
  | "water_purifier" //정수기
  | "homebrew" //수제맥주제조기
  | "washer_n_dryer" //세탁기
  | "styler" //스타일러
  | "vacuum_cleaner" //청소기
  | "microwave_oven" //전자레인지
  | "air_conditioner" //에어컨
  | "air_care" //공기청정기
  | "wearable_air_purifier" //마스크
  | "tiiun" //식물재배기
  | "tiiun_mini" //식물재배기 (미니)
  | "lansen_freezer"
  | "mask_case";

//3D공간 타입 (LG Home)
export type RoomType = "living" | "kitchen" | "utility";

//카테고리 데이터 타입
export type ProductsDataItemType = {
  category: ProductsCategoryType; //카테고리 타입
  label?: LangLabelType;
  category_img_url?: string; //카테고리 대표 제품 이미지
  category_thumb_url?: string; //카테고리 대표 섬네일 이미지 (하단 패널용)
  category_glow_url?: string; //카테고리 대표 제품 glow 이미지
  productList?: ProductDataItemType[]; //카테고리 내 제품 데이터 리스트
  roomType: RoomType; //3D 공간 타입
  isHome?: boolean; // (true: LG Home, false: 오브제컬랙션)
  usp_transition?: string;
  ga_id?: string;
};

//제품 색상 데이터 타입
export type ProductColorType = {
  name: React.ReactNode | string; //컬러 명칭
  label?: LangLabelType;
  color: string; //컬러값 (16진수 or 컬러칩 이미지 url)
  ga_id?: string;
};

//단일 제품 데이터 타입
export type ProductDataItemType = {
  name: string; //제품 이름
  modelNo?: string; //제품 모델번호
  thumb_url?: string; //제품 섬네일 이미지
  colors: ProductColorType[]; //제품 컬러 리스트
  isCES?: boolean; //섬네일 위 CES 2022 라벨 표기
  model?: React.ReactNode; //R3F용 gltf모델 노드
  openExperience?: boolean; //문열기 체험 여부
  ar_url?: string; //ar주소
  ar_text?: string | React.ReactNode; //ar설명
  ar_url_2?: string; //추가 ar주소
  ar_text_2?: string | React.ReactNode; //추가 ar설명
  open_url?: string; //냉장고 오픈 이미지
  design_url?: string; //냉장고 오픈 이미지
  usp?: React.ReactNode; //USP 컨텐츠
  ga_id?: string; //GA용 아이디
};

//제품 카테고리 데이터
const productsData: ProductsDataItemType[] = [
  productsData_Refrigerator,
  productsData_Tiiun,
  productsData_TiiunMini,
  productsData_Cooking,
  productsData_MicrowaveOven,
  productsData_DishWasher,
  productsData_HomeBrew,
  productsData_WaterPurifier,
  productsData_WashernDryer,
  productsData_Styler,
  productsData_VacuumCleaner,
  productsData_AirCare,
  productsData_WearableAirPurifier,
  productsData_AirConditioner,
  productsData_MaskCase,
];

//카테고리 타입으로 제품 카테고리 데이터 가져오기
export function getProductsData(category: ProductsCategoryType) {
  const result = productsData.filter((val) => val.category === category);
  return result && result.length > 0 ? result[0] : null;
}

//제품 데이터 리스트에서 모델번호로 단일 제품 데이터 가져오기
export function getProductDataFromList(
  productList: ProductDataItemType[],
  modelNo: string
) {
  const result = productList.filter((val) => val.modelNo === modelNo);
  return result && result.length > 0 ? result[0] : null;
}

export default productsData;
