/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {useLoader} from "@react-three/fiber";
import {TextureLoader} from "three";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";

type GLTFResult = GLTF & {
    nodes: {
        ceiling_inner: THREE.Mesh
        ceiling_outer: THREE.Mesh
        Rectangle2100660130: THREE.Mesh
        Rectangle2100660120: THREE.Mesh
        glasses: THREE.Mesh
        Shelf: THREE.Mesh
        window_frame: THREE.Mesh
        Line2121040472: THREE.Mesh
        Line2121040473: THREE.Mesh
        Wall_kitchen_Type_A: THREE.Mesh
        Art_wall_back_Type_B: THREE.Mesh
        Art_wall_back_Type_C: THREE.Mesh
        Art_wall_Type_A: THREE.Mesh
        Wall_kitchen_Type_B: THREE.Mesh
        Wall_shelf_Type_A: THREE.Mesh
        Art_wall_Type_B: THREE.Mesh
        Plane: THREE.Mesh
        Rectangle2100661003001: THREE.Mesh
        wall_inner: THREE.Mesh
        Line038: THREE.Mesh
        Rectangle2100660139: THREE.Mesh
        Rectangle2100660141: THREE.Mesh
        wall_outer: THREE.Mesh
        Wall_wide_Type_A: THREE.Mesh
        Wall_shelf_Type_B: THREE.Mesh
        Floor_1_Type_A: THREE.Mesh
        Floor_1_Type_C: THREE.Mesh
        Wall_kitchen_Type_C: THREE.Mesh
        Wall_shelf_Type_C: THREE.Mesh
        Art_wall_Type_C: THREE.Mesh
        Wall_wide_Type_B: THREE.Mesh
        Wall_wide_Type_C: THREE.Mesh
        Floor_2_Type_C: THREE.Mesh
        Floor_Deco: THREE.Mesh
        Floor_1_Type_B: THREE.Mesh
        Floor_2_Type_A: THREE.Mesh
        Art_wall_back_Type_A: THREE.Mesh
        Floor: THREE.Mesh
        Floor_2_Type_B: THREE.Mesh
    }
    materials: {
        ['Wall wide_Type_A']: THREE.MeshStandardMaterial
        ['Art wall_Type_B']: THREE.MeshStandardMaterial
        ['Art wall back_Type_A']: THREE.MeshStandardMaterial
        mat_glass: THREE.MeshStandardMaterial
        ['22 - Default']: THREE.MeshStandardMaterial
        ['mat_black_plastic.002']: THREE.MeshStandardMaterial
        ['Material #-2147483568']: THREE.MeshStandardMaterial
        ['Wall kitchen_Type_A']: THREE.MeshStandardMaterial
        ['Art wall back_Type_B']: THREE.MeshStandardMaterial
        ['Art wall back_Type_C']: THREE.MeshStandardMaterial
        ['Art wall_Type_A']: THREE.MeshStandardMaterial
        ['Wall kitchen_Type_B']: THREE.MeshStandardMaterial
        ['Wall shelf_Type_A']: THREE.MeshStandardMaterial
        ['Wall shelf_Type_B']: THREE.MeshStandardMaterial
        ['Floor 1_Type_A']: THREE.MeshStandardMaterial
        ['Floor 1_Type_C']: THREE.MeshStandardMaterial
        ['Wall kitchen_Type_C']: THREE.MeshStandardMaterial
        ['Wall shelf_Type_C']: THREE.MeshStandardMaterial
        ['Art wall_Type_C']: THREE.MeshStandardMaterial
        ['Wall wide_Type_B']: THREE.MeshStandardMaterial
        ['Wall wide_Type_C.001']: THREE.MeshStandardMaterial
        ['Floor 2_Type_C']: THREE.MeshStandardMaterial
        ['Floor 1_Type_B']: THREE.MeshStandardMaterial
        ['Floor 2_Type_A']: THREE.MeshStandardMaterial
        ['Floor 2_Type_B']: THREE.MeshStandardMaterial
    }
}

type ModelProps = {
    interiorFloorIdx?: number;
    interiorWallIdx?: number;
} & JSX.IntrinsicElements['group'];

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/modern_02_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/modern_02_ktx2.glb');


const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/map/modern_02_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/map/modern_02_cyclesbake_DIFFUSE.jpg');

const AO_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/m/map/modern_02_cyclesbake_AO.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/modern/map/modern_02_cyclesbake_AO.jpg');

export default function Model({interiorFloorIdx = 0, interiorWallIdx = 0, ...props}: ModelProps) {
    const group = useRef<THREE.Group>()
    const { nodes, materials, scene } = useKTXLoader(GLTF_URL) as GLTFResult
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);
    const aoTex = useLoader(TextureLoader, AO_MAP_URL);

    const floor1Mat = useMemo(() => {
        if(interiorFloorIdx === 0) return materials['Floor 1_Type_A'];
        else if(interiorFloorIdx === 1) return materials['Floor 1_Type_B'];
        else return materials['Floor 1_Type_C'];
    }, [interiorFloorIdx]);

    const floor2Mat = useMemo(() => {
        if(interiorFloorIdx === 0) return materials['Floor 2_Type_A'];
        else if(interiorFloorIdx === 1) return materials['Floor 2_Type_B'];
        else return materials['Floor 2_Type_C'];
    }, [interiorFloorIdx]);

    const artWallMat = useMemo(() => {
        if(interiorWallIdx === 0) return materials['Art wall_Type_A'];
        else if(interiorWallIdx === 1) return materials['Art wall_Type_B'];
        else return materials['Art wall_Type_C'];
    }, [interiorWallIdx]);

    const artWallBackMat = useMemo(() => {
        if(interiorWallIdx === 0) return materials['Art wall back_Type_A'];
        else if(interiorWallIdx === 1) return materials['Art wall back_Type_B'];
        else return materials['Art wall back_Type_C'];
    }, [interiorWallIdx]);

    const wallWideMat = useMemo(() => {
        if(interiorWallIdx === 0) return materials['Wall wide_Type_A'];
        else if(interiorWallIdx === 1) return materials['Wall wide_Type_B'];
        else return materials['Wall wide_Type_C.001'];
    }, [interiorWallIdx]);


    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        setupScene(scene, lightmapTex, aoTex);

        materials['Art wall_Type_C'].envMapIntensity = 2;
        if(materials['Floor 1_Type_B'].map) materials['Floor 1_Type_B'].map.anisotropy = 4;
        if(materials['Floor 1_Type_B'].roughnessMap) materials['Floor 1_Type_B'].roughnessMap.anisotropy = 4;
        if(materials['Floor 1_Type_B'].metalnessMap) materials['Floor 1_Type_B'].metalnessMap.anisotropy = 4;
        if(materials['Floor 1_Type_B'].normalMap) materials['Floor 1_Type_B'].normalMap.anisotropy = 4;

        materials['Floor 1_Type_A'].color.setRGB(0.3, 0.3, 0.3);
        materials['Floor 1_Type_A'].lightMapIntensity = 2;

        materials['Floor 1_Type_B'].lightMapIntensity = 3;
        materials['Floor 1_Type_C'].lightMapIntensity = 2;
        materials['Floor 1_Type_C'].aoMapIntensity = 0.5;

        materials['Floor 2_Type_B'].lightMapIntensity = 3;
        materials['Floor 2_Type_C'].lightMapIntensity = 6;
        materials['Floor 2_Type_B'].aoMapIntensity = 0.8;
        materials['Floor 2_Type_A'].lightMapIntensity = 3;
        materials['Floor 2_Type_A'].aoMapIntensity = 1;
        materials['Art wall_Type_B'].metalness = 0.1;
        materials['Art wall_Type_B'].roughness = 0.5;

        materials['Art wall_Type_A'].lightMapIntensity = 3;
        materials['Art wall_Type_B'].lightMapIntensity = 1;
        materials['Art wall_Type_B'].aoMapIntensity = 1;
        materials['Art wall back_Type_B'].lightMapIntensity = 3;

        if(materials['Floor 2_Type_B'].map) materials['Floor 2_Type_B'].map.anisotropy = 8;
        if(materials['Floor 2_Type_B'].roughnessMap) materials['Floor 2_Type_B'].roughnessMap.anisotropy = 8;
        if(materials['Floor 2_Type_B'].metalnessMap) materials['Floor 2_Type_B'].metalnessMap.anisotropy = 8;
        if(materials['Floor 2_Type_B'].normalMap) materials['Floor 2_Type_B'].normalMap.anisotropy = 8;

    }, [lightmapTex, aoTex]);

    useEffect(()=>{
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            aoTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, AO_MAP_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <group
                position={[-0.309, 5.9809, -0.0429]}
                scale={[0.001, 0.001, 0.001]}
                userData={{ name: 'Group2136676036' }}>
                <mesh
                    geometry={nodes.ceiling_inner.geometry}
                    material={nodes.ceiling_inner.material}
                    userData={{ name: 'ceiling_inner' }}
                />
                <mesh
                    geometry={nodes.ceiling_outer.geometry}
                    material={nodes.ceiling_outer.material}
                    userData={{ name: 'ceiling_outer' }}
                />
            </group>
            <group
                position={[0.8465, 3.4551, 2.1385]}
                scale={[0.001, 0.001, 0.001]}
                userData={{ name: 'Alt_01' }}>
                <mesh
                    geometry={nodes.Rectangle2100660130.geometry}
                    material={artWallMat}
                    userData={{ name: 'Rectangle2100660130' }}
                />
                <mesh
                    geometry={nodes.Rectangle2100660120.geometry}
                    material={artWallBackMat}
                    userData={{ name: 'Rectangle2100660120' }}
                />
            </group>
            <mesh
                geometry={nodes.glasses.geometry}
                material={materials.mat_glass}
                position={[4.4601, 0.173, 12.0954]}
                scale={[0.001, 0.001, 0.001]}
                userData={{ name: 'glasses' }}
            />
            <mesh
                geometry={nodes.Shelf.geometry}
                material={materials['22 - Default']}
                userData={{ name: 'Shelf' }}
            />
            <mesh
                geometry={nodes.window_frame.geometry}
                material={materials['mat_black_plastic.002']}
                userData={{ name: 'window_frame' }}
            />
            <mesh
                geometry={nodes.Line2121040472.geometry}
                material={nodes.Line2121040472.material}
                userData={{ name: 'Line2121040472' }}
            />
            <mesh
                geometry={nodes.Line2121040473.geometry}
                material={nodes.Line2121040473.material}
                userData={{ name: 'Line2121040473' }}
            />
            <mesh
                geometry={nodes.Wall_kitchen_Type_A.geometry}
                material={materials['Wall kitchen_Type_A']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall kitchen_Type_A' }}
            />
            <mesh
                geometry={nodes.Art_wall_back_Type_B.geometry}
                material={materials['Art wall back_Type_B']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Art wall back_Type_B' }}
            />
            <mesh
                geometry={nodes.Art_wall_back_Type_C.geometry}
                material={materials['Art wall back_Type_C']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Art wall back_Type_C' }}
            />
            <mesh
                geometry={nodes.Art_wall_Type_A.geometry}
                material={materials['Art wall_Type_A']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Art wall_Type_A' }}
            />
            <mesh
                geometry={nodes.Wall_kitchen_Type_B.geometry}
                material={materials['Wall kitchen_Type_B']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall kitchen_Type_B' }}
            />
            <mesh
                geometry={nodes.Wall_shelf_Type_A.geometry}
                material={materials['Wall shelf_Type_A']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall shelf_Type_A' }}
            />
            <mesh
                geometry={nodes.Art_wall_Type_B.geometry}
                material={nodes.Art_wall_Type_B.material}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Art wall_Type_B' }}
            />
            <mesh
                geometry={nodes.Plane.geometry}
                material={wallWideMat}
                userData={{ name: 'Plane' }}
            />
            <mesh
                geometry={nodes.Rectangle2100661003001.geometry}
                material={nodes.Rectangle2100661003001.material}
                userData={{ name: 'Rectangle2100661003.001' }}
            />
            <mesh
                geometry={nodes.wall_inner.geometry}
                material={nodes.wall_inner.material}
                userData={{ name: 'wall_inner' }}
            />
            <mesh
                geometry={nodes.Line038.geometry}
                material={wallWideMat}
                userData={{ name: 'Line038' }}
            />
            <mesh
                geometry={nodes.Rectangle2100660139.geometry}
                material={nodes.Rectangle2100660139.material}
                userData={{ name: 'Rectangle2100660139' }}
            />
            <mesh
                geometry={nodes.Rectangle2100660141.geometry}
                material={wallWideMat}
                userData={{ name: 'Rectangle2100660141' }}
            />
            <mesh
                geometry={nodes.wall_outer.geometry}
                material={nodes.wall_outer.material}
                userData={{ name: 'wall_outer' }}
            />
            <mesh
                geometry={nodes.Wall_wide_Type_A.geometry}
                material={nodes.Wall_wide_Type_A.material}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall wide_Type_A' }}
            />
            <mesh
                geometry={nodes.Wall_shelf_Type_B.geometry}
                material={materials['Wall shelf_Type_B']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall shelf_Type_B' }}
            />
            <mesh
                geometry={nodes.Floor_1_Type_A.geometry}
                material={materials['Floor 1_Type_A']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Floor 1_Type_A' }}
            />
            <mesh
                geometry={nodes.Floor_1_Type_C.geometry}
                material={materials['Floor 1_Type_C']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Floor 1_Type_C' }}
            />
            <mesh
                geometry={nodes.Wall_kitchen_Type_C.geometry}
                material={materials['Wall kitchen_Type_C']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall kitchen_Type_C' }}
            />
            <mesh
                geometry={nodes.Wall_shelf_Type_C.geometry}
                material={materials['Wall shelf_Type_C']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall shelf_Type_C' }}
            />
            <mesh
                geometry={nodes.Art_wall_Type_C.geometry}
                material={materials['Art wall_Type_C']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Art wall_Type_C' }}
            />
            <mesh
                geometry={nodes.Wall_wide_Type_B.geometry}
                material={materials['Wall wide_Type_B']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall wide_Type_B' }}
            />
            <mesh
                geometry={nodes.Wall_wide_Type_C.geometry}
                material={materials['Wall wide_Type_C.001']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Wall wide_Type_C' }}
            />
            <mesh
                geometry={nodes.Floor_2_Type_C.geometry}
                material={materials['Floor 2_Type_C']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Floor 2_Type_C' }}
            />
            <mesh
                geometry={nodes.Floor_Deco.geometry}
                material={floor1Mat}
                userData={{ name: 'Floor_Deco' }}
            />
            <mesh
                geometry={nodes.Floor_1_Type_B.geometry}
                material={nodes.Floor_1_Type_B.material}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Floor 1_Type_B' }}
            />
            <mesh
                geometry={nodes.Floor_2_Type_A.geometry}
                material={materials['Floor 2_Type_A']}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Floor 2_Type_A' }}
            />
            <mesh
                geometry={nodes.Art_wall_back_Type_A.geometry}
                material={nodes.Art_wall_back_Type_A.material}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Art wall back_Type_A' }}
            />
            <mesh
                geometry={nodes.Floor.geometry}
                material={floor2Mat}
                userData={{ name: 'Floor' }}
            />
            <mesh
                geometry={nodes.Floor_2_Type_B.geometry}
                material={nodes.Floor_2_Type_B.material}
                position={[-15.1784, -10.5993, 0]}
                userData={{ name: 'Floor 2_Type_B' }}
            />
        </group>
    )
}
