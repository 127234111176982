/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {TextureLoader} from "three";
import {useEquirectEXR} from "../../../../utils/useEquirect";
import {GLTFLoader} from "three-stdlib";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {useKTXLoader} from "../../../../utils/useKTXLoader";


type GLTFResult = GLTF & {
    nodes: {
        b_props_5005: THREE.Mesh
        d_ceiling_light005: THREE.Mesh
        c_props_merged005: THREE.Mesh
        c_chair005: THREE.Mesh
        a_glass005: THREE.Mesh
        d_floor005: THREE.Mesh
        b_wall005: THREE.Mesh
        a_wall005: THREE.Mesh
        c_props_3005: THREE.Mesh
        b_props_2005: THREE.Mesh
        cabinet_door_02004: THREE.Mesh
        cabinet_door_04004: THREE.Mesh
        b_sink_top_2005: THREE.Mesh
        a_glass008: THREE.Mesh
        a_props_pot3005: THREE.Mesh
        a_props005: THREE.Mesh
    }
    materials: {
        b_mat_metal: THREE.MeshStandardMaterial
        mat_ceiling_light: THREE.MeshStandardMaterial
        ['c_mat_props_merged.001']: THREE.MeshStandardMaterial
        c_props_chair: THREE.MeshStandardMaterial
        glass2: THREE.MeshStandardMaterial
        ['mat_floor.003']: THREE.MeshStandardMaterial
        mat_wall_white: THREE.MeshStandardMaterial
        ['plaster_ultra_fine_spray.001']: THREE.MeshStandardMaterial
        ['c_wood_ipe_2.002']: THREE.MeshStandardMaterial
        b_mat_ceramic: THREE.MeshStandardMaterial
        b_mat_cabinet_door_3: THREE.MeshStandardMaterial
        ['wood_ipe_2.001']: THREE.MeshStandardMaterial
        mat_white_marble: THREE.MeshStandardMaterial
        glass: THREE.MeshStandardMaterial
        a_lambert2SG: THREE.MeshStandardMaterial
        a_mat_props: THREE.MeshStandardMaterial
    }
}


const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/kitchen_dark_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/kitchen_dark_ktx2.glb');

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/map/kitchen_cyclesbake_DIFFUSE.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_DIFFUSE.exr');
const AO_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/m/map/kitchen_cyclesbake_AO.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_AO.jpg');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/kitchen/map/kitchen_cyclesbake_Environment_01.exr';

const Model = React.memo((props: JSX.IntrinsicElements['group']) => {

    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    // @ts-ignore
    const lightmapTex = useLoader(EXRLoader, LIGHT_MAP_URL, (loader: EXRLoader) => {
        if (IS_MOBILE) {
            loader.setDataType(THREE.HalfFloatType);
        } else {
            loader.setDataType(THREE.FloatType);
        }
    });
    const aoTex = useLoader(TextureLoader, AO_URL);
    const envTex = useEquirectEXR(ENV_URL);

    const gl = useThree(state => state.gl);
    const scene2 = useThree(state => state.scene);

    useLayoutEffect(() => {
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;

        aoTex.flipY = false;
        aoTex.needsUpdate = true;

        scene2.environment = envTex;
        gl.toneMappingExposure = 1.2;

        setupScene(scene, lightmapTex, aoTex);
        materials['wood_ipe_2.001'].lightMapIntensity = 2;
        materials['c_wood_ipe_2.002'].lightMapIntensity = 2;

    }, [lightmapTex, aoTex, envTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);

            lightmapTex.dispose();
            aoTex.dispose();
            envTex.dispose();

            useLoader.clear(GLTFLoader, GLTF_URL);
            // //@ts-ignore
            // useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            // useLoader.clear(TextureLoader, AO_URL)
            // //@ts-ignore
            // useLoader.clear(EXRLoader, ENV_URL);
        }
    }, []);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.b_props_5005.geometry}
                material={materials.b_mat_metal}
                userData={{name: 'b_props_5.005'}}
            />
            <mesh
                geometry={nodes.d_ceiling_light005.geometry}
                material={materials.mat_ceiling_light}
                userData={{name: 'd_ceiling_light.005'}}
            />
            <mesh
                geometry={nodes.c_props_merged005.geometry}
                material={materials['c_mat_props_merged.001']}
                userData={{name: 'c_props_merged.005'}}
            />
            <mesh
                geometry={nodes.c_chair005.geometry}
                material={materials.c_props_chair}
                userData={{name: 'c_chair.005'}}
            />
            <mesh
                geometry={nodes.a_glass005.geometry}
                material={materials.glass2}
                userData={{name: 'a_glass.005'}}
            />
            <mesh
                geometry={nodes.d_floor005.geometry}
                material={materials['mat_floor.003']}
                userData={{name: 'd_floor.005'}}
            />
            <mesh
                geometry={nodes.b_wall005.geometry}
                material={materials.mat_wall_white}
                userData={{name: 'b_wall.005'}}
            />
            <mesh
                geometry={nodes.a_wall005.geometry}
                material={materials['plaster_ultra_fine_spray.001']}
                userData={{name: 'a_wall.005'}}
            />
            <mesh
                geometry={nodes.c_props_3005.geometry}
                material={materials['c_wood_ipe_2.002']}
                userData={{name: 'c_props_3.005'}}
            />
            <mesh
                geometry={nodes.b_props_2005.geometry}
                material={materials.b_mat_ceramic}
                userData={{name: 'b_props_2.005'}}
            />
            <mesh
                geometry={nodes.cabinet_door_02004.geometry}
                material={materials.b_mat_cabinet_door_3}
                userData={{name: 'cabinet_door_02.004'}}
            />
            <mesh
                geometry={nodes.cabinet_door_04004.geometry}
                material={materials['wood_ipe_2.001']}
                userData={{name: 'cabinet_door_04.004'}}
            />
            <mesh
                geometry={nodes.b_sink_top_2005.geometry}
                material={materials.mat_white_marble}
                userData={{name: 'b_sink_top_2.005'}}
            />
            <mesh
                geometry={nodes.a_glass008.geometry}
                material={materials.glass}
                userData={{name: 'a_glass.008'}}
            />
            <mesh
                geometry={nodes.a_props_pot3005.geometry}
                material={materials.a_lambert2SG}
                userData={{name: 'a_props_pot3.005'}}
            />
            <mesh
                geometry={nodes.a_props005.geometry}
                material={materials.a_mat_props}
                userData={{name: 'a_props.005'}}
            />
        </group>
    )
});

export default Model;
