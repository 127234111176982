/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../../../utils/useKTXLoader";

type GLTFResult = GLTF & {
    nodes: {
        f_props_basket003: THREE.Mesh
        f_furniture_00_1_props003: THREE.Mesh
        f_furniture_00_1003: THREE.Mesh
        f_furniture_00_1_marble003: THREE.Mesh
    }
    materials: {
        mat_props_basket: THREE.MeshStandardMaterial
        mat_metal_white: THREE.MeshStandardMaterial
        ['mat_cerused_maple_wood.001']: THREE.MeshStandardMaterial
        mat_yule_marble: THREE.MeshStandardMaterial
    }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/m/laundry_dark_module_02_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/laundry_dark_module_02_ktx2.glb');
// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/laundry/laundry_dark_module_02_ktx2.glb';
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_module_02_cyclesbake_DIFFUSE_m.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_module_02_cyclesbake_DIFFUSE.exr');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);

    // @ts-ignore
    const lightmapTex = useLoader(EXRLoader, LIGHT_MAP_URL, (loader: EXRLoader) => {
        if (IS_MOBILE) {
            loader.setDataType(THREE.HalfFloatType);
        } else {
            loader.setDataType(THREE.FloatType);
        }
    });

    useLayoutEffect(()=>{
        lightmapTex.flipY = true;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

    }, [lightmapTex]);


    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
        }
    }, []);


    return (
        <group ref={group} {...props} position={[-0.138, 0, 0]} dispose={null}>
            <group
                position={[10, 0, 0]}
                rotation={[Math.PI / 2, 0, 0]}
                scale={[0.01, 0.01, 0.01]}
                userData={{ name: 'Laundry_Room' }}>
                <group userData={{ name: 'HA_LifeStyle_LaundryRoom' }}>
                    <group userData={{ name: 'HA_LifeStyle_Object' }}>
                        <mesh
                            geometry={nodes.f_props_basket003.geometry}
                            material={materials.mat_props_basket}
                            position={[-1055.7485, -454.8416, -3.8294]}
                            rotation={[-Math.PI / 2, 0, 0]}
                            scale={[13, 13, 13]}
                            userData={{ name: 'f_props_basket.003' }}
                        />
                    </group>
                </group>
            </group>
            <mesh
                geometry={nodes.f_furniture_00_1_props003.geometry}
                material={materials.mat_metal_white}
                userData={{ name: 'f_furniture_00_1_props.003' }}
            />
            <mesh
                geometry={nodes.f_furniture_00_1003.geometry}
                material={materials['mat_cerused_maple_wood.001']}
                userData={{ name: 'f_furniture_00_1.003' }}
            />
            <mesh
                geometry={nodes.f_furniture_00_1_marble003.geometry}
                material={materials.mat_yule_marble}
                userData={{ name: 'f_furniture_00_1_marble.003' }}
            />
        </group>
    )
}
