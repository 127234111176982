import {SpecboardsDataItemType} from "./specboardData";
import {Sup} from "../../components/common/comps_styled/Text.styled";
import React from "react";

/**
 * 세탁기 스펙보드 데이터
 */
const specboardsData_WashernDryer: SpecboardsDataItemType = {
  category: "washer_n_dryer",
  specboardList: [
    {
      name: "WashTower 27＂",
      modelNo: "washtower",
      specboard_img_url: "/images/products/washer_n_dryer/washtower/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "FX Washer & Dryer",
      modelNo: "fx",
      specboard_img_url: "/images/products/washer_n_dryer/fx/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "D3 Dryer",
      modelNo: "REF_01",
      specboard_img_url: "/images/products/washer_n_dryer/d3/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Victor2R Best 27＂ 4in1",
      modelNo: "victor2r",
      specboard_img_url: "/images/products/washer_n_dryer/victor2r/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "Victor2R Best 27＂ 4in1 Dryer",
      modelNo: "victor2r_dryer",
      specboard_img_url: "/images/products/washer_n_dryer/victor2r_dryer/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "TD27 pro2 AGT Washer",
      modelNo: "td_washer",
      specboard_img_url: "/images/products/washer_n_dryer/td27_washer/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "TD27 pro2 Dryer",
      modelNo: "td_dryer",
      specboard_img_url: "/images/products/washer_n_dryer/td27_dryer/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
    {
      name: "FX Washer & Dryer",
      modelNo: "fx",
      specboard_img_url: "/images/products/washer_n_dryer/fx/specboard.png",
      title_img_url: "/images/logos/logo_instaview.png",
      title_img_alt: "instaview",
      spec_img_url: "/images/logos/logo_inverter.png",
      spec_img_alt: "inverter",
      spec: [
        <>
          InstaView<Sup>TM</Sup> Door-in-Door<Sup>TM</Sup>
        </>,
        <>
          LINEARCooling<Sup>TM</Sup>
        </>,
        <>
          Door Cooling+<Sup>TM</Sup>
        </>,
      ],
      thinq_img_url: "/images/logos/logo_thinq.png",
      thinq_img_alt: "thinq",
      thinq_spec: [
        <>LG ThinQ app</>,
        <>works with AI speakers</>
      ]
    },
  ],
};

export default specboardsData_WashernDryer;
