import {useLoader, useThree} from "@react-three/fiber";
import * as THREE from "three";
import {RGBELoader} from "three-stdlib";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {useEffect, useMemo, useState} from "react";
import {useTexture} from "@react-three/drei";
import {UnsignedByteType} from "three";
import {IS_IPHONE, IS_MOBILE} from "../components/common/const";


export const useEquirectHDR = (url: string): THREE.Texture => {
    const gl = useThree(state => state.gl);
    const [pmremGen] = useState(() => {
        const _pmremGen = new THREE.PMREMGenerator(gl);
        _pmremGen.compileEquirectangularShader();
        return _pmremGen;
    });

    // @ts-ignore
    const hdr = useLoader(RGBELoader, url);

    const hdrCubeRenderTarget = useMemo(() => {
        return pmremGen.fromEquirectangular(hdr);
    }, [pmremGen, hdr]);

    return hdrCubeRenderTarget.texture;
};

export const useEquirectEXR = (url: string): THREE.Texture => {
    const gl = useThree(state => state.gl);

    const [pmremGen] = useState(() => {
        const _pmremGen = new THREE.PMREMGenerator(gl);
        _pmremGen.compileEquirectangularShader();
        return _pmremGen;
    });

    // @ts-ignore
    const exr = useLoader(EXRLoader, url, (loader:EXRLoader) => {
        // loader.setDataType(UnsignedByteType);
        if(IS_MOBILE){
            loader.setDataType(THREE.HalfFloatType);
        }else{
            loader.setDataType(THREE.FloatType);
        }
        // console.log("EXRLoader ---->", loader);
    });

    const exrCubeRenderResult = useMemo(()=> {
        const result = pmremGen.fromEquirectangular(exr);
        exr.dispose();
        pmremGen.dispose();
        return result;
    }, [exr, pmremGen]);

    return exrCubeRenderResult.texture;
}


export const useEquirectPNG = (url: string): THREE.Texture => {
    const gl = useThree(state => state.gl);

    const [pmremGen] = useState(() => {
        const _pmremGen = new THREE.PMREMGenerator(gl);
        _pmremGen.compileEquirectangularShader();
        return _pmremGen;
    });

    const tex = useTexture(url);
    tex.encoding = THREE.sRGBEncoding;

    const pngCubeRenderTarget = useMemo(() => {
        return pmremGen.fromEquirectangular(tex);
    }, [pmremGen, tex]);

    return pngCubeRenderTarget.texture;
}
