import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_MicrowaveOven: UspPageDataType[] = [
  {
    category: "microwave_oven",
    modelNo: "convection",
    bgImgUrl: "/images/usp/microwave_oven/convection.jpg",
    uspList: [
      {
        label: "convection_usp_0",
        title: [
          <>
            Keep juicy taste even when you
            <br />
            cook with microwave oven.
          </>,
        ],
        subMsg: [<>Steam Cook with Accessory</>],
        videoUrl: "https://vimeo.com/659698261/ecab0520ba",
        x: 198,
        y: 352,
        ga_id: "steam_cook_with_accessory",
      },
      {
        isRight: true,
        label: "convection_usp_0",
        title: [
          <>
            Don’t need to buy
            <br />
            an air fryer.
          </>,
        ],
        subMsg: [<>Air Fry</>],
        videoUrl: "https://vimeo.com/659698235/03f8cec7c9",
        x: 1202,
        y: 352,
        ga_id: "air_fry",
      },
    ],
  },
  {
    category: "microwave_oven",
    modelNo: "scallet2_best",
    bgImgUrl: "/images/usp/microwave_oven/scallet2_best.jpg",
    uspList: [
      {
        label: "scallet2_best_usp_0",
        title: [
          <>
            Just simply slide out
            <br />
            the ventilation panel
            <br />
            whenever you need it.
          </>,
        ],
        subMsg: [<>Extendavent™</>],
        videoUrl: "https://vimeo.com/659699220/149a2743c1",
        x: 198,
        y: 272,
        ga_id: "extendavent",
      },
      {
        label: "scallet2_best_usp_0",
        title: [
          <>
            Keep juicy taste even when you
            <br />
            cook with microwave oven.
          </>,
        ],
        subMsg: [<>Steam Cook with Accessory</>],
        videoUrl: "https://vimeo.com/659699249/adb5e1ae02",
        x: 198,
        y: 451,
        ga_id: "steam_cook_with_accessory",
      },
      {
        isRight: true,
        label: "scallet2_best_usp_0",
        title: [
          <>
            Do you want to prepare the
            <br />
            perfect food in short time?
          </>,
        ],
        subMsg: [<>Express Cook</>],
        videoUrl: "https://vimeo.com/659699173/45b6e7c2c0",
        x: 1202,
        y: 369,
        ga_id: "express_cook",
      },
    ],
  },
  {
    category: "microwave_oven",
    modelNo: "scallet2_better",
    bgImgUrl: "/images/usp/microwave_oven/scallet2_better.jpg",
    uspList: [
      {
        label: "scallet2_better_usp_0",
        title: [
          <>
            Just simply slide out
            <br />
            the ventilation panel
            <br />
            whenever you need it.
          </>,
        ],
        subMsg: [<>Extendavent™</>],
        videoUrl: "https://vimeo.com/659699850/c6f99f0f5a",
        x: 200,
        y: 320,
        ga_id: "extendavent",
      },
      {
        isRight: true,
        label: "scallet2_better_usp_0",
        title: [
          <>
            Do you want to prepare the
            <br />
            perfect food in short time?
          </>,
        ],
        subMsg: [<>Express Cook</>],
        videoUrl: "https://vimeo.com/659699823/4fcb131379",
        x: 1202,
        y: 436,
        ga_id: "express_cook",
      },
    ],
  },
  {
    category: "microwave_oven",
    modelNo: "scallet2_good",
    bgImgUrl: "/images/usp/microwave_oven/scallet2_good.jpg",
    uspList: [
      {
        isRight: true,
        label: "scallet2_good_usp_0",
        title: [
          <>
            Do you want to prepare the
            <br />
            perfect food in short time?
          </>,
        ],
        subMsg: [<>Express Cook</>],
        videoUrl: "https://vimeo.com/659700128/1ba6fadf8a",
        x: 1202,
        y: 368,
        ga_id: "express_cook",
      },
    ],
  },
  {
    category: "microwave_oven",
    modelNo: "utopia",
    bgImgUrl: "/images/usp/microwave_oven/utopia.jpg",
    uspList: [
      {
        isRight: true,
        label: "utopia_usp_0",
        title: [
          <>
            Do you want to prepare the
            <br />
            perfect food in short time?
          </>,
        ],
        subMsg: [<>Express Cook</>],
        videoUrl: "https://vimeo.com/659700699/08efea6a4a",
        x: 1202,
        y: 368,
        ga_id: "express_cook",
      },
    ],
  },
];

export default uspData_MicrowaveOven;
