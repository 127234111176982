import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Box, Environment, Plane, Preload, Sky, Text} from "@react-three/drei";
import {getProductsData, ProductDataItemType} from "../../../api/products/productsData";
import ProductContainer3D from "../../common/ProductContainer3D";
import {MathUtils, TextureLoader} from "three";
import {IS_MOBILE, Layer, ROOT_PATH_PREFIX} from "../../common/const";
import {useLoader} from "@react-three/fiber";
import Obstacle from "../../common/camera/Obstacle";
import useCameraStore, {CameraState} from "../../../stores/cameraStore";
import useProductStore, {productDic, ProductState} from "../../../stores/productStore";
import useCameraControls from "../../../utils/useCameraControls";
import * as THREE from "three";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ProductInfoButtonGroup2_PC from '../info/ProductInfoButtonGroup2';
import ProductInfoButtonGroup2_Mobile from "../info/mobile/ProductInfoButtonGroup2_M";
import {easeCubicOut} from "d3-ease";
import shallow from "zustand/shallow";

import LaundryRoomModelVivid from "./laundry/LaundryRoomModelVivid";
import LaundryRoomModelVivid_01 from "./laundry/LaundryRoomModelVivid_01";
import LaundryRoomModelVivid_02 from "./laundry/LaundryRoomModelVivid_02";
import LaundryRoomModelDark from "./laundry/LaundryRoomModelDark";
import LaundryRoomModelDark_01 from "./laundry/LaundryRoomModelDark_01";
import LaundryRoomModelDark_02 from "./laundry/LaundryRoomModelDark_02";
import LaundryRoomModelMarble from "./laundry/LaundryRoomModelMarble";
import LaundryRoomModelMarble_01 from "./laundry/LaundryRoomModelMarble_01";
import LaundryRoomModelMarble_02 from "./laundry/LaundryRoomModelMarble_02";
import LaundryRoomModelWood from "./laundry/LaundryRoomModelWood";
import LaundryRoomModelWood_01 from "./laundry/LaundryRoomModelWood_01";
import LaundryRoomModelWood_02 from "./laundry/LaundryRoomModelWood_02";
import LaundryRoomModelWarm from "./laundry/LaundryRoomModelWarm";
import LaundryRoomModelWarm_01 from "./laundry/LaundryRoomModelWarm_01";
import LaundryRoomModelWarm_02 from "./laundry/LaundryRoomModelWarm_02";


// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const cameraApiSelector = (state: CameraState) => state.api;
const selectedCategorySelector = (state: ProductState) => state.selectedCategory;
const selectedRoomSelector = (state: ProductState) => state.selectedRoom

const ProductInfoButtonGroup2 = IS_MOBILE ? ProductInfoButtonGroup2_Mobile : ProductInfoButtonGroup2_PC;

const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_cyclesbake_DIFFUSE_m.exr') :
    (ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_cyclesbake_DIFFUSE.exr');
const ENV_URL = ROOT_PATH_PREFIX + '/RP/rooms/laundry/map/laundry_cyclesbake_Environment_01.exr';



const UtilityRoom = React.memo(() => {

    const productData_washer_n_dryer = useMemo(() => getProductsData("washer_n_dryer"), []);
    const productData_styler = useMemo(() => getProductsData("styler"), []);
    const productData_vacuum_cleaner = useMemo(() => getProductsData("vacuum_cleaner"), []);

    const {setTargetPos, setLookPos, setTouchStart} = useCameraStore(cameraApiSelector);
    const selectedCategory = useProductStore(selectedCategorySelector);
    const selectedProduct = useProductStore(state => state.selectedProduct);
    const selectedRoom = useProductStore(selectedRoomSelector);
    const [cameraControls, rotateToTarget, cancelAnimation] = useCameraControls();
    const lgHomeTheme = useSpaceThemeStore(state => state.lgHomeTheme);

    const refLightTargetRef = useRef<THREE.Object3D>(null!);
    const refLightRef = useRef<THREE.SpotLight>(null!);
    const refLightTargetRef2 = useRef<THREE.Object3D>(null!);
    const refLightRef2 = useRef<THREE.SpotLight>(null!);


    useLayoutEffect(() => {
        if (selectedRoom === 'utility') {
            if (selectedCategory) {
                cameraControls.minAzimuthAngle = -20 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxAzimuthAngle = 20 * THREE.MathUtils.DEG2RAD;
                cameraControls.minPolarAngle = 70 * THREE.MathUtils.DEG2RAD;
                cameraControls.maxPolarAngle = 90 * THREE.MathUtils.DEG2RAD;

                if (selectedCategory.category === "styler") {
                    cameraControls.minAzimuthAngle = (-30) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (0) * THREE.MathUtils.DEG2RAD;
                } else if (selectedCategory.category === "washer_n_dryer") {
                    cameraControls.minAzimuthAngle = (-30) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (10) * THREE.MathUtils.DEG2RAD;
                } else if (selectedCategory.category === "vacuum_cleaner") {
                    cameraControls.minAzimuthAngle = (-20 + 90) * THREE.MathUtils.DEG2RAD;
                    cameraControls.maxAzimuthAngle = (20 + 90) * THREE.MathUtils.DEG2RAD;
                }
            } else {
                cameraControls.minAzimuthAngle = -Infinity;
                cameraControls.maxAzimuthAngle = Infinity;
                cameraControls.minPolarAngle = -Infinity;
                cameraControls.maxPolarAngle = Infinity;
            }
        }
    }, [selectedCategory, selectedRoom]);

    useEffect(() => {
        if (refLightRef.current) {
            refLightRef.current.target = refLightTargetRef.current;
        }
        if (refLightRef2.current) {
            refLightRef2.current.target = refLightTargetRef2.current;
        }

        return () => {
            console.log("세탁실 공간 해제");

            //@ts-ignore
            useLoader.clear(EXRLoader, LIGHT_MAP_URL);
            //@ts-ignore
            useLoader.clear(EXRLoader, ENV_URL);
        }

    }, []);




    const [isTwin, setIsTwin] = useState(false);
    //첫 진입 시 세탁기 타입 체크
    useLayoutEffect(()=>{
        if(productData_washer_n_dryer &&
            productData_washer_n_dryer.productList &&
            productData_washer_n_dryer.productList.length > 0)
        {
            if(productData_washer_n_dryer.productList[0].modelNo !== 'washtower'){
                setIsTwin(true);
            }
        }
    }, []);
    useLayoutEffect(()=>{
        //세탁기 타입을 선택했고
        if(selectedCategory && selectedCategory.category === 'washer_n_dryer')
        {
            //워시타워를 선택했으면
            if(selectedProduct && selectedProduct.modelNo === 'washtower')
            {
                setIsTwin(false);
            }else{
                setIsTwin(true);
            }
        }
    }, [selectedProduct, selectedCategory]);




    const {targetPos, lookPos, isOpenInfo} = useCameraStore(state => ({
        targetPos: state.targetPos,
        lookPos: state.lookPos,
        isOpenInfo: state.isOpenInfo
    }), shallow);

    //제품 선택 시 포커스 맞추기
    function focusToProduct(offset: [number, number, number] = [0, 0, 0]) {
        if (targetPos && lookPos) {
            rotateToTarget([targetPos.x + offset[0], targetPos.y + offset[1], targetPos.z + offset[2]], [lookPos.x + offset[0], lookPos.y + offset[1], lookPos.z + offset[2]], () => {
                cameraControls.enabled = true;
            }, 1, 1, easeCubicOut);
        }
    }

    return (
        <group>
            <React.Suspense fallback={null}>
                {IS_MOBILE && (<Environment preset={'studio'} />)}

                {lgHomeTheme === 'vivid' && (
                    <>
                        <LaundryRoomModelVivid/>
                        <LaundryRoomModelVivid_01 visible={!isTwin}/>
                        <LaundryRoomModelVivid_02 visible={isTwin}/>
                    </>
                )}
                {lgHomeTheme === 'dark' && (
                    <>
                        <LaundryRoomModelDark/>
                        <LaundryRoomModelDark_01 visible={!isTwin}/>
                        <LaundryRoomModelDark_02 visible={isTwin}/>
                    </>

                )}
                {lgHomeTheme === 'marble' && (
                    <>
                        <LaundryRoomModelMarble/>
                        <LaundryRoomModelMarble_01 visible={!isTwin}/>
                        <LaundryRoomModelMarble_02 visible={isTwin}/>
                    </>
                )}
                {lgHomeTheme === 'warm' && (
                    <>
                        <LaundryRoomModelWarm/>
                        <LaundryRoomModelWarm_01 visible={!isTwin}/>
                        <LaundryRoomModelWarm_02 visible={isTwin}/>
                    </>
                )}
                {lgHomeTheme === 'wood' && (
                    <>
                        <LaundryRoomModelWood/>
                        <LaundryRoomModelWood_01 visible={!isTwin}/>
                        <LaundryRoomModelWood_02 visible={isTwin}/>
                    </>
                )}
                <Preload all/>
            </React.Suspense>

            {/* 이동 제한을 위한 콜라이더 설치 */}
            <Obstacle size={[10, 4, 2]} position={[0, 2, -4.5]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            <Obstacle size={[10, 4, 2]} position={[0, 2, 2.9]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            <Obstacle size={[2, 4, 10]} position={[3.8, 2, 0]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>
            <Obstacle size={[2, 4, 10]} position={[-3.8, 2, 0]} color={"red"} type={"Static"} visible={false}
                      collisionFilterGroup={Layer.TriggerArea | Layer.Character}/>

            {/* 바닥 히트처리 */}
            <Plane args={[6, 5.5]} position={[0, 0.01, -1]} rotation={[-Math.PI / 2, 0, 0]}
                   onClick={(e) => {
                       console.log(e);
                       e.stopPropagation();
                       if (e.delta < 10) {
                           setLookPos(null);
                           setTargetPos(e.point);
                       }
                   }}>
                <meshBasicMaterial color={"#ff3"} transparent={true} opacity={0}/>
            </Plane>

            <Box args={[1, 1, 1]} position={[2.9, 1, -4.45]} ref={refLightTargetRef} visible={false}>
                <meshBasicMaterial color={"#f00"}/>
            </Box>
            <spotLight position={[3.3, 2.6, -1]} ref={refLightRef} angle={Math.PI * 0.5} penumbra={1} intensity={0.5}/>

            <Box args={[1, 1, 1]} position={[-3.4, 0.95, -0.95]} ref={refLightTargetRef2} visible={false}>
                <meshBasicMaterial color={"#f00"}/>
            </Box>
            <spotLight position={[-3.4, 2, -0.95]} ref={refLightRef2} angle={0.6} penumbra={1} intensity={1}/>


            <ProductContainer3D
                args={[1.3, 1.1, 1]}
                position={[1.925, 0.55, -4.6]}
                rotation={[0, 0, 0]}
                category={'washer_n_dryer'}
                data={productData_washer_n_dryer}
                showDebug={false}
                matchingModelNo={['td_washer', 'fx', 'victor2r']}
                uiScale={0.9}
                uiOffset={
                    (selectedProduct?.modelNo === 'td_washer') ? [-0.05, 0, -0.1] : [-0.05, 0.25, -0.1]
                }
                uiInfoOffset={
                    IS_MOBILE ? [-1.1, -0.1, 0] : [-0.7, 0, 0]
                }
                cameraTargetPos={[0, 0, 3]}
                cameraLookOffset={[0, 0, 0]}
            >
                {selectedCategory?.category === 'washer_n_dryer' &&
                selectedProduct?.modelNo !== 'washtower' && (
                    <ProductInfoButtonGroup2
                        position={
                            selectedProduct?.modelNo === 'td_washer' ? [0.75, 0.75, 0.5] :
                                (IS_MOBILE ? [0.78, 1.1, 0.5] : [0.78, 1, 0.5])
                        }
                        scale={0.9}
                        // rotation={[-0.25, 0, 0]}
                        specboardOffset={
                            selectedProduct?.modelNo === 'victor2r' ?
                                (IS_MOBILE ? [1.4, 0, 0] : [1.3, -0.03, 0]) :
                                (selectedProduct?.modelNo === 'fx' ?
                                    (IS_MOBILE ? [1.4, 0, 0] : [1.3, 0, 0]) :
                                    (IS_MOBILE ? [1.4, 0, 0] : [1.32, 0, 0]))
                        }
                        specImgUrl={
                            selectedProduct?.modelNo === 'victor2r' ?
                                "/images/products/washer_n_dryer/vicor2r_dryer/specboard.png" :
                                (selectedProduct?.modelNo === 'fx' ?
                                    "/images/products/washer_n_dryer/d3/specboard.png" :
                                    "/images/products/washer_n_dryer/td27_dryer/specboard.png") //td
                        }
                        specboardCallback={(show: boolean) => {
                            if (!show) console.log(">스펙보드 닫힘!");
                            focusToProduct(show ? [0.9, 0, 0] : [0, 0, 0]);
                        }}
                    />
                )}
            </ProductContainer3D>


            <ProductContainer3D
                args={[0.64, 1.8, 0.8]}
                position={[2.28, 0.93, -4.45]}
                rotation={[0, 0, 0]}
                category={'washer_n_dryer'}
                data={productData_washer_n_dryer}
                showDebug={false}
                matchingModelNo={['washtower']}
                uiScale={0.9}
                uiOffset={[0.025, 0, 0]}
                uiInfoOffset={[-1, 0, 0]}
                cameraTargetPos={[0, 0, 3]}  // 211221
            >
            </ProductContainer3D>

            <ProductContainer3D
                args={[0.5, 1.8, 0.5]}
                position={[2.9, 0.9, -4.335]}
                rotation={[0, 0, 0]}
                category={'styler'}
                data={productData_styler}
                showDebug={false}
                // matchingModelNo={['baron']}
                uiScale={0.9}
                uiOffset={
                    IS_MOBILE ? [1, 0.03, 0] : [0.85, 0.03, 0]
                }
                specboardOffset={
                    IS_MOBILE ? [-0.95, 0, 0] : [-0.75, 0, 0]
                }
                cameraTargetPos={[0, 0, 3]} // 211221
                uiInfoOffset={[-1, 0, 0]} // 211222
            >
            </ProductContainer3D>


            <ProductContainer3D
                args={[0.4, 0.4, 0.3]}
                position={[-3.5, 0.25, -0.95]}
                rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                category={'vacuum_cleaner'}
                data={productData_vacuum_cleaner}
                showDebug={false}
                matchingModelNo={['m9', 'r9']}
                uiScale={0.6}
                uiOffset={[0.1, 0.1, -0.2]}
                uiRotation={[-MathUtils.DEG2RAD * 17, 0, 0]}
                uiInfoOffset={
                    IS_MOBILE ? [0, -0.1, 0.5] : [0, 0, 0]
                }
                specboardOffset={
                    IS_MOBILE ? [-0.15, 0.45, 0] : [-0.15, 0, 0]
                }
                cameraTargetPos={[0, 0.5, 1.5]}
            >
            </ProductContainer3D>


            <ProductContainer3D
                args={[0.4, 1.2, 0.3]}
                position={[-3.6, 0.65, -0.95]}
                rotation={[0, MathUtils.DEG2RAD * 90, 0]}
                category={'vacuum_cleaner'}
                data={productData_vacuum_cleaner}
                showDebug={false}
                matchingModelNo={['a9t', 'a9k']}
                uiScale={0.8}
                uiOffset={
                    (selectedProduct?.modelNo === 'a9t') ?
                        (IS_MOBILE ? [0.1, 0, 0] : [0.1, -0.02, 0]) :
                        (IS_MOBILE ? [0.1, -0.15, 0] : [0.1, -0.15, 0])
                }
                uiInfoOffset={
                    (selectedProduct?.modelNo === 'a9t') ?
                        (IS_MOBILE ? [0,-0.5,1] : [0,0,0]) :
                        (IS_MOBILE ? [0,0,1] : [0,0,0])
                }
                specboardOffset={[-0.15, -0.02, 0]}
                cameraTargetPos={[0, 0, 2.5]}  // 211221
            >
            </ProductContainer3D>


            <Sky
                distance={150000}
                sunPosition={[-1, 0.5, 0]}
                inclination={0}
                azimuth={0.35}
            />
        </group>
    );
});

export default UtilityRoom;
