export const BREAK_POINT_MOBILE = 768;
export const BREAK_POINT_TABLET = 992;
export const BREAK_POINT_PC = 1200;

export const KEY_COLOR = '#ac2551';

export const Z_INDEX_THINQ =        100000;
export const Z_INDEX_TOP_UI =       20000000;
export const Z_INDEX_BOTTOM_UI =    90000000;
export const Z_INDEX_POPUP =        99000000;
export const Z_INDEX_LANDSCAPE =        99900000;

export enum Layer {
    None = 0,
    Ground = 1 << 0,
    Character = 1 << 2,
    TriggerArea = 1 << 3,
};

//@ts-ignore
export const ROOT_PATH_PREFIX = window.rootPathPrefix ?? '';
//@ts-ignore
export const DOMAIN_URL = window.domainUrl ?? '';

//디바이스 체크
export const IS_MOBILE = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
export const IS_ANDROID = /Android/i.test(navigator.userAgent);
export const IS_IPHONE = /iPhone|iPad|iPod/i.test(navigator.userAgent);
export const IS_APPLE_DEVICE = /iPhone|iPad|iPod|Macintosh/i.test(navigator.userAgent);
export const IS_CHROME = /Chrome/i.test(navigator.userAgent);

export const IS_LOW_TIER = IS_IPHONE;
