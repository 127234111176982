import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useModalDesign } from "../../../stores/modalStore";
import useProductStore from "../../../stores/productStore";
import { Image } from "../../common/comps_styled/Image.styled";
import { ROOT_PATH_PREFIX, Z_INDEX_POPUP } from "../../common/const";
import CloseButton from "../../common/navigation/CloseButton";
import { GA_Event } from "../../../api/ga";
import useResizeStore from "../../../stores/resizeStore";

const DesignBoard = (props: JSX.IntrinsicElements["div"]) => {
  //
  const setModalOpen = useModalDesign((state) => state.setIsOpen);
  const containerRef = useRef<HTMLDivElement>(null!);
  const resize = useResizeStore((state) => state.resize);
  // for GAEvent
  const ga_product = useProductStore((state) => state.selectedCategory?.ga_id);

  const designURL =
    useProductStore((state) => state.selectedProduct?.design_url) ??
    "/images/products/washer_n_dryer/fx/design.png";

  return (
    <Wrapper>
      <Panel
        style={{ width: 1700 * resize.ratio, height: 1028 * resize.ratio }}
      >
        <CloseButton
          style={{
            position: "absolute",
            zIndex: Z_INDEX_POPUP,
            top: "5%",
            right: "4%",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setModalOpen(false);
            GA_Event("lg_home", `design`, "close");
            // GA_Event("lg_home", `${ga_product}_design`, "close");
          }}
        />
        <Image
          alt="open design"
          role="img"
          src={ROOT_PATH_PREFIX + designURL}
        />
      </Panel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  z-index: ${Z_INDEX_POPUP};
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  pointer-events: visible;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Panel = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 1.2em;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  & > div {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: flex-end;
  }

  & div > button {
    position: relative;
    top: 1%;
    right: 1%;
  }
`;

export default DesignBoard;
