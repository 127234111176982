import create from "zustand";

export type ResizeType = {
  resize: { width: number; height: number; ratio: number };
  setResize: (width: number, height: number, ratio: number) => void;
};

const useResizeStore = create<ResizeType>((set, get) => ({
  resize: { width: 1920, height: 1080, ratio: 1 },
  setResize: (width, height, ratio) =>
    set({ resize: { width, height, ratio } }),
}));

export default useResizeStore;
