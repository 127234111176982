import {useLoader, useThree} from "@react-three/fiber";
import {IS_APPLE_DEVICE, IS_IPHONE} from "../components/common/const";
import {KTX2Loader} from "three/examples/jsm/loaders/KTX2Loader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader";


class KTXLoaderSingleton {
    private static instance : KTX2Loader;
    public static getInstance() : KTX2Loader{
        if(!this.instance){
            this.instance = new KTX2Loader();
            this.instance.setTranscoderPath('js/libs/basis/');
            this.instance.setWorkerLimit(8);
        }
        return this.instance;
    }
}

class DracoLoaderSingleton {
    private static instance : DRACOLoader;
    public static getInstance() : DRACOLoader{
        if(!this.instance) {
            this.instance = new DRACOLoader();
            this.instance.setDecoderPath('js/libs/draco/');
        }
        return this.instance;
    }
}

export const useKTXLoader = <T>(url: string): T => {
    const gl = useThree(state => state.gl);

    //@ts-ignore
    return useLoader(GLTFLoader, url, (loader: GLTFLoader) => {
        // const useKtx2 = (url.indexOf('ktx2') > -1);
        loader.setDRACOLoader(DracoLoaderSingleton.getInstance());

        const ktx2Loader = KTXLoaderSingleton.getInstance();
        ktx2Loader.detectSupport(gl);
        loader.setKTX2Loader(ktx2Loader);

        if(IS_APPLE_DEVICE)
        {
            //@ts-ignore
            if(ktx2Loader){
                const workerConfig = ktx2Loader['workerConfig'];
                workerConfig.etc1Supported = false;
            }
        }
    }) as T;
}
