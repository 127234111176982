/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import sleep from "../../../../utils/sleep";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {Objet_ColorCategoryType, water_purifier_undersink_data} from "../../../../api/products/objetProductsData";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {LangLabelType} from "../../../../api/lang/langData";
import {disposeScene} from "../../../../utils/disposeScene";
import {GLTFLoader} from "three-stdlib";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "./Product_Harvester";
import useHistoryStore from "../../../../stores/historyStore";
import {TextureLoader} from "three";
import {a} from "@react-spring/three";
import {animated, useSpring} from "@react-spring/web";
import {easeCubicInOut} from "d3-ease";
import {useModalTutorial} from "../../../../stores/modalStore";
import ObjetPlusButton from "../../ObjetPlusButton";

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
// const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/oven/Lightmap_Oven.jpg';

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom_u_sts/black_mat_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom_u_sts/black_mat_body_merged_BaseColor.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/atom_u_sts/brown_mat_body_merged_BaseColor.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/atom_u_sts/brown_mat_body_merged_BaseColor.jpg');


export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);

    const scene2 = useThree(state => state.scene);

    const pathname = useHistoryStore(state => state.pathname);
    const isActiveLnF = useMemo(() => pathname.indexOf('lansen_freezer') > -1, [pathname]);

    const colorCategory = useObjetColorStore(state => state.water_purifier_undersink_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.water_purifier_undersink_ColorLabel);

    const colorTextures = useTexture([COLOR_0_URL, COLOR_1_URL]);

    const isOpenTutorial = useModalTutorial(state => state.isOpen);
    const [isSet, setIsSet] = useState(false);
    const [spring, animate] = useSpring(() => ({
        from: {
            opacity: 0
        }
    }));

    useEffect(() => {
        animate({cancel: true});
        if (!isOpenTutorial) {
            animate({
                cancel: false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config: {duration: 3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) => {
        if (colorCategory === 'Solid') {
            if (colorLabel === 'objet_color_black') {
                if (!isFirst) setIsSet(true);
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            } else if (colorLabel === 'objet_color_clay_brown') {
                if (!isFirst) setIsSet(true);
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            }
        }
    }, [colorTextures, materials]);

    useLayoutEffect(() => {
        if (scene2.environment) {
            materials.mat_atom_u_sts_body.envMapIntensity = 1.5;
        }
    }, [scene2]);

    useLayoutEffect(() => {
        changeTexture('mat_atom_u_sts_body', colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }

            useLoader.clear(TextureLoader, [COLOR_0_URL, COLOR_1_URL]);
        }
    }, []);


    return (
        <group ref={group} {...props} visible={!isActiveLnF} dispose={null}>
            <mesh
                geometry={nodes.atom_u_sts.geometry}
                material={nodes.atom_u_sts.material}
                position={[2.4112, 1.33, -2.4241]}
                rotation={[Math.PI, -1.0763, Math.PI]}>
                <mesh geometry={nodes.Iron001.geometry} material={nodes.Iron001.material}/>
                <mesh geometry={nodes.Decal005.geometry} material={materials.mat_atom_u_sts_decal}/>
            </mesh>

            <ObjetProductContainer3D
                args={[0.18, 0.3, 0.18]}
                position={[2.41, 1.5, -2.42]}
                rotation={[0, -Math.PI * 0.5, 0]}
                data={water_purifier_undersink_data}
                showDebug={false}
                cameraTargetPos={[0, 1, 1]}
            />

            <mesh
                geometry={nodes.atom_u_sts.geometry}
                position={[2.4112, 1.33, -2.4241]}
                rotation={[Math.PI, -1.0763, Math.PI]}
                visible={!isSet}
            >
                <mesh geometry={nodes.Iron001.geometry} material={nodes.Iron001.material}>
                    {/* @ts-ignore */}
                    <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                         opacity={spring.opacity}/>
                </mesh>

                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0.2, 0]} visible={!(isSet || isActiveLnF)} opacity={spring.opacity}
                                 color={pathname.indexOf('/water_purifier_undersink') > -1 ? '#be1e42' : '#000'}/>
            </mesh>
        </group>
    )
}
