/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {disposeScene, setupScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import {GLTFResult_NaturalRoom} from "./NaturalRoomFloor";

type GLTFResult = GLTF & {
    nodes: {
        Plane014: THREE.Mesh
        Plane004: THREE.Mesh
        Plane006: THREE.Mesh
        Plane003: THREE.Mesh
        b_wood_frame: THREE.Mesh
        b_shelf: THREE.Mesh
        Plane018: THREE.Mesh
        b_wall_outer: THREE.Mesh
        b_wall: THREE.Mesh
        Plane002: THREE.Mesh
        b_wall_deco: THREE.Mesh
        Plane005: THREE.Mesh
        Plane013: THREE.Mesh
        Plane007: THREE.Mesh
    }
    materials: {
        wall_B: THREE.MeshStandardMaterial
        wall_C: THREE.MeshStandardMaterial
        art_wall_deco_B: THREE.MeshStandardMaterial
        shelf_B: THREE.MeshStandardMaterial
        wood_frame: THREE.MeshStandardMaterial
        shelf_A: THREE.MeshStandardMaterial
        wall_A: THREE.MeshStandardMaterial
        art_wall_deco_A: THREE.MeshStandardMaterial
        shelf_C: THREE.MeshStandardMaterial
        art_wall_deco_C: THREE.MeshStandardMaterial
    }
}


type ModelProps = {
    interiorIdx?: number;
} & JSX.IntrinsicElements['group'];

// const GLTF_URL = ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_wall.glb';
const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_wall_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_wall_ktx2.glb');
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_Wall_Bake1_cyclesbake_DIFFUSE.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_Wall_Bake1_cyclesbake_DIFFUSE.jpg');


export default function Model({interiorIdx = 0, ...props}: ModelProps) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        setupScene(scene, lightmapTex);

        // materials.b_mat_wood_ipe.lightMapIntensity = 5;
        // materials.b_mat_wallpaper.lightMapIntensity = 5;
        // materials.mat_wood_ipe.lightMapIntensity = 5;
        // materials.a_mat_white_marble.lightMapIntensity = 5;
        materials.wood_frame.lightMapIntensity = 5;
        materials.wall_A.lightMapIntensity = 5;
        materials.wall_B.lightMapIntensity = 3;
        materials.wall_C.lightMapIntensity = 2;
        materials.shelf_A.lightMapIntensity = 5;
        materials.shelf_B.lightMapIntensity = 1;
        materials.shelf_C.lightMapIntensity = 2;
        materials.art_wall_deco_A.lightMapIntensity = 5;
        materials.art_wall_deco_B.lightMapIntensity = 5;
        materials.art_wall_deco_C.lightMapIntensity = 5;

    }, [lightmapTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            // for (let i = 0; i < brickTextures.length; i++) {
            //     brickTextures[i].dispose();
            // }

            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
        }
    }, []);


    const wallMat = useMemo(()=>{
        if(interiorIdx === 0) return materials.wall_A;
        else if(interiorIdx === 1) return materials.wall_B;
        else return materials.wall_C;
    }, [interiorIdx]);

    const wallDecoMat = useMemo(()=>{
        if(interiorIdx === 0) return materials.art_wall_deco_A;
        else if(interiorIdx === 1) return materials.art_wall_deco_B;
        else return materials.art_wall_deco_C;
    }, [interiorIdx]);

    const shelfMat = useMemo(()=>{
        if(interiorIdx === 0) return materials.shelf_A;
        else if(interiorIdx === 1) return materials.shelf_B;
        else return materials.shelf_C;
    }, [interiorIdx]);


    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.Plane014.geometry}
                material={materials.wall_B}
                position={[2.3445, -8.7269, 0.3951]}
            />
            <mesh
                geometry={nodes.Plane004.geometry}
                material={materials.wall_C}
                position={[2.3445, -8.7269, 1.6449]}
            />
            <mesh
                geometry={nodes.Plane006.geometry}
                material={materials.art_wall_deco_B}
                position={[3.8328, -8.7426, 0.3951]}
            />
            <mesh
                geometry={nodes.Plane003.geometry}
                material={materials.shelf_B}
                position={[0.7384, -8.7426, 0.3951]}
            />
            <mesh geometry={nodes.b_wood_frame.geometry} material={materials.wood_frame} />
            <mesh geometry={nodes.b_shelf.geometry} material={shelfMat} />
            <mesh
                geometry={nodes.Plane018.geometry}
                material={nodes.Plane018.material}
                position={[0.7384, -8.7426, -0.8209]}
            />
            <mesh geometry={nodes.b_wall_outer.geometry} material={nodes.b_wall_outer.material} />
            <mesh geometry={nodes.b_wall.geometry} material={wallMat} />
            <mesh
                geometry={nodes.Plane002.geometry}
                material={nodes.Plane002.material}
                position={[2.3445, -8.7426, -0.8209]}
            />
            <mesh geometry={nodes.b_wall_deco.geometry} material={wallDecoMat} />
            <mesh
                geometry={nodes.Plane005.geometry}
                material={nodes.Plane005.material}
                position={[3.8328, -8.7426, -0.8209]}
            />
            <mesh
                geometry={nodes.Plane013.geometry}
                material={materials.shelf_C}
                position={[0.7384, -8.7426, 1.6449]}
            />
            <mesh
                geometry={nodes.Plane007.geometry}
                material={materials.art_wall_deco_C}
                position={[3.8328, -8.7426, 1.6449]}
            />
        </group>
    )
}
