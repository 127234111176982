//-------------------------------------------- Solid
import {ProductColorType} from "../productsData";

//Dishwasher
export const Dishwasher_Solid_Green: ProductColorType = {
    name: <>Manhattan<br/>Midnight</>,
    label: 'objet_color_green',
    color: '/images/objet/solid/dishwasher/green.png',
    ga_id: "green"
};
export const Dishwasher_Solid_Silver: ProductColorType = {
    name: <>Silver</>,
    label: 'objet_color_silver',
    color: '/images/objet/solid/dishwasher/silver.png',
    ga_id: "silver"
};

//Freeze & Freezer
export const Freeze_Solid_Beige: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_beige',
    color: '/images/objet/solid/freeze_n_freezer/beige.png',
    ga_id: "beige"
};
export const Freeze_Solid_Green: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_green',
    color: '/images/objet/solid/freeze_n_freezer/green.png',
    ga_id: "green"
};
export const Freeze_Solid_Mint: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_mint',
    color: '/images/objet/solid/freeze_n_freezer/mint.png',
    ga_id: "mint"
};
export const Freeze_Solid_Pink: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_pink',
    color: '/images/objet/solid/freeze_n_freezer/pink.png',
    ga_id: "pink"
};
export const Freeze_Solid_Silver: ProductColorType = {
    name: <>Green</>,
    label: 'objet_color_silver',
    color: '/images/objet/solid/freeze_n_freezer/silver.png',
    ga_id: "silver"
};

//Microwave Oven
export const MicrowaveOven_Solid_Green: ProductColorType = {
    name: <>Beige</>,
    label: 'objet_color_green',
    color: '/images/objet/solid/microwave_oven/green.png',
    ga_id: "green"
};
export const MicrowaveOven_Solid_Silver: ProductColorType = {
    name: <>Pink</>,
    label: 'objet_color_silver',
    color: '/images/objet/solid/microwave_oven/silver.png',
    ga_id: "silver"
};

//Refrigerator
export const Refrigerator_Solid_Green: ProductColorType = {
    name: <>Mint</>,
    label: 'objet_color_green',
    color: '/images/objet/solid/refrigerator/green.png',
    ga_id: "green"
};
export const Refrigerator_Solid_Manhattan_Midnight: ProductColorType = {
    name: <>Mint</>,
    label: 'objet_color_midnight',
    color: '/images/objet/solid/refrigerator/manhattan_midnight.png',
    ga_id: "manhattan_midnight"
};
export const Refrigerator_Solid_Silver: ProductColorType = {
    name: <>Mint</>,
    label: 'objet_color_silver',
    color: '/images/objet/solid/refrigerator/silver.png',
    ga_id: "silver"
};

//Water Purifier undersink
export const WaterPurifier_Solid_Clay_Brown: ProductColorType = {
    name: <>Clay Brown</>,
    label: 'objet_color_clay_brown',
    color: '/images/objet/solid/water_purifier/clay_brown.png',
    ga_id: "clay_brown"
};
export const WaterPurifier_Solid_Black: ProductColorType = {
    name: <>Black</>,
    label: 'objet_color_black',
    color: '/images/objet/solid/water_purifier/black.png',
    ga_id: "black"
};

