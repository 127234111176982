import React from "react";
import {ProductColorType, ProductsCategoryType} from "./productsData";
import {
    AirConditiner_Calming_Beige, AirConditioner_Calming_Green,
    VacuumCleaner_Calming_Beige, VacuumCleaner_Calming_Green,
    WaterPurifier_Calming_Beige, WaterPurifier_Calming_Cotton_Sky,
    WaterPurifier_Calming_Green, WaterPurifier_Calming_Pale_Rose
} from "./objetColors/objetColorData_Calming";
import {Refrigerator_Fenix_Botanic, Refrigerator_Fenix_Sand, Refrigerator_Fenix_Stone
} from "./objetColors/objetColorData_Fenix";
import {
    Freeze_Mist_Black, Freeze_Mist_Clay_Brown, Freeze_Mist_Gray,
    Freeze_Mist_Redwood, Freeze_Mist_Silver, Freeze_Mist_White,
    MicrowaveOven_Mist_Beige,
    Refrigerator_Mist_Beige, Refrigerator_Mist_Clay_Brown, Refrigerator_Mist_Mint,
    Refrigerator_Mist_Pink, Refrigerator_Mist_Redwood, Refrigerator_Mist_Silver,
    Styler_Mist_Beige, Styler_Mist_Clay_Brown, Styler_Mist_Green, Styler_Mist_Redwood
} from "./objetColors/objetColorData_Mist";
import {
    AirCare_Nature_Clay_Brown,
    AirCare_Nature_Cream_White,
    AirCare_Nature_Dark_Green,
    AirCare_Nature_Green,
    AirCare_Nature_RedWood,
    Diashwasher_Nature_Beige,
    Refrigerator_Nature_Black,
    Refrigerator_Nature_Gray,
    Refrigerator_Nature_White,
    Tiiun_Nature_Beige,
    Tiiun_Nature_Green,
    WashernDryer_Nature_Beige,
    WashernDryer_Nature_Clay_Brown,
    WashernDryer_Nature_Green,
    WashernDryer_Nature_Pink,
    WashernDryer_Nature_RedWood
} from "./objetColors/objetColorData_Nature";
import {
    Dishwasher_Solid_Green, Dishwasher_Solid_Silver,
    Freeze_Solid_Beige, Freeze_Solid_Green, Freeze_Solid_Mint, Freeze_Solid_Pink, Freeze_Solid_Silver,
    MicrowaveOven_Solid_Green, MicrowaveOven_Solid_Silver,
    Refrigerator_Solid_Green, Refrigerator_Solid_Manhattan_Midnight, Refrigerator_Solid_Silver,
    WaterPurifier_Solid_Clay_Brown, WaterPurifier_Solid_Black
} from "./objetColors/objetColorData_Solid";
import {
    Styler_Glass_Black, Styler_Glass_Black_Mirror,
    Styler_Glass_Brown, Styler_Glass_Charcoal_Grey, Styler_Glass_Espresso, Styler_Glass_Ivory, Styler_Glass_Linen_Black,
    Styler_Glass_Metallic_Charcoal, Styler_Glass_Mirror, Styler_Glass_White
} from "./objetColors/objetColorData_Glass";
import {LangLabelType} from "../lang/langData";


//오브제컬랙션 컬러 카테고리 타입
export type Objet_ColorCategoryType = "Fenix" | "Solid" | "Mist" | "Nature" | "Calming" | "Glass";

//오브제컬랙션 공간 타입
export type Objet_RoomType = "blossom" | "natural" | "modern";

//카테고리 타입
export type Objet_ProductsCategoryType =
    | "refrigerator_tl" //냉장고 LT
    | "refrigerator_bl" //냉장고 BL
    | "refrigerator_br" //냉장고 BR
    | "dishwasher" //식기세척기
    | "water_purifier" //정수기
    | "water_purifier_undersink" //정수기 (싱크대 매립형)
    | "washer" //세탁기
    | "dryer" //건조기
    | "styler" //스타일러
    | "vacuum_cleaner_stand" //청소기 스탠드형
    | "vacuum_cleaner_robot" //청소기 로봇형
    | "microwave_oven" //전자레인지
    | "air_conditioner_stand" //에어컨 스탠드형
    | "air_conditioner_wall" //에어컨 벽걸이형
    | "air_care" //공기청정기
    | "air_care_aero" //공기청정기 (AERO)
    | "tiiun" //식물재배기
    | "lansen_freezer_l"
    | "lansen_freezer_r";


//제품 색상 데이터 그룹 타입
export type Objet_ProductColorType = {
    label: LangLabelType
    colorCategory: Objet_ColorCategoryType;  //컬러 카테고리
    colors?: ProductColorType[];    //컬러 아이템
    ga_id?: string;
};

export type Objet_CategoryItemType = {
    label: LangLabelType;
    category: Objet_ProductsCategoryType; //제품 카테고리
    colorData: Objet_ProductColorType[];  //제품 컬러 데이터
    ga_id?: string;
}


//냉장고 --------------------------------------------------------------
const refrigerator_color_data: Objet_ProductColorType[] = [
    {
        label: "objet_material_fenix",
        colorCategory: "Fenix",
        ga_id: "fenix",
        colors: [Refrigerator_Fenix_Botanic, Refrigerator_Fenix_Sand, Refrigerator_Fenix_Stone]
    },
    {
        label: "objet_material_solid",
        colorCategory: "Solid",
        ga_id: "solid",
        colors: [Refrigerator_Solid_Green, Refrigerator_Solid_Manhattan_Midnight, Refrigerator_Solid_Silver]
    },
    {
        label: "objet_material_mist",
        colorCategory: "Mist",
        ga_id: "mist",
        colors: [Refrigerator_Mist_Beige, Refrigerator_Mist_Clay_Brown, Refrigerator_Mist_Mint, Refrigerator_Mist_Pink, Refrigerator_Mist_Redwood, Refrigerator_Mist_Silver]
    },
    {
        label: "objet_material_nature",
        colorCategory: "Nature",
        ga_id: "nature",
        colors: [Refrigerator_Nature_Black, Refrigerator_Nature_Gray, Refrigerator_Nature_White]
    }
];
export const refrigerator_tl_data: Objet_CategoryItemType = {
    label: "objet_category_refrigerator",
    category: "refrigerator_tl",
    ga_id: "refrigerator_f/door_1",
    colorData: refrigerator_color_data
};
export const refrigerator_bl_data: Objet_CategoryItemType = {
    label: "objet_category_refrigerator",
    category: "refrigerator_bl",
    ga_id: "refrigerator_f/door_2",
    colorData: refrigerator_color_data
};
export const refrigerator_br_data: Objet_CategoryItemType = {
    label: "objet_category_refrigerator",
    category: "refrigerator_br",
    ga_id: "refrigerator_f/door_3",
    colorData: refrigerator_color_data
};

//스타일러 --------------------------------------------------------------
export const styler_data: Objet_CategoryItemType = {
    label: "objet_category_styler",
    category: "styler",
    ga_id: "styler",
    colorData: [
        {
            label: "objet_material_mist",
            colorCategory: "Mist",
            ga_id: "mist",
            colors: [Styler_Mist_Green, Styler_Mist_Beige, Styler_Mist_Clay_Brown, Styler_Mist_Redwood]
        },
        {
            label: "objet_material_glass",
            colorCategory: "Glass",
            ga_id: "glass",
            colors: [Styler_Glass_Brown, Styler_Glass_Ivory, Styler_Glass_Black,
                Styler_Glass_Charcoal_Grey, Styler_Glass_Black_Mirror, Styler_Glass_White]
        }
    ]
};

//에어컨 --------------------------------------------------------------
const air_conditioner_color_data: Objet_ProductColorType[] = [
    {
        label: "objet_material_calming",
        colorCategory: "Calming",
        ga_id: "calming",
        colors: [AirConditiner_Calming_Beige, AirConditioner_Calming_Green]
    }
];
export const air_conditioner_stand_data: Objet_CategoryItemType = {
    label: "objet_category_air_conditioner",
    category: "air_conditioner_stand",
    ga_id: "rac_floor_standing",
    colorData: air_conditioner_color_data
};
export const air_conditioner_wall_data: Objet_CategoryItemType = {
    label: "objet_category_air_conditioner",
    category: "air_conditioner_wall",
    ga_id: "rac_wall_mounted",
    colorData: air_conditioner_color_data
};

//세탁기_건조기 --------------------------------------------------------------
const washer_n_dryer_color_data: Objet_ProductColorType[] = [
    {
        label: "objet_material_nature",
        colorCategory: "Nature",
        ga_id: "nature",
        colors: [WashernDryer_Nature_Green, WashernDryer_Nature_Beige,
            WashernDryer_Nature_Pink, WashernDryer_Nature_Clay_Brown, WashernDryer_Nature_RedWood]
    }
];
export const washer_data: Objet_CategoryItemType = {
    label: "objet_category_washer_n_dryer",
    category: "washer",
    ga_id: "washtower_1",
    colorData: washer_n_dryer_color_data
};
export const dryer_data: Objet_CategoryItemType = {
    label: "objet_category_washer_n_dryer",
    category: "dryer",
    ga_id: "washtower_2",
    colorData: washer_n_dryer_color_data
};

//공기청정기 --------------------------------------------------------------
export const air_care_aero_data: Objet_CategoryItemType = {
    label: "objet_category_air_care",
    category: "air_care_aero",
    ga_id: "air_care_aero_tower",
    colorData: [
        {
            label: "objet_material_nature",
            colorCategory: "Nature",
            ga_id: "nature",
            colors: [AirCare_Nature_Green, AirCare_Nature_RedWood, AirCare_Nature_Cream_White, AirCare_Nature_Clay_Brown]
        }
    ]
};
export const air_care_data: Objet_CategoryItemType = {
    label: "objet_category_air_care",
    category: "air_care",
    ga_id: "air_care_360_tower",
    colorData: [
        {
            label: "objet_material_nature",
            colorCategory: "Nature",
            ga_id: "nature",
            colors: [AirCare_Nature_Dark_Green, AirCare_Nature_RedWood]
        }
    ]
};

//식기세척기 --------------------------------------------------------------
export const dishwasher_data: Objet_CategoryItemType = {
    label: "objet_category_dishwasher",
    category: "dishwasher",
    ga_id: "dishwasher",
    colorData: [
        {
            label: "objet_material_solid",
            colorCategory: "Solid",
            ga_id: "solid",
            colors: [Dishwasher_Solid_Silver, Dishwasher_Solid_Green]
        },
        {
            label: "objet_material_nature",
            colorCategory: "Nature",
            ga_id: "nature",
            colors: [Diashwasher_Nature_Beige]
        }
    ]
};

//정수기 --------------------------------------------------------------
export const water_purifier_data: Objet_CategoryItemType = {
    label: "objet_category_water_purifier",
    category: "water_purifier",
    ga_id: "water_care_atom_4d",
    colorData: [
        {
            label: "objet_material_calming",
            colorCategory: "Calming",
            ga_id: "calming",
            colors: [WaterPurifier_Calming_Beige, WaterPurifier_Calming_Green,
                WaterPurifier_Calming_Cotton_Sky, WaterPurifier_Calming_Pale_Rose]
        }
    ]
};
export const water_purifier_undersink_data: Objet_CategoryItemType = {
    label: "objet_category_water_purifier",
    category: "water_purifier_undersink",
    ga_id: "water_care_atom_u",
    colorData: [
        {
            label: "objet_material_solid",
            colorCategory: "Solid",
            ga_id: "solid",
            colors: [WaterPurifier_Solid_Clay_Brown, WaterPurifier_Solid_Black]
        }
    ]
};

//전자레인지 --------------------------------------------------------------
export const microwave_oven_data: Objet_CategoryItemType = {
    label: "objet_category_microwave_oven",
    category: "microwave_oven",
    ga_id: "cooking_mwo",
    colorData: [
        {
            label: "objet_material_mist",
            colorCategory: "Mist",
            ga_id: "mist",
            colors: [MicrowaveOven_Mist_Beige]
        },
        {
            label: "objet_material_solid",
            colorCategory: "Solid",
            ga_id: "solid",
            colors: [MicrowaveOven_Solid_Silver, MicrowaveOven_Solid_Green]
        }
    ]
};

//하베스터 --------------------------------------------------------------
export const tiiun_data: Objet_CategoryItemType = {
    label: "objet_category_tiiun",
    category: "tiiun",
    ga_id: "indoor_gardening_appliance",
    colorData: [
        {
            label: "objet_material_nature",
            colorCategory: "Nature",
            ga_id: "nature",
            colors: [Tiiun_Nature_Green, Tiiun_Nature_Beige]
        }
    ]
};

//청소기 --------------------------------------------------------------
const vacuum_cleaner_color_data: Objet_ProductColorType[] = [
    {
        label: "objet_material_calming",
        colorCategory: "Calming",
        ga_id: "calming",
        colors: [VacuumCleaner_Calming_Beige, VacuumCleaner_Calming_Green]
    }
];
export const vacuum_cleaner_stand_data: Objet_CategoryItemType = {
    label: "objet_category_vacuum_cleaner",
    category: "vacuum_cleaner_stand",
    ga_id: "vacuum_cleaner_a9t",
    colorData: vacuum_cleaner_color_data
};
export const vacuum_cleaner_robot_data: Objet_CategoryItemType = {
    label: "objet_category_vacuum_cleaner",
    category: "vacuum_cleaner_robot",
    ga_id: "vacuum_cleaner_r9",
    colorData: vacuum_cleaner_color_data
};

//Lansen & Freezer --------------------------------------------------------------
const lansen_freezer_color_data : Objet_ProductColorType[] = [
    {
        label: "objet_material_solid",
        colorCategory: "Solid",
        ga_id: "solid",
        colors: [Freeze_Solid_Silver, Freeze_Solid_Green, Freeze_Solid_Beige,
            Freeze_Solid_Pink, Freeze_Solid_Mint]
    },
    {
        label: "objet_material_mist",
        colorCategory: "Mist",
        ga_id: "mist",
        colors: [
            Freeze_Mist_Silver, Freeze_Mist_Redwood, Freeze_Mist_Clay_Brown,
            Freeze_Mist_Gray, Freeze_Mist_Black, Freeze_Mist_White]
    }
];
export const lansen_freezer_l_data: Objet_CategoryItemType = {
    label: "objet_category_lansen_freezer",
    category: "lansen_freezer_l",
    ga_id: "refrigerator_freeze&freezer_1",
    colorData: lansen_freezer_color_data
};
export const lansen_freezer_r_data: Objet_CategoryItemType = {
    label: "objet_category_lansen_freezer",
    category: "lansen_freezer_r",
    ga_id: "refrigerator_freeze&freezer_2",
    colorData: lansen_freezer_color_data
};


const objetProductsData: Objet_CategoryItemType[] = [
    refrigerator_tl_data,
    refrigerator_bl_data,
    refrigerator_br_data,
    dishwasher_data,
    water_purifier_data,
    water_purifier_undersink_data,
    washer_data,
    dryer_data,
    styler_data,
    vacuum_cleaner_stand_data,
    vacuum_cleaner_robot_data,
    microwave_oven_data,
    air_conditioner_stand_data,
    air_conditioner_wall_data,
    air_care_data,
    air_care_aero_data,
    tiiun_data,
    lansen_freezer_l_data,
    lansen_freezer_r_data
];

//카테고리 타입으로 제품 카테고리 데이터 가져오기
//(데이터를 예를 들어 refrigerator_data에 직접 접근해서 가져갈수도 있으니 이 함수 사용은 어쩌면 불필요할 수도 있음)
export function getObjetProductData(category: Objet_ProductsCategoryType) {
    const result = objetProductsData.filter(val => val.category === category);
    return (result && result.length > 0) ? result[0] : null;
}

export default objetProductsData;
