import { ProductsDataItemType } from "./productsData";
import Xd2_steam from "../../products/dishwasher/Xd2_steam";
import Xd3_steam from "../../products/dishwasher/Xd3_steam";
import Xd4_steam from "../../products/dishwasher/Xd4_steam";
import Xd5 from "../../products/dishwasher/Xd5";
import Xd6 from "../../products/dishwasher/Xd6";
import Xd3 from "../../products/dishwasher/Xd3";

/**
 * 식기세척기 데이터
 */
const productsData_DishWasher: ProductsDataItemType = {
  category: "dishwasher",
  ga_id: "dishwasher",
  label: "category_dishwasher",
  category_img_url: "/images/products/dishwasher/category_img.png",
  category_thumb_url: "/images/products/dishwasher/category_thumb.png",
  category_glow_url: "/images/products/dishwasher/category_glow.png",
  roomType: "kitchen",
  usp_transition: "/movies/usp/transition/main_XD_000_00000.webm",
  productList: [
    {
      name: "XD 4 Steam",
      modelNo: "xd4_steam",
      ga_id: "xd_4_steam",
      isCES: true,
      thumb_url: "/images/products/dishwasher/xd4_steam/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/dishwasher/xd4_steam/open.png",
      colors: [
        {
          //컬러 27
          name: <></>,
          label: "home_exp_color_steel",
          color: "/images/products/dishwasher/xd4_steam/color_steel.png",
          ga_id: "stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color: "/images/products/dishwasher/xd4_steam/color_sts.png",
          ga_id: "black_stainless_steel",
        },
      ],
      model: <Xd4_steam />,
    },
    {
      name: "XD 2 Steam",
      modelNo: "xd2_steam",
      ga_id: "xd_2_steam",
      isCES: true,
      thumb_url: "/images/products/dishwasher/xd2_steam/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/dishwasher/xd2_steam/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color: "/images/products/dishwasher/xd2_steam/color_printproof.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Xd2_steam />,
    },
    {
      name: "XD 3 Steam",
      modelNo: "xd3_steam",
      ga_id: "xd_3_steam",
      isCES: true,
      thumb_url: "/images/products/dishwasher/xd3_steam/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/dishwasher/xd3_steam/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color: "/images/products/dishwasher/xd3_steam/color_printproof.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Xd3_steam />,
    },
    {
      name: "XD 3",
      modelNo: "xd3",
      ga_id: "xd3",
      isCES: false,
      thumb_url: "/images/products/dishwasher/xd3/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/dishwasher/xd3/open.png",
      ar_url: "http://lgevirtual.com/ar/quadwashsteam",
      ar_text: "home_exp_ar_xd3",
      colors: [
        {
          //컬러 25
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color: "/images/products/dishwasher/xd3/color_printproof.png",
          ga_id: "printproof_stainless_steel",
        },
      ],
      model: <Xd3 />,
    },

    {
      name: "XD 5",
      modelNo: "xd5",
      ga_id: "xd_5",
      isCES: false,
      thumb_url: "/images/products/dishwasher/xd5/product_thumb.png",
      openExperience: true,
      open_url: "/images/products/dishwasher/xd5/open.png",
      colors: [
        {
          name: <></>,
          label: "home_exp_color_printproofsteel",
          color: "/images/products/dishwasher/xd5/color_printproof.png",
          ga_id: "printproof_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_blackstainsteel",
          color: "/images/products/dishwasher/xd5/color_sts.png",
          ga_id: "black_stainless_steel",
        },
        {
          name: <></>,
          label: "home_exp_color_black",
          color: "/images/products/dishwasher/xd5/color_black.png",
          ga_id: "black",
        },
        {
          name: <></>,
          label: "home_exp_color_white",
          color: "/images/products/dishwasher/xd5/color_white.png",
          ga_id: "white",
        },
      ],
      model: <Xd5 />,
    },
    {
      name: "XD 6",
      modelNo: "xd6",
      ga_id: "xd_6",
      isCES: false,
      thumb_url: "/images/products/dishwasher/xd6/product_thumb.png",
      open_url: "/images/products/dishwasher/xd6/open.png",
      openExperience: true,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_steel",
          color: "/images/products/dishwasher/xd6/color_steel.png",
          ga_id: "stainless_steel",
        },
      ],
      model: <Xd6 />,
    },
  ],
};

export default productsData_DishWasher;
