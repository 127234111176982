import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_AirCare: UspPageDataType[] = [
  {
    category: "air_care",
    modelNo: "aerotower",
    bgImgUrl: "/images/usp/air_care/aerotower.jpg",
    uspList: [
      {
        label: "aerotower_usp_0",
        title: [
          <>
            Breathe in a different level
            <br />
            of clean air.
          </>,
        ],
        subMsg: [<>HealthyAir</>],
        videoUrl: "https://vimeo.com/659224030/dc714f7164",
        x: 308,
        y: 292,
        ga_id: "healthyair",
      },
      {
        isRight: true,
        label: "aerotower_usp_0",
        title: [
          <>
            feel the wind that fits
            <br />
            your lifestyle.
          </>,
        ],
        subMsg: [<>Multi-directional Airflow</>],
        videoUrl: "https://vimeo.com/659223323/2a731bfb6d",
        x: 1093,
        y: 620,
        ga_id: "multi_directional_airflow",
      },
    ],
  },
  {
    category: "air_care",
    modelNo: "puricare_pet",
    bgImgUrl: "/images/usp/air_care/puricare_pet.jpg",
    uspList: [
      {
        label: "puricare_pet_usp_0",
        title: [<>Wider range, cleaner air.</>],
        subMsg: [<>360 Purification</>],
        videoUrl: "https://vimeo.com/654778267/eca9f94440",
        x: 264,
        y: 310,
        ga_id: "360_purification",
      },
      {
        label: "puricare_pet_usp_0",
        title: [
          <>
            Plus, faster.
            <br />
            Breathe the clean air.
          </>,
        ],
        subMsg: [<>Clean Booster</>],
        videoUrl: "https://vimeo.com/654778300/fc50bb3b18",
        x: 264,
        y: 435,
        ga_id: "clean_booster",
      },
      {
        isRight: true,
        label: "puricare_pet_usp_0",
        title: [<>Your pets also love fresh air!</>],
        subMsg: [<>Pet Mode</>],
        videoUrl: "https://vimeo.com/654778328/eb78261df0",
        x: 1134,
        y: 384,
        ga_id: "pet_mode",
      },
    ],
  },
  {
    category: "air_care",
    modelNo: "puricare_stage1",
    bgImgUrl: "/images/usp/air_care/puricare_pet.jpg",
    uspList: [
      {
        label: "puricare_pet_usp_0",
        title: [<>Wider range, cleaner air.</>],
        subMsg: [<>360 Purification</>],
        videoUrl: "https://vimeo.com/654778267/eca9f94440",
        x: 264,
        y: 310,
        ga_id: "360_purification",
      },
      {
        label: "puricare_pet_usp_0",
        title: [
          <>
            Plus, faster.
            <br />
            Breathe the clean air.
          </>,
        ],
        subMsg: [<>Clean Booster</>],
        videoUrl: "https://vimeo.com/654778300/fc50bb3b18",
        x: 264,
        y: 435,
        ga_id: "clean_booster",
      },
      {
        isRight: true,
        label: "puricare_pet_usp_0",
        title: [<>Your pets also love fresh air!</>],
        subMsg: [<>Pet Mode</>],
        videoUrl: "https://vimeo.com/654778328/eb78261df0",
        x: 1134,
        y: 384,
        ga_id: "pet_mode",
      },
    ],
  },
  {
    category: "air_care",
    modelNo: "puricare_mini_2nd",
    bgImgUrl: "/images/usp/air_care/puricare_mini_2nd.jpg",
    uspList: [
      {
        isRight: true,
        label: "puricare_mini_2nd_usp_0",
        title: [
          <>
            Experience the filter-care
            <br />
            that is small but perfect!
          </>,
        ],
        subMsg: [<>Total Care Filter with UV</>],
        videoUrl: "https://vimeo.com/658887251/9ae32c8a95",
        x: 1061,
        y: 406,
        ga_id: "total_care_filter_with_uv",
      },
    ],
  },
  {
    category: "air_care",
    modelNo: "puricare_mini",
    bgImgUrl: "/images/usp/air_care/puricare_mini.jpg",
    uspList: [
      {
        label: "puricare_mini_usp_0",
        title: [<>Psst, do you hear anything?</>],
        subMsg: [<>Quiet Operation</>],
        videoUrl: "https://vimeo.com/654778347/5c4f95a589",
        x: 345,
        y: 339,
        ga_id: "quiet_operation",
      },
      {
        isRight: true,
        label: "puricare_mini_usp_0",
        title: [
          <>
            This compact and with filter
            <br />
            care function?
          </>,
        ],
        subMsg: [<>Total Care Filter</>],
        videoUrl: "https://vimeo.com/654778367/db2f1abbb8",
        x: 1071,
        y: 473,
        ga_id: "total_care_filter",
      },
    ],
  },
];

export default uspData_AirCare;
