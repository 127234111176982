import {Objet_CategoryItemType, Objet_RoomType} from "../products/objetProductsData";

export type Objet_PartType = "wall" | "floor";

export type Objet_MaterialType = {
    name: string
    default_url: string
    hover_url: string
    ga_id: string
};

export type Objet_MaterialsType = {
    room: Objet_RoomType
    part: Objet_PartType
    materials: Objet_MaterialType[]
    ga_id: string
};

export const natural_wall_data: Objet_MaterialsType = {
    room: "natural",
    part: "wall",
    ga_id: "natural",
    materials: [
        {
            name: "natural_wall_wood",
            default_url: "/images/objet/textures/nature_wall/wood_default.png",
            hover_url: "/images/objet/textures/nature_wall/wood_hover.png",
            ga_id: "wood"
        },
        {
            name: "natural_wall_marble",
            default_url: "/images/objet/textures/nature_wall/marble_default.png",
            hover_url: "/images/objet/textures/nature_wall/marble_hover.png",
            ga_id: "marble"
        },
        {
            name: "natural_wall_bricks",
            default_url: "/images/objet/textures/nature_wall/bricks_default.png",
            hover_url: "/images/objet/textures/nature_wall/bricks_hover.png",
            ga_id: "bricks"
        }
    ]
};

export const natural_floor_data: Objet_MaterialsType = {
    room: "natural",
    part: "floor",
    ga_id: "natural",
    materials: [
        {
            name: "natural_floor_wood",
            default_url: "/images/objet/textures/nature_floor/wood_default.png",
            hover_url: "/images/objet/textures/nature_floor/wood_hover.png",
            ga_id: "wood"
        },
        {
            name: "natural_floor_herringbone",
            default_url: "/images/objet/textures/nature_floor/herringbone_default.png",
            hover_url: "/images/objet/textures/nature_floor/herringbone_hover.png",
            ga_id: "herringbone"
        },
        {
            name: "natural_floor_fabric",
            default_url: "/images/objet/textures/nature_floor/fabric_default.png",
            hover_url: "/images/objet/textures/nature_floor/fabric_hover.png",
            ga_id: "fabric"
        }
    ]
};

export const blossom_floor_data: Objet_MaterialsType = {
    room: "blossom",
    part: "floor",
    ga_id: "blossom",
    materials: [
        {
            name: "blossom_floor_concrete",
            default_url: "/images/objet/textures/blossom_floor/concrete_default.png",
            hover_url: "/images/objet/textures/blossom_floor/concrete_hover.png",
            ga_id: "concrete"
        },
        {
            name: "blossom_floor_wood",
            default_url: "/images/objet/textures/blossom_floor/wood_default.png",
            hover_url: "/images/objet/textures/blossom_floor/wood_hover.png",
            ga_id: "wood"
        },
        {
            name: "blossom_floor_marble",
            default_url: "/images/objet/textures/blossom_floor/marble_default.png",
            hover_url: "/images/objet/textures/blossom_floor/marble_hover.png",
            ga_id: "marble"
        }
    ]
};

export const blossom_wall_data: Objet_MaterialsType = {
    room: "blossom",
    part: "wall",
    ga_id: "blossom",
    materials: [
        {
            name: "blossom_wall_wood",
            default_url: "/images/objet/textures/blossom_wall/wood_default.png",
            hover_url: "/images/objet/textures/blossom_wall/wood_hover.png",
            ga_id: "wood"
        },
        {
            name: "blossom_wall_fabric",
            default_url: "/images/objet/textures/blossom_wall/fabric_default.png",
            hover_url: "/images/objet/textures/blossom_wall/fabric_hover.png",
            ga_id: "fabric"
        },
        {
            name: "blossom_wall_painting",
            default_url: "/images/objet/textures/blossom_wall/painting_default.png",
            hover_url: "/images/objet/textures/blossom_wall/painting_hover.png",
            ga_id: "paint"
        }
    ]
};


export const modern_floor_data: Objet_MaterialsType = {
    room: "modern",
    part: "floor",
    ga_id: "modern",
    materials: [
        {
            name: "modern_floor_granite",
            default_url: "/images/objet/textures/modern_floor/granite_default.png",
            hover_url: "/images/objet/textures/modern_floor/granite_hover.png",
            ga_id: "granite"
        },
        {
            name: "modern_floor_marble",
            default_url: "/images/objet/textures/modern_floor/marble_default.png",
            hover_url: "/images/objet/textures/modern_floor/marble_hover.png",
            ga_id: "marble"
        },
        {
            name: "modern_floor_wood",
            default_url: "/images/objet/textures/modern_floor/wood_default.png",
            hover_url: "/images/objet/textures/modern_floor/wood_hover.png",
            ga_id: "wood"
        }
    ]
};

export const modern_wall_data: Objet_MaterialsType = {
    room: "modern",
    part: "wall",
    ga_id: "modern",
    materials: [
        {
            name: "modern_wall_marble",
            default_url: "/images/objet/textures/modern_wall/marble_default.png",
            hover_url: "/images/objet/textures/modern_wall/marble_hover.png",
            ga_id: "marble"
        },
        {
            name: "modern_wall_painting",
            default_url: "/images/objet/textures/modern_wall/painting_default.png",
            hover_url: "/images/objet/textures/modern_wall/painting_hover.png",
            ga_id: "paint"
        },
        {
            name: "modern_wall_bronze",
            default_url: "/images/objet/textures/modern_wall/bronze_default.png",
            hover_url: "/images/objet/textures/modern_wall/bronze_hover.png",
            ga_id: "bronze_panel"
        }
    ]
};