import {useEquirectEXR} from "../../../../utils/useEquirect";
import {ROOT_PATH_PREFIX} from "../../../common/const";
import {Sphere, useTexture} from "@react-three/drei";
import {useLoader, useThree} from "@react-three/fiber";
import React, {useEffect} from "react";
import * as THREE from "three";
import {EXRLoader} from "three-stdlib";

const ENV_URL = ROOT_PATH_PREFIX + "/models/rooms/objetcollection/natural/Environment_01.exr";
const BG_URL = ROOT_PATH_PREFIX + "/models/rooms/objetcollection/natural/epping_forest_02_4k.jpg";

const EnvLoader = () => {
    const envTex = useEquirectEXR(ENV_URL);
    const bgTex = useTexture(BG_URL);
    const gl = useThree((state) => state.gl);
    const scene = useThree((state) => state.scene);

    useEffect(() => {
        scene.environment = envTex;
        gl.toneMappingExposure = 1;
        bgTex.encoding = THREE.sRGBEncoding;

        return () => {
            envTex.dispose();
            bgTex.dispose();
            //@ts-ignore
            useLoader.clear(EXRLoader, ENV_URL);
            useTexture.clear(BG_URL);
        }

    }, []);

    return (
        <Sphere args={[15, 60, 40]} scale={3} rotation={[0, 90 * Math.PI, 0]}>
            <meshBasicMaterial side={THREE.BackSide} map={bgTex}/>
        </Sphere>
    );
}

export default EnvLoader;
