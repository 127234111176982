import { animated, useSpring } from "@react-spring/web";
import { easeCubicOut } from "d3-ease";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useObjetInterior } from "../../stores/modalStore";
import useObjetProductStore from "../../stores/objetProductStore";
import useResizeStore from "../../stores/resizeStore";
import useSpaceThemeStore from "../../stores/spaceThemeStore";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "./const";
import HoverButton from "./navigation/HoverButton";
import useHistoryStore from "../../stores/historyStore";

type Props = {};

const ObjetInteriorButtons = (props: Props) => {
  const setObjetTheme = useSpaceThemeStore((state) => state.api.setObjetTheme);
  const objetTheme = useSpaceThemeStore((state) => state.objetTheme);
  const hidden = useObjetInterior((state) => state.isOpen);
  const setHidden = useObjetInterior((state) => state.setIsOpen);
  const resize = useResizeStore((state) => state.resize);
  const objetData = useObjetProductStore((state) => state.selectedCategory);
  const pushHistory = useHistoryStore((state) => state.pushHistory);

  useEffect(() => {
    setHidden(!!objetData);
  }, [objetData]);

  const spring = useSpring({
    opacity: hidden ? 0 : 1,
    config: { duration: 450, easing: easeCubicOut },
  });

  useEffect(() => {
    clickEffects(objetTheme);
  }, [objetTheme]);

  const [clicked, setClicked] = useState({
    natural: false,
    blossom: false,
    modern: false,
  });

  const clickEffects = (selected: string, status: boolean = true) => {
    setClicked({
      natural: false,
      blossom: false,
      modern: false,
      [selected]: status,
    });
  };

  return (
    <Wrapper
      disabled={hidden}
      ratio={resize.ratio}
      style={{
        opacity: spring.opacity.to((value) => value) as any,
      }}
    >
      <HoverButton
        alt="interior natural"
        style={{
          width: `${resize.ratio * (IS_MOBILE ? 16.5 : 11.5)}em`,
        }}
        clicked={clicked.natural}
        defaultSrc={
          ROOT_PATH_PREFIX +
          "/images/objet/themeButtons/btn_interior_01_default@2x.png"
        }
        hoverSrc={
          ROOT_PATH_PREFIX +
          "/images/objet/themeButtons/btn-interior-01-press@2x.png"
        }
        onClick={() => {
          clickEffects("natural", !clicked.natural);
          setObjetTheme("natural");
          pushHistory("/objetcollection/natural");
        }}
      />

      {/*<HoverButton*/}
      {/*  alt="interior blossom" */}
      {/*  style={{*/}
      {/*    width: `${resize.ratio * (IS_MOBILE ? 16.5 : 11.5)}em`,*/}
      {/*  }}*/}
      {/*  clicked={clicked.blossom}*/}
      {/*  defaultSrc={*/}
      {/*    ROOT_PATH_PREFIX +*/}
      {/*    "/images/objet/themeButtons/btn_interior_02_default@2x.png"*/}
      {/*  }*/}
      {/*  hoverSrc={*/}
      {/*    ROOT_PATH_PREFIX +*/}
      {/*    "/images/objet/themeButtons/btn-interior-02-press@2x.png"*/}
      {/*  }*/}
      {/*  onClick={() => {*/}
      {/*    clickEffects("blossom", !clicked.blossom);*/}
      {/*    setObjetTheme("blossom");*/}
      {/*  }}*/}
      {/*/>*/}

      <HoverButton
        alt="interior modern"
        style={{
          width: `${resize.ratio * (IS_MOBILE ? 16.5 : 11.5)}em`,
        }}
        clicked={clicked.modern}
        defaultSrc={
          ROOT_PATH_PREFIX +
          "/images/objet/themeButtons/btn_interior_03_default@2x.png"
        }
        hoverSrc={
          ROOT_PATH_PREFIX +
          "/images/objet/themeButtons/btn-interior-03-press@2x.png"
        }
        onClick={() => {
          clickEffects("modern", !clicked.modern);
          setObjetTheme("modern");
          pushHistory("/objetcollection/modern");
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled(animated.div)<{ ratio: number; disabled: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  pointer-events: none;
  /* border: 1px solid tomato; */

  button {
    margin-right: ${({ ratio }) => ratio * (IS_MOBILE ? 0.8 : 0.45)}em;
    pointer-events: ${({ disabled }) => (disabled ? "none" : "visible")};
  }
`;

export default ObjetInteriorButtons;
