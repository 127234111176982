/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useEffect, useLayoutEffect, useRef} from 'react'
import {useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {useKTXLoader} from "../../utils/useKTXLoader";
import {GLTFResult_ObjetNaturalProducts} from "../../components/objetcollection/room/natural/Product_Harvester";
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../components/common/const";
import {disposeScene} from "../../utils/disposeScene";
import {useLoader} from "@react-three/fiber";
import {GLTFLoader} from "three-stdlib";
import {TextureLoader} from "three";

type GLTFResult = GLTF & {
  nodes: {
    xd6_back: THREE.Mesh
    xd6_body: THREE.Mesh
    xd6_decal: THREE.Mesh
  }
  materials: {
    ['mat_black.004']: THREE.MeshStandardMaterial
    DW_04_Albedo: THREE.MeshStandardMaterial
    ['DW_04_Albedo_Decal.002']: THREE.MeshStandardMaterial
  }
}

const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/m/xd6_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/products/dish_wash_machine/xd6_ktx2.glb');

// const GLTF_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd6_ktx2.glb';
const AO_URL = ROOT_PATH_PREFIX + '/models/products/dish_wash_machine/xd6_AO.jpg';

export default function Model(props: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult>(GLTF_URL);
    const aoTex = useTexture(AO_URL);

    useLayoutEffect(() => {
        aoTex.flipY = false;
        aoTex.encoding = THREE.sRGBEncoding;
        aoTex.needsUpdate = true;

        materials.DW_04_Albedo.aoMap = aoTex;
        materials.DW_04_Albedo.aoMapIntensity = 2;

    }, [aoTex]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            aoTex.dispose();
            useLoader.clear(GLTFLoader, GLTF_URL);
            useLoader.clear(TextureLoader, AO_URL);
        }
    }, []);

  return (
      <group ref={group} {...props} dispose={null} position={[1.805, -0.003, 3.1]}
             scale={[1.01,1,1]}
      >
        <mesh
            geometry={nodes.xd6_back.geometry}
            material={materials['mat_black.004']}
            position={[-1.79, -0.02, -2.79]}
            scale={[0.97, 0.97, 0.97]}
        />
        <mesh
            geometry={nodes.xd6_body.geometry}
            material={materials.DW_04_Albedo}
            position={[-1.79, -0.02, -2.8]}
            scale={[1,1,1]}
        />
        <mesh
            geometry={nodes.xd6_decal.geometry}
            material={materials['DW_04_Albedo_Decal.002']}
            position={[-1.79, -0.02, -2.79]}
            scale={[0.97, 0.97, 0.97]}
        />
          <mesh position={[-1.79, 0.3,-3.6]}>
              <boxBufferGeometry args={[1,1,1]} />
              <meshBasicMaterial color={'#000'} />
          </mesh>
      </group>
  )
}
