import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_WashernDryer: UspPageDataType[] = [
  {
    category: "washer_n_dryer",
    modelNo: "washtower",
    bgImgUrl: "/images/usp/washer_n_dryer/washtower.jpg",
    uspList: [
      {
        label: "washtower_usp_0",
        title: [
          <>
            Looking for a space
            <br />
            for a dryer?
          </>,
        ],
        subMsg: [<>Single Unit WashTower™ Design</>],
        videoUrl: "https://vimeo.com/658878859/60328fd32b",
        x: 242,
        y: 284,
        ga_id: "single_unit_washtower_design",
      },
      {
        isRight: true,
        label: "washtower_usp_0",
        title: [
          <>
            Smart enough!
            <br />
            Everything is done by itself.
          </>,
        ],
        subMsg: [
          <>
            Built-in Intelligence
            <br />
            <sub>Smart Leaner, AI DD, Smart Pairing, AI Sensor Dry</sub>
          </>,
        ],
        videoUrl: "https://vimeo.com/658878724/02d3b68e9b",
        x: 1169,
        y: 447,
        ga_id: "built_in_intelligence",
      },
      {
        isRight: true,
        label: "washtower_usp_0",
        title: [
          <>
            Is it possible to use less energy
            <br />
            but have a strong drying or
            <br />
            performnace?
          </>,
        ],
        subMsg: [<>Dual Inverter HeatPump</>],
        videoUrl: "https://vimeo.com/658878815/d8ce685d80",
        x: 1169,
        y: 597,
        ga_id: "dual_inverter_heatpump",
      },
    ],
  },
  {
    category: "washer_n_dryer",
    modelNo: "fx",
    bgImgUrl: "/images/usp/washer_n_dryer/fx.jpg",
    uspList: [
      {
        label: "fx_usp_0",
        title: [
          <>
            6 motion take cares of
            <br />
            your style delicately from
            <br />
            washing to drying.
          </>,
        ],
        subMsg: [<>6 motion wash & 6 motion dry</>],
        videoUrl: "https://vimeo.com/659821975/e3f0affd9c",
        x: 95,
        y: 180,
        ga_id: "6_motion_wash&6_motion_dry",
      },
      {
        label: "fx_usp_0",
        title: [
          <>
            Not a problem!
            <br />
            Can wash your delicate style
            <br />
            clothes without any damage.
          </>,
        ],
        subMsg: [<>Advanced AI DD</>],
        videoUrl: "https://vimeo.com/658878914/6fe39b2f11",
        x: 95,
        y: 370,
        ga_id: "advanced_ai_dd",
      },
      {
        label: "fx_usp_0",
        title: [
          <>
            May I help you with
            <br />
            your question?
          </>,
        ],
        subMsg: [<>Conversational message</>],
        videoUrl: "https://vimeo.com/658878974/212979ae13",
        x: 95,
        y: 560,
        ga_id: "conversational_message",
      },
      {
        isRight: true,
        label: "fx_usp_0",
        title: [
          <>
            Change your washer
            <br />
            that is difficult to use
            <br />
            and hard to reach.
          </>,
        ],
        subMsg: [<>Simple/seamless design and distinctive UI/UX</>],
        videoUrl: "https://vimeo.com/658879008/f916967daa",
        x: 1310,
        y: 380,
        ga_id: "simple/seamless_design_and_distinctive_ui/ux",
      },
      {
        isRight: true,
        label: "fx_usp_0",
        title: [
          <>
            Feel the softness of the
            <br />
            perfectly dried garment.
          </>,
        ],
        subMsg: [<>Advanced AI dry</>],
        videoUrl: "https://vimeo.com/658883931/1730166942",
        x: 1310,
        y: 570,
        ga_id: "advanced_ai_dry",
      },
    ],
  },
  {
    category: "washer_n_dryer",
    modelNo: "victor2r",
    bgImgUrl: "/images/usp/washer_n_dryer/victor2r.jpg",
    uspList: [
      {
        label: "victor2r_usp_0",
        title: [
          <>
            Save your time.
            <br />
            It only takes about 30 mins.
          </>,
        ],
        subMsg: [<>TurboWash360</>],
        videoUrl: "https://vimeo.com/659897476/ecc36808c0",
        x: 125,
        y: 326,
        ga_id: "turbowash360",
      },
      {
        label: "victor2r_usp_0",
        title: [
          <>
            It actually recognizes
            <br />
            the laundry garment.
          </>,
        ],
        subMsg: [<>AI DD</>],
        videoUrl: "https://vimeo.com/659897455/dd2ef26a86",
        x: 125,
        y: 476,
        ga_id: "ai_dd",
      },
      {
        isRight: true,
        label: "victor2r_usp_0",
        title: [
          <>
            Still confused about
            <br />
            course setting?
          </>,
        ],
        subMsg: [<>Smart paring</>],
        videoUrl: "https://vimeo.com/659897423/83de59a624",
        x: 1280,
        y: 379,
        ga_id: "smart_paring",
      },
    ],
  },
  {
    category: "washer_n_dryer",
    modelNo: "td_washer",
    bgImgUrl: "/images/usp/washer_n_dryer/td.jpg",
    uspList: [
      {
        label: "td_usp_0",
        title: [
          <>
            Wondering the know-how
            <br />
            of powerful washing?
          </>,
        ],
        subMsg: [<>4-Way Agitator</>],
        videoUrl: "https://vimeo.com/659897379/d12f3e3e26",
        x: 125,
        y: 473,
        ga_id: "4_way_agitator",
      },
      {
        isRight: true,
        label: "td_usp_0",
        title: [
          <>
            Load your laundry
            <br />
            in the dryer easily
          </>,
        ],
        subMsg: [<>EasyLoad door</>],
        videoUrl: "https://vimeo.com/659897396/60726e82d7",
        x: 1280,
        y: 473,
        ga_id: "easyload_door",
      },
    ],
  },
];

export default uspData_WashernDryer;
