import { UspDataItemType, UspPageDataType } from "./uspData";

//---------------------------------------------------[EN]
const uspData_HomeBrew: UspPageDataType[] = [
  {
    category: "homebrew",
    modelNo: "homebrew",
    bgImgUrl: "/images/usp/homebrew/homebrew.jpg",
    uspList: [
      {
        label: "homebrew_usp_0",
        title: [
          <>
            Why don't you taste
            <br />
            high-quality craft beer at
            <br />
            home with just one capsule?
          </>,
        ],
        subMsg: [
          <>Capsule-based Automated Brewing Systems <br/> & 6 Premium Beer recipe</>,
        ],
        videoUrl: "https://vimeo.com/658876938/09a672acc5",
        x: 130,
        y: 437,
        ga_id: "capsule_based_automated_brewing_systems&6_premium_beer_receipe",
      },
      {
        isRight: true,
        label: "homebrew_usp_0",
        title: [
          <>
            Don’t take apart.
            <br />
            It cleans itself.
          </>,
        ],
        subMsg: [<>Automated Cleaning</>],
        videoUrl: "https://vimeo.com/658889900/a2a2aae703",
        x: 1265,
        y: 601,
        ga_id: "automated_cleaning",
      },
    ],
  },
];

export default uspData_HomeBrew;
