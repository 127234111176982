import React, { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import ProductInfoButton from "./ProductInfoButton";
import { ProductDataItemType } from "../../../api/products/productsData";
import { Html } from "@react-three/drei";
import { animated, useSpring } from "@react-spring/web";
import { easeCubicOut } from "d3-ease";
import SpecBoard from "./SpecBoard";
import useProductStore, { ProductState } from "../../../stores/productStore";
import ARBoard from "./ARBoard";
import {
  useModalDesign,
  useModalOpen,
  useModalUSP,
} from "../../../stores/modalStore";
import { useGuideMenuHidden } from "../../../stores/hiddenGuideMenu";
import useRightPanelStore from "../../../stores/rightPanelStore";
import useCameraStore from "../../../stores/cameraStore";
import { IS_MOBILE, ROOT_PATH_PREFIX } from "../../common/const";
import { GA_Event } from "../../../api/ga";
import useSpaceThemeStore from "../../../stores/spaceThemeStore";
import { getUspData, UspPageDataType } from "../../../api/usp/uspData";

type ProductInfoButtonGroupProps = {
  data?: ProductDataItemType;
  isShow?: boolean;
  specboardOffset?: [x: number, y: number, z: number];
  specboardCallback?: (show: boolean) => void;
} & JSX.IntrinsicElements["group"];

// Memoizing Selectors (참조: https://github.com/pmndrs/zustand#memoizing-selectors)
const selectedProductSelector = (state: ProductState) => state.selectedProduct;

const ProductInfoButtonGroup = React.memo(
  ({
    data,
    specboardOffset = [-0.15, 0, 0],
    specboardCallback,
    isShow = true,
    ...rest
  }: ProductInfoButtonGroupProps) => {
    const [selectedIdx, setSelectedIdx] = useState(-1);
    const [uspData, setUspData] = useState<UspPageDataType | null>(null);

    const ref = useRef<HTMLDivElement>(null!);
    const selectedProduct = useProductStore(selectedProductSelector); //선택된 제품 데이터
    const category = useProductStore(
      (state) => state.selectedCategory?.category
    );
    const guideCategory = useGuideMenuHidden((state) => state.category);
    const product = useProductStore((state) => state);

    // for GAEvent
    const ga_product = useProductStore(
      (state) => state.selectedCategory?.ga_id
    );
    const ga_model = useProductStore((state) => state.selectedProduct?.ga_id);
    const ga_interior = useSpaceThemeStore((state) => state.lgHomeTheme);
    const ga_isNew = useProductStore((state) =>
      state.selectedProduct?.isCES ? "y" : "n"
    );
    const colorIndex = useProductStore((state) => state.selectedColorIndex);
    const ga_color = useMemo(
      () => selectedProduct?.colors[colorIndex].ga_id,
      [colorIndex, selectedProduct]
    );
    //

    const isVisible = useMemo(() => {
      return category === guideCategory ? isShow : false;
    }, [category, guideCategory]);

    const spring = useSpring({
      from: { opacity: 0 },
      opacity: isVisible ? 1 : 0,
      onStart: (result) => {
        if (isVisible) {
          if (ref.current) ref.current.style.display = "flex";
          setSelectedIdx(-1);
        }
      },
      onRest: (result) => {
        if (ref.current)
          ref.current.style.display = isVisible ? "flex" : "none";
        if (!isVisible) setSelectedIdx(-1);
      },
      config: { duration: 300, easing: easeCubicOut },
    });

    const modalOpen = useModalOpen((state) => state.isOpen);
    const setModalOpen = useModalOpen((state) => state.setIsOpen);
    const modalDesign = useModalDesign((state) => state.isOpen);
    const setModalDesign = useModalDesign((state) => state.setIsOpen);
    const modalUSP = useModalUSP((state) => state.isOpen);
    const setModalUSP = useModalUSP((state) => state.setIsOpen);
    const setIsOpen = useRightPanelStore((state) => state.setIsOpen);
    const setIsOpenInfo = useCameraStore((state) => state.api.setIsOpenInfo);

    useEffect(() => {
      setSelectedIdx(-1);
    }, [selectedProduct]);

    useEffect(() => {
      !modalOpen && setSelectedIdx(-1);
      !modalUSP && setSelectedIdx(-1);
      !modalDesign && setSelectedIdx(-1);
    }, [modalOpen, modalUSP, modalDesign]);

    useEffect(() => {
      if (selectedIdx !== -1) {
        setIsOpen(false);
      }
      setIsOpenInfo(selectedIdx !== -1);
    }, [selectedIdx]);

    useEffect(() => {
      if (product.selectedCategory) {
        if (product.selectedProduct?.modelNo) {
          // console.log(product);
          const usp = getUspData(
            product?.selectedCategory.category,
            product?.selectedProduct?.modelNo
          );
          setUspData(usp);
          // console.log(usp);
        }
      }
    });

    return (
      <group {...rest}>
        {/* 아이콘 버튼 */}
        <Html
          transform={true}
          distanceFactor={2}
          sprite={false}
          style={{
            pointerEvents: "none",
          }}
          position={[0, 0, 0]}
        >
          <Wrapper ref={ref} style={{ opacity: spring.opacity }}>
            <ProductInfoButton
              title={"Info"}
              images={[
                ROOT_PATH_PREFIX +
                  "/images/icons/png/product_info/btn_spec_default@2x.png",
                ROOT_PATH_PREFIX +
                  "/images/icons/png/product_info/btn_spec_hover@2x.png",
                ROOT_PATH_PREFIX +
                  "/images/icons/png/product_info/btn_spec_press@2x.png",
              ]}
              selected={selectedIdx === 0}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedIdx(0);
                if (specboardCallback) specboardCallback(true);

                GA_Event("lg_home", `${ga_product}_spec`, "click", {
                  dimension5: ga_product,
                  dimension6: ga_interior,
                  dimension7: ga_model,
                  dimension8: ga_isNew,
                  dimension9: ga_color,
                });
              }}
            />

            {selectedProduct && uspData && (
              <ProductInfoButton
                title={"USP"}
                images={[
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_tech_default@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_tech_hover@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_tech_press@2x.png",
                ]}
                selected={selectedIdx === 1}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIdx(1);
                  setModalUSP(true);

                  GA_Event("lg_home", `${ga_product}_tech`, "click", {
                    dimension5: ga_product,
                    dimension6: ga_interior,
                    dimension7: ga_model,
                    dimension8: ga_isNew,
                    dimension9: ga_color,
                  });
                }}
              />
            )}

            {selectedProduct && selectedProduct.openExperience && (
              <ProductInfoButton
                title={"Open the door"}
                images={[
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_open_default@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_open_hover@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_open_press@2x.png",
                ]}
                selected={selectedIdx === 2}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIdx(2);
                  setModalOpen(true);

                  GA_Event("lg_home", `${ga_product}_open`, "click", {
                    dimension5: ga_product,
                    dimension6: ga_interior,
                    dimension7: ga_model,
                    dimension8: ga_isNew,
                    dimension9: ga_color,
                  });
                }}
              />
            )}
            {selectedProduct && selectedProduct.design_url && (
              <ProductInfoButton
                title={"Design"}
                images={[
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_design_default@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_design_hover@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_design_press@2x.png",
                ]}
                selected={selectedIdx === 2}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIdx(3);
                  setModalDesign(true);

                  GA_Event("lg_home", `${ga_product}_design`, "click", {
                    dimension5: ga_product,
                    dimension6: ga_interior,
                    dimension7: ga_model,
                    dimension8: ga_isNew,
                    dimension9: ga_color,
                  });
                }}
              />
            )}
            {selectedProduct && selectedProduct.ar_url && (
              <ProductInfoButton
                title={"AR Experience"}
                images={[
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_ar_default@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_ar_hover@2x.png",
                  ROOT_PATH_PREFIX +
                    "/images/icons/png/product_info/btn_ar_press@2x.png",
                ]}
                selected={selectedIdx === 3}
                onClick={(e) => {
                  e.stopPropagation();

                  if(IS_MOBILE){
                    window.open(selectedProduct.ar_url, "_blank")
                  }
                  setSelectedIdx(4);

                  GA_Event("lg_home", `${ga_product}_ar`, "click", {
                    dimension5: ga_product,
                    dimension6: ga_interior,
                    dimension7: ga_model,
                    dimension8: ga_isNew,
                    dimension9: ga_color,
                  });
                }}
              />
            )}
          </Wrapper>
        </Html>

        {/* 팝업창 */}
        <Html
          transform={true}
          distanceFactor={1}
          sprite={false}
          style={{
            pointerEvents: "none",
          }}
          position={specboardOffset}
        >
          {/* info */}
          {selectedIdx === 0 && (
            <SpecBoard
              closeHandler={() => {
                setSelectedIdx(-1);
                if (specboardCallback) specboardCallback(false);
                GA_Event("lg_home", `${ga_product}_spec`, "close");
              }}
            />
          )}
          {/* AR */}
          {selectedIdx === 4 && !IS_MOBILE && (
            <ARBoard
              closeHandler={() => {
                setSelectedIdx(-1);
                GA_Event("lg_home", `${ga_product}_ar`, "close");
              }}
            />
          )}
        </Html>
      </group>
    );
  }
);

const Wrapper = styled(animated.div)<Record<string, any>>`
  //background: yellow;
  position: absolute;
  left: ${IS_MOBILE ? -75 : -70}px;
  //top: -150px;
  top: 0;
  width: 54px;
  //height: 300px;
  display: none; //'flex' or 'none'
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    margin: 0.6rem;
  }
`;

export default ProductInfoButtonGroup;
