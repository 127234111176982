import { ProductsDataItemType } from "./productsData";

/**
 * 식물재배기 데이터
 */
const productsData_TiiunMini: ProductsDataItemType = {
  category: "tiiun_mini",
  ga_id: "indoor_gardening_appliance",
  label: "category_tiiun",
  category_img_url: "/images/products/tiiun/category_img.png",
  category_thumb_url: "/images/products/tiiun/category_thumb.png",
  category_glow_url: "/images/products/tiiun/category_glow.png",
  roomType: "living",
  usp_transition: "/movies/usp/transition/main_IG_000_00000.webm",
  productList: [
    {
      name: "LG tiiun mini",
      modelNo: "tiiun_mini",
      ga_id: "tiiun_mini",
      isCES: true,
      thumb_url: "/images/products/tiiun/tiiun_mini/product_thumb.png",
      openExperience: false,
      colors: [
        {
          name: <></>,
          label: "home_exp_color_white",
          color: "/images/products/tiiun/tiiun_mini/color_white.png",
          ga_id: "white",
        },
      ],
      // model: <TiiunMini />,
    },
  ],
};
export default productsData_TiiunMini;
