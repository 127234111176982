/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Html, useGLTF, useTexture} from '@react-three/drei'
import {GLTF} from 'three/examples/jsm/loaders/GLTFLoader'
import {IS_MOBILE, ROOT_PATH_PREFIX} from "../../../common/const";
import {useLoader, useThree} from "@react-three/fiber";
import {EXRLoader} from "three/examples/jsm/loaders/EXRLoader";
import ObjetProductContainer3D from "../../../common/ObjetProductContainer3D";
import {Objet_ColorCategoryType, vacuum_cleaner_robot_data} from "../../../../api/products/objetProductsData";
import {disposeScene} from "../../../../utils/disposeScene";
import {GLTFLoader, KTX2Loader} from "three-stdlib";
import {TextureLoader} from "three";
import {useObjetColorStore} from "../../../../stores/objetProductStore";
import {useModalTutorial} from "../../../../stores/modalStore";
import {animated, useSpring} from "@react-spring/web";
import {LangLabelType} from "../../../../api/lang/langData";
import {a} from "@react-spring/three";
import {easeCubicInOut} from "d3-ease";
import {GLTFResult_ObjetNaturalProducts} from "./Product_Harvester";
import {useKTXLoader} from "../../../../utils/useKTXLoader";
import ObjetPlusButton from "../../ObjetPlusButton";
import useHistoryStore from "../../../../stores/historyStore";



const GLTF_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/natural_products_ktx2.glb') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/natural_products_ktx2.glb');
// const LIGHT_MAP_URL = ROOT_PATH_PREFIX + '/models/rooms/objetcollection/natural/r9/Lightmap_R9.jpg';
const LIGHT_MAP_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/Lightmap_R9.jpg') :
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/Lightmap_R9.jpg');

const COLOR_0_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/r9/VC_04_Albedo.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/r9/VC_04_Albedo.jpg');
const COLOR_1_URL = IS_MOBILE ?
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/m/map/r9/VC_04_Albedo_Green.jpg'):
    (ROOT_PATH_PREFIX + '/RP/rooms/natural/map/r9/VC_04_Albedo_Green.jpg');

export default function Model(props: JSX.IntrinsicElements['group']) {
    const group = useRef<THREE.Group>()
    const {nodes, materials, scene} = useKTXLoader<GLTFResult_ObjetNaturalProducts>(GLTF_URL);
    const lightmapTex = useLoader(TextureLoader, LIGHT_MAP_URL);

    const colorTextures = useTexture([COLOR_0_URL, COLOR_1_URL]);

    const colorCategory = useObjetColorStore(state => state.vacuum_cleaner_robot_ColorCategory);
    const colorLabel = useObjetColorStore(state => state.vacuum_cleaner_robot_ColorLabel);
    const isOpenTutorial = useModalTutorial(state => state.isOpen);

    const [spring, animate] = useSpring(() => ({from: {opacity: 0}}));
    const [isSet, setIsSet] = useState(false);

    const changeTexture = useCallback((materialName: string, colorCategory?: Objet_ColorCategoryType, colorLabel?: LangLabelType, isFirst: boolean = false) =>
    {
        if (colorCategory !== undefined && colorLabel !== undefined && !isFirst) setIsSet(true);
        if (colorCategory === 'Calming') {
            if (colorLabel === 'objet_color_green') {
                colorTextures[1].flipY = false;
                colorTextures[1].encoding = THREE.sRGBEncoding;
                colorTextures[1].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[1];
            } else if (colorLabel === 'objet_color_beige') {
                colorTextures[0].flipY = false;
                colorTextures[0].encoding = THREE.sRGBEncoding;
                colorTextures[0].needsUpdate = true;
                materials[`${materialName}`].map = colorTextures[0];
            }
        }
    }, []);

    useLayoutEffect(() => {
        changeTexture("mat_body.002", colorCategory, colorLabel);
    }, [colorCategory, colorLabel]);

    useLayoutEffect(() => {
        lightmapTex.flipY = false;
        lightmapTex.encoding = THREE.sRGBEncoding;
        lightmapTex.needsUpdate = true;

        materials["mat_body.002"].lightMap = lightmapTex;
        materials["mat_glass.004"].transparent = true;
        materials["mat_glass.004"].opacity = 0.6;
        materials["mat_glass.004"].color.setRGB(0.05, 0.05, 0.05);
        materials["mat_glass.004"].envMapIntensity = 2;
        materials["mat_glass.004"].roughness = 0;
    }, [lightmapTex]);

    useEffect(()=>{
        animate({cancel:true});
        if(!isOpenTutorial){
            animate({
                cancel:false,
                opacity: !isSet ? 1 : 0,
                delay: 4000,
                config:{duration:3000, easing: easeCubicInOut}
            });
        }
    }, [isOpenTutorial]);

    useEffect(() => {
        return () => {
            disposeScene(scene);
            lightmapTex.dispose();
            for (let i = 0; i < colorTextures.length; i++) {
                colorTextures[i].dispose();
            }
            useLoader.clear(TextureLoader, LIGHT_MAP_URL);
            useLoader.clear(TextureLoader, [COLOR_0_URL, COLOR_1_URL]);
        }
    }, []);

    const pathname = useHistoryStore(state => state.pathname);

    return (
        <group ref={group} {...props} dispose={null}>
            <mesh
                geometry={nodes.r9.geometry}
                material={materials['mat_body.002']}
                position={[2.2969, 0.3048, 1.3246]}
                rotation={[0, -1.2343, 0]}
                scale={[0.01, 0.01, 0.01]}>
                <mesh
                    geometry={nodes.Glass.geometry}
                    material={materials['mat_glass.004']}
                    position={[0, 0, 0]}
                    visible={isSet}
                />
                <mesh
                    geometry={nodes.Decal_merged.geometry}
                    material={materials.mat_decal_merged}
                    position={[-0.0007, 4.6601, 14.1951]}
                    visible={isSet}
                />
            </mesh>

            <ObjetProductContainer3D
                args={[0.3, 0.3, 0.3]}
                position={[2.2969, 0.4, 1.3246]}
                rotation={[0, -Math.PI * 0.2, 0]}
                data={vacuum_cleaner_robot_data}
                showDebug={false}
                cameraTargetPos={[0, 0.3, 1]}
            />

            <mesh
                geometry={nodes.r9.geometry}
                material={materials['mat_body.002']}
                position={[2.2969, 0.3048, 1.3246]}
                rotation={[0, -1.2343, 0]}
                scale={[0.01, 0.01, 0.01]}
                visible={!isSet}
            >
                {/* @ts-ignore */}
                <a.meshBasicMaterial color={"#fff"} depthTest={true} depthWrite={true} transparent={true}
                                     opacity={spring.opacity}/>
                <ObjetPlusButton position={[0, 0, 0]} scale={100} visible={!(isSet)} opacity={spring.opacity} color={pathname.indexOf('/vacuum_cleaner_robot') > -1 ? '#be1e42' : '#000'}/>
            </mesh>

        </group>
    )
}
