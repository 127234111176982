import styled from "styled-components";
import { ROOT_PATH_PREFIX, Z_INDEX_LANDSCAPE } from "./const";

const Landscape = () => {
  return (
    <Wrapper>
      <img
        alt="landscape"
        src={ROOT_PATH_PREFIX + "/images/etc/rotate-screen.png"}
        width={"100%"}
      />
      {/*<Comming src={ROOT_PATH_PREFIX+"/images/etc/mobile_commingsoon.png"}  />*/}
    </Wrapper>
  );
};
export default Landscape;

const Wrapper = styled.div`
  // @media all {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   pointer-events: none;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   color: white;
  //   background-color: black;
  //   z-index: ${Z_INDEX_LANDSCAPE}
  // }

  @media all and (orientation: portrait) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    z-index: ${Z_INDEX_LANDSCAPE};
  }

  @media all and (orientation: landscape) {
    display: none;
  }
`;

const Comming = styled.img`
  @media all and (orientation: portrait) {
    width: 100%;
    padding: 2%;
  }

  @media all and (orientation: landscape) {
    height: 100%;
    padding: 2%;
  }
`;
