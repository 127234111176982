import create from "zustand";

export type Mode = {
  path: string;
  isPlaying: boolean;
  isMuted: boolean;
  play: () => void;
  pause: () => void;
  setURL: (val: string) => void;
  setMuted: (val: boolean) => void;
};

const audio = new Audio();
audio.loop = true;
audio.muted = false;
//
export const useAudioStore = create<Mode>((set, get) => ({
  path: "",
  isPlaying: false,
  isMuted: false,
  play: () => {
    if (get().path !== "") {
      audio.play();
      set({ isPlaying: true });
    }
  },
  pause: () => {
    if (get().path !== "") {
      audio.pause();
      set({ isPlaying: false });
    }
  },
  setURL: (val) => {
    audio.src = val;
    set({ path: val });
  },
  setMuted: (val) => {
    audio.muted = val;
    set({ isMuted: val });
  },
}));
